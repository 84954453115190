import { computed, observable, makeObservable } from 'mobx';

import { getProductAvailabilityMessage } from '~/product/common/summary/summary.utils';
import { formatCurrency } from '~/util/formatCurrency';

class SummaryCasegoods {
	addToCartAction;

	addToCartResponse;

	analytics;

	availability;

	breadcrumbModels;

	deliveryMessages;

	deliveryRateMessages;

	hasError;

	isAddToCartDone;

	isAddToCartInProcess;

	isLoading;

	isMaxQuantity;

	prices;

	pricing;

	productGroupModel;

	quantity;

	reflektionSkuKeys;

	selectorConfigModel;

	shippingMethod;

	shouldButtonAnimate;

	variantIds;

	workspaceModel;

	constructor() {
		makeObservable(this, {
			addToCartAction: observable,
			addToCartResponse: observable,
			analytics: observable.ref,
			availability: observable,
			deliveryMessages: observable,
			deliveryRateMessages: observable,
			isAddToCartDone: observable,
			isAddToCartInProcess: observable,
			isLoading: observable,
			isMaxQuantity: observable,
			prices: observable,
			pricing: observable,
			quantity: observable,
			reflektionSkuKeys: observable,
			shouldButtonAnimate: observable,
			addToCartHref: computed,
			addToCartParams: computed,
			addToFavoritesPayload: computed,
			availabilityMessage: computed,
			addToWishlistHref: computed,
			favoritesModalProductDto: computed,
			finalPrice: computed,
			isInvalidSummary: computed,
			isValidQuantity: computed,
			isValidSummary: computed,
			priceText: computed,
			showCallForPricing: computed,
			summaryHref: computed,
			summaryParams: computed,
		});
	}

	get addToFavoritesPayload() {
		const {
			canonicalUrl: friendlyPath,
			key: productGroupKey,
		} = this.productGroupModel;

		const frameProducts = this.frameProducts.map((product) => {
			const configuration = Object.keys(product.configuration).map((configKey) => {
				return `${configKey}:${product.configuration[configKey]}`;
			});

			const insertProducts = {};

			if (product.insertProducts) {
				Object.keys(product.insertProducts).forEach((insertKey) => {
					const insert = product.insertProducts[insertKey];
					insertProducts[insertKey] = {
						...insert,
						configuration: Object.keys(insert.configuration).map((configKey) => {
							return `${configKey}:${insert.configuration[configKey]}`;
						}),
					};
				});
			}

			return {
				...product,
				insertProducts,
				configuration,
			};
		});

		return {
			addToFavoritesPayload: {
				friendlyPath,
				productGroupKey,
				frameProducts,
				...(frameProducts.length === 1 && {
					product: frameProducts[0],
				}),
				quantity: this.quantity,
				sourceBreadcrumbs: this.sourceBreadcrumbs,
			},
		};
	}

	get addToFavoritesHref() {
		const {
			_links: {
				addToFavorites: {
					href = '',
				} = {},
			} = {},
		} = this.productGroupModel;

		return href;
	}

	get addToCartHref() {
		const {
			_links: {
				addToCart: {
					href = '',
				} = {},
			} = {},
		} = this.productGroupModel;

		return href;
	}

	get addToCartParams() {
		const {
			canonicalUrl: friendlyPath,
			key: productGroupKey,
		} = this.productGroupModel;

		const { sortedWorkspaceProducts = [] } = this.workspaceModel;

		const productRequestDtos = sortedWorkspaceProducts
			.map(({
				frameProductModel: {
					articleNumber = '',
					questionsAnswers = [],
				},
			}) => ({
				articleNumber,
				questionsAnswers,
			}));

		return {
			...this.summaryParams,
			productRequestDtos,
			friendlyPath,
			productGroupKey,
		};
	}

	get addToWishlistHref() {
		const {
			_links: {
				addToWishlist: {
					href = '',
				} = {},
			} = {},
		} = this.productGroupModel;

		return href;
	}

	get availabilityMessage() {
		return this.isValidSummary ? getProductAvailabilityMessage(this.availability) : '';
	}

	get favoritesModalProductDto() {
		const {
			finalPrice,
			productGroupModel: {
				summaryTitle,
			} = {},
		} = this;

		return {
			imageUrl: '',
			salesText: summaryTitle,
			quantity: this.quantity,
			price: finalPrice,
		};
	}

	get finalPrice() {
		if (this.workspaceModel.hasWorkspaceProducts) {
			return this.pricing;
		}

		return 0;
	}

	get isInvalidSummary() {
		const {
			settings: {
				isValidSummaryRequireIsValidSlots = false,
			} = {},
		} = this.selectorConfigModel;

		const { isValidSlots = false } = this.workspaceModel;

		if (isValidSummaryRequireIsValidSlots) {
			return !isValidSlots;
		}

		return false;
	}

	get isValidQuantity() {
		const { hasWorkspaceProducts = false } = this.workspaceModel;

		return hasWorkspaceProducts;
	}

	get isValidSummary() {
		return !this.isLoading && this.workspaceModel.hasWorkspaceProducts && Boolean(this.finalPrice);
	}

	get summaryHref() {
		const {
			_links: {
				summary: {
					href = '',
				} = {},
			} = {},
		} = this.productGroupModel;

		return href;
	}

	get showCallForPricing() {
		return this.hasError || (this.workspaceModel.hasWorkspaceProducts && !this.finalPrice);
	}

	get sourceBreadcrumbs() {
		if (!this.breadcrumbModels) {
			return {};
		}

		const sourceCrumbs = {};

		this.breadcrumbModels.forEach((crumb) => {
			const { url = '', type = '' } = crumb.data[0];
			if (!url || !type) {
				return;
			}
			const lastIndex = url.lastIndexOf('/');
			const trimmedUrl = url.slice(lastIndex + 1);
			const sourceCrumb = { [type]: trimmedUrl };
			Object.assign(sourceCrumbs, sourceCrumb);
		});
		return sourceCrumbs;
	}

	get frameProducts() {
		const {
			settings: {
				useInsertProductsSummaryParam = false,
			} = {},
		} = this.selectorConfigModel;
		const { sortedWorkspaceProducts = [] } = this.workspaceModel;
		const frameProducts = sortedWorkspaceProducts.map((workspaceProduct, index) => {
			const {
				frameProductModel: { articleNumber = '', shortSalesText = '' } = {},
				slotModelsWithInserts = [],
				workspaceProductConfiguration = {},
			} = workspaceProduct;

			const insertProducts = slotModelsWithInserts.reduce((accumulatedInsertProducts, insert) => {
				const {
					configuration,
					id = '',
					insertProductModel: {
						articleNumber: insertArticleNumber = '',
						configuration: insertConfiguration = {},
						title = '',
					} = {},
				} = insert;

				accumulatedInsertProducts[id] = {
					articleNumber: insertArticleNumber,
					configuration: { ...configuration, ...insertConfiguration },
					salesText: title,
				};
				return accumulatedInsertProducts;
			}, {});
			return {
				articleNumber,
				configuration: workspaceProductConfiguration,
				salesText: `Bookcase ${index + 1}: ${shortSalesText}`,
				sortOrder: index + 1,
				...(useInsertProductsSummaryParam && { insertProducts }),
			};
		});

		return frameProducts;
	}

	get priceText() {
		return this.showCallForPricing ? 'Call for pricing' : formatCurrency(this.finalPrice, true);
	}

	get summaryParams() {
		const { key = '' } = this.productGroupModel;

		const { sortedWorkspaceProducts = [] } = this.workspaceModel;

		const products = sortedWorkspaceProducts.map(({
			frameProductModel: { articleNumber = '' } = {},
			workspaceProductConfiguration = {},
		}) => {
			return {
				articleNumber,
				configuration: workspaceProductConfiguration,
				quantity: 1,
			};
		});

		return {
			frameProducts: this.frameProducts,
			productGroupKey: key,
			products,
			quantity: this.quantity,
			shippingMethod: this.shippingMethod,
		};
	}
}

export const SummaryCasegoodsModelFactory = ({
	create: ({
		availability = {},
		breadcrumbModels = [],
		hasError = false,
		prices = [],
		pricing = 0,
		productGroupModel = {},
		quantity = 1,
		reflektionSkuKeys = [],
		selectorConfigModel = {},
		shippingMethod = 'INH',
		variantIds = [],
		workspaceModel = {},
	}) => {
		const summary = new SummaryCasegoods();

		Object.assign(summary, {
			availability,
			breadcrumbModels,
			hasError,
			prices,
			pricing,
			productGroupModel,
			quantity,
			reflektionSkuKeys,
			selectorConfigModel,
			shippingMethod,
			variantIds,
			workspaceModel,
		});

		return summary;
	},
});
