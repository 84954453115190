import { action, observable, makeObservable } from 'mobx';

class Delivery {
	UPS = ['UPS', 'UP2', 'UP3', 'UPS_NEXT_DAY', 'UPS_SECOND_DAY'];

	constructor() {
		makeObservable(this, {
			UPS: observable,
			isUpsDelivery: action,
		});
	}

	isUpsDelivery(shippingMethod) {
		return (shippingMethod && this.UPS.indexOf(shippingMethod) !== -1);
	}
}

export const delivery = new Delivery();
