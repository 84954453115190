import { MediaModelFactory } from '~/media-set/Models/Media.model';

export const createMediaModel = ({
	disableClick = false,
	mediaData = {},
}) => {
	const mediaModel = MediaModelFactory.create({
		...mediaData,
		disableClick,
	});

	return mediaModel;
};

export const createMediaModels = ({
	disableClick = false,
	mediaSetData = [],
}) => {
	const mediaModels = mediaSetData
		.map(mediaData => createMediaModel({
			disableClick,
			mediaData,
		}))
		.filter(mediaModel => mediaModel?.type !== 'VIDEO_VIMEO');

	return mediaModels;
};
