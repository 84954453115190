import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { s7ContentPath, s7ImagePath } from '~/global/global.constants';

import styles from '~/product/sectionals/workspace/Components/modal-help/workspace-modal-help.module.scss';

export const WorkspaceModalHelpSectionals = () => {
	return (
		<div>
			<div className={styles['workspace-modal-help-section']}>
				<ul className={styles['workspace-modal-help-steps']}>
					<li>
						Drag and drop pieces to build your sectional.
					</li>
					<li>
						<span className={styles['workspace-modal-help-icon']}></span> means you can add the selected piece to that spot.
					</li>
					<li>
						<span className={`${styles['workspace-modal-help-icon']} ${styles['workspace-modal-help-icon-x']}`}></span> means you cannot add the selected piece to that spot.
					</li>
					<li>
						Pieces will auto-rotate when hovering over a space with <span className={styles['workspace-modal-help-icon']}></span>.
					</li>
					<li>
						Release a piece over <span className={styles['workspace-modal-help-icon']}></span> to add it.
					</li>
					<li>
						Drag individual pieces to the trash can to remove them.
					</li>
				</ul>
				<div>
					<strong>Need help?</strong> Contact Customer Care for <a href="/free-design-services">free design services</a> or assistance via <a href="/contact-us">phone, email or chat</a>.
				</div>
			</div>

			<div className={styles['workspace-modal-help-section']}>
				<h2 className={`${styles['workspace-modal-help-heading']} Heading Heading--4`}>Sectional Pieces</h2>
				<div>
					<div className={styles['workspace-modal-help-subheading']}>
						Right-arm vs. Left-arm
					</div>
					<div>Arm names refer to the sofa from your perspective when looking at the sofa.</div>
					<div className={styles['workspace-modal-help-images']}>
						<figure>
							<S7Image
								alt="diagram of sectional showing a left-arm chaise and right-arm sofa"
								height="130"
								src={`${s7ImagePath}/IA_sectionalIdeas_leftArmChaise`}
								width="235"
							/>
							<figcaption>Left-arm chaise, right-arm sofa</figcaption>
						</figure>
						<figure>
							<S7Image
								alt="diagram of sectional showing a left-arm sofa and right-arm chaise"
								height="130"
								src={`${s7ImagePath}/IA_sectionalIdeas_rightArmChaise`}
								width="235"
							/>
							<figcaption>Left-arm sofa, right-arm chaise</figcaption>
						</figure>
					</div>
				</div>
				<div>
					<div className={styles['workspace-modal-help-subheading']}>
						Corner Pieces
					</div>
					<div>
						If you want a corner in your layout, use one of the following pieces (varies by collection):
					</div>
					<ul className={styles['workspace-modal-help-steps']}>
						<li>
							Corner
						</li>
						<li>
							Curved corner
						</li>
						<li>
							Corner wedge
						</li>
						<li>
							Curved wedge
						</li>
						<li>
							Ottoman
						</li>
					</ul>
				</div>
				<div>
					<div className={styles['workspace-modal-help-subheading']}>
						Ottomans
					</div>
					<div>
						Use ottomans in your layout as corner pieces or in-line with other pieces. Or, add an optional unattached ottoman.
					</div>
					<div className={styles['workspace-modal-help-image']}>
						<S7Image
							alt="diagram of sectional showing various ottoman positions"
							height="96"
							src={`${s7ContentPath}/ottoman_diagram`}
							width="430"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
