import { computed, observable, makeObservable } from 'mobx';

import { showBtiDimensionsSelectorTypes } from '~/product/common/selector/selector.constants';
import { getFlattenedSelectors, getFlattenedSelectorValues, getProductModelsByQuestionAnswers } from '~/product/common/selector/Utils/SelectorConfig.utils';
import { sortBy } from '~/util/sortBy';
import { uniqBy } from '~/util/uniqBy';

export class SelectorConfig {
	defaultConfigModels;

	defaultProductModel;

	lastSelectedSelectorValue;

	productSelectorModel;

	productsModel;

	_questionSelectorModels;

	showSingleSelectorValueSelectors;

	constructor() {
		makeObservable(this, {
			lastSelectedSelectorValue: observable,
			btiDimensionsSelectorValues: computed,
			flattenedUniqueSelectorBlurbs: computed,
			hasCylindoData: computed,
			hasSelectorsToRender: computed,
			hasUnselectedSelectors: computed,
			loadedProductModels: computed,
			loadedSelectedProducts: computed,
			productSelectorModels: computed,
			productSelectorValues: computed,
			questionSelectorModels: computed,
			questionSelectorsConfig: computed,
			questionSelectorsQuestionAnswers: computed,
			questionSelectorValues: computed,
			selectedMaterialSelectorValue: computed,
			selectedProduct: computed,
			selectedProductConfig: computed,
			selectedProductQuestionAnswers: computed,
			selectedProducts: computed,
			selectedProductSelectors: computed,
			selectedProductSelectorValue: computed,
			selectedProductSelectorValues: computed,
			selectedQuestionSelectorValues: computed,
			selectedQuestionSelectorValuesWithStatuses: computed,
			selectedSelectorsToRender: computed,
			selectedSelectorValues: computed,
			sortedSelectedSelectorsToRender: computed,
			unselectedSelectors: computed,
			useExposedSelector: computed,
			validQuestionSelectorModels: computed,
			validQuestionSelectorsQuestionAnswers: computed,
			validSelectedQuestionSelectorValues: computed,
		});
	}

	get btiDimensionsSelectorValues() {
		const btiDimensionsSelectors = this._questionSelectorModels.filter(({ renderer = '' }) => showBtiDimensionsSelectorTypes.includes(renderer));

		return btiDimensionsSelectors.reduce((accumulatedDimensionQuestionSelectorVallues = [], { dimensionQuestionSelectorModels = [] } = {}) => {
			const selectedDimensionQuestionSelectorValues = dimensionQuestionSelectorModels.map(({ selectedSelectorValue = {} }) => selectedSelectorValue);

			return [...accumulatedDimensionQuestionSelectorVallues, ...selectedDimensionQuestionSelectorValues];
		}, []);
	}

	get flattenedUniqueSelectorBlurbs() {
		const flattenedSelectorBlurbs = this.productSelectorModels.reduce((accumulatedBlurbs = [], { blurbs = [] } = {}) => {
			return [...accumulatedBlurbs, ...blurbs];
		}, []);

		return uniqBy(flattenedSelectorBlurbs, 'key');
	}

	get hasCylindoData() {
		return this.productsModel.some(({
			imageData: {
				cylindo,
			} = {},
		}) => {
			return cylindo;
		});
	}

	get hasSelectorsToRender() {
		return Boolean(this.sortedSelectedSelectorsToRender.length);
	}

	get hasUnselectedSelectors() {
		return Boolean(this.unselectedSelectors.length);
	}

	get loadedProductModels() {
		return this.productsModel.filter(({ isLoaded = false }) => isLoaded);
	}

	get loadedSelectedProducts() {
		return this.selectedProducts.filter(({ isLoaded = false }) => isLoaded);
	}

	get productSelectorModels() {
		return getFlattenedSelectors([this.productSelectorModel]);
	}

	get productSelectorValues() {
		const { selectorValues = [] } = this.productSelectorModel;

		return getFlattenedSelectorValues(selectorValues);
	}

	get questionSelectorModels() {
		return this._questionSelectorModels.reduce((accumulatedQuestionSelectorModels = [], questionSelectorModel = {}) => {
			const { dimensionQuestionSelectorModels = [] } = questionSelectorModel;

			dimensionQuestionSelectorModels.forEach((dimensionQuestionSelectorModel = {}) => {
				accumulatedQuestionSelectorModels.push(dimensionQuestionSelectorModel);
			});

			if (dimensionQuestionSelectorModels.length) {
				return accumulatedQuestionSelectorModels;
			}

			return [...accumulatedQuestionSelectorModels, questionSelectorModel];
		}, []);
	}

	get questionSelectorsConfig() {
		return this.questionSelectorsQuestionAnswers.map(({
			answer = '',
			group = '',
		}) => {
			return `${group}:${answer}`;
		}).filter(Boolean);
	}

	get questionSelectorsQuestionAnswers() {
		return this.selectedQuestionSelectorValues.reduce((accumulatedQuestionAnswers, { questionAnswers = [] }) => {
			return [...accumulatedQuestionAnswers, ...questionAnswers];
		}, []);
	}

	get questionSelectorValues() {
		return this.questionSelectorModels.reduce((accumulatedQuestionSelectorValues, { selectorValues = [] }) => {
			return [...accumulatedQuestionSelectorValues, ...selectorValues];
		}, []);
	}

	get selectedMaterialSelectorModel() {
		return [...this.productSelectorModels, ...this.questionSelectorModels].find(({ selectorValues = [] }) => selectorValues.includes(this.selectedMaterialSelectorValue));
	}

	get selectedMaterialSelectorValue() {
		return [...this.selectedProductSelectorValues, ...this.selectedQuestionSelectorValues].find(({ materialAPIHref = '' }) => materialAPIHref);
	}

	get selectedProduct() {
		const [firstProductModel = {}] = this.productsModel;

		const [firstSelectedProductModel] = this.selectedProducts;

		const validProductModels = getProductModelsByQuestionAnswers({
			productModels: this.loadedSelectedProducts,
			questionAnswers: this.questionSelectorsQuestionAnswers,
		});

		const [firstValidProductModel] = validProductModels;

		return firstValidProductModel || firstSelectedProductModel || firstProductModel;
	}

	get selectedProductConfig() {
		return this.selectedProductQuestionAnswers.map(({
			selectedAnswer: {
				key: answerKey = '',
			} = {},
			key = '',
		}) => {
			return key && `${key}:${answerKey}`;
		}).filter(Boolean);
	}

	get selectedProductQuestionAnswers() {
		const { questionsModel = [] } = this.selectedProduct;

		return questionsModel.map(({
			answersModel = [],
			group = '',
			key = '',
		}) => {
			const questionSelectorsQuestionAnswer = this.validQuestionSelectorsQuestionAnswers.find(({ group: questionSelectorGroup = '' }) => {
				return group === questionSelectorGroup;
			}) || {};

			const { answer = '' } = questionSelectorsQuestionAnswer;

			const selectedAnswer = answersModel.find(({ key: answerKey = '' }) => answer === answerKey) || {};

			const { key: answerKey = '' } = selectedAnswer;

			return answerKey && {
				answersModel,
				group,
				key,
				selectedAnswer,
			};
		}).filter(Boolean);
	}

	get selectedProducts() {
		const { products = [] } = this.selectedProductSelectorValue || {};

		return products;
	}

	get selectedProductSelectors() {
		return this.productSelectorModels.filter(({ selected = false }) => selected);
	}

	get selectedProductSelectorValue() {
		return this.selectedProductSelectorValues.find(({ hasSelectors = false }) => !hasSelectors);
	}

	get selectedProductSelectorValues() {
		return this.productSelectorValues.filter(({ selected = false }) => selected);
	}

	get selectedQuestionSelectorValues() {
		return this.questionSelectorValues.filter(({ selected = false }) => selected);
	}

	get selectedQuestionSelectorValuesWithStatuses() {
		return this.validSelectedQuestionSelectorValues.filter(({ status = {} }) => Boolean(Object.entries(status).length));
	}

	get selectedSelectorsToRender() {
		return [...this.selectedProductSelectors, ...this._questionSelectorModels].filter(({
			label = '',
			matchingQuestionSelectorModel,
			matchingQuestionSelectorModel: {
				validSelectorValues: matchingQuestionSelectorModelValidSelectorValues = [],
			} = {},
			materialSelectorValues = [],
			renderer = '',
			selectorValues = [],
			showAllValues = false,
			type = '',
			validSelectorValues = [],
		}) => {
			// do not render any questionSelectors with a matching productSelector
			const hasMatchingProductSelector = type === 'QUESTION' && this.productSelectorModels.some(({
				label: productSelectorLabel = '',
				renderer: productSelectorRenderer = '',
			}) => {
				return label === productSelectorLabel && renderer === productSelectorRenderer;
			});

			const allOrValidSelectorValues = showAllValues ? selectorValues : validSelectorValues;

			// use matchingQuestionSelectorModelValidSelectorValues when a productSelector only has one validSelectorValue, and has a matching question selector
			const validSelectorValuesToUse = (matchingQuestionSelectorModel && validSelectorValues.length === 1) ? matchingQuestionSelectorModelValidSelectorValues : allOrValidSelectorValues;

			// use materialSelectorValues when a productSelector only has one validSelectorValue, and has no matching question selector
			const validMaterialSelectorValuesToUse = (!matchingQuestionSelectorModel && validSelectorValues.length === 1) ? materialSelectorValues : [];

			if (renderer === 'NONE' || hasMatchingProductSelector) {
				return false;
			}

			return this.showSingleSelectorValueSelectors || Boolean(validSelectorValuesToUse.length > 1) || Boolean(validMaterialSelectorValuesToUse.length > 1);
		});
	}

	get selectedSelectorValues() {
		return this.selectedProductSelectorValues;
	}

	get sortedSelectedSelectorsToRender() {
		return sortBy(this.selectedSelectorsToRender, 'displayOrder');
	}

	get unselectedSelectors() {
		return this.selectedProductSelectors.filter(({ unselected = false }) => unselected);
	}

	get useExposedSelector() {
		const hasMultipleSelectors = this.sortedSelectedSelectorsToRender.length > 1;

		const hasExposedSelector = this.sortedSelectedSelectorsToRender.some(({ isExposed = false }) => isExposed);

		return !hasMultipleSelectors || hasExposedSelector;
	}

	get validQuestionSelectorModels() {
		return this.questionSelectorModels.filter(({ hasValidSelectorValues = false }) => hasValidSelectorValues);
	}

	get validQuestionSelectorsQuestionAnswers() {
		return this.validSelectedQuestionSelectorValues.reduce((accumulatedQuestionAnswers, { questionAnswers = [] }) => {
			return [...accumulatedQuestionAnswers, ...questionAnswers];
		}, []);
	}

	get validSelectedQuestionSelectorValues() {
		return this.validQuestionSelectorModels.map(({ selectedSelectorValue = {} }) => selectedSelectorValue).filter(Boolean);
	}
}

export const SelectorConfigModelFactory = ({
	create: ({
		defaultConfigModels = [],
		defaultProductModel = {},
		lastSelectedSelectorValue = {},
		productSelectorModel = {},
		productsModel = [],
		questionSelectorModels = [],
		showSingleSelectorValueSelectors = false,
	}) => {
		const selectorConfigModel = new SelectorConfig();

		Object.assign(selectorConfigModel, {
			defaultConfigModels,
			defaultProductModel,
			lastSelectedSelectorValue,
			productSelectorModel,
			productsModel,
			_questionSelectorModels: questionSelectorModels,
			showSingleSelectorValueSelectors,
		});

		return selectorConfigModel;
	},
});
