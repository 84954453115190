import {
	idProp,
	Model, model, prop, modelAction,
} from 'mobx-keystone';
import { observable } from 'mobx';

import { modelNamespace } from '~/util/modelNamespace';
import { ADD_FAVORITES_PRODUCT_GROUP_TYPE, type ISourceBreadcrumbs, PageType } from '~/components/product-group/Types/ProductGroup.type';
import { SUBCAT_DOCUMENT_TYPE } from '~/subcategory/common/subcategory.type';
import { BreadcrumbModel, DynamicBreadcrumbModelFactory, breadcrumbsType } from '~/layout/Models/Breadcrumb.model';

@model(`${modelNamespace.COMPONENT}/AddToFavoritesButtonModel`)
export class AddToFavoritesButtonModel extends Model({
	id: idProp.withSetter(),
	addToFavoritesAPI: prop<string>(),
	addToFavoritesPropertiesAPI: prop<string>(),
	addToFavoritesType: prop<ADD_FAVORITES_PRODUCT_GROUP_TYPE | undefined>(),
	breadcrumbs: prop<breadcrumbsType>(),
	articleNumber: prop<number | string>('').withSetter(),
	configuration: prop<any>().withSetter(),
	productGroupKey: prop<string>('').withSetter(),
	productGroupName: prop<string>(),
	pageType: prop<PageType | 'PRODUCT_PAGE'>().withSetter(),
	productGroupURL: prop<string>(),
	salesText: prop<string>('').withSetter(),
	targetFavoriteListID: prop<string>('').withSetter(),
}) {
	@observable.ref breadcrumbModels!: BreadcrumbModel[];

	get friendlyPath() {
		if (this.pageType === SUBCAT_DOCUMENT_TYPE.STANDARD) {
			return this.productGroupURL;
		}
		if (this.pageType === SUBCAT_DOCUMENT_TYPE.EDITORIAL || this.pageType === 'STANDALONE_GALLERY') {
			return this.productGroupURL.replace(/\/[^/]*$/, '');
		}
		if (this.pageType === SUBCAT_DOCUMENT_TYPE.SHOP_THIS_ROOM || this.pageType === 'PRODUCT_PAGE') {
			return `/${this.subcategoryBreadcrumbURL()}/${this.productGroupURL.replace(/\/[^/]*$/, '')}`;
		}
		if (this.pageType === 'INTERMEDIATE_CATEGORY') {
			return `/${this.firstBreadcrumbURL()}/${this.productGroupURL}`;
		}
		return '';
	}

	onInit() {
		this.breadcrumbModels = DynamicBreadcrumbModelFactory.create(this.breadcrumbs);
	}

	@modelAction
	firstBreadcrumbURL() {
		const firstBreadcrumb = this.breadcrumbModels[0];
		return firstBreadcrumb?.url || '';
	}

	@modelAction
	subcategoryBreadcrumbURL() {
		const subcategoryBreadcrumb = this.breadcrumbModels.find(crumb => crumb.isSubcategory);
		if (!subcategoryBreadcrumb) {
			return '';
		}

		return subcategoryBreadcrumb.url;
	}

	get favTrLinkEventCompName() {
		return this.pageType === SUBCAT_DOCUMENT_TYPE.STANDARD
			|| this.pageType === SUBCAT_DOCUMENT_TYPE.EDITORIAL
			|| this.pageType === SUBCAT_DOCUMENT_TYPE.SHOP_THIS_ROOM
			|| this.pageType === 'PRODUCT_PAGE'
			|| this.pageType === 'STANDALONE_GALLERY' ? this.productGroupName : null;
	}

	get favTrLinkEventCompType() {
		if (this.pageType === SUBCAT_DOCUMENT_TYPE.STANDARD) {
			return 'subcategory carousel';
		}

		if (this.pageType === SUBCAT_DOCUMENT_TYPE.EDITORIAL) {
			return 'editorial shopping';
		}

		if (this.pageType === 'INTERMEDIATE_CATEGORY') {
			return 'intermediate category page';
		}

		if (this.pageType === SUBCAT_DOCUMENT_TYPE.SHOP_THIS_ROOM) {
			return 'shop this room';
		}

		if (this.pageType === 'PRODUCT_PAGE') {
			return 'shop this room tray';
		}

		if (this.pageType === 'STANDALONE_GALLERY') {
			return 'standalone gallery';
		}

		return null;
	}

	@modelAction
	sourceBreadcrumbs(): ISourceBreadcrumbs {
		const sourceCrumbs: ISourceBreadcrumbs = {};

		this.breadcrumbModels.forEach((crumb: BreadcrumbModel) => {
			const { url = '', type = '' } = crumb;
			if (!url || !type) {
				return null;
			}
			const lastIndex = url.lastIndexOf('/');
			const trimmedUrl = url.slice(lastIndex + 1);
			const sourceCrumb = { [type]: trimmedUrl };
			Object.assign(sourceCrumbs, sourceCrumb);
			return sourceCrumbs;
		});

		return sourceCrumbs;
	}

	get productDTO() {
		if (this.addToFavoritesType === ADD_FAVORITES_PRODUCT_GROUP_TYPE.MULTI_PRODUCT) {
			return this.productsPayload;
		}
		return this.productPayload;
	}

	get productPayload() {
		return {
			addToFavoritesPayload: {
				friendlyPath: this.friendlyPath,
				product: {
					articleNumber: this.articleNumber,
					configuration: this.configuration,
					salesText: this.salesText,
				},
				productGroupKey: this.productGroupKey,
				quantity: 1,
				sourceBreadcrumbs: this.sourceBreadcrumbs(),
			},
		};
	}

	get productsPayload() {
		return {
			addToFavoritesPayload: {
				friendlyPath: this.friendlyPath,
				products: [{
					articleNumber: this.articleNumber,
					salesText: this.salesText,
					quantity: 1,
				}],
				productGroupKey: this.productGroupKey,
				sourceBreadcrumbs: this.sourceBreadcrumbs(),
			},
		};
	}
}
