import { computed, observable, makeObservable } from 'mobx';

class SelectorValueMaterial {
	_links;

	answer;

	articles;

	available;

	detailsModel;

	image;

	parentLabel;

	parentType;

	question;

	selected;

	selectorDetailsModel;

	selectors;

	stocked;

	title;

	constructor() {
		makeObservable(this, {
			available: observable,
			selected: observable,
			hasSelectorDetails: computed,
			materialAPIHref: computed,
			questionAnswerParams: computed,
			questionAnswers: computed,
			selectorDetailImage: computed,
			selectorDetailLink: computed,
			selectorDetailsToRender: computed,
		});
	}

	get hasSelectorDetails() {
		return Boolean(this.selectorDetailsModel.length);
	}

	get materialAPIHref() {
		const {
			materialDetails: {
				href = '',
			} = {},
		} = this._links;

		return href;
	}

	get questionAnswerParams() {
		return `${this.question}=${this.answer}`;
	}

	get questionAnswers() {
		return { [this.question]: this.answer };
	}

	get selectorDetailImage() {
		return this.selectorDetailsModel.find(({ type }) => type === 'image');
	}

	get selectorDetailLink() {
		return this.selectorDetailsModel.find(({ type }) => type === 'link');
	}

	get selectorDetailsToRender() {
		return this.selectorDetailsModel.filter(({ type }) => type !== 'image');
	}
}

export const SelectorValueMaterialModelFactory = ({
	create: ({
		_links = {},
		answer = '',
		articles = [],
		available = true,
		detailsModel = [],
		image = 'noimage',
		parentLabel = '',
		parentType = '',
		question = '',
		selected = false,
		selectorDetailsModel = [],
		selectors = [],
		stocked = false,
		title = '',
	}) => {
		const selectorValueMaterial = new SelectorValueMaterial();

		Object.assign(selectorValueMaterial, {
			_links,
			answer,
			articles,
			available,
			detailsModel,
			image,
			parentLabel,
			parentType,
			question,
			selected,
			selectorDetailsModel,
			selectors,
			stocked,
			title,
		});

		return selectorValueMaterial;
	},
});
