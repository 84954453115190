import { SummaryModelFactory } from '~/product/common/summary/Models/Summary.model';
import { SummaryStoreFactory } from '~/product/common/summary/Stores/Summary.store';

export const createSummaryMultiModels = ({
	globalDynamicStore = {},
	productGroupModel = {},
	selectorConfigModels = [],
}) => {
	return selectorConfigModels.map((selectorConfigModel = {}) => {
		const {
			defaultProductModel: {
				configurationOverrideModel: {
					hasConfigurationOverride = false,
				} = {},
			} = {},
		} = selectorConfigModel;

		return SummaryModelFactory.create({
			globalDynamicStore,
			productGroupModel,
			quantity: hasConfigurationOverride ? 1 : 0,
			selectorConfigModel,
		});
	});
};

export const createSummaryMultiStores = ({
	featureTogglesModel = {},
	globalDynamicStore = {},
	magicModal = {},
	pageStore = {},
	summaryModels = [],
	HREF,
	trackingDebugMode = {},
}) => {
	return summaryModels.map((summaryModel) => {
		return SummaryStoreFactory.create({
			disableSummaryReactions: true,
			featureTogglesModel,
			globalDynamicStore,
			magicModal,
			pageStore,
			summaryModel,
			HREF,
		});
	});
};
