import { MouseEvent, TouchEvent } from 'react';

import { SUBCAT_DOCUMENT_TYPE } from '~/subcategory/common/subcategory.type';

export enum ADD_FAVORITES_PRODUCT_GROUP_TYPE {
	STANDARD = 'STANDARD',
	MULTI_PRODUCT = 'MULTI_PRODUCT',
}

export interface DefaultProductGroup {
	carouselMediaSet?: CarouselMedia[],
	displaySwatch: {
		displaySwatchValues: { imageSrc: string, altText: string }[],
		plusMore: boolean
	},
	gallery?: GalleryObject,
	id: number,
	imageUrl: string,
	key: string,
	leadtimeText?: string,
	_links?: {
		addToFavorites: {
			href: string,
			type: ADD_FAVORITES_PRODUCT_GROUP_TYPE,
		},
		addToFavoritesProperties: {
			href: string,
		},
	},
	moreOptions: string,
	name: string,
	priceRange: string,
	promotionalPrice: boolean,
	reviews?: {
		count: number,
		reviewsAverageOverallRating: number,
	}
	staticUrl: string,
	status?: ProductGroupStatus,
	url: string,
	wasPriceString?: string,
}

export interface DefaultProductGroupInterface {
	additionalTextOptions: {
		statusText?: string,
		optionsText: string,
		shippingText?: string,
		leadtimeText?: string,
		styleText?: string,
		customDesignText?: string,
	},
	addToFavoritesAPI?: string,
	addToFavoritesType?: ADD_FAVORITES_PRODUCT_GROUP_TYPE,
	addToFavoritesPropertiesAPI?: string,
	articleNumber: number,
	carouselMediaSet?: CarouselMedia[],
	img: string | undefined,
	headerText: string,
	highlightPrice: boolean,
	link: {
		href: string,
		asHref: string,
	},
	price: string,
	productGroupKey: string,
	reviews: {
		starCount: number,
		reviewsCount?: number,
	},
	swatches?: {
		images: {
			url: string,
			altText: string,
		}[],
		plusMore: boolean,
	}
	subcategoryType: string,
}

export type PageType = SUBCAT_DOCUMENT_TYPE | 'INTERMEDIATE_CATEGORY' | 'STANDALONE_GALLERY';

export type ClickOrTouchEventHandler = (event: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => void;

export interface CarouselMedia {
	imageUrl: string,
	caption: string,
}

export interface ProductGroupStatus {
	status?: string,
	type: 'DEFAULT' | 'LIMITED_EDITION_SOLD_OUT',
}

export enum SOURCE_BREADCRUMB_TYPE {
	CATEGORY = 'CATEGORY',
	INTERMEDIATE_CATEGORY = 'INTERMEDIATE_CATEGORY',
	SUBCATEGORY = 'SUBCATEGORY',
	PRODUCT_GROUP = 'PRODUCT_GROUP',
}

export type SourceBreadcrumb = {
	[key in SOURCE_BREADCRUMB_TYPE]?: string;
}

export interface ISourceBreadcrumbs {
	sourceBreadcrumbs?: SourceBreadcrumb,
}

interface GalleryImage {
	src: string;
	width: number;
	height: number;
	altText?: string;
}

interface GalleryItem {
	image: GalleryImage;
	caption?: string;
	url?: string;
}
export interface GalleryObject {
	items: GalleryItem[];
}
