import { computed, makeObservable, observable } from 'mobx';

class DefaultConfig {
	config

	frameProducts

	constructor(frameProducts) {
		makeObservable(this, {
			configuration: computed,
			defaultAnswers: computed,
			frameProducts: observable,
			configurationOverride: computed,
		});
		this.frameProducts = frameProducts;
	}

	get configuration() {
		return {
			...this.config,
			...this.configurationOverride,
		};
	}

	get configurationOverride() {
		return this.frameProducts.reduce((accumulatedConfiguration, frameProduct) => {
			const { configuration } = frameProduct;
			Object.assign(accumulatedConfiguration, configuration);
			Object.values(frameProduct?.insertProducts).map(({ configuration: insertConfiguration }) => Object.assign(accumulatedConfiguration, insertConfiguration));
			return accumulatedConfiguration;
		}, {});
	}

	get defaultAnswers() {
		return Object.entries(this.configuration).map(([question, answer]) => {
			return {
				answer,
				question,
			};
		});
	}

	get params() {
		return Object.entries(this.configuration).reduce(
			(accumulator, [question, answer]) => `${accumulator}&${question}=${answer}`,
			'',
		);
	}
}

export const DefaultConfigModelFactory = ({
	create: ({
		config = {},
		workspaceData: {
			frameProducts = [],
		} = {},
	} = {}) => {
		const defaultConfig = new DefaultConfig();

		Object.assign(defaultConfig, {
			frameProducts,
			config,
		});

		return defaultConfig;
	},
});
