import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { s7ContentPath } from '~/global/global.constants';
import { SelectorPromos } from '~/product/common/selector/Components/SelectorPromos';
import { SelectorValueLayout } from '~/product/sectionals/selector/Components/layout/SelectorValueLayout';
import { SelectorLayoutRemoveDroppable } from '~/product/sectionals/selector/Components/layout/SelectorLayoutRemoveDroppable';
import { getShouldShowShadowY } from '~/product/common/selector/Utils/Selector.utils';
import { WorkspaceModalHelpSectionals } from '~/product/sectionals/workspace/Components/modal-help/WorkspaceModalHelpSectionals';

import styles from '~/product/sectionals/selector/Components/layout/SelectorLayout.module.scss';

export const SelectorLayout = observer((props) => {
	const {
		isActive = false,
		selectedSelector: {
			label = '',
			stockedSelectorValues = [],
			stockedSelectorValuesCount = 0,
			spoSelectorValues = [],
			spoSelectorValuesCount = 0,
			promos = [],
		} = {},
	} = props;

	const { magicModal = {} } = useGlobalContext();

	const scrollingParentRef = useRef();
	const scrollingChildRef = useRef();

	const [showShadow, setShowShadow] = useState(false);

	const messageRef = useRef();
	const [showMessage, setShowMessasge] = useState(true);
	let ticking = false;

	function setShowShadowState() {
		const shouldShowShadow = getShouldShowShadowY({
			isActive,
			scrollingChildRef,
			scrollingParentRef,
		});

		setShowShadow(shouldShowShadow);
	}

	function handleScroll() {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				ticking = false;
				setShowMessasge(false);
				setShowShadowState();
			});

			ticking = true;
		}
	}
	function handleTouchStart() {
		setShowMessasge(false);
	}

	function handleClick(event) {
		magicModal.openModal({
			content: {
				children: <WorkspaceModalHelpSectionals />,
			},
			id: 'workspace-help-modal',
			maxWidth: '600px',
			title: 'Help',
		}, event);
	}

	useEffect(setShowShadowState, [isActive]);

	return (
		<>
			<button
				className={`${styles['selector-layout-help']} ButtonAnchor`}
				data-qa="selector-layout-help"
				onClick={handleClick}
			>
				Learn about sectional pieces
			</button>
			<div className={styles['selector-layout-wrapper']}>
				<div
					className={styles['selector-layout-container']}
					onScroll={handleScroll}
					onTouchStart={handleTouchStart}
					ref={scrollingParentRef}
				>
					<div
						className={
							classNames(styles['selector-layout'], {
								[styles['selector-layout-shadow']]: showShadow,
							})
						}
						ref={scrollingChildRef}
					>
						<div className={styles['selector-values-types-layout']}>
							{
								stockedSelectorValues.length > 0 && (
									<div
										key="selector-values-type-stocked"
										className={`${styles['selector-values-type-layout']} selector-values-type-layout-stocked`}
										data-qa="selector-values-type-layout"
									>
										<div className={styles['selector-values-type-layout-text']}>
											<span
												className={styles['selector-values-type-layout-title']}
												data-qa="selector-values-type-layout-title-stocked"
											>
												Stocked (in select materials)
											</span>
											<span
												className={styles['selector-values-type-layout-count']}
												data-qa="selector-values-type-layout-count-stocked"
											>
												{stockedSelectorValuesCount} {stockedSelectorValuesCount === 1 ? 'option' : 'options'}
											</span>
										</div>
										<div className={styles['selector-values-layout']} >
											{
												stockedSelectorValues.map((selectorValue, index) => {
													return (
														<SelectorValueLayout
															key={`selector-value-${index}`}
															label={label}
															selectorValue={selectorValue}
														/>
													);
												})
											}
										</div>
									</div>
								)
							}
							{
								spoSelectorValues.length > 0 && (
									<div
										key="selector-values-type-spo"
										className={`${styles['selector-values-type-layout']} selector-values-type-layout-spo`}
										data-qa="selector-values-type-layout"
									>
										<div className={styles['selector-values-type-layout-text']}>
											<span
												className={styles['selector-values-type-layout-title']}
												data-qa="selector-values-type-layout-title-spo"
											>
												Made for you
											</span>
											<span
												className={styles['selector-values-type-layout-count']}
												data-qa="selector-values-type-layout-count-spo"
											>
												{spoSelectorValuesCount} {spoSelectorValuesCount === 1 ? 'option' : 'options'}
											</span>
										</div>
										<div className={styles['selector-values-layout']}>
											{
												spoSelectorValues.map((selectorValue, index) => {
													return (
														<SelectorValueLayout
															key={`selector-value-${index}`}
															label={label}
															selectorValue={selectorValue}
														/>
													);
												})
											}
										</div>
									</div>
								)
							}
						</div>
					</div>
				</div>
				<SelectorLayoutRemoveDroppable />
			</div>
			<SelectorPromos promos={promos} />
			<div
				className={
					classNames(`${styles['selector-layout-message']} md:tw-hidden`, {
						'tw-opacity-0': !showMessage,
					})
				}
				ref={messageRef}
			>
				<S7Image
					alt=""
					className={styles['selector-layout-message-image']}
					height="14"
					src={`${s7ContentPath}/swipe-arrows`}
					width="40"
				/>
				<span className={styles['selector-layout-message-text']}>Swipe for <br />options</span>
			</div>
		</>
	);
});
