import React from 'react';
import { useRouter } from 'next/router';
import { reaction } from 'mobx';

import { SummaryModelFactory } from '~/product/clearance/Models/Summary.model';
import { SummaryStoreFactory } from '~/product/clearance/Stores/Summary.store';

import { createMagicDeliveryModel, createMagicDeliveryStore } from '~/components/magic-delivery/Helpers/MagicDelivery.init';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { BreadcrumbModel } from '~/layout/Models/Breadcrumb.model';
import { ErrorsModel } from '~/product/common/errors/Models/Errors.model';
import { createDetailsModel } from '~/product/common/details/Helpers/Details.init';
import { createMediaSetDetailModel } from '~/product/common/media-set/Helpers/MediaSetDetail.init';
import { MediaSetStoreFactory } from '~/media-set/Stores/MediaSet.store';
import { ProductModelFactory } from '~/product/common/Models/Product.model';
import { ProductCommonsModelFactory } from '~/product/common/Models/ProductCommons.model';
import { MagicPagerStore } from '~/components/magic-pager/Stores/MagicPager.store';
import { DeliveryMainStoreFactory } from '~/delivery/Stores/DeliveryMain.store';
import { createProductRecoStore } from '~/product/common/Helpers/ProductRecos.init';
import { isEngage, isOnServer } from '~/global/global.constants';
import { ProductEventsTrackingStoreFactory } from '~/product/common/Stores/ProductEvents.tracking.store';
import { TrackingDebugModeFactory } from '~/tracking/debug/Tracking.debugMode';
import { StickySummaryModel } from '~/product/common/summary/Models/StickySummary.model';

export const productClearanceContext = (pageProps) => {
	const router = useRouter();

	const {
		productPageModel: {
			query: {
				category = '',
				subcategory = '',
				product = '',
				error = '',
			} = {},
			breadcrumbs,
			product: rawProductData,
			product: {
				caption = '',
				detail: {
					additionalInformation: detailsAdditionalData = [],
					details: detailsAttributesData = [],
					dimensions: detailsDimensionsData = [],
					materials: detailsMaterialsData = [],
					sustainability: detailsSustainabilityData = [],
				} = {},
				imageData: productImageData = {},
			} = {},
			requestTimeContent: {
				maxQtyOverride,
				oneAvailableQtyOverride,
				promos,
				statusOverride,
				canonicalUrl,
			} = {}
		},
		pageType,
		productPageTemplate,
	} = pageProps;

	const relativeProductGroupPath = `/clearance/${category}/${subcategory}/${product}`;

	const {
		globalDynamicModel,
		globalDynamicStore,
		globalStaticModel,
		magicModal,
		featureTogglesModel,
		pageStore,
	} = useGlobalContext();

	const errorsModel = new ErrorsModel(error);

	const breadcrumbModels = breadcrumbs.map((breadcrumb) => {
		return new BreadcrumbModel(breadcrumb);
	});

	// Product specific stuff
	const imageData = productImageData;

	// The set handles de-duping the array
	let detailsAttributesModel = createDetailsModel(
		detailsDimensionsData
			.concat(detailsAttributesData)
			.concat(detailsAdditionalData)
	);

	const detailLabels = [];
	detailsAttributesModel = detailsAttributesModel
		.filter((detail) => {
			const {
				label,
				displayText
			} = detail;
			if (detailLabels.includes(`${label}:${displayText}`)) {
				return false;
			}
			detailLabels.push(`${label}:${displayText}`);
			return true;
		});

	const detailsMaterialsModel = createDetailsModel(detailsMaterialsData);

	const detailsSustainabilityModel = createDetailsModel(detailsSustainabilityData);

	const productModel = ProductModelFactory.create({
		...rawProductData,
		detailsAttributesModel,
		detailsMaterialsModel,
		detailsSustainabilityModel,
		imageData,
		relativeProductGroupPath,
		maxQtyOverride,
		oneAvailableQtyOverride,
		statusOverride,
		promos
	});

	const mediaSetDetailModel = createMediaSetDetailModel({
		productGroup: {
			mainImageCaption: caption,
		},
		selectorConfigModel: { selectedProduct: productModel },
	});

	const mediaSetDetailStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetDetailModel });

	const categoryBreadcrumb = breadcrumbModels.find(breadcrumbModel => breadcrumbModel.isCategory);
	const categoryName = categoryBreadcrumb ? categoryBreadcrumb.pageTitle || categoryBreadcrumb.title : '';
	const subcategoryBreadcrumb = breadcrumbModels.find(breadcrumbModel => breadcrumbModel.isSubcategory);
	const subcategoryName = subcategoryBreadcrumb ? subcategoryBreadcrumb.pageTitle || subcategoryBreadcrumb.title : '';

	const productUrl = breadcrumbModels?.length ? breadcrumbModels[breadcrumbModels.length - 1].url : '';

	const friendlyUrl = breadcrumbModels?.length > 1 ? breadcrumbModels[breadcrumbModels.length - 2].url : '';

	const summaryModel = SummaryModelFactory.create(productModel, friendlyUrl, productUrl, featureTogglesModel);

	const stickySummaryModel = featureTogglesModel.isOn('PRODUCT_PAGE_MOBILE_LAYOUT_REDESIGN') ? new StickySummaryModel({ productPageTemplate }) : {};

	if (featureTogglesModel.isOn('PRODUCT_PAGE_MOBILE_LAYOUT_REDESIGN')) {
		stickySummaryModel.setModels({
			mediaSetDetailModel,
			productGroupModel: productModel,
			selectorConfigModel: { selectedProduct: productModel },
			summaryModel,
		});
	}

	const productTrackingStore = ProductEventsTrackingStoreFactory.create(
		summaryModel,
		featureTogglesModel,
	);

	const trackingDebugMode = TrackingDebugModeFactory.create(featureTogglesModel);

	const summaryStore = SummaryStoreFactory.create({
		featureTogglesModel,
		globalDynamicStore,
		globalStaticModel,
		magicModal,
		summaryModel,
		pageStore,
		productModel,
		productTrackingStore,
		trackingDebugMode,
	});

	const magicDeliveryModel = createMagicDeliveryModel({
		summaryModel,
		trLinkEventCompType: 'product summary',
		trLinkEventCompName: productModel.summaryTitle,
	});

	const magicDeliveryStore = createMagicDeliveryStore({
		featureTogglesModel,
		globalDynamicStore,
		magicDeliveryModel,
		summaryStore,
	});

	const recoItemWidth = 212;
	const recoItemHeight = 224;
	const recoPageWidth = recoItemWidth;
	const recoPageHeight = recoItemHeight;

	let productRecosStore;
	let productRecosPagerModel;

	if (!isEngage) {
		productRecosStore = createProductRecoStore(
			'rfkid_32',
			router.asPath,
			{
				globalDynamicModel,
				globalStaticModel,
				featureTogglesModel,
			},
			{
				sku: null,
				title: 'You May Also Like',
				analytics: {
					pageId: 'product',
				},
				maxItems: 18,
				forceNonSkuImage: true,
			},
		);

		productRecosPagerModel = new MagicPagerStore(recoPageWidth, recoPageHeight, recoItemWidth, recoItemHeight);
	}

	const productRecentsStore = createProductRecoStore(
		'rfkid_33',
		'',
		{
			globalDynamicModel,
			globalStaticModel,
			featureTogglesModel,
		},
		{
			sku: [],
			title: 'Recently Viewed',
			analytics: {
				pageId: 'product2_recently_viewed',
			},
			maxItems: 18,
		},
	);
	const productRecentsPagerModel = new MagicPagerStore(recoPageWidth, recoPageHeight, recoItemWidth, recoItemHeight);

	if (!isOnServer && productRecosStore) {
		const summaryRFKDisposer = reaction(
			() => summaryModel.reflektionSkuKey,
			(reflektionSkuKey) => {
				productRecosStore.model.setSku([reflektionSkuKey]);
				summaryRFKDisposer();
			},
		);
	}

	const productCommons = ProductCommonsModelFactory.create({
		breadcrumbModels,
		productModel,
		summaryModel,
		type: pageType,
	});

	const selectorConfigModel = { selectedProduct: productModel };

	const deliveryMainStore = DeliveryMainStoreFactory.create(productCommons, globalDynamicStore);

	// roll up product stores and models here
	return {
		breadcrumbModels,
		canonicalUrl,
		categoryName,
		deliveryMainStore,
		magicDeliveryModel,
		magicDeliveryStore,
		mediaSetDetailModel,
		mediaSetDetailStore,
		productCommons,
		productModel,
		productRecentsStore,
		productRecentsPagerModel,
		productRecosPagerModel,
		productRecosStore,
		productTrackingStore,
		selectorConfigModel,
		stickySummaryModel,
		subcategoryName,
		summaryModel,
		summaryStore,
		errorsModel,
	};
};

export const ProductClearanceContextProvider = ({
	pageProps = {},
	pageProps: {
		children,
	} = {},
	productContext = {},
}) => {
	return <productContext.Provider value={productClearanceContext(pageProps)}>{children}</productContext.Provider>;
};
