import React from 'react';
import classNames from 'classnames';

import type { MediaSetStore } from '~/media-set/Stores/MediaSet.store';
import type { Media } from '~/media-set/Models/Media.model';
import type { MediaSet } from '~/media-set/Models/MediaSet.model';
import { MediaMainButton } from '~/media-set/Components/MediaMainButton';
import { MediaMainCounter } from '~/media-set/Components/MediaMainCounter';
import { MediaMainLinks } from '~/media-set/Components/MediaMainLinks';
import { noop } from '~/util/noop';
import styles from '#/media-set/media-main.module.scss';
import { wistiaVideoMediaType } from '~/components/wistia-player/Types/constants';

export interface MediaProps {
	hideCounter?: boolean,
	hideMediaMainLinks?: boolean,
	imagePreset?: string,
	isZoomed?: boolean,
	mainHeight?: number,
	mainWidth?: number,
	mediaComponentsOverride?: object,
	mediaModel: Media,
	mediaSetModel: MediaSet,
	mediaSetStore?: MediaSetStore,
	onClick?: Function,
	openImageInNewTab?: boolean,
	showCounterForMedium?: boolean,
	showDimensions?: boolean,
	showDriftZoom?: boolean,
	showFullScreenButton?: boolean,
}

export const MediaMain = (props: MediaProps) => {
	const {
		hideCounter = false,
		hideMediaMainLinks = false,
		imagePreset = undefined,
		isZoomed = false,
		mainHeight = 0,
		mainWidth = 0,
		mediaComponentsOverride = {},
		mediaModel,
		mediaSetModel,
		mediaSetModel: {
			mediaModelsCount = 0,
		},
		mediaSetStore,
		onClick = noop,
		openImageInNewTab = false,
		showCounterForMedium = false,
		showDimensions = false,
		showDriftZoom = false,
		showFullScreenButton = false,
	} = props;

	return (
		<div className={classNames(styles['media-main-container'], {
			'tw-block': mediaModel?.type === wistiaVideoMediaType
		})}>
			<div
				className={classNames(styles['media-main'], {
					'tw-items-start': mediaModel?.type === wistiaVideoMediaType
				})}
				data-qa="media-main"
			>
				<MediaMainButton
					imagePreset={imagePreset}
					isZoomed={isZoomed}
					mainHeight={mainHeight}
					mainWidth={mainWidth}
					mediaComponentsOverride={mediaComponentsOverride}
					mediaModel={mediaModel}
					mediaSetModel={mediaSetModel}
					mediaSetStore={mediaSetStore}
					onClick={onClick}
					openImageInNewTab={openImageInNewTab}
					showDimensions={showDimensions}
					showDriftZoom={showDriftZoom}
					showFullScreenButton={showFullScreenButton} />
				{
					!hideMediaMainLinks && (
						<MediaMainLinks mediaModel={mediaModel} />
					)
				}
			</div>
			{!hideCounter && <MediaMainCounter
				mediaModel={mediaModel}
				mediaModelsCount={mediaModelsCount}
				showCounterForMedium={showCounterForMedium}
			/>}
		</div>
	);
};
