import { computed, makeObservable } from 'mobx';

class ComfortSpectrum {
	metersModel

	comfortSpectrumKeys

	constructor() {
		makeObservable(this, {
			hasMeterModels: computed,
			hasComfortSpectrumKeys: computed,
		});
	}

	get hasMeterModels() {
		return Boolean(this.metersModel.length);
	}

	get hasComfortSpectrumKeys() {
		return Boolean(this.comfortSpectrumKeys.length);
	}
}

export const ComfortSpectrumModelFactory = ({
	create: ({
		metersModel = [],
		comfortSpectrumKeys = [],
	}) => {
		const comfortSpectrum = new ComfortSpectrum();

		Object.assign(comfortSpectrum, {
			metersModel,
			comfortSpectrumKeys,
		});

		return comfortSpectrum;
	},
});
