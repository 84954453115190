const curalateFilterOptions = [
	{ text: 'Outdoor', value: 'label:outdoor' },
	{ text: 'Dining & Kitchen', value: 'label:dining' },
	{ text: 'Bath', value: 'label:bath' },
	{ text: 'Living', value: 'label:living' },
	{ text: 'Bedroom', value: 'label:bedroom' },
	{ text: 'Kids', value: 'label:kids' },
	{ text: 'Entryway', value: 'label:entryway' },
	{ text: 'Lighting', value: 'label:lighting' },
	{ text: 'Home Decor', value: 'label:home_decor' },
	{ text: 'Rugs', value: 'label:rugs' },
	{ text: 'Storage', value: 'label:storage' },
	{ text: 'Office', value: 'label:office' },
	{ text: 'Business', value: 'label:business' },
	{ text: 'Pets', value: 'label:pets' },
];

const curalateImageSizes = {
	SMALL_SQUARE: {
		key: 'smallSquare',
		dimensions: {
			width: 150,
			height: 150,
		},
	},
	MEDIUM_SQUARE: {
		key: 'mediumSquare',
		dimensions: {
			width: 300,
			height: 300,
		},
	},
	LARGE_SQUARE: {
		key: 'largeSquare',
		dimensions: {
			width: 600,
			height: 600,
		},
	},
	XLARGE_SQUARE: {
		key: 'extraLargeSquare',
		dimensions: {
			width: 1080,
			height: 1080,
		},
	},
};

export {
	curalateFilterOptions,
	curalateImageSizes,
};
