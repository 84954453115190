import { observer } from 'mobx-react';
import React from 'react';

import styles from '#/product/standard/details/details-attributes.module.scss';
import stylesDetail from '#/product/standard/details/details.module.scss';

export const DetailTitle = observer((props) => {
	const {
		detail: {
			label = '',
		} = {},
	} = props;

	return (
		<div className={`${styles['detail']} ${stylesDetail['detail']} detail-title`}>
			<strong>{label}</strong>
		</div>
	);
});
