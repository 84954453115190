import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react';

import { CuralateUploadSelect } from '~/util/curalate/Components/CuralateUploadSelect';
import { isOnServer } from '~/global/global.constants';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

export const CuralateUploadFacebook = observer((props) => {
	const {
		store,
		store: {
			facebookFiles,
			facebookToken,
			setFacebookToken,
		},
	} = props;

	const facebookAuthIframeRef = useRef();

	const clickHandler = () => {
		if (facebookAuthIframeRef.current) {
			facebookAuthIframeRef.current.contentWindow.postMessage('crl8InitAuth', 'https://cdn.curalate.com');
		}
	};

	const messageHandler = (event) => {
		const {
			data: {
				crl8: {
					fb
				} = {},
			} = {},
			origin,
		} = event;

		if (origin.indexOf('https://cdn.curalate.com') > -1 && fb) {
			setFacebookToken(fb);
		}
	};

	useEffect(() => {
		if (!facebookToken && !isOnServer) {
			window.addEventListener('message', messageHandler);
		}

		return function () {
			window.removeEventListener('message', messageHandler);
		};
	}, []);

	if (!facebookToken) {
		return (
			<div className={styles['curalate-upload-facebook']}>
				<button
					className={`${styles['curalate-upload-facebook-button']} Button`}
					onClick={clickHandler}
					type={'button'}
				>
					Connect to Facebook
				</button>
				<iframe
					className={styles['curalate-upload-facebook-auth']}
					ref={facebookAuthIframeRef}
					src={'https://cdn.curalate.com/auth/facebook/auth.html?baseUrl=https%3A%2F%2Fwww.facebook.com%2Fv2.12%2Fdialog%2Foauth&clientId=314206488716604&redirectUri=https%3A%2F%2Fcdn.curalate.com%2Fauth%2Ffacebook%2Fauth-response.html&scope=email,user_photos,user_videos&state=&responseType=token'}
					tabIndex={-1}
					title={'Facebook Authorization'}
				/>
			</div>
		);
	}

	return (
		<div className={styles['curalate-upload-facebook']}>
			<CuralateUploadSelect files={facebookFiles} store={store} />
		</div>
	);
});
