import { observer } from 'mobx-react';
import React from 'react';

import type { ChangeEvent } from 'react';

import { kebabCase } from '~/util/kebabCase';

import styles from '~/product/casegoods/selector/Components/frame-modal/SelectorSelectFrameModal.module.scss';

export const SelectorSelectFrameModal = observer(({
	selectedSelector: {
		label = '',
		selectedSelectorValue: {
			peerId: selectedPeerId = '',
		} = {},
		selectorValues = [],
	} = {},
	selectorConfigFrameModalStore = {},
	workspaceProductStoreFrameModal = {},
}) => {
	function handleChange(event: ChangeEvent<HTMLSelectElement>) {
		const {
			target: {
				value = '',
			} = {},
		} = event;

		const selectorValueToSelect = selectorValues.find(({ peerId = '' }) => peerId === value);

		(selectorConfigFrameModalStore as any).selectQuestionSelectorValue(selectorValueToSelect, true);

		(workspaceProductStoreFrameModal as any).getWorkspaceProductImagesData();
	}

	return (
		<div className={styles['selector-select-frame-modal']}>
			<span className={styles['selector-select-frame-modal-label']}>{label}:</span>
			<fieldset>
				<legend className="tw-sr-only">Choose a {label}</legend>
				<select
					className={styles['selector-select-frame-modal-select']}
					data-qa={`${kebabCase(label)}-selector-value`}
					onChange={handleChange}
					value={selectedPeerId}
				>
					{
						selectorValues.map((selectorValue, index = 0) => {
							const {
								peerId = '',
								title = '',
							} = selectorValue;

							return (
								<option
									key={`selector-value-${index}`}
									value={peerId}
								>
									{title}
								</option>
							);
						})
					}
				</select>
			</fieldset>
		</div>
	);
});
