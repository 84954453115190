import { observable, computed, makeObservable } from 'mobx';

export class ProductCommons {
	links;

	breadcrumbModels;

	printModule;

	productGroupModel;

	_productModel;

	_summaryPromos;

	summaryModel;

	selectorConfigModel;

	selectedSelectorValues;

	_displayName;

	_editorialDescription;

	dimensionPromos;

	type

	constructor() {
		makeObservable(this, {
			links: observable,
			breadcrumbModels: observable,
			printModule: observable,
			productGroupModel: observable,
			_productModel: observable,
			_summaryPromos: observable,
			summaryModel: observable,
			selectorConfigModel: observable,
			selectedSelectorValues: observable,
			_displayName: observable,
			_editorialDescription: observable,
			dimensionPromos: observable,
			editorialDescription: computed,
			displayName: computed,
			productModel: computed,
			summaryPromos: computed,
		});
	}

	get editorialDescription() {
		if (this._editorialDescription) {
			return this._editorialDescription;
		}

		if (this.productGroupModel) {
			return this.productGroupModel.editorialDescription;
		}

		return null;
	}

	get displayName() {
		if (this._displayName) {
			return this._displayName;
		}

		if (this.productGroupModel) {
			return this.productGroupModel.name;
		}

		return null;
	}

	get productModel() {
		if (this._productModel) {
			return this._productModel;
		}

		if (this.selectorConfigModel.selectedProduct) {
			return this.selectorConfigModel.selectedProduct;
		}

		return null;
	}

	get summaryPromos() {
		if (this._summaryPromos) {
			return this._summaryPromos;
		}

		if (this.productGroupModel.summaryPromos) {
			return this.productGroupModel.summaryPromos;
		}

		return [];
	}
}

export const ProductCommonsModelFactory = ({
	create: ({
		links = {},
		breadcrumbModels = [],
		collectionSearchSegment,
		printModule = {},
		productGroupModel = {},
		productModel,
		selectorConfigModel = {},
		summaryModel = {},
		type = '',
	}) => {
		const productPage = new ProductCommons();

		Object.assign(productPage, {
			links,
			breadcrumbModels,
			collectionSearchSegment,
			printModule,
			productGroupModel,
			_productModel: productModel,
			selectorConfigModel,
			summaryModel,
			type,
		});

		return productPage;
	},
});
