import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { useDrift } from '~/hooks/useDrift';
import { scaleLayeredImage } from '~/util/scaleLayeredImage';
import { addToUrl } from '~/util/addToUrl';

export const MediaProduct = observer((props = {}) => {
	const {
		imageHeight = 0,
		imagePreset = '',
		imageWidth = 0,
		isMediaThumbnail = false,
		mediaModel: {
			productAltText = '',
			productImageUrl = '',
			productImageUrlWithDimensions = '',
		} = {},
		shouldLoad = false,
		showDimensions = false,
		showDriftZoom = false,
	} = props;
	const mediaProductRef = useRef();
	useDrift({ driftRef: mediaProductRef, showDriftZoom });
	const productImageUrlToUse = showDimensions ? productImageUrlWithDimensions : productImageUrl;
	const src = addToUrl(productImageUrlToUse, imagePreset);
	const zoomSrc = scaleLayeredImage(src, imageWidth * 2, imageHeight * 2);
	return (
		<S7Image
			alt={productAltText}
			className={classNames({ 'show-drift': showDriftZoom })}
			data-zoom={showDriftZoom ? zoomSrc : null}
			height={imageHeight}
			priority={shouldLoad}
			ref={mediaProductRef}
			sizes={!isMediaThumbnail ? `(max-width: 900px) 100vw, ${imageWidth}px` : null}
			src={src}
			width={imageWidth}
		/>
	);
});
