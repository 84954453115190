import {
	computed, makeObservable, observable, action,
} from 'mobx';

import { BROKEN_UI, ifEmptyUsePlaceholderText, LAUNCH_PLACEHOLDER_TEXT } from '~/util/launchUtils';
import { sanitize } from '~/util/sanitizeString';

export class ProductViewed {
	articleNumber: string | number

	product: any

	mediaSetDetailModel: any

	categoryName: string

	productUrlOverride: string

	subcategoryName: string

	_stockedStatus: string

	_name: string

	productGroupKey: string

	variantId: string

	insert: string

	message?: string

	constructor(product: any = {}, articleNumber: string | number, stockedStatus: string = '', categoryName: string = '', subcategoryName: string = '', productUrlOverride: string = '', name: string = '', productGroupKey: string = '', variantId: string = '', insert = '', message?: string) {
		this.product = product;
		this.message = message;
		this.articleNumber = articleNumber;
		this._stockedStatus = stockedStatus;
		this.categoryName = categoryName;
		this.subcategoryName = subcategoryName;
		this.productUrlOverride = productUrlOverride;
		this._name = name;
		this.productGroupKey = productGroupKey;
		this.variantId = variantId;
		this.insert = insert;

		makeObservable(this, {
			id: computed,
			name: computed,
			product: observable.ref,
			url: computed,
			url2: computed,
			webCat: computed,
			webSub: computed,
			webMessage: computed,
			equalTo: action.bound,
			insert: observable,
		});

	}

	get id() {
		return this.articleNumber || this.product.articleNumber || 0;
	}

	get name() {
		return (this._name && sanitize(this._name)) || LAUNCH_PLACEHOLDER_TEXT;
	}

	get stockedStatus() {
		return this._stockedStatus.length ? this._stockedStatus : `${BROKEN_UI}: ${this.id}`;
	}

	get urlString() {
		return this.productUrlOverride?.length ? this.productUrlOverride : this.product.url || '';
	}

	get url() {
		return this.urlString.substring(0, 255);
	}

	get url2() {
		return ifEmptyUsePlaceholderText(this.urlString.substring(255, 255));
	}

	get webCat() {
		return this.categoryName;
	}

	get webSub() {
		return this.subcategoryName;
	}

	get webMessage() {
		const webMessageToUse = this.message || this.product?.status?.text;
		if (webMessageToUse) {
			return ifEmptyUsePlaceholderText(webMessageToUse);
		}
		return LAUNCH_PLACEHOLDER_TEXT;
	}

	get trackingJson() {
		return {
			id: this.id,
			name: this.name,
			thumb: LAUNCH_PLACEHOLDER_TEXT,
			thumb2: LAUNCH_PLACEHOLDER_TEXT,
			url: this.url,
			url2: this.url2,
			webCat: this.webCat,
			webSub: this.webSub,
			webMessage: this.webMessage,
			stockedStatus: this.stockedStatus,
			productGroupKey: this.productGroupKey,
			variantId: this.variantId,
			insert: this.insert,
		};
	}

	get comparatorString() {
		return this.id;
	}

	equalTo(comparatorString: string) {
		return this.comparatorString === comparatorString;
	}
}
