import { observer } from 'mobx-react';
import React from 'react';

import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { kebabCase } from '~/util/kebabCase';

export const SelectorHeadingDownload = observer((props) => {
	const {
		selectedSelector: {
			label = '',
		} = {},
	} = props;

	return (
		<div
			className="selector-heading-insert selector-heading"
			data-qa={`${kebabCase(label)}-selector-heading`}
		>
			<div className="selector-heading-label-container">
				<SelectorHeadingLabel label={label} />
			</div>
		</div>
	);
});
