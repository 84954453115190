import { computed, observable, makeObservable } from 'mobx';

import type { ISelectorValueQuestion } from '~/product/sectionals/selector/Interfaces/SelectorValueQuestion.interface';

import { s7ImagePath } from '~/global/global.constants';

export class SelectorValueQuestionModel implements ISelectorValueQuestion {
	_links: any

	articles: Array<any>

	detailsModel: Array<any>

	filters: any

	image: any

	parentLabel: string

	parentType: string

	pricesByArticle: any

	questionAnswers: Array<any>

	selected: boolean

	selectorDetailsModel: Array<any>

	stocked: boolean

	subtitle: string

	title: string

	constructor({
		_links = {},
		articles = [],
		detailsModel = [],
		filters = {},
		image = 'noimage',
		parentLabel = '',
		parentType = '',
		pricesByArticle = {},
		questionAnswers = [],
		selected = false,
		selectorDetailsModel = [],
		stocked = false,
		subtitle = '',
		title = '',
	}: any = {}) {
		makeObservable(this, {
			selected: observable,
			hasSelectorDetails: computed,
			materialAPIHref: computed,
			question: computed,
			questionAnswerParams: computed,
			selectorDetailImage: computed,
			selectorDetailLink: computed,
			selectorDetailsToRender: computed,
		});

		this._links = _links;
		this.articles = articles;
		this.detailsModel = detailsModel;
		this.filters = filters;
		this.image = image;
		this.parentLabel = parentLabel;
		this.parentType = parentType;
		this.pricesByArticle = pricesByArticle;
		this.questionAnswers = questionAnswers;
		this.selected = selected;
		this.selectorDetailsModel = selectorDetailsModel;
		this.stocked = stocked;
		this.subtitle = subtitle;
		this.title = title;
	}

	get hasSelectorDetails(): boolean {
		return Boolean(this.selectorDetailsModel.length);
	}

	get imageUrl(): string {
		const {
			imageSrc = '',
		} = this.image;

		return `${s7ImagePath}/${imageSrc}`;
	}

	get materialAPIHref(): string {
		const {
			materialDetails: {
				href = '',
			} = {},
		} = this._links;

		return href;
	}

	// shim to use legacy detail material renderer
	get question(): string {
		const firstFilteredQuestionAnswer = this.questionAnswers.find(({ group = '' }) => (group !== 'FABRIC' && group !== 'LEATHER'));
		return firstFilteredQuestionAnswer.question;
	}

	get questionAnswerParams(): string {
		return this.questionAnswers.map(({
			answer = '',
			question = '',
		}) => {
			return `${question}=${answer}`;
		}).join('&');
	}

	get selectorDetailImage(): any {
		return this.selectorDetailsModel.find(({ type = '' }) => type === 'image');
	}

	get selectorDetailLink(): any {
		return this.selectorDetailsModel.find(({ type = '' }) => type === 'link');
	}

	get selectorDetailsToRender(): Array<any> {
		return this.selectorDetailsModel.filter(({ type = '' }) => type !== 'image');
	}
}

export const SelectorValueQuestionModelFactory = ({
	create: (data: any = {}) => {
		return new SelectorValueQuestionModel(data);
	},
});
