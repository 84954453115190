import { createMediaSetModel } from '~/media-set/Helpers/MediaSet.init';
import { createMediaProductSectionalsModel } from '~/product/sectionals/media-set/Helpers/MediaProductSectionals.init';
import { createMediaWorkspaceSectionalsModel } from '~/product/sectionals/media-set/Helpers/MediaWorkspaceSectionals.init';

export const createMediaSetDetailSectionalsModel = ({
	selectorConfigModel = {},
	workspaceModel = {},
}) => {
	const mediaProductSectionalsModel = createMediaProductSectionalsModel({
		mediaData: {
			caption: 'Image shows your materials but not your layout.',
		},
		selectorConfigModel,
	});

	const mediaWorkspaceSectionalsModel = createMediaWorkspaceSectionalsModel({
		disableClick: true,
		mediaData: {
			caption: 'Image shows your layout but not your material.',
		},
		workspaceModel,
	});

	const mediaSetDetailSectionalsModel = createMediaSetModel({
		mediaModelsHead: [
			mediaProductSectionalsModel,
			mediaWorkspaceSectionalsModel,
		],
	});

	return mediaSetDetailSectionalsModel;
};
