import React from 'react';
import { observer } from 'mobx-react';

import { isEngage } from '~/global/global.constants';
import { Recos } from '~/components/recos/Components/Recos';

export const InterstitialRecos = observer(({ store }) => {
	return (
		<Recos
			className={'tw-border-t-2'}
			defaultSlidesToShow={3}
			hasTopBorder={true}
			shouldShowRecos={!isEngage}
			shouldShowFourSquaresOnMobile={true}
			store={store}
		/>
	);
});
