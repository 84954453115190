import React from 'react';

import { s7ContentPath } from '~/global/global.constants';

export const DeliveryPackageIcon = () => {
	return (
		<img
			src={`${s7ContentPath}/icon_ups_box`}
			alt=""
			width="40"
			className="delivery-icon delivery-package"
		/>
	);
};
