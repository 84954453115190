import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/optional-features-saved/workspace-optional-features-saved.module.scss';

export const WorkspaceOptionalFeaturesSavedCasegoods = observer(() => {
	const {
		workspaceModel: {
			combinedNonDefaultInsertOptionsSelectorValues = [],
		} = {},
	} = useProductContext();

	const [shouldAnimateState, setShouldAnimateState] = useState(true);

	useEffect(() => {
		if (shouldAnimateState) {
			setTimeout(() => {
				setShouldAnimateState(false);
			}, 3000);
		}
	}, [shouldAnimateState]);

	useEffect(() => {
		setShouldAnimateState(true);
	}, [combinedNonDefaultInsertOptionsSelectorValues]);

	return (
		<div
			className={
				classNames(styles['workspace-optional-features-saved'], {
					[styles['workspace-optional-features-saved-animation']]: shouldAnimateState,
				})
			}
			data-qa="workspace-optional-features-saved"
		>
			Optional features saved
		</div>
	);
});
