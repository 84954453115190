import { isEngage } from '~/global/global.constants';
import { StyliticsClickEventTrackingModel } from '~/tracking/vendor-events/stylitics/style-click/Models/StyliticsClickEvent.tracking.model';
import { StyliticsPresentEventTrackingModel } from '~/tracking/vendor-events/stylitics/style-present/Models/StyliticsPresentEvent.tracking.model';
import { StyliticsViewEventTrackingModel } from '~/tracking/vendor-events/stylitics/style-view/Models/StyliticsViewEvent.tracking.model';

export class StyliticsEventsTrackingStore {
	tsWindow = global as any;

	_styliticsSku: string | null;

	constructor() {
		this._styliticsSku = null;
	}

	get trackedStyliticsSku() {
		return this._styliticsSku;
	}

	trackStyliticsClick({
		id, items, material, styleKey,
	}: {
		id: string, items: Array<string>, material: string | null, styleKey: string,
	}) {
		if (!this.tsWindow.eventDataLayer || isEngage) {
			return;
		}

		const styliticsClickeEventTrackingModel = new StyliticsClickEventTrackingModel(styleKey, id, items, material);

		this.pushToEventDataLayer(styliticsClickeEventTrackingModel.trackingJson);
	}

	trackStyliticsPresent({
		styliticsSku, styleKey, material, count,
	}: {
		styliticsSku: string, styleKey: string, material: string | null, count: number,
	}) {
		if (!this.tsWindow.eventDataLayer || isEngage) {
			return;
		}

		this._styliticsSku = styliticsSku;

		const styliticsPresentEventTrackingModel = new StyliticsPresentEventTrackingModel(styleKey, material, count);

		this.pushToEventDataLayer(styliticsPresentEventTrackingModel.trackingJson);
	}

	trackStyliticsView({
		id, items, material, styleKey,
	}: {
		id: string, items: Array<string>, material: string | null, styleKey: string,
	}) {
		if (!this.tsWindow.eventDataLayer || isEngage) {
			return;
		}

		const styliticsViewEventTrackingModel = new StyliticsViewEventTrackingModel(styleKey, id, items, material);

		this.pushToEventDataLayer(styliticsViewEventTrackingModel.trackingJson);
	}

	pushToEventDataLayer(json: any) {
		this.tsWindow.eventDataLayer.push(json);
	}
}
