import React, { useRef } from 'react';
import { observer } from 'mobx-react';
/*
	The below lib was included to properly trigger a download for a
	generic base64 image blob that was generated from a canvas that
	contains crossorigin images.

	The canvas is technically tainted because the images are pulled
	from s7 so simply linking direct to the base64 url will be blocked.
*/
import download from 'downloadjs';

import {
	getWorkspaceCanvasFrameImages,
	getWorkspaceCanvasInsertImages,
	createImageBlobStack,
	composeWorkspaceBlob,
} from '~/product/casegoods/selector/Utils/SelectorDownloadCasegoods.utils';
import { downloadImageWorkspaceCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/workspace-casegoods.constants';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { PAGE_CONTENT_PLACEHOLDER_TEXT } from '~/util/launchUtils';
import { sanitize } from '~/util/sanitizeString';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

export const SelectorDownload = observer(() => {
	const {
		workspaceModel = {},
		workspaceModel: {
			settings: {
				renderWorkspaceProductsAsStacks = false,
			} = {},
			sortedWorkspaceProducts = [],
			workspaceProductsDepth = 0,
			workspaceProductsHeight = 0,
			workspaceProductsWidth = 0,
		} = {},
		selectorConfigModel: {
			productModels: frameProductModels = [],
			settings: {
				downloadSelectorHeadingTitle = '',
			} = {},
		} = {},
	} = useProductContext();

	const {
		linkEventStore
	} = useGlobalContext();

	const workspaceDimensions = {
		workspaceProductsDepth,
		workspaceProductsHeight,
		workspaceProductsWidth,
	};
	const [firstFrameProductModel = {}] = frameProductModels;
	const { imageCollectionName = '' } = firstFrameProductModel;

	const workspaceCanvas = useRef(null);

	function trackFileDownload(fileName) {
		const linkEventTrackingData = {
			trLinkEventCompName: imageCollectionName,
			trLinkEventName: 'download a photo',
			trLinkEventCompType: PAGE_CONTENT_PLACEHOLDER_TEXT,
			trLinkEventType: LinkEventTypes.DOWNLOAD,
			trLinkEventHref: sanitize(fileName),
		};
		linkEventStore.trackLinkEvent(linkEventTrackingData);
	}

	// Set up context and download the canvas as image
	function saveWorkspaceImage(shouldDownload) {
		let downloadImage = downloadImageWorkspaceCasegoods[imageCollectionName];

		if (!downloadImage) {
			downloadImage = downloadImageWorkspaceCasegoods.DEFAULT;

			console.warn(`selector download image not found for imageCollectionName ${imageCollectionName}`);
		}

		const {
			mimeType,
			fileName,
		} = downloadImage;

		workspaceCanvas.current.height = 0;
		workspaceCanvas.current.width = 0;

		if (renderWorkspaceProductsAsStacks) {
			createImageBlobStack({
				workspaceCanvas: workspaceCanvas.current,
				workspaceModel,
			})
				.then(() => {
					const imageBlob = workspaceCanvas.current.toDataURL(mimeType, 0.7);

					if (shouldDownload) {
						download(imageBlob, fileName, mimeType); // Extension required by iOS despite the MIME type
						trackFileDownload(fileName);
					}
				});
		} else {
			getWorkspaceCanvasFrameImages(sortedWorkspaceProducts)
				.then((workspaceFrameImages) => {
					getWorkspaceCanvasInsertImages(sortedWorkspaceProducts)
						.then((workspaceInsertImages) => {
							const imageBlobs = composeWorkspaceBlob(
								workspaceCanvas.current,
								workspaceFrameImages,
								workspaceInsertImages,
								imageCollectionName,
								workspaceDimensions,
							);

							if (shouldDownload) {
								download(imageBlobs, fileName, mimeType); // Extension required by iOS despite the MIME type
								trackFileDownload(fileName);
							}
						});
				});
		}
	}

	function handleClick() {
		saveWorkspaceImage(true);
	}

	return (
		<div className="selector-download tw-pb-5">
			<button
				data-tr-link-event-track={false}
				data-qa="download-photo-button"
				className="ButtonAnchor"
				disabled={!sortedWorkspaceProducts.length}
				onClick={handleClick}
			>Download a photo</button> {downloadSelectorHeadingTitle}

			<div className="tw-sr-only">
				<canvas ref={workspaceCanvas} width={0} height={0} />
			</div>
		</div>
	);
});
