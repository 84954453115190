import axios from 'axios';
import { reaction } from 'mobx';

import { apiUrl, isOnServer } from '~/global/global.constants';
import { addToUrl } from '~/util/addToUrl';

class SelectorMaterialCombinedStore {
	summaryModel;

	materialCombinedSelectorModel;

	selectorConfigStore;

	getMaterialData(href, params) {
		if (!href) return new Promise((resolve) => { resolve(); });
		const {
			workspaceModel: {
				selectorConfigModel: {
					defaultConfigModel: {
						config: defaultConfig = {},
					} = {},
				} = {},
			} = {},
		} = this.summaryModel;
		const {
			combinedMaterialSelectedSelectorConfig: currentConfig,
			combinedMaterialSelectorsConfigurationParams: questions = {},
			materialSelectorModels = [],
		} = this.materialCombinedSelectorModel;
		const paramsToUse = params || Object.entries({ ...defaultConfig, ...currentConfig }).reduce(
			(accumulator, [question, answer]) => `${accumulator}&${question}=${answer}`,
			'',
		);
		const url = addToUrl(`${apiUrl}${href}`, `${paramsToUse}&${questions}`);
		return axios.get(url).then(({ data = [] }) => {
			data.forEach((dataProp) => {
				let notAvailable = false;
				const matchingValues = materialSelectorModels.find(model => model.selectedSelectorValue.question === dataProp.key)?.selectorValues;
				if (!matchingValues?.length) return;
				matchingValues.forEach((v = {}) => {
					v.available = dataProp.values.find(d => d.value === v.answer)?.available || false; // SIDE EFFECT: setting material selector value's availability to it's availability value from the api
					if (!v.available && v.selected) notAvailable = true;
				});
				// IF a material selector is selected but not available for current configuration, THEN find the first available material selector in the list OR jus the first material selector in the list
				if (notAvailable) this.selectorConfigStore.selectMaterialSelectorValue(matchingValues.find(({ available = false }) => available) || matchingValues[0]);
			});
		})
			.catch(error => console.info(error));
	}
}

export const SelectorMaterialCombinedStoreFactory = ({
	create: ({
		isPreconfiguredWorkspace = false,
		summaryModel = {},
		materialCombinedSelectorModel = {},
		selectorConfigModel: {
			settings: {
				useMaterialsEndpoint = false,
			} = {},
		} = {},
		selectorConfigStore = {},
	}) => {
		let materialSelectorValueChangedDisposers = [];
		const { materialSelectorModels = [] } = materialCombinedSelectorModel;
		const selectorMaterialCombinedStore = new SelectorMaterialCombinedStore();
		Object.assign(selectorMaterialCombinedStore, {
			summaryModel,
			materialCombinedSelectorModel,
			materialSelectorValueChangedDisposers,
			selectorConfigStore,
		});
		const materialSelectorModelToUse = materialSelectorModels.length ? [materialSelectorModels[0]] : [];
		if (!isOnServer && useMaterialsEndpoint) {
			materialSelectorValueChangedDisposers = materialSelectorModelToUse.map((materialSelectorModel = {}) => {
				return reaction(() => materialSelectorModel.selectedSelectorValue, (newSelectedSelectorValue) => {
					const hrefToUse = selectorConfigStore?.selectorConfigModel?.selectedProduct?.links?.AVAILABLE_CONFIGURATIONS?.href || selectorConfigStore?.selectorConfigModel?.productModels[0]?.links.AVAILABLE_CONFIGURATIONS.href;
					selectorMaterialCombinedStore.getMaterialData(hrefToUse, newSelectedSelectorValue.questionAnswerParams);
				}, { delay: 100, fireImmediately: isPreconfiguredWorkspace });
			});
		}
		return selectorMaterialCombinedStore;
	},
});
