import axios from 'axios';
import { action, makeObservable, observable } from 'mobx';

import { isOnServer } from '~/global/global.constants';
import { AddProductToCartEvent } from '~/product/common/Models/AddToCartEvent.tracking';
import { NewCartEvent } from '~/product/common/Models/NewCartEvent.tracking.model';

import { ProductViewEvent } from '~/product/common/Models/ProductViewEvent.tracking.model';
import { TrackedCart } from '~/product/common/Models/TrackedCart.tracking.model';
import { TrackedProduct } from '~/product/common/Models/TrackedProduct.tracking';
import { TrackingDebugModeFactory } from '~/tracking/debug/Tracking.debugMode';
import { FeatureTogglesModel } from '~/util/feature-toggles/Models/FeatureToggles.model';
import { IAddedToCartProductAnalytics, IAddedToCartProducts } from '~/product/common/Interfaces/IAddToCart.tracking';

export class ProductEventsTrackingStore {
	mediaSetDetailModel: any;

	previousTrackingJsonStrings: {
		[key: string]: string
	};

	summary: any;

	debugMode: any;

	constructor(summary: any, debugMode: any) {
		makeObservable(this, {
			summary: observable.ref,
			trackAddToCart: action.bound,
			trackProductView: action.bound,
		});

		this.debugMode = debugMode;
		this.summary = summary;
		this.previousTrackingJsonStrings = {};
	}

	trackNewCartCreated(href: string | undefined) {
		if (href) {
			const event = new NewCartEvent();
			this.pushToEventDataLayer(event);

			// this tells the server that this event has now been tracked
			axios.delete(href);
		}
	}

	trackAddToCart(addToCartData: IAddedToCartProductAnalytics) {
		const tsWindow = window as any;
		if (!isOnServer && tsWindow?.eventDataLayer) {
			const {
				addedProductsAnalytics: {
					addedProducts = [],
				},
			} = addToCartData;

			const products = this.createTrackedProducts(addedProducts);
			const reflektion = this.getReflektionData(addedProducts);
			const variantIds = this.getVariantIdsData(addedProducts);
			const cart = new TrackedCart(addToCartData, products);
			const event = new AddProductToCartEvent(addToCartData, products, cart, reflektion, variantIds);

			if (cart.wasNewCartCreated) {
				this.trackNewCartCreated(cart.cancelNewCartHref);
			}

			this.pushToEventDataLayer(event);
		}
	}

	trackProductView(
		wasSuccessful: boolean = true,
		// optional
		{ trackAllWorkspaceProducts = false, checkForBackToBackDuplicates = true, ignoreReflektion = false } = {},
	) {
		const tsWindow = window as any;
		if (!isOnServer && tsWindow?.eventDataLayer) {
			const event = new ProductViewEvent(
				this.summary,
				wasSuccessful,
				trackAllWorkspaceProducts,
				checkForBackToBackDuplicates,
				ignoreReflektion,
			);

			if (event.products.length > 0) {
				this.pushToEventDataLayer(event);
			}
		}
	}

	trackMultiOptionsProductView(changedProductIndex: number, wasSuccessful: boolean = true) {
		const tsWindow = window as any;
		if (!isOnServer && tsWindow?.eventDataLayer && Number.isInteger(changedProductIndex)) {
			const changedSummary = this.summary.summaryModels[changedProductIndex];
			const scm = changedSummary?.selectorConfigModel;
			const changedSummaryAnalytics = changedSummary?.analytics || [];

			if (changedSummaryAnalytics.length) {
				changedSummary.reflektionSkuKey = changedSummary.analytics[0].reflektionSkuKey || null;
			}

			if (scm && !scm.hasUnselectedSelectors) {
				const event = new ProductViewEvent(
					changedSummary,
					wasSuccessful,
				);
				this.pushToEventDataLayer(event);
			}
		}
	}

	getReflektionData(addedProducts: IAddedToCartProducts[]): Array<string> {
		if (!addedProducts) {
			return [];
		}

		const cartProductsToUse = addedProducts.filter((addedProduct: IAddedToCartProducts) => addedProduct.vendor);

		return cartProductsToUse.map((cartProduct: any) => {
			const {
				vendor: {
					reflektion = [],
				} = {},
			} = cartProduct;

			return reflektion;
		}).filter((arr: Array<any>) => arr.length > 0).flat();
	}

	getVariantIdsData(addedProducts: IAddedToCartProducts[]): Array<string> {
		if (!addedProducts) {
			return [];
		}
		const cartProductsToUse = addedProducts.filter((addedProduct: IAddedToCartProducts) => addedProduct.vendor);

		return cartProductsToUse.map((cartProduct: any) => {
			const {
				vendor: {
					variantIds = [],
				} = {},
			} = cartProduct;

			return variantIds;
		}).filter((arr: Array<any>) => arr.length > 0).flat();
	}

	createTrackedProducts(addedProducts?: IAddedToCartProducts[]): Array<TrackedProduct> {
		if (!addedProducts) {
			return [];
		}

		const cartProductsToUse = addedProducts.filter((addedProduct: IAddedToCartProducts) => Boolean(addedProduct.product));

		return cartProductsToUse.map((cartProduct: any) => {
			const {
				product = {},
				vendor = {},
			} = cartProduct;

			return new TrackedProduct(
				product,
				vendor,
			);
		});
	}

	pushToEventDataLayer(event: any) {
		const tsWindow = window as any;
		const {
			checkForBackToBackDuplicates = true,
			ignoreReflektion = false,
			comparatorString = '',
			equalTo,
			eventName = '',
			trackingJson = '',
		} = event;
		const trackingJsonToUse = ignoreReflektion ? { ...trackingJson, reflektionSkuKeys: null } : trackingJson;

		const previousEventComparator = this.previousTrackingJsonStrings[eventName] || '';
		if (!isOnServer && tsWindow?.eventDataLayer && (!checkForBackToBackDuplicates || !equalTo(previousEventComparator))) {
			if (this.debugMode && this.debugMode.shouldLogLinkEvents) {
				console.info(trackingJsonToUse);
			}
			this.previousTrackingJsonStrings[eventName] = comparatorString;
			tsWindow.eventDataLayer.push(trackingJsonToUse);
		}
	}
}

export const ProductEventsTrackingStoreFactory = {
	create: (
		summary: any,
		featureTogglesModel: FeatureTogglesModel,
	) => {
		const debugMode = TrackingDebugModeFactory.create(featureTogglesModel);
		const mainStore = new ProductEventsTrackingStore(
			summary,
			debugMode,
		);

		return mainStore;
	},
};
