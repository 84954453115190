import {
	action, observable, computed, makeObservable,
} from 'mobx';

type crumbId = string | number;

interface crumbInterface {
	title: string,
	url: string,
	// optional
	children?: breadcrumbsType,
	hideBreadcrumb?: boolean,
	id?: crumbId,
	type?: string,
	pageTitle?: string,
	parentId?: crumbId,
}

export type breadcrumbsType = crumbInterface[]

export const BreadcrumbTypes = Object.freeze({
	ROOT: 'ROOT',
	CATALOG: 'CATALOG',
	CATEGORY: 'CATEGORY',
	SUBCATEGORY: 'SUBCATEGORY',
	PRODUCT_GROUP: 'PRODUCT_GROUP',
	PRODUCT: 'PRODUCT',
	FAVORITES: 'FAVORITES',
});

export class BreadcrumbModel {
	// populated on create()
	data

	id

	// other observables
	breadcrumbs: breadcrumbsType = []

	constructor(data: breadcrumbsType | crumbInterface, id?: crumbId) {
		this.id = id;
		this.data = Array.isArray(data) ? data : [data];

		makeObservable(this, {
			// observables
			breadcrumbs: observable,
			data: observable,
			// computeds
			flatData: computed,
			isCategory: computed,
			isSubcategory: computed,
			resolvedBreadcrumbs: computed,
			// current crumb computeds
			currentCrumb: computed,
			title: computed,
			type: computed,
			pageTitle: computed,
			url: computed,
			// actions
			createBreadcrumbs: action.bound,
			flattenData: action.bound,
		});
	}

	// /////////////////////////
	// COMPUTEDS
	// /////////////////////////
	get flatData() {
		return this.flattenData(this.data);
	}

	get isCategory() {
		return Boolean(BreadcrumbTypes.CATEGORY === this.type);
	}

	get isSubcategory() {
		return Boolean(BreadcrumbTypes.SUBCATEGORY === this.type);
	}

	get resolvedBreadcrumbs() {
		this.breadcrumbs = []; // clear breadcrumbs
		return this.createBreadcrumbs(this.id);
	}

	// /////////////////////////
	// CURRENT CRUMB COMPUTEDS
	// /////////////////////////
	get currentCrumb() {
		return this.resolvedBreadcrumbs.slice().pop(); // last crumb in breadcrumbs is assumed to be currentCrumb
	}

	get pageTitle() {
		return this.currentCrumb?.pageTitle;
	}

	get title() {
		return this.currentCrumb?.title;
	}

	get type() {
		return this.currentCrumb?.type;
	}

	get url() {
		return this.currentCrumb?.url;
	}

	// /////////////////////////
	// ACTIONS
	// /////////////////////////
	createBreadcrumbs(id?: crumbId) {
		const selectedItem = this.flatData.filter((item: crumbInterface) => item.id === id);
		selectedItem.forEach((item) => {
			if (item.parentId) {
				this.createBreadcrumbs(item.parentId);
			}
			this.breadcrumbs.push(item);
		});

		return this.breadcrumbs;
	}

	flattenData(children: breadcrumbsType = [], parentId: crumbId = 0) { // Flatten the data so that it can be traversed in reverse by adding a reference to its parent
		const flatData = children.reduce((acc: breadcrumbsType, value: crumbInterface): breadcrumbsType => {
			let result = acc;
			value.parentId = parentId;
			if (!value.hideBreadcrumb) {
				acc.push(value);
			}
			if (value.children) {
				// If the parent node is marked hidden, make it's parent the parentId
				const parent = value.hideBreadcrumb ? value.parentId : value.id;
				result = acc.concat(this.flattenData(value.children, parent));
			}
			return result;
		}, []);
		return flatData;
	}
}

// INTENDED USE: for pages that utilizes a static front-end data file
// example of expected static data...
// {
// 	id: 1,
// 	title: 'title1',
// 	url: 'url-1',
// 	children: [
// 		{ id: 10, title: 'title10', url: 'url-1/url-10' },
// 		{ id: 20, title: 'title20', url: 'url-1/url-20' },
// 		{ id: 30, title: 'title30', url: 'url-1/url-30', children: [{ id: 301, title: 'title301', url: 'url-1/url-30/url-301' }] },
// 		{ id: 40, title: 'title40', url: 'url-1/url-40', children: [{ id: 401, title: 'title401', url: 'url-1/url-40/url-401' }] children: [{ id: 401, title: 'title401', url: 'url-1/url-40/url-401' }]},
// 	],
// };
export const BreadcrumbModelFactory = {
	create: (data: breadcrumbsType | crumbInterface, id: crumbId) => {
		const instance = new BreadcrumbModel(data, id);
		return instance.resolvedBreadcrumbs;
	},
};

// INTENDED USE: for used for pages that utilize an endpoint's payload to create breadcrumbs
// example of expected dynamic data...
// [
// 	{ title: 'title1', pageTitle: 'Title One', url: '/title-1', type: 'CATEGORY' },
// 	{ title: 'title2', pageTitle: 'Title Two', url: '/title-1/title-2', type: 'SUBCATEGORY' },
// 	{ title: 'title3', pageTitle: 'Title Three', url: '/title-1/title-2/title-3', type: 'PRODUCT_GROUP' },
// ]
export const DynamicBreadcrumbModelFactory = {
	create: (data: breadcrumbsType) => {
		return data.map(crumb => new BreadcrumbModel(crumb));
	},
};
