import { computed, makeObservable, observable } from 'mobx';

import { getParameterByName } from '~/util/getParameterByName';

class Stylitics {
	styliticsArticleNumber

	styliticsWidgetDidClick

	styliticsWidgetDidLoad

	styliticsWidgetDidView

	styliticsWidgetIsHidden

	styliticsWidgetInstance

	styliticsId

	summaryModel

	_numberOfOutfitsToShow

	_styleKey

	_material

	constructor() {
		makeObservable(this, {
			styliticsWidgetDidClick: observable,
			styliticsWidgetDidLoad: observable,
			styliticsWidgetDidView: observable,
			styliticsWidgetIsHidden: observable,
			styliticsSku: computed,
			styliticsTrackingObject: computed,
		});
	}

	set numberOfOutfitsToShow(num) {
		this._numberOfOutfitsToShow = num;
	}

	get numberOfOutfitsToShow() {
		return this._numberOfOutfitsToShow;
	}

	set styleKey(styleKey) {
		this._styleKey = styleKey;
	}

	get styleKey() {
		return this._styleKey;
	}

	set material(material) {
		this._material = material;
	}

	get material() {
		return this._material;
	}

	get styliticsSku() {
		return this.summaryModel.styliticsSku;
	}

	get styliticsTrackingObject() {
		return {
			articleNumber: this.styliticsArticleNumber,
			itemClicked: this.styliticsWidgetDidClick,
			widgetLoaded: this.styliticsWidgetDidLoad,
			widgetViewed: this.styliticsWidgetDidView,
		};
	}
}

export const StyliticsModelFactory = ({
	create: ({
		styliticsWidgetDidClick,
		styliticsWidgetDidLoad,
		styliticsWidgetDidView,
		styliticsWidgetIsHidden,
		styliticsWidgetInstance,
		styliticsId = 'stylitics',
		summaryModel = {},
	}) => {
		const stylitics = new Stylitics();

		const styliticsArticleNumber = getParameterByName('styliticsArticleNumber');

		Object.assign(stylitics, {
			styliticsArticleNumber,
			styliticsWidgetDidClick,
			styliticsWidgetDidLoad,
			styliticsWidgetDidView,
			styliticsWidgetIsHidden,
			styliticsWidgetInstance,
			styliticsId,
			summaryModel,
		});

		return stylitics;
	},
});
