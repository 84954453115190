import React from 'react';

import { WorkspaceControlHelpSectionals } from '~/product/sectionals/workspace/Components/controls/WorkspaceControlHelpSectionals';
import { WorkspaceControlRotateSectionals } from '~/product/sectionals/workspace/Components/controls/WorkspaceControlRotateSectionals';

import styles from '~/product/sectionals/workspace/Components/controls/workspace-controls.module.scss';

interface Props {
	isMainWorkspace: boolean
}

export const WorkspaceControlsSectionals = ({ isMainWorkspace = false }: Props) => {
	return (
		<div className={`${styles['workspace-controls']} print:tw-hidden`}>
			{
				isMainWorkspace && (
					<WorkspaceControlRotateSectionals isMainWorkspace={isMainWorkspace} />
				)
			}
			<WorkspaceControlHelpSectionals isMainWorkspace={isMainWorkspace} />
		</div>
	);
};
