import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { kebabCase } from '~/util/kebabCase';

import styles from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-insert-options/modal-insert-options-control-checkbox.module.scss';

export const WorkspaceModalInsertOptionsControlCheckboxCasegoods = observer((props = {}) => {
	const {
		selectedSelector: {
			defaultSelectorValue = {},
			negativeSelectorValue = {},
			selectorValueToRender = {},
			selectorValueToRender: {
				showOverrideWarning = false,
				title = '',
				upcharge = '',
				woodUpcharges = {},
			} = {},
		} = {},
		selectedSelectorValue = {},
	} = props;

	const {
		selectorConfigModel: {
			materialSelectorModels = [],
		} = {},
		workspaceModalInsertOptionsStore = {},
	} = useProductContext();

	const finishMaterialSelector = materialSelectorModels.find(({ type = '' }) => type === 'FINISH_MATERIAL') || {};

	const {
		selectedSelectorValue: {
			answer = '',
		} = {},
	} = finishMaterialSelector;

	const woodUpcharge = woodUpcharges[answer];

	const checked = selectedSelectorValue !== negativeSelectorValue;

	const selectorValueToSelect = checked ? defaultSelectorValue : selectorValueToRender;

	function handleChange() {
		workspaceModalInsertOptionsStore.selectSelectorValue(selectorValueToSelect);
	}

	return (
		<div className={styles['workspace-modal-insert-options-control-checkbox']}>
			<label
				className={styles['workspace-modal-insert-options-control-checkbox-label']}
				data-qa={`${kebabCase(title)}-insert-option-selector-value`}
			>
				<input
					checked={checked}
					className={styles['workspace-modal-insert-options-control-checkbox-input']}
					onChange={handleChange}
					type="checkbox"
				/>
				<span
					className={styles['workspace-modal-insert-options-control-checkbox-text']}
					data-qa={`${kebabCase(title)}-insert-option-title`}
				>
					{title} {woodUpcharge || upcharge}
					{
						showOverrideWarning && (
							<span className={styles['workspace-modal-insert-options-control-checkbox-warning']}>
								If selected, the finished back option for this cabinet will not be available.
							</span>
						)
					}
				</span>
			</label>
		</div>
	);
});
