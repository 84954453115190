import React from 'react';

import { s7ContentPath } from '~/global/global.constants';

export const ratings = [
	{
		ratingValue: '0',
		text: '0',
	},
	{
		ratingValue: '_25',
		text: '0.25',
	},
	{
		ratingValue: '_5',
		text: '0.5'
	},
	{
		ratingValue: '_75',
		text: '0.75'
	},
	{
		ratingValue: '1',
		text: '1'
	},
	{
		ratingValue: '1_25',
		text: '1.25'
	},
	{
		ratingValue: '1_5',
		text: '1.5'
	},
	{
		ratingValue: '1_75',
		text: '1.75',
	},
	{
		ratingValue: '2',
		text: '2',
	},
	{
		ratingValue: '2_25',
		text: '2.25',
	},
	{
		ratingValue: '2_5',
		text: '2.5',
	},
	{
		ratingValue: '2_75',
		text: '2.75',
	},
	{
		ratingValue: '3',
		text: '3',
	},
	{
		ratingValue: '3_25',
		text: '3.25'
	},
	{
		ratingValue: '3_5',
		text: '3.5'
	},
	{
		ratingValue: '3_75',
		text: '3.75'
	},
	{
		ratingValue: '4',
		text: '4'
	},
	{
		ratingValue: '4_25',
		text: '4.25'
	},
	{
		ratingValue: '4_5',
		text: '4.5'
	},
	{
		ratingValue: '4_75',
		text: '4.75'
	},
	{
		ratingValue: '5',
		text: '5'
	}
];

export interface ReviewStarsInterface {
	starCount: number | string,
	reviewsCount?: number
}

export const ReviewStars = ({
	starCount = 0,
	reviewsCount,
}: ReviewStarsInterface) => {
	const ratingToUse = `${starCount}`.replace('.', '_');
	if (typeof reviewsCount !== 'undefined' && reviewsCount < 1) return null;
	return (<>
		<img
			src={`${s7ContentPath}/review-stars-${ratingToUse}`}
			className="star-rating-image"
			data-qa="reviews-image"
			width={85}
			height={14}
			// eslint-disable-next-line eqeqeq
			alt={`${ratings.find(element => element.ratingValue == ratingToUse)?.text} star rating`}
		/>
		{typeof reviewsCount !== 'undefined' && <span className="tw-ml-2 tw-text-gray" data-qa="reviews-count">({reviewsCount}<span className="tw-sr-only">reviews</span>)</span>}
	</>
	);
};
