import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { DetailsList } from '~/product/common/details/Components/DetailsList';

import styles from '#/product/standard/details/details-materials.module.scss';

export const DetailQuestion = observer(({
	detail: {
		questionKey = '',
	} = {},
	isCasegoods = false,
} = {}) => {
	const {
		selectorConfigModel: {
			questionSelectorModels = [],
			selectedSelectorValues = [],
		} = {},
	} = useProductContext();

	let detailsModel = [];

	if (isCasegoods) {
		const matchingQuestionAnswerSelectorValue = selectedSelectorValues.find(({ question }) => question === questionKey);

		if (matchingQuestionAnswerSelectorValue) {
			const { detailsModel: casegoodsDetailsModel = [] } = matchingQuestionAnswerSelectorValue;

			detailsModel = casegoodsDetailsModel;
		}
	} else {
		const questionSelectorModel = questionSelectorModels.find(({ selectorValues = [] }) => {
			return selectorValues.some(({ questionAnswers = [] }) => {
				return questionAnswers.some(({ question = '' }) => question === questionKey);
			});
		});

		const {
			selectedSelectorValue: {
				detailsModel: standardDetailsModel = [],
			} = {},
		} = questionSelectorModel || {};

		detailsModel = standardDetailsModel;
	}

	if (detailsModel.length) {
		return (
			<div className={styles['detail-question']} data-qa={`detail-question_${questionKey}`}>
				<DetailsList details={detailsModel} isList={false} />
			</div>
		);
	}

	return null;
});
