import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '~/product/sectionals/workspace/Components/workspace-minimum-spacing-message.module.scss';

export const WorkspaceMinumumSpacingMessage = observer(() => {
	const { workspaceStore = {} } = useProductContext();

	const containerRef = useRef<HTMLDivElement>(null);

	const messageRef = useRef<HTMLDivElement>(null);

	function closeMinimumSpacingMessage() {
		workspaceStore.setShowMinimumSpacingMessage(false);
	}

	function handleBodyMouseDown(event: any) {
		const workspaceInvalidConfigutationMessageElements = document.querySelectorAll('[data-workspace-minimum-spacing-message]');

		const targetIsWorkspaceInvlidConfigurationMessage = Array.from(workspaceInvalidConfigutationMessageElements).some((workspaceMinimumSpacingMessageElement) => {
			return workspaceMinimumSpacingMessageElement === event.target || workspaceMinimumSpacingMessageElement.contains(event.target);
		});

		if (!targetIsWorkspaceInvlidConfigurationMessage) {
			closeMinimumSpacingMessage();
		}
	}

	useEffect(() => {
		document.querySelector('body')!.addEventListener('mousedown', handleBodyMouseDown);
		document.querySelector('body')!.addEventListener('touchstart', handleBodyMouseDown);

		return () => {
			document.querySelector('body')!.removeEventListener('mousedown', handleBodyMouseDown);
			document.querySelector('body')!.removeEventListener('touchstart', handleBodyMouseDown);
		};
	}, []);

	return (
		<div
			className={styles['workspace-minimum-spacing-message']}
			data-qa="workspace-minimum-spacing-message"
			data-workspace-minimum-spacing-message={true}
			ref={containerRef}
		>
			<button
				className={styles['workspace-minimum-spacing-message-close']}
				data-qa="workspace-minimum-spacing-message-close"
				onClick={closeMinimumSpacingMessage}
			>
				<span className="tw-sr-only">
					Close
				</span>
			</button>
			<p
				className={styles['workspace-minimum-spacing-message-text']}
				ref={messageRef}
			>
				Please note: We recommend a minimum of 58" between facing pieces for U-shaped sectionals.
			</p>
		</div>
	);
});
