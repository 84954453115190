import { WorkspaceSectionalsModelFactory } from '~/product/sectionals/workspace/Models/WorkspaceSectionals.model';

export const createWorkspaceSectionalsModel = ({
	selectorConfigModel = {},
	selectorMagicTabccordionStore = {},
}) => {
	const workspaceSectionalsModel = WorkspaceSectionalsModelFactory.create({
		selectorConfigModel,
		selectorMagicTabccordionStore,
	});

	return workspaceSectionalsModel;
};
