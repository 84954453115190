import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { SelectorValuesTypeInsert } from '~/product/casegoods/selector/Components/insert/SelectorValuesTypeInsert';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { kebabCase } from '~/util/kebabCase';
import styles from '#/product/casegoods/selector/insert/selector-value-insert.module.scss';

export const SelectorValueInsert = observer((props) => {
	const {
		label = '',
		selectorValue = {},
		selectorValue: {
			selected = false,
			title = '',
			productModels,
			productModelsByLineDrawingImage,
		} = {},
	} = props;

	const {
		selectorConfigModel: {
			settings: {
				showInsertSelectorValuesCount = '',
			} = {},
		} = {},
	} = useProductContext();

	return (
		<div
			data-qa="selector-value-insert"
			className={
				classNames(styles['selector-value-insert'], {
					[styles['selector-value-insert-selected']]: selected,
				})
			}
		>
			<div className={styles['selector-value-type-insert-text']}>
				<span
					className={styles['selector-value-insert-text-title']}
					data-qa={`${kebabCase(label)}-type-selector-value-title`}
				>
					{title}
				</span>
				{
					showInsertSelectorValuesCount && (
						<span
							className={styles['selector-value-insert-text-count']}
							data-qa={`${kebabCase(label)}-type-selector-value-count`}
						>
							{Object.entries(productModelsByLineDrawingImage).length || productModels.length} options
						</span>
					)
				}
			</div>
			<SelectorValuesTypeInsert selectorValue={selectorValue} />
		</div>
	);
});
