import { createSelectorConfigModel } from '~/product/common/selector/Helpers/SelectorConfig.init';
import { SelectorConfigStoreFactory } from '~/product/common/selector/Stores/SelectorConfig.store';
import { getSelectorValueByArticleNumber, getSelectorValuesByPeerId } from '~/product/common/selector/Utils/SelectorConfig.utils';

export const createSelectorConfigMultiModels = ({
	configurationOverrideModel = {},
	configurationOverrideModel: {
		defaultProduct: {
			articleNumber: configurationOverrideDefaultProductArticleNumber = '',
		} = {},
	} = {},
	productModels = [],
	productSelector: productSelectorData = {},
	relativeProductGroupPath = '',
	selectorConfigModel: globalSelectorConfigModel = {},
}) => {
	const { values: rootValues = [] } = productSelectorData;

	const { values, ...baseSelector } = productSelectorData;

	const {
		defaultProductModel: {
			articleNumber: defaultProductArticleNumber,
		} = {},
		productSelectorValues = [],
	} = globalSelectorConfigModel;

	const defaultProductSelectorValue = getSelectorValueByArticleNumber(productSelectorValues, defaultProductArticleNumber);

	const { peerId: defaultProductSelectorValuePeerId } = defaultProductSelectorValue;

	const selectorConfigModels = rootValues.map((value) => {
		const { articleNumbers = [] } = value;

		const configurationOverrideModelToUse = articleNumbers.includes(configurationOverrideDefaultProductArticleNumber) ? configurationOverrideModel : {};

		const filteredProductSelectorValues = productSelectorValues.filter(({ articleNumbers: productSelectorValueArticleNumbers = [] }) => {
			return articleNumbers.includes(productSelectorValueArticleNumbers[0]);
		});

		const { peerId: firstProductSelectorValuePeerId } = filteredProductSelectorValues[0];

		const peerProductSelectorValues = getSelectorValuesByPeerId(filteredProductSelectorValues, firstProductSelectorValuePeerId);

		const defaultProductSelectorValues = getSelectorValuesByPeerId(filteredProductSelectorValues, defaultProductSelectorValuePeerId);

		const newDefaultProductSelectorValue = defaultProductSelectorValues[0] || peerProductSelectorValues[0];

		const { preselectedProduct: newDefaultProductSelectorValueProduct = {} } = newDefaultProductSelectorValue;

		const {
			articleNumber = '',
			imageData: {
				imageUrl = '',
			},
		} = newDefaultProductSelectorValueProduct;

		const newDefaultProductData = {
			articleNumber,
			imageUrl,
		};

		const newSelector = {
			...baseSelector,
			values: [value],
		};

		const selectorConfigModel = createSelectorConfigModel({
			configurationOverrideModel: configurationOverrideModelToUse,
			defaultProductData: newDefaultProductData,
			productModels,
			productSelector: newSelector,
			relativeProductGroupPath,
			showSingleSelectorValueSelectors: true,
		});

		return selectorConfigModel;
	});

	return selectorConfigModels;
};

export const createSelectorConfigMultiStores = ({
	productGroupModel = {},
	selectorConfigModels = [],
}) => {
	return selectorConfigModels.map((selectorConfigModel) => {
		return SelectorConfigStoreFactory.create({
			productGroupModel,
			selectorConfigModel,
		});
	});
};
