import { reaction } from 'mobx';

import { isOnServer } from '~/global/global.constants';
import { MediaSetStoreFactory } from '~/media-set/Stores/MediaSet.store';

export const createMediaSetDimensionsStore = ({
	mediaSetModel: mediaSetDimensionsProductGroupModel = {},
	selectorConfigModel = {},
	selectorConfigModel: {
		selectedProduct: {
			mediaSetDimensionsModel = {},
			mediaSetDimensionsModel: {
				hasMediaModels = false,
			} = {},
		} = {},
	} = {},
}) => {
	const mediaSetModelToUse = hasMediaModels ? mediaSetDimensionsModel : mediaSetDimensionsProductGroupModel;

	const mediaSetDimensionsStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetModelToUse });

	const mediaSetDisposers = [];

	if (!isOnServer) {
		const selectedProductChangedDisposer = reaction(() => {
			return {
				selectedProduct: selectorConfigModel.selectedProduct,
				isLoaded: selectorConfigModel.selectedProduct.isLoaded,
				mediaSetDimensionsModel: selectorConfigModel.selectedProduct.mediaSetDimensionsModel,
			};
		}, ({
			selectedProduct: {
				mediaSetDimensionsModel: newMediaSetDimensionsModel = {},
				mediaSetDimensionsModel: {
					hasMediaModels: newHasMediaModels = false,
				} = {},
			} = {},
		}) => {
			const newMediaSetModelToUse = newHasMediaModels ? newMediaSetDimensionsModel : mediaSetDimensionsProductGroupModel;

			mediaSetDimensionsStore.setMediaSetModel(newMediaSetModelToUse);

			mediaSetDimensionsStore.selectMediaModelHead();
		});

		mediaSetDisposers.push(selectedProductChangedDisposer);

		mediaSetDimensionsStore.setMediaSetDisposers(mediaSetDisposers);
	}

	return mediaSetDimensionsStore;
};
