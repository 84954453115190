import React from 'react';
import dynamic from 'next/dynamic';

import { CartProductFactory } from '../../Models/CartProduct.model';
import { CartProductGroupFactory } from '../../Models/CartProductGroup.model';
import { AddedToWishlistFactory } from '../../Models/AddedToWishlist.model';

import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { interstitialViewstates } from '~/product/common/interstitial/interstitial.constants';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import {
	interstitialFormModel,
	wishlistRoomsFormSettings,
} from '~/product/common/interstitial/wishlist/Models/WishlistRooms.form.settings';

const AddedToWishlist = dynamic(() => import('~/product/common/interstitial/wishlist/AddedToWishlist').then(module => module.AddedToWishlist),
	{ loading: () => <LoadingSpinner isLoading /> });
const AddedToWishlistCasegoods = dynamic(() => import('~/product/casegoods/interstitial/Components/AddedToWishlistCasegoods').then(module => module.AddedToWishlistCasegoods),
	{ loading: () => <LoadingSpinner isLoading /> });
const AddedToWishlistMulti = dynamic(() => import('~/product/multi/interstitial/Components/AddedToWishlistMulti').then(module => module.AddedToWishlistMulti),
	{ loading: () => <LoadingSpinner isLoading /> });
const AddedToWishlistSectionals = dynamic(() => import('~/product/sectionals/interstitial/Components/AddedToWishlistSectionals').then(module => module.AddedToWishlistSectionals),
	{ loading: () => <LoadingSpinner isLoading /> });
const WishlistRoomAssignmentUpdated = dynamic(() => import('~/product/common/interstitial/wishlist/WishlistRoomAssignmentUpdated').then(module => module.WishlistRoomAssignmentUpdated),
	{ loading: () => <LoadingSpinner isLoading /> });

const {
	LOADING,
	WISHLIST_INTERSTITIAL,
	MULTI_SELECT,
	CUSTOM_BOOKCASE_DESIGNER,
	CUSTOM_SECTIONAL_BUILDER,
	WISHLIST_ASSIGNED,
} = interstitialViewstates;

class AddedToWishlistStore {
	magicModal

	viewState

	loadRfkRecos

	productGroups
}

export const AddedToWishlistStoreFactory = {
	create(data, magicModal, options = {}) {
		const {
			showQuantity = true,
			summaryModels = [],
		} = options;

		const instance = new AddedToWishlistStore();

		const viewStates = {
			[LOADING]: {
				component: () => (
					<div style={{ minHeight: '400px' }}>
						<LoadingSpinner isLoading />
					</div>
				),
			},
			[WISHLIST_INTERSTITIAL]: {
				component: AddedToWishlist,
				onEnter: (viewState, store) => {
					return { store };
				},
			},
			[MULTI_SELECT]: {
				component: AddedToWishlistMulti,
				onEnter: (viewState, store) => {
					return { store };
				},
			},
			[CUSTOM_BOOKCASE_DESIGNER]: {
				component: AddedToWishlistCasegoods,
				onEnter: (viewState, store) => {
					return { store };
				},
			},
			[CUSTOM_SECTIONAL_BUILDER]: {
				component: AddedToWishlistSectionals,
				onEnter: (viewState, store) => {
					return { store };
				},
			},
			[WISHLIST_ASSIGNED]: {
				component: WishlistRoomAssignmentUpdated,
				onEnter: (viewState, store) => {
					instance.magicModal.alterModal({
						title: 'Room assignment added.',
						maxWidth: '594px',
					});
					return { store };
				},
			},
		};

		instance.magicModal = magicModal;
		instance.productGroups = [];
		instance.summaryModels = summaryModels;

		instance.productGroups = data.cartProductGroups.map((cartProductGroup) => {
			const products = cartProductGroup.cartProducts.map((cartProduct) => {
				return CartProductFactory.create(cartProduct);
			});

			return CartProductGroupFactory.create(cartProductGroup, products);
		});

		instance.model = AddedToWishlistFactory.create(data, instance.productGroups, showQuantity);

		instance.model.form = new FormBuilder(
			interstitialFormModel(instance),
			wishlistRoomsFormSettings(instance),
		);

		instance.viewState = new ViewStateStore(instance, viewStates, { scrollToTopOfModal: true });

		instance.viewState.goTo(viewStates[instance.model.interstitialRenderer] ? instance.model.interstitialRenderer : WISHLIST_INTERSTITIAL);

		return instance;
	},
};
