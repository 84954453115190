import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { SelectorValueDropDown } from '~/product/common/selector/Components/drop-down/SelectorValueDropDown';
import { SelectorFilters } from '~/product/common/selector/Components/filters/SelectorFilters';
import { SelectorPromos } from '~/product/common/selector/Components/SelectorPromos';
import { getShouldShowShadowY } from '~/product/common/selector/Utils/Selector.utils';

import styles from '#/product/standard/selector/selector-drop-down.module.scss';

export const SelectorDropDown = observer((props) => {
	const {
		isActive = false,
		selectedSelector: {
			filtersMagicTabccordionStore = {},
			filtersModel = {},
			filtersStore = {},
			label = '',
			promos = [],
			selectorValuesToRender = [],
			showImages = false,
		} = {},
		selectorIndex = 0,
	} = props;

	const scrollingParentRef = useRef();

	const scrollingChildRef = useRef();

	const [showShadow, setShowShadow] = useState(false);

	let ticking = false;

	function setShowShadowState() {
		const shouldShowShadow = getShouldShowShadowY({
			isActive,
			scrollingChildRef,
			scrollingParentRef,
		});

		setShowShadow(shouldShowShadow);
	}

	function handleScroll() {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				ticking = false;

				setShowShadowState();
			});

			ticking = true;
		}
	}

	useEffect(setShowShadowState, [isActive]);

	return (
		<>
			<div className={styles['selector-drop-down-wrapper']}>
				<SelectorFilters
					filtersMagicTabccordionStore={filtersMagicTabccordionStore}
					filtersModel={filtersModel}
					filtersStore={filtersStore}
				/>
				<div
					className={`${styles['selector-drop-down-container']}`}
					onScroll={handleScroll}
					ref={scrollingParentRef}
				>
					<div
						className={
							classNames('selector-drop-down', {
								[styles['selector-drop-down-show-images']]: showImages,
								[styles['selector-drop-down-shadow']]: showShadow,
							})
						}
						ref={scrollingChildRef}
					>
						<fieldset className="selector-values-drop-down-container">
							<legend className="tw-sr-only">Choose a {label}</legend>
							<div className={styles['selector-values-drop-down']}>
								{
									selectorValuesToRender.map((selectorValue, index) => {
										return (
											<SelectorValueDropDown
												isActive={isActive}
												key={`selector-value-${index}`}
												label={label}
												selectorIndex={selectorIndex}
												selectorValue={selectorValue}
												showImages={showImages}
											/>
										);
									})
								}
							</div>
						</fieldset>
					</div>
				</div>
			</div>
			<SelectorPromos promos={promos} />
		</>
	);
});
