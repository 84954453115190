import axios from 'axios';
import { action, runInAction, makeObservable } from 'mobx';

import { apiUrl, isOnServer, s7ImagePath } from '~/global/global.constants';
import { updateProductModel } from '~/product/common/product/Helpers/Product.init';
import {
	getParentSelector,
	getProductModelsByQuestionAnswers,
	getSelectorValueByArticleNumber,
	preselectProductSelectorValues,
	selectProductQuestionAnswers,
	selectProductSelectorValue,
	getSelectorValueByPeerId,
} from '~/product/common/selector/Utils/SelectorConfig.utils';
import { symmetricDifference } from '~/util/symmetricDifference';
import { uniqBy } from '~/util/uniqBy';

class SelectorConfigStore {
	productGroupModel;

	selectorConfigModel;

	constructor({
		productGroupModel = {},
		selectorConfigModel = {},
	}) {
		makeObservable(this, {
			getProductDataBySelectorValue: action,
			selectMultiOptionsSelectorValue: action,
			selectProductSelectorValue: action,
			selectQuestionSelectorValue: action,
			selectSelectorValue: action,
			selectSelectorValueByPeerId: action,
			updateProductModelsQuestionAnswers: action,
			updateProductSelectorModels: action,
			updateQuestionSelectorModels: action,
			updateQuestionSelectorModelsFromSummary: action,
		});

		this.productGroupModel = productGroupModel;
		this.selectorConfigModel = selectorConfigModel;
	}

	getProductDataBySelectorValue({ products = [] } = {}) {
		const getProductDataPromises = products.map((productModel = {}) => {
			const {
				isLoaded = false,
				isLoading = false,
				productHref = '',
			} = productModel;

			if (!isLoaded && !isLoading && productHref) {
				const url = `${apiUrl}${productHref}`;

				productModel.isLoading = true;

				return axios.get(url)
					.then(({ data = {} }) => {
						if (productModel) {
							updateProductModel({
								productData: data,
								productModel,
							});
						} else {
							console.warn('No product found for productModel:', productModel);
						}
					})
					.catch((error) => {
						productModel.isLoading = false;

						console.error(error);
					});
			}

			return new Promise((resolve) => {
				resolve();
			});
		});

		return Promise.all(getProductDataPromises);
	}

	getProductImagesData() {
		const { id } = this.productGroupModel;

		const {
			productsModel = [],
			questionSelectorsConfig = [],
			selectedProduct: {
				articleNumber = '',
				isLoaded = false,
			} = {},
		} = this.selectorConfigModel;

		const url = `${apiUrl}/api/web/product-group/${id}/image`;

		const params = {
			articleNumber,
			question: questionSelectorsConfig,
		};

		if (isLoaded) {
			return axios.request({
				url,
				method: 'get',
				params,
				paramsSerializer: (paramsToSerialize) => {
					return Object.entries(paramsToSerialize).map(([key, value]) => {
						if (Array.isArray(value)) {
							return value.map((subValue) => {
								return `${key}=${encodeURIComponent(subValue)}`;
							}).join('&');
						}

						return `${key}=${encodeURIComponent(value)}`;
					}).join('&');
				},
			})
				.then(({ data = {} }) => {
					productsModel.forEach((product) => {
						// TODO: preserve the imageData.cylindo field between image endpoint requests until the server starts sending it in the endpoint reponse
						const {
							imageData: {
								cylindo,
							} = {},
						} = product;

						const imageData = data[product.articleNumber] || {
							imageUrl: `${s7ImagePath}/noimage`,
						};

						if (cylindo) {
							Object.assign(imageData, { cylindo });
						}

						product.imageData = imageData;
					});
				})
				.catch((error) => {
					console.info(error);
				});
		}

		return new Promise((resolve) => {
			resolve();
		});
	}

	selectMultiOptionsSelectorValue(productSelectorValue = {}) {
		const { productSelectorModels = [] } = this.selectorConfigModel;

		const parentSelector = getParentSelector(productSelectorModels, productSelectorValue);

		parentSelector.unselected = false;

		this.selectSelectorValue(productSelectorValue);
	}

	selectProductSelectorValue(productSelectorValue = {}) {
		const {
			productSelectorModels = [],
			productSelectorValues = [],
		} = this.selectorConfigModel;

		selectProductSelectorValue({
			productSelectorModels,
			productSelectorValue,
			productSelectorValues,
		});

		preselectProductSelectorValues({
			productSelectorModels,
			productSelectorValues,
			selectedProductSelectorValues: this.selectorConfigModel.selectedProductSelectorValues,
		});
	}

	selectQuestionSelectorValue(selectorValueToSelect = {}) {
		const { questionSelectorModels = [] } = this.selectorConfigModel;

		const parentQuestionSelectorModel = questionSelectorModels.find(({ selectorValues = [] }) => selectorValues.includes(selectorValueToSelect));

		const { selectorValues = [] } = parentQuestionSelectorModel || {};

		if (parentQuestionSelectorModel) {
			selectorValues.forEach((selectorValue = {}) => {
				selectorValue.selected = false;
			});

			selectorValueToSelect.selected = true;

			const { validSelectorValues = [] } = parentQuestionSelectorModel || {};

			validSelectorValues.forEach((selectorValue = {}) => {
				selectorValue.preselected = false;
			});

			selectorValueToSelect.preselected = true;
		} else {
			console.warn('Could not find parent question selector for question selectorValue', selectorValueToSelect);
		}
	}

	selectSelectorValue(selectorValueToSelect = {}) {
		const {
			parentType = '',
			questionAnswers = [],
		} = selectorValueToSelect;

		const {
			selectedProduct = {},
			selectedProductConfig: prevSelectedProductConfig = [],
			selectedProducts = [],
		} = this.selectorConfigModel;

		if (parentType === 'QUESTION') {
			const productModelsByQuestionAnswers = getProductModelsByQuestionAnswers({
				productModels: selectedProducts,
				questionAnswers,
			});

			const [firstProductModelByQuestionAnswers = {}] = productModelsByQuestionAnswers;

			this.selectQuestionSelectorValue(selectorValueToSelect);

			// only update selectedProduct if this questionSelectorValue caused the current one to be an invalid configuration
			if (!productModelsByQuestionAnswers.includes(selectedProduct)) {
				this.updateQuestionSelectorModels(firstProductModelByQuestionAnswers);
			}
		} else {
			this.selectProductSelectorValue(selectorValueToSelect);
		}

		this.selectorConfigModel.lastSelectedSelectorValue = selectorValueToSelect;

		return this.getProductDataBySelectorValue(this.selectorConfigModel.selectedProductSelectorValue)
			.then(() => {
				this.updateQuestionSelectorModels(this.selectorConfigModel.selectedProduct);

				this.updateProductSelectorModels();

				this.updateProductModelsQuestionAnswers();

				const { selectedProductConfig = [] } = this.selectorConfigModel;

				const configDifference = symmetricDifference(prevSelectedProductConfig, selectedProductConfig);

				if (configDifference.length) {
					this.getProductImagesData();
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	selectSelectorValueByPeerId(peerId = '', selectorValues = []) {
		const selectorValueToSelect = getSelectorValueByPeerId(selectorValues, peerId);

		this.selectSelectorValue(selectorValueToSelect);
	}

	updateProductModelsQuestionAnswers() {
		const {
			loadedProductModels = [],
			selectedQuestionSelectorValues = [],
		} = this.selectorConfigModel;

		selectProductQuestionAnswers({
			productModels: loadedProductModels,
			questionSelectorValues: selectedQuestionSelectorValues,
		});
	}

	updateProductSelectorModels() {
		const {
			productSelectorModels = [],
			questionSelectorModels = [],
			questionSelectorsQuestionAnswers = [],
		} = this.selectorConfigModel;

		productSelectorModels.forEach((productSelector = {}) => {
			const { selectorValues = [] } = productSelector;

			productSelector.questionSelectorModels = questionSelectorModels;

			selectorValues.forEach((productSelectorValue = {}) => {
				productSelectorValue.questionSelectorsQuestionAnswers = questionSelectorsQuestionAnswers;
			});
		});
	}

	updateQuestionSelectorModels(selectedProduct = {}) {
		const {
			questionSelectorModels = [],
			productSelectorModels = [],
			selectedMaterialSelectorValue = {},
			selectedProducts = [],
		} = this.selectorConfigModel;

		const selectedMaterialSelectorModel = [...productSelectorModels, ...questionSelectorModels].find(({ selectorValues = [] }) => selectorValues.includes(selectedMaterialSelectorValue));

		questionSelectorModels.forEach((questionSelectorModel = {}) => {
			const {
				label = '',
				renderer = '',
			} = questionSelectorModel;

			const hasMatchingProductSelector = productSelectorModels.some(({
				label: productSelectorLabel = '',
				renderer: productSelectorRenderer = '',
			}) => {
				return label === productSelectorLabel && renderer === productSelectorRenderer;
			});

			// don't update selectedMaterialSelectorModel on its own parent
			if (questionSelectorModel !== selectedMaterialSelectorModel) {
				questionSelectorModel.selectedMaterialSelectorModel = selectedMaterialSelectorModel;
			}

			questionSelectorModel.hasMatchingProductSelector = hasMatchingProductSelector;

			questionSelectorModel.selectedProduct = selectedProduct;

			questionSelectorModel.selectedProducts = selectedProducts;

			const {
				selectedSelectorValue = {},
				selectedSelectorValue: {
					peerId = '',
				} = {},
				validSelectorValues = [],
			} = questionSelectorModel;

			const [firstValidQuestionSelectorValue] = validSelectorValues;

			const peerValidQuestionSelectorValue = validSelectorValues.find(({ peerId: selectorValuePeerId = '' }) => peerId === selectorValuePeerId);

			const preselectedValidQuestionSelectorValue = validSelectorValues.find(({ preselected = false }) => preselected);

			const validQuestionSelectorValue = peerValidQuestionSelectorValue || preselectedValidQuestionSelectorValue || firstValidQuestionSelectorValue;

			// update questionSelectorModel to be valid for newSelectedProduct, if needed
			if (!validSelectorValues.includes(selectedSelectorValue) && validQuestionSelectorValue) {
				this.selectQuestionSelectorValue(validQuestionSelectorValue);
			}
		});
	}

	updateQuestionSelectorModelsFromSummary({ _questionSelectorsData = [] }) {
		const {
			questionSelectorModels = [],
			selectedProductConfig: prevSelectedProductConfig = [],
		} = this.selectorConfigModel;

		const selectorValuesToSelect = [];

		const questionSelectorsData = _questionSelectorsData.reduce((accumulatedQuestionSelectorsData = [], questionSelectorData = {}) => {
			const { dimensionSelectors: dimensionSelectorsData = [] } = questionSelectorData;

			dimensionSelectorsData.forEach((dimensionQuestionSelectorData = {}) => {
				accumulatedQuestionSelectorsData.push(dimensionQuestionSelectorData);
			});

			if (dimensionSelectorsData.length) {
				return accumulatedQuestionSelectorsData;
			}

			return [...accumulatedQuestionSelectorsData, questionSelectorData];
		}, []);

		questionSelectorsData.forEach(({
			questionGroups: questionGroupsData = [],
			values: selectorValuesData = [],
		}) => {
			const questionSelectorModel = questionSelectorModels.find(({ questionGroups = [] }) => {
				return questionGroups.every((questionGroup = '') => questionGroupsData.includes(questionGroup));
			});

			if (questionSelectorModel) {
				const {
					selectedSelectorValue = {},
					selectorValues = [],
				} = questionSelectorModel;

				selectorValues.forEach((selectorValue = {}) => {
					const { questionAnswers = [] } = selectorValue;

					const selectorValueData = selectorValuesData.find(({ questionAnswers: questionAnswersData = [] }) => {
						return questionAnswersData.every(({
							answer: answerData = '',
							question: questionData = '',
						}) => {
							return questionAnswers.every(({
								answer = '',
								question = '',
							}) => {
								return answer === answerData && question === questionData;
							});
						});
					});

					selectorValue.unavailable = selectorValueData ? !selectorValueData.available : true;
				});

				const lastAvailableSelectorValue = [...selectorValues].reverse().find(({ unavailable = false }) => !unavailable);

				const { unavailable = false } = selectedSelectorValue;

				if (unavailable) {
					selectorValuesToSelect.push(lastAvailableSelectorValue);
				}
			}
		});

		selectorValuesToSelect.forEach((questionSelectorValue = {}) => {
			this.selectQuestionSelectorValue(questionSelectorValue);
		});

		this.updateProductSelectorModels();

		this.updateProductModelsQuestionAnswers();

		const { selectedProductConfig = [] } = this.selectorConfigModel;

		const configDifference = symmetricDifference(prevSelectedProductConfig, selectedProductConfig);

		if (configDifference.length) {
			this.getProductImagesData();
		}
	}
}

export const SelectorConfigStoreFactory = ({
	create: ({
		productGroupModel = {},
		selectorConfigModel = {},
		selectorConfigModel: {
			defaultConfigModels = [],
			defaultProductModel: {
				configurationOverrideModel: {
					defaultConfig: defaultConfigOverrideModels = [],
				} = {},
				productArticleNumber = '',
			},
			productSelectorValues = [],
			questionSelectorModels = [],
		} = {},
	}) => {
		const selectorConfigStore = new SelectorConfigStore({
			productGroupModel,
			selectorConfigModel,
		});

		const defaultConfigModelsToUse = uniqBy([...defaultConfigOverrideModels, ...defaultConfigModels], 'group');

		const defaultProductSelectorValues = productSelectorValues.filter(({ hasSelectors = false }) => !hasSelectors);

		// find the productSelectorValue, which will drive selectedProduct
		let defaultProductSelectorValue = getSelectorValueByArticleNumber(defaultProductSelectorValues, productArticleNumber);

		if (!defaultProductSelectorValue) {
			console.warn(`Could not find product selectorValue for defaultProduct ${productArticleNumber}`);

			[defaultProductSelectorValue = {}] = defaultProductSelectorValues;
		}

		// find a question selectorValue for each questionSelector
		const defaultConfigQuestionSelectorValues = questionSelectorModels.map(({
			questionGroups = [],
			selectorValues = [],
		}) => {
			const [firstQuestionGroup = ''] = questionGroups;

			const matchingDefaultConfigModel = defaultConfigModelsToUse.find(({ group = '' }) => group === firstQuestionGroup);

			const [firstQuestionSelectorValue = {}] = selectorValues;

			const defaultConfigQuestionSelectorValue = selectorValues.find(({ questionAnswers = [] }) => {
				return questionAnswers.every(({
					answer = '',
					question = '',
				}) => {
					return defaultConfigModelsToUse.find(({
						answer: defaultConfigAnswer = '',
						question: defaultConfigQuestion = '',
					}) => {
						return answer === defaultConfigAnswer && question === defaultConfigQuestion;
					});
				});
			});

			if (matchingDefaultConfigModel && !defaultConfigQuestionSelectorValue) {
				console.warn(`Could not find questionSelectorValue for defaultConfig ${JSON.stringify(matchingDefaultConfigModel)}`);
			}

			return defaultConfigQuestionSelectorValue || firstQuestionSelectorValue;
		});

		runInAction(() => {
			selectorConfigStore.selectProductSelectorValue(defaultProductSelectorValue);

			defaultConfigQuestionSelectorValues.forEach((defaultConfigQuestionSelectorValue = {}) => {
				selectorConfigStore.selectQuestionSelectorValue(defaultConfigQuestionSelectorValue);
			});

			selectorConfigStore.updateQuestionSelectorModels(selectorConfigModel.selectedProduct);

			selectorConfigStore.updateProductSelectorModels();

			selectorConfigStore.updateProductModelsQuestionAnswers();

			selectorConfigModel.lastSelectedSelectorValue = defaultProductSelectorValue;
		});

		// load any other products found on defaultProductSelectorValue
		if (!isOnServer) {
			selectorConfigStore.getProductDataBySelectorValue(defaultProductSelectorValue);
		}

		return selectorConfigStore;
	},
});
