import { action, makeObservable, reaction } from 'mobx';

import { isOnServer } from '~/global/global.constants';
import { noop } from '~/util/noop';

export class SelectorFiltersStore {
	filtersModel

	hasActiveFiltersChangedDisposer

	constructor() {
		makeObservable(this, {
			clearAllFilters: action,
			setDidHaveActiveFilters: action,
			toggleFacet: action,
		});
	}

	clearAllFilters() {
		const { filterModels = [] } = this.filtersModel;

		filterModels.forEach(({ facetsModel = [] }) => {
			facetsModel.forEach((facetModel) => {
				facetModel.selected = false;
			});
		});
	}

	setDidHaveActiveFilters(didHaveActiveFilters = false) {
		this.filtersModel.didHaveActiveFilters = didHaveActiveFilters;
	}

	toggleFacet(facetToToggle) {
		facetToToggle.selected = !facetToToggle.selected;
	}
}

export const SelectorFiltersStoreFactory = ({
	create: ({ filtersModel = {} }) => {
		const selectorFiltersStore = new SelectorFiltersStore();

		const hasActiveFiltersChangedDisposer = !isOnServer
			? reaction(() => filtersModel.hasActiveFilters, (newHasActiveFilters = false) => selectorFiltersStore.setDidHaveActiveFilters(newHasActiveFilters))
			: noop;

		Object.assign(selectorFiltersStore, {
			filtersModel,
			hasActiveFiltersChangedDisposer,
		});

		return selectorFiltersStore;
	},
});
