import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/modal-frame/workspace-modal-frame-attribute-control.module.scss';

export const WorkspaceModalFrameControlWidthCasegoods = observer(() => {
	const {
		workspaceModalFrameModel: {
			widthSelectorValue: {
				peerId: selectedPeerId = '',
			} = {},
			widthSelectorValues = [],
			workspaceProductModalFrameModel: {
				frameProductModel: {
					width = 0,
				} = {},
			} = {},
		} = {},
		workspaceModalFrameStore = {},
	} = useProductContext();

	function handleChange(event) {
		const {
			target: {
				value: peerIdToSelect = '',
			} = {}
		} = event;

		const selectorValueToSelect = widthSelectorValues.find(({ peerId = '' }) => peerId === peerIdToSelect);

		workspaceModalFrameStore.setWidthSelectorValue(selectorValueToSelect, true);
	}

	if (!widthSelectorValues.length) {
		return (
			`${width} inches`
		);
	}

	return (
		<fieldset className={styles['workspace-modal-frame-attribute-control-select']}>
			<legend className="tw-sr-only">Choose a width</legend>
			<select
				data-qa="workspace-modal-frame-width-select"
				onChange={handleChange}
				value={selectedPeerId}
			>
				{
					widthSelectorValues.map(({
						title = '',
						peerId = ''
					}, index) => (
						<option
							key={`workspace-modal-frame-attribute-width-${index}`}
							value={peerId}
						>
							{title}
						</option>
					))
				}
			</select>
		</fieldset>
	);
});
