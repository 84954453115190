import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import type { IWorkspaceProductJoinableSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductJoinableSectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

import { WorkspaceProductDraggableSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductDraggableSectionals';
import { WorkspaceProductJoinableDroppableSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductJoinableDroppableSectionals';
import { WorkspaceProductSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductSectionals';

import styles from '~/product/sectionals/workspace/Components/product/workspace-product-joinable.module.scss';

interface Props {
	imageScale: number
	isDraggable: boolean
	showDimensions: boolean
	workspaceProductJoinableModel: IWorkspaceProductJoinableSectionals
	workspaceProductModel: IWorkspaceProductSectionals;
	workspaceProductPixelsHeight: number
	workspaceProductPixelsWidth: number
}

export const WorkspaceProductJoinableSectionals = observer(({
	imageScale = 0,
	isDraggable = false,
	showDimensions = false,
	workspaceProductJoinableModel,
	workspaceProductJoinableModel: {
		joinableSideOrientation = '',
		nextWorkspaceProductModel,
	},
	workspaceProductModel,
	workspaceProductModel: {
		alignToSidesOrientations = [],
		orientation = '',
		productModel: {
			isWide = false,
		},
		workspaceProductFrameDepthRatio = 0,
		workspaceProductFrameWidthRatio = 0,
	},
	workspaceProductPixelsHeight = 0,
	workspaceProductPixelsWidth = 0,
}: Props) => {
	const workspaceProductFrameDepthRatioToUse = isWide && ['E', 'W'].includes(orientation) ? workspaceProductFrameWidthRatio : workspaceProductFrameDepthRatio;

	const workspaceProductFrameWidthRatioToUse = isWide && ['N', 'S'].includes(orientation) ? workspaceProductFrameWidthRatio : workspaceProductFrameDepthRatio;

	const inlineHeight = ['E', 'W'].includes(joinableSideOrientation) ? { height: `${workspaceProductFrameDepthRatioToUse * 100}%` } : {};

	const inlineWidth = ['N', 'S'].includes(joinableSideOrientation) ? { width: `${workspaceProductFrameWidthRatioToUse * 100}%` } : {};

	return (
		<div
			className={
				classNames(
					styles['workspace-product-joinable'],
					styles[`workspace-product-joinable-${joinableSideOrientation}`],
					...alignToSidesOrientations.map((alignToSideOrientation = '') => styles[`workspace-product-joinable-alignToSide-${alignToSideOrientation}`]),
				)
			}
			style={{
				...inlineHeight,
				...inlineWidth,
			}}
		>
			{
				isDraggable && (
					<WorkspaceProductJoinableDroppableSectionals
						imageScale={imageScale}
						workspaceProductJoinableModel={workspaceProductJoinableModel}
						workspaceProductModel={workspaceProductModel}
						workspaceProductFrameDepthRatioToUse={workspaceProductFrameDepthRatioToUse}
						workspaceProductFrameWidthRatioToUse={workspaceProductFrameWidthRatioToUse}
						workspaceProductPixelsHeight={workspaceProductPixelsHeight}
						workspaceProductPixelsWidth={workspaceProductPixelsWidth}
					/>
				)
			}
			{
				nextWorkspaceProductModel && isDraggable && (
					<WorkspaceProductDraggableSectionals
						imageScale={imageScale}
						isDraggable={isDraggable}
						showDimensions={showDimensions}
						workspaceProductModel={nextWorkspaceProductModel}
					/>
				)
			}
			{
				nextWorkspaceProductModel && !isDraggable && (
					<WorkspaceProductSectionals
						imageScale={imageScale}
						isDraggable={isDraggable}
						showDimensions={showDimensions}
						workspaceProductModel={nextWorkspaceProductModel}
					/>
				)
			}
		</div>
	);
});
