import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { kebabCase } from '~/util/kebabCase';
import styles from '#/product/standard/selector/selector-drop-down.module.scss';
import stylesTwoColumn from '#/product/standard/selector/selector-two-column.module.scss';

export const SelectorValueTwoColumn = observer((props) => {
	const {
		label = '',
		selectorIndex = 0,
		selectorValue,
		selectorValue: {
			selected,
			statusToRender = '',
			title = '',
		},
	} = props;

	const {
		selectorConfigStore,
		selectorConfigStores,
	} = useProductContext();

	function handleChange() {
		const selectorConfigStoreToUse = selectorConfigStores ? selectorConfigStores[selectorIndex] : selectorConfigStore;

		selectorConfigStoreToUse.selectSelectorValue(selectorValue);
	}

	return (
		<label
			className={
				classNames(stylesTwoColumn['selector-value-two-column'], {
					[stylesTwoColumn['selector-value-two-column-selected']]: selected,
				})
			}
			data-qa={`${kebabCase(label)}-selector-value`}
			title={title}
		>
			<div className={classNames(stylesTwoColumn['selector-value-two-column-text'], {
				[styles['has-status']]: statusToRender,
			})}>
				{
					statusToRender && (
						<span className={
							classNames(stylesTwoColumn['selector-value-two-column-status'], {
								[stylesTwoColumn['selector-value-two-column-status-new']]: statusToRender === 'New',
							})
						}>{statusToRender}</span>
					)
				}
				<span
					className={stylesTwoColumn['selector-value-two-column-title']}
					data-qa={`${kebabCase(label)}-selector-value-title`}
				>
					{title}
				</span>
			</div>
			<input
				checked={selected}
				className="tw-sr-only"
				data-tr-link-event-track={false}
				onChange={handleChange}
				type="radio"
			/>
		</label>
	);
});
