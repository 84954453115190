import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { ViewState } from '~/util/viewState/Components/ViewState';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

export const Delivery = observer((props) => {
	const {
		hasLoaded,
		viewState,
	} = props.store;

	if (!hasLoaded || !viewState.currentView) {
		return null;
	}

	const {
		productCommons: {
			summaryPromos = [],
		},
		mediaSetEnvironmentModel: {
			hasMediaModels = false,
		} = {},
	} = useProductContext();

	const showBorder = hasMediaModels || Boolean(summaryPromos.length);

	return (
		<div className={classNames('delivery', 'tw-hidden-for-print', {
			'show-border': showBorder,
		})}>
			<ViewState viewState={viewState} />
		</div>
	);
});
