import { observer } from 'mobx-react';
import React from 'react';

import { SelectorHeadingDefault } from '~/product/common/selector/Components/SelectorHeading';

export const SelectorHeadingMediumImage = observer((props) => {
	const {
		isActive = true,
		selectedSelector = {},
		showPrice = false,
	} = props;

	return (
		<SelectorHeadingDefault
			isActive={isActive}
			selectedSelector={selectedSelector}
			showPrice={showPrice}
			hideOptionsCountWhenActive={true}
		/>
	);
});
