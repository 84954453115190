import { computed, makeObservable } from 'mobx';

class DefaultProduct {
	articleNumber;

	configurationOverrideModel;

	defaultAnswers;

	imageUrl;

	constructor() {
		makeObservable(this, {
			productImageUrl: computed,
			productArticleNumber: computed,
		});
	}

	get productImageUrl() {
		const { hasConfigurationOverride } = this.configurationOverrideModel;

		return hasConfigurationOverride ? this.configurationOverrideModel.imageUrl : this.imageUrl;
	}

	get productArticleNumber() {
		const { hasConfigurationOverride } = this.configurationOverrideModel;

		return hasConfigurationOverride ? this.configurationOverrideModel.articleNumber : this.articleNumber;
	}
}

export const DefaultProductModelFactory = ({
	create: ({
		articleNumber = '',
		configurationOverrideModel = {},
		defaultAnswers = [],
		imageUrl = '',
	}) => {
		const defaultProduct = new DefaultProduct();

		Object.assign(defaultProduct, {
			articleNumber,
			configurationOverrideModel,
			defaultAnswers,
			imageUrl,
		});

		return defaultProduct;
	},
});
