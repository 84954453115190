import { createMediaModels } from '~/media-set/Helpers/Media.init';
import { MediaSetModelFactory } from '~/media-set/Models/MediaSet.model';

export const createMediaSetModel = ({
	disableClick = false,
	mediaModelsHead = [],
	mediaModelsTail = [],
	mediaSetData = [],
	featureTogglesModel = {},
}) => {
	const mediaModels = createMediaModels({
		disableClick,
		mediaSetData,
		featureTogglesModel,
	});

	const mediaModelsCombined = [
		...mediaModelsHead,
		...mediaModels,
		...mediaModelsTail,
	];

	// normalize the indicies after combining mediaModels
	mediaModelsCombined.forEach((mediaModel, index) => {
		mediaModel.index = index;
	});

	const mediaSetModel = MediaSetModelFactory.create({ mediaModels: mediaModelsCombined });

	return mediaSetModel;
};
