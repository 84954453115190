import React from 'react';
import { observer } from 'mobx-react';

import type { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { ViewState } from '~/util/viewState/Components/ViewState';

type Props = {
	viewState: ViewStateStore
}
export const ShopThisRoomTray = observer(({ viewState }: Props) => {
	return (
		<ViewState viewState={viewState} />
	);
});
