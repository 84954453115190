import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { SelectorValueSmallImage } from '~/product/common/selector/Components/small-image/SelectorValueSmallImage';
import { getShouldShowShadowY } from '~/product/common/selector/Utils/Selector.utils';
import styles from '#/product/standard/selector/selector-small-image.module.scss';
import stylesSelector from '#/product/standard/selector/selector.module.scss';

export const SelectorSmallImage = (props) => {
	const {
		isActive = false,
		selectedSelector: {
			groupValuesByStocked = false,
			label = '',
			selectorValuesToRender = [],
			spoSelectorValues = [],
			stockedSelectorValues = [],
		} = {},
	} = props;

	// these values MUST match what you have in CSS, and I hate it a whole bunch
	// nested flexbox columns don't grow their parent width, so we need to figure it out on our own...
	const selectorValueMargin = 5;

	const selectorValueWidth = 78;

	const spoSelectorValuesContainerWidth = spoSelectorValues.length * (selectorValueMargin + selectorValueWidth);

	const stockedSelectorValuesContainerWidth = stockedSelectorValues.length * (selectorValueMargin + selectorValueWidth);

	const scrollingParentRef = useRef();

	const scrollingChildRef = useRef();

	const [showShadow, setShowShadow] = useState(false);

	let ticking = false;

	function setShowShadowState() {
		const shouldShowShadow = getShouldShowShadowY({
			isActive,
			scrollingChildRef,
			scrollingParentRef,
		});

		setShowShadow(shouldShowShadow);
	}

	function handleScroll() {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				ticking = false;

				setShowShadowState();
			});

			ticking = true;
		}
	}

	useEffect(setShowShadowState, [isActive]);

	return (
		<div
			className={`${styles['selector-small-image-container']}`}
			onScroll={handleScroll}
			ref={scrollingParentRef}
		>
			<div
				className={
					classNames('selector-small-image', {
						[styles['selector-small-image-shadow']]: showShadow,
					})
				}
				ref={scrollingChildRef}
			>
				<fieldset className="selector-values-types-small-image-container">
					<div className={styles['selector-values-types-small-image']}>
						<legend className="tw-sr-only">Choose a {label}</legend>
						{
							!groupValuesByStocked && (
								<div className={styles['selector-values-type-small-image']}>
									<div className={styles['selector-values-small-image']}>
										{
											selectorValuesToRender.map((selectorValue, index) => {
												return (
													<SelectorValueSmallImage
														isActive={isActive}
														key={`selector-value-${index}`}
														label={label}
														selectorValue={selectorValue}
													/>
												);
											})
										}
									</div>
								</div>
							)
						}
						{
							groupValuesByStocked && stockedSelectorValues.length > 0 && (
								<div
									className={`${styles['selector-values-type-small-image']} selector-values-type-small-image-stocked`}
									style={{ flexBasis: stockedSelectorValuesContainerWidth }}
								>
									<span className={styles['selector-values-type-small-image-title']}>
										<span className="tw-pr-3">Stocked</span>
										<span className={stylesSelector['selector-heading-count']} data-qa="selector-values-type-small-image-title-stock-option-count">{stockedSelectorValues.length} {stockedSelectorValues.length === 1 ? 'option' : 'options'}</span>
									</span>
									<div className={styles['selector-values-small-image']}>
										{
											stockedSelectorValues.map((selectorValue, index) => {
												return (
													<SelectorValueSmallImage
														isActive={isActive}
														key={`selector-value-${index}`}
														label={label}
														selectorValue={selectorValue}
													/>
												);
											})
										}
									</div>
								</div>
							)
						}
						{
							groupValuesByStocked && spoSelectorValues.length > 0 && (
								<div
									className={`${styles['selector-values-type-small-image']} selector-values-type-small-image-spo`}
									style={{ flexBasis: spoSelectorValuesContainerWidth }}
								>
									<span className={styles['selector-values-type-small-image-title']}>
										<span className="tw-pr-3">Made for you</span>
										<span className={stylesSelector['selector-heading-count']} data-qa="selector-values-type-small-image-title-spot-option-count">{spoSelectorValues.length} {spoSelectorValues.length === 1 ? 'option' : 'options'}</span>
									</span>
									<div className={styles['selector-values-small-image']}>
										{
											spoSelectorValues.map((selectorValue, index) => {
												return (
													<SelectorValueSmallImage
														isActive={isActive}
														key={`selector-value-${index}`}
														label={label}
														selectorValue={selectorValue}
													/>
												);
											})
										}
									</div>
								</div>
							)
						}
					</div>
				</fieldset>
			</div>
		</div>
	);
};
