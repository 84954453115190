import { observer } from 'mobx-react';
import React, { useRef, useEffect } from 'react';

import { Media } from '~/media-set/Components/Media';
import { scaleLayeredImage } from '~/util/scaleLayeredImage';
import { noop } from '~/util/noop';

import styles from '#/media-set/media-main-button.module.scss';
import { wistiaVideoMediaType } from '~/components/wistia-player/Types/constants';

export const MediaMainButton = observer((props) => {
	const {
		imagePreset = '$prodzoom0$',
		isZoomed = false,
		mainHeight = 0,
		mainWidth = 0,
		mediaComponentsOverride = {},
		mediaModel = {},
		mediaModel: {
			disableClick = false,
			hasImageLink,
			index = 0,
			mediaImageUrl = '',
			screenReaderText = '',
			selected = false,
			type = '',
		} = {},
		mediaSetModel = {},
		mediaSetStore = {},
		onClick = noop,
		openImageInNewTab = false,
		showDimensions = false,
		showDriftZoom = false,
		showFullScreenButton = false,
	} = props;

	const buttonRef = useRef();

	const clientXRef = useRef(0);

	const clientYRef = useRef(0);

	const eventButtonRef = useRef(0);

	let tagLink = null;

	const TagName = (() => {
		if (disableClick) {
			return 'div';
		}
		if (hasImageLink) {
			tagLink = mediaModel.imageLink;
			return 'a';
		}
		if (openImageInNewTab) {
			tagLink = scaleLayeredImage(mediaImageUrl, 2400, 1300);
			return 'a';
		}
		return 'button';
	})();

	function onKeyUp(event) {
		// re-enables keyboard functionality, since we hijack the click handler to discern swipe vs click intent
		if ([13, 32].includes(event.keyCode) && !disableClick) {
			onClick({ isMediaMainButtonClick: true });
		}
	}

	function onMouseDown(event) {
		const {
			button = 0,
			clientX = 0,
			clientY = 0,
		} = event;

		clientXRef.current = clientX;

		clientYRef.current = clientY;

		eventButtonRef.current = button;
	}

	function onMouseUp(event) {
		const {
			clientX = 0,
			clientY = 0,
		} = event;

		const didMove = clientX !== clientXRef.current || clientY !== clientYRef.current;

		if (!didMove && eventButtonRef.current !== 2 && !disableClick) {
			// take the click handler out of the event loop so modal focus lock gets set correctly
			setTimeout(() => {
				onClick({ isMediaMainButtonClick: true });
			}, 0);
		}
	}

	// don't allow .slick-cloned elements to be focusable
	useEffect(() => {
		if (buttonRef.current && selected) {
			const isCloned = buttonRef.current.closest('.slick-cloned');

			if (isCloned) {
				buttonRef.current.setAttribute('tabindex', -1);
			}
		}
	});

	return (
		<TagName
			className={`${styles['media-main-button']}`}
			data-media-main-button-selected={selected}
			data-qa={`media-main-${index}`}
			data-tr-link-event-track={type !== wistiaVideoMediaType}
			href={tagLink}
			onKeyUp={onKeyUp}
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			ref={buttonRef}
			tabIndex={selected ? 0 : -1}
			target={openImageInNewTab ? '_blank' : null}
		>
			<Media
				imageHeight={mainHeight}
				imagePreset={imagePreset}
				imageWidth={mainWidth}
				isZoomed={isZoomed}
				mediaComponentsOverride={mediaComponentsOverride}
				mediaModel={mediaModel}
				mediaSetModel={mediaSetModel}
				mediaSetStore={mediaSetStore}
				showDimensions={showDimensions}
				showDriftZoom={showDriftZoom}
				showFullScreenButton={showFullScreenButton}
				showMediaPlayer={true}
			/>
			{
				screenReaderText && (
					<span className="tw-sr-only">
						{screenReaderText}
					</span>
				)
			}
			{
				showFullScreenButton && (
					<span className="tw-sr-only">
						View fullscreen media
					</span>
				)
			}
		</TagName>
	);
});
