import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductInsertImageCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-insert/WorkspaceProductInsertImageCasegoods';
import { WorkspaceProductInsertOptionsEditCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-insert/WorkspaceProductInsertOptionsEditCasegoods';
import { WorkspaceProductInsertOptionsSavedCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-insert/WorkspaceProductInsertOptionsSavedCasegoods';

import styles from '#/product/casegoods/workspace/product-insert/workspace-product-insert.module.scss';

export const WorkspaceProductInsertCasegoods = observer((props) => {
	const {
		imageScale = 0,
		insertProductModel = {},
		insertProductModel: {
			insertSpace = '',
		} = {},
		slotModel = {},
		slotModel: {
			hasNonDefaultInsertOptionsSelectorValues = false,
		} = {},
	} = props;

	const {
		workspaceModel: {
			dimensions: {
				imageInchesToPixelsFactor = 0,
				slotPaddingInches = 0,
			} = {},
			showInsertOptionsEdit = false,
		} = {},
	} = useProductContext();

	const workspaceProductInsertPixelsPadding = (imageInchesToPixelsFactor * slotPaddingInches) / imageScale;

	return (
		<div
			className={
				classNames(
					styles['workspace-product-insert'],
					styles[`workspace-product-insert-${insertSpace.replace(' ', '_')}`],
				)
			}
			style={{ padding: `${workspaceProductInsertPixelsPadding}px` }}
		>
			<WorkspaceProductInsertImageCasegoods
				imageScale={imageScale}
				insertProductModel={insertProductModel}
			/>
			{
				showInsertOptionsEdit && (
					<>
						<WorkspaceProductInsertOptionsEditCasegoods
							imageScale={imageScale}
							slotModel={slotModel}
						/>
						{
							hasNonDefaultInsertOptionsSelectorValues && (
								<WorkspaceProductInsertOptionsSavedCasegoods />
							)
						}
					</>
				)
			}
		</div>
	);
});
