import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import classNames from 'classnames';
import { useDragLayer } from 'react-dnd';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/product-slot/workspace-product-slot-droppable.module.scss';

export const WorkspaceProductsErrorCasegoods = observer(() => {
	const {
		workspaceModel: {
			showErrorMessage = false,
		} = {},
	} = useProductContext();

	const errorMessageRef = useRef();

	useDragLayer((monitor) => {
		const dragCoord = monitor?.getClientOffset();
		if (!dragCoord || !errorMessageRef?.current) return;
		const { x, y } = dragCoord;
		errorMessageRef.current.style.left = `${x + 10}px`;
		errorMessageRef.current.style.top = `${y}px`;
	});

	return (
		<div
			ref={errorMessageRef}
			data-qa="workspace-error-message"
			className={classNames(styles['workspace-product-slot-droppable-message'],
				{ 'tw-block': showErrorMessage })}
		>
			The selected insert is not <br /> an option for this space
		</div>
	);
});
