import React from 'react';

import { SelectorValueRadioVertical } from '~/product/common/selector/Components/radio-vertical/SelectorValueRadioVertical';
import styles from '#/product/standard/selector/selector-radio-vertical.module.scss';

export const SelectorRadioVertical = (props) => {
	const {
		selectedSelector: {
			label = '',
			selectorValues = [],
			subheader = '',
		} = {},
	} = props;

	return (
		<div className={styles['selector-radio-vertical']}>
			<fieldset className={styles['selector-values-radio-vertical-container']}>
				<legend className="tw-sr-only">Choose a {label}</legend>
				<div className="selector-values-radio-vertical">
					<fieldset>
						{
							subheader && <legend>{subheader}</legend>
						}

						{
							selectorValues.map((selectorValue, index) => {
								return (
									<SelectorValueRadioVertical
										key={`selector-value-${index}`}
										label={label}
										selectorValue={selectorValue}
									/>
								);
							})
						}
					</fieldset>
				</div>
			</fieldset>
		</div>
	);
};
