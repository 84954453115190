import { makeObservable, observable } from 'mobx';

class MediaWorkspaceSectionals {
	caption

	disableClick

	index

	selected

	type

	workspaceModel

	constructor() {
		makeObservable(this, {
			selected: observable,
		});
	}
}

export const MediaWorkspaceSectionalsModelFactory = ({
	create: ({
		caption = '',
		disableClick = false,
		index = 0,
		selected = false,
		type = 'WORKSPACE_SECTIONALS',
		workspaceModel = {},
	}) => {
		const mediaWorkspaceSectionals = new MediaWorkspaceSectionals();

		Object.assign(mediaWorkspaceSectionals, {
			caption,
			disableClick,
			index,
			selected,
			type,
			workspaceModel,
		});

		return mediaWorkspaceSectionals;
	},
});
