import { computed, makeObservable, observable } from 'mobx';

class MediaCylindo {
	cylindoData;

	isCylindoActive;

	isCylindoARActive;

	isCylindoDimensionsActive;

	productGroupModel;

	selectorConfigModel;

	constructor() {
		makeObservable(this, {
			cylindoData: observable.ref,
			isCylindoActive: observable,
			isCylindoARActive: observable,
			isCylindoDimensionsActive: observable,
			cylindoAPIParams: computed,
			cylindoAPIUrl: computed,
			cylindoDimensionsImage: computed,
			cylindoFeatures: computed,
			cylindoProductCode: computed,
			hasCylindo: computed,
			hasCylindoDimensionImage: computed,
		});
	}

	get cylindoAPIParams() {
		const {
			selectedProduct: {
				articleNumber = '',
			} = {},
			selectedProductConfig = [],
		} = this.selectorConfigModel;

		return {
			articleNumber,
			question: selectedProductConfig,
		};
	}

	get cylindoAPIUrl() {
		if (!this.hasCylindo) {
			return '';
		}

		const {
			_links: {
				cylindo: {
					href = '',
				} = {},
			} = {},
		} = this.productGroupModel;

		return href;
	}

	get cylindoDimensionsImage() {
		const { dimensionImageUrl = '' } = this.cylindoData || {};

		return dimensionImageUrl.replaceAll(' ', '%20');
	}

	get cylindoFeatures() {
		const { features = {} } = this.cylindoData || {};

		return Object.entries(features).reduce((accumulatedFeatures, [key, value]) => {
			accumulatedFeatures.push(key);

			accumulatedFeatures.push(value);

			return accumulatedFeatures;
		}, []);
	}

	get cylindoProductCode() {
		const { productCode = '' } = this.cylindoData || {};

		return productCode;
	}

	get hasCylindo() {
		const {
			selectedProduct: {
				hasCylindo = false,
			} = {},
		} = this.selectorConfigModel;

		return hasCylindo;
	}

	get hasCylindoDimensionImage() {
		const {
			selectedProduct: {
				hasCylindoDimensionImage = false,
			} = {},
		} = this.selectorConfigModel;

		return hasCylindoDimensionImage;
	}
}

export const MediaCylindoModelFactory = ({
	create: ({
		cylindoData = null,
		isCylindoActive = false,
		isCylindoARActive = false,
		isCylindoDimensionsActive = false,
		productGroupModel = {},
		selectorConfigModel = {},
	}) => {
		const mediaCylindo = new MediaCylindo();

		Object.assign(mediaCylindo, {
			cylindoData,
			isCylindoActive,
			isCylindoARActive,
			isCylindoDimensionsActive,
			productGroupModel,
			selectorConfigModel,
		});

		return mediaCylindo;
	},
});
