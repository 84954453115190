import React from 'react';
import { observer } from 'mobx-react';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

const CuralateUploadEditImage = observer(class CuralateUploadEditImage extends React.Component {
	constructor(props) {
		super(props);

		this.changeHandler = this.changeHandler.bind(this);
		this.clickHandler = this.clickHandler.bind(this);
	}

	render() {
		const {
			file,
			index,
		} = this.props;

		const {
			caption,
			link,
			name,
		} = file;

		return (
			<div className={`field-group ${styles['upload-edit-field-group']}`}>
				<div className={styles['upload-edit-image-container']}>
					<img alt={`Upload preview for file name: ${name}`} className={styles['upload-edit-image']} src={link} />
				</div>
				<label className={'tw-sr-only'} htmlFor={`upload-edit-${index}`}>
					{`Caption for file name: ${name}`}
				</label>
				<div className={styles['upload-edit-control-container']}>
					<textarea className={styles['upload-edit-control']} id={`upload-edit-${index}`} name={`upload-edit-${index}`} onChange={this.changeHandler} value={caption} />
				</div>
				<button className={`${styles['upload-edit-remove']} ButtonAnchor`} onClick={this.clickHandler} type={'button'}>
					<span className={'tw-sr-only'}>
						{`Remove image: ${name}`}
					</span>
					<span aria-hidden={true}>Remove</span>
				</button>
			</div>
		);
	}

	changeHandler(event) {
		const { value } = event.target;

		const {
			changeHandler,
			file,
		} = this.props;

		changeHandler(file, value);
	}

	clickHandler() {
		const {
			clickHandler,
			file,
		} = this.props;

		clickHandler(file);
	}
});

export { CuralateUploadEditImage };
