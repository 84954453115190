import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { isOnServer } from '~/global/global.constants';

export const SelectorCustomMirrorDimension = observer(({
	selectedSelector: {
		dimensionQuestionSelectorModels = [],
		label = '',
	} = {},
}) => {
	const {
		selectorConfigStore = {},
		summaryModel: {
			isLoading = false,
		} = {},
	} = useProductContext();

	return (
		<div className="selector-custom-mirror-dimension-wrapper tw-w-full md:tw-mt-0 md:tw-mb-8 tw-mt-5 tw-mb-5">
			<div
				className="selector-custom-mirror-dimension-container"
			>
				<fieldset className="selector-values-custom-mirror-dimension-container">
					<legend className="tw-sr-only">Choose a {label}</legend>
					<div className="selector-values-custom-mirror-dimension tw-flex tw-space-x-5">
						{
							dimensionQuestionSelectorModels.map(({
								selectorValues: nestedSelectorValues = [],
								label: nestedSelectorLabel = '',
								selectedSelectorValue: {
									peerId = '',
								} = {},
							}) => {
								return (
									<div key={peerId} className="tw-w-3/12">
										<label
											data-qa={`custom-mirror-dimension-label_${nestedSelectorLabel}`}
											htmlFor={`custom-mirror-dimension-select_${nestedSelectorLabel}`}
										>
											{nestedSelectorLabel}:
										</label>
										<select
											id={`custom-mirror-dimension-select_${nestedSelectorLabel}`}
											data-qa={`custom-mirror-dimension-select_${nestedSelectorLabel}`}
											value={peerId}
											onChange={(e) => {
												selectorConfigStore.selectSelectorValueByPeerId(e.target.value, nestedSelectorValues);
											}}
											disabled={isOnServer || isLoading}
										>
											{
												nestedSelectorValues.map(({
													peerId: nestedPeerId = '',
													title = '',
													unavailable = false,
												}) => {
													return (
														<option
															disabled={unavailable}
															key={nestedPeerId}
															value={nestedPeerId}
														>
															{title}
														</option>
													);
												})
											}
										</select>
									</div>
								);
							})
						}
					</div>
				</fieldset>
			</div>
		</div>
	);
});
