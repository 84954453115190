import React from 'react';
import { observer } from 'mobx-react';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

const CuralateUploadImageField = observer(class CuralateUploadImageField extends React.Component {
	constructor(props) {
		super(props);

		this.changeHandler = this.changeHandler.bind(this);
	}

	render() {
		const {
			file,
			index,
		} = this.props;

		const {
			name,
			selected,
			link,
		} = file;

		let labelClasses = styles['upload-image-label'];

		if (selected) {
			labelClasses += ` ${styles['upload-image-label-selected']}`;
		}

		return (
			<div className="field-group upload-image-field-group">
				<input className={'tw-sr-only u-focusSiblingOutline'} checked={selected} id={`upload-image-${index}`} name={`upload-image-${index}`} onChange={this.changeHandler} type={'checkbox'} />
				<label className={labelClasses} htmlFor={`upload-image-${index}`}>
					<span className={'tw-sr-only'}>{name}</span>
					<img alt={`Upload preview for file name: ${name}`} src={link} />
				</label>
			</div>
		);
	}

	changeHandler(event) {
		const {
			changeHandler,
			file,
		} = this.props;

		const { value } = event.target;

		changeHandler(file, value);
	}
});

export { CuralateUploadImageField };
