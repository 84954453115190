/* eslint-disable @typescript-eslint/no-use-before-define */
import { noop } from '~/util/noop';
import { sortBy } from '~/util/sortBy';

export const clearSelectorValueDescendantsFilters = (selectorValueToClear = {}) => {
	const { selectors = [] } = selectorValueToClear;

	selectors.forEach(({
		displayType = '',
		filtersStore = {
			clearAllFilters: noop,
		},
		filtersMagicTabccordionStore = {
			deactivateAllTabs: noop,
		},
	}) => {
		if (displayType !== 'QUESTION_ANSWER' && displayType !== 'PRODUCT') {
			filtersStore.clearAllFilters();
		}

		filtersMagicTabccordionStore.deactivateAllTabs();
	});
};

export const deselectProductSelectorAndDescendants = (selectorToDeselect = {}) => {
	const { selectorValues = [] } = selectorToDeselect;

	selectorValues.forEach((selectorValue) => {
		const { selectors = [] } = selectorValue;

		selectors.forEach(deselectProductSelectorAndDescendants);

		selectorValue.selected = false;
	});
};

export const deselectSelectorAndDescendants = (selectorToDeselect = {}) => {
	const { selectorValues = [] } = selectorToDeselect;

	selectorValues.forEach((selectorValue) => {
		const { selectors = [] } = selectorValue;

		selectors.forEach(deselectSelectorAndDescendants);

		selectorValue.selected = false;
	});

	selectorToDeselect.selected = false;
};

export const deselectSelectorValue = (flattenedSelectorsAndSelectorValues = [], selectorValueToSelect = {}) => {
	const parentSelector = getParentSelector(flattenedSelectorsAndSelectorValues, selectorValueToSelect);

	deselectSelectorAndDescendants(parentSelector);

	clearSelectorValueDescendantsFilters(selectorValueToSelect);
};

export const getFlattenedSelectors = (selectorsToAdd = [], selectorsToReturn = []) => {
	selectorsToAdd.forEach((selectorToAdd) => {
		const { selectorValues = [] } = selectorToAdd;

		selectorsToReturn.push(selectorToAdd);

		selectorValues.forEach(({ selectors = [] }) => getFlattenedSelectors(selectors, selectorsToReturn));
	});

	return selectorsToReturn;
};

export const getFlattenedSelectorValues = (selectorValuesToAdd = [], selectorValuesToReturn = []) => {
	selectorValuesToAdd.forEach((valueToAdd) => {
		const { selectors = [] } = valueToAdd;

		const sortedSelectors = sortBy(selectors, 'displayOrder');

		selectorValuesToReturn.push(valueToAdd);

		sortedSelectors.forEach(({ selectorValues = [] }) => getFlattenedSelectorValues(selectorValues, selectorValuesToReturn));
	});

	return selectorValuesToReturn;
};

export const getMatchingQuestionSelectorValuesByPriceGroup = ({
	productSelectorValues = [],
	quesitonSelectorValues = [],
	stocked = false,
}) => {
	return productSelectorValues.reduce((accumulatedPriceGroups = {}, selectorValue = {}) => {
		const {
			preselectedProductModel = {},
			preselectedProductModel: {
				articleNumber = '',
				price = 0,
			} = {},
		} = selectorValue;

		const validQuestionSelectorValues = getQuestionSelectorValuesByProductModel({
			productModel: preselectedProductModel,
			selectorValues: quesitonSelectorValues,
		});

		const matchingQuestionSelectorValues = validQuestionSelectorValues.filter((questionSelectorValue = {}) => {
			const { pricesByArticle = {} } = questionSelectorValue;

			const priceByArticle = pricesByArticle[articleNumber];

			const { stocked: priceByArticleStocked = false } = priceByArticle || {};

			return stocked === priceByArticleStocked;
		});

		if (matchingQuestionSelectorValues.length) {
			(accumulatedPriceGroups[price] = accumulatedPriceGroups[price] || []).push(...matchingQuestionSelectorValues);
		}

		return accumulatedPriceGroups;
	}, {});
};

export const getParentSelector = (selectors = [], selectorValue = {}) => {
	return selectors.find(({ selectorValues = [] }) => selectorValues.includes(selectorValue));
};

export const getParentSelectorValue = (selectorValues = [], selector = {}) => {
	return selectorValues.find(({ selectors = [] }) => selectors.includes(selector));
};

export const getProductModelsByQuestionAnswers = ({
	productModels = [],
	questionAnswers = [],
}) => {
	return productModels.filter(({ questionsModel = [] }) => {
		return questionAnswers.every(({
			answer = '',
			question = '',
		}) => {
			const matchingQuestionModel = questionsModel.find(({ key = '' }) => question === key);

			if (matchingQuestionModel) {
				const { answersModel = [] } = matchingQuestionModel;

				return answersModel.some(({ key = '' }) => answer === key);
			}

			return false;
		});
	});
};

export const getProductSelectorValuesByPriceGroup = ({ productSelectorValues = [] }) => {
	return productSelectorValues.reduce((accumulatedPriceGroups = {}, selectorValue = {}) => {
		const { preselectedProductModel = {} } = selectorValue;

		const {
			pricingInfo: {
				base = 0,
			} = {},
		} = preselectedProductModel || {};

		(accumulatedPriceGroups[base] = accumulatedPriceGroups[base] || []).push(selectorValue);

		return accumulatedPriceGroups;
	}, {});
};

export const getQuestionSelectorValuesByPriceGroup = ({
	productModel: {
		articleNumber = '',
		nonCustomArticles = [],
		pricing: {
			base = 0,
		} = {},
		upcharge = 0,
	} = {},
	selectorValues = [],
}) => {
	return selectorValues.reduce((accumulatedPriceGroups = [], selectorValue = {}) => {
		const { pricesByArticle = {} } = selectorValue;

		const matchingNonCustomArticleNumber = nonCustomArticles.find((nonCustomArticleNumber = '') => pricesByArticle[nonCustomArticleNumber]);

		const priceGroup = pricesByArticle[matchingNonCustomArticleNumber] || pricesByArticle[articleNumber];

		const { price = 0 } = priceGroup || {};

		const priceGroupPrice = (price || base) + upcharge;

		(accumulatedPriceGroups[priceGroupPrice] = accumulatedPriceGroups[priceGroupPrice] || []).push(selectorValue);

		return accumulatedPriceGroups;
	}, {});
};

export const getQuestionSelectorValuesByProductModel = ({
	productModel: {
		questionsModel = [],
	} = {},
	selectorValues = [],
}) => {
	return selectorValues.filter(({ questionAnswers = [] }) => {
		return questionAnswers.every(({
			answer = '',
			question = '',
		}) => {
			const matchingQuestionModel = questionsModel.find(({ key = '' }) => question === key);

			if (matchingQuestionModel) {
				const { answersModel = [] } = matchingQuestionModel;

				return answersModel.some(({ key = '' }) => answer === key);
			}

			return false;
		});
	});
};

// eslint-disable-next-line default-param-last
export const getSelectorValueByArticleNumber = (selectorValues = [], articleNumberToLookup) => {
	return selectorValues.find(({ articleNumbers = [] }) => articleNumbers.includes(articleNumberToLookup));
};

export const getSelectorValuesByPeerId = (selectorValues = [], peerIdToMatch = '') => {
	return selectorValues.filter(({ peerId = '' }) => peerId === peerIdToMatch);
};

export const getSelectorValueByPeerId = (selectorValues = [], peerIdToMatch = '') => {
	return selectorValues.find(({ peerId = '' }) => peerId === peerIdToMatch);
};

export const getStockedQuestionSelectorValues = ({
	materialSelectorModel = [],
	questionSelectorValues = [],
	selectedProduct = {},
	selectedProducts = [],
}) => {
	const {
		hasMatchingProductSelector: materialSelectorHasMatchingProductSelector = false,
		label: materialSelectorLabel = '',
		selectedSelectorValue: {
			stockedArticleNumbers: materialStockedArticleNumbers = [],
		} = {},
	} = materialSelectorModel || {};

	return questionSelectorValues.filter(({
		pricesByArticle = {},
		questionAnswers = [],
		stocked = false,
		stockedArticleNumbers = [],
	}) => {
		const productModelsByQuestionAnswers = getProductModelsByQuestionAnswers({
			productModels: selectedProducts,
			questionAnswers,
		});

		const [firstProductModelByQuestionAnswers = {}] = productModelsByQuestionAnswers;

		// selectedProduct may be invalid for this selctorValue configuration, use the first valid product instead to determine stocked
		const selectedProductToUse = !productModelsByQuestionAnswers.includes(selectedProduct) ? firstProductModelByQuestionAnswers : selectedProduct;

		const {
			articleNumber = '',
			nonCustomArticles = [],
		} = selectedProductToUse;

		const matchingNonCustomArticleNumber = nonCustomArticles.find((nonCustomArticleNumber = '') => pricesByArticle[nonCustomArticleNumber]);

		const priceByArticle = pricesByArticle[matchingNonCustomArticleNumber] || pricesByArticle[articleNumber];

		if (priceByArticle) {
			// product groups with a Fabric or Leather material question are special - we respect the intersection of the currently selected material stocked status
			if (['Fabric', 'Leather'].includes(materialSelectorLabel) || materialSelectorHasMatchingProductSelector) {
				return stockedArticleNumbers.some((stockedArticleNumber = '') => materialStockedArticleNumbers.includes(stockedArticleNumber));
			}

			const { stocked: priceByArticleStocked = false } = priceByArticle;

			return priceByArticleStocked;
		}

		return stocked;
	});
};

export const preselectProductSelectorValues = ({
	productSelectorModels = [],
	productSelectorValues = [],
	selectedProductSelectorValues = [],
}) => {
	selectedProductSelectorValues.forEach((selectedSelectorValue = {}) => {
		const { peerId = '' } = selectedSelectorValue;

		const peerProductSelectorValues = productSelectorValues.filter(({ peerId: selectorValuePeerId = '' }) => selectorValuePeerId === peerId);

		peerProductSelectorValues.forEach((peerSelectorValue = {}) => {
			const peerParentSelectorModel = getParentSelector(productSelectorModels, peerSelectorValue);

			const { selectorValues = [] } = peerParentSelectorModel || {};

			selectorValues.forEach((selectorValue = {}) => {
				selectorValue.preselected = false;
			});

			peerSelectorValue.preselected = true;
		});
	});
};

export const preselectSelectorsAndSelectorValues = (selectorsAndSelectorValues = [], selectedSelectorValues = []) => {
	selectorsAndSelectorValues.forEach((selectorOrSelectorValue) => {
		const {
			preselected = false,
			products = [],
		} = selectorOrSelectorValue;

		selectorOrSelectorValue.wasPreselected = preselected;

		products.forEach((product) => { product.preselected = false; });

		selectorOrSelectorValue.preselected = false;
	});

	const selectedProductSelectorValue = selectedSelectorValues.find(({ hasSelectors = false }) => hasSelectors) || {};

	const {
		aggregatedQuestionSelector: {
			selectedSelectorValue: {
				peerId: selectedAggregatedQuestionSelectorValuePeerId = '',
			} = {},
		} = {},
	} = selectedProductSelectorValue;

	const matchingProductSelectorValues = getSelectorValuesByPeerId(selectorsAndSelectorValues, selectedProductSelectorValue.peerId);

	// filter out these selectorValues in the loop below - they have the same peerId as the one we ACTUALLY want to select, so it's hard to discern between them
	const nonMatchingAggregatorSelectorValues = matchingProductSelectorValues.filter(({ aggregatedQuestionSelector }) => {
		if (!aggregatedQuestionSelector) {
			return false;
		}

		const { selectorValues = [] } = aggregatedQuestionSelector;

		return !selectorValues.find(({ peerId = '' }) => {
			return peerId === selectedAggregatedQuestionSelectorValuePeerId;
		});
	}).filter(Boolean);

	selectedSelectorValues.forEach((selectedSelectorValue = {}) => {
		const { peerId = '' } = selectedSelectorValue;

		const matchingSelectorValues = getSelectorValuesByPeerId(selectorsAndSelectorValues, peerId);

		const filteredMatchingSelectorValues = matchingSelectorValues.filter(matchingSelectorValue => !nonMatchingAggregatorSelectorValues.includes(matchingSelectorValue));

		filteredMatchingSelectorValues.forEach((matchingSelectorValue) => {
			const {
				products = [],
				productSelectorValue = false,
			} = matchingSelectorValue;

			const matchingParentSelector = getParentSelector(selectorsAndSelectorValues, matchingSelectorValue);

			if (productSelectorValue) {
				products.forEach((product) => { product.preselected = true; });
			}

			matchingSelectorValue.preselected = true;

			if (matchingParentSelector) {
				matchingParentSelector.preselected = true;
			}
		});
	});
};

export const selectProductQuestionAnswers = ({
	productModels = [],
	questionSelectorValues = [],
}) => {
	questionSelectorValues.forEach(({ questionAnswers = [] }) => {
		productModels.forEach(({
			// articleNumber = '',
			questionsModel = [],
		}) => {
			questionAnswers.forEach(({
				answer: selectorValueAnswer = '',
				group: selectorValueGroup = '',
			}) => {
				const productQuestion = questionsModel.find(({ group: productQuestionGroup }) => productQuestionGroup === selectorValueGroup);

				if (productQuestion) {
					let foundMatchingProductAnswerKey = false;

					productQuestion.answersModel.forEach((productAnswer) => {
						const { key: productAnswerKey } = productAnswer;

						if (productAnswerKey === selectorValueAnswer) {
							productAnswer.selected = true;
							foundMatchingProductAnswerKey = true;
						} else {
							productAnswer.selected = false;
						}
					});

					if (!foundMatchingProductAnswerKey) {
						// console.warn(`Could not find product answer ${selectorValueAnswer} for product question group ${selectorValueGroup} on article ${articleNumber}`);
					}
				} else {
					// console.warn(`Could not find product question group ${selectorValueGroup} on article ${articleNumber}`);
				}
			});
		});
	});
};

export const selectProductSelectorAndAncestors = ({
	productSelector = {},
	productSelectorModels = [],
	productSelectorValues = [],
}) => {
	const parentSelectorValue = getParentSelectorValue(productSelectorValues, productSelector);

	if (parentSelectorValue) {
		const parentSelector = getParentSelector(productSelectorModels, parentSelectorValue);

		deselectProductSelectorAndDescendants(parentSelector);

		selectProductSelectorAndAncestors({
			productSelector: parentSelector,
			productSelectorModels,
			productSelectorValues,
		});

		parentSelectorValue.selected = true;
	}
};

export const selectProductSelectorValue = ({
	productSelectorModels = [],
	productSelectorValue = {},
	productSelectorValues = [],
}) => {
	const productSelector = getParentSelector(productSelectorModels, productSelectorValue);

	deselectProductSelectorAndDescendants(productSelector);

	selectProductSelectorAndAncestors({
		productSelector,
		productSelectorModels,
		productSelectorValues,
	});

	selectProductSelectorValueAndDescendants(productSelectorValue);

	clearSelectorValueDescendantsFilters(productSelectorValue);
};

export const selectProductSelectorValueAndDescendants = (selectorValueToSelect = {}) => {
	const { selectors = [] } = selectorValueToSelect || {};

	selectors.forEach((selector) => {
		const { selectorValues = [] } = selector;

		const preselectedSelectorValue = selectorValues.find(({ preselected = false }) => preselected);

		const availableSelectorValue = selectorValues.find(({ unavailable = false }) => !unavailable);

		selectProductSelectorValueAndDescendants(preselectedSelectorValue || availableSelectorValue || selectorValues[0]);
	});

	if (selectorValueToSelect) {
		selectorValueToSelect.selected = true;
	}
};

export const selectSelectorAndAncestors = (selectorsAndSelectorValues = [], selectorToSelect = {}, disableDeselectSelectorAndDescendants = false) => {
	const parentSelectorValue = getParentSelectorValue(selectorsAndSelectorValues, selectorToSelect);

	if (parentSelectorValue) {
		const parentSelector = getParentSelector(selectorsAndSelectorValues, parentSelectorValue);

		if (!disableDeselectSelectorAndDescendants) {
			deselectSelectorAndDescendants(parentSelector);
		}

		selectSelectorAndAncestors(selectorsAndSelectorValues, parentSelector);

		parentSelectorValue.selected = true;
	}

	if (selectorToSelect) {
		selectorToSelect.selected = true;
	}
};

export const selectSelectorValue = (flattenedSelectorsAndSelectorValues = [], selectorValueToSelect = {}, disableDeselectSelectorAndDescendants = false) => {
	const parentSelector = getParentSelector(flattenedSelectorsAndSelectorValues, selectorValueToSelect);

	deselectSelectorAndDescendants(parentSelector);

	selectSelectorAndAncestors(flattenedSelectorsAndSelectorValues, parentSelector, disableDeselectSelectorAndDescendants);

	selectSelectorValueAndDescendants(selectorValueToSelect);

	clearSelectorValueDescendantsFilters(selectorValueToSelect);
};

export const selectSelectorValueAndDescendants = (selectorValueToSelect = {}) => {
	const { selectors = [] } = selectorValueToSelect || {};

	selectors.forEach((selector) => {
		const { selectorValues = [] } = selector;

		const preselectedSelectorValue = selectorValues.find(({ preselected = false }) => preselected);

		const availableSelectorValue = selectorValues.find(({ unavailable = false }) => !unavailable);

		selectSelectorValueAndDescendants(preselectedSelectorValue || availableSelectorValue || selectorValues[0]);

		selector.selected = true;
	});

	if (selectorValueToSelect) {
		selectorValueToSelect.selected = true;
	}
};
