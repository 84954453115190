import { makeObservable, observable } from 'mobx';

class MediaWorkspaceCasegoods {
	caption

	index

	selected

	type

	workspaceModel

	constructor() {
		makeObservable(this, {
			selected: observable,
		});
	}
}

export const MediaWorkspaceCasegoodsModelFactory = ({
	create: ({
		caption = '',
		index = 0,
		selected = false,
		type = 'WORKSPACE_CASEGOODS',
		workspaceModel = {},
	}) => {
		const mediaWorkspaceCasegoods = new MediaWorkspaceCasegoods();

		Object.assign(mediaWorkspaceCasegoods, {
			caption,
			index,
			selected,
			type,
			workspaceModel,
		});

		return mediaWorkspaceCasegoods;
	},
});
