import {
	action, computed, observable, makeObservable,
} from 'mobx';
import axios from 'axios';

import { curalateItemReportFormModel, curalateItemReportFormSettings } from '~/util/curalate/Models/CuralateItemReport.model';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';

class CuralateItemReportStore {
	form = null;

	hasError = false;

	isSubmitted = false;

	fanreelId = null;

	curalateModel = null;

	constructor(settings) {
		makeObservable(this, {
			form: observable.ref,
			hasError: observable,
			isSubmitted: observable,
			selectedCuralateItem: computed,
			reportCuralateItem: action.bound,
			resetReportCuralateItem: action.bound,
		});

		Object.assign(this, settings);

		this.form = new FormBuilder(
			curalateItemReportFormModel(),
			curalateItemReportFormSettings(this),
		);
	}

	get selectedCuralateItem() {
		return this.curalateModel.selectedItem;
	}

	reportCuralateItem(formModel) {
		const {
			reportReason,
			userEmail,
		} = formModel;

		const {
			fanreelId,
			selectedCuralateItem,
		} = this;

		const { id } = selectedCuralateItem;

		const postData = {
			deets_id: id,
			message: reportReason,
			reel_id: fanreelId,
			source_url: window.location.href,
			user_email: userEmail,
		};

		const reportCuralateItemPromise = axios({
			data: JSON.stringify(postData),
			method: 'post',
			transformRequest: [
				(data, headers) => {
					headers.clear();
					headers.setContentType('application/json; charset=utf-8');

					return data;
				},
			],
			url: 'https://api.curalate.com/v1/reels/report',
		}).then(() => {
			this.isSubmitted = true;
		}).catch(() => {
			this.hasError = true;
		});

		return reportCuralateItemPromise;
	}

	resetReportCuralateItem() {
		const { model } = this.form;

		Object.assign(model, {
			reportReason: '',
			userEmail: '',
		});

		Object.assign(this, {
			hasError: false,
			isSubmitted: false,
		});
	}
}

export { CuralateItemReportStore };
