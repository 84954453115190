import React from 'react';
import { observer } from 'mobx-react';

import { WorkspaceProductWidthCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductWidthCasegoods';
import { WorkspaceProductWidthDraggableCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductWidthDraggableCasegoods';
import { WorkspaceProductFrameDraggableCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-frame/WorkspaceProductFrameDraggableCasegoods';
import { WorkspaceProductFrameImageCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-frame/WorkspaceProductFrameImageCasegoods';
import { WorkspaceProductSlotsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-slot/WorkspaceProductSlotsCasegoods';
import { WorkspaceProductSlotsDroppableCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-slot/WorkspaceProductSlotsDroppableCasegoods';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/product/workspace-product.module.scss';

export const WorkspaceProductCasegoods = observer((props = {}) => {
	const {
		imageScale = 0,
		isDraggable = false,
		isMediaThumbnail = false,
		workspaceProduct = {},
		workspaceStore = {},
	} = props;

	const {
		workspaceModel: {
			showDimensions = false,
		} = {},
	} = useProductContext();

	return (
		<div className={styles['workspace-product']}>
			{
				showDimensions && !isMediaThumbnail && isDraggable &&
				<WorkspaceProductWidthDraggableCasegoods canShowWorkspaceErrorMessage={true} workspaceProduct={workspaceProduct} workspaceStore={workspaceStore} />
			}
			{
				showDimensions && !isMediaThumbnail && !isDraggable &&
				<WorkspaceProductWidthCasegoods workspaceProduct={workspaceProduct} />
			}
			<div className={styles['workspace-product-draggable-container']}>
				{
					isDraggable
						? <WorkspaceProductFrameDraggableCasegoods
							imageScale={imageScale}
							workspaceProduct={workspaceProduct}
						/>
						: <WorkspaceProductFrameImageCasegoods
							imageScale={imageScale}
							workspaceProduct={workspaceProduct}
						/>
				}
				{
					isDraggable
						? <WorkspaceProductSlotsDroppableCasegoods
							imageScale={imageScale}
							workspaceProduct={workspaceProduct}
						/>
						: <WorkspaceProductSlotsCasegoods
							imageScale={imageScale}
							workspaceProduct={workspaceProduct}
						/>
				}
			</div>
		</div>
	);
});
