import { WorkspaceCasegoodsModelFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Models/WorkspaceCasegoods.model';
import {
	dimensionsWorkspaceCasegoods,
	imageScaleWorkspaceCasegoods,
	imageSettingsWorkspaceCasegoods,
	productGroupOverrideSettingsWorkspaceCasegoods,
	settingsWorkspaceCasegoods,
} from '~/product/casegoods/workspaces/defaultWorkspace/workspace-casegoods.constants';

export const createWorkspaceCasegoodsModel = ({
	frameProductModels = [],
	productGroupModel: {
		productPageType = '',
		key = '',
	} = {},
	selectorConfigModel = {},
	selectorMagicTabccordionStore = {},
	workspaceOverrideModel = {},
}) => {
	const firstFrameProductModel = frameProductModels.length ? frameProductModels[0] : {};

	const { imageCollectionName = '' } = firstFrameProductModel;

	const settings = settingsWorkspaceCasegoods[productPageType] || settingsWorkspaceCasegoods.DEFAULT;

	const productGroupSettingOverrides = productGroupOverrideSettingsWorkspaceCasegoods(key) || {};

	const settingsToUse = { ...settings, ...productGroupSettingOverrides };

	let dimensionsSettings = dimensionsWorkspaceCasegoods[imageCollectionName];

	if (!dimensionsSettings) {
		dimensionsSettings = dimensionsWorkspaceCasegoods.DEFAULT;

		console.warn(`workspace dimensionsSettings not found for imageCollectionName ${imageCollectionName}`);
	}

	let imageScale = imageScaleWorkspaceCasegoods[imageCollectionName];

	if (!imageScale) {
		imageScale = imageScaleWorkspaceCasegoods.DEFAULT;

		console.warn(`workspace imageScale not found for imageCollectionName ${imageCollectionName}`);
	}

	let imageSettings = imageSettingsWorkspaceCasegoods[imageCollectionName];

	if (!imageSettings) {
		imageSettings = imageSettingsWorkspaceCasegoods.DEFAULT;

		console.warn(`workspace imageSettings not found for imageCollectionName ${imageCollectionName}`);
	}

	const workspaceModel = WorkspaceCasegoodsModelFactory.create({
		dimensionsSettings,
		imageScale,
		imageSettings,
		selectorConfigModel,
		selectorMagicTabccordionStore,
		settings: settingsToUse,
		workspaceOverrideModel,
	});

	return workspaceModel;
};
