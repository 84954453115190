import { extendObservable } from 'mobx';
import React from 'react';

import { MagicTabccordionStore } from '~/components/magic-tabccordion/Stores/magicTabccordion.store';
import { SelectorFiltersTabccordionTab } from '~/product/common/selector/Components/filters/SelectorFiltersTabccordionTab';
import { SelectorFiltersHeading } from '~/product/common/selector/Components/filters/SelectorFiltersHeading';

export const createSelectorFiltersMagicTabccordionStore = (filtersStore = {}, linkEventStore = {}) => {
	const {
		filtersModel: {
			sortedFilters = [],
		} = {},
	} = filtersStore;

	const tabGroups = [
		{
			tabs: sortedFilters.map((filterModel) => {
				return extendObservable({
					filterModel,
					filtersStore,
					tabTitleComponent: ({
						tab: {
							isActive,
						},
					}) => {
						return (
							<SelectorFiltersHeading
								filterModel={filterModel}
								isActive={isActive}
							/>
						);
					},
				}, {
					get listElementClass() {
						return filterModel.hasSelectedFacets && 'Tabccordion-listItem--selected';
					},
				});
			}),
		},
	];

	const selectorFiltersTabccordionData = {
		allowCloseableTabs: true,
		disableAriaRoles: true,
		disableSmoothScroll: true,
		id: 'selector-filters-tabccordion',
		renderMode: 'TABS',
		tabGroups,
		wrapperRenderer: SelectorFiltersTabccordionTab,
	};

	const selectorFiltersMagicTabccordionStore = new MagicTabccordionStore(selectorFiltersTabccordionData, linkEventStore);

	return selectorFiltersMagicTabccordionStore;
};
