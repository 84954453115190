import React from 'react';
import { observer } from 'mobx-react';

import { CuralateUploadForm } from '~/util/curalate/Components/CuralateUploadForm';
import { CuralateUploadControls } from '~/util/curalate/Components/CuralateUploadControls';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

export const CuralateUpload = observer((props) => {
	const {
		store,
		store: {
			hasError,
			isSubmitted,
		},
		magicModal,
	} = props;

	if (hasError) {
		return (
			<div data-qa="curalate-upload-container" className={`${styles['curalate-upload-container']} ${styles['curalate-upload-response']}`}>
				<p className={`${styles['curalate-upload-response-copy ']} tw-text-red`}>Failed to upload your content.</p>
				<CuralateUploadControls store={store} magicModal={magicModal} />
			</div>
		);
	}

	if (isSubmitted) {
		return (
			<div data-qa="curalate-upload-container" className={`${styles['curalate-upload-container']} ${styles['curalate-upload-response']}`}>
				<p className={styles['curalate-upload-response-copy']}>Your content was uploaded successfully.</p>
				<CuralateUploadControls store={store} magicModal={magicModal} />
			</div>
		);
	}

	return (
		<div data-qa="curalate-upload-container" className={styles['curalate-upload-container']}>
			<CuralateUploadForm store={store} magicModal={magicModal} />
		</div>
	);
});
