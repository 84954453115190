import { reaction } from 'mobx';
import { useRouter } from 'next/router';
import React from 'react';

import { createMagicDeliveryModel, createMagicDeliveryStore } from '~/components/magic-delivery/Helpers/MagicDelivery.init';
import { MagicPagerStore } from '~/components/magic-pager/Stores/MagicPager.store';
import { MagicTabccordionStore } from '~/components/magic-tabccordion/Stores/magicTabccordion.store';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import {
	curalateProductDataSourceId, curalateProductFanreelId, isEngage, isOnServer,
} from '~/global/global.constants';
import { BreadcrumbModel } from '~/layout/Models/Breadcrumb.model';
import { generateDetailsTabData } from '~/product/common/Data/tabs.data';
import { ErrorModals } from '~/product/common/errors/Components/ErrorModals';
import { ErrorsModel } from '~/product/common/errors/Models/Errors.model';
import { createProductRecoStore } from '~/product/common/Helpers/ProductRecos.init';
import { createProductGroupModel } from '~/product/common/Helpers/ProductGroup.init';
import { createMediaSetModel } from '~/media-set/Helpers/MediaSet.init';
import { MediaSetStoreFactory } from '~/media-set/Stores/MediaSet.store';
import { ProductCommonsModelFactory } from '~/product/common/Models/ProductCommons.model';
import { createSelectorMagicTabccordionCasegoodsStore } from '~/product/casegoods/selector/Helpers/SelectorTabccordionCasegoods.init';
import { ConfigurationOverrideCasegoodsModelFactory } from '~/product/casegoods/selector/Models/ConfigurationOverrideCasegoods.model';
import { createFrameProductModels } from '~/product/casegoods/Helpers/FrameProducts.init';
import { createInsertProductModels } from '~/product/casegoods/Helpers/InsertProducts.init';
import { createMediaSetDetailCasegoodsModel } from '~/product/casegoods/media-set/Helpers/MediaSetDetailCasegoods.init';
import { DefaultConfigModelFactory } from '~/product/casegoods/Models/DefaultConfig.model';
import { createSelectorConfigCasegoodsModel } from '~/product/casegoods/selector/Helpers/SelectorConfigCasegoods.init';
import { SelectorConfigCasegoodsStoreFactory } from '~/product/casegoods/selector/Stores/SelectorConfigCasegoods.store';
import { SelectorMaterialCombinedStoreFactory } from '~/product/casegoods/selector/Stores/SelectorMaterialCombined.store';
import { SummaryCasegoodsModelFactory } from '~/product/casegoods/summary/Models/SummaryCasegoods.model';
import { SummaryCasegoodsStoreFactory } from '~/product/casegoods/summary/Stores/SummaryCasegoods.store';
import { createWorkspaceCasegoodsModel } from '~/product/casegoods/workspaces/defaultWorkspace/Helpers/WorkspaceCasegoods.init';
import { createWorkspaceOverrideCasegoodsModel } from '~/product/casegoods/workspaces/defaultWorkspace/Helpers/WorkspaceOverrideCasegoods.init';
import { WorkspaceModalFrameCasegoodsModelFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Models/WorkspaceModalFrameCasegoods.model';
import { WorkspaceModalFrameCasegoodsStoreFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Stores/WorkspaceModalFrameCasegoods.store';
import { WorkspaceModalInsertOptionsCasegoodsModelFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Models/WorkspaceModalInsertOptionsCasegoods.model';
import { WorkspaceModalInsertOptionsCasegoodsStoreFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Stores/WorkspaceModalInsertOptionsCasegoods.store';
import { WorkspaceCasegoodsStoreFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Stores/WorkspaceCasegoods.store';
import { PowerReviewsStoreFactory } from '~/util/power-reviews/Stores/PowerReviews.store';
import { CuralateStoreFactory } from '~/util/curalate/Stores/Curalate.store';
import { LinkEventStoreFactory } from '~/tracking/link-event/Stores/LinkEvent.store';
import { ProductEventsTrackingStoreFactory } from '~/product/common/Stores/ProductEvents.tracking.store';
import { TrackingDebugModeFactory } from '~/tracking/debug/Tracking.debugMode';
import { ReviewEventTrackingStore } from '~/tracking/review-event/Stores/ReviewEvent.tracking.store';
import { isEmpty } from '~/util/isEmpty';

export const productCasegoodsContext = (pageProps) => {
	const {
		pageType,
		printModule,
		productPageModel,
	} = pageProps;

	const router = useRouter();

	const {
		breadcrumbs,
		collectionSearchSegment,
		configurationOverride,
		customQuestionAnswers,
		defaultConfig,
		defaultInsertConfig,
		frameProducts: frameProductsData,
		frameSelector: frameSelectorData,
		insertGroups: insertGroupsData,
		insertMaterialSelectors,
		insertProducts: insertProductsData,
		insertSelector: insertSelectorData,
		insertPlaceholderSelector: insertPlaceholderSelectorData,
		materialSelectors,
		defaultAvailableConfigurations,
		optionsSelector: optionsSelectorData,
		productGroup = {},
		productGroup: {
			environmentMediaSet = [],
			videoMediaSet = [],
		} = {},
		query: {
			limit,
			error = '',
		},
		workspace: workspaceData,
	} = productPageModel;

	const insertSelectorDataToUse = isEmpty(insertSelectorData) ? insertPlaceholderSelectorData : insertSelectorData;

	const {
		globalDynamicModel,
		globalDynamicStore,
		globalStaticModel,
		magicModal,
		cookies,
		featureTogglesModel,
		HREF,
	} = useGlobalContext();

	const linkEventStore = LinkEventStoreFactory.create(featureTogglesModel);

	const isPreconfiguredWorkspace = Boolean(Object.keys(workspaceData).length);

	const configurationOverrideModel = ConfigurationOverrideCasegoodsModelFactory.create(configurationOverride);

	const defaultConfigModel = DefaultConfigModelFactory.create({
		config: defaultConfig,
		workspaceData,
	});

	const defaultInsertConfigModel = DefaultConfigModelFactory.create({
		config: defaultInsertConfig,
		workspaceData,
	});

	const productGroupModel = createProductGroupModel(productGroup);

	const errorsModel = new ErrorsModel(error);

	const frameProductModels = createFrameProductModels({
		configurationOverrideModel,
		frameProductsData,
	});

	const insertProductModels = createInsertProductModels({
		configurationOverrideModel,
		insertProductsData,
	});

	const selectorConfigModel = createSelectorConfigCasegoodsModel({
		configurationOverrideModel,
		customQuestionAnswers,
		defaultAvailableConfigurations,
		defaultConfigModel,
		defaultInsertConfigModel,
		frameProductModels,
		frameSelectorData,
		insertGroupsData,
		insertMaterialSelectors,
		insertProductModels,
		insertSelectorData: insertSelectorDataToUse,
		isPreconfiguredWorkspace,
		linkEventStore,
		materialSelectors,
		optionsSelectorData,
		productGroupModel,
	});

	const { materialCombinedSelectorModel = {} } = selectorConfigModel;

	const selectorConfigStore = SelectorConfigCasegoodsStoreFactory.create({
		linkEventStore,
		productGroupModel,
		selectorConfigModel,
	});

	const selectorMagicTabccordionStore = createSelectorMagicTabccordionCasegoodsStore({
		linkEventStore,
		selectorConfigModel,
	});

	const workspaceOverrideModel = createWorkspaceOverrideCasegoodsModel({
		frameProductModels,
		insertProductModels,
		productGroupModel,
		selectorConfigModel,
		selectorConfigStore,
		workspaceData,
	});

	const workspaceModel = createWorkspaceCasegoodsModel({
		frameProductModels,
		productGroupModel,
		selectorConfigModel,
		selectorMagicTabccordionStore,
		workspaceOverrideModel,
	});

	const workspaceStore = WorkspaceCasegoodsStoreFactory.create({
		insertProductModels,
		productGroupModel,
		selectorConfigModel,
		selectorConfigStore,
		workspaceModel,
	});

	const workspaceModalFrameModel = WorkspaceModalFrameCasegoodsModelFactory.create();

	const workspaceModalFrameStore = WorkspaceModalFrameCasegoodsStoreFactory.create({
		productGroupModel,
		selectorConfigModel,
		selectorConfigStore,
		workspaceModalFrameModel,
		workspaceStore,
		workspaceModel,
	});

	const workspaceModalInsertOptionsModel = WorkspaceModalInsertOptionsCasegoodsModelFactory.create();

	const workspaceModalInsertOptionsStore = WorkspaceModalInsertOptionsCasegoodsStoreFactory.create({ workspaceModalInsertOptionsModel });

	const mediaSetDetailModel = createMediaSetDetailCasegoodsModel({ workspaceModel });

	const mediaSetDetailStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetDetailModel });

	const mediaSetEnvironmentModel = createMediaSetModel({ mediaSetData: environmentMediaSet });

	const mediaSetEnvironmentStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetEnvironmentModel });

	const mediaSetVideoModel = createMediaSetModel({ mediaSetData: videoMediaSet, featureTogglesModel });

	const mediaSetVideoStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetVideoModel });

	const breadcrumbModels = breadcrumbs.map((breadcrumb) => {
		return new BreadcrumbModel(breadcrumb);
	});

	const summaryModel = SummaryCasegoodsModelFactory.create({
		breadcrumbModels,
		productGroupModel,
		selectorConfigModel,
		workspaceModel,
	});

	const materialCombinedSelectorStore = SelectorMaterialCombinedStoreFactory.create({
		isPreconfiguredWorkspace,
		summaryModel,
		materialCombinedSelectorModel,
		selectorConfigModel,
		selectorConfigStore,
	});

	const productTrackingStore = ProductEventsTrackingStoreFactory.create(
		summaryModel,
		featureTogglesModel,
	);

	const trackingDebugMode = TrackingDebugModeFactory.create(featureTogglesModel);

	const summaryStore = SummaryCasegoodsStoreFactory.create({
		featureTogglesModel,
		globalDynamicStore,
		globalStaticModel,
		HREF,
		isPreconfiguredWorkspace,
		magicModal,
		productTrackingStore,
		summaryModel,
		trackingDebugMode,
		workspaceModel,
	});

	const magicDeliveryModel = createMagicDeliveryModel({
		summaryModel,
		trLinkEventCompType: 'product summary',
		trLinkEventCompName: productGroupModel.name,
	});

	const magicDeliveryStore = createMagicDeliveryStore({
		featureTogglesModel,
		globalDynamicStore,
		magicDeliveryModel,
		summaryStore,
	});

	const {
		selectedProduct: {
			urlSuffix = 'empty',
		},
	} = selectorConfigModel;

	const tabData = generateDetailsTabData();
	const reviewsMagicTabccordionStore = new MagicTabccordionStore(tabData, linkEventStore);
	const powerReviewsStore = PowerReviewsStoreFactory.create(selectorConfigModel, productGroupModel, featureTogglesModel);

	const categoryName = breadcrumbModels.find(breadcrumbModel => breadcrumbModel.isCategory)?.pageTitle;
	const subcategoryBreadcrumb = breadcrumbModels.find(breadcrumbModel => breadcrumbModel.isSubcategory);
	const subcategoryName = subcategoryBreadcrumb?.pageTitle;
	const subcategoryUrl = subcategoryBreadcrumb?.url;
	const lastBreadcrumb = breadcrumbModels[breadcrumbModels.length - 1];
	const lastBreadcrumbUrl = lastBreadcrumb?.url;
	const lastBreadcrumbTitle = lastBreadcrumb?.title;
	const defaultProductUrl = `${lastBreadcrumbUrl}/${urlSuffix}`;

	const recoItemWidth = 212;
	const recoItemHeight = 224;
	const recoPageWidth = recoItemWidth;
	const recoPageHeight = recoItemHeight;
	let productRecosStore;
	let productRecosPagerModel;

	if (!isEngage) {
		productRecosStore = createProductRecoStore(
			'rfkid_32',
			router.asPath,
			{
				globalDynamicModel,
				globalStaticModel,
				featureTogglesModel,
			},
			{
				sku: null,
				title: 'You May Also Like',
				analytics: {
					pageId: 'product',
				},
				maxItems: 18,
				forceNonSkuImage: true,
			},
		);

		productRecosPagerModel = new MagicPagerStore(recoPageWidth, recoPageHeight, recoItemWidth, recoItemHeight);
	}

	const productRecentsStore = createProductRecoStore(
		'rfkid_33',
		'',
		{
			globalDynamicModel,
			globalStaticModel,
			featureTogglesModel,
		},
		{
			sku: [],
			title: 'Recently Viewed',
			analytics: {
				pageId: 'product2_recently_viewed',
			},
			maxItems: 18,
		},
	);
	const productRecentsPagerModel = new MagicPagerStore(recoPageWidth, recoPageHeight, recoItemWidth, recoItemHeight);

	if (!isOnServer && productRecosStore) {
		const rfkSkuKeyReaction = reaction(
			() => summaryModel.reflektionSkuKeys,
			(reflektionSkuKeys) => {
				const value = Array.isArray(reflektionSkuKeys) ? reflektionSkuKeys : [reflektionSkuKeys];
				productRecosStore.model.setSku(value);
				rfkSkuKeyReaction();
			},
		);
	}

	const curalateStore = CuralateStoreFactory.create(cookies, magicModal, featureTogglesModel, {
		dataSourceId: curalateProductDataSourceId,
		experienceId: 'product-page-experience',
		fanreelId: curalateProductFanreelId,
		filter: `productId:${productGroupModel.ugcProductGroupId}`,
		limit,
		itemsPerPage: 5,
	});

	const productCommons = ProductCommonsModelFactory.create({
		breadcrumbModels,
		printModule,
		productGroupModel,
		summaryModel,
		selectorConfigModel,
		collectionSearchSegment,
		type: pageType,
	});

	if (!isOnServer && errorsModel.componentKey) {
		const Component = ErrorModals[errorsModel.componentKey];

		if (Component) {
			magicModal.openModal({
				id: 'product-error-modal',
				title: errorsModel.modalTitle,
				maxWidth: '600px',
				content: {
					children: (
						<Component
							productGroupModel={productGroupModel}
							selectorConfigModel={selectorConfigModel}
							goBackFunction={magicModal.closeModal}
						/>
					),
				},
			});
		}
	}

	if (!isOnServer && !isEngage) {
		const reviewEventTracking = new ReviewEventTrackingStore();
		const {
			reviewProductGroupKey = '',
			reviewsAverageOverallRating = 0,
			reviewsCount = 0,
		} = productGroupModel;
		reviewEventTracking.trackReviewStats(reviewProductGroupKey, reviewsAverageOverallRating, reviewsCount);
	}

	// roll up product stores and models here
	const context = {
		breadcrumbModels,
		categoryName,
		curalateStore,
		defaultConfigModel,
		defaultProductUrl,
		errorsModel,
		insertProductModels,
		lastBreadcrumbTitle,
		magicDeliveryModel,
		magicDeliveryStore,
		materialCombinedSelectorStore,
		mediaSetDetailModel,
		mediaSetDetailStore,
		mediaSetEnvironmentModel,
		mediaSetEnvironmentStore,
		mediaSetVideoModel,
		mediaSetVideoStore,
		powerReviewsStore,
		productCommons,
		productGroupModel,
		productPageModel,
		productRecentsStore,
		productRecentsPagerModel,
		productRecosPagerModel,
		productRecosStore,
		productTrackingStore,
		reviewsMagicTabccordionStore,
		selectorConfigModel,
		selectorConfigStore,
		selectorMagicTabccordionStore,
		subcategoryName,
		subcategoryUrl,
		summaryModel,
		summaryStore,
		workspaceModalFrameModel,
		workspaceModalFrameStore,
		workspaceModalInsertOptionsModel,
		workspaceModalInsertOptionsStore,
		workspaceModel,
		workspaceStore,
	};

	return context;
};

export const ProductCasegoodsContextProvider = ({
	pageProps = {},
	pageProps: {
		children,
	} = {},
	productContext = {},
}) => {
	return <productContext.Provider value={productCasegoodsContext(pageProps)}>{children}</productContext.Provider>;
};
