import { computed, makeObservable, observable } from 'mobx';

import { s7ImagePath } from '~/global/global.constants';

export class Media {
	_links;

	altText;

	caption;

	disableClick;

	fileName;

	hasShopThisRooms;

	index;

	screenReaderText;

	selected = false;

	type;

	shopThisRoomUrl;

	constructor() {
		makeObservable(this, {
			hasCaption: computed,
			mediaFriendlyUrl: computed,
			hasImageLink: computed,
			mediaImageUrl: computed,
			selected: observable,
		});
	}

	get hasCaption() {
		return Boolean(this.caption);
	}

	get hasImageLink() {
		return Boolean(this.imageLink);
	}

	get imageUrl() {
		return this.fileName ? `${s7ImagePath}/${this.fileName}` : this.fullyQualifiedUrl;
	}

	get mediaImageUrl() {
		return this.imageUrl;
	}

	get mediaFriendlyUrl() {
		return this.urlFriendlyStrName;
	}
}

export const MediaModelFactory = ({
	create: ({
		_links = {},
		altText = '',
		caption = '',
		disableClick = false,
		fileName = '',
		hasShopThisRooms = false,
		index = 0,
		imageUrl: fullyQualifiedUrl = '',
		screenReaderText = '',
		selected = false,
		type = 'IMAGE',
		shopThisRoomUrl = '',
		urlFriendlyStrName = '',
	}) => {
		const media = new Media();

		Object.assign(media, {
			_links,
			altText,
			caption,
			disableClick,
			fileName,
			index,
			fullyQualifiedUrl,
			screenReaderText,
			selected,
			type,
			hasShopThisRooms,
			shopThisRoomUrl,
			urlFriendlyStrName,
		});

		return media;
	},
});
