import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { useDrop } from 'react-dnd';

import { S7Image } from '~/components/Images/S7Image';
import { s7ContentPath } from '~/global/global.constants';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { updateWorkspacePreflightModel } from '~/product/sectionals/workspace/Utils/WorkspaceSectionals.utils';

import styles from '~/product/sectionals/selector/Components/layout/SelectorLayoutRemoveDroppable.module.scss';

export const SelectorLayoutRemoveDroppable = observer(() => {
	const {
		workspaceModel = {},
		workspaceModel: {
			firstWorkspaceProductModel = {},
			isDraggingWorkspaceProductModel = false,
			workspaceProductModels = [],
		} = {},
		workspacePreflightModel = {},
		workspacePreflightStore = {},
		workspaceStore = {},
	} = useProductContext();

	const [{ isOver = false }, drop] = useDrop({
		accept: 'PRODUCT',
		collect: (monitor) => {
			return {
				isOver: monitor.isOver(),
			};
		},
		drop: ({ workspaceProductModel = {} }: any): void => {
			const workspaceProductPreflightIndexToRemove = workspaceProductModels.indexOf(workspaceProductModel);

			workspacePreflightStore.removeAllWorkspaceProductModels();

			workspacePreflightStore.addFirstWorkspaceProductModel(firstWorkspaceProductModel);

			const { firstWorkspaceProductModel: firstWorkspaceProductPreflightModel = {} } = workspacePreflightModel;

			updateWorkspacePreflightModel({
				workspacePreflightModel,
				workspacePreflightStore,
				workspaceProductModel: firstWorkspaceProductModel,
				workspaceProductPreflightModel: firstWorkspaceProductPreflightModel,
			});

			const { workspaceProductModels: workspaceProductPreflightModels = [] } = workspacePreflightModel;

			const workspaceProductPreflightModelToRemove = workspaceProductPreflightModels[workspaceProductPreflightIndexToRemove];

			// preflight removing an existing piece
			if (workspaceProductPreflightModelToRemove) {
				workspacePreflightStore.removeWorkspaceProductModel(workspaceProductPreflightModelToRemove);
			}

			const { hasOverlappingWorkspaceProducts = false } = workspacePreflightModel;

			if (hasOverlappingWorkspaceProducts) {
				workspaceStore.setShowInvalidConfigurationMessage(true);
			} else {
				workspaceStore.removeWorkspaceProductModel(workspaceProductModel);

				const { hasBufferingWorkspaceProducts = false } = workspaceModel;

				if (hasBufferingWorkspaceProducts) {
					workspaceStore.setShowMinimumSpacingMessage(true);
				}
			}
		},
	});

	return (
		<div
			className={
				classNames(styles['selector-layout-remove-droppable-container'], {
					[styles['selector-layout-remove-droppable-container-active']]: isDraggingWorkspaceProductModel,
					[styles['selector-layout-remove-droppable-container-hover']]: isOver,
				})
			}
		>
			<div
				className={styles['selector-layout-remove-droppable']}
				data-qa="selector-layout-remove-droppable"
				ref={drop}
			>
				<div className={styles['selector-layout-remove-droppable-image']}>
					<S7Image
						alt=""
						height="70"
						src={`${s7ContentPath}/trash`}
						width="70"
					/>
				</div>
				<span className={`${styles['selector-layout-remove-droppable-text']} md:tw-hidden`}>
					Drag here to remove
				</span>
			</div>
		</div>
	);
});
