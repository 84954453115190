import { createSelectorFrameModalModel } from '~/product/casegoods/selector/Helpers/SelectorFrameModal.init';
import { WorkspaceOverrideCasegoodsModelFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Models/WorkspaceOverrideCasegoods.model';
import { getParentSelector, getSelectorValueByArticleNumber } from '~/product/common/selector/Utils/SelectorConfig.utils';

const insertSplitters = ['_INS_', '_CAB_'];

export const createWorkspaceOverrideCasegoodsModel = ({
	frameProductModels = [],
	insertProductModels = [],
	productGroupModel = {},
	selectorConfigModel = {},
	selectorConfigStore = {},
	workspaceData = {},
}) => {
	const {
		flattenedSelectors = [],
		insertsSelectorModel: {
			chunkedProductModels: chunkedInsertProductModels = [],
			combinedArticles: combinedInsertArticles = [],
		} = {},
		settings: {
			frameFirstBuilder = false,
			showFlattenedInsertProducts = false,
		} = {},
		productSelectorValues = [],
		widthSelectorValues = [],
	} = selectorConfigModel;

	const { frameProducts = [] } = workspaceData;

	const workspaceProducts = frameProducts.map(({
		articleNumber: frameArticleNumber = '',
		configuration = {},
		insertProducts = [],
	}, index = 0) => {
		const matchingFrameProductModel = frameProductModels.find(({ articleNumber = '' }) => {
			return articleNumber === frameArticleNumber;
		});

		const widthSelectorValue = widthSelectorValues.find(({ articles = [] }) => {
			return articles.find(({ article = '' }) => article === frameArticleNumber);
		}) || {};

		const { selectors: depthSelectors = [] } = widthSelectorValue;

		const depthSelector = depthSelectors.length ? depthSelectors[0] : {};

		const { chunkedProductModels = [] } = depthSelector;

		const matchingFrameProductModels = chunkedProductModels.find((productModels = []) => productModels.includes(matchingFrameProductModel));

		const { selectorValues: depthSelectorValues = [] } = depthSelector;

		const depthSelectorValue = depthSelectorValues.find(({ articles = [] }) => {
			return articles.find(({ article = '' }) => article === frameArticleNumber);
		}) || {};

		const { selectors: questionSelectors = [] } = depthSelectorValue;

		const doorSelector = questionSelectors.find(({ renderer = '' }) => renderer === 'DOOR') || {};

		const { selectorValues: doorSelectorValues = [] } = doorSelector;

		const doorSelectorValue = doorSelectorValues.find(({
			answer = '',
			question = '',
		}) => configuration[question] === answer);

		const widthSelector = questionSelectors.find(({ renderer = '' }) => renderer === 'WIDTH') || {};

		const { selectorValues: widthQuestionSelectorValues = [] } = widthSelector;

		const widthQuestionSelectorValue = widthQuestionSelectorValues.find(({
			answer = '',
			question = '',
		}) => configuration[question] === answer);

		// find the end-of-the-line product selectorValue, which will drive selectedProduct
		const matchingProductSelectorValue = getSelectorValueByArticleNumber(productSelectorValues, frameArticleNumber);

		const matchingParentSelector = getParentSelector(flattenedSelectors, matchingProductSelectorValue);

		selectorConfigStore.selectSelectorValue(matchingProductSelectorValue);

		const selectorFrameModalModel = createSelectorFrameModalModel({
			defaultConfig: configuration,
			defaultProduct: matchingFrameProductModel,
			productGroupModel,
			selectedSelector: matchingParentSelector,
			selectorConfigModel,
			selectorConfigStore,
			workspaceProductIndex: index,
		});

		const { selectorConfigFrameModalModel = {} } = selectorFrameModalModel;

		const insertProductsFromConfiguration = Object.entries(configuration).reduce((accumulatedInsertProducts, [question = '', answer = '']) => {
			const splitKey = insertSplitters.find(splitter => question.includes(splitter));
			const questionParts = question.split(splitKey);

			if (questionParts.length > 1) {
				const slotId = questionParts[questionParts.length - 1];

				// note that custom cabinets do nat have a compatibleFrameWidth attribute, so that needs to be omitted from the matching when not present
				const matchingInsertProductModel = insertProductModels.find(({
					answerKey = '',
					compatibleFrameWidth = 0,
				}) => answerKey === answer && (!compatibleFrameWidth || compatibleFrameWidth === matchingFrameProductModel.width));

				if (matchingInsertProductModel) {
					Object.assign(accumulatedInsertProducts, { [slotId]: matchingInsertProductModel });
				}
			}

			return accumulatedInsertProducts;
		}, {});

		const insertProductsToUse = Object.keys(insertProductsFromConfiguration).length ? insertProductsFromConfiguration : insertProducts;

		const slots = Object.entries(insertProductsToUse).map(([slotId = '', { articleNumber: insertArticleNumber = '' }]) => {
			const matchingInsertProductModel = insertProductModels.find(({ articleNumber = '' }) => {
				return articleNumber === insertArticleNumber;
			});

			const matchingInsertProductModels = chunkedInsertProductModels.find((productModels = []) => {
				return productModels.includes(matchingInsertProductModel);
			});

			const matchingInsertArticle = combinedInsertArticles.find(({ articleNumber = '' }) => {
				return articleNumber === insertArticleNumber;
			});

			const { lineDrawingImage = '' } = matchingInsertArticle || {};

			const insertProps = {
				imageScale: 5,
				lineDrawingImage,
				productModel: matchingInsertProductModel,
				productModels: showFlattenedInsertProducts ? [matchingInsertProductModel] : matchingInsertProductModels,
			};

			return {
				slotId,
				insertProductModel: matchingInsertProductModel,
				insertProductModels: matchingInsertProductModels,
				insertProps,
			};
		});

		return {
			depthSelector,
			doorSelectorValue,
			frameProductModel: matchingFrameProductModel,
			frameProductModels: matchingFrameProductModels,
			frameSelectorModel: matchingParentSelector,
			...frameFirstBuilder && { selectorConfigFrameModalModel },
			slots,
			widthSelectorValue: widthQuestionSelectorValue,
		};
	});

	return WorkspaceOverrideCasegoodsModelFactory.create({ workspaceProducts });
};
