import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { s7ImagePath } from '~/global/global.constants';

import styles from '#/product/standard/selector/selector-links.module.scss';

export const SelectorLinkFabricOptions = (props) => {
	const {
		productLink: {
			href = '',
		} = {},
	} = props;

	return (
		<a
			className={`${styles['selector-link']} ${styles['selector-link-fabric-options']}`}
			data-qa="selector-link-fabric-options"
			href={href}
		>
			<S7Image
				alt=""
				className={styles['selector-value-drop-down-image']}
				height="38"
				src={`${s7ImagePath}/fabric-promo-selector`}
				width="95"
			/>
			<span className={styles['selector-link-text']}>
				See fabric options
			</span>
		</a>
	);
};
