import React, { useEffect, useRef } from 'react';

import { MagicTabccordionContainer } from '~/components/magic-tabccordion/Components/Containers/MagicTabccordionContainer';

export const SelectorFiltersTabccordion = (props = {}) => {
	const {
		filtersMagicTabccordionStore = {},
		noBorder = false,
	} = props;

	const tabRef = useRef();

	function handleBodyMouseDown(event) {
		const { activeTab } = filtersMagicTabccordionStore;

		if (activeTab) {
			const selectorFilterTabccordionElements = document.querySelectorAll('[data-selector-filters-tabccordion]');

			const targetIsSelectorFilterTabccordion = Array.from(selectorFilterTabccordionElements).some((selectorFilterTabccordionElement) => {
				return selectorFilterTabccordionElement === event.target || selectorFilterTabccordionElement.contains(event.target);
			});

			if (!targetIsSelectorFilterTabccordion) {
				filtersMagicTabccordionStore.deactivateAllTabs();
			}
		}
	}

	useEffect(() => {
		document.querySelector('body').addEventListener('mousedown', handleBodyMouseDown);

		document.querySelector('body').addEventListener('touchstart', handleBodyMouseDown);

		return () => {
			document.querySelector('body').removeEventListener('mousedown', handleBodyMouseDown);

			document.querySelector('body').removeEventListener('touchstart', handleBodyMouseDown);
		};
	}, []);

	return (
		<div
			data-selector-filters-tabccordion="true"
			ref={tabRef}
		>
			<MagicTabccordionContainer
				store={filtersMagicTabccordionStore}
				id="selector-filters-tabccordion"
				noBorder={noBorder}
			/>
		</div>
	);
};
