import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Script from 'next/script';

import { isOnServer } from '~/global/global.constants';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

export const Reviews = observer(() => {
	const {
		powerReviewsStore: {
			REVIEW_DISPLAY_ID,
			reviewsConfig,
		},
	} = useProductContext();

	const reviewsRef = useRef();

	const reviewsObserverRef = useRef(createObserver());

	const [shouldLoadState, setShouldLoadState] = useState(false);

	function createObserver() {
		const options = { rootMargin: '200px 0px 200px 0px' };

		if (isOnServer) {
			return null;
		}

		return new IntersectionObserver(handleIntersect, options);
	}

	function handleIntersect(entries = [], reviewsObserver = {}) {
		const hasIsIntersecting = entries.some(({ isIntersecting = false }) => isIntersecting);

		entries.forEach(({
			isIntersecting = false,
			target = {},
		}) => {
			if (isIntersecting) {
				reviewsObserver.unobserve(target);
			}
		});

		if (hasIsIntersecting) {
			setShouldLoadState(true);
		}
	}

	function handleLoad() {
		if (window.POWERREVIEWS) {
			window.POWERREVIEWS.display.render(reviewsConfig);
		}
	}

	useEffect(() => {
		if (reviewsRef.current && reviewsObserverRef.current) {
			reviewsObserverRef.current.observe(reviewsRef.current);
		}

		return () => reviewsObserverRef.current && reviewsObserverRef.current.disconnect;
	}, [reviewsRef.current]);

	return (
		<>
			<div
				data-tr-link-event-track={false}
				id={REVIEW_DISPLAY_ID}
				ref={reviewsRef}
			/>
			{
				shouldLoadState && (
					<Script
						src="//ui.powerreviews.com/stable/4.0/ui.js"
						strategy="lazyOnload"
						onLoad={handleLoad}
					/>
				)
			}
		</>
	);
});
