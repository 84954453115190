import {
	action, makeObservable, observable, reaction,
} from 'mobx';

import { isOnServer } from '~/global/global.constants';
import { createWorkspaceProductCasegoodsModel } from '~/product/casegoods/workspaces/defaultWorkspace/Helpers/WorkspaceProductCasegoods.init';
import { WorkspaceProductCasegoodsStoreFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Stores/WorkspaceProductCasegoods.store';
import { noop } from '~/util/noop';

class WorkspaceModalFrameCasegoodsStore {
	productGroupModel;

	selectorConfigModel;

	selectorConfigStore;

	depthSelectorValueChangedDisposer;

	workspaceModalFrameModel;

	workspaceModel;

	workspaceStore;

	constructor() {
		makeObservable(this, {
			addFrameToWorkspace: action,
			setDepthSelector: action,
			setDoorSelectorValue: action,
			setFrameProductModel: action,
			setWidthSelectorValue: action,
			setWorkspaceProductModalFrame: action,
			workspaceModalFrameModel: observable,
			workspaceModel: observable,
		});
	}

	addFrameToWorkspace({
		continueOnClick = noop,
		dropProductArticleNumber = '',
		productModels = [],
	}) {
		const {
			depthSelectorValue: {
				articles = {},
			},
			doorSelectorValue = {},
			insertProductModel: {
				answerKey: insertAnswerKey = '',
			} = {},
			insertProps = {},
			workspaceProductModalFrameModel: {
				frameProductModel = {},
				frameProductModel: {
					articleNumber: frameProductArticleNumber = '',
				} = {},
				frameProductModels = [],
				widthSelectorValue = {},
				workspaceProductImageData,
				workspaceProductIndex = 0,
			} = {},
			insertProductModel: insertProductModelToAdd,
		} = this.workspaceModalFrameModel;

		const {
			productModels: selectorConfigFrameProductModels = [],
			settings: {
				useProductsEndpoint = false,
			} = {},
		} = this.selectorConfigModel;

		if (useProductsEndpoint) {
			this.selectorConfigStore.getSelectorData({
				currentInsertArticles: articles || {},
				currentProductModels: productModels,
				currentWorkspaceProducts: this.workspaceModel.workspaceProducts,
				currentFrameProductArticleNumber: frameProductArticleNumber,
				insertAnswerKey,
				hrefToUse: frameProductModel?.links?.FRAME_DOCUMENT?.href || frameProductModel?.links?.STACK_DOCUMENT?.href,
			}).then(() => {
				const matchingFrameProductModel = selectorConfigFrameProductModels.find(({ articleNumber = '' }) => articleNumber === dropProductArticleNumber);

				this.workspaceStore.addOrUpdateWorkspaceProduct({
					doorSelectorValue,
					frameProductModel: matchingFrameProductModel,
					frameProductModels,
					widthSelectorValue,
					workspaceProductImageData,
					workspaceProductIndex,
				});

				if (insertProductModelToAdd) {
					const matchingWorkspaceProductModel = this.workspaceModel.workspaceProducts[workspaceProductIndex];
					const { workspaceProductSlotModels = [] } = matchingWorkspaceProductModel;
					const [firstWorkspaceProductSlotModel] = workspaceProductSlotModels;

					this.workspaceStore.setInsertProduct({
						insertProductModel: insertProductModelToAdd,
						insertProps,
						slotModel: firstWorkspaceProductSlotModel,
						workspaceProduct: matchingWorkspaceProductModel,
					});
				}

				continueOnClick();
			})
				.catch((error) => {
					console.error(error);
				});
		}
	}

	setDepthSelector(depthSelector = {}) {
		const { workspaceProductModalFrameModel = {} } = this.workspaceModalFrameModel;

		this.workspaceModalFrameModel.depthSelector = depthSelector;

		workspaceProductModalFrameModel.depthSelector = depthSelector;
	}

	setDoorSelectorValue(doorSelectorValue = {}, updateWorkspaceProductImages = false) {
		const {
			workspaceProductModalFrameModel = {},
			workspaceProductModalFrameStore = {},
		} = this.workspaceModalFrameModel;

		this.workspaceModalFrameModel.doorSelectorValue = doorSelectorValue;

		workspaceProductModalFrameModel.doorSelectorValue = doorSelectorValue;

		if (updateWorkspaceProductImages) {
			workspaceProductModalFrameStore.getWorkspaceProductImagesData();
		}
	}

	setFrameProductModel(frameProductModel = {}) {
		const { workspaceProductModalFrameModel = {} } = this.workspaceModalFrameModel;
		const prevQuestionModels = workspaceProductModalFrameModel.frameProductModel.questionModels;

		workspaceProductModalFrameModel.frameProductModelOld = frameProductModel;

		Object.assign(workspaceProductModalFrameModel.frameProductModel, {
			questionModels: [...prevQuestionModels],
		});
	}

	setWidthSelectorValue(widthSelectorValue = {}, updateWorkspaceProductImages = false) {
		const {
			workspaceProductModalFrameModel = {},
			workspaceProductModalFrameStore = {},
		} = this.workspaceModalFrameModel;

		this.workspaceModalFrameModel.widthSelectorValue = widthSelectorValue;

		workspaceProductModalFrameModel.widthSelectorValue = widthSelectorValue;

		if (updateWorkspaceProductImages) {
			workspaceProductModalFrameStore.getWorkspaceProductImagesData();
		}
	}

	setWorkspaceProductModalFrame({
		depthSelector = {},
		doorSelectorValue = {},
		frameProductModel = {},
		frameProductModels = [],
		insertProductModelToAdd,
		insertProps = {},
		widthSelectorValue = {},
		workspaceProductIndex = 0,
		workspaceProductImageData,
	}) {
		const workspaceProductModalFrameModel = createWorkspaceProductCasegoodsModel({
			depthSelector,
			doorSelectorValue,
			frameProductModel,
			frameProductModels,
			widthSelectorValue,
			workspaceProductIndex,
			workspaceProductImageData,
		});

		const workspaceProductModalFrameStore = WorkspaceProductCasegoodsStoreFactory.create({
			productGroupModel: this.productGroupModel,
			workspaceProductModel: workspaceProductModalFrameModel,
		});

		workspaceProductModalFrameStore.getWorkspaceProductImagesData();

		Object.assign(this.workspaceModalFrameModel, {
			insertProductModel: insertProductModelToAdd,
			insertProps,
			workspaceProductModalFrameModel,
			workspaceProductModalFrameStore,
		});
	}
}

export const WorkspaceModalFrameCasegoodsStoreFactory = ({
	create: ({
		productGroupModel = {},
		selectorConfigModel = {},
		selectorConfigStore = {},
		workspaceModalFrameModel = {},
		workspaceModel = {},
		workspaceStore = {},
	}) => {
		const workspaceModalFrameCasegoodsStore = new WorkspaceModalFrameCasegoodsStore();

		let depthSelectorValueChangedDisposer = noop;

		Object.assign(workspaceModalFrameCasegoodsStore, {
			productGroupModel,
			selectorConfigModel,
			selectorConfigStore,
			depthSelectorValueChangedDisposer,
			workspaceModalFrameModel,
			workspaceModel,
			workspaceStore,
		});

		if (!isOnServer) {
			depthSelectorValueChangedDisposer = reaction(() => workspaceModalFrameModel.depthSelectorValue, () => {
				const {
					doorSelectorValue: {
						peerId: doorSelectorValuePeerId = '',
					} = {},
					doorSelectorValues = [],
					widthSelectorValue: {
						peerId: widthSelectorValuePeerId = '',
					} = {},
					widthSelectorValues = [],
				} = workspaceModalFrameModel;

				const matchingDoorSelectorValue = doorSelectorValues.find(({ peerId = '' }) => {
					return peerId === doorSelectorValuePeerId;
				});

				const matchingWidthSelectorValue = widthSelectorValues.find(({ peerId = '' }) => {
					return peerId === widthSelectorValuePeerId;
				});

				workspaceModalFrameCasegoodsStore.setDoorSelectorValue(matchingDoorSelectorValue);

				workspaceModalFrameCasegoodsStore.setWidthSelectorValue(matchingWidthSelectorValue);
			});
		}

		Object.assign(workspaceModalFrameCasegoodsStore, { depthSelectorValueChangedDisposer });

		return workspaceModalFrameCasegoodsStore;
	},
});
