import { observer } from 'mobx-react';
import React from 'react';

import { WorkspaceProductControlsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-controls/WorkspaceProductControlsCasegoods';
import { WorkspaceProductDepthCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductDepthCasegoods';
import { WorkspaceProductDimensionsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductDimensionsCasegoods';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/product-controls/workspace-product-controls-group.module.scss';

export const WorkspaceProductControlsGroupCasegoods = observer((props) => {
	const {
		workspaceProduct = {},
	} = props;

	const {
		workspaceModel: {
			settings: {
				showDepth = true,
			} = {},
			showAllDimensionsByTabType = false,
			showControls = false,
			showDimensions = false,
		} = {}
	} = useProductContext();

	return (
		<div className={styles['workspace-product-controls-group']}>
			{
				showDimensions && showDepth && !showAllDimensionsByTabType && (
					<WorkspaceProductDepthCasegoods workspaceProduct={workspaceProduct} />
				)
			}
			{
				showAllDimensionsByTabType && (
					<WorkspaceProductDimensionsCasegoods workspaceProduct={workspaceProduct} />
				)
			}
			{
				showControls && (
					<WorkspaceProductControlsCasegoods workspaceProduct={workspaceProduct} />
				)
			}
		</div>
	);
});
