import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/product/workspace-products-height.module.scss';

export const WorkspaceProductsHeightCasegoods = observer(() => {
	const { workspaceModel = {} } = useProductContext();

	const { workspaceProductsHeight = 0 } = workspaceModel;

	return (
		<div className={styles['workspace-products-height']} data-qa="workspace-products-height">
			<div className={styles['workspace-products-height-line-container']}>
				<div className={styles['workspace-products-height-line']} />
			</div>
			<span className={styles['workspace-products-height-value']}>
				{workspaceProductsHeight}"
			</span>
		</div>
	);
});
