import React from 'react';

export const imageInchesToPixelsFactor = 20;

export const imageSettingsWorkspaceSectionals = {
	fullScreen: {
		defaultImageScale: 5.9,
		workspaceHeight: 560,
		workspaceWidth: 760,
	},
	medium: {
		defaultImageScale: 6,
		workspaceHeight: 560,
		workspaceWidth: 564,
	},
	small: {
		defaultImageScale: 10,
		workspaceHeight: 275,
		workspaceWidth: 292,
	},
	thumbnail: {
		defaultImageScale: 77.5,
		workspaceHeight: 40,
		workspaceWidth: 75,
	},
};

export const isBufferingWorkspaceProductsInches = 58;

export const perpendicularOrientationsMap = {
	'E': ['N', 'S'],
	'N': ['E', 'W'],
	'S': ['E', 'W'],
	'W': ['N', 'S'],
};

export const opposingOrientationMap = {
	'E': 'W',
	'N': 'S',
	'S': 'N',
	'W': 'E',
};

export const opposingSidesMap = {
	'A': 'C',
	'B': 'D',
	'C': 'A',
	'D': 'B',
};

export const orientationByJoinableSidesMapByOrientation = {
	'E': {
		'A': 'E',
		'B': 'S',
		'C': 'W',
		'D': 'N',
	},
	'N': {
		'A': 'N',
		'B': 'E',
		'C': 'S',
		'D': 'W',
	},
	'S': {
		'A': 'S',
		'B': 'W',
		'C': 'N',
		'D': 'E',
	},
	'W': {
		'A': 'W',
		'B': 'N',
		'C': 'E',
		'D': 'S',
	},
};

export const orientationChangeByOrientationMapByOrientation = {
	'N': {
		'N': 0,
		'E': 90,
		'S': 180,
		'W': 270,
	},
	'E': {
		'N': 270,
		'E': 0,
		'S': 90,
		'W': 180,
	},
	'S': {
		'N': 190,
		'E': 270,
		'S': 0,
		'W': 90,
	},
	'W': {
		'N': 90,
		'E': 180,
		'S': 270,
		'W': 0,
	},
};

export const orientationByOrientationChangeMapByOrientation = {
	'N': {
		0: 'N',
		90: 'E',
		180: 'S',
		270: 'W',
	},
	'E': {
		270: 'N',
		0: 'E',
		90: 'S',
		180: 'W',
	},
	'S': {
		180: 'N',
		270: 'E',
		0: 'S',
		90: 'W',
	},
	'W': {
		90: 'N',
		180: 'E',
		270: 'S',
		0: 'W',
	},
};

export const workspaceEmptyTextSectionals = {
	DEPTH: 'Choose sectional depth',
	DEFAULT: <>Drag and drop pieces to <br />build your sectional</>,
	LAYOUT: <>Drag and drop pieces to <br />build your sectional</>,
};

export const defaultImageScaleWorkspaceSectionals = {
	canvas: 1.85,
};

export const defaultDownloadImageWorkspaceSectionals = {
	compression: 0.7,
	mimeType: 'image/jpg',
	fileName: 'SECTIONALS.jpg',
};
