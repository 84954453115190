import React from 'react';
import { observer } from 'mobx-react';

import type { ISelectorDownload } from '../../Interfaces/SelectorDownload.interface';

import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { kebabCase } from '~/util/kebabCase';

interface IProps {
	selectedSelector: ISelectorDownload;
}

export const SelectorHeadingDownload = observer((props: IProps) => {
	const {
		selectedSelector: {
			label = '',
		} = {},
	} = props;

	return (
		<div data-qa={`${kebabCase(label)}-selector-heading`}>
			<div className="selector-heading-label-container">
				<SelectorHeadingLabel label={label} />
			</div>
		</div>
	);
});
