/* eslint-disable @typescript-eslint/no-use-before-define */
import { createDetailsModel } from '~/product/common/details/Helpers/Details.init';
import { createSelectorMaterialCombinedMagicTabccordionStore } from '~/product/casegoods/selector/Helpers/SelectorMaterialCombinedTabccordion.init';
import { SelectorMaterialModelFactory } from '~/product/casegoods/selector/Models/SelectorMaterial.model';
import { SelectorMaterialCombinedModelFactory } from '~/product/casegoods/selector/Models/SelectorMaterialCombined.model';
import { SelectorValueMaterialModelFactory } from '~/product/casegoods/selector/Models/SelectorValueMaterial.model';

export const createSelectorMaterialModel = ({
	selectorData = {},
	wasActive = false,
	defaultAvailableConfigurations,
}) => {
	const {
		label = '',
		type = '',
		values = [],
	} = selectorData;

	const selectorValues = values.map((selectorValueData = {}) => {
		return createSelectorValueMaterialModel({
			parentLabel: label,
			parentType: type,
			selectorValueData,
			wasActive,
		});
	});

	const selectorMaterialModel = SelectorMaterialModelFactory.create({
		...selectorData,
		selectorValues,
		type,
		wasActive,
	});

	if (defaultAvailableConfigurations) {
		selectorValues.forEach((selector) => {
			const availConfig = defaultAvailableConfigurations.find(config => selector.question === config.key && config.values.includes);
			selector.available = availConfig.values.some(v => v.available && v.value === selector.answer);
		});
	}

	return selectorMaterialModel;
};

export const createSelectorMaterialCombinedModel = ({
	linkEventStore = {},
	materialSelectorModels,
	selectorData = {},
	wasActive = false,
}) => {
	const selectorMaterialCombinedMagicTabccordionStore = createSelectorMaterialCombinedMagicTabccordionStore({
		disableSmoothScroll: true,
		isDraggable: true,
		linkEventStore,
		renderMode: 'TABS',
		materialSelectorModels,
	});

	const selectorMaterialCombinedModel = SelectorMaterialCombinedModelFactory.create({
		...selectorData,
		materialSelectorModels,
		selectorMaterialCombinedMagicTabccordionStore,
		type: 'COMBINED_MATERIAL',
		wasActive,
	});

	return selectorMaterialCombinedModel;
};

const createSelectorValueMaterialModel = ({
	parentLabel = '',
	parentType = '',
	selectorValueData = {},
	wasActive = false,
}) => {
	const {
		selectors: selectorsData = [],
		details: detailsData,
		selectorDetails: selectorDetailsData,
	} = selectorValueData;

	const detailsModel = createDetailsModel(detailsData);

	const selectorDetailsModel = createDetailsModel(selectorDetailsData);

	const selectors = selectorsData.map((selectorData = {}) => {
		const { title = '' } = selectorData;

		return createSelectorMaterialModel({
			selectorData: {
				...selectorData,
				label: title,
				selectorValues: selectorsData,
			},
			wasActive,
		});
	});

	const selectorValueMaterialModel = SelectorValueMaterialModelFactory.create({
		...selectorValueData,
		detailsModel,
		parentLabel,
		parentType,
		selectorDetailsModel,
		selectors,
	});

	return selectorValueMaterialModel;
};
