import { computed, observable, makeObservable } from 'mobx';

class CustomQuestionAnswerSelectorValueFrame {
	answerKeys;

	articles;

	available;

	detailsModel;

	filters;

	id;

	image;

	imageSrc;

	isVisible;

	parentDisplayType;

	parentLabel;

	parentRenderer;

	parentType;

	peerId;

	preselected;

	priceGroup;

	pricesByArticle;

	products;

	question;

	questionAnswers;

	selected;

	selectedMaterialSelectorValue;

	selectorDetailsModel;

	selectors;

	status;

	stocked;

	subtitle;

	title;

	wasPreselected;

	_links;

	constructor() {
		makeObservable(this, {
			preselected: observable,
			selected: observable,
			selectedMaterialSelectorValue: observable,
			wasPreselected: observable,
			answer: computed,
			detailImage: computed,
			detailsToRender: computed,
			hasSelectorDetails: computed,
			isMaterialSelectorValue: computed,
			isStockedSelectorValue: computed,
			isStockedSelectorValuePriceGroup: computed,
			preselectedProduct: computed,
			selectorDetailImage: computed,
			selectorDetailLink: computed,
			selectorDetailsToRender: computed,
			selectorValuePriceGroup: computed,
			selectorValuePriceGroupPrice: computed,
			statusToRender: computed,
			materialAPIHref: computed,
		});
	}

	get answer() {
		return this.questionAnswers[this.question];
	}

	get detailImage() {
		return this.detailsModel.find(({ type }) => type === 'image');
	}

	get detailsToRender() {
		return this.detailsModel.filter(({ type }) => type !== 'image');
	}

	get hasSelectorDetails() {
		return Boolean(this.selectorDetailsModel.length);
	}

	get isMaterialSelectorValue() {
		return this.parentRenderer === 'NONE';
	}

	get isStockedSelectorValue() {
		if (this.selectorValuePriceGroup && !this.isMaterialSelectorValue) {
			const { articleNumber = '' } = this.preselectedProduct;

			// product groups with a material question are special - we respect the intersection of the currently selected material stocked status
			if (this.selectedMaterialSelectorValue) {
				const matchingStockedArticleNumbers = Object.entries(this.pricesByArticle).reduce((accumulatedArticleNumbers, [pricesByArticleNumber, { stocked }]) => {
					return stocked ? [...accumulatedArticleNumbers, pricesByArticleNumber] : [...accumulatedArticleNumbers];
				}, []);

				const fabricStockedArticleNumbers = Object.entries(this.selectedMaterialSelectorValue.pricesByArticle).reduce((accumulatedArticleNumbers, [pricesByArticleNumber, { stocked }]) => {
					return stocked ? [...accumulatedArticleNumbers, pricesByArticleNumber] : [...accumulatedArticleNumbers];
				}, []);

				const intersectingNonCustomArticleNumbers = matchingStockedArticleNumbers.filter(matchingNonCustomArticleNumber => fabricStockedArticleNumbers.includes(matchingNonCustomArticleNumber));

				return Boolean(intersectingNonCustomArticleNumbers.length);
			}

			const matchingStockedPricesByArticle = this.pricesByArticle[articleNumber] || {};

			return matchingStockedPricesByArticle.stocked;
		}

		return this.stocked;
	}

	get isStockedSelectorValuePriceGroup() {
		return this.selectorValuePriceGroup ? this.selectorValuePriceGroup.stocked : this.stocked;
	}

	get preselectedProduct() {
		return this.products.find(({ preselected }) => preselected) || this.products[0];
	}

	get selectorDetailImage() {
		return this.selectorDetailsModel.find(({ type }) => type === 'image');
	}

	get selectorDetailLink() {
		return this.selectorDetailsModel.find(({ type }) => type === 'link');
	}

	get selectorDetailsToRender() {
		return this.selectorDetailsModel.filter(({ type }) => type !== 'image');
	}

	get selectorValuePriceGroup() {
		const {
			articleNumber,
			nonCustomArticles = [],
		} = this.products[0];

		const matchingNonCustomArticleNumber = nonCustomArticles.find(nonCustomArticleNumber => this.pricesByArticle[nonCustomArticleNumber]);

		return this.pricesByArticle[matchingNonCustomArticleNumber] || this.pricesByArticle[articleNumber];
	}

	get selectorValuePriceGroupPrice() {
		const {
			pricingInfo: {
				base,
			},
			upcharge = 0,
		} = this.products[0];

		const baseProductOrPriceGroupPrice = this.selectorValuePriceGroup.price
			? this.selectorValuePriceGroup.price
			: base;

		return baseProductOrPriceGroupPrice + upcharge;
	}

	get statusToRender() {
		const { text = '' } = this.status;

		return text !== 'COMPONENT' ? text : '';
	}

	get materialAPIHref() {
		return this._links?.SWATCH_API_LINK?.href;
	}
}

export const CustomQuestionAnswerSelectorValueFrameModelFactory = ({
	create: ({
		answerKeys = '',
		articles = [],
		available = false,
		detailsModel = [],
		filters = {},
		id = '',
		image = {},
		imageSrc = '',
		isVisible = true,
		parentDisplayType = '',
		parentLabel = '',
		parentRenderer = '',
		parentType = '',
		peerId = '',
		preselected = false,
		priceGroup = '',
		pricesByArticle = {},
		products = [],
		question = '',
		questionAnswers = {},
		selected = false,
		selectorDetailsModel = [],
		selectors = [],
		status = {},
		stocked = false,
		subtitle = '',
		title = '',
		wasPreselected = false,
		_links = {},
	}) => {
		const customQuestionAnswerSelectorValueFrame = new CustomQuestionAnswerSelectorValueFrame();

		Object.assign(customQuestionAnswerSelectorValueFrame, {
			answerKeys,
			articles,
			available,
			detailsModel,
			filters,
			id,
			image,
			imageSrc,
			isVisible,
			parentDisplayType,
			parentLabel,
			parentRenderer,
			parentType,
			peerId,
			preselected,
			priceGroup,
			pricesByArticle,
			products,
			question,
			questionAnswers,
			selected,
			selectorDetailsModel,
			selectors,
			status,
			stocked,
			subtitle,
			title,
			wasPreselected,
			_links,
		});

		return customQuestionAnswerSelectorValueFrame;
	},
});
