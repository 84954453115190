import { computed, makeObservable, observable } from 'mobx';

import type { ISelectorConfigSectionals } from '~/product/sectionals/selector/Interfaces/SelectorConfigSectionals.interface';
import type { IWorkspaceProductsChunkSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductsChunkSectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';
import type { IWorkspaceSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceSectionals.interface';

import { WorkspaceProductsChunkSectionalsModelFactory } from '~/product/sectionals/workspace/Models/WorkspaceProductsChunkSectionals.model';
import {
	getFlattenedWorkspaceProductModels, getHasBufferingWorkspaceProducts, getHasOverlappingWorkspaceProducts, getWorkspaceProductData,
} from '~/product/sectionals/workspace/Utils/WorkspaceSectionals.utils';
import { opposingOrientationMap, perpendicularOrientationsMap } from '~/product/sectionals/workspace/workspace-sectionals.constants';
import { intersection } from '~/util/intersection';
import { maxBy } from '~/util/maxBy';
import { minBy } from '~/util/minBy';
import { sortBy } from '~/util/sortBy';

export class WorkspaceSectionalsModel implements IWorkspaceSectionals {
	dragOrientation: string

	dragPreviewHeight: number

	dragPreviewWidth: number

	firstWorkspaceProductModel: IWorkspaceProductSectionals | null

	isDragging: boolean

	isDraggingWorkspaceProductModel: IWorkspaceProductSectionals | null

	lastWorkspaceProductModel: IWorkspaceProductSectionals | null

	selectorConfigModel: ISelectorConfigSectionals

	selectorMagicTabccordionStore: any

	showInvalidConfigurationMessage: boolean

	showMinimumSpacingMessage: boolean

	constructor({
		dragOrientation = 'N',
		dragPreviewHeight = 0,
		dragPreviewWidth = 0,
		firstWorkspaceProductModel = null,
		isDragging = false,
		isDraggingWorkspaceProductModel = null,
		lastWorkspaceProductModel = null,
		selectorConfigModel = {},
		selectorMagicTabccordionStore = {},
		showInvalidConfigurationMessage = false,
		showMinimumSpacingMessage = false,
	}: any = {}) {
		makeObservable(this, {
			dragOrientation: observable,
			dragPreviewHeight: observable,
			dragPreviewWidth: observable,
			firstWorkspaceProductModel: observable.ref,
			isDragging: observable,
			isDraggingWorkspaceProductModel: observable,
			lastWorkspaceProductModel: observable.ref,
			showInvalidConfigurationMessage: observable,
			showMinimumSpacingMessage: observable,
			accentPillowCountTotal: computed,
			activeTabType: computed,
			additionalWorkspaceProductsChunkModels: computed,
			combinedDepthWorkspaceProductsChunkModels: computed,
			combinedWidthWorkspaceProductsChunkModels: computed,
			combinedWorkspaceProductsChunkModels: computed,
			depthWorkspaceProductsChunkModels: computed,
			forcedWorkspaceProductsChunkModels: computed,
			hasBufferingWorkspaceProducts: computed,
			hasOverlappingWorkspaceProducts: computed,
			hasWorkspaceProductModels: computed,
			lastWorkspaceProductIndex: computed,
			sortedWorkspaceProductModels: computed,
			widthWorkspaceProductsChunkModels: computed,
			workspaceData: computed,
			workspaceProductModels: computed,
			workspaceProductsChunkModelWithFirstWorkspaceProduct: computed,
			workspaceProductsChunkModels: computed,
			workspaceProductsData: computed,
			workspaceProductsDepth: computed,
			workspaceProductsWidth: computed,
			workspaceProductsXEnd: computed,
			workspaceProductsXStart: computed,
			workspaceProductsYEnd: computed,
			workspaceProductsYStart: computed,
		});

		this.dragOrientation = dragOrientation;
		this.dragPreviewHeight = dragPreviewHeight;
		this.dragPreviewWidth = dragPreviewWidth;
		this.firstWorkspaceProductModel = firstWorkspaceProductModel;
		this.isDragging = isDragging;
		this.isDraggingWorkspaceProductModel = isDraggingWorkspaceProductModel;
		this.lastWorkspaceProductModel = lastWorkspaceProductModel;
		this.selectorConfigModel = selectorConfigModel;
		this.selectorMagicTabccordionStore = selectorMagicTabccordionStore;
		this.showInvalidConfigurationMessage = showInvalidConfigurationMessage;
		this.showMinimumSpacingMessage = showMinimumSpacingMessage;
	}

	get accentPillowCountTotal(): number {
		return this.workspaceProductModels.map((item) => {
			return (item.productModel.accentPillowCount) || 0;
		}).reduce((total, next) => {
			return total + next;
		}, 0);
	}

	get activeTabType(): string {
		const {
			activeTab: {
				type = '',
			} = {},
		} = this.selectorMagicTabccordionStore;

		return type;
	}

	get additionalWorkspaceProductsChunkModels(): Array<IWorkspaceProductsChunkSectionals> {
		return this.workspaceProductModels.reduce((additionalWorkspaceProductsChunkModels: Array<IWorkspaceProductsChunkSectionals>, workspaceProductModel: IWorkspaceProductSectionals) => {
			const { showDimensionsOrientations = [] } = workspaceProductModel;

			showDimensionsOrientations.forEach((joinableOrientation = '') => {
				const workspaceProductsChunkModel = WorkspaceProductsChunkSectionalsModelFactory.create({
					isAdditionalWorkspaceProductsChunk: true,
					workspaceProductModels: [workspaceProductModel],
					workspaceProductsChunkOrientation: joinableOrientation,
				});

				additionalWorkspaceProductsChunkModels.push(workspaceProductsChunkModel);
			});

			return additionalWorkspaceProductsChunkModels.filter(({ firstWorkspaceProductModelInChunk }: IWorkspaceProductsChunkSectionals) => {
				const hasOverlappingWorkspaceProductChunks = this.workspaceProductsChunkModels.some(({
					afterWorkspaceProductModel,
					beforeWorkspaceProductModel,
				}: IWorkspaceProductsChunkSectionals) => {
					return [afterWorkspaceProductModel, beforeWorkspaceProductModel].includes(firstWorkspaceProductModelInChunk);
				});

				const hasOverlappingForcedWorkspaceProductChunks = this.forcedWorkspaceProductsChunkModels.some(({
					firstWorkspaceProductModel,
					lastWorkspaceProductModelInChunk,
				}: IWorkspaceProductsChunkSectionals) => {
					return [firstWorkspaceProductModel, lastWorkspaceProductModelInChunk].includes(firstWorkspaceProductModelInChunk);
				});

				return !hasOverlappingWorkspaceProductChunks && !hasOverlappingForcedWorkspaceProductChunks;
			});
		}, []);
	}

	get combinedDepthWorkspaceProductsChunkModels(): Array<IWorkspaceProductsChunkSectionals> {
		return this.combinedWorkspaceProductsChunkModels.filter(({ isDepthWorkspaceProductsChunkModel }: IWorkspaceProductsChunkSectionals) => isDepthWorkspaceProductsChunkModel);
	}

	get combinedWidthWorkspaceProductsChunkModels(): Array<IWorkspaceProductsChunkSectionals> {
		return this.combinedWorkspaceProductsChunkModels.filter(({ isWidthWorkspaceProductsChunkModel }: IWorkspaceProductsChunkSectionals) => isWidthWorkspaceProductsChunkModel);
	}

	get combinedWorkspaceProductsChunkModels(): Array<IWorkspaceProductsChunkSectionals> {
		return [...this.additionalWorkspaceProductsChunkModels, ...this.workspaceProductsChunkModels];
	}

	get depthWorkspaceProductsChunkModels(): Array<IWorkspaceProductsChunkSectionals> {
		return this.workspaceProductsChunkModels.filter(({ isDepthWorkspaceProductsChunkModel }: IWorkspaceProductsChunkSectionals) => isDepthWorkspaceProductsChunkModel);
	}

	get forcedWorkspaceProductsChunkModels(): Array<IWorkspaceProductsChunkSectionals> {
		return this.workspaceProductsChunkModels.filter(({ isForcedWorkspaceProductsChunk }: IWorkspaceProductsChunkSectionals) => isForcedWorkspaceProductsChunk);
	}

	get hasBufferingWorkspaceProducts(): boolean {
		return getHasBufferingWorkspaceProducts(this.workspaceProductModels, this.workspaceProductsChunkModels);
	}

	get hasOverlappingWorkspaceProducts(): boolean {
		return getHasOverlappingWorkspaceProducts(this.workspaceProductModels, this.workspaceProductsChunkModels);
	}

	get hasWorkspaceProductModels(): boolean {
		return Boolean(this.workspaceProductModels.length);
	}

	get lastWorkspaceProductIndex(): number {
		const { workspaceProductIndex = 0 } = this.lastWorkspaceProductModel || {};

		return workspaceProductIndex;
	}

	get sortedWorkspaceProductModels(): Array<IWorkspaceProductSectionals> {
		return sortBy(this.workspaceProductModels, 'workspaceProductIndex');
	}

	get widthWorkspaceProductsChunkModels(): Array<IWorkspaceProductsChunkSectionals> {
		return this.workspaceProductsChunkModels.filter(({ isWidthWorkspaceProductsChunkModel }: IWorkspaceProductsChunkSectionals) => isWidthWorkspaceProductsChunkModel);
	}

	get workspaceData(): any {
		const {
			selectedProduct: {
				articleNumber = '',
			} = {},
			selectedUnattachedOttomanSelectorValue = null,
			selectedQuestionSelectorValues = [],
		} = this.selectorConfigModel;

		const configuration = selectedQuestionSelectorValues.reduce((accumulatedConfiguration: Array<any>, { questionAnswers = [] }) => {
			return [...accumulatedConfiguration, ...questionAnswers];
		}, []);

		return {
			...(selectedUnattachedOttomanSelectorValue?.articleNumber && { selectedOttomanArticle: selectedUnattachedOttomanSelectorValue.articleNumber }),
			selectedProductArticleNumber: articleNumber,
			configuration,
			workspaceOrientation: 'N',
			workspaceProduct: this.workspaceProductsData,
		};
	}

	get workspaceProductModels(): Array<IWorkspaceProductSectionals> {
		if (this.firstWorkspaceProductModel) {
			return getFlattenedWorkspaceProductModels(this.firstWorkspaceProductModel);
		}

		return [];
	}

	get workspaceProductsChunkModelWithFirstWorkspaceProduct(): IWorkspaceProductsChunkSectionals | any {
		const { orientation = '' } = this.firstWorkspaceProductModel || {};

		const perpendicularChunkOrientations = perpendicularOrientationsMap[orientation as keyof typeof perpendicularOrientationsMap];

		const workspaceProductsChunkModelWithFirstWorkspaceProduct = this.workspaceProductsChunkModels.find(({ workspaceProductModels = [] }: any) => {
			return workspaceProductModels.includes(this.firstWorkspaceProductModel);
		});

		const workspaceProductsChunkModelsWithAdjacentFirstWorkspaceProduct = this.workspaceProductsChunkModels.filter(({ workspaceProductModelsWithAdjacentWorkspaceProductModels = [] }: any) => {
			return workspaceProductModelsWithAdjacentWorkspaceProductModels.includes(this.firstWorkspaceProductModel);
		});

		const workspaceProductsChunkModelWithAdjacentFirstWorkspaceProduct = workspaceProductsChunkModelsWithAdjacentFirstWorkspaceProduct.find(({ workspaceProductsChunkOrientation = '' }: any) => {
			return perpendicularChunkOrientations.includes(workspaceProductsChunkOrientation);
		});

		if (['N', 'W'].includes(orientation)) {
			return workspaceProductsChunkModelWithFirstWorkspaceProduct;
		}

		return workspaceProductsChunkModelWithAdjacentFirstWorkspaceProduct || workspaceProductsChunkModelWithFirstWorkspaceProduct;
	}

	get workspaceProductsChunkModels(): Array<IWorkspaceProductsChunkSectionals> {
		const { orientation: firstWorkspaceProductModelOrientation = '' } = this.firstWorkspaceProductModel || {};

		let previousOrientation: string = firstWorkspaceProductModelOrientation;

		let previousWorkspaceProductModel: IWorkspaceProductSectionals | null = null;

		let prevWorkspaceProductsChunkModel: IWorkspaceProductsChunkSectionals | null = null;

		let workspaceProductsChunkIndex: number = 0;

		return this.workspaceProductModels.reduce((workspaceProductsChunkModels: Array<IWorkspaceProductsChunkSectionals>, workspaceProductModel: IWorkspaceProductSectionals) => {
			const {
				backSidesOrientations = [],
				orientation = '',
				workspaceProductJoinableModels = [],
			} = workspaceProductModel;

			const hasPrevWorkspaceProductJoinableModel = workspaceProductJoinableModels.some(({ prevWorkspaceProductModel = {} }) => prevWorkspaceProductModel && (prevWorkspaceProductModel === previousWorkspaceProductModel));

			const { backSidesOrientations: prevBackSidesOrientations = [] } = previousWorkspaceProductModel || {};

			const intersectingBackSidesOrientations = intersection(backSidesOrientations, prevBackSidesOrientations);

			const [firstIntersectingBackSideOrientation = ''] = intersectingBackSidesOrientations;

			const oppositeOrientation = opposingOrientationMap[orientation as keyof typeof opposingOrientationMap];

			const isOppositeOrientation = previousOrientation === oppositeOrientation;

			const forcedWorkspaceProductsChunkModel = WorkspaceProductsChunkSectionalsModelFactory.create({
				isForcedWorkspaceProductsChunk: true,
				isNewChunk: false,
				workspaceProductModels: [previousWorkspaceProductModel, workspaceProductModel],
				workspaceProductsChunkOrientation: firstIntersectingBackSideOrientation,
			});

			const isNewChunk = previousWorkspaceProductModel && !hasPrevWorkspaceProductJoinableModel;

			if (hasPrevWorkspaceProductJoinableModel && isOppositeOrientation) {
				workspaceProductsChunkIndex++;

				workspaceProductsChunkModels.push(forcedWorkspaceProductsChunkModel);
			}

			if (isNewChunk || (orientation !== previousOrientation)) {
				workspaceProductsChunkIndex++;
			}

			previousOrientation = orientation;

			previousWorkspaceProductModel = workspaceProductModel;

			const existingWorkspaceProductsChunkModel = workspaceProductsChunkModels[workspaceProductsChunkIndex];

			const workspaceProductsChunkModel = existingWorkspaceProductsChunkModel || WorkspaceProductsChunkSectionalsModelFactory.create({
				isNewChunk,
				prevWorkspaceProductsChunkModel: !isNewChunk ? prevWorkspaceProductsChunkModel : null,
				workspaceProductModels: [],
				workspaceProductsChunkOrientation: orientation,
			});

			workspaceProductsChunkModel.workspaceProductModels.push(workspaceProductModel);

			prevWorkspaceProductsChunkModel = workspaceProductsChunkModel;

			if (!existingWorkspaceProductsChunkModel) {
				workspaceProductsChunkModels.push(workspaceProductsChunkModel);
			}

			return workspaceProductsChunkModels;
		}, []);
	}

	get workspaceProductsData(): any {
		if (this.firstWorkspaceProductModel) {
			return getWorkspaceProductData(this.firstWorkspaceProductModel);
		}

		return {};
	}

	get workspaceProductsXEnd(): number {
		const maxXEndWorkspaceProduct = maxBy(this.workspaceProductModels, ({ workspaceProductXEnd = 0 }) => workspaceProductXEnd) || {};

		const { workspaceProductXEnd = 0 } = maxXEndWorkspaceProduct;

		return workspaceProductXEnd;
	}

	get workspaceProductsXStart(): number {
		const minXStartWorkspaceProduct = minBy(this.workspaceProductModels, ({ workspaceProductXStart = 0 }) => workspaceProductXStart) || {};

		const { workspaceProductXStart = 0 } = minXStartWorkspaceProduct;

		return workspaceProductXStart;
	}

	get workspaceProductsYEnd(): number {
		const maxYEndWorkspaceProduct = maxBy(this.workspaceProductModels, ({ workspaceProductYEnd = 0 }) => workspaceProductYEnd) || {};

		const { workspaceProductYEnd = 0 } = maxYEndWorkspaceProduct;

		return workspaceProductYEnd;
	}

	get workspaceProductsYStart(): number {
		const minYStartWorkspaceProduct = minBy(this.workspaceProductModels, ({ workspaceProductYStart = 0 }) => workspaceProductYStart) || {};

		const { workspaceProductYStart = 0 } = minYStartWorkspaceProduct;

		return workspaceProductYStart;
	}

	get workspaceProductsDepth(): number {
		return this.workspaceProductsYEnd - this.workspaceProductsYStart;
	}

	get workspaceProductsWidth(): number {
		return this.workspaceProductsXEnd - this.workspaceProductsXStart;
	}
}

export const WorkspaceSectionalsModelFactory = ({
	create: (data: any = {}) => {
		return new WorkspaceSectionalsModel(data);
	},
});
