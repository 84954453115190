import React from 'react';

import { detailComponents } from '~/product/common/details/Components/detail-components';
import styles from '#/product/standard/details/details-materials.module.scss';

export const DetailsList = (props) => {
	const {
		details = [],
		detailsListIndex = 0,
		isCasegoods = false,
		isList = true,
	} = props;

	if (!details.length) {
		return null;
	}

	if (isList) {
		return (
			<ul
				className={`${styles['details-list']} details-list u-noListStyle`}
				data-qa="details-list"
			>
				{
					details.map((detail, index) => {
						const { type } = detail;

						let DetailRenderer = detailComponents[type];

						if (!DetailRenderer) {
							DetailRenderer = detailComponents.nameValue;
							// console.warn(`No detail renderer found for "${type}"`);
						}

						return (
							<li
								className="detail-container"
								key={`detail-${index}`}
							>
								<DetailRenderer
									detail={detail}
									detailsListIndex={detailsListIndex}
									isCasegoods={isCasegoods}
								/>
							</li>
						);
					})
				}
			</ul>
		);
	}

	return (
		<div
			className={`${styles['details-list']} details-list u-noListStyle`}
			data-qa="details-list"
		>
			{
				details.map((detail, index) => {
					const { type } = detail;

					let DetailRenderer = detailComponents[type];

					if (!DetailRenderer) {
						DetailRenderer = detailComponents.nameValue;
						// console.warn(`No detail renderer found for "${type}"`);
					}

					return (
						<div
							className="detail-container"
							key={`detail-${index}`}
						>
							<DetailRenderer
								detail={detail}
								detailsListIndex={detailsListIndex}
								isCasegoods={isCasegoods}
							/>
						</div>
					);
				})
			}
		</div>
	);
};
