import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { runInAction } from 'mobx';

import { addToUrl } from '~/util/addToUrl';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/product/workspace-product-stack.module.scss';

interface propsInterface { slot: {id: string, insertProductModel: any, allow: ''[], rowIndex: number }, workspaceProduct: any, imageInchesToPixelsFactor: number, imageScale: number, productIndex: number, translateBottomPixels: number }

export const WorkspaceProductStackInsertDraggableCasegoods = observer(({
	slot, workspaceProduct, imageInchesToPixelsFactor, imageScale, productIndex, translateBottomPixels
}: propsInterface) => {
	const { workspaceStore = {} } = useProductContext();
	const dragItem = {
		imageScale,
		productIndex,
		productModel: slot.insertProductModel,
		slot,
	};
	const ref: any = useRef();
	const dragItemRef: any = useRef(dragItem);
	const heightInches = slot.insertProductModel?.height || 0;
	const heightPixels = ((imageInchesToPixelsFactor * heightInches) / imageScale) + translateBottomPixels;
	const widthInches = slot.insertProductModel?.compatibleFrameWidth || 0;
	const widthPixels = (imageInchesToPixelsFactor * widthInches) / imageScale;
	const [{ isDragging }, drag, preview] = useDrag(() => ({
		type: 'WORKSPACE_INSERT_PRODUCT',
		collect: monitor => ({ isDragging: monitor.isDragging() }),
		end: ((item, monitor) => {
			runInAction(() => {
				if (!monitor.didDrop()) {
					workspaceStore.clearSlot(dragItemRef.current.slot);
					workspaceStore.updateWorkspaceStackProductsSlotModels();
					workspaceStore.removeWorkspaceProductsWithoutInserts();
				}
				workspaceStore.setInsertProductIsDragging(false);
				workspaceStore.getAvailableInsertValidationData(workspaceProduct); // correct validation for inserts (preflight erases previous validation)
			});
		}),
		item: () => {
			workspaceStore.setInsertProductIsDragging(true);
			return dragItemRef.current;
		},
	}));
	const [, drop] = useDrop({
		accept: ['WORKSPACE_INSERT_PRODUCT'],
		drop: () => {
			return { slot, productIndex };
		}
	});
	useEffect(() => {
		if (isDragging) {
			workspaceStore.getPreflightAvailableInsertValidationData(workspaceProduct, dragItem.slot);
		}
	}, [isDragging]);
	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);
	useEffect(() => {
		dragItemRef.current = dragItem;
	});
	drag(drop(ref));
	return (
		<div className="tw-pointer-events-auto" ref={ref}>
			<img
				alt=""
				className={classNames(`${styles['workspace-product-insert-image']} tw-user-drag-none tw-user-select-none`, {
					'tw-opacity-0': isDragging,
				})}
				src={addToUrl(slot.insertProductModel?.imageUrl, `$proddd$&fmt=png-alpha&scl=${imageScale}`)}
				srcSet={`${addToUrl(slot.insertProductModel?.imageUrl, `$proddd$&fmt=png-alpha&scl=${imageScale / 2}`)} 2x`}
				style={{ height: heightPixels, width: widthPixels }}
			/>
		</div>
	);
});
