import React, { useEffect } from 'react';
import classNames from 'classnames';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/product/workspace-products-message.module.scss';

export const WorkspaceProductsMessageCasegoods = () => {
	const {
		workspaceStore = {},
		workspaceModel: {
			settings: {
				insertMessage,
				hideInsertMessageDesktop,
			},
		} = {},
	} = useProductContext(); // here

	function closeWorkspaceProductsMessage() {
		workspaceStore.setDidShowInsertMessage(true);
	}

	function handleBodyMouseDown(event) {
		const workspaceProductsMessageElements = document.querySelectorAll('[data-workspace-products-message]');

		const targetIsWorkspaceProductsMessage = Array.from(workspaceProductsMessageElements).some((workspaceProductsMessageElement) => {
			return workspaceProductsMessageElement === event.target || workspaceProductsMessageElement.contains(event.target);
		});

		if (!targetIsWorkspaceProductsMessage) {
			closeWorkspaceProductsMessage();
		}
	}

	useEffect(() => {
		document.querySelector('body').addEventListener('mousedown', handleBodyMouseDown);

		document.querySelector('body').addEventListener('touchstart', handleBodyMouseDown);

		return () => {
			document.querySelector('body').removeEventListener('mousedown', handleBodyMouseDown);

			document.querySelector('body').removeEventListener('touchstart', handleBodyMouseDown);
		};
	}, []);

	return (
		<div className={classNames(styles['workspace-products-message-container'], { 'md:tw-hidden': hideInsertMessageDesktop })}>
			<div
				className={classNames(styles['workspace-products-message'], 'tw-hidden-for-print')}
				data-qa="workspace-products-message"
				data-workspace-products-message={true}
			>
				<button
					className={styles['workspace-products-message-close']}
					data-tr-link-event-track="false"
					data-qa="workspace-products-message-close"
					onClick={closeWorkspaceProductsMessage}
				>
					<span className="tw-sr-only">
						Close
					</span>
				</button>
				<div className={styles['workspace-products-message-text']}>
					{insertMessage}
				</div>
			</div>
		</div>
	);
};
