import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import { FullScreenModal } from '~/components/magic-modal/Components/FullScreenModal';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { s7ContentPath } from '~/global/global.constants';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { MediaControlFullscreenClose } from '~/media-set/Components/MediaControlFullscreen';
import { MediaSet } from '~/media-set/Components/MediaSet';
import { mediaCasegoodsComponents } from '~/product/casegoods/media-set/Components/media-components-casegoods';

import styles from '#/product/casegoods/workspace/controls/workspace-control-enlarge.module.scss';
import mediaSetStyles from '#/product/common/media-set/media-set-detail.module.scss';

export const WorkspaceControlEnlargeCasegoods = observer(() => {
	const { magicModal = {} } = useGlobalContext();

	const {
		mediaSetDetailModel = {},
		mediaSetDetailStore = {},
		workspaceModel = {},
		workspaceStore = {},
	} = useProductContext();

	const { hasWorkspaceProducts = false } = workspaceModel;

	const mediaControlFullscreenRef = useRef();

	const mediaControlsFullscreen = (
		<MediaControlFullscreenClose onClick={magicModal.closeModal} />
	);

	function handleClick() {
		// unbox selectedMediaIndex at initialization time - we don't want initialSlide prop to be observable
		const { selectedMediaIndex: selectedMediaIndexFullscreen = 0 } = mediaSetDetailModel;

		if (!window.matchMedia('(max-width: 40em)').matches) {
			magicModal.openModal({
				id: 'media-set-fullscreen',
				title: 'Media Viewer',
				width: '100%',
				maxWidth: '100%',
				showHeader: false,
				showCloseButton: false,
				showOverlay: false,
				absoluteScrollTop: 0,
				WrapperComponent: FullScreenModal,
				onCloseFocusElement: mediaControlFullscreenRef.current,
				onCloseModal: () => workspaceStore.setIsWorkspaceEnlarged(false),
				onOpenModal: () => workspaceStore.setIsWorkspaceEnlarged(true),
				content: {
					children: (
						<div className={mediaSetStyles['media-set-detail-fullscreen']}>
							<MediaSet
								initialSlide={selectedMediaIndexFullscreen}
								mainHeight={600}
								mainWidth={1200}
								mediaComponentsOverride={mediaCasegoodsComponents}
								mediaControls={mediaControlsFullscreen}
								mediaSetModel={mediaSetDetailModel}
								mediaSetStore={mediaSetDetailStore}
								numDotsToShow={7}
								showArrowsForMedium={true}
							/>
						</div>
					),
				},
			});
		}
	}

	if (!hasWorkspaceProducts) {
		return null;
	}

	return (
		<button
			className={`${styles['workspace-control-enlarge']} tw-hidden md:tw-block`}
			data-qa="workspace-control-enlarge"
			onClick={handleClick}
			ref={mediaControlFullscreenRef}
		>
			<div className={styles['workspace-control-enlarge-image-container']}>
				<img
					alt=""
					className={styles['workspace-control-enlarge-image']}
					src={`${s7ContentPath}/enlarge`}
				/>
			</div>
			<span className="tw-sr-only">
				View larger version of Modular Bookcase workspace
			</span>
		</button>
	);
});
