import { observer } from 'mobx-react';
import React from 'react';

import { SelectorFilters } from '~/product/common/selector/Components/filters/SelectorFilters';
import { SelectorMaterial } from '~/product/common/selector/Components/material/SelectorMaterial';
import { SelectorDetails } from '~/product/common/selector/Components/SelectorDetails';
import { SelectorLinks } from '~/product/common/selector/Components/SelectorLinks';

export const SelectorFabric = observer((props) => {
	const {
		isActive = false,
		selectedSelector,
		selectedSelector: {
			label = '',
			matchingQuestionSelectorModel,
			selectedProduct = {},
			selectedSelectorValue: {
				selectedSelector: selectedChildSelector = {},
			} = {},
			showLinks = [],
			type = '',
		} = {},
	} = props;

	const selectedSelectorToUse = type === 'QUESTION' ? selectedSelector : matchingQuestionSelectorModel || selectedChildSelector;

	const {
		filtersMagicTabccordionStore = {},
		filtersModel = {},
		filtersStore = {},
		selectedSelectorValue: {
			hasSelectorDetails = false,
			selectorDetailImage = '',
			selectorDetailsToRender = [],
		} = {},
		showSelectorDetails = false,
	} = selectedSelectorToUse;

	return (
		<div className="selector-fabric">
			{
				showSelectorDetails && hasSelectorDetails && (
					<SelectorDetails
						selectorDetailImage={selectorDetailImage}
						selectorDetails={selectorDetailsToRender}
					/>
				)
			}
			<SelectorFilters
				filtersMagicTabccordionStore={filtersMagicTabccordionStore}
				filtersModel={filtersModel}
				filtersStore={filtersStore}
			/>
			<SelectorMaterial
				isActive={isActive}
				label={label}
				selectedSelector={selectedSelector}
			/>
			<SelectorLinks
				product={selectedProduct}
				links={showLinks}
			/>
		</div>
	);
});
