import React from 'react';
import { observer } from 'mobx-react';

import { CuralateUploadImageField } from '~/util/curalate/Components/CuralateUploadImageField';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

const CuralateUploadSelect = observer(class CuralateUploadSelect extends React.Component {
	constructor(props) { // eslint-disable-line no-useless-constructor
		super(props);
	}

	render() {
		const {
			files,
			store,
		} = this.props;

		const {
			toggleFileSelected,
		} = store;

		const uploadImageFields = files.map((file, index) => {
			return (
				<CuralateUploadImageField changeHandler={toggleFileSelected} file={file} index={index} key={`curalate-upload-image-${index}`} />
			);
		});

		if (uploadImageFields.length) {
			return (
				<fieldset>
					<div className={styles['upload-images-fieldset']}>
						<legend className={'tw-sr-only'}>Select your uploaded content</legend>
						<div className={`field-line ${styles['upload-images-field-line']}`}>
							{uploadImageFields}
						</div>
					</div>
				</fieldset>
			);
		}

		return null;
	}
});

export { CuralateUploadSelect };
