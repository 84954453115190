import React, { MouseEvent } from 'react';

import type { ISiblingSectionalDesignerLeatherLinkProps } from '~/product/common/selector/Components/links/siblingSectionalDesignerLink/Types/SiblingSectionalDesignerLink.types';

import { S7Image } from '~/components/Images/S7Image';
import { s7ImagePath } from '~/global/global.constants';
import { SiblingSectionalDesignerStoreFactory } from '~/product/common/selector/Components/links/siblingSectionalDesignerLink/Stores/SiblingSectionalDesignerLink.store';
import { SiblingSectionalDesignerModelFactory } from '~/product/common/selector/Components/links/siblingSectionalDesignerLink/Models/SiblingSectionalDesignerLink.model';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/standard/selector/selector-links.module.scss';

export const SiblingSectionalDesignerLeatherLink = (props: ISiblingSectionalDesignerLeatherLinkProps) => {
	const {
		productLink: {
			href = '',
		} = {},
	} = props;

	if (!href) {
		return null;
	}

	const {
		productGroupModel = {},
		selectorConfigModel = {},
		summaryModel = {},
		workspaceModel = {},
	} = useProductContext();

	const siblingModel = SiblingSectionalDesignerModelFactory.create({
		href,
		productGroupModel,
		selectorConfigModel,
		summaryModel,
		workspaceModel,
	});

	const siblingStore = SiblingSectionalDesignerStoreFactory.create(siblingModel);

	function handleClick(event: MouseEvent) {
		event.preventDefault();
		siblingStore.getSiblingSectionalDesigner(event, href);
	}

	return (
		<a
			onClick={handleClick}
			className={`${styles['selector-link']} ${styles['selector-link-leather-options']}`}
			data-qa="sibling-selector-link-leather-options"
			href="/"
		>
			<S7Image
				alt=""
				className={styles['selector-value-drop-down-image']}
				height="38"
				src={`${s7ImagePath}/leather-promo-selector`}
				width="95"
			/>
			<span className={styles['selector-link-text']}>
				See leather options
			</span>
		</a>
	);
};
