import {
	computed, makeObservable, observable, action,
} from 'mobx';

import { ProductViewed } from '~/product/common/Models/ProductViewed.tracking.model';

export class ProductViewEvent {
	checkForBackToBackDuplicates: boolean;

	ignoreReflektion: boolean;

	eventName: string;

	mediaSetDetailModel: any;

	changedProductIndex: any;

	summary: any;

	trackAllWorkspaceProducts: boolean;

	wasSuccessful: boolean;

	constructor(summary: any, wasSuccessful: boolean = true, trackAllWorkspaceProducts: boolean = false, checkForBackToBackDuplicates: boolean = true, ignoreReflektion: boolean = false) {
		makeObservable(this, {
			products: computed,
			summary: observable.ref,
			equalTo: action.bound,
		});

		this.eventName = 'productview';
		this.summary = summary;
		this.trackAllWorkspaceProducts = trackAllWorkspaceProducts;
		this.wasSuccessful = wasSuccessful;
		this.checkForBackToBackDuplicates = checkForBackToBackDuplicates;
		this.ignoreReflektion = ignoreReflektion;
	}

	get products() {
		const summaries = this.summary.summaryModels ? this.summary.summaryModels : [this.summary];
		const [firstSummary = {}] = summaries;
		const {
			analytics: firstAnalytics = [],
			workspaceModel: firstWorkspaceModel,
		} = firstSummary;

		if (this.trackAllWorkspaceProducts) {
			return firstWorkspaceModel ? firstAnalytics.map((_: any, index: number) => this.createWorkspaceProductViewModels(firstSummary, index)) : [];
		}

		return summaries.map((sm: { analytics?: [{ articleNumber: any; category: string, name: string, productGroupKey: string, productUrl: string, stockedStatus: any; subcategory: string }] | undefined; productGroupModel: {key: string}, productModel: any; pricing: number | undefined; selectorConfigModel?: { selectedProduct: any; } | undefined; variantIds: Array<string>; workspaceModel: any; }) => {
			const {
				analytics = [],
				productModel,
				selectorConfigModel: {
					selectedProduct,
				} = {},
				variantIds = [],
				workspaceModel,
			} = sm;

			// check for workspace weirdness
			if (workspaceModel) {
				return this.createWorkspaceProductViewModels(sm);
			}

			// custom top and base have multiple products to report being viewed
			if (analytics && analytics.length) {
				const productsViewed = analytics.map((product, index) => {
					const {
						articleNumber,
						category,
						name,
						productGroupKey,
						productUrl,
						stockedStatus,
						subcategory,
					} = product;

					return new ProductViewed(
						selectedProduct || productModel,
						articleNumber,
						stockedStatus,
						category,
						subcategory,
						productUrl,
						name,
						productGroupKey,
						variantIds[index],
					);
				});
				return productsViewed;
			}

			return null;
		}).filter(Boolean).flat();
	}

	get trackingJson() {
		return {
			'event': this.eventName,
			'successful': this.wasSuccessful ? 'pass' : 'fail',
			'product': this.products.map((product: { trackingJson: any; }) => {
				return product.trackingJson;
			}),
			'reflektionSkuKeys': this.ignoreReflektion ? null : this.reflektionSkuKeys,
			'variantIds': this.variantIds,
		};
	}

	createWorkspaceProductViewModels(summaryModel: any, mostRecentProductIndexOverride?: number) {
		const {
			analytics = [{}],
			workspaceModel: {
				sortedWorkspaceProductModels = [],
				workspaceProducts = [],
				lastSelectedInsertProductModel: {
					title: insert = '',
				} = {},
			},
			variantIds = [],
		} = summaryModel;

		const workspaceProductsToUse = sortedWorkspaceProductModels.length ? sortedWorkspaceProductModels : workspaceProducts;

		const products = workspaceProductsToUse.map(({
			frameProductModel,
			productModel,
		}: any) => {
			return frameProductModel || productModel;
		});

		if (products.length) {
			const mostRecentProductIndex = products.length - 1;

			const mostRecentProductIndexToUse = mostRecentProductIndexOverride ?? mostRecentProductIndex;

			if (!analytics[mostRecentProductIndexToUse]) {
				return null;
			}

			const {
				articleNumber,
				category,
				name = '',
				productGroupKey,
				productUrl,
				stockedStatus,
				subcategory,
				webMessage,
			} = analytics[mostRecentProductIndexToUse] || {};

			const defaultStockedStatus = stockedStatus;
			const useDefaultStockedStatus = products.length !== analytics.length;
			const stockedStatusToUse = useDefaultStockedStatus ? defaultStockedStatus : analytics[mostRecentProductIndexToUse].stockedStatus;
			const event = new ProductViewed(
				products[mostRecentProductIndexToUse],
				articleNumber,
				stockedStatusToUse,
				category,
				subcategory,
				productUrl,
				name,
				productGroupKey,
				variantIds[mostRecentProductIndexToUse],
				insert,
				webMessage,
			);
			return event;
		}
		// This shouldn't happen but just in case
		return null;
	}

	get comparatorString() {
		return `[${this.products.map((product: { comparatorString: any; }) => product.comparatorString).join(',')}]`;
	}

	get reflektionSkuKeys() {
		const rfkSkuKeys = this.summary.reflektionSkuKey || this.summary.reflektionSkuKeys;
		return Array.isArray(rfkSkuKeys) ? rfkSkuKeys : [rfkSkuKeys];
	}

	get variantIds() {
		const { variantIds = [] } = this.summary;
		return variantIds;
	}

	equalTo(comparatorString: string) {
		return this.comparatorString === comparatorString;
	}
}
