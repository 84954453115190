import React from 'react';

import { WorkspaceProductsSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductsSectionals';
import { imageSettingsWorkspaceSectionals } from '~/product/sectionals/workspace/workspace-sectionals.constants';

export const MediaWorkspaceSectionals = ({
	imageHeight = 0,
	isMediaThumbnail = false,
}) => {
	const {
		fullScreen: imageSettingsFullScreen = {},
		medium: imageSettingsMedium = {},
		thumbnail: imageSettingsThumbnail = {},
	} = imageSettingsWorkspaceSectionals;

	let imageSettings = isMediaThumbnail ? imageSettingsThumbnail : imageSettingsMedium;

	if (imageHeight > 600) {
		imageSettings = imageSettingsFullScreen;
	}

	return (
		<WorkspaceProductsSectionals
			imageSettings={imageSettings}
			isMainWorkspace={false}
			isDraggable={false}
		/>
	);
};
