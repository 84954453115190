import React from 'react';

import { NextJsTextLink } from '~/components/Buttons/Components/NextJsTextLink';

const isMenuHidden = menu => menu.hiddenOnFull;

export const TopMenuSectionColumnItems = (props) => {
	const {
		colMenuItems = []
	} = props;

	return colMenuItems.map((collMenuItem) => {
		const {
			id: colMenuItemId,
			url: colMenuItemUrl,
			title: colMenuItemTitle,
			classes: colMenuItemClasses,
		} = collMenuItem;

		return !isMenuHidden(collMenuItem)
			// Needs a title for the key since there are duplicate IDs for some reason.
			? <li className={colMenuItemClasses} key={colMenuItemId + colMenuItemTitle}>
				<NextJsTextLink hasNoUnderline={true} href={colMenuItemUrl}><span dangerouslySetInnerHTML={{ __html: colMenuItemTitle }} /></NextJsTextLink>
			</li>
			: null;
	});
};
