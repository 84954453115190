import { observer } from 'mobx-react';
import React from 'react';

import { formatCurrency } from '~/util/formatCurrency';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/product/workspace-product-dimensions.module.scss';

export const WorkspaceProductDimensionsCasegoods = observer((props) => {
	const {
		workspaceProduct: {
			frameProductModel: {
				depth = 0,
				height = 0,
			} = {},
			workspaceProductIndex = 0,
			workspaceProductWidth = 0,
		} = {},
	} = props;

	const {
		summaryModel: {
			isLoading = false,
			prices = [],
		} = {},
		workspaceModel: {
			settings: {
				detailsProductHeadingTitle = '',
				showFramePrice = true,
			} = {},
		} = {},
	} = useProductContext();

	const workspaceProductPrice = prices.length > workspaceProductIndex ? prices[workspaceProductIndex] : 0;

	return (
		<div data-qa="workspace-product-all-dimensions">
			<div className={styles['workspace-product-dimensions-heading']} data-qa="workspace-product-heading">
				{detailsProductHeadingTitle} {workspaceProductIndex + 1}
			</div>
			<div>
				<div data-qa="workspace-product-height">
					{height}" Height
				</div>
				<div data-qa="workspace-product-width">
					{workspaceProductWidth}" Width
				</div>
				<div data-qa="workspace-product-depth">
					{depth}" Depth
				</div>
			</div>
			{ showFramePrice &&
				<div className={styles['workspace-product-dimensions-price']} data-qa="worspace-product-price">
					{
						!isLoading && formatCurrency(workspaceProductPrice, false)
					}
				</div>
			}
		</div>
	);
});
