import { computed, observable, makeObservable } from 'mobx';

import { getMatchingQuestionSelectorValuesByPriceGroup, getProductSelectorValuesByPriceGroup } from '~/product/common/selector/Utils/SelectorConfig.utils';

class SelectorProduct {
	allowUnselected;

	blurbs;

	displayOrder;

	displayType;

	filter;

	filtersMagicTabccordionStore;

	filtersModel;

	filtersStore;

	firstSelectorValueData;

	groupValuesByStocked;

	id;

	isExposed;

	label;

	promos;

	questionSelectorModels;

	renderer;

	selectorValues;

	showAllValues;

	showImages;

	showLinks;

	showSelectorDetails;

	showWidthQuantityMessage;

	summaryRenderer;

	type;

	unselected;

	constructor() {
		makeObservable(this, {
			questionSelectorModels: observable.ref,
			selectorValues: observable.ref,
			unselected: observable,
			filteredSelectorValues: computed,
			hasFilters: computed,
			hasFilterData: computed,
			hasValidSelectorValues: computed,
			matchingQuestionSelectorModel: computed,
			materialSelectorValues: computed,
			preselected: computed,
			preselectedSelectorValue: computed,
			selected: computed,
			selectedMaterialSelector: computed,
			selectedSelectorValue: computed,
			selectorValuesToRender: computed,
			selectorValuesToRenderCount: computed,
			spoMaterialSelectorValues: computed,
			spoSelectorValues: computed,
			spoSelectorValuesByPriceGroup: computed,
			spoSelectorValuesCount: computed,
			stockedMaterialSelectorValues: computed,
			stockedSelectorValues: computed,
			stockedSelectorValuesByPriceGroup: computed,
			validSelectorValues: computed,
		});
	}

	get filteredSelectorValues() {
		const { filteredSelectorValues = [] } = this.filtersModel;

		return filteredSelectorValues;
	}

	get hasFilters() {
		return this.filtersModel.hasFilters;
	}

	get hasFilterData() {
		return Object.keys(this.filter).length;
	}

	get hasValidSelectorValues() {
		return Boolean(this.validSelectorValues.length);
	}

	get matchingQuestionSelectorModel() {
		return this.questionSelectorModels.find(({
			label = '',
			renderer = '',
		}) => {
			return label === this.label && renderer === this.renderer;
		});
	}

	get materialSelectorValues() {
		if (this.matchingQuestionSelectorModel) {
			const { filteredSelectorValues = [] } = this.matchingQuestionSelectorModel || {};

			return filteredSelectorValues;
		}

		const materialSelectorValues = this.selectorValuesToRender.reduce((accumulatedMaterialSelectorValues = [], { selectors = [] } = {}) => {
			const materialSelector = selectors.find(({ renderer = '' }) => renderer === 'NONE') || {};

			const { selectorValuesToRender = [] } = materialSelector;

			return [...accumulatedMaterialSelectorValues, ...selectorValuesToRender];
		}, []).filter(Boolean);

		return Array.from(new Set(materialSelectorValues));
	}

	get preselected() {
		return Boolean(this.preselectedSelectorValue);
	}

	get preselectedSelectorValue() {
		return this.selectorValues.find(({ preselected = false }) => preselected);
	}

	get selected() {
		return Boolean(this.selectedSelectorValue);
	}

	get selectedMaterialSelector() {
		const { selectors = [] } = this.selectedSelectorValue || {};

		return this.matchingQuestionSelectorModel || selectors.find(({ renderer = '' }) => renderer === 'NONE') || {};
	}

	get selectedSelectorValue() {
		return this.selectorValues.find(({ selected = false }) => selected);
	}

	get selectorValuesToRender() {
		return this.filteredSelectorValues;
	}

	get selectorValuesToRenderCount() {
		return this.selectorValuesToRender.length;
	}

	get spoMaterialSelectorValues() {
		return this.materialSelectorValues.filter(({ stocked = false }) => !stocked);
	}

	get spoSelectorValues() {
		return this.selectorValuesToRender.filter(({ stocked = false }) => !stocked);
	}

	get spoSelectorValuesByPriceGroup() {
		if (this.matchingQuestionSelectorModel) {
			return getMatchingQuestionSelectorValuesByPriceGroup({
				productSelectorValues: this.selectorValues,
				quesitonSelectorValues: this.materialSelectorValues,
			});
		}

		return getProductSelectorValuesByPriceGroup({ productSelectorValues: this.spoMaterialSelectorValues });
	}

	get spoSelectorValuesCount() {
		return this.spoSelectorValues.length;
	}

	get stockedMaterialSelectorValues() {
		return this.materialSelectorValues.filter(({ stocked = false }) => stocked);
	}

	get stockedSelectorValues() {
		return this.selectorValuesToRender.filter(({ stocked = false }) => stocked);
	}

	get stockedSelectorValuesByPriceGroup() {
		if (this.matchingQuestionSelectorModel) {
			return getMatchingQuestionSelectorValuesByPriceGroup({
				productSelectorValues: this.selectorValues,
				quesitonSelectorValues: this.materialSelectorValues,
				stocked: true,
			});
		}

		return getProductSelectorValuesByPriceGroup({ productSelectorValues: this.stockedMaterialSelectorValues });
	}

	get validSelectorValues() {
		return this.selectorValues;
	}
}

export const SelectorProductModelFactory = ({
	create: ({
		allowUnselected = false,
		blurbs = [],
		displayOrder = 0,
		displayType = '',
		filter = {},
		filtersMagicTabccordionStore = {},
		filtersModel = {},
		filtersStore = {},
		firstSelectorValueData = {},
		groupValuesByStocked = false,
		id = '',
		isExposed = false,
		label = '',
		promos = [],
		questionSelectorModels = [],
		renderer = '',
		selectorValues = [],
		showAllValues = false,
		showImages = false,
		showLinks = [],
		showSelectorDetails = false,
		showWidthQuantityMessage = false,
		subheader = '',
		summaryRenderer = '',
		type = '',
		unselected = false,
	}) => {
		const selectorProduct = new SelectorProduct();

		Object.assign(selectorProduct, {
			allowUnselected,
			blurbs,
			displayOrder,
			displayType,
			filter,
			filtersMagicTabccordionStore,
			filtersModel,
			filtersStore,
			firstSelectorValueData,
			groupValuesByStocked,
			id,
			isExposed,
			label,
			promos,
			questionSelectorModels,
			renderer,
			selectorValues,
			showAllValues,
			showImages,
			showLinks,
			showSelectorDetails,
			showWidthQuantityMessage,
			subheader,
			summaryRenderer,
			type,
			unselected,
		});

		return selectorProduct;
	},
});
