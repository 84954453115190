import { computed, makeObservable } from 'mobx';

class ProductQuestion {
	answersModel

	group

	key

	mappedFromQuestionGroup

	constructor() {
		makeObservable(this, {
			selectedAnswer: computed,
			selectedAnswerKey: computed,
		});
	}

	get selectedAnswer() {
		return this.answersModel.find(({ selected }) => selected);
	}

	get selectedAnswerKey() {
		const { key = '' } = this.selectedAnswer || {};

		return key;
	}
}

export const ProductQuestionModelFactory = ({
	create: ({
		answersModel = [],
		group = '',
		key = '',
		mappedFromQuestionGroup = '',
	}) => {
		const productQuestion = new ProductQuestion();

		Object.assign(productQuestion, {
			answersModel,
			group,
			key,
			mappedFromQuestionGroup,
		});

		return productQuestion;
	},
});
