import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { SelectorValueInsert } from '~/product/casegoods/selector/Components/insert/SelectorValueInsert';
import { SelectorFilters } from '~/product/common/selector/Components/filters/SelectorFilters';
import { getShouldShowShadowY } from '~/product/common/selector/Utils/Selector.utils';
import { MagicTabccordionContainer } from '~/components/magic-tabccordion/Components/Containers/MagicTabccordionContainer';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { s7ContentPath } from '~/global/global.constants';

import styles from '#/product/casegoods/selector/insert/selector-insert.module.scss';
import frameStyles from '#/product/casegoods/selector/frame/selector-frame.module.scss';

export const SelectorInsert = observer((props) => {
	const {
		isActive = false,
		selectedSelector: {
			filtersMagicTabccordionStore = {},
			filtersModel = {},
			filtersStore = {},
			label = '',
			selectorInsertMagicTabccordionStore = {},
			filteredSelectorValues = [],
		} = {},
	} = props;

	const {
		selectorConfigModel: {
			settings: {
				insertSelectorHeadingTitle,
				showInsertSelectorAsTabs = false,
			} = {},
		} = {},
	} = useProductContext();
	const scrollingParentRef = useRef();
	const scrollingChildRef = useRef();

	const [showShadow, setShowShadow] = useState(false);
	let ticking = false;
	function setShowShadowState() {
		if (showInsertSelectorAsTabs) return;
		const shouldShowShadow = getShouldShowShadowY({
			isActive,
			scrollingChildRef,
			scrollingParentRef,
		});
		setShowShadow(shouldShowShadow);
	}
	function handleScroll() {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				ticking = false;
				setShowShadowState();
			});
			ticking = true;
		}
	}

	useEffect(setShowShadowState, [isActive]);

	if (showInsertSelectorAsTabs) {
		return (
			<div>
				{
					insertSelectorHeadingTitle && (
						<div className={'tw-pb-6 md:tw-hidden'}>
							{`${insertSelectorHeadingTitle}.`}
						</div>
					)
				}
				<div className={`${styles['selector-insert']}`}>
					<MagicTabccordionContainer
						id="selector-insert-tabccordion"
						store={selectorInsertMagicTabccordionStore}
					/>
				</div>
			</div>
		);
	}

	return (<div className={
		classNames('selector-insert-wrapper', {
			'tw-hidden': showInsertSelectorAsTabs,
			'md:tw-block': showInsertSelectorAsTabs,
		})
	}>
		<SelectorFilters
			filtersMagicTabccordionStore={filtersMagicTabccordionStore}
			filtersModel={filtersModel}
			filtersStore={filtersStore}
			useSingleColumn={true}
		/>
		<div
			className={styles['selector-insert-container']}
			onScroll={handleScroll}
			ref={scrollingParentRef}
		>
			<div
				className={
					classNames(styles['selector-insert'], {
						[styles['selector-insert-shadow']]: showShadow,
					})
				}
				ref={scrollingChildRef}
			>
				<div
					className={styles['selector-values-insert']}
					data-qa="selector-values-insert"
				>
					{filteredSelectorValues.length
						? filteredSelectorValues.map((selectorValue, index) => {
							return (
								<SelectorValueInsert
									key={`selector-value-${index}`}
									label={label}
									selectorValue={selectorValue}
								/>
							);
						})
						: <div className="tw-pt-16 tw-pb-32">No inserts available for these frames</div>
					}
				</div>
			</div>
		</div>
	</div>);
});
