import type { GetServerSidePropsContext } from 'next';
import { AxiosResponse } from 'axios';
import React from 'react';
import {
	model, Model, idProp, registerRootStore, prop, modelFlow, _async, _await, modelAction,
} from 'mobx-keystone';

import styles from '~/product/common/shop-this-room-tray/Components/shop-this-room-tray.module.scss';

import type { AppConfigPageProps } from '~/global/app-config/AppConfig.types';
import type { ViewStateConfig } from '~/util/viewState/ViewState.interface';
import type { ShopThisRoomSubcategoryResponse } from '~/subcategory/common/subcategory.type';

import { getAxios } from '~/global/app-config/ipHeaders';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { modelNamespace } from '~/util/modelNamespace';
import { PromiseError } from '~/util/messaging/promise-error/PromiseError';
import { setCookieHeader } from '~/util/setCookieHeader';
import { ShopThisRoomModel } from '~/subcategory/shopThisRoom/Models/ShopThisRoom.model';
import { ShopThisRoomTray } from '~/product/common/shop-this-room-tray/Components/ShopThisRoomTray';
import { ShopThisRoomTrayProducts } from '~/product/common/shop-this-room-tray/Components/ShopThisRoomTrayProducts';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';

export const VIEWS = {
	LOADING: 'LOADING',
	SHOP_THIS_ROOM_PRODUCTS: 'SHOP_THIS_ROOM_PRODUCTS',
} as const;

@model(`${modelNamespace.PRODUCT_PAGE}/ShopThisRoomTray`)
export class ShopThisRoomTrayStore extends Model({
	id: idProp,
	shopThisRoomApiLink: prop<string | undefined>(undefined),
	mediaSetImage: prop<string>(),
}) {
	magicModal: any;

	shopThisRoomModel!: ShopThisRoomModel;

	viewState!: ViewStateStore;

	onInit() {
		this.viewState = new ViewStateStore(this, this.viewStateConfig);
	}

	viewStateConfig: ViewStateConfig = {
		[VIEWS.LOADING]: {
			component: () => <LoadingSpinner isLoading={true} minHeight="500" />,
		},
		[VIEWS.SHOP_THIS_ROOM_PRODUCTS]: {
			component: ShopThisRoomTrayProducts,
			onEnter: (_, store) => {
				return { store };
			},
		},
	};

	@modelAction
	setApiLink(href: string) {
		this.shopThisRoomApiLink = href;
	}

	@modelAction
	setMagicModal(magicModal: any) {
		this.magicModal = magicModal;
	}

	@modelAction
	update(data: ShopThisRoomSubcategoryResponse) {
		this.shopThisRoomModel = new ShopThisRoomModel(data);
	}

	@modelFlow
	fetchData = _async(function* (
		this: ShopThisRoomTrayStore,
		pageProps?: AppConfigPageProps | undefined,
		ctx?: GetServerSidePropsContext | undefined,
	) {
		if (!this.shopThisRoomApiLink) {
			throw new Error('No shopThisRoomApiLink found.');
		}

		const dynaAxios = getAxios(ctx);
		const headers = setCookieHeader(pageProps);
		const promise = dynaAxios.request({
			url: this.shopThisRoomApiLink,
			method: 'get',
			...(headers) && { headers },
		});
		const response: AxiosResponse<any> = yield* _await(promise);
		this.update(response.data);
	});

	onError(error: PromiseError) {
		console.error(error);
		this.closeModal();
	}

	closeModal = () => {
		this.magicModal.closeModal();
	};

	@modelFlow
	openModal = _async(function* (this: ShopThisRoomTrayStore) {
		if (!this.magicModal) {
			return;
		}
		this.viewState.goTo(VIEWS.LOADING);

		this.magicModal.openModal({
			dataQa: 'shop-this-room-tray',
			title: 'Shop This Room',
			isTray: true,
			keepScrollPosition: true,
			containerClass: styles.ShopThisRoomTray,
			onCloseModal: () => {
				document.body.classList.remove('tw-overflow-hidden');
			},
			onOpenModal: () => {
				document.body.classList.add('tw-overflow-hidden');
			},
			trayAlignment: 'right',
			content: {
				children: <ShopThisRoomTray viewState={this.viewState} />,
			},
		});

		try {
			yield* _await(this.fetchData());
			this.viewState.goTo(VIEWS.SHOP_THIS_ROOM_PRODUCTS);
		} catch (error: any) {
			this.onError(error);
		}
	});
}

export const createStore = (href: string, fileName: string): ShopThisRoomTrayStore => {
	const store = new ShopThisRoomTrayStore({ shopThisRoomApiLink: href, mediaSetImage: fileName });

	registerRootStore(store);

	return store;
};
