import {
	idProp,
	Model,
	model,
	prop,
} from 'mobx-keystone';
import { action, observable } from 'mobx';

import { modelNamespace } from '~/util/modelNamespace';
import { ProductPageTemplate } from '~/product/Product.types';
import { Summary } from '~/product/common/summary/Models/Summary.model';
import { SelectorConfig } from '~/product/common/selector/Models/SelectorConfig.model';
import { ProductGroupModel } from '~/product/common/Models/ProductGroup.model';
import { MediaSet } from '~/media-set/Models/MediaSet.model';
import { isOnServer } from '~/global/global.constants';

@model(`${modelNamespace.PRODUCT_PAGE}/Summary/SelectorSummary`)
export class StickySummaryModel extends Model({
	id: idProp,
	productPageTemplate: prop<ProductPageTemplate>(),
	selectorsPosition: prop<number | undefined>().withSetter(),
	summaryStatusPosition: prop<number | undefined>().withSetter(),
}) {
	@observable.ref
	mediaSetDetailModel!: MediaSet;

	@observable.ref
	summaryModel!: Summary;

	@observable.ref
	productGroupModel!: ProductGroupModel;

	@observable.ref
	selectorConfigModel!: SelectorConfig;

	@action
	setModels({
		mediaSetDetailModel, productGroupModel, selectorConfigModel, summaryModel,
	}: { mediaSetDetailModel: MediaSet, productGroupModel: ProductGroupModel, summaryModel: Summary, selectorConfigModel: SelectorConfig }) {
		this.mediaSetDetailModel = mediaSetDetailModel;
		this.productGroupModel = productGroupModel;
		this.selectorConfigModel = selectorConfigModel;
		this.summaryModel = summaryModel;
	}

	get disableSummaries() {
		return isOnServer || this.summaryModel.hasError || !this.summaryModel.isValidQuantity;
	}

	get hasValidNumberOfSelectors() {
		if (this.productPageTemplate === ProductPageTemplate.STANDARD) {
			return this.numberOfSelectors >= 2;
		}
		return true;
	}

	get image() {
		return this.mediaSetDetailModel.mediaModelHead.productImageUrl;
	}

	get isClearance() {
		return this.productPageTemplate === ProductPageTemplate.CLEARANCE;
	}

	get name() {
		return this.productGroupModel?.name || '';
	}

	get summaryTitle() {
		return this.productGroupModel?.summaryTitle || '';
	}

	get modifiedProductName() {
		return this.summaryTitle.length > 33 ? `${this.summaryTitle.substring(0, 33).trimEnd()}...` : this.summaryTitle;
	}

	get numberOfSelectors() {
		return this.selectorConfigModel?.selectedSelectorsToRender ? this.selectorConfigModel?.selectedSelectorsToRender?.length : 0;
	}

	get price() {
		return this.summaryModel?.priceText || '';
	}

	get showStickySummary() {
		if (this.disableSummaries) {
			return false;
		}
		return Boolean(this.productPageTemplate === ProductPageTemplate.STANDARD && this.selectorsPosition && this.selectorsPosition <= 200 && this.numberOfSelectors >= 2);
	}

	get showMiniStickySummary() {
		if (this.disableSummaries || !this.summaryStatusPosition) {
			return false;
		}
		return this.summaryStatusPosition && this.summaryStatusPosition <= 200;
	}
}
