import { extendObservable, observable } from 'mobx';

import { zipCodeInterceptor } from '~/util/formz/interceptors/validationInterceptors';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

export const deliveryFormModel = (defaults = {}) => {
	const model = observable({
		zipCode: '',
	});

	extendObservable(model, defaults);

	zipCodeInterceptor(model, 'zipCode');

	return model;
};

export const deliveryFormSettings = (store) => {
	const formSettings = {
		id: 'deliveryForm',
		reactProps: {
			method: 'POST',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler: (form) => {
							if (!form.plugins.formValidator.hasErrors) {
								store.parentStore.trackingStore
									.trackZipCode('Shopping Cart');

								return store.globalDynamicStore
									.setTransZone(form.model.zipCode);
							}

							return null;
						},
						promiseHandler: (promise) => {
							promise
								.then((response) => {
									store.parentStore.invalidTzone = false;
									store.parentStore.results = response.data;
								}, () => {
									store.parentStore.invalidTzone = true;
								});

							return promise;
						},
					},
				}),
			],
		},
		fields: {
			zipCode: {
				reactProps: {
					className: 'field-group',
				},
				control: {
					reactProps: {
						'type': 'number',
						'autoComplete': 'postal-code',
						'data-qa': 'delivery-widget-input',
						'autoFocus': true,
					},
				},
				label: {
					reactProps: {
						children: 'Enter ZIP Code',
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Please enter a valid ZIP Code',
						},
						zipCode: {
							message: '^Please enter a valid ZIP code',
						},
						length: {
							minimum: 5,
							maximum: 10,
						},
					},
				},
			},
		},
	};

	return formSettings;
};
