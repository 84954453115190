import { observable, makeObservable } from 'mobx';

class SelectorDownload {
	isValid;

	label

	renderer

	type

	constructor() {
		makeObservable(this, {
			isValid: observable,
		});
	}
}

export const SelectorDownloadModelFactory = ({
	create: ({
		isValid = false,
		label = '',
		renderer = '',
		type = '',
	}) => {
		const selectorDownload = new SelectorDownload();

		Object.assign(selectorDownload, {
			isValid,
			label,
			renderer,
			type,
		});

		return selectorDownload;
	},
});
