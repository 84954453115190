import { observer } from 'mobx-react';
import React from 'react';

import { SelectorDetails } from '~/product/common/selector/Components/SelectorDetails';
import { SelectorFilters } from '~/product/common/selector/Components/filters/SelectorFilters';
import { SelectorLinks } from '~/product/common/selector/Components/SelectorLinks';
import { SelectorMaterial } from '~/product/sectionals/selector/Components/material/SelectorMaterial';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

export const SelectorFabric = observer((props) => {
	const {
		isActive = false,
		selectedSelector,
		selectedSelector: {
			filtersMagicTabccordionStore = {},
			filtersModel = {},
			filtersStore = {},
			label = '',
			selectedSelectorValue: {
				hasSelectorDetails,
				selectorDetailImage,
				selectorDetailsToRender = [],
			} = {},
			showLinks = [],
			showSelectorDetails,
		} = {},
	} = props;

	const {
		productGroupModel = {},
	} = useProductContext();

	return (
		<div className="selector-fabric">
			{
				showSelectorDetails && hasSelectorDetails && (
					<SelectorDetails
						selectorDetailImage={selectorDetailImage}
						selectorDetails={selectorDetailsToRender}
					/>
				)
			}
			<SelectorFilters
				filtersMagicTabccordionStore={filtersMagicTabccordionStore}
				filtersModel={filtersModel}
				filtersStore={filtersStore}
			/>
			<SelectorMaterial
				isActive={isActive}
				label={label}
				selectedSelector={selectedSelector}
			/>
			<SelectorLinks
				product={productGroupModel}
				links={showLinks}
			/>
		</div>
	);
});
