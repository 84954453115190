import dynamic from 'next/dynamic';
import React from 'react';

import { formatCurrency } from '~/util/formatCurrency';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { TextButton } from '~/components/Buttons/Components/TextButton';

const FullServiceDeliveryModal = dynamic(
	() => import('~/components/magic-delivery/Components/FullServiceDeliveryModal').then(module => module.FullServiceDeliveryModal),
);

export const deliveryMessages = {
	DELIVERY_RATE_MESSAGE_100({ values }) {
		const { deliveryFee = null } = values;

		if (!deliveryFee) {
			return null;
		}
		return (
			<span className="tw-font-bold tw-text-base">
				{formatCurrency(deliveryFee, true)}
				{' full-service delivery '}
			</span>
		);
	},
	DELIVERY_RATE_MESSAGE_110() {
		return 'Unlimited items per trip, free returns. ';
	},
	DELIVERY_RATE_MESSAGE_120({ store }) {
		if (!store) {
			return null;
		}
		const { magicModal } = useGlobalContext();

		return (
			<>
				<TextButton
					type="button"
					onClick={() => {
						magicModal.openModal({
							title: 'Full-service Delivery',
							content: {
								children: <FullServiceDeliveryModal magicModal={magicModal} />,
							},

						});
					}}
				>See details</TextButton>
				{' '}
			</>
		);
	},
	DELIVERY_RATE_MESSAGE_130() {
		return <span className="tw-font-bold tw-text-base">Free UPS Shipping </span>;
	},
	DELIVERY_RATE_MESSAGE_140({ values }) {
		const { deliveryFee = null } = values;

		if (!deliveryFee) {
			return null;
		}
		return (
			<span className="tw-font-bold tw-text-base">
				{formatCurrency(deliveryFee, true)}
				{' UPS Shipping '}
			</span>
		);
	},
	INHOME_1030() {
		return (
			<span
				data-qa="INHOME_1030"
			>
				{'Ready to deliver. '}
			</span>
		);
	},
	INHOME_1040({ store }) {
		if (!store) {
			return null;
		}

		const {
			linkEventStore,
		} = useGlobalContext();

		const {
			magicDeliveryModel: {
				trLinkEventCompName = null,
				trLinkEventCompType = null,
			} = {},
		} = store;

		return (
			<>
				<button
					type="button"
					className="button-as-anchor"
					data-tr-link-event-track={false}
					data-qa="delivery-widget-enter-zip"
					onClick={() => {
						store.viewState.goTo('deliveryForm', {
							autoFocusZipCode: true,
						});
						store.trackZipCodeWidgetInteration(trLinkEventCompName, trLinkEventCompType, linkEventStore);
					}}
				>See delivery timing</button>
				{' '}
			</>
		);
	},
	INHOME_1050({ values }) {
		const { availabilityDate = null } = values;

		if (!availabilityDate) {
			return null;
		}

		return (
			<span data-qa="INHOME_1050">
				<strong>{`Available ${availabilityDate}. `}</strong>
			</span>
		);
	},
	INHOME_1060({ store, values }) {
		const {
			availableWindow = null,
			zipCode = null,
		} = values;

		if (!store || !availableWindow || !zipCode) {
			return null;
		}

		const {
			linkEventStore,
		} = useGlobalContext();

		const {
			magicDeliveryModel: {
				trLinkEventCompName = null,
				trLinkEventCompType = null,
			} = {},
		} = store;

		return (
			<span
				data-qa="INHOME_1060"
			>
				{'Estimated delivery to '}
				<button
					type="button"
					className="button-as-anchor"
					data-tr-link-event-track={false}
					data-qa="delivery-widget-enter-zip"
					onClick={() => {
						store.trackZipCodeWidgetInteration(trLinkEventCompName, trLinkEventCompType, linkEventStore);
						store.viewState.goTo('deliveryForm', {
							autoFocusZipCode: true,
						});
					}}
				>{zipCode.substring(0, 5)}</button>
				{`: ${availableWindow}`}
			</span>
		);
	},
	INHOME_1070({ store, values }) {
		const {
			availableWindow = null,
			zipCode = null,
		} = values;

		if (!store || !availableWindow || !zipCode) {
			return null;
		}

		const {
			linkEventStore,
		} = useGlobalContext();

		const {
			magicDeliveryModel: {
				trLinkEventCompName = null,
				trLinkEventCompType = null,
			} = {},
		} = store;

		return (
			<span data-qa="INHOME_1070">
				<strong>
					{'Estimated delivery to '}
					<button
						type="button"
						className="button-as-anchor"
						data-tr-link-event-track={false}
						data-qa="delivery-widget-enter-zip"
						onClick={() => {
							store.trackZipCodeWidgetInteration(trLinkEventCompName, trLinkEventCompType, linkEventStore);
							store.viewState.goTo('deliveryForm', {
								autoFocusZipCode: true,
							});
						}}
					>
						{zipCode.substring(0, 5)}
					</button>
					{': '}
				</strong>
				{availableWindow}
			</span>
		);
	},
	UPS_1090() {
		return 'Ready to ship ';
	},
	UPS_1100: ({ values }) => {
		const { availabilityDate = null } = values;

		if (!availabilityDate) {
			return null;
		}

		return (
			<span data-qa="UPS_1100">
				<strong>{`Ready to ship ${availabilityDate} `}</strong>
			</span>
		);
	},
	INHOME_1110({ values }) {
		const { availabilityDate = null } = values;

		if (!availabilityDate) {
			return null;
		}

		return (
			<span data-qa="INHOME_1110">
				{`Available ${availabilityDate}. `}
			</span>
		);
	},
	INHOME_1120({ store, values }) {
		const {
			availableWindow = null,
			zipCode = null,
		} = values;

		if (!store || !availableWindow || !zipCode) {
			return null;
		}

		const {
			linkEventStore,
		} = useGlobalContext();

		const {
			magicDeliveryModel: {
				trLinkEventCompName = null,
				trLinkEventCompType = null,
			} = {},
		} = store;

		return (
			<span data-qa="INHOME_1120">
				{'Estimated delivery to '}
				<button
					type="button"
					className="button-as-anchor"
					data-tr-link-event-track={false}
					data-qa="delivery-widget-enter-zip"
					onClick={() => {
						store.trackZipCodeWidgetInteration(trLinkEventCompName, trLinkEventCompType, linkEventStore);
						store.viewState.goTo('deliveryForm', {
							autoFocusZipCode: true,
						});
					}}
				>{zipCode.substring(0, 5)}</button>
				{`: ${availableWindow}`}
			</span>
		);
	},
	UPS_1130: ({ values }) => {
		const { availabilityDate = null } = values;

		if (!availabilityDate) {
			return null;
		}

		return (
			<span data-qa="UPS_1130">
				{`Ready to ship ${availabilityDate} `}
			</span>
		);
	},
	CASEGOODS: () => {
		const {
			magicModal,
			globalDynamicStore: {
				model: {
					inHomeDeliveryFeeAmount = 0,
				} = {},
			} = {},
		} = useGlobalContext();

		return (
			<>
				<span className="tw-font-bold tw-text-base">
					{`${formatCurrency(inHomeDeliveryFeeAmount, true)} full-service delivery`}
				</span>
				<div>Unlimited items per trip, free returns.</div>
				<TextButton
					type="button"
					onClick={() => {
						magicModal.openModal({
							title: 'Full-service Delivery',
							content: {
								children: <FullServiceDeliveryModal magicModal={magicModal} />,
							},
						});
					}}
				>See details</TextButton>
			</>
		);
	},
};
