import { DefaultProductModelFactory } from '~/product/common/selector/Models/DefaultProduct.model';
import { SelectorConfigSectionalsModelFactory } from '~/product/sectionals/selector/Models/SelectorConfigSectionals.model';
import { createSelectorProductModel } from '~/product/sectionals/selector/Helpers/SelectorProduct.init';
import { createSelectorQuestionModel } from '~/product/sectionals/selector/Helpers/SelectorQuestion.init';
import { SelectorDownload } from '~/product/sectionals/selector/Models/SelectorDownload.model';

export const createSelectorConfigSectionalsModel = ({
	defaultConfigData = [],
	defaultOttomanProductData = {},
	defaultProductData = {},
	downloadSelectorData = {},
	featureTogglesModel = {},
	ottomanProductGroup = {},
	ottomanProductModels = [],
	ottomanSelectorData = null,
	productModels = [],
	productSelectorData = {},
	questionSelectorsData = [],
	siblingDefaultConfigData = [],
}) => {
	const questionSelectorModels = questionSelectorsData.map((questionSelectorData = {}) => {
		return createSelectorQuestionModel({ selectorData: questionSelectorData });
	});

	const productSelectorModel = createSelectorProductModel({
		productModels,
		questionSelectorModels,
		selectorData: productSelectorData,
	});

	const ottomanSelectorModel = ottomanSelectorData ? createSelectorProductModel({
		productModels: ottomanProductModels,
		questionSelectorModels,
		selectorData: { ...ottomanSelectorData, isValid: false },
	}) : {};

	downloadSelectorData.isValid = false;

	const defaultProductModel = DefaultProductModelFactory.create(defaultProductData);
	const defaultOttomanProductModel = DefaultProductModelFactory.create(defaultOttomanProductData);
	const downloadSelectorModel = new SelectorDownload(downloadSelectorData);

	const selectorConfigSectionalsModel = SelectorConfigSectionalsModelFactory.create({
		defaultConfigModels: defaultConfigData,
		defaultOttomanProductModel,
		defaultProductModel,
		downloadSelectorModel,
		featureTogglesModel,
		ottomanProductGroup,
		ottomanProductModels,
		ottomanSelectorModel,
		questionSelectorModels,
		productModels,
		productSelectorModel,
		siblingDefaultConfigModels: siblingDefaultConfigData,
	});

	return selectorConfigSectionalsModel;
};
