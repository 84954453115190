import { MediaProductSectionalsModelFactory } from '~/product/sectionals/media-set/Models/MediaProductSectionals.model';

export const createMediaProductSectionalsModel = ({
	mediaData = {},
	selectorConfigModel = {},
}) => {
	const mediaModel = MediaProductSectionalsModelFactory.create({
		...mediaData,
		selectorConfigModel,
	});

	return mediaModel;
};
