import { computed, observable, makeObservable } from 'mobx';

import { sortBy } from '~/util/sortBy';

export class SelectorFrame {
	displayType;

	filters;

	filtersMagicTabccordionStore;

	filtersModel;

	filtersStore;

	label;

	productModels;

	renderer;

	selected;

	selectorData;

	selectorValues;

	title;

	type;

	wasActive;

	constructor() {
		makeObservable(this, {
			filtersModel: observable,
			selected: observable,
			wasActive: observable,
			chunkedProductModels: computed,
			combinedArticles: computed,
			filteredSelectorValues: computed,
			productModelsByPeerId: computed,
			selectedSelectorValue: computed,
			selectorValuesCount: computed,
			selectorValuesToRender: computed,
		});
	}

	get chunkedProductModels() {
		return Object.values(this.productModelsByPeerId)
			.map((productModels = []) => sortBy(productModels, 'depth'));
	}

	get combinedArticles() {
		return this.selectorValues.reduce((accumulatedArticles, { articles = [] }) => {
			return [...accumulatedArticles, ...articles];
		}, []);
	}

	get filteredSelectorValues() {
		const { filteredSelectorValues = [] } = this.filtersModel;

		return filteredSelectorValues;
	}

	get productModelsByPeerId() {
		return this.combinedArticles.reduce((accumulatedPeerIdsMap, {
			article = '',
			peerId = '',
		}) => {
			const matchingProduct = this.productModels.find(({ articleNumber = '' }) => articleNumber === article);

			(accumulatedPeerIdsMap[peerId] = accumulatedPeerIdsMap[peerId] || []).push(matchingProduct);

			return accumulatedPeerIdsMap;
		}, {});
	}

	get selectedSelectorValue() {
		return this.selectorValues.find(({ selected }) => selected) || this.selectorValues[0];
	}

	get selectorValuesCount() {
		return this.selectorValues.length;
	}

	get selectorValuesToRender() {
		return this.filteredSelectorValues;
	}
}

export const SelectorFrameModelFactory = ({
	create: ({
		displayType = '',
		filters = {},
		filtersMagicTabccordionStore = {},
		filtersModel = {},
		filtersStore = {},
		label = '',
		productModels = [],
		renderer = '',
		selected = false,
		selectorData = {},
		selectorValues = [],
		title = '',
		type = '',
		wasActive = false,
	} = {}) => {
		const selectorFrame = new SelectorFrame();

		Object.assign(selectorFrame, {
			displayType,
			filters,
			filtersMagicTabccordionStore,
			filtersModel,
			filtersStore,
			label,
			productModels,
			renderer,
			selected,
			selectorData,
			selectorValues,
			title,
			type,
			wasActive,
		});

		return selectorFrame;
	},
});
