import { WorkspaceProductJoinableSectionalsModelFactory } from '~/product/sectionals/workspace/Models/WorkspaceProductJoinableSectionals.model';
import { WorkspaceProductSectionalsModelFactory } from '~/product/sectionals/workspace/Models/WorkspaceProductSectionals.model';

export const createWorkspaceProductSectionalsModel = ({
	orientation = '',
	productSelectorValue = {},
	productSelectorValue: {
		preselectedProductModel: {
			connectables: {
				joinableSides = [],
			} = {},
		} = {},
	} = {},
	workspaceProductIndex = 0,
}) => {
	const workspaceProductJoinableModels = joinableSides.map((joinableSide = '') => {
		return WorkspaceProductJoinableSectionalsModelFactory.create({
			joinableSide,
			parentOrientation: orientation,
		});
	});

	const workspaceProductModel = WorkspaceProductSectionalsModelFactory.create({
		orientation,
		productSelectorValue,
		workspaceProductIndex,
		workspaceProductJoinableModels,
	});

	return workspaceProductModel;
};
