import React from 'react';

import type { ISelectorProps } from '~/product/casegoods/selector/Types/SelectorCasegoods.types';

import { selectorRecursiveCasegoodsComponents } from '~/product/casegoods/selector/Components/selector-recursive-casegoods-components';
import { noop } from '~/util/noop';

export const SelectorRecursive = ({
	selectedSelector,
	selectedSelector: {
		renderer,
	},
	scrollHandler = noop,
	touchStartHandler = noop,
}: ISelectorProps) => {
	let SelectorRenderer = selectorRecursiveCasegoodsComponents[renderer];

	if (!SelectorRenderer) {
		SelectorRenderer = selectorRecursiveCasegoodsComponents.STATIC_HEADING;

		console.warn(`No recursive selector renderer found for "${renderer}"`);
	}

	return (
		<SelectorRenderer selectedSelector={selectedSelector} scrollHandler={scrollHandler} touchStartHandler={touchStartHandler} />
	);
};
