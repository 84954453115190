import axios from 'axios';
import { action, makeObservable, reaction } from 'mobx';

import { apiUrl, isOnServer } from '~/global/global.constants';
import { noop } from '~/util/noop';

class MediaCylindoStore {
	mediaCylindoModel

	selectorValueChangedDisposer

	constructor() {
		makeObservable(this, {
			getCylindoData: action,
			setIsCylindoActive: action,
			setIsCylindoARActive: action,
			setIsCylindoDimensionsActive: action,
		});
	}

	getCylindoData() {
		const {
			cylindoAPIParams = {},
			cylindoAPIUrl = '',
		} = this.mediaCylindoModel;

		const url = `${apiUrl}${cylindoAPIUrl}`;

		return axios.request({
			url,
			method: 'get',
			params: cylindoAPIParams,
			paramsSerializer: (paramsToSerialize) => {
				return Object.entries(paramsToSerialize).map(([key, value]) => {
					if (Array.isArray(value)) {
						return value.map((subValue) => {
							return `${key}=${encodeURIComponent(subValue)}`;
						}).join('&');
					}

					return `${key}=${encodeURIComponent(value)}`;
				}).join('&');
			},
		})
			.then(({ data = {} }) => {
				this.setCylindoData(data);
			})
			.catch((error) => {
				this.setCylindoData(null);

				console.error(error);

				throw error;
			});
	}

	setCylindoData(cylindoData = null) {
		this.mediaCylindoModel.cylindoData = cylindoData;
	}

	setIsCylindoActive(isCylindoActive = false) {
		this.mediaCylindoModel.isCylindoActive = isCylindoActive;
	}

	setIsCylindoARActive(isCylindoARActive = false) {
		this.mediaCylindoModel.isCylindoARActive = isCylindoARActive;
	}

	setIsCylindoDimensionsActive(isCylindoDimensionsActive = false) {
		this.mediaCylindoModel.isCylindoDimensionsActive = isCylindoDimensionsActive;
	}
}

export const MediaCylindoStoreFactory = ({
	create: ({
		mediaCylindoModel = {},
		selectorConfigModel = {},
	}) => {
		const mediaCylindoStore = new MediaCylindoStore();

		let selectorValueChangedDisposer = noop;

		Object.assign(mediaCylindoStore, {
			mediaCylindoModel,
			selectorValueChangedDisposer,
		});

		if (!isOnServer) {
			selectorValueChangedDisposer = reaction(() => selectorConfigModel.lastSelectedSelectorValue, () => {
				mediaCylindoStore.setIsCylindoActive(false);

				mediaCylindoStore.setIsCylindoARActive(false);

				mediaCylindoStore.setIsCylindoDimensionsActive(false);
			});
		}

		return mediaCylindoStore;
	},
});
