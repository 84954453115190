import { observer } from 'mobx-react';
import React from 'react';

import { DetailLinkIcon } from '~/product/common/details/Components/details/DetailLinkIcon';
import { TextLink } from '~/components/Buttons/Components/TextLink';

export const DetailLinkNew = observer((props) => {
	const {
		linkModel: {
			displayText = '',
			url = '',
			mediaIcon = '',
		} = {},
	} = props;

	const hasProtocol = url.startsWith('http');
	const modifiedUrl = hasProtocol ? url : `/${url}`;
	const isExternalLink = !modifiedUrl.includes('roomandboard.com');
	const isPdf = modifiedUrl.endsWith('.pdf');

	return (
		<TextLink
			className="detail-link detail-link-new tw-hidden-for-print"
			href={modifiedUrl}
			target="_blank"
			linkExternal={isExternalLink && !isPdf}
			rel="noopener noreferrer">
			{displayText}
			<DetailLinkIcon mediaIcon={mediaIcon} />
		</TextLink>
	);
});
