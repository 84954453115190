import { formatCurrency } from '~/util/formatCurrency';

export const selectProductQuestionAnswers = ({
	productModels = [],
	questionSelectorValues = [],
}) => {
	questionSelectorValues.forEach(({ questionAnswers = [] }) => {
		productModels.forEach((productModel = {}) => {
			const { questionModels = [] } = productModel;

			questionAnswers.forEach(({
				answer = '',
				group = '',
			}) => {
				const productQuestionModel = questionModels.find(({ group: productQuestionGroup }) => productQuestionGroup === group);

				if (productQuestionModel) {
					let foundMatchingProductAnswerKey = false;

					productQuestionModel.answersModel.forEach((productAnswer) => {
						const { key: productAnswerKey } = productAnswer;

						if (productAnswerKey === answer) {
							productAnswer.selected = true;
							foundMatchingProductAnswerKey = true;
						} else {
							productAnswer.selected = false;
						}
					});

					if (!foundMatchingProductAnswerKey) {
						// console.warn(`Could not find product answer ${answer} for product question group ${group} on product:`, productModel);
					}
				} else {
					console.warn(`Could not find product question ${group} on product:`, productModel);
				}
			});
		});
	});
};

export const getSelectorValuePrice = (selectorValue = {}, productModel = {}) => {
	const { questionSelectorModels } = selectorValue;

	const questionSelectorModel = questionSelectorModels ? questionSelectorModels.find(qsm => qsm.renderer === 'FABRIC') : {};

	const {
		selectedSelectorValue: {
			pricesByArticle = {},
		} = {},
	} = questionSelectorModel;

	const {
		articleNumber = '',
		nonCustomArticles = [],
		pricing: {
			base = 0,
		} = {},
	} = productModel;

	const matchingNonCustomArticleNumber = nonCustomArticles.find(nonCustomArticleNumber => pricesByArticle[nonCustomArticleNumber]);

	const priceGroup = matchingNonCustomArticleNumber ? pricesByArticle[matchingNonCustomArticleNumber] : pricesByArticle[articleNumber];

	return formatCurrency(priceGroup?.price, true) || base;
};
