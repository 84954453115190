import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import React from 'react';

import { SelectorFrameModal } from '~/product/casegoods/selector/Components/frame-modal/SelectorFrameModal';
import { createSelectorFrameModalModel } from '~/product/casegoods/selector/Helpers/SelectorFrameModal.init';
import { WorkspaceProductStackCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductStackCasegoods';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/product-controls/workspace-product-controls.module.scss';

const WorkspaceModalFrameCasegoods = dynamic(
	() => import('~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameCasegoods').then(module => module.WorkspaceModalFrameCasegoods),
);

export const WorkspaceProductControlsCasegoods = observer((props = {}) => {
	const {
		workspaceProduct = {},
		workspaceProduct: {
			depthSelector = {},
			doorSelectorValue = {},
			frameProductModel = {},
			frameProductModels = [],
			selectorConfigFrameModalModel = {},
			selectorConfigFrameModalModel: {
				frameSelectorModel = {},
				insertConfiguration = {},
				selectedProduct = {},
				selectedProductConfiguration = {},
			} = {},
			widthSelectorValue = {},
			workspaceProductIndex = 0,
			workspaceProductImageData,
		} = {},
	} = props;

	const { magicModal = {} } = useGlobalContext();

	const {
		productGroupModel = {},
		selectorConfigModel: {
			settings: {
				frameFirstBuilder = false,
				frameModalHeadingTitle = '',
				hideEditButton = false,
				useProductsEndpoint = false,
			} = {},
		} = {},
		selectorConfigStore = {},
		workspaceModalFrameStore = {},
		workspaceModel = {},
		workspaceModel: {
			dimensions: {
				imageInchesToPixelsFactor = 0,
				translateBottomInches,
			} = {},
		} = {},
		workspaceStore = {},
	} = useProductContext();

	function handleEditClick() {
		if (frameFirstBuilder) {
			const selectorFrameModalModel = createSelectorFrameModalModel({
				defaultConfig: selectedProductConfiguration,
				defaultnsertConfig: insertConfiguration,
				defaultProduct: selectedProduct,
				productGroupModel,
				selectedSelector: frameSelectorModel,
				selectorConfigModel: selectorConfigFrameModalModel,
				selectorConfigStore,
				workspaceProductImageData,
				workspaceProductIndex,
			});

			magicModal.openModal({
				content: {
					children: (
						<SelectorFrameModal selectorFrameModalModel={selectorFrameModalModel} />
					),
				},
				id: 'workspace-frame-modal',
				maxWidth: '600px',
				title: frameModalHeadingTitle,
			});
		} else {
			runInAction(() => {
				workspaceModalFrameStore.setDepthSelector(depthSelector);
				workspaceModalFrameStore.setWorkspaceProductModalFrame({
					frameProductModel,
					frameProductModels,
					insertProductModelToAdd: workspaceProduct.slotModelsWithInserts[0]?.insertProductModel,
					workspaceProductIndex,
					workspaceProductImageData,
				});
				workspaceModalFrameStore.setDoorSelectorValue(doorSelectorValue);
				workspaceModalFrameStore.setWidthSelectorValue(widthSelectorValue);
			});
			magicModal.openModal({
				content: {
					children: (
						<WorkspaceModalFrameCasegoods
							view={
								<WorkspaceProductStackCasegoods
									imageInchesToPixelsFactor={imageInchesToPixelsFactor}
									translateBottomInches={translateBottomInches}
									imageScale={8}
									workspaceProduct={workspaceProduct}

								/>
							}
						/>
					),
				},
				id: 'workspace-frame-modal',
				maxWidth: '600px',
				title: frameModalHeadingTitle,
			});
		}
	}

	function handleRemoveClick() {
		workspaceStore.removeWorkspaceProduct(workspaceProduct);
		if (useProductsEndpoint) {
			// if setting useProductsEndpoint enabled, update selector data from api after removing workspaceProduct
			const firstProduct = workspaceModel.workspaceProducts.length ? workspaceModel.workspaceProducts[0] : {};
			const { combinedArticles: firstProductArticles, productModels: firstProductModels } = firstProduct;
			const currentWorkspaceProducts = workspaceModel.workspaceProducts.filter(product => product !== workspaceProduct);
			selectorConfigStore.getSelectorData(
				{
					currentInsertArticles: firstProductArticles,
					currentProductModels: firstProductModels,
					currentWorkspaceProducts,
					hrefToUse: firstProduct?.frameProductModel?.links.FRAME_DOCUMENT?.href,
				}
			).catch(error => console.error(error));
		}
	}

	return (
		<div className={styles['workspace-product-controls']}>
			{!hideEditButton && <button
				className={`${styles['workspace-product-control']} ButtonAnchor`}
				data-qa="workspace-product-edit"
				onClick={handleEditClick}
			>
				Edit
			</button>}
			<button
				className={`${styles['workspace-product-control']} ButtonAnchor`}
				data-qa="workspace-product-remove"
				onClick={handleRemoveClick}
			>
				Remove
			</button>
		</div>
	);
});
