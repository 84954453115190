import {
	action, observable, computed, makeObservable,
} from 'mobx';

import { chunk } from '~/util/chunk';

class MagicPagerStore {
	items = null;

	imageData = [];

	pageWidth = null;

	pageHeight = null;

	itemWidth = null;

	itemHeight = null;

	index = 0;

	left = 0;

	leftTransform = 0;

	ELLIPSIS = '...';

	get activePage() {
		return Array.isArray(this.pages) ? this.pages[this.index] : undefined;
	}

	get activeImage() {
		return this?.imageData?.find?.(datum => datum.selected);
	}

	get columnCount() {
		return Math.floor(this.pageWidth / this.itemWidth);
	}

	get rowCount() {
		return Math.floor(this.pageHeight / this.itemHeight);
	}

	get itemsPerPage() {
		const itemsPerPage = this.columnCount * this.rowCount;
		return itemsPerPage;
	}

	get pages() {
		return chunk(this.items, this.itemsPerPage);
	}

	get renderedPages() {
		return this.pages.slice(Math.max(0, this.index - 1), this.index + 2);
	}

	setLeftTransform(leftTransform) {
		this.leftTransform = leftTransform;
	}

	get maxVisibleNavLinkCount() {
		return Math.floor(this.pageWidth / 35) - 1;
	}

	get pageNavigation() {
		const current = this.index + 1;
		const navArray = this.page(this.maxVisibleNavLinkCount, this.pages.length, current);

		return navArray;
	}

	setActivePage(index) {
		if (index >= 0 && this.pages.length > index) {
			this.leftTransform = 0;
			this.index = index;
		}
	}

	setPageDimensions(pageWidth, pageHeight) {
		this.pageWidth = pageWidth;
		this.pageHeight = pageHeight;
	}

	setDimensions(pageWidth, pageHeight, itemWidth, itemHeight) {
		this.pageWidth = pageWidth;
		this.pageHeight = pageHeight;
		this.itemWidth = itemWidth;
		this.itemHeight = itemHeight;
	}

	// Updated paging nav algorithm thanks to Alex!
	page(length, pageCount, selectedPageNumber) {
		if (pageCount <= length) { // we have NO ellipses
			return this.range(1, pageCount);
		}

		const finalLength = ((pageCount - 5) % (length - 4)) + 3; // don't ask me what this is.  resulted from lots of guess and check

		if (selectedPageNumber < length - 1) { // we have RIGHT ellipses only
			let result = [];
			const range = this.range(1, length - 2);
			result = result.concat(range);
			result.push(this.ELLIPSIS);
			result.push(pageCount);
			return result;
		}

		if (selectedPageNumber > (pageCount - finalLength)) { // we have LEFT ellipses only
			let result = [];
			result.push(1);
			result.push(this.ELLIPSIS);

			const range = this.range((pageCount - finalLength) + 1, pageCount);
			result = result.concat(range);
			return result;
		}

		// we have LEFT and RIGHT ellipses:

		const middleLength = length - 4;

		let result = [];
		result.push(1);
		result.push(this.ELLIPSIS);

		const starter = length - 1;
		const start = Math.floor(starter + (middleLength * Math.floor((selectedPageNumber - starter) / middleLength)));

		const range = this.range(start, start + (middleLength - 1));
		result = result.concat(range);
		result.push(this.ELLIPSIS);
		result.push(pageCount);
		return result;
	}

	range(start, end) {
		const val = [];
		for (let i = start; i <= end; i++) {
			val.push(i);
		}
		return val;
	}

	constructor(pageWidth, pageHeight, itemWidth, itemHeight, items) {
		makeObservable(this, {
			items: observable,
			imageData: observable,
			pageWidth: observable,
			pageHeight: observable,
			itemWidth: observable,
			itemHeight: observable,
			index: observable,
			left: observable,
			leftTransform: observable,
			ELLIPSIS: observable,
			activePage: computed,
			activeImage: computed,
			columnCount: computed,
			rowCount: computed,
			itemsPerPage: computed,
			pages: computed,
			renderedPages: computed,
			maxVisibleNavLinkCount: computed,
			pageNavigation: computed,
			setActivePage: action,
			setPageDimensions: action,
			setDimensions: action,
		});

		this.pageWidth = pageWidth;
		this.pageHeight = pageHeight;
		this.itemWidth = itemWidth;
		this.itemHeight = itemHeight;
		this.items = items;
	}
}

export { MagicPagerStore };
