import { observer } from 'mobx-react';
import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { DetailLinkIcon } from '~/product/common/details/Components/details/DetailLinkIcon';
import { detailModalComponents } from '~/product/common/details/Components/modals/detail-modal-components';

export const DetailLinkModal = observer((props) => {
	const {
		linkModel: {
			displayText = '',
			key = '',
			mediaIcon = '',
		} = {},
	} = props;

	const {
		magicModal,
	} = useGlobalContext();

	function handleClick(event) {
		let detailModalSettings = detailModalComponents()[key];

		if (!detailModalSettings) {
			detailModalSettings = {
				id: 'detail-modal',
				title: 'Detail Modal',
				maxWidth: '640px',
				content: {
					key,
				},
			};

			console.warn(`No detail modal settings found for "${key}"`);
		}

		magicModal.openModal(detailModalSettings, event);
	}

	return (
		<button
			className="detail-link detail-link-modal ButtonAnchor"
			onClick={handleClick}
		>
			{displayText}
			<DetailLinkIcon mediaIcon={mediaIcon} />
		</button>
	);
});
