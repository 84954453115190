import { SelectorFacetModelFactory } from '~/product/common/selector/Models/SelectorFacet.model';
import { SelectorFilterModelFactory } from '~/product/common/selector/Models/SelectorFilter.model';
import { SelectorFiltersModelFactory } from '~/product/common/selector/Models/SelectorFilters.model';

export const createSelectorInsertFiltersCasegoodsModel = ({
	filtersData = {},
	parentSelectorModel = {},
	selectorValues = [],
}) => {
	const { types: typesData = [] } = filtersData;

	const facetsTypeModel = typesData.map(typeData => SelectorFacetModelFactory.create({
		...typeData,
		isInclusive: true,
		parentSelectorModel,
		parentType: 'types',
		selectorValues,
		showMatchCount: false,
		title: typeData,
	}));

	facetsTypeModel.forEach((facetTypeModel) => {
		const filteredFacetsTypeModel = facetsTypeModel.filter(facetModel => facetModel !== facetTypeModel);

		facetTypeModel.matchingFacetsModels = [...filteredFacetsTypeModel];
	});

	const filterTypeModel = SelectorFilterModelFactory.create({
		facetsColumnCount: 1,
		facetsModel: facetsTypeModel,
		sort: 0,
		title: 'Insert type',
		type: 'types',
	});

	// only send along filterModels with facets
	const filterModels = [
		filterTypeModel,
	].filter(({ facetsModel = [] }) => Boolean(facetsModel.length));

	const selectorInsertFiltersModel = SelectorFiltersModelFactory.create({
		filterModels,
		parentSelectorModel,
		selectorValues,
	});

	return selectorInsertFiltersModel;
};
