import React from 'react';

import { StackWorkspace } from '~/product/casegoods/workspaces/stackWorkspace/Components/stackWorkspace';

export const helpTextCasegoods = {
	DEFAULT: <>For help designing or purchasing, call us at <a className="tw-text-gray" href="tel:800-301-9720">800.301.9720</a></>,
};

export const settingsProductPageCasegoods = {
	CUSTOM_CABINET: {
		showReviews: true,
		showStandardDetails: true,
	},
	DEFAULT: {
		showReviews: true,
		showStandardDetails: false,
	},
	MODULAR_BOOKCASE: {
		showReviews: true,
		showStandardDetails: false,
	},
	STACK: {
		showReviews: true,
		showStandardDetails: false,
		disableInsertDragPreviewAbsoutePositioning: true,
	},
};

export const workspaceEmptyTextMediumCasegoods = {
	CUSTOM_CABINET: 'Drag and drop a cabinet into your design',
	DEFAULT: <>Drag and drop pieces <br />into your design</>,
};

export const workspaceEmptyTextSmallCasegoods = {
	DEFAULT: 'Tap pieces to add into your design',
	CUSTOM_CABINET: 'Tap a cabinet to add into your design',
	MODULAR_BOOKCASE: 'Tap pieces to add into your design',
	STACK: 'Drag and drop pieces into your design'
};

export const workspaceEmptySummaryTextCasegoods = {
	CUSTOM_CABINET: 'Select a cabinet to see dimensions',
	DEFAULT: 'Select bookcases to see dimensions',
};

export const productGroupOverrideWorkspaceEmptyTextSmallCasegoods = {
	slim: 'Drag and drop pieces into your design',
};
