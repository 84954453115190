import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/product/workspace-products-width.module.scss';

export const WorkspaceProductsWidthCasegoods = observer(() => {
	const { workspaceModel = {} } = useProductContext();

	const { workspaceProductsWidth = 0 } = workspaceModel;

	return (
		<div className={styles['workspace-products-width']} data-qa="workspace-products-width">
			<span className={styles['workspace-products-width-value']}>
				{workspaceProductsWidth}"
			</span>
			<div className={styles['workspace-products-width-line-container']}>
				<div className={styles['workspace-products-width-line']} />
			</div>
		</div>
	);
});
