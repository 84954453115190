import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { DetailLink } from '~/product/common/details/Components/details/DetailLink';

import styles from '#/product/standard/details/details-attributes.module.scss';
import stylesDetail from '#/product/standard/details/details.module.scss';

export const DetailInsertOptionsQuestionAnswerCasegoods = observer((props) => {
	const {
		detail = {},
		detail: {
			hasLink = false,
			insertOptionsSelectors = [],
			label = '',
		} = {},
	} = props;

	const {
		selectorConfigModel: {
			materialSelectorModels = [],
		} = {},
	} = useProductContext();

	const finishMaterialSelector = materialSelectorModels.find(({ type = '' }) => type === 'FINISH_MATERIAL') || {};

	const {
		selectedSelectorValue: {
			answer = '',
		} = {},
	} = finishMaterialSelector;

	const displayText = insertOptionsSelectors.map(({
		selectedSelectorValue: {
			title: insertOptionTitle,
			upcharge = '',
			woodUpcharges = {},
		} = {},
	}) => {
		const woodUpcharge = woodUpcharges[answer];

		const upchargeToUse = woodUpcharge || upcharge;

		return `${upchargeToUse && '+ '}${insertOptionTitle}${upchargeToUse && ' '}${upchargeToUse}`;
	}).join(', ');

	return (
		<div
			className={`${styles['detail']} ${stylesDetail['detail']} detail-name-value`}
			data-qa="detail-name-value"
		>
			<strong data-qa="detail-name-value-name">{label}{displayText && ':'}</strong>
			<span data-qa="detail-name-value-value"> {displayText} </span>
			{
				hasLink && (
					<DetailLink detail={detail} />
				)
			}
		</div>
	);
});
