import { SelectorLinkFabricOptions } from '~/product/common/selector/Components/links/SelectorLinkFabricOptions';
import { SelectorLinkLeatherOptions } from '~/product/common/selector/Components/links/SelectorLinkLeatherOptions';
import { SiblingSectionalDesignerFabricLink } from '~/product/common/selector/Components/links/siblingSectionalDesignerLink/SiblingSectionalDesignerFabricLink';
import { SiblingSectionalDesignerLeatherLink } from '~/product/common/selector/Components/links/siblingSectionalDesignerLink/SiblingSectionalDesignerLeatherLink';

export const selectorLinkComponents = {
	FABRIC_OPTIONS: SelectorLinkFabricOptions,
	LEATHER_OPTIONS: SelectorLinkLeatherOptions,
	SECTIONAL_DESIGNER_FABRIC_OPTIONS: SiblingSectionalDesignerFabricLink,
	SECTIONAL_DESIGNER_LEATHER_OPTIONS: SiblingSectionalDesignerLeatherLink,
};
