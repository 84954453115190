import { responsiveColumnOptions } from '~/subcategory/common/components/ResponsiveColumns';
import { sortOptionKeys, sortOptionTypes } from '~/subcategory/common/util/subcategorySortUtil';
import { breadcrumbsType } from '~/layout/Models/Breadcrumb.model';
import { BANNER_ID } from '~/features/banners/Types/BannerLibrary.type';
import { SUBFEATURE_ID } from '~/features/Types/FeatureLibrary.type';
import {
	ADD_FAVORITES_PRODUCT_GROUP_TYPE,
	CarouselMedia, DefaultProductGroup, DefaultProductGroupInterface, ProductGroupStatus, GalleryObject,
} from '~/components/product-group/Types/ProductGroup.type';
import { brandStatementInterface } from '~/components/brand-statement/Types/BrandStatement.type';

export enum SUBCAT_DOCUMENT_TYPE {
	CLEARANCE = 'CLEARANCE',
	CLEARANCE_CATEGORY = 'CLEARANCE_CATEGORY',
	CLEARANCE_SUBCATEGORY = 'CLEARANCE_SUBCATEGORY',
	EDITORIAL = 'EDITORIAL',
	SHOP_THIS_ROOM = 'SHOP_THIS_ROOM',
	STANDARD = 'STANDARD',
}

export const NON_HEADER_NAMES = {
	DEFAULT: 'DEFAULT',
	IN_STOCK: 'IN_STOCK',
} as const;

export interface featuredContentInterface {
	title: string,
	links: { title: string, url: string, behavior: string, image: string }[]
}

export interface featuresInterface {
	banner: BANNER_ID;
	sub: SUBFEATURE_ID;
}

////////////////////
///// Editorial Shopping subcategory pages
///////////////////
export interface EditorialSet {
	name: string,
	key: string,
	bannerImageUrl?: string,
	// eslint-disable-next-line camelcase
	button1_text?: string, button1_url?: string,
	// eslint-disable-next-line camelcase
	button2_text?: string, button2_url?: string,
	copy?: string,
	productGroups: DefaultProductGroup[],
}

////////////////////
///// Standard subcategory pages
///////////////////
export interface StandardProductGroup extends DefaultProductGroup {
	filters: string[],
	productGroupKey: string,
	sectionalDesignerText?: string,
	shippingText?: string,
	status?: ProductGroupStatus,
	styleText?: string,
	gallery?: GalleryObject,
	valuesToSortOn: sortOptionTypes;
}
export interface StandardProductGroupInterface extends DefaultProductGroupInterface {
	filters: string[],
	isSoldOut: boolean,
	sortValues: sortOptionTypes,
	carouselMediaSet?: CarouselMedia[],
}

export interface StandardLinks {
	allProducts?: {
		href: string,
	},
	inStockProducts?: {
		href: string,
	},
}

interface AddToFavoritesLinks {
	addToFavorites: {
		href: string,
		type: ADD_FAVORITES_PRODUCT_GROUP_TYPE,
	},
	addToFavoritesProperties: {
		href: string,
	},
}

////////////////////
///// Shop This Room subcategory pages
///////////////////
export interface ShopThisRoomProduct {
	articleNumber: string,
	imageUrl: string,
	_links?: AddToFavoritesLinks,
	moreOptions: string,
	name: string,
	priceRange: string,
	reviews: {
		count: number,
		reviewsAverageOverallRating: number,
	},
	status: {
		status?: string,
	}
	url: string,
}

interface CommonResponse {
	_links?: StandardLinks,
	breadcrumbs: breadcrumbsType,
	document: ShopThisRoomDocument | StandardDocument | EditorialDocument | ClearanceCategoryDocument | ClearanceSubcategoryDocument,
	documentType: keyof typeof SUBCAT_DOCUMENT_TYPE,
	filters: { key: string, image?: string, banner?: BANNER_ID }[],
}

export interface IFilters {
	key: string, image?: string, banner?: BANNER_ID
}
interface CommonResponseDocument {
	altText: string,
	bannerImageUrl: string,
	brandStatement: brandStatementInterface,
	canonicalUrl: string,
	columnLayout: responsiveColumnOptions,
	editorialBannerBlockStyle: boolean,
	editorialSubhead?: boolean,
	featuredContent: featuredContentInterface,
	features: featuresInterface,
	filters: IFilters[],
	imageCaption?: string,
	imageUrl: string,
	longName: string,
	metaDescription: string,
	pageTitle: string,
	products: ShopThisRoomProduct[],
	sets: EditorialSet[],
	productGroups: StandardProductGroup[],
	shortName: string,
	subFeatureKey: SUBFEATURE_ID,
}

type ShopThisRoomDocument = Omit<CommonResponseDocument, 'bannerImageUrl' | 'brandStatement' | 'columnLayout' | 'editorialSubhead' | 'editorialBannerBlockStyle' | 'featuredContent' | 'features' | 'filters' | 'sets' | 'productGroups'>

export type StandardDocument = Omit<CommonResponseDocument, 'altText' | 'bannerImageUrl' | 'imageCaption' | 'editorialSubhead' | 'editorialBannerBlockStyle' | 'imageUrl' | 'products' | 'subFeatureKey' | 'sets'>

export type EditorialDocument = Omit<CommonResponseDocument, 'altText' | 'imageCaption' | 'imageUrl' | 'products' | 'subFeatureKey' | 'productGroups'>

export type ClearanceSubcategoryDocument = Pick<CommonResponseDocument, 'bannerImageUrl' | 'canonicalUrl' | 'metaDescription' | 'productGroups'> & {
	name: string;
}

export interface IClearanceSubcategory {
	name: string;
	url: string;
}

export type ClearanceCategoryDocument = Pick<CommonResponseDocument, 'canonicalUrl' | 'metaDescription' | 'productGroups'> & {
	availableSorts: sortOptionKeys[];
	name: string;
	subcategories: IClearanceSubcategory[];
};

export type StandardLinksOnly = Omit<CommonResponse, 'breadcrumbs' | 'document' | 'documentType'>

export type SubcatDocumentType = ShopThisRoomDocument | StandardDocument | EditorialDocument | StandardLinksOnly;

export type ProductGroupType = DefaultProductGroup | StandardProductGroup;

export interface ShopThisRoomSubcategoryResponse extends CommonResponse {
	categoryPageTitle: string,
	document: ShopThisRoomDocument,
	documentType: SUBCAT_DOCUMENT_TYPE.SHOP_THIS_ROOM,
	hideRoomNavLinks: boolean,
	nextRoomUrl?: string,
	previousRoomUrl?: string,
}

export interface StandardSubcategoryResponse extends CommonResponse {
	availableSorts: sortOptionKeys[],
	defaultSort: sortOptionKeys,
	document: StandardDocument,
	documentType: SUBCAT_DOCUMENT_TYPE.STANDARD,
	globalDynamicData: {
		enabledFeatures: string[],
	},
	_links: StandardLinks,
	inStockProducts: boolean,
}

export interface EditorialSubcategoryResponse extends CommonResponse {
	availableSorts: sortOptionKeys[],
	defaultSort: sortOptionKeys,
	document: EditorialDocument,
	documentType: SUBCAT_DOCUMENT_TYPE.EDITORIAL,
	globalDynamicData: {
		enabledFeatures: string[],
	},
}

export interface ClearanceSubcategoryResponse extends Omit<CommonResponse, '_links'> {
	document: ClearanceSubcategoryDocument;
	documentType: SUBCAT_DOCUMENT_TYPE.CLEARANCE_SUBCATEGORY;
}

export interface ClearanceCategoryResponse extends Omit<CommonResponse, '_links'> {
	document: ClearanceCategoryDocument;
	documentType: SUBCAT_DOCUMENT_TYPE.CLEARANCE_CATEGORY;
}

export type ClearancePageShape = ClearanceSubcategoryResponse | ClearanceCategoryResponse;

export interface IGroupedByJustAdded {
	justAdded: StandardProductGroupInterface[];
	notJustAdded: StandardProductGroupInterface[];
}

export type PageShape = ShopThisRoomSubcategoryResponse | StandardSubcategoryResponse | EditorialSubcategoryResponse;

export const isShopThisRoom = (
	data: PageShape,
): data is ShopThisRoomSubcategoryResponse => {
	return data.documentType === SUBCAT_DOCUMENT_TYPE.SHOP_THIS_ROOM;
};

export const isStandardSubcategory = (
	data: PageShape,
): data is StandardSubcategoryResponse => {
	return data.documentType === SUBCAT_DOCUMENT_TYPE.STANDARD;
};

export const isEditorialSubcategory = (
	data: PageShape,
): data is EditorialSubcategoryResponse => {
	return data.documentType === SUBCAT_DOCUMENT_TYPE.EDITORIAL;
};

export const isClearanceCategory = (
	data: ClearancePageShape,
): data is ClearanceCategoryResponse => {
	return data.documentType === SUBCAT_DOCUMENT_TYPE.CLEARANCE_CATEGORY;
};

export const isStandardProductGroup = (
	data: ProductGroupType,
): data is StandardProductGroup => {
	return Object.prototype.hasOwnProperty.call(data, 'filters') && Object.prototype.hasOwnProperty.call(data, 'valuesToSortOn');
};

export const isAllProductsLink = (
	data: SubcatDocumentType,
): data is StandardLinksOnly => {
	return Object.prototype.hasOwnProperty.call(data, '_links') && !Object.prototype.hasOwnProperty.call(data, 'document');
};
