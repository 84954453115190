import { SelectorHeadingDownload } from '~/product/casegoods/selector/Components/download/SelectorHeadingDownload';
import { SelectorHeadingFrame } from '~/product/casegoods/selector/Components/frame/SelectorHeadingFrame';
import { SelectorHeadingInsert } from '~/product/casegoods/selector/Components/insert/SelectorHeadingInsert';
import { SelectorHeadingOptions } from '~/product/casegoods/selector/Components/options/SelectorHeadingOptions';
import { SelectorHeadingSwatchCombined } from '~/product/casegoods/selector/Components/swatch-combined/SelectorHeadingSwatchCombined';

export const selectorHeadingCasegoodsComponents = {
	DOWNLOAD: SelectorHeadingDownload,
	FRAME: SelectorHeadingFrame,
	STACK: SelectorHeadingInsert,
	INSERT: SelectorHeadingInsert,
	OPTIONS: SelectorHeadingOptions,
	SWATCH_COMBINED: SelectorHeadingSwatchCombined,
};
