import { observer } from 'mobx-react';
import React from 'react';

import { workspaceModalInsertOptionsCasegoodsComponents } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-insert-options/modal-insert-options-casegoods-components';

export const WorkspaceModalInsertOptionsControlCasegoods = observer((props = {}) => {
	const {
		selectedSelector = {},
		selectedSelector: {
			renderer = '',
		} = {},
		selectedSelectorValue = {},
	} = props;

	let ControlRenderer = workspaceModalInsertOptionsCasegoodsComponents[renderer];

	if (!ControlRenderer) {
		ControlRenderer = workspaceModalInsertOptionsCasegoodsComponents.VERTICAL_RADIO;

		console.warn(`No insert options control renderer found for "${renderer}"`);
	}

	return (
		<ControlRenderer
			selectedSelector={selectedSelector}
			selectedSelectorValue={selectedSelectorValue}
		/>
	);
});
