import { ShopThisRoomProduct } from '~/subcategory/common/subcategory.type';

export class ShopThisRoomProductModel {
	addToFavoritesAPI;

	addToFavoritesPropertiesAPI;

	addToFavoritesType;

	articleNumber;

	moreOptions;

	name;

	imageUrl;

	url;

	priceRange;

	reviews;

	status;

	constructor(data: ShopThisRoomProduct) {
		this.articleNumber = data.articleNumber;
		this.moreOptions = data.moreOptions;
		this.name = data.name;
		this.imageUrl = data.imageUrl;
		this.url = data.url;
		this.priceRange = data.priceRange;
		this.reviews = data.reviews;
		this.status = data.status;
		this.addToFavoritesAPI = data._links?.addToFavorites.href || '';
		this.addToFavoritesPropertiesAPI = data._links?.addToFavoritesProperties.href || '';
		this.addToFavoritesType = data._links?.addToFavorites.type || undefined;
	}
}
