import type { MouseEvent } from 'react';

import axios from 'axios';

import type { AxiosResponse } from 'axios';

import { apiUrl } from '~/global/global.constants';
import type { SibilingSectionalDesignerModel } from '~/product/common/selector/Components/links/siblingSectionalDesignerLink/Models/SiblingSectionalDesignerLink.model';

class SibilingSectionalDesignerStore {
	model: SibilingSectionalDesignerModel

	summaryModel: any

	constructor(model: any) {
		this.model = model;
	}

	getSiblingSectionalDesigner(event: MouseEvent, link: string): Promise<AxiosResponse | void> {
		const {
			siblingParams = {},
			siblingWorkspaceData = {},
		} = this.model;

		const url = `${apiUrl}${link}`;

		return axios.request({
			data: {
				...siblingParams,
				workspace: siblingWorkspaceData,
			},
			method: 'post',
			url,
		})
			.then(({ data }) => {
				const {
					_links: {
						product: {
							href = null,
						} = {},
					} = {},
				} = data;

				window.location.href = `${href}&activeTabRenderer=FABRIC`;
			})
			.catch(({
				response: {
					data: { errors = [] },
				},
			}) => {
				console.log(errors);
			});
	}
}

export const SiblingSectionalDesignerStoreFactory = {
	create(model: SibilingSectionalDesignerModel) {
		const siblingSectionalDesignerStore = new SibilingSectionalDesignerStore(model);

		return siblingSectionalDesignerStore;
	},
};
