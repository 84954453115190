import React from 'react';

import type { ISelectorProps } from '~/product/casegoods/selector/Types/SelectorCasegoods.types';

import { SelectorRecursive } from '~/product/casegoods/selector/Components/recursive/SelectorRecursive';

export const SelectorNone = ({
	selectedSelector: {
		selectorValues,
	},
	scrollHandler,
	touchStartHandler,
}: ISelectorProps) => {
	return (
		<>
			{
				selectorValues.map(({
					selectedSelector = {},
				}, index) => {
					return (
						<div key={`selector-value-${index}`}>
							<SelectorRecursive selectedSelector={selectedSelector} scrollHandler={scrollHandler} touchStartHandler={touchStartHandler} />
						</div>
					);
				})
			}
		</>
	);
};
