import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '~/product/casegoods/selector/Components/options/selector-options-group-inserts.module.scss';

export const SelectorOptionsGroupInserts = observer((props = {}) => {
	const {
		slotModel: {
			insertOptionsSelectorsToRender = [],
			insertProductModel: {
				subtitle = '',
				title = '',
			} = {},
		} = {},
	} = props;

	const {
		selectorConfigModel: {
			materialSelectorModels = [],
		} = {},
	} = useProductContext();

	const finishMaterialSelector = materialSelectorModels.find(({ type = '' }) => type === 'FINISH_MATERIAL') || {};

	const {
		selectedSelectorValue: {
			answer = '',
		} = {},
	} = finishMaterialSelector;

	return (
		<div className={styles['selector-options-group-inserts']}>
			<div className={styles['selector-options-group-inserts-title']}>
				{title}
			</div>
			{
				subtitle && (
					<div className={styles['selector-options-group-inserts-subtitle']}>
						{subtitle}
					</div>
				)
			}
			{
				Boolean(insertOptionsSelectorsToRender.length) && (
					<ul
						className={styles['selector-options-group-inserts-selector-values']}
						data-qa="selector-options-group-inserts-selector-values"
					>
						{
							insertOptionsSelectorsToRender.map(({
								selectedSelectorValue: {
									title: selectorValueTitle = '',
									upcharge = '',
									woodUpcharges = {},
								} = {},
							}, selectorIndex) => {
								const woodUpcharge = woodUpcharges[answer];

								const upchargeToUse = woodUpcharge || upcharge;

								return (
									<li
										className={
											classNames({
												[styles['selector-options-group-inserts-selector-value-upcharge']]: upchargeToUse,
											})
										}
										key={selectorIndex}
									>
										{selectorValueTitle} {upchargeToUse}
									</li>
								);
							})
						}
					</ul>
				)
			}
		</div>
	);
});
