import { casegoodsHelpModals } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-help/casegoodHelpModalLibrary';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

export const WorkspaceModalHelpCasegoods = () => {
	const {
		productGroupModel: {
			productPageType = '',
		} = {},
		selectorConfigModel: {
			frameSelectorModel: {
				productModels: frameProductModels = [],
			} = {},
		} = {},
	} = useProductContext();

	const firstFrameProductModel = frameProductModels.length ? frameProductModels[0] : {};

	const { imageCollectionName = '' } = firstFrameProductModel;

	return casegoodsHelpModals[imageCollectionName] || casegoodsHelpModals[productPageType] || casegoodsHelpModals['DEFAULT'];
};
