import React from 'react';
import { observer } from 'mobx-react';

import { CuralateItemProduct } from '~/util/curalate/Components/CuralateItemProduct';

import styles from '~/util/curalate/Components/curalate-detail.module.scss';

const CuralateItemProducts = observer(class CuralateItemProducts extends React.Component {
	constructor(props) {
		super(props);

		this.handleItemProductClick = this.handleItemProductClick.bind(this);
	}

	render() {
		const { item } = this.props;

		const { products = [] } = item;

		const curalateProducts = products.map((product, index) => {
			return (
				<CuralateItemProduct clickHandler={this.handleItemProductClick} key={`curalate-item-product-${index}`} product={product} />
			);
		});

		if (curalateProducts.length) {
			return (
				<div className={styles['curalate-item-products-container']}>
					<h2 className="tw-heading-5 tw-font-normal" data-qa="curalate-item-products-heading">Shop This Room</h2>
					<ul className={styles['curalate-item-products']}>
						{curalateProducts}
					</ul>
				</div>
			);
		}

		return null;
	}

	handleItemProductClick(product) {
		const {
			handleItemProductClick,
			item,
		} = this.props;

		handleItemProductClick(item, product);
	}
});

export { CuralateItemProducts };
