import React from 'react';
import { observer } from 'mobx-react';

import { DeliveryTruckIcon } from '~/delivery/Components/DeliveryTruckIcon';
import { Field } from '~/util/formz/Components/fields/Field';

export const DeliveryForm = observer((props) => {
	const {
		store: {
			formStore: {
				form,
			},
			invalidTzone,
			controlReactPropsOverride,
		},
	} = props;

	return (
		<div className="delivery-container delivery-container-standard delivery-container-inhome edit-zip-code">
			<div className="delivery-icon">
				<DeliveryTruckIcon />
			</div>

			<div className="delivery-content">
				<form {...form.reactProps}>
					<Field
						field={form.fields.zipCode}
						controlReactPropsOverride={controlReactPropsOverride}
						afterControl={
							<button
								data-qa="delivery-widget-submit-button"
								className="ButtonTertiary ButtonTertiary--gray tw-font-normal"
							>
								Submit
							</button>
						}
					/>
					{
						invalidTzone &&
						<div data-qa="invalid-trans-zone-message" className="validation">
							<span className="tw-text-red">
								{'Please check your ZIP Code for accuracy. For deliveries to AK or HI, please call '}
								<a href="tel:+18003019720">800.301.9720</a>
							</span>
						</div>
					}
				</form>
			</div>
		</div>
	);
});
