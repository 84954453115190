import { extendObservable } from 'mobx';
import React from 'react';

import { MagicTabccordionStore } from '~/components/magic-tabccordion/Stores/magicTabccordion.store';
import { SelectorInsertTabccordionTab } from '~/product/casegoods/selector/Components/insert/SelectorInsertTabccordionTab';
import { SelectorInsertTabccordionTabHeading } from '~/product/casegoods/selector/Components/insert/SelectorInsertTabccordionTabHeading';

export const createSelectorInsertMagicTabccordionStore = ({
	isDraggable,
	linkEventStore = {},
	selectorValues = [],
}) => {
	const tabGroups = [
		{
			tabs: selectorValues.map((selectorValueModel, index = 0) => {
				return extendObservable({
					isDraggable,
					selectorValueModel,
					tabTitleComponent: ({
						tab: {
							isActive,
						},
					}) => {
						return (
							<SelectorInsertTabccordionTabHeading
								selectorValueModel={selectorValueModel}
								isActive={isActive}
							/>
						);
					},
				}, {
					isActive: index === 0,
				});
			}),
		},
	];

	const selectorInsertTabccordionData = {
		disableSmoothScroll: true,
		id: 'selector-insert-tabccordion',
		renderMode: 'TABS',
		tabGroups,
		wrapperRenderer: SelectorInsertTabccordionTab,
	};

	const selectorInsertMagicTabccordionStore = new MagicTabccordionStore(selectorInsertTabccordionData, linkEventStore);

	return selectorInsertMagicTabccordionStore;
};
