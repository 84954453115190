import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '~/product/sectionals/workspace/Components/workspace-invalid-configuration-message.module.scss';

export const WorkspaceInvalidConfigurationMessage = observer(() => {
	const { workspaceStore = {} } = useProductContext();

	const containerRef = useRef<HTMLDivElement>(null);

	const messageRef = useRef<HTMLDivElement>(null);

	function closeInvalidConfigurationMessage() {
		workspaceStore.setShowInvalidConfigurationMessage(false);
	}

	function handleBodyMouseDown(event: any) {
		const workspaceInvalidConfigutationMessageElements = document.querySelectorAll('[data-workspace-invalid-configuration-message]');

		const targetIsWorkspaceInvlidConfigurationMessage = Array.from(workspaceInvalidConfigutationMessageElements).some((workspaceInvalidConfigurationMessageElement) => {
			return workspaceInvalidConfigurationMessageElement === event.target || workspaceInvalidConfigurationMessageElement.contains(event.target);
		});

		if (!targetIsWorkspaceInvlidConfigurationMessage) {
			closeInvalidConfigurationMessage();
		}
	}

	useEffect(() => {
		document.querySelector('body')!.addEventListener('mousedown', handleBodyMouseDown);
		document.querySelector('body')!.addEventListener('touchstart', handleBodyMouseDown);

		return () => {
			document.querySelector('body')!.removeEventListener('mousedown', handleBodyMouseDown);
			document.querySelector('body')!.removeEventListener('touchstart', handleBodyMouseDown);
		};
	}, []);

	return (
		<div
			className={styles['workspace-invalid-configuration-message']}
			data-qa="workspace-invalid-configuration-message"
			data-workspace-invalid-configuration-message={true}
			ref={containerRef}
		>
			<button
				className={styles['workspace-invalid-configuration-message-close']}
				data-qa="workspace-invalid-configuration-message-close"
				onClick={closeInvalidConfigurationMessage}
			>
				<span className="tw-sr-only">
					Close
				</span>
			</button>
			<p
				className={styles['workspace-invalid-configuration-message-text']}
				ref={messageRef}
			>
				This piece cannot be removed since remaining pieces will not fit together.
			</p>
		</div>
	);
});
