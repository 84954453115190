class ErrorsModel {
	errorCode

	constructor(errorCode) {
		this.errorCode = errorCode;
	}

	get componentKey() {
		let componentKey = '';
		switch (this.errorCode) {
			case 'NOTCUSTOMIZABLE':
			case 'NOT_CUSTOMIZABLE':
				componentKey = 'CUSTOM_SIZE_LACKING_ERROR';
				break;
			case 'CUSTOM_OPTION_NOT_AVAILABLE':
				componentKey = 'CUSTOM_OPTION_NOT_AVAILABLE';
				break;
			case 'CUSTOM_STYLE_OPTION_NOT_AVAILABLE':
				componentKey = 'CUSTOM_STYLE_OPTION_NOT_AVAILABLE';
				break;
			default:
				break;
		}
		return componentKey;
	}

	get modalTitle() {
		let title = '';
		switch (this.errorCode) {
			case 'NOTCUSTOMIZABLE':
			case 'NOT_CUSTOMIZABLE':
				title = 'Size not available in custom';
				break;
			case 'CUSTOM_OPTION_NOT_AVAILABLE':
				title = 'Material not available';
				break;
			case 'CUSTOM_STYLE_OPTION_NOT_AVAILABLE':
				title = 'Option not available';
				break;
			default:
				break;
		}
		return title;
	}
}

export { ErrorsModel };
