import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import styles from '#/product/standard/details/details-attributes.module.scss';
import stylesDetail from '#/product/standard/details/details.module.scss';

export const DetailCustomBookcaseQuestionAnswer = observer((props) => {
	const {
		workspaceModel: {
			sortedWorkspaceProducts = [],
		} = {},
	} = useProductContext();

	const {
		detail: {
			dividerCount = 0,
			label = '',
			prefix = '',
			subtrahend = 0,
			suffix = '',
		} = {},
		detailsListIndex = 0,
	} = props;

	const workspaceProduct = sortedWorkspaceProducts[detailsListIndex];

	const { workspaceProductWidth = 0 } = workspaceProduct;

	const displayWidth = ((workspaceProductWidth - subtrahend) / dividerCount).toFixed(2);

	const displayText = `${prefix}${displayWidth}${suffix}`;

	return (
		<div
			className={`${styles['detail']} ${stylesDetail['detail']} detail-name-value`}
			data-qa="detail-name-value"
		>
			<strong data-qa="detail-name-value-name">{label}{displayText && ':'}</strong>
			<span data-qa="detail-name-value-value"> {displayText}</span>
		</div>
	);
});
