import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { SelectorValueTwoColumn } from '~/product/common/selector/Components/two-column/SelectorValueTwoColumn';
import { SelectorPromos } from '~/product/common/selector/Components/SelectorPromos';
import { getShouldShowShadowY } from '~/product/common/selector/Utils/Selector.utils';
import styles from '#/product/standard/selector/selector-drop-down.module.scss';
import stylesTwoColumn from '#/product/standard/selector/selector-two-column.module.scss';

export const SelectorTwoColumn = (props) => {
	const {
		isActive = false,
		selectedSelector: {
			label = '',
			promos = [],
			selectorValues = [],
		} = {},
		selectorIndex = 0,
	} = props;

	const scrollingParentRef = useRef();

	const scrollingChildRef = useRef();

	const [showShadow, setShowShadow] = useState(false);

	let ticking = false;

	function setShowShadowState() {
		const shouldShowShadow = getShouldShowShadowY({
			isActive,
			scrollingChildRef,
			scrollingParentRef,
		});

		setShowShadow(shouldShowShadow);
	}

	function handleScroll() {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				ticking = false;

				setShowShadowState();
			});

			ticking = true;
		}
	}

	useEffect(setShowShadowState, [isActive]);

	return (
		<>
			<div className={stylesTwoColumn['selector-two-column-wrapper']}>
				<div
					className={`${styles['selector-drop-down-container']} ${stylesTwoColumn['selector-two-column-container']}`}
					onScroll={handleScroll}
					ref={scrollingParentRef}
				>
					<div
						className={
							classNames('selector-two-column', {
								[stylesTwoColumn['selector-two-column-shadow']]: showShadow,
							})
						}
						ref={scrollingChildRef}
					>
						<fieldset className="selector-values-two-column-container">
							<legend className="tw-sr-only">Choose a {label}</legend>
							<div className={stylesTwoColumn['selector-values-two-column']}>
								{
									selectorValues.map((selectorValue, index) => {
										return (
											<SelectorValueTwoColumn
												key={`selector-value-${index}`}
												label={label}
												selectorIndex={selectorIndex}
												selectorValue={selectorValue}
											/>
										);
									})
								}
							</div>
						</fieldset>
					</div>
				</div>
			</div>
			<SelectorPromos promos={promos} />
		</>
	);
};
