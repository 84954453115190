import { calculateImageScale } from '~/product/casegoods/workspaces/defaultWorkspace/Utils/WorkspaceCasegoods.utils';
import {
	dimensionsWorkspaceCasegoods,
	imageScaleWorkspaceCasegoods,
	downloadImageWorkspaceCasegoods,
} from '~/product/casegoods/workspaces/defaultWorkspace/workspace-casegoods.constants';
import { addToUrl } from '~/util/addToUrl';

// Wait for all frame images to download
export function getWorkspaceCanvasFrameImages(sortedWorkspaceProducts) {
	const workspaceFrameImagePromises = sortedWorkspaceProducts.map((workspaceProduct) => {
		return new Promise((resolve) => {
			const {
				frameProductModel: {
					imageCollectionName = 'DEFAULT',
					topUrl = '',
				} = {},
				workspaceProductImageUrl = '',
			} = workspaceProduct;
			const imageUrlToUse = topUrl || workspaceProductImageUrl;
			const { canvas = 0 } = imageScaleWorkspaceCasegoods[imageCollectionName];

			const workspaceFrameImage = new Image();

			// Required attr to convert the canvas to a data url that contains crossorigin images (s7)
			// This attribute *must* be applied before 'src' or else iOS Safari will complain
			workspaceFrameImage.crossOrigin = 'Anonymous';
			workspaceFrameImage.src = addToUrl(imageUrlToUse, `$proddd$&scl=${canvas}`);

			const handleWorkspaceImageLoad = () => {
				workspaceFrameImage.removeEventListener('load', handleWorkspaceImageLoad);
				resolve({
					workspaceFrameImage,
					workspaceProduct,
				});
			};

			workspaceFrameImage.addEventListener('load', handleWorkspaceImageLoad);
		});
	});

	return Promise.all(workspaceFrameImagePromises);
}

// Wait for all insert images per frame to download
export function getWorkspaceCanvasInsertImages(sortedWorkspaceProducts) {
	const workspaceInsertImagePromises = sortedWorkspaceProducts.map((workspaceProduct) => {
		const {
			slotModelsWithInserts = [],
			frameProductModel: {
				imageCollectionName = 'DEFAULT',
			} = {},
		} = workspaceProduct;
		const { canvas = 0 } = imageScaleWorkspaceCasegoods[imageCollectionName];

		const workspaceSlotWithInsertImagePromises = slotModelsWithInserts.map((workspaceSlotModel) => {
			return new Promise((resolve) => {
				const {
					insertProductModel: {
						imageUrl = '',
					} = {},
				} = workspaceSlotModel;

				const workspaceInsertImage = new Image();

				// Required attr to convert the canvas to a data url that contains crossorigin images (s7)
				// This attribute *must* be applied before 'src' or else iOS Safari will complain
				workspaceInsertImage.crossOrigin = 'Anonymous';
				workspaceInsertImage.src = addToUrl(imageUrl, `$proddd$&fmt=png-alpha&scl=${canvas}`);

				const handleInsertImageLoad = () => {
					workspaceInsertImage.removeEventListener('load', handleInsertImageLoad);
					resolve({
						workspaceInsertImage,
						workspaceSlotModel,
					});
				};

				workspaceInsertImage.addEventListener('load', handleInsertImageLoad);
			});
		});

		return Promise.all(workspaceSlotWithInsertImagePromises);
	});

	return Promise.all(workspaceInsertImagePromises);
}

function frameDepthDimension({
	ctx, depth, imageScale, textYOffset, xMargin,
}) {
	const fontSize = Math.ceil(60 / imageScale);

	ctx.fillStyle = '#000000';
	ctx.textBaseline = 'bottom';
	ctx.textAlign = 'center';
	ctx.font = `${fontSize}px proxima-nova, Arial`;
	ctx.fillText(`${depth}" d`, xMargin, textYOffset);
}

function frameWidthDimension({
	ctx, imageScale, imageWidth, width, yMargin,
}) {
	const fontSize = Math.ceil(60 / imageScale);
	const lineYOffset = yMargin - (60 / imageScale);
	const textYOffset = yMargin - (90 / imageScale);
	const endcapWidth = 20 / imageScale;

	ctx.fillStyle = '#000000';
	ctx.textBaseline = 'bottom';
	ctx.textAlign = 'center';
	ctx.font = `${fontSize}px proxima-nova, Arial`;
	ctx.lineWidth = 3 / imageScale;
	ctx.fillText(`${width}" w`, 0 + (imageWidth / 2), textYOffset);
	ctx.beginPath();
	ctx.moveTo(0, lineYOffset);
	ctx.lineTo(imageWidth - 1, lineYOffset);
	ctx.stroke();
	ctx.moveTo(1, lineYOffset - endcapWidth);
	ctx.lineTo(1, lineYOffset + endcapWidth);
	ctx.stroke();
	ctx.moveTo(imageWidth - 1, lineYOffset - endcapWidth);
	ctx.lineTo(imageWidth - 1, lineYOffset + endcapWidth);
	ctx.stroke();
}

function workspaceHeightDimension({
	ctx, imageScale, workspaceCanvas, workspaceProductsHeight, xMargin, yMargin,
}) {
	const fontSize = Math.ceil(60 / imageScale);
	const lineXOffset = xMargin - (60 / imageScale);
	const textXOffset = xMargin - (90 / imageScale);
	const endcapWidth = 20 / imageScale;

	ctx.fillStyle = '#000000';
	ctx.textBaseline = 'middle';
	ctx.textAlign = 'end';
	ctx.font = `${fontSize}px proxima-nova, Arial`;
	ctx.lineWidth = 3 / imageScale;
	ctx.fillText(`${workspaceProductsHeight}" h`, textXOffset, workspaceCanvas.height / 2);
	ctx.beginPath();
	ctx.moveTo(lineXOffset, yMargin);
	ctx.lineTo(lineXOffset, workspaceCanvas.height - yMargin);
	ctx.stroke();
	ctx.moveTo(lineXOffset - endcapWidth, yMargin);
	ctx.lineTo(lineXOffset + endcapWidth, yMargin);
	ctx.stroke();
	ctx.moveTo(lineXOffset - endcapWidth, workspaceCanvas.height - yMargin);
	ctx.lineTo(lineXOffset + endcapWidth, workspaceCanvas.height - yMargin);
	ctx.stroke();
}

function workspaceWidthDimension({
	ctx,
	imageScale,
	workspaceCanvas,
	workspaceProductsWidth,
	xMargin,
}) {
	const fontSize = Math.ceil(60 / imageScale);
	const lineYOffset = 230 / imageScale;
	const textYOffset = 200 / imageScale;
	const endcapWidth = 20 / imageScale;

	ctx.fillStyle = '#000000';
	ctx.textBaseline = 'bottom';
	ctx.textAlign = 'center';
	ctx.font = `${fontSize}px proxima-nova, Arial`;
	ctx.lineWidth = 3 / imageScale;
	ctx.fillText(`${workspaceProductsWidth}" w`, workspaceCanvas.width / 2, textYOffset);
	ctx.beginPath();
	ctx.moveTo(xMargin, lineYOffset);
	ctx.lineTo(workspaceCanvas.width - xMargin, lineYOffset);
	ctx.stroke();
	ctx.moveTo(xMargin, lineYOffset - endcapWidth);
	ctx.lineTo(xMargin, lineYOffset + endcapWidth);
	ctx.stroke();
	ctx.moveTo(workspaceCanvas.width - xMargin, lineYOffset - endcapWidth);
	ctx.lineTo(workspaceCanvas.width - xMargin, lineYOffset + endcapWidth);
	ctx.stroke();
}

export function composeWorkspaceBlob(workspaceCanvas = {}, workspaceFrameImages = [], workspaceInsertImages = [], imageCollectionName = '', workspaceDimensions = {}) {
	const {
		mimeType,
		compression,
		slotPaddingXMult,
		slotPaddingYMult,
	} = downloadImageWorkspaceCasegoods[imageCollectionName];

	const {
		imageInchesToPixelsFactor,
		offsetBottomInches,
		offsetTopInches,
	} = dimensionsWorkspaceCasegoods[imageCollectionName];

	const {
		workspaceProductsHeight = 0,
		workspaceProductsWidth = 0,
	} = workspaceDimensions;

	const { canvas: imageScale = 0 } = imageScaleWorkspaceCasegoods[imageCollectionName];
	const ctx = workspaceCanvas.getContext('2d');

	let workspaceCanvasWidth = 0;
	let workspaceCanvasHeight = 0;

	const xMargin = Math.ceil(350 / imageScale);
	const yMargin = Math.ceil(500 / imageScale);

	const frameImages = workspaceFrameImages.map((workspaceFrameImageObj, i) => {
		const {
			workspaceFrameImage,
			workspaceProduct: {
				frameProductModel: {
					depth: frameProductDepth = 0,
					slotsRowCount = 0,
					width: frameProductWidth = 0,
				} = {},
			} = {},
		} = workspaceFrameImageObj;

		const {
			width: frameImageWidth,
			height: frameImageHeight,
		} = workspaceFrameImage;

		// keep track of workspace overall width and height
		workspaceCanvasWidth += frameImageWidth;
		workspaceCanvasHeight = frameImageHeight > workspaceCanvasHeight ? frameImageHeight : workspaceCanvasHeight;

		// create a new canvas for each frame
		const frameCanvas = document.createElement('canvas');
		const frameCtx = frameCanvas.getContext('2d');
		const frameCanvasHeight = frameImageHeight + (yMargin * 2);
		frameCtx.clearRect(0, 0, frameImageWidth, frameCanvasHeight);

		// Resize frame canvas to fit frame image
		frameCanvas.width = frameImageWidth;
		frameCanvas.height = frameCanvasHeight;
		frameCtx.fillStyle = '#FFFFFF';
		frameCtx.fillRect(0, 0, frameCanvas.width, frameCanvasHeight);

		const textYOffset = frameCanvas.height - ((yMargin / 2) / imageScale);

		// draw the frames width and depth
		if (frameProductWidth && frameProductDepth) {
			frameDepthDimension({
				ctx: frameCtx,
				depth: frameProductDepth,
				imageScale,
				xMargin,
				textYOffset,
			});
			frameWidthDimension({
				ctx: frameCtx,
				imageScale,
				imageWidth: frameImageWidth,
				width: frameProductWidth,
				xMargin,
				yMargin,
			});
		}

		// draw the frame
		frameCtx.drawImage(workspaceFrameImage, 0, yMargin, frameImageWidth, frameImageHeight);

		// draw current frame's insert images
		workspaceInsertImages[i]
			.reverse() // Place images bottom-up
			.forEach((workspaceInsertImageObj) => {
				const {
					workspaceSlotModel: {
						rowIndex,
					} = {},
					workspaceInsertImage,
				} = workspaceInsertImageObj;

				const frameHeight = frameCanvas.height;
				const insertsTop = ((imageInchesToPixelsFactor * offsetTopInches) / imageScale) + yMargin; // Top of insert drop zone
				const insertsBottom = (frameHeight - ((imageInchesToPixelsFactor * offsetBottomInches) / imageScale)) - yMargin; // Bottom of insert drop zone
				const insertsRange = insertsBottom - insertsTop; // Size of insert drop zone
				const slotPaddingX = slotPaddingXMult / imageScale; // Scaled X padding
				const slotPaddingY = slotPaddingYMult / imageScale; //  Scaled Y padding
				const insertPosY = insertsBottom - (rowIndex + 1) * (insertsRange / slotsRowCount) + slotPaddingY; // Where to place the insert per the current slot row, including padding
				const insertPosX = slotPaddingX; // Center the insert horizontally, using padding
				const insertWidth = frameImageWidth - (slotPaddingX * 2); // Remove X padding from left and right of the insert
				const insertHeight = (insertsRange / slotsRowCount) - (slotPaddingY * 2); // Remove Y padding from top and bottom of the insert

				frameCtx.drawImage(workspaceInsertImage, insertPosX, insertPosY, insertWidth, insertHeight);
			});

		return frameCanvas;
	});

	// create the workspace canvas where we create a composition drawing of all of the frame canvases
	workspaceCanvasHeight += yMargin * 2;
	workspaceCanvasWidth += xMargin * 2;

	workspaceCanvas.width = workspaceCanvasWidth;
	workspaceCanvas.height = workspaceCanvasHeight;

	ctx.fillStyle = '#FFFFFF';
	ctx.fillRect(0, 0, workspaceCanvasWidth, workspaceCanvasHeight);

	let xOffset = xMargin;

	// draw the frame canvases onto the workspace canvas
	frameImages.forEach((image) => {
		const yOffset = (workspaceCanvas.height - image.height);
		ctx.drawImage(image, xOffset, yOffset);
		xOffset += image.width;
	});

	// draw the overall workspace height and width
	if (workspaceProductsHeight && workspaceProductsWidth) {
		workspaceHeightDimension({
			ctx,
			imageScale,
			workspaceCanvas,
			workspaceProductsHeight,
			xMargin,
			yMargin,
		});
		workspaceFrameImages.length > 1 && workspaceWidthDimension({
			ctx,
			imageScale,
			workspaceCanvas,
			workspaceProductsWidth,
			xMargin,
		});
	}

	return workspaceCanvas.toDataURL(mimeType, compression);
}

function drawImage({
	ctx = {},
	offsetX = 0,
	offsetY = 0,
	src = '',
}) {
	return new Promise((resolve) => {
		const image = new Image();

		// Required attr to convert the canvas to a data url that contains crossorigin images (s7)
		// This attribute *must* be applied before 'src' or else iOS Safari will complain
		image.crossOrigin = 'Anonymous';
		image.src = src;

		const handleWorkspaceImageLoad = () => {
			image.removeEventListener('load', handleWorkspaceImageLoad);

			ctx.drawImage(image, offsetX, offsetY);

			resolve();
		};

		image.addEventListener('load', handleWorkspaceImageLoad);
	});
}

// Assemble canvas and create the base64 data url with workspace images
export function createImageBlobStack({
	workspaceCanvas = {},
	workspaceModel: {
		dimensions: {
			imageInchesToPixelsFactor = 0,
			translateBottomInches = 0,
		} = {},
		sortedWorkspaceProducts = [],
		workspaceProductsHeight = 0,
		workspaceProductsWidth = 0,
	} = {},
}) {
	const { workspaceProductsPixelsHeight, workspaceProductsPixelsWidth, imageScaleToUse: imageScale } = calculateImageScale({
		imageInchesToPixelsFactor,
		workspaceProductsHeight,
		workspaceProductsWidth,
		defaultImageScale: 3,
	});

	const translateBottomPixels = (imageInchesToPixelsFactor * translateBottomInches) / imageScale;

	const ctx = workspaceCanvas.getContext('2d');

	async function stackImagePromiseResolver(resolve, {
		frameProductModel: {
			baseHeight = 0,
			baseUrl = '',
			topHeight = 0,
			topUrl = '',
		} = {},
		slotModelsWithInserts = [],
	}, workspaceProductIndex = 0) {
		const prevWorkspaceProductModels = sortedWorkspaceProducts.slice(0, workspaceProductIndex);

		// eslint-disable-next-line default-param-last
		const workspaceProductOffsetX = prevWorkspaceProductModels.reduce((accumulatedOffsetX = 0, { workspaceProductWidth = 0 }) => {
			const workspaceProductPixelsWidth = (imageInchesToPixelsFactor * workspaceProductWidth) / imageScale;

			return accumulatedOffsetX + workspaceProductPixelsWidth;
		}, 0);

		const baseHeightPixels = ((imageInchesToPixelsFactor * baseHeight) / imageScale);

		const baseOffsetY = workspaceProductsPixelsHeight - baseHeightPixels;

		const baseSrc = addToUrl(baseUrl, `$proddd$&fmt=png-alpha&scl=${imageScale}`);

		await drawImage({
			ctx,
			offsetX: workspaceProductOffsetX,
			offsetY: baseOffsetY,
			src: baseSrc,
		});

		async function insertImagePromiseResolver(resolveInsert, {
			insertProductModel: {
				height = 0,
				imageUrl = '',
			} = {},
		}, slotIndex = 0) {
			const prevSlotModelsWithInserts = slotModelsWithInserts.slice(0, slotIndex);

			// eslint-disable-next-line default-param-last
			const prevInsertsHeightPixels = prevSlotModelsWithInserts.reduce((accumulatedInsertsHeight = 0, {
				insertProductModel: {
					height: prevHeight = 0,
				} = {},
			}) => {
				const insertHeightPixels = ((imageInchesToPixelsFactor * prevHeight) / imageScale);

				return accumulatedInsertsHeight + insertHeightPixels;
			}, 0);

			const insertHeightPixels = ((imageInchesToPixelsFactor * height) / imageScale);

			const insertOffsetY = baseOffsetY + translateBottomPixels - prevInsertsHeightPixels - insertHeightPixels;

			const insertSrc = addToUrl(imageUrl, `$proddd$&fmt=png-alpha&scl=${imageScale}`);

			await drawImage({
				ctx,
				offsetX: workspaceProductOffsetX,
				offsetY: insertOffsetY,
				src: insertSrc,
			});

			resolveInsert();
		}

		await Promise.all(slotModelsWithInserts.map((workspaceProduct, index) => {
			return new Promise((resolveInsert) => {
				insertImagePromiseResolver(resolveInsert, workspaceProduct, index);
			});
		}));

		// eslint-disable-next-line default-param-last
		const insertsHeightPixels = slotModelsWithInserts.reduce((accumulatedOffsetY = 0, {
			insertProductModel: {
				height = 0,
			} = {},
		}) => {
			const insertHeightPixels = ((imageInchesToPixelsFactor * height) / imageScale);

			return accumulatedOffsetY + insertHeightPixels;
		}, 0);

		const insertsOffsetY = baseOffsetY + translateBottomPixels - insertsHeightPixels;

		const topHeightPixels = ((imageInchesToPixelsFactor * topHeight) / imageScale);

		const topOffsetY = insertsOffsetY - topHeightPixels;

		const topSrc = addToUrl(topUrl, `$proddd$&fmt=png-alpha&scl=${imageScale}`);

		await drawImage({
			ctx,
			offsetX: workspaceProductOffsetX,
			offsetY: topOffsetY,
			src: topSrc,
		});

		resolve();
	}

	const stackImagePromises = sortedWorkspaceProducts.map((workspaceProduct, index) => {
		return new Promise((resolve) => {
			stackImagePromiseResolver(resolve, workspaceProduct, index);
		});
	});

	workspaceCanvas.width = workspaceProductsPixelsWidth;
	workspaceCanvas.height = workspaceProductsPixelsHeight;

	ctx.fillStyle = '#fff';
	ctx.fillRect(0, 0, workspaceProductsPixelsWidth, workspaceProductsPixelsHeight);

	return Promise.all(stackImagePromises);
}
