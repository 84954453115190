import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useDragLayer } from 'react-dnd';

import { WorkspaceStackDropZone } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceStackDropZone';
import { WorkspaceProductStackInsertDraggableCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductStackInsertDraggableCasegoods';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

interface propsInterface {
	workspaceProduct: any,
	slot: {
		id: string,
		insertProductModel: any,
		allow: ''[],
		rowIndex: number,
		columnIndex: number
	},
	index: number,
	translateBottomPixels: number,
	imageInchesToPixelsFactor: number,
	imageScale: number
	productIndex: number
}

export const WorkspaceProductStackSlotDraggableCasegoods = observer(({
	workspaceProduct, slot, index, translateBottomPixels, imageInchesToPixelsFactor, imageScale, productIndex
}: propsInterface) => {
	const {
		workspaceModel: {
			workspaceProducts = null,
		} = {},
	} = useProductContext();
	const {
		isDragging,
		item,
		itemType,
	} = useDragLayer((monitor: any) => {
		return {
			isDragging: monitor.isDragging(),
			item: monitor.getItem(),
			itemType: monitor.getItemType(),
		};
	});
	const [isDraggingEcho, setIsDraggingEcho] = useState(false);
	const isDraggingInOwnStack = workspaceProduct.workspaceProductIndex === item?.productIndex;
	const isDraggingSelf = isDraggingInOwnStack && item?.slot?.rowIndex === slot?.rowIndex;
	const isDraggingSiblingBelow = isDraggingInOwnStack && item?.slot?.rowIndex + 1 === slot?.rowIndex;
	const isNotLastSlot = Boolean(workspaceProduct?.workspaceProductSlotModels[index + 1]);
	const isLastFilledSlot = index === workspaceProduct?.slotModelsWithInserts.length - 1;
	const isLoneInsert = workspaceProducts?.length === 1 && workspaceProduct?.slotModelsWithInserts?.length === 1; // only one product in workspace, and that product only has one insert
	const isCompatibleWidth = item?.productModel?.compatibleFrameWidth === workspaceProduct?.workspaceProductWidth;
	const itemIsAllowed = Boolean(slot.allow?.find((a: any) => a === item?.productModel?.answerKey));
	const itemIsAllowedInNextSlot = Boolean(workspaceProduct?.workspaceProductSlotModels[index + 1]?.allow?.find((a: any) => a === item?.productModel?.answerKey));

	// need to allow animations to finish and drag to "solidify" its state before showing/hiding drop zones
	useEffect(() => {
		const isDraggingEchoTimeout = setTimeout(() => setIsDraggingEcho(isDragging), 200);
		return () => { clearTimeout(isDraggingEchoTimeout); };
	}, [isDragging]);

	return (
		<div
			style={{
				marginBottom: -translateBottomPixels,
				zIndex: index,
			}}
		>
			<WorkspaceStackDropZone
				show={
					isDraggingEcho
					&& !isDraggingSelf
					&& !(isLoneInsert && itemType === 'WORKSPACE_INSERT_PRODUCT')
					&& isLastFilledSlot
					&& isCompatibleWidth
					&& isNotLastSlot
					&& itemIsAllowedInNextSlot
				}
				slot={slot}
				location="top"
				imageScale={imageScale}
				productIndex={productIndex}
				imageInchesToPixelsFactor={imageInchesToPixelsFactor}
			/>
			<WorkspaceProductStackInsertDraggableCasegoods slot={slot} workspaceProduct={workspaceProduct} imageInchesToPixelsFactor={imageInchesToPixelsFactor} imageScale={imageScale} productIndex={workspaceProduct.workspaceProductIndex} translateBottomPixels={translateBottomPixels} />
			<WorkspaceStackDropZone
				show={
					isDraggingEcho
					&& !isDraggingSelf
					&& !(isLoneInsert && itemType === 'WORKSPACE_INSERT_PRODUCT')
					&& !isDraggingSiblingBelow
					&& isCompatibleWidth
					&& item?.productModel?.answerKey
					&& itemIsAllowed
				}
				slot={slot}
				location="bottom"
				imageScale={imageScale}
				productIndex={productIndex}
				imageInchesToPixelsFactor={imageInchesToPixelsFactor}
			/>
		</div>
	);
});
