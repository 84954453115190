import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { SelectorValueInsertProductImage } from '~/product/casegoods/selector/Components/insert/SelectorValueInsertProductImage';
import { isEmpty } from '~/util/isEmpty';

import styles from '#/product/casegoods/selector/frame/selector-value-frame-draggable.module.scss';

export const SelectorValueInsertProductDraggable = observer((props) => {
	const {
		imageScale = 0,
		imageScaleDraggable = 0,
		imageScaleDraggableSmall = 0,
		insertOptionsSelectors = [],
		lineDrawingImage = '',
		productModel = {},
		productModel: {
			hasExceptionRows = false,
			imageUrl: image,
		} = {},
		productModels = [],
	} = props;

	const imageToUse = isEmpty(lineDrawingImage) ? image : lineDrawingImage;

	const { workspaceStore = {} } = useProductContext();

	const dataQaSuffix = hasExceptionRows ? 'NONE' : 'ALL';

	const dragItem = {
		imageScale: imageScaleDraggable,
		imageScaleSmall: imageScaleDraggableSmall,
		insertOptionsSelectors,
		lineDrawingImage,
		productModel,
		productModels,
	};

	const dragItemRef = useRef(dragItem);

	const [, drag, preview] = useDrag(() => ({
		end: (() => {
			workspaceStore.setInsertProductIsDragging(false);
		}),
		item: () => {
			workspaceStore.setInsertProductIsDragging(true);

			return dragItemRef.current;
		},
		type: 'INSERT_PRODUCT',
	}));

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	useEffect(() => {
		dragItemRef.current = dragItem;
	});

	return (
		<div
			className={styles['selector-value-frame-draggable']}
			data-qa={`insert-selector-value-draggable-${dataQaSuffix}`}
			ref={drag}
		>
			<SelectorValueInsertProductImage
				imageScale={imageScale}
				image={imageToUse}
				productModel={productModel}
			/>
		</div>
	);
});
