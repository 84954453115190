import React from 'react';
import { observer } from 'mobx-react';

import { addToUrl } from '~/util/addToUrl';

import styles from '#/product/casegoods/workspace/product/workspace-product-stack.module.scss';

interface propsInterface {slot: {id: string, insertProductModel: any, allow: ''[]}, index: number, translateBottomPixels: number, imageInchesToPixelsFactor: number, imageScale: number}

export const WorkspaceProductStackSlotCasegoods = observer(({
	slot, index, translateBottomPixels, imageInchesToPixelsFactor, imageScale
}: propsInterface) => {
	const heightInches = slot.insertProductModel?.height || 0;
	const heightPixels = ((imageInchesToPixelsFactor * heightInches) / imageScale) + translateBottomPixels;
	const widthInches = slot.insertProductModel?.compatibleFrameWidth || 0;
	const widthPixels = (imageInchesToPixelsFactor * widthInches) / imageScale;
	const srcParams = `$proddd$&fmt=png-alpha&scl=${imageScale}`;
	const srcSetParams = `$proddd$&fmt=png-alpha&scl=${imageScale / 2}`;

	return (
		<div
			style={{
				marginBottom: -translateBottomPixels,
				zIndex: index,
			}}
		>
			<img
				alt=""
				className={`${styles['workspace-product-insert-image']} tw-user-drag-none tw-user-select-none`}
				draggable={false}
				src={addToUrl(slot.insertProductModel?.imageUrl, srcParams)}
				srcSet={`${addToUrl(slot.insertProductModel?.imageUrl, srcSetParams)} 2x`}
				style={{ height: heightPixels, width: widthPixels }}
			/>
		</div>
	);
});
