import {
	computed, observable, action, makeObservable,
} from 'mobx';

export class MagicDeliveryModel {
	api;

	contentAreaKey = undefined;

	hasInitialized = false;

	initialErrorView;

	initialTransZoneTimeout = false;

	initialView;

	isInvalidTransZone = false;

	isUps;

	shippingMethod = '';

	summaryModel;

	zipCode = undefined;

	constructor() {
		makeObservable(this, {
			contentAreaKey: observable,
			hasInitialized: observable,
			initialTransZoneTimeout: observable,
			isInvalidTransZone: observable,
			shippingMethod: observable,
			zipCode: observable,
			controlReactPropsOverride: computed,
			deliveryMessages: computed,
			deliveryRateMessages: computed,
			hasCatchAllErrorDeliveryMessage: computed,
			hasCatchAllErrorDeliveryRateMessage: computed,
			hasTransZone: computed,
			hasTransZoneError: computed,
			shouldCheckTransZone: computed,
			updateTransZoneData: action.bound,
		});
	}

	get controlReactPropsOverride() {
		if (!this.isInvalidTransZone) {
			return {};
		}
		return {
			'aria-describedby': 'invalid-tzone-message',
			'aria-invalid': true,
		};
	}

	get deliveryMessages() {
		return this.summaryModel.deliveryMessages;
	}

	get deliveryRateMessages() {
		return this.summaryModel.deliveryRateMessages;
	}

	get hasCatchAllErrorDeliveryMessage() {
		const errorDelMsg = this.deliveryMessages?.find?.(deliveryMessage => deliveryMessage.messageKey === 'UNIVERSAL_1080');

		return Boolean(errorDelMsg);
	}

	get hasCatchAllErrorDeliveryRateMessage() {
		const errorDelRateMsg = this.deliveryRateMessages.find((deliveryRateMessage) => {
			return deliveryRateMessage.messageKey === 'UNIVERSAL_1080';
		});

		return Boolean(errorDelRateMsg);
	}

	get hasTransZone() {
		return Boolean(this.contentAreaKey && this.zipCode);
	}

	get hasTransZoneError() {
		return this.hasCatchAllErrorDeliveryMessage || this.isInvalidTransZone;
	}

	get shouldCheckTransZone() {
		return !this.isUps;
	}

	updateTransZoneData(data) {
		this.contentAreaKey = data?.contentAreaKey;
		this.hasInitialized = true;
		this.zipCode = data?.zipCode;
	}
}

export const MagicDeliveryModelFactory = {
	create({
		initialErrorView = '',
		initialTransZoneTimeout = false,
		initialView = '',
		isUps = false,
		shippingMethod = '',
		summaryModel = {},
		trLinkEventCompName = null,
		trLinkEventCompType = null,
	} = {}) {
		const magicDeliveryModel = new MagicDeliveryModel();

		Object.assign(magicDeliveryModel, {
			initialErrorView,
			initialTransZoneTimeout,
			initialView,
			isUps,
			shippingMethod,
			summaryModel,
			trLinkEventCompName,
			trLinkEventCompType,
		});

		return magicDeliveryModel;
	},
};
