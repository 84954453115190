import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceModalFrameAttributesCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameAttributesCasegoods';
import { WorkspaceModalFrameHeightCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameHeightCasegoods';
import { WorkspaceModalFrameWidthCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameWidthCasegoods';
import { WorkspaceProductFrameImageCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-frame/WorkspaceProductFrameImageCasegoods';
import { noop } from '~/util/noop';

import styles from '#/product/casegoods/workspace/modal-frame/workspace-modal-frame.module.scss';

export const WorkspaceModalFrameCasegoods = observer((props: {view?: React.ReactNode, removeAllWorkspaceProducts?: boolean, continueOnClick: () => any}) => {
	const { view, removeAllWorkspaceProducts = false, continueOnClick = noop } = props;

	const { magicModal } = useGlobalContext();

	const {
		selectorConfigModel: {
			settings: {
				showModalFrameDimensions = false,
				useProductsEndpoint = false,
			} = {},
		} = {},
		selectorConfigStore = {},
		workspaceModalFrameModel: {
			depthSelectorValue = {},
			depthSelector = {},
			doorSelectorValue = {},
			insertProductModel: {
				imageUrl: insertImageUrl = '',
			} = {},
			widthSelectorValue = {},
			workspaceProductModalFrameModel = {},
			workspaceProductModalFrameModel: {
				frameProductModel = {},
				frameProductModel: {
					articleNumber: frameProductArticleNumber = '',
					height = 0,
					width = 0,
				} = {},
				frameProductModels = [],
				workspaceProductImageData = null,
				workspaceProductIndex = 0,
			} = {},
		} = {},
		workspaceModalFrameStore = {},
		workspaceModel = {},
		workspaceStore = {},
	} = useProductContext();

	const {
		imageScale: {
			modal: imageScaleModal = 0,
		} = {},
	} = workspaceModel;

	function handleContinueClick() {
		if (removeAllWorkspaceProducts) {
			workspaceStore.removeAllWorkspaceProducts();
		}
		if (useProductsEndpoint) {
			selectorConfigStore.selectSelectorValue(depthSelectorValue);

			workspaceModalFrameStore.addFrameToWorkspace({
				continueOnClick,
				dropProductArticleNumber: frameProductArticleNumber,
				productModels: frameProductModels,
			});
		} else {
			workspaceStore.addOrUpdateWorkspaceProduct({
				depthSelector,
				doorSelectorValue,
				frameProductModel,
				frameProductModels,
				widthSelectorValue,
				workspaceProductIndex,
				workspaceProductImageData,
			});
		}
		magicModal.closeModal();
	}

	function handleCancelClick() {
		magicModal.closeModal();
	}

	return (
		<div className={styles['workspace-modal-frame']}>
			<div
				className={
					classNames(styles['workspace-modal-frame-product'], {
						[styles['workspace-modal-frame-product-dimensions']]: showModalFrameDimensions,
					})
				}
			>
				{
					showModalFrameDimensions && (
						<WorkspaceModalFrameHeightCasegoods height={height} />
					)
				}
				<div className={styles['workspace-modal-frame-product-image']}>
					{
						showModalFrameDimensions && (
							<WorkspaceModalFrameWidthCasegoods width={width} />
						)
					}
					{view
						? <>{view}</>
						: <WorkspaceProductFrameImageCasegoods
							imageScale={imageScaleModal}
							overrideImageUrl={insertImageUrl}
							workspaceProduct={workspaceProductModalFrameModel}
						/>}
				</div>
			</div>
			<WorkspaceModalFrameAttributesCasegoods />
			<button
				className={`${styles['workspace-modal-frame-control']} Button`}
				data-qa="frame-modal-continue"
				onClick={handleContinueClick}
			>
				Continue
			</button>
			<button
				className={`${styles['workspace-modal-frame-control']} ButtonAnchor`}
				data-qa="frame-modal-cancel"
				onClick={handleCancelClick}
			>
				Cancel
			</button>
		</div>
	);
});
