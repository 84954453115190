import React from 'react';
import { observer } from 'mobx-react';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

export const CuralateUploadControls = observer((props) => {
	const {
		store: {
			hasError,
			isImagesComplete,
			isSubmitted,
			resetUploadCuralate,
			selectedFiles,
			setIsImagesComplete,
		} = {},
		magicModal: {
			closeModal
		} = {},
	} = props;

	const backClickHandler = () => {
		setIsImagesComplete(false);
	};

	const nextClickHandler = () => {
		setIsImagesComplete(true);
	};

	let controls;

	const buttonClass = 'Button';

	const tryAgainControls = [
		<button className={'ButtonAnchor'} onClick={closeModal} key={'curalate-upload-controls-cancel'}>Close</button>,
		<button className={buttonClass} key={'curalate-upload-controls-reset'} onClick={resetUploadCuralate}>Try Again</button>,
	];

	const uploadFilesControls = [
		<button className={'ButtonAnchor curalate-cancel-upload'} onClick={closeModal} key={'curalate-upload-controls-cancel'} type={'button'} data-tr-link-event-track={false}>Cancel</button>,
		<button className={buttonClass} disabled={!selectedFiles.length} key={'curalate-upload-controls-next'} onClick={nextClickHandler} type={'button'}>Next</button>,
	];

	const uploadInfoControls = [
		<button className={'ButtonAnchor'} key={'curalate-upload-controls-back'} onClick={backClickHandler} type={'button'}>Back</button>,
		<input className={buttonClass} disabled={!selectedFiles.length} key={'curalate-upload-controls-submit'} type={'submit'} value={'Submit'} />,
	];

	const uploadMoreFilesControls = [
		<button className={buttonClass} key={'curalate-upload-controls-reset'} onClick={resetUploadCuralate}>Upload More Content</button>,
	];

	if (hasError) {
		controls = tryAgainControls;
	} else if (isSubmitted) {
		controls = uploadMoreFilesControls;
	} else if (isImagesComplete) {
		controls = uploadInfoControls;
	} else {
		controls = uploadFilesControls;
	}

	return (
		<div className={styles['curalate-upload-controls']}>
			{controls}
		</div>
	);
});
