import React from 'react';

import { WorkspaceProductsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductsCasegoods';

export const MediaWorkspaceCasegoods = ({
	imageHeight = 0,
	isMediaThumbnail = false,
	mediaModel: {
		workspaceModel: {
			imageSettings = {},
		} = {},
	} = {},
}) => {
	const {
		fullScreen: imageSettingsFullScreen = {},
		medium: imageSettingsMedium = {},
		thumbnail: imageSettingsThumbnail = {},
	} = imageSettings;

	let imageSettingsToUse = isMediaThumbnail ? imageSettingsThumbnail : imageSettingsMedium;

	if (imageHeight > 600) {
		imageSettingsToUse = imageSettingsFullScreen;
	}

	return (
		<WorkspaceProductsCasegoods
			imageSettings={imageSettingsToUse}
			isDraggable={false}
			showControls={false}
			showDimensions={false}
			showOverallWidthDimension={false}
		/>
	);
};
