import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import React from 'react';

import { SwatchViewerModalWrapper } from '~/components/magic-modal/Components/SwatchViewerModal';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { swatchViewerModalSettings } from '~/product/common/swatchViewer/swatchViewer.constants';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';

const SwatchViewerModal = dynamic(
	() => import('~/product/common/swatchViewer/Components/SwatchViewer.modal').then(module => module.SwatchViewerModal),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const SwatchViewerProvider = dynamic(
	() => import('~/product/common/swatchViewer/Contexts/SwatchViewer.context').then(module => module.SwatchViewerProvider),
	{ loading: () => <LoadingSpinner isLoading /> },
);

export const DetailLinkSwatchOrder = observer((props) => {
	const {
		linkModel: {
			displayText = '',
			url = '',
		} = {},
		orderContentType = '',
		className = 'ButtonAnchor',
	} = props;

	const {
		magicModal,
	} = useGlobalContext();

	const modalSettings = swatchViewerModalSettings.ORDER;

	function handleClick(event) {
		magicModal.openModal({
			WrapperComponent: SwatchViewerModalWrapper,
			...modalSettings,
			content: {
				children: (
					<SwatchViewerProvider apiUrl={`${url.startsWith('/') ? '' : '/'}${url}`} renderMode="ORDER" orderContentType={orderContentType}>
						<SwatchViewerModal />
					</SwatchViewerProvider>
				),
			},
		}, event);
	}

	return (
		<button
			className={`${className} detail-link detail-link-swatch-order tw-hidden-for-print`}
			onClick={handleClick}
		>
			{displayText}
		</button>
	);
});
