import { computed, makeObservable, observable } from 'mobx';

import type { ISelectorConfigSectionals } from '~/product/sectionals/selector/Interfaces/SelectorConfigSectionals.interface';
import type { IWorkspaceSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceSectionals.interface';
import type { IArticleNumbers, ISummarySectionals } from '~/product/sectionals/summary/Interfaces/SummarySectionals.interface';

import { getProductAvailabilityMessage } from '~/product/common/summary/summary.utils';
import { formatCurrency } from '~/util/formatCurrency';
import { BreadcrumbModel } from '~/layout/Models/Breadcrumb.model';
import { ISourceBreadcrumbs } from '~/components/product-group/Types/ProductGroup.type';

export class SummarySectionals implements ISummarySectionals {
	addToCartAction: string;

	analytics: any;

	availability: any;

	breadcrumbModels: Array<BreadcrumbModel>;

	deliveryMessages: Array<any>;

	deliveryRateMessages: Array<any>;

	detailArticleNumbers: Array<IArticleNumbers>;

	hasError: boolean;

	isAddToCartDone: boolean;

	isAddToCartInProcess: boolean;

	isLoading: boolean;

	isMaxQuantity: boolean;

	ottomanDetailArticleNumber: IArticleNumbers;

	pricing: number;

	productGroupModel: any;

	reflektionSkuKeys: Array<string>;

	selectorConfigModel: ISelectorConfigSectionals;

	shouldButtonAnimate: boolean;

	variantIds: Array<string>;

	workspaceModel: IWorkspaceSectionals;

	constructor({
		addToCartAction = '',
		analytics = [],
		availability = {},
		breadcrumbModels = [],
		deliveryMessages = [],
		deliveryRateMessages = [],
		detailArticleNumbers = [],
		hasError = false,
		isAddToCartDone = false,
		isAddToCartInProcess = false,
		isLoading = false,
		isMaxQuantity = false,
		ottomanDetailArticleNumber = {},
		pricing = 0,
		productGroupModel = {},
		reflektionSkuKeys = [],
		selectorConfigModel = {},
		shouldButtonAnimate = false,
		variantIds = [],
		workspaceModel = {},
	}: any = {}) {
		makeObservable(this, {
			addToCartAction: observable,
			analytics: observable.ref,
			availability: observable,
			deliveryMessages: observable,
			deliveryRateMessages: observable,
			detailArticleNumbers: observable.ref,
			hasError: observable,
			isAddToCartDone: observable,
			isAddToCartInProcess: observable,
			isLoading: observable,
			isMaxQuantity: observable,
			ottomanDetailArticleNumber: observable,
			pricing: observable,
			reflektionSkuKeys: observable,
			shouldButtonAnimate: observable,
			variantIds: observable,
			addToCartHref: computed,
			addToFavoritesPayload: computed,
			availabilityMessage: computed,
			finalPrice: computed,
			isValidQuantity: computed,
			isValidSummary: computed,
			priceText: computed,
			summaryHref: computed,
			summaryParams: computed,
		});

		this.addToCartAction = addToCartAction;
		this.analytics = analytics;
		this.availability = availability;
		this.breadcrumbModels = breadcrumbModels;
		this.deliveryMessages = deliveryMessages;
		this.deliveryRateMessages = deliveryRateMessages;
		this.detailArticleNumbers = detailArticleNumbers;
		this.hasError = hasError;
		this.isAddToCartDone = isAddToCartDone;
		this.isAddToCartInProcess = isAddToCartInProcess;
		this.isLoading = isLoading;
		this.isMaxQuantity = isMaxQuantity;
		this.ottomanDetailArticleNumber = ottomanDetailArticleNumber;
		this.pricing = pricing;
		this.productGroupModel = productGroupModel;
		this.reflektionSkuKeys = reflektionSkuKeys;
		this.selectorConfigModel = selectorConfigModel;
		this.shouldButtonAnimate = shouldButtonAnimate;
		this.variantIds = variantIds;
		this.workspaceModel = workspaceModel;
	}

	get addToCartHref(): string {
		const {
			_links: {
				addToCart: {
					href = '',
				} = {},
			} = {},
		} = this.productGroupModel;

		return href;
	}

	get addToWishlistHref(): string {
		const {
			_links: {
				addToWishlist: {
					href = '',
				} = {},
			} = {},
		} = this.productGroupModel;

		return href;
	}

	get addToFavoritesHref(): string {
		const {
			_links: {
				addToFavorites: {
					href = '',
				} = {},
			} = {},
		} = this.productGroupModel;

		return href;
	}

	get addToFavoritesPayload(): any {
		const {
			productGroupModel: {
				canonicalUrl: friendlyPath = '',
				key = '',
				title,
			} = {},
			workspaceModel: {
				workspaceData: workspace = {},
			} = {},
		} = this;

		const { selectedQuestionSelectorValues = [] } = this.selectorConfigModel;

		const { sortedWorkspaceProductModels = [] } = this.workspaceModel;

		const articleNumbers = sortedWorkspaceProductModels.map(({
			productModel: {
				articleNumber = '',
			} = {},
		}) => articleNumber);

		const { selectedUnattachedOttomanSelectorValue = null } = this.selectorConfigModel;

		const configuration = selectedQuestionSelectorValues.flatMap(({ questionAnswers = [] }) => {
			return questionAnswers.map(({
				answer = '',
				question = '',
			}) => {
				return `${question}:${answer}`;
			});
		});

		return {
			addToFavoritesPayload: {
				articleNumbers,
				configuration,
				friendlyPath,
				...(selectedUnattachedOttomanSelectorValue?.articleNumber && { ottomanArticle: selectedUnattachedOttomanSelectorValue.articleNumber }),
				productGroupKey: key,
				workspace,
				salesText: title,
				sourceBreadcrumbs: this.sourceBreadcrumbs,
			},
		};
	}

	get availabilityMessage() {
		return this.isValidSummary ? getProductAvailabilityMessage(this.availability) : '';
	}

	get favoritesModalProductDto(): any {
		const {
			productGroupModel: {
				title,
			} = {},
			finalPrice,
		} = this;

		return {
			imageUrl: '',
			salesText: title,
			quantity: 1,
			price: finalPrice,
		};
	}

	get finalPrice(): number {
		const { hasWorkspaceProductModels = false } = this.workspaceModel;

		if (hasWorkspaceProductModels) {
			return this.pricing;
		}

		return 0;
	}

	get isValidQuantity(): boolean {
		const { hasWorkspaceProductModels = false } = this.workspaceModel;

		return hasWorkspaceProductModels;
	}

	get isValidSummary(): boolean {
		const { questionSelectorModels = [] } = this.selectorConfigModel;

		const { hasWorkspaceProductModels = false } = this.workspaceModel;

		const isValidConfiguration = questionSelectorModels.every(({ isValidSelectedSelectorValue = false }) => isValidSelectedSelectorValue);

		return hasWorkspaceProductModels && isValidConfiguration;
	}

	get priceText() {
		if (this.hasError) {
			return 'Call for pricing';
		}

		return formatCurrency(this.finalPrice, true);
	}

	get summaryHref(): string {
		const {
			_links: {
				summary: {
					href = '',
				} = {},
			} = {},
		} = this.productGroupModel;

		return href;
	}

	get sourceBreadcrumbs(): ISourceBreadcrumbs {
		const sourceCrumbs: ISourceBreadcrumbs = {};

		this.breadcrumbModels.forEach((crumb: BreadcrumbModel) => {
			const { url = '', type = '' } = crumb.data[0];
			if (!url || !type) {
				return;
			}
			const lastIndex = url.lastIndexOf('/');
			const trimmedUrl = url.slice(lastIndex + 1);
			const sourceCrumb = { [type]: trimmedUrl };
			Object.assign(sourceCrumbs, sourceCrumb);
		});
		return sourceCrumbs;
	}

	get summaryParams(): any {
		const {
			canonicalUrl: friendlyPath = '',
			key = '',
		} = this.productGroupModel;

		const { selectedQuestionSelectorValues = [] } = this.selectorConfigModel;

		const { sortedWorkspaceProductModels = [] } = this.workspaceModel;

		const articles = sortedWorkspaceProductModels.map(({
			productModel: {
				articleNumber = '',
			} = {},
		}) => articleNumber);

		const { selectedUnattachedOttomanSelectorValue = null } = this.selectorConfigModel;

		const customConfiguration = selectedQuestionSelectorValues.map(({ questionAnswers = [] }) => {
			return questionAnswers.map(({
				answer = '',
				question = '',
			}) => {
				return `${question}|${answer}`;
			})
				.join('|');
		})
			.join('|');

		return {
			articles,
			...(selectedUnattachedOttomanSelectorValue?.articleNumber && { ottomanArticle: selectedUnattachedOttomanSelectorValue.articleNumber }),
			customConfiguration,
			deliveryMethod: 'INH',
			friendlyPath,
			productGroupKey: key,
		};
	}
}

export const SummarySectionalsModelFactory = ({
	create: (data: any = {}) => {
		return new SummarySectionals(data);
	},
});
