import React from 'react';
import dynamic from 'next/dynamic';

import { LoadingSpinner } from '~/util/Components/LoadingSpinner';

const MaterialNotAvailable = dynamic(() => import('~/product/common/errors/Components/MaterialNotAvailable'),
	{ loading: () => <LoadingSpinner isLoading /> });
const OptionNotAvailable = dynamic(() => import('~/product/common/errors/Components/OptionNotAvailable'),
	{ loading: () => <LoadingSpinner isLoading /> });
const SizeNotAvailable = dynamic(() => import('~/product/common/errors/Components/SizeNotAvailable'),
	{ loading: () => <LoadingSpinner isLoading /> });

export const ErrorModals = {
	CUSTOM_OPTION_NOT_AVAILABLE: (props) => {
		return <MaterialNotAvailable {...props} />;
	},
	CUSTOM_STYLE_OPTION_NOT_AVAILABLE: (props) => {
		return <OptionNotAvailable {...props} />;
	},
	CUSTOM_SIZE_LACKING_ERROR: (props) => {
		return <SizeNotAvailable {...props} />;
	},
};
