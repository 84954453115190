import { SelectorFabric } from '~/product/sectionals/selector/Components/fabric/SelectorFabric';
import { SelectorNone } from '~/product/common/selector/Components/none/SelectorNone';
import { SelectorMediumImage } from '~/product/sectionals/selector/Components/medium-image/SelectorMediumImage';
import { SelectorLayout } from '~/product/sectionals/selector/Components/layout/SelectorLayout';
import { SelectorSwatch } from '~/product/sectionals/selector/Components/swatch/SelectorSwatch';
import { SelectorDownload } from '~/product/sectionals/selector/Components/download/SelectorDownload';
import { SelectorUnattachedOttoman } from '~/product/sectionals/selector/Components/unattached-ottoman/SelectorUnattachedOttoman';

export const selectorSectionalsComponents = {
	CUSTOM_SWATCH: SelectorSwatch,
	FABRIC: SelectorFabric,
	DOWNLOAD: SelectorDownload,
	LAYOUT: SelectorLayout,
	MEDIUM_IMAGE: SelectorMediumImage,
	NONE: SelectorNone,
	SWATCH: SelectorSwatch,
	UNATTACHED_OTTOMAN: SelectorUnattachedOttoman,
};
