import React from 'react';
import { observer } from 'mobx-react';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

const CuralateUploadTab = observer(class CuralateUploadTab extends React.Component {
	constructor(props) {
		super(props);

		this.clickHandler = this.clickHandler.bind(this);
	}

	render() {
		const {
			addUploadTypeRef,
			uploadType: {
				active,
				source,
			},
		} = this.props;

		let tabClasses = styles['curalate-upload-tab'];

		if (active) {
			tabClasses += ` ${styles['curalate-upload-tab-active']}`;
		}

		return (
			<li className={tabClasses} role="presentation">
				<button
					aria-controls={`curalate-upload-tabpanel-${source}`}
					aria-selected={active}
					className={`${styles['curalate-upload-tab-button']} tw-heading-5`}
					id={`curalate-upload-tab-${source}`}
					onClick={this.clickHandler}
					ref={addUploadTypeRef}
					role="tab"
					tabIndex={active ? 0 : -1}
					type="button"
				>
					{source}
				</button>
			</li>
		);
	}

	clickHandler() {
		const {
			setUploadTypeActive,
			uploadType,
		} = this.props;

		setUploadTypeActive(uploadType);
	}
});

export { CuralateUploadTab };
