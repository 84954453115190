import { computed, observable, makeObservable } from 'mobx';

import { s7ImagePath } from '~/global/global.constants';

export class WorkspaceProductCasegoods {
	depthSelector;

	doorSelectorValue;

	frameProductModelOld;

	frameProductModels;

	frameSelectorModel;

	isHidden;

	selectorConfigFrameModalModel;

	widthSelectorValue;

	workspaceProductImageData;

	workspaceProductIndex;

	workspaceProductSlotModels;

	constructor() {
		makeObservable(this, {
			doorSelectorValue: observable,
			frameProductModelOld: observable,
			isHidden: observable,
			widthSelectorValue: observable,
			workspaceProductImageData: observable,
			workspaceProductIndex: observable,
			frameProductModel: computed,
			hasSlotModelsWithInserts: computed,
			hasSlotModelsWithNonDefaultInsertOptionsSelectorValues: computed,
			slotModelsWithInserts: computed,
			slotModelsWithNonDefaultInsertOptionsSelectorValues: computed,
			slotsConfiguration: computed,
			workspaceProductConfiguration: computed,
			workspaceProductDepth: computed,
			workspaceProductDimensions: computed,
			workspaceProductInsertsTotalHeight: computed,
			workspaceProductImageUrl: computed,
			workspaceProductQuestionModels: computed,
			workspaceProductQuestionsConfiguration: computed,
			workspaceProductWidth: computed,
		});
	}

	get frameProductModel() {
		const { selectedProduct } = this.selectorConfigFrameModalModel || {};

		return selectedProduct || this.frameProductModelOld;
	}

	get hasSlotModelsWithInserts() {
		return Boolean(this.slotModelsWithInserts.length);
	}

	get hasSlotModelsWithNonDefaultInsertOptionsSelectorValues() {
		return Boolean(this.slotModelsWithNonDefaultInsertOptionsSelectorValues.length);
	}

	get slotModelsWithInserts() {
		return this.workspaceProductSlotModels.filter(({ insertProductModel }) => insertProductModel);
	}

	get slotModelsWithNonDefaultInsertOptionsSelectorValues() {
		return this.slotModelsWithInserts.filter(({ hasNonDefaultInsertOptionsSelectorValues = false }) => hasNonDefaultInsertOptionsSelectorValues);
	}

	get slotsConfiguration() {
		return this.workspaceProductSlotModels.reduce((accumulatedSlotsConfiguration, { configuration = {} }) => {
			return {
				...accumulatedSlotsConfiguration,
				...configuration,
			};
		}, {});
	}

	get workspaceProductConfiguration() {
		const { configuration = {} } = this.frameProductModel;

		return {
			...configuration,
			...this.slotsConfiguration,
			...this.workspaceProductQuestionsConfiguration,
		};
	}

	get workspaceProductDepth() {
		const { depth = 0 } = this.frameProductModel;

		return depth;
	}

	get workspaceProductDimensions() {
		const { height = 0 } = this.frameProductModel;

		return `${this.workspaceProductWidth}"w ${this.workspaceProductDepth}"d ${height || this.workspaceProductInsertsTotalHeight}"h`;
	}

	get workspaceProductInsertsTotalHeight() {
		const height = this.slotModelsWithInserts.reduce(
			(accumulator, currSlot) => accumulator + (currSlot?.insertProductModel?.height || 0),
			(this.frameProductModel?.baseHeight || 0) + (this.frameProductModel?.topHeight || 0), // initial value
		);

		return height;
	}

	get workspaceProductImageUrl() {
		const {
			articleNumber = '',
			imageUrl: frameProductImageUrl = '',
		} = this.frameProductModel;

		if (!this.workspaceProductImageData) {
			return frameProductImageUrl;
		}

		let imageUrl = this.workspaceProductImageData[articleNumber]?.imageUrl || null;

		// Determine which image data range matches the selected frame width
		if (this.workspaceProductImageData[articleNumber] && !imageUrl) {
			const { imageUrl: defaultImageUrl = `${s7ImagePath}/noimage` } = this.workspaceProductImageData[articleNumber][0];

			const lookedUpImageData = this.workspaceProductImageData[articleNumber].find(({ widthFrom = 0, widthTo = 0 }) => {
				return this.workspaceProductWidth >= widthFrom && this.workspaceProductWidth <= widthTo;
			});

			imageUrl = lookedUpImageData ? lookedUpImageData.imageUrl : defaultImageUrl;
		}

		return imageUrl;
	}

	get workspaceProductQuestionModels() {
		const { questionModels = [] } = this.frameProductModel;

		const workspaceProductQuestionModels = questionModels.map((questionModel = {}) => {
			const { key = '' } = questionModel;

			const workspaceProductAnswerValue = this.workspaceProductQuestionsConfiguration[key];

			if (workspaceProductAnswerValue) {
				return {
					...questionModel,
					selectedAnswer: {
						key: workspaceProductAnswerValue,
					},
				};
			}

			return questionModel;
		});

		return workspaceProductQuestionModels;
	}

	get workspaceProductQuestionsConfiguration() {
		if (this.selectorConfigFrameModalModel) {
			const { selectedAnswerSelectorValues = [] } = this.selectorConfigFrameModalModel;

			return selectedAnswerSelectorValues.reduce((accumulatedQuestionAnswers, { questionAnswers = {} }) => {
				return {
					...accumulatedQuestionAnswers,
					...questionAnswers,
				};
			}, {});
		}

		const { questionAnswers: doorQuestionAnswers = {} } = this.doorSelectorValue;

		const { questionAnswers: widthQuestionAnswers = {} } = this.widthSelectorValue;

		return {
			...doorQuestionAnswers,
			...widthQuestionAnswers,
		};
	}

	get workspaceProductWidth() {
		if (this.selectorConfigFrameModalModel) {
			const { width = 0 } = this.frameProductModel;

			const { selectedAnswerSelectorValues = [] } = this.selectorConfigFrameModalModel;

			const widthSelectorValue = selectedAnswerSelectorValues.find(({ parentType = '' }) => parentType === 'WIDTH');

			const { answer: widthAnswer = '' } = widthSelectorValue || {};

			return parseInt(widthAnswer, 10) || width;
		}

		const { width = 0 } = this.frameProductModel;

		const { answer: widthAnswer = '' } = this.widthSelectorValue;

		return parseInt(widthAnswer, 10) || width;
	}
}

export const WorkspaceProductCasegoodsModelFactory = ({
	create: ({
		depthSelector = {},
		doorSelectorValue = {},
		frameProductModel = {},
		frameProductModels = [],
		frameSelectorModel,
		isHidden = false,
		selectorConfigFrameModalModel,
		widthSelectorValue = {},
		workspaceProductImageData,
		workspaceProductIndex = 0,
		workspaceProductSlotModels = [],
	} = {}) => {
		const workspaceProductCasegoods = new WorkspaceProductCasegoods();

		Object.assign(workspaceProductCasegoods, {
			depthSelector,
			doorSelectorValue,
			frameProductModelOld: frameProductModel,
			frameProductModels,
			frameSelectorModel,
			isHidden,
			selectorConfigFrameModalModel,
			widthSelectorValue,
			workspaceProductImageData,
			workspaceProductIndex,
			workspaceProductSlotModels,
		});

		return workspaceProductCasegoods;
	},
});
