import React, { useRef, useEffect } from 'react';

let Drift: any;

export function useDrift({ driftRef, showDriftZoom = true }: { driftRef: React.RefObject<HTMLDivElement>, showDriftZoom: boolean }) {
	const ref: any = useRef();
	async function importDriftZoom() {
		if (!Drift) Drift = (await import('drift-zoom')).default;
		ref.current = new Drift(driftRef.current, {
			hoverDelay: 600,
			inlinePane: true,
			touchDelay: 600,
			zoomFactor: 2,
		});
	}
	useEffect(() => {
		if (showDriftZoom) {
			importDriftZoom();
		}
		if (!showDriftZoom && ref.current) {
			ref.current.disable();
		}
		if (showDriftZoom && ref.current) {
			ref.current.enable();
		}
		return () => {
			if (ref.current) {
				ref.current.destroy();
			}
		};
	}, [showDriftZoom, driftRef.current]);
}
