import { computed, makeObservable } from 'mobx';

export class Detail {
	behavior;

	componentId;

	detailsGroup;

	displayText;

	group;

	key;

	label;

	linkModel;

	linkType;

	questionKey;

	primaryQuestionKey;

	secondaryQuestionKeys;

	src;

	type;

	url;

	values;

	displayBoldText;

	constructor() {
		makeObservable(this, {
			hasLink: computed,
		});
	}

	get hasLink() {
		const { behavior } = this.linkModel;

		return Boolean(behavior);
	}
}

export const DetailModelFactory = ({
	create: ({
		behavior = '',
		detailsGroup = [],
		displayBoldText = '',
		displayText = '',
		componentId = '',
		elements = [],
		group = '',
		key = '',
		label = '',
		linkModel = {},
		linkType = '',
		questionKey = '',
		primaryQuestionKey = '',
		secondaryQuestionKeys = [],
		src = '',
		type = '',
		url = '',
		values = [],
	}) => {
		const detail = new Detail();

		const dividerCount = parseInt(elements[0]?.dividerCount, 10);
		const subtrahend = parseFloat(elements[0]?.subtrahend, 2);
		const prefix = elements[0]?.prefix;
		const suffix = elements[0]?.suffix;

		Object.assign(detail, {
			behavior,
			componentId,
			detailsGroup,
			displayBoldText,
			displayText,
			dividerCount,
			group,
			elements,
			key,
			label,
			linkModel,
			linkType,
			questionKey,
			prefix,
			primaryQuestionKey,
			secondaryQuestionKeys,
			subtrahend,
			src,
			suffix,
			type,
			url,
			values,
		});

		return detail;
	},
});
