import React from 'react';

import styles from '#/product/casegoods/workspace/modal-frame/workspace-modal-frame-height.module.scss';

export const WorkspaceModalFrameHeightCasegoods = ({ height = 0 }) => {
	return (
		<div className={styles['workspace-modal-frame-height']}>
			<div className={styles['workspace-modal-frame-height-line-container']}>
				<div className={styles['workspace-modal-frame-height-line']} />
			</div>
			<span className={styles['workspace-modal-frame-height-value']}>
				{height}"
			</span>
		</div>
	);
};
