import React from 'react';

import { workspaceEmptyTextMediumCasegoods, workspaceEmptyTextSmallCasegoods, productGroupOverrideWorkspaceEmptyTextSmallCasegoods } from '~/product/casegoods/casegoods-constants';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/workspace-empty.module.scss';

export const WorkspaceEmptyCasegoods = () => {
	const {
		selectorConfigModel: {
			frameSelectorModel: {
				productModels: frameProductModels = [],
			} = {},
		} = {},
		productGroupModel: {
			productPageType = '',
		} = {},
	} = useProductContext();

	const firstFrameProductModel = frameProductModels.length ? frameProductModels[0] : {};

	const { imageCollectionName = '' } = firstFrameProductModel;

	const workspaceEmptyTextMedium = workspaceEmptyTextMediumCasegoods[imageCollectionName] || workspaceEmptyTextMediumCasegoods[productPageType] || workspaceEmptyTextMediumCasegoods.DEFAULT;

	const workspaceEmptyTextSmall = productGroupOverrideWorkspaceEmptyTextSmallCasegoods[imageCollectionName] || workspaceEmptyTextSmallCasegoods[productPageType] || workspaceEmptyTextSmallCasegoods.DEFAULT;

	return (
		<>
			<p className={`${styles['workspace-empty-text']} md:tw-hidden`}>
				{workspaceEmptyTextSmall}
			</p>
			<p className={`${styles['workspace-empty-text']} tw-hidden md:tw-block`}>
				{workspaceEmptyTextMedium}
			</p>
		</>
	);
};
