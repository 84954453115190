import axios from 'axios';
import {
	action,
	makeObservable,
	observe,
	reaction
} from 'mobx';
import dynamic from 'next/dynamic';
import React from 'react';

import { loggedInAction } from '~/account/sign-in/Actions/loggedIn.action';
import { loggedOutAction } from '~/account/sign-in/Actions/loggedOut.action';
import { SignInModalStoreFactory } from '~/account/sign-in/Stores/SignInModal.store';
import {
	apiUrl,
	isOnServer,
	recommendationsEnabled,
} from '~/global/global.constants';
import { InterstitialStoreFactory } from '~/product/common/interstitial/Stores/Interstitial.store';
import { AddedToWishlistStoreFactory } from '~/product/common/interstitial/wishlist/Stores/AddedToWishlist.store';
import { getFavoritesInfoAndAddToFavoritesStore } from '~/product/common/summary/Helpers/AddToFavorites.helpers';
import {
	addProductsToCartErrorKeys,
	addToCartActionKeys,
} from '~/product/common/summary/summary.constants';
import { ReflektionEventsTrackingStore } from '~/tracking/vendor-events/reflektion/Stores/ReflektionEvents.tracking.store';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { isObjectEqual } from '~/util/isObjectEqual';
import { noop } from '~/util/noop';
import { ViewState } from '~/util/viewState/Components/ViewState';

const CartInterstitialMultiOptions = dynamic(
	() => import('~/product/multi-options/interstitial/Components/CartInterstitialMultiOptions').then(module => module.CartInterstitialMultiOptions),
	{ loading: () => <LoadingSpinner isLoading /> }
);

class SummaryMultiOptionsStore {
	HREF;

	observers = [];

	productTrackingStore;

	summaryModel;

	summaryStores;

	globalStaticModel;

	rfkTrackingStore;

	constructor() {
		makeObservable(this, {
			addToCartInit: action.bound,
			addProductToCart: action.bound,
			addProductToWishlist: action.bound,
			assignGlobalDeliveryMessages: action.bound,
			assignInnerDeliveryMessages: action.bound,
			getSummaryData: action.bound,
			handleConfigurationChange: action.bound,
			addToCartErrorHandler: action.bound,
			addToCartSuccessHandler: action.bound,
			openCartInterstitialModal: action.bound,
			openWishlistInterstitialModal: action.bound,
			stopAnimating: action.bound
		});
	}

	addToCartInit(addToCartAction) {
		Object.assign(this.summaryModel, {
			addToCartAction,
			isMaxQuantity: false,
			isAddToCartInProcess: true,
			isAddToCartDone: false,
			shouldButtonAnimate: true,
		});
	}

	addToFavorites(event) {
		const onCloseFocusElement = event ? event.target : null;
		const {
			addProductsToFavoritesRequestDto,
			firstSelectedProduct: {
				addToFavoritesHref,
			} = {},
		} = this.summaryModel;

		const {
			isCustomerGuest,
		} = this.globalDynamicStore.model;

		const promise = getFavoritesInfoAndAddToFavoritesStore(
			addToFavoritesHref,
			addProductsToFavoritesRequestDto,
			null,
			null,
			this.addToCartErrorHandler,
			this.featureTogglesModel,
			this.globalDynamicStore,
			this.magicModal,
			onCloseFocusElement,
		);

		promise.then(() => {
			if (!isCustomerGuest) {
				this.magicModal.closeModal();
			}
		});

		return promise;
	}

	addProductToCart(event) {
		const {
			firstSelectedProduct: { addToCartHref },
		} = this.summaryModel;

		const onCloseFocusElement = event ? event.target : null;

		this.addToCartInit(addToCartActionKeys.CART);

		return axios
			.post(`${apiUrl}${addToCartHref}`, this.summaryModel.addProductsToCartRequestDto)
			.then(({ data }) => {
				Object.assign(this.summaryModel, { addToCartResponse: data });
				this.openCartInterstitialModal(onCloseFocusElement, data);
				this.addToCartSuccessHandler();
				this.productTrackingStore.trackAddToCart(data);
			}, ({
				response: {
					data: { errors = [] },
				}
			}) => {
				this.addToCartErrorHandler(onCloseFocusElement, errors);
			});
	}

	addProductToWishlist(event) {
		const onCloseFocusElement = event ? event.target : null;
		return this.addToFavorites(event)
			.then(() => {
				this.addToCartSuccessHandler();
			}, ({
				response: {
					data: { errors = [] },
				} = {}
			}) => {
				this.addToCartErrorHandler(onCloseFocusElement, errors);
			});
	}

	assignGlobalDeliveryMessages(data) {
		const {
			productSummaries = [],
		} = data;

		const {
			deliveryMessages = [],
			deliveryRateMessages = [],
			vendor: {
				reflektion,
			} = {},
		} = productSummaries[0];

		if (reflektion) {
			reflektion.map(rfk => (
				this.summaryModel.reflektionSkuKeys.push(rfk.skuKey)
			));
		}

		Object.assign(this.summaryModel, {
			deliveryMessages,
			deliveryRateMessages,
			isLoading: false,
		});
	}

	assignInnerDeliveryMessages(data) {
		const {
			priceTotal = 0,
			productSummaries = [],
		} = data;

		Object.assign(this.summaryModel, { pricing: priceTotal });

		if (this.summaryModel.reflektionSkuKeys) {
			this.summaryModel.reflektionSkuKeys.clear();
		}

		this.assignGlobalDeliveryMessages(data);

		if (this.summaryModel.analytics) {
			this.summaryModel.analytics.clear();
		}

		productSummaries.forEach(({
			availability = {},
			analytics,
			analytics: [{
				articleNumber = '',
			}] = [{}],
			deliveryMessages = [],
			deliveryRateMessages = [],
			vendor: {
				reflektion,
				variantIds = [],
			} = {},
		}) => {
			const matchingSummaryStore = this.summaryStores.find(({
				summaryModel: {
					selectorConfigModel: {
						selectedProduct: {
							articleNumber: selectedProductArticleNumber = '',
						}
					}
				} = {},
			}) => {
				return articleNumber === selectedProductArticleNumber;
			});

			if (matchingSummaryStore) {
				const { summaryModel = {} } = matchingSummaryStore;

				if (reflektion && summaryModel.quantity) {
					reflektion.map(rfk => (
						this.summaryModel.reflektionSkuKeys.push(rfk.skuKey)
					));
				}

				// add the reflektion skuKeys to the analytics array
				analytics.forEach((article, index) => {
					article.reflektionSkuKey = reflektion[index].skuKey;
					article.quantity = summaryModel.quantity;
				});

				Object.assign(summaryModel, {
					analytics,
					availability,
					deliveryMessages,
					deliveryRateMessages,
					variantIds,
				});

				if (this.summaryModel.analytics) {
					this.summaryModel.analytics.push(...analytics);
				}
			}
		});
	}

	// eslint-disable-next-line default-param-last
	getSummaryData(callTracking = true, changedProductIndex) {
		const {
			firstSelectedProduct: { summaryHref },
			hasInitialized,
			isValidSummary = false,
			shippingMethod = '',
			summaryParams,
			productGroupModel = {},
			productGroupModel: {
				canonicalUrl,
			},
		} = this.summaryModel;

		if (!isOnServer && isValidSummary) {
			const endpoint = `${apiUrl}${summaryHref}`;

			const restConfig = {
				products: summaryParams,
				shippingMethod,
				productGroupKey: productGroupModel.key,
				friendlyPath: canonicalUrl,
			};

			if (hasInitialized) {
				Object.assign(this.summaryModel, { isLoading: true });
			}

			const promise = axios.post(endpoint, restConfig);

			return promise
				.then(({ data = {} }) => {
					const {
						productSummaries = [],
					} = data;

					this.assignInnerDeliveryMessages(data);

					Object.assign(this.summaryModel, {
						hasError: false,
						hasInitialized: true,
					});

					const reflektionSkuKeys = [];

					const variantIds = [];

					productSummaries.forEach((summary) => {
						const {
							vendor: {
								reflektion = [],
								variantIds: variantIdsData = [],
							} = {},
						} = summary;

						reflektion.forEach(({ skuKey }) => {
							reflektionSkuKeys.push(skuKey);
						});

						variantIdsData.forEach((variantId) => {
							variantIds.push(variantId);
						});
					});

					Object.assign(this.summaryModel, {
						reflektionSkuKeys,
						variantIds,
					});

					if (callTracking && Number.isInteger(changedProductIndex)) {
						this.productTrackingStore.trackMultiOptionsProductView(changedProductIndex, 'pass');
					}
				}, () => {
					Object.assign(this.summaryModel, {
						hasError: true,
						isLoading: false,
					});

					if (callTracking && Number.isInteger(changedProductIndex)) {
						this.productTrackingStore.trackMultiOptionsProductView(changedProductIndex, 'fail');
					}
				});
		}

		return Promise.reject().catch(noop);
	}

	handleConfigurationChange() {
		Object.assign(this.summaryModel, {
			availability: undefined,
			detailArticleNumber: undefined,
			isMaxQuantity: false,
		});

		this.getSummaryData();
	}

	addToCartErrorHandler(onCloseFocusElement, errors) {
		Object.assign(this.summaryModel, {
			isAddToCartDone: true,
			isAddToCartInProcess: false,
		});

		this.stopAnimating();

		errors.forEach((err) => { console.error(err); });

		if (errors.find(({ errorKey }) => errorKey === addProductsToCartErrorKeys.maxQuantity)) {
			Object.assign(this.summaryModel, { isMaxQuantity: true });
		}

		if (errors.find(({ errorKey }) => errorKey === addProductsToCartErrorKeys.unauthorizedWishlist)) {
			const signInModalStore = SignInModalStoreFactory
				.create(this.magicModal, this.globalDynamicStore, this.HREF);

			signInModalStore.openModal('signIn', {
				callback: this.addProductToWishlist,
				modalOptions: {
					onCloseFocusElement,
				},
			});
		}
	}

	addToCartSuccessHandler() {
		Object.assign(this.summaryModel, {
			isAddToCartDone: true,
			isAddToCartInProcess: false,
		});
		setTimeout(this.stopAnimating, 3000);
		this.globalDynamicStore.fetchData();
	}

	openCartInterstitialModal(onCloseFocusElement, data) {
		const {
			productGroupModel = {},
			validSummaryModels = [],
		} = this.summaryModel;

		const loadRfkRecos = recommendationsEnabled;

		const store = InterstitialStoreFactory.create(
			data,
			{
				magicModal: this.magicModal,
				featureTogglesModel: this.featureTogglesModel,
				globalDynamicModel: this.globalDynamicStore.model,
				globalStaticModel: this.globalStaticModel,
				pageStore: this.pageStore,
			},
			{
				loadRfkRecos,
			},
		);

		this.magicModal.openModal({
			title: 'Nice choice! Your cart has been updated.',
			content: {
				children: (
					<CartInterstitialMultiOptions
						productGroupModel={productGroupModel}
						store={store}
						summaryModel={this.summaryModel}
						summaryModels={validSummaryModels}
					/>
				),
			},
			width: 'calc(100vw - 20px)',
			maxWidth: '725px',
			useLegacyWrapper: false,
		});
	}

	openWishlistInterstitialModal(onCloseFocusElement, data) {
		const store = AddedToWishlistStoreFactory.create(data, this.magicModal, {
			showQuantity: false,
		});

		this.magicModal.openModal({
			id: 'added-to-wishlist-modal',
			title: 'Added to Wish List!',
			width: 'calc(100vw - 20px)',
			maxWidth: '784px',
			onCloseFocusElement,
			useLegacyWrapper: false,
			content: {
				children: <ViewState viewState={store.viewState} />,
			},
		});
	}

	stopAnimating() {
		Object.assign(this.summaryModel, {
			isAddToCartDone: false,
			shouldButtonAnimate: false,
		});
	}
}

export const SummaryMultiOptionsStoreFactory = {
	create({
		featureTogglesModel,
		globalDynamicStore,
		globalStaticModel,
		magicModal,
		pageStore,
		productTrackingStore,
		summaryModel = {},
		summaryStores = [],
		HREF,
		trackingDebugMode = {},
	}) {
		const summaryMultiOptionsStore = new SummaryMultiOptionsStore();

		const rfkTrackingStore = new ReflektionEventsTrackingStore(featureTogglesModel);

		Object.assign(summaryMultiOptionsStore, {
			featureTogglesModel,
			globalDynamicStore,
			globalStaticModel,
			magicModal,
			pageStore,
			productTrackingStore,
			summaryModel,
			summaryStores,
			HREF,
			rfkTrackingStore,
			trackingDebugMode,
		});

		if (!isOnServer) {
			summaryMultiOptionsStore.observers.push(
				reaction(
					() => summaryModel.summaryParams,
					(newParams, oldParams) => {
						// Figure out if it was the quantity that changed
						const quantityDiff = oldParams.filter(({ quantity: oldQuantity }, index) => {
							const newQuantity = newParams[index];

							return oldQuantity !== newQuantity;
						});

						// Figured out which product actually changed
						const changedIndex = oldParams.findIndex((params, index) => {
							const newParamsObj = newParams[index] || {};

							return !isObjectEqual(params, newParamsObj);
						});

						summaryMultiOptionsStore.getSummaryData(quantityDiff.length === 0, changedIndex);
					}
				),

				// Refresh summary if an Engage user creates an online account
				// (effectively changing who they're working with).
				observe(globalDynamicStore.model, (change) => {
					if (change.name !== 'workingWith') {
						return;
					}
					if (!change.oldValue.name && change.newValue.name) {
						return;
					}
					if (!change.oldValue.name && !change.newValue.name) {
						return;
					}
					summaryMultiOptionsStore.getSummaryData();
				}),
			);
			summaryMultiOptionsStore.getSummaryData(true);
			// Refresh summary upon logging in or out.
			loggedInAction.add(() => {
				summaryMultiOptionsStore.getSummaryData();
			});
			loggedOutAction.add(() => {
				summaryMultiOptionsStore.getSummaryData();
			});
		}

		return summaryMultiOptionsStore;
	},
	destroy(store) {
		store.observers?.forEach?.(dispose => dispose());
	},
};
