import axios from 'axios';
import { action, makeObservable, observable } from 'mobx';

class StyliticsStore {
	styliticsModel;

	styliticsEventsTrackingStore;

	summaryModel = {};

	maxOutfits = 3;

	constructor(summaryModel) {
		makeObservable(this, {
			detectOutfits: action,
			setStyliticsWidgetDidClick: action,
			setStyliticsWidgetDidLoad: action,
			setStyliticsWidgetDidView: action,
			setStyliticsWidgetIsHidden: action,
			setStyliticsWidgetInstance: action,
			trackStyliticsView: action,
			trackStyliticsPresent: action,
			summaryModel: observable,
		});
		this.summaryModel = summaryModel;
	}

	detectOutfits() {
		const { styliticsSku = '' } = this.styliticsModel;

		const {
			analytics: [{
				articleNumber = '',
			}] = [{}],
			selectorConfigModel: {
				selectedMaterialSelectorValue: {
					title = null,
				} = {},
			} = {},
		} = this.summaryModel;

		const url = 'https://content.stylitics.com/api/outfits';

		const params = {
			item_number: styliticsSku,
			item_number_type: 'sku',
			max: this.maxOutfits,
			return_object: true,
			username: 'roomandboard',
			with_item_coords: true,
		};

		axios.get(url, {
			params,
			withCredentials: false,
		})
			.then(({
				data: {
					outfits = [],
				} = {},
			}) => {
				if (outfits.length) {
					this.setStyliticsWidgetDidLoad(true);

					this.setStyliticsWidgetIsHidden(false);

					this.trackStyliticsPresent({
						articleNumber,
						count: outfits.length,
						material: title,
						styliticsSku,
					});
				} else {
					this.trackStyliticsPresent({
						articleNumber,
						count: 0,
						material: title,
						styliticsSku,
					});

					this.setStyliticsWidgetIsHidden(true);
				}
			})
			.catch((error) => {
				this.setStyliticsWidgetIsHidden(true);

				console.info(error);
			});
	}

	setStyliticsWidgetDidClick(styliticsWidgetDidClick) {
		this.styliticsModel.styliticsWidgetDidClick = styliticsWidgetDidClick;
	}

	setStyliticsWidgetDidLoad(styliticsWidgetDidLoad) {
		this.styliticsModel.styliticsWidgetDidLoad = styliticsWidgetDidLoad;
	}

	setStyliticsWidgetDidView(styliticsWidgetDidView) {
		this.styliticsModel.styliticsWidgetDidView = styliticsWidgetDidView;
	}

	setStyliticsWidgetIsHidden(styliticsWidgetIsHidden) {
		this.styliticsModel.styliticsWidgetIsHidden = styliticsWidgetIsHidden;
	}

	setStyliticsWidgetInstance(styliticsWidgetInstance) {
		this.styliticsModel.styliticsWidgetInstance = styliticsWidgetInstance;
	}

	trackStyliticsPresent({
		styliticsSku = null, articleNumber = null, material = null, count = 0,
	}) {
		const outfitCount = count > this.maxOutfits ? this.maxOutfits : count;

		this.styliticsEventsTrackingStore.trackStyliticsPresent({
			styliticsSku,
			styleKey: articleNumber,
			material,
			count: outfitCount,
		});
	}

	trackStyliticsView({
		articleNumber = '', id = '', items = [], material = null, size = 0,
	}) {
		items.splice(size);
		this.styliticsEventsTrackingStore.trackStyliticsView({
			id, items, material, styleKey: articleNumber,
		});
	}

	trackStyliticsClick({
		articleNumber = '', id = '', items = [], material = null, size = 0,
	}) {
		items.splice(size);
		this.styliticsEventsTrackingStore.trackStyliticsClick({
			id, items, material, styleKey: articleNumber,
		});
	}
}

export const StyliticsStoreFactory = {
	create({
		styliticsModel = {},
		styliticsEventsTrackingStore = {},
		summaryModel = {},
	}) {
		const styliticsStore = new StyliticsStore(summaryModel);

		Object.assign(styliticsStore, {
			styliticsModel,
			styliticsEventsTrackingStore,
			summaryModel,
		});

		return styliticsStore;
	},
};
