import React from 'react';
import { observer } from 'mobx-react';

import type { CuralateStore } from '~/util/curalate/Stores/Curalate.store';
import { CuralateDetailItem } from '~/util/curalate/Components/CuralateDetailItem';
import { isEngage } from '~/global/global.constants';
import { ArrowButtonDirection, ArrowButtonSize, ButtonVariant } from '~/components/Buttons/Types/constants';
import { Button } from '~/components/Buttons/Components/Button';
import styles from '~/util/curalate/Components/curalate-detail.module.scss';

export const CuralateDetail = observer(({ store }: { store: CuralateStore }) => {
	const {
		model: {
			hasNextItem,
			hasPrevItem,
			selectedItem,
			trLinkEventUseCompNameH1 = null,
			trLinkEventCompName = null,
		},
		handleKeyDown,
		selectNextItem,
		selectPrevItem,
		openCuralateItemReportModal,
		trackCuralateItemProductClick,
	} = store;

	const keyDownHandler = (event: any) => {
		handleKeyDown({
			event,
			nextFunc: selectNextItem,
			prevFunc: selectPrevItem,
		});
	};

	let prevButtonClasses = `${styles['curalate-detail-nav']} ${styles['curalate-detail-nav-prev']}`;

	if (!hasPrevItem) {
		prevButtonClasses += ` ${styles['curalate-detail-nav-disabled']}`;
	}

	let nextButtonClasses = `${styles['curalate-detail-nav']} ${styles['curalate-detail-nav-next']}`;

	if (!hasNextItem) {
		nextButtonClasses += ` ${styles['curalate-detail-nav-disabled']}`;
	}

	return (
		<>
			<p className="tw-sr-only">This section of the page contains a carousel that visually displays various linked product images one at a time. Using previous and next advances to a new slide.</p>
			{/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
			<div
				aria-label="Customer photos media carousel"
				className={styles['curalate-detail-container']}
				onKeyDown={keyDownHandler}
				role="region"
			>
				<div className={prevButtonClasses}>
					<Button variant={ButtonVariant.Arrow}
						data-tr-link-event-name="left navigation"
						data-tr-link-event-track={!isEngage}
						disabled={!hasPrevItem}
						onClick={selectPrevItem}
						direction={ArrowButtonDirection.Left}
						size={ArrowButtonSize.Medium}>
						<span className={'tw-sr-only'}>Previous image</span>
					</Button>
				</div>
				<CuralateDetailItem
					item={selectedItem}
					handleItemProductClick={trackCuralateItemProductClick}
					handleReportItemClick={openCuralateItemReportModal}
					trLinkEventCompName={trLinkEventCompName}
					trLinkEventUseCompNameH1={trLinkEventUseCompNameH1}
				/>
				<div className={nextButtonClasses}>
					<Button variant={ButtonVariant.Arrow}
						data-tr-link-event-name="right navigation"
						data-tr-link-event-track={!isEngage}
						disabled={!hasNextItem}
						onClick={selectNextItem}
						direction={ArrowButtonDirection.Right}
						size={ArrowButtonSize.Medium}>
						<span className={'tw-sr-only'}>Next image</span>
					</Button>
				</div>
			</div>
		</>
	);
});
