import { computed, observable, makeObservable } from 'mobx';

class SelectorMaterial {
	isVisible

	label

	renderer

	selected

	selectorValues

	type

	showSelectorDetails

	wasActive

	constructor() {
		makeObservable(this, {
			isVisible: observable,
			selected: observable,
			wasActive: observable,
			selectedSelectorValue: computed,
			selectorValuesCount: computed,
			selectorValuesToRender: computed,
			spoSelectorValues: computed,
			stockedSelectorValues: computed,
		});
	}

	get selectedSelectorValue() {
		return this.selectorValues.find(({ selected }) => selected);
	}

	get selectorValuesCount() {
		return this.selectorValuesToRender.length;
	}

	get selectorValuesToRender() {
		return this.selectorValues.filter(({ available = false }) => available);
	}

	get spoSelectorValues() {
		return this.selectorValuesToRender.filter(({ stocked }) => !stocked);
	}

	get stockedSelectorValues() {
		return this.selectorValuesToRender.filter(({ stocked }) => stocked);
	}
}

export const SelectorMaterialModelFactory = ({
	create: ({
		isVisible = true,
		label = '',
		renderer = '',
		selected = false,
		selectorValues = [],
		type = '',
		wasActive = false,
		showSelectorDetails,
	}) => {
		const selectorMaterial = new SelectorMaterial();

		Object.assign(selectorMaterial, {
			isVisible,
			label,
			renderer,
			selected,
			selectorValues,
			type,
			wasActive,
			showSelectorDetails,
		});

		return selectorMaterial;
	},
});
