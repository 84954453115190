const getCustomFiltersData = (selectorValues = []) => {
	const customFiltersData = {};

	// roll up the custom filters data
	selectorValues.forEach(({ selectors = [] }) => {
		selectors.forEach(({ filter = {} }) => {
			Object.entries(filter).forEach(([filterType, facets]) => {
				const matchedFilterFacets = customFiltersData[filterType];

				if (matchedFilterFacets) {
					facets.forEach((facet) => {
						const {
							count = 0,
							title = '',
						} = facet;

						const matchingFacet = matchedFilterFacets
							.find(matchedFilterFacet => matchedFilterFacet.title === title);

						if (matchingFacet) {
							matchingFacet.count += count;
						} else {
							matchedFilterFacets.push(facet);
						}
					});
				} else {
					customFiltersData[filterType] = facets;
				}
			});
		});
	});

	return customFiltersData;
};

const getCustomFiltersSelectorValues = (selectorValues = [], hasCustomFilters) => {
	if (!hasCustomFilters) {
		return [];
	}

	return selectorValues.reduce((accumulatedSelectorValues, { selectors = [] }) => {
		const selectorValuesToAdd = selectors.reduce((accumulatedDescendantSelectorValues, {
			filter = {},
			selectorValues: descendantSelectorValues = [],
		}) => {
			if (Object.keys(filter).length) {
				return [...accumulatedDescendantSelectorValues, ...descendantSelectorValues];
			}

			return accumulatedDescendantSelectorValues;
		}, []);

		return [...accumulatedSelectorValues, ...selectorValuesToAdd];
	}, []);
};

const getStockedFiltersData = (selectorValues = []) => {
	let stockedFiltersData = {};

	// stocked filters are pre-combined and all the same
	selectorValues.forEach(({ selectors = [] }) => {
		selectors.forEach(({ filter }) => {
			if (filter) {
				stockedFiltersData = { ...filter };
			}
		});
	});

	return stockedFiltersData;
};

const getStockedFiltersSelectorValues = (selectorValues = [], hasStockedFilters = false) => {
	if (!hasStockedFilters) {
		return [];
	}

	return selectorValues.reduce((accumulatedSelectorValues, { selectors = [] }) => {
		const selectorValuesToAdd = selectors.reduce((accumulatedDescendantSelectorValues, { selectorValues: descendantSelectorValues = [] }) => {
			return [...accumulatedDescendantSelectorValues, ...descendantSelectorValues];
		}, []);

		return [...accumulatedSelectorValues, ...selectorValuesToAdd];
	}, []);
};

const setFilters = (selectorValues = [], filtersMagicTabccordionStore = {}, filtersModel = {}, filtersStore = {}) => {
	selectorValues.forEach(({ selectors = [] }) => {
		selectors.forEach((selector) => {
			const { hasFilterData = false } = selector;

			if (hasFilterData) {
				Object.assign(selector, {
					filtersMagicTabccordionStore,
					filtersModel,
					filtersStore,
				});
			}
		});
	});
};

export {
	getCustomFiltersData,
	getCustomFiltersSelectorValues,
	getStockedFiltersData,
	getStockedFiltersSelectorValues,
	setFilters,
};
