export const officeChairsFeaturesData = {
	OFFICE_CHR_FEATURES_CHORAL: {
		img: 'OFFICE_CHR_FEATURES_CHORAL',
		imgWidth: 284,
		imgHeight: 335,
		list: [
			'Optional adjustable headrest',
			'Recline action: easily adjustable tension, lockable positions',
			'Lumbar support: form-sensing mesh for custom support',
			'Armrest adjustability: height, width, depth, pivot',
			'Seat adjustability: height, depth',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_CORA: {
		img: 'OFFICE_CHR_FEATURES_CORA',
		imgWidth: 200,
		imgHeight: 282,
		list: [
			'Lumbar support: firm foam and fiber cushioning',
			'Seat adjustability: height',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_CYNARA: {
		img: 'OFFICE_CHR_FEATURES_CYNARA',
		imgWidth: 200,
		imgHeight: 296,
		list: [
			'Recline action: easily adjustable tension, lockable positions',
			'Lumbar support: form-sensing mesh for custom support',
			'Seat adjustability: height, tilt',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_DIFFRIENT_WORLD: {
		img: 'OFFICE_CHR_FEATURES_DIFFRIENT_WORLD',
		imgWidth: 200,
		imgHeight: 295,
		list: [
			'Recline action: self-adjusting tension',
			'Lumbar support: form-sensing mesh for custom support, pivoting backrest',
			'Armrest adjustability: adjustable or fixed height; move in tandem with backrest for dynamic support',
			'Seat adjustability: height, depth',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_FINORA: {
		img: 'OFFICE_CHR_FEATURES_FINORA',
		imgWidth: 200,
		imgHeight: 335,
		list: [
			'Recline action: easily adjustable tension, lockable positions',
			'Lumbar support: form-sensing mesh for custom support',
			'Armrest adjustability: height, width, depth, pivot',
			'Seat adjustability: height, depth',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_FREEDOM: {
		img: 'OFFICE_CHR_FEATURES_FREEDOM',
		imgWidth: 200,
		imgHeight: 386,
		list: [
			'Adjustable headrest',
			'Recline action: self-adjusting tension, lockable positions',
			'Lumbar support: contoured foam cushioning, pivoting backrest',
			'Armrest adjustability: height moves in tandem to eliminate uneven arm positioning',
			'Seat adjustability: height, depth',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_LIBERTY_OCEAN: {
		img: 'OFFICE_CHR_FEATURES_LIBERTY_OCEAN',
		imgWidth: 200,
		imgHeight: 375,
		list: [
			'Recline action: self-adjusting tension',
			'Lumbar support: contoured foam cushioning, pivoting backrest',
			'Armrest adjustability: height, depth, pivot',
			'Seat adjustability: height, depth',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_LIRA: {
		img: 'OFFICE_CHR_FEATURES_LIRA',
		imgWidth: 200,
		imgHeight: 251,
		list: [
			'Lumbar support: firm foam and fiber cushioning',
			'Armrest adjustability: fixed height',
			'Seat adjustability: height',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_LUCE: {
		img: 'OFFICE_CHR_FEATURES_LUCE',
		imgWidth: 231,
		imgHeight: 335,
		list: [
			'Recline action: self-adjusting tension',
			'Lumbar support: high-density mesh for firm support',
			'Armrest adjustability: fixed height',
			'Seat adjustability: height',
			'Seat incline smoothly levels for easy transition between sitting and standing',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_LW: {
		img: 'OFFICE_CHR_FEATURES_LW',
		imgWidth: 200,
		imgHeight: 327,
		list: [
			'Recline action: easily adjustable tension, lockable positions',
			'Armrest adjustability: fixed height',
			'Seat adjustability: height',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_NICO: {
		img: 'OFFICE_CHR_FEATURES_NICO',
		imgWidth: 200,
		imgHeight: 282,
		list: [
			'Lumbar support: firm foam and fiber cushioning',
			'Armrest adjustability: fixed height',
			'Seat adjustability: height',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_PATH: {
		img: 'OFFICE_CHR_FEATURES_PATH',
		imgWidth: 200,
		imgHeight: 335,
		list: [
			'Recline action: easily adjustable tension, lockable positions',
			'Lumbar support: FormSense Eco Knit™ self-adjusting mesh',
			'Armrest adjustability: height, width, depth, pivot',
			'Seat adjustability: height, depth',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_PLIMODE: {
		img: 'OFFICE_CHR_FEATURES_PLIMODE',
		imgWidth: 200,
		imgHeight: 320,
		list: [
			'Recline action: easily adjustable tension, lockable position',
			'Lumbar support: breathable inner mesh structure',
			'Armrest adjustability: fixed height, move in tandem with backrest for dynamic support',
			'Seat adjustability: height',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_RUNA: {
		img: 'OFFICE_CHR_FEATURES_RUNA',
		imgWidth: 200,
		imgHeight: 274,
		list: [
			'Lumbar support: flexible contoured back',
			'Armrest adjustability: fixed height',
			'Seat adjustability: height',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_SYLPHY: {
		img: 'OFFICE_CHR_FEATURES_SYLPHY',
		imgWidth: 200,
		imgHeight: 314,
		list: [
			'Recline action: easily adjustable tension, lockable positions',
			'Lumbar support: form-sensing mesh for custom support, adjustable lumbar curve',
			'Armrest adjustability: height, width, depth or fixed height',
			'Seat adjustability: height, depth, forward tilt for lower back relief',
			'Swivels 360 degrees',
		],
	},
	OFFICE_CHR_FEATURES_TENLEY: {
		img: 'OFFICE_CHR_FEATURES_TENLEY',
		imgWidth: 200,
		imgHeight: 273,
		list: [
			'Lumbar support: firm foam and fiber cushioning',
			'Armrest adjustability: fixed height',
			'Seat adjustability: height',
			'Swivels 360 degrees',
		],
	},
};
