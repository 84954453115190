import { createSelectorFiltersMagicTabccordionStore } from '~/product/common/selector/Helpers/SelectorFiltersTabccordion.init';
import { createSelectorInsertFiltersCasegoodsModel } from '~/product/casegoods/selector/Helpers/SelectorInsertFiltersCasegoods.init';
import { createSelectorInsertOptionsModel } from '~/product/casegoods/selector/Helpers/SelectorInsertOptions.init';
import { createSelectorInsertMagicTabccordionStore } from '~/product/casegoods/selector/Helpers/SelectorInsertTabccordion.init';
import { SelectorInsertModelFactory } from '~/product/casegoods/selector/Models/SelectorInsert.model';
import { SelectorValueInsertModelFactory } from '~/product/casegoods/selector/Models/SelectorValueInsert.model';
import { SelectorFiltersStoreFactory } from '~/product/common/selector/Stores/SelectorFilters.store';

const createSelectorValueInsertModel = ({
	linkEventStore,
	parentLabel = '',
	productModels = [],
	selectorValueData = {},
}) => {
	const { articles: articlesData = [], selectors: selectorsData = [] } = selectorValueData;

	const articles = articlesData.map((articleData = {}) => {
		const { customCabinetInsertOptions: insertOptionsSelectorsData = [] } = articleData;

		const insertOptionsSelectorModels = insertOptionsSelectorsData.map((insertOptionsSelectorData) => {
			return createSelectorInsertOptionsModel({ selectorData: insertOptionsSelectorData });
		});

		return {
			...articleData,
			selectors: insertOptionsSelectorModels,
		};
	});

	const matchingProductModels = productModels.filter(({ articleNumber: productModelArticleNumber = '' }) => {
		return articlesData.find(({ article, articleNumber = '' }) => (article || articleNumber) === productModelArticleNumber);
	});

	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	const selectorInsertModels = selectorsData.map(selectorData => createSelectorInsertModel({
		linkEventStore,
		productModels,
		selectorData,
	}));

	const selectorValueInsertModel = SelectorValueInsertModelFactory.create({
		...selectorValueData,
		articles,
		parentLabel,
		productModels: matchingProductModels,
		selectors: selectorInsertModels,
	});

	return selectorValueInsertModel;
};

export const createSelectorInsertModel = ({
	linkEventStore = {},
	insertGroupsData = {},
	productModels = [],
	selectorData = {},
	settings: {
		isValidInsertsSelectorAlways = false,
	} = {},
	wasActive = false,
}) => {
	const {
		filters: filtersData = {},
		label = '',
		values: selectorValuesData = [],
	} = selectorData;

	const selectorValues = selectorValuesData.map((selectorValueData = {}) => {
		return createSelectorValueInsertModel({
			linkEventStore,
			parentLabel: label,
			productModels,
			selectorValueData,
		});
	});

	const filtersModel = createSelectorInsertFiltersCasegoodsModel({
		filtersData,
		selectorValues,
	});

	const filtersStore = SelectorFiltersStoreFactory.create({ filtersModel });

	const filtersMagicTabccordionStore = createSelectorFiltersMagicTabccordionStore(filtersStore, linkEventStore);

	const selectorInsertMagicTabccordionStore = createSelectorInsertMagicTabccordionStore({
		isDraggable: true,
		linkEventStore,
		selectorValues,
	});

	const selectorInsertModel = SelectorInsertModelFactory.create({
		...selectorData,
		filtersMagicTabccordionStore,
		filtersModel,
		filtersStore,
		insertGroupsData,
		isValid: isValidInsertsSelectorAlways || selectorValues.length,
		productModels,
		selectorInsertMagicTabccordionStore,
		selectorValues,
		wasActive,
	});

	return selectorInsertModel;
};
