import { DetailComponent } from '~/product/common/details/Components/details/DetailComponent';
import { DetailComponentDimensions } from '~/product/common/details/Components/details/DetailComponentDimensions';
import { DetailCustomArticleNumber } from '~/product/common/details/Components/details/DetailCustomArticleNumber';
import { DetailCustomBaseArticleNumber } from '~/product/common/details/Components/details/DetailCustomBaseArticleNumber';
import { DetailCustomBookcaseQuestionAnswer } from '~/product/common/details/Components/details/DetailCustomBookcaseQuestionAnswer';
import { DetailCustomTopAndBaseArticleNumber } from '~/product/common/details/Components/details/DetailCustomTopAndBaseArticleNumber';
import { DetailCustomTopArticleNumber } from '~/product/common/details/Components/details/DetailCustomTopArticleNumber';
import { DetailImage } from '~/product/common/details/Components/details/DetailImage';
import { DetailLink } from '~/product/common/details/Components/details/DetailLink';
import { DetailNameValue } from '~/product/common/details/Components/details/DetailNameValue';
import { DetailNameValueGroup } from '~/product/common/details/Components/details/DetailNameValueGroup';
import { DetailQuestion } from '~/product/common/details/Components/details/DetailQuestion';
import { DetailMultiComponent } from '~/product/common/details/Components/details/DetailMultiComponent';
import { DetailMultiValue } from '~/product/common/details/Components/details/DetailMultiValue';
import { DetailTitle } from '~/product/common/details/Components/details/DetailTitle';
import { DetailArticleDimensionsCasegoods } from '~/product/casegoods/details/Components/DetailArticleDimensionsCasegoods';
import { DetailInsertOptionsQuestionAnswerCasegoods } from '~/product/casegoods/details/Components/DetailInsertOptionsQuestionAnswerCasegoods';
import { DetailOptionsQuestionAnswerCasegoods } from '~/product/casegoods/details/Components/DetailOptionsQuestionAnswerCasegoods';
import { DetailDimensionsCasegoods } from '~/product/casegoods/details/Components/DetailDimensionsCasegoods';
import { DetailPromo } from '~/product/common/details/Components/details/DetailPromo';
import { DetailAccentPillowsSectionals } from '~/product/sectionals/details/Components/details/DetailAccentPillowsSectionals';
import { DetailOverallDimensions } from '~/product/sectionals/details/Components/details/DetailOverallDimensions';

export const detailComponents = {
	accentPillows: DetailAccentPillowsSectionals,
	casegoodsInsertOptionsQuestionAnswer: DetailInsertOptionsQuestionAnswerCasegoods,
	casegoodsOptionsQuestionAnswer: DetailOptionsQuestionAnswerCasegoods,
	component: DetailComponent,
	componentDimensions: DetailComponentDimensions,
	customArticleNumber: DetailCustomArticleNumber,
	customBaseQuestionAnswer: DetailNameValue,
	customBookcaseQuestionAnswer: DetailCustomBookcaseQuestionAnswer,
	customTopArticleNumberDetail: DetailCustomTopArticleNumber,
	customBaseArticleNumberDetail: DetailCustomBaseArticleNumber,
	customTopAndBaseArticleNumberDetail: DetailCustomTopAndBaseArticleNumber,
	image: DetailImage,
	link: DetailLink,
	// TODO: get this updated to "casegoodsArticleDimensions" naming from the server
	modularBookcaseArticleDimensions: DetailArticleDimensionsCasegoods,
	multiComponent: DetailMultiComponent,
	multiValue: DetailMultiValue,
	nameValue: DetailNameValue,
	nameValue_group: DetailNameValueGroup,
	overallDimensions: DetailOverallDimensions,
	question: DetailQuestion,
	tightLink: DetailLink,
	title: DetailTitle,
	// TODO: get this updated to "casegoodsDimensions" naming from the server
	modularBookcaseDimensions: DetailDimensionsCasegoods,
	promo: DetailPromo,
};
