import { computed, makeObservable, observable } from 'mobx';

import { sortBy } from '~/util/sortBy';

class SelectorInsert {
	filtersMagicTabccordionStore

	filtersModel

	filtersStore

	isValid

	insertGroupsData

	label

	productModels

	renderer

	selected

	selectorInsertMagicTabccordionStore

	selectorValues

	type

	wasActive

	constructor() {
		makeObservable(this, {
			isValid: observable,
			selected: observable,
			wasActive: observable,
			chunkedProductModels: computed,
			combinedArticles: computed,
			combinedChunkedProductModels: computed,
			combinedChunkedProductModelsCount: computed,
			filteredSelectorValues: computed,
			productModelsByLineDrawingImage: computed,
			selectedSelectorValue: computed,
		});
	}

	get chunkedProductModels() {
		return Object.values(this.productModelsByLineDrawingImage)
			.map((productModels = []) => sortBy(productModels, 'depth'));
	}

	get combinedArticles() {
		return this.selectorValues.reduce((accumulatedArticles, { articles = [] }) => {
			return [...accumulatedArticles, ...articles];
		}, []);
	}

	get combinedChunkedProductModels() {
		return this.selectorValues.reduce((accumulatedChunkedProductModels, { chunkedProductModels = [] }) => {
			return [...accumulatedChunkedProductModels, ...chunkedProductModels];
		}, []);
	}

	get combinedChunkedProductModelsCount() {
		return this.combinedChunkedProductModels.length;
	}

	get filteredSelectorValuesOrder() {
		if (!this.insertGroupsData?.length || this.insertGroupsData.length < 1) return [];

		return this.insertGroupsData.sort((prevInsertGroup, insertGroup) => {
			return prevInsertGroup.sort - insertGroup.sort;
		}).map(insertGroup => insertGroup.title); // use titles as keys to match insertGroup to filteredSelectorValues
	}

	get filteredSelectorValues() {
		const newValues = [];
		this.filteredSelectorValuesOrder?.length && this.filteredSelectorValuesOrder.forEach((orderTitle) => {
			this.filtersModel.filteredSelectorValues.forEach((value) => {
				if (value.title === orderTitle) newValues.push(value);
			});
		});
		return newValues.length ? newValues : this.filtersModel.filteredSelectorValues;
	}

	get productModelsByLineDrawingImage() {
		return this.combinedArticles.reduce((accumulatedLineDrawingImagesMap, {
			articleNumber = '',
			lineDrawingImage = '',
		}) => {
			const matchingProduct = this.productModels.find(({ articleNumber: productModelArticleNumber = '' }) => articleNumber === productModelArticleNumber);

			if (matchingProduct) {
				(accumulatedLineDrawingImagesMap[lineDrawingImage] = accumulatedLineDrawingImagesMap[lineDrawingImage] || []).push(matchingProduct);
			}

			return accumulatedLineDrawingImagesMap;
		}, {});
	}

	get selectedSelectorValue() {
		return this.selectorValues.find(({ selected }) => selected) || this.selectorValues[0];
	}
}

export const SelectorInsertModelFactory = ({
	create: ({
		filtersMagicTabccordionStore = {},
		filtersModel = {},
		filtersStore = {},
		isValid = false,
		insertGroupsData = {},
		label = '',
		productModels = [],
		renderer = '',
		selected = false,
		selectorInsertMagicTabccordionStore = {},
		selectorValues = [],
		type = '',
		wasActive = false,
	}) => {
		const selectorInsert = new SelectorInsert();

		Object.assign(selectorInsert, {
			filtersMagicTabccordionStore,
			filtersModel,
			filtersStore,
			isValid,
			insertGroupsData,
			label,
			productModels,
			renderer,
			selected,
			selectorInsertMagicTabccordionStore,
			selectorValues,
			type,
			wasActive,
		});

		return selectorInsert;
	},
});
