import React from 'react';
import styles from '#/product/standard/selector/selector-fabric.module.scss';

export const SelectorHeadingFabricMoreOptions = () => {
	return (
		<div className={styles['selector-heading-fabric-more-options']}>
			<span className={styles['selector-heading-fabric-more-options-text']}>+ more options</span>
			<div className={styles['selector-heading-fabric-more-options-colors']}>
				<span className={`${styles['selector-heading-fabric-more-options-color']} ${styles['selector-heading-fabric-more-options-color-1']}`} />
				<span className={`${styles['selector-heading-fabric-more-options-color']} ${styles['selector-heading-fabric-more-options-color-2']}`} />
				<span className={`${styles['selector-heading-fabric-more-options-color']} ${styles['selector-heading-fabric-more-options-color-3']}`} />
				<span className={`${styles['selector-heading-fabric-more-options-color']} ${styles['selector-heading-fabric-more-options-color-4']}`} />
				<span className={`${styles['selector-heading-fabric-more-options-color']} ${styles['selector-heading-fabric-more-options-color-5']}`} />
				<span className={`${styles['selector-heading-fabric-more-options-color']} ${styles['selector-heading-fabric-more-options-color-6']}`} />
				<span className={`${styles['selector-heading-fabric-more-options-color']} ${styles['selector-heading-fabric-more-options-color-7']}`} />
			</div>
		</div>
	);
};
