import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/casegoods/workspace/modal-frame/workspace-modal-frame-attribute-control.module.scss';

export const SelectorValueRadioFrameModal = observer(({
	label = '',
	selectorValue,
	selectorValue: {
		articleNumbers = [],
		question = '',
		selected = false,
		title = '',
	},
	selectorConfigFrameModalStore = {},
	workspaceProductStoreFrameModal = {},
}) => {
	const {
		selectorConfigModel: {
			productSelectorValues = [],
		} = {},
		selectorConfigStore = {},
	} = useProductContext();

	function handleChange() {
		if (question) {
			selectorConfigFrameModalStore.selectQuestionSelectorValue(selectorValue, true);

			workspaceProductStoreFrameModal.getWorkspaceProductImagesData();
		} else {
			selectorConfigFrameModalStore.selectSelectorValue(selectorValue, true);

			// keep the main selectorConfig in sync with productSelectorValue changes
			const selectorValueToSelect = productSelectorValues.find(({ articleNumbers: selectorConfigArticleNumbers = [] }) => {
				return articleNumbers.toString() === selectorConfigArticleNumbers.toString();
			});

			if (selectorValueToSelect) {
				selectorConfigStore.selectSelectorValue(selectorValueToSelect, true);
			}
		}
	}

	return (
		<label
			className={styles['workspace-modal-frame-attribute-control']}
			data-qa={`${kebabCase(label)}-selector-value`}
		>
			<input
				checked={selected}
				className={styles['workspace-modal-frame-attribute-control-input']}
				data-tr-link-event-track={false}
				onChange={handleChange}
				type="radio"
			/>
			<span>{title}</span>
		</label>
	);
});
