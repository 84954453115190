import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useDragLayer } from 'react-dnd';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductSlotDroppableCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-slot/WorkspaceProductSlotDroppableCasegoods';

import styles from '#/product/casegoods/workspace/product-slot/workspace-product-slots.module.scss';

export const WorkspaceProductSlotsDroppableCasegoods = observer((props = {}) => {
	const {
		imageScale = 0,
		workspaceProduct = {},
		workspaceProduct: {
			frameProductModel: {
				depth = 0,
				insertSizeKey = '',
				slotsColumnCount = 0,
				slotsRowCount = 0,
				width = 0,
			} = {},
			isHidden = false,
			workspaceProductSlotModels = [],
		} = {},
	} = props;

	const {
		workspaceModel: {
			dimensions: {
				imageInchesToPixelsFactor = 0,
				offsetBottomInches = 0,
				offsetTopInches = 0,
				offsetXInches = 0,
			} = {},
		} = {},
	} = useProductContext();

	const workspaceProductSlotsPixelsBottom = (imageInchesToPixelsFactor * offsetBottomInches) / imageScale;

	const workspaceProductSlotsPixelsTop = (imageInchesToPixelsFactor * offsetTopInches) / imageScale;

	const workspaceProductSlotsPixelsX = (imageInchesToPixelsFactor * offsetXInches) / imageScale;

	const slotHeightPercentage = 100 / slotsRowCount;

	const slotWidthPercentage = 100 / slotsColumnCount;

	const {
		isDragging,
		type,
	} = useDragLayer(monitor => ({
		isDragging: monitor.isDragging(),
		type: monitor.getItemType(),
	}));

	return (
		<div
			className={classNames(styles['workspace-product-slots'], {
				'tw-pointer-events-auto': isDragging && type === 'INSERT_PRODUCT',
			})}
			style={{ padding: `${workspaceProductSlotsPixelsTop}px ${workspaceProductSlotsPixelsX}px ${workspaceProductSlotsPixelsBottom}px` }}
		>
			{
				workspaceProductSlotModels.map((slotModel, index) => {
					return (
						<WorkspaceProductSlotDroppableCasegoods
							depth={depth}
							imageScale={imageScale}
							insertSizeKey={insertSizeKey}
							isHidden={isHidden}
							key={`product-slot-${index}`}
							slotHeightPercentage={slotHeightPercentage}
							slotModel={slotModel}
							slotWidthPercentage={slotWidthPercentage}
							width={width}
							workspaceProduct={workspaceProduct}
						/>
					);
				})
			}
		</div>
	);
});
