import { computed, observable, makeObservable } from 'mobx';

import { maxBy } from '~/util/maxBy';

export class FrameProduct {
	applicableInsertValidationData;

	articleNumber;

	baseUrl;

	depth;

	detailsAdditionalModel;

	detailsAttributesModel;

	detailsDimensionsModel;

	detailsMaterialsModel;

	detailsSustainabilityModel;

	height;

	imageCollectionName;

	imageUrl;

	insertSizeKey;

	originImage;

	originImageText;

	questionModels;

	salesText;

	salesTextOverride;

	shortSalesText;

	slots;

	topUrl;

	widthData;

	constructor() {
		makeObservable(this, {
			applicableInsertValidationData: observable,
			topUrl: observable,
			baseUrl: observable,
			imageUrl: observable,
			questionModels: observable,
			slots: observable,
			configuration: computed,
			hasAllowAllSlot: computed,
			questionsAnswers: computed,
			salesTextToRender: computed,
			selectedQuestionAnswers: computed,
			slotsColumnCount: computed,
			slotsRowCount: computed,
			width: computed,
		});
	}

	get configuration() {
		return this.selectedQuestionAnswers.reduce((accumulatedQuestionAnswers, {
			answer = '',
			question = '',
		}) => {
			if (answer) {
				accumulatedQuestionAnswers[question] = answer;
			}

			return accumulatedQuestionAnswers;
		}, {});
	}

	get hasAllowAllSlot() {
		// TODO: have the API send slots data on initial frameProduct document so we can stop cherry picking by collection
		const hasShelfQuestion = this.questionModels.some(({ group = '' }) => group === 'SHELF');

		const allowAllSlot = this.slots.some(({ allow = '' }) => allow === 'ALL');

		return allowAllSlot || this.imageCollectionName === 'slim' || (this.imageCollectionName === 'beam' && hasShelfQuestion);
	}

	get questionsAnswers() {
		const { questionModels = [] } = this;

		return questionModels
			.map(({
				key: characteristicKey,
				name: questionText,
				selectedAnswer: {
					name: answerText = '',
					key: characteristicValueKey = '',
				} = {},
			}) => {
				return {
					questionText,
					answerText,
					characteristicKey,
					characteristicValueKey,
				};
			});
	}

	get salesTextToRender() {
		return this.salesTextOverride || this.shortSalesText || this.salesText;
	}

	get selectedQuestionAnswers() {
		return this.questionModels.map(({
			key,
			selectedAnswer,
		}) => {
			return {
				answer: selectedAnswer?.key,
				question: key,
			};
		});
	}

	get slotsColumnCount() {
		const maxSlotColumn = maxBy(this.slots, ({ columnIndex = 0 }) => columnIndex) || {};

		const { columnIndex = 0 } = maxSlotColumn;

		return columnIndex + 1;
	}

	get slotsRowCount() {
		const maxSlotColumn = maxBy(this.slots, ({ rowIndex = 0 }) => rowIndex) || {};

		const { rowIndex = 0 } = maxSlotColumn;

		return rowIndex + 1;
	}

	get width() {
		const { answer: widthAnswerValue = 0 } = this.selectedQuestionAnswers
			.find(({ question = '' }) => question === 'BOOKCASE_CUST_WIDTH') || {};

		return this.widthData || parseInt(widthAnswerValue, 10);
	}
}

export const FrameProductModelFactory = ({
	create: ({
		applicableInsertValidationData,
		articleNumber = '',
		base: {
			height: baseHeight = 0,
			imageUrl: baseUrl = '',
		} = {},
		depth = 0,
		detailsAdditionalModel = [],
		detailsAttributesModel = [],
		detailsDimensionsModel = [],
		detailsMaterialsModel = [],
		detailsSustainabilityModel = [],
		height = 0,
		imageCollectionName = '',
		imageUrl = '',
		topImageUrl,
		baseImageUrl,
		insertSizeKey = '',
		originImage = '',
		originImageText = '',
		questionModels = [],
		salesText = '',
		salesTextOverride = '',
		shortSalesText = '',
		slots = [],
		top: {
			height: topHeight = 0,
			imageUrl: topUrl = '',
		} = {},
		widthData = 0,
		_links: links,
	}) => {
		const frameProduct = new FrameProduct();

		Object.assign(frameProduct, {
			applicableInsertValidationData,
			articleNumber,
			baseHeight,
			baseUrl: baseImageUrl || baseUrl,
			depth,
			detailsAdditionalModel,
			detailsAttributesModel,
			detailsDimensionsModel,
			detailsMaterialsModel,
			detailsSustainabilityModel,
			height,
			imageCollectionName,
			imageUrl,
			insertSizeKey,
			originImage,
			originImageText,
			questionModels,
			salesText,
			salesTextOverride,
			shortSalesText,
			slots,
			topHeight,
			topUrl: topImageUrl || topUrl,
			widthData,
			links,
		});
		return frameProduct;
	},
});
