import { MagicRecosRfkStoreFactory } from '~/components/magic-recos-rfk/Stores/MagicRecosRfk.store';
import { isOnServer } from '~/global/global.constants';

export const createProductRecoStore = (widgetId, uri, context, options) => {
	if (isOnServer) {
		return;
	}
	// eslint-disable-next-line consistent-return
	return MagicRecosRfkStoreFactory.create(widgetId, uri, context, options);
};
