import React from 'react';

export const EditZipCode = ({ store }) => {
	return (
		<button
			type="button"
			className="ButtonAnchor tw-text-lg tw-font-light"
			onClick={() => {
				store.viewState.goTo('deliveryForm', {
					autoFocusZipCode: true,
				});
			}}
		>
			Edit ZIP Code
		</button>
	);
};
