import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { selectorHeadingComponents } from '~/product/common/selector/Components/selector-heading-components';
import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector.module.scss';

export const SelectorHeadingDefault = observer((props) => {
	const {
		isActive = true,
		selectedSelector: {
			groupValuesByStocked = false,
			label = '',
			selectedSelectorValue: {
				statusToRender = '',
				title = '',
			} = {},
			selectorValuesCount = 0,
			selectorValuesToRenderCount = null,
			showWidthQuantityMessage = false,
		} = {},
		hideOptionsCountWhenActive = false,
		alwaysShowOptionsCount = false,
		alwaysHideOptionsCount = false,
		showOptional = false,
		titleOverride = null,
	} = props;

	const {
		summaryModel: {
			hasWidthQuantity = false,
		} = {},
	} = useProductContext();

	const renderTitle = titleOverride || title;

	const hideCount = alwaysHideOptionsCount || (!alwaysShowOptionsCount && isActive && (hideOptionsCountWhenActive || groupValuesByStocked));

	return (
		<div
			className="selector-heading"
			data-qa={`${kebabCase(label)}-selector-heading`}
		>
			<div className="selector-heading-label-container">
				<SelectorHeadingLabel label={label} />
				{
					!hideCount && (
						<span
							className={styles['selector-heading-count']}
							data-qa={`${kebabCase(label)}-selector-heading-count`}
						>
							{selectorValuesToRenderCount === null ? selectorValuesCount : selectorValuesToRenderCount} options
						</span>
					)
				}
				{
					showOptional && (
						<span
							className={styles['selector-heading-optional']}
							data-qa={`${kebabCase(label)}-selector-heading-optional`}
						>
							Optional
						</span>
					)
				}
			</div>
			{
				renderTitle && (
					<span
						className={styles['selector-heading-title']}
						data-qa={`${kebabCase(label)}-selector-heading-title`}
					>
						{renderTitle}
						{
							isActive && statusToRender && (
								<span className={
									classNames(styles['selector-heading-status'], {
										[styles['selector-heading-status-new']]: statusToRender === 'New',
									})
								}>{statusToRender}</span>
							)
						}
					</span>
				)
			}
			{
				isActive && showWidthQuantityMessage && hasWidthQuantity && (
					<div
						className={styles['selector-heading-message']}
						data-qa={`${kebabCase(label)}-selector-heading-message`}
					>
						Top will be in two pieces
					</div>
				)
			}
		</div>
	);
});

export const SelectorHeading = observer((props) => {
	const {
		isActive = true,
		selectedSelector = {},
		selectedSelector: {
			renderer = '',
		} = {},
		selectorHeadingOverrideComponents = {},
		showPrice = false,
	} = props;

	const SelectorHeadingComponent = selectorHeadingOverrideComponents[renderer] || selectorHeadingComponents[renderer] || SelectorHeadingDefault;

	return (
		<div className={`${styles['selector-heading-container']} selector-heading-container`}>
			<SelectorHeadingComponent
				isActive={isActive}
				selectedSelector={selectedSelector}
				showPrice={showPrice}
			/>
		</div>
	);
});
