import { computed, observable, makeObservable } from 'mobx';

import { s7ImagePath } from '~/global/global.constants';

class SelectorValueQuestion {
	articleNumbers;

	detailsModel;

	filters;

	id;

	image;

	imageSrc;

	parentDisplayType;

	parentLabel;

	parentRenderer;

	parentType;

	peerId;

	preselected;

	priceGroup;

	pricesByArticle;

	products;

	questionAnswers;

	selected;

	selectedProduct;

	selectorDetailsModel;

	selectors;

	status;

	stocked;

	subtitle;

	title;

	unavailable;

	_links;

	constructor() {
		makeObservable(this, {
			preselected: observable,
			selected: observable,
			unavailable: observable,
			detailImage: computed,
			detailsToRender: computed,
			hasSelectorDetails: computed,
			materialAPIHref: computed,
			selectorDetailImage: computed,
			selectorDetailLink: computed,
			selectorDetailsToRender: computed,
			statusToRender: computed,
		});
	}

	get detailImage() {
		return this.detailsModel.find(({ type = '' }) => type === 'image');
	}

	get detailsToRender() {
		return this.detailsModel.filter(({ type = '' }) => type !== 'image');
	}

	get hasSelectorDetails() {
		return Boolean(this.selectorDetailsModel.length);
	}

	get imageUrl() {
		const { imageSrc = '' } = this.image;

		return `${s7ImagePath}/${imageSrc}`;
	}

	get materialAPIHref() {
		return this._links?.SWATCH_API_LINK?.href || ['Fabric', 'Leather'].includes(this.parentLabel);
	}

	get selectorDetailImage() {
		return this.selectorDetailsModel.find(({ type }) => type === 'image');
	}

	get selectorDetailLink() {
		return this.selectorDetailsModel.find(({ type }) => type === 'link');
	}

	get selectorDetailsToRender() {
		return this.selectorDetailsModel.filter(({ type }) => type !== 'image');
	}

	get statusToRender() {
		const { text = '' } = this.status;

		return text !== 'COMPONENT' ? text : '';
	}

	get stockedArticleNumbers() {
		return Object.entries(this.pricesByArticle).reduce((accumulatedArticleNumbers = [], [articleNumber = '', { stocked = false } = {}] = []) => {
			return stocked ? [...accumulatedArticleNumbers, articleNumber] : [...accumulatedArticleNumbers];
		}, []);
	}
}

export const SelectorValueQuestionModelFactory = ({
	create: ({
		articleNumbers = [],
		detailsModel = [],
		filters = {},
		id = '',
		image = {},
		imageSrc = '',
		parentDisplayType = '',
		parentLabel = '',
		parentRenderer = '',
		parentType = '',
		peerId = '',
		preselected = false,
		priceGroup = '',
		pricesByArticle = {},
		products = [],
		questionAnswers = [],
		selected = false,
		selectedProduct = {},
		selectorDetailsModel = [],
		selectors = [],
		status = {},
		stocked = false,
		subtitle = '',
		title = '',
		unavailable = false,
		_links = {},
	}) => {
		const selectorValueQuestion = new SelectorValueQuestion();

		Object.assign(selectorValueQuestion, {
			articleNumbers,
			detailsModel,
			filters,
			id,
			image,
			imageSrc,
			parentDisplayType,
			parentLabel,
			parentRenderer,
			parentType,
			peerId,
			preselected,
			priceGroup,
			pricesByArticle,
			products,
			questionAnswers,
			selected,
			selectedProduct,
			selectorDetailsModel,
			selectors,
			status,
			stocked,
			subtitle,
			title,
			unavailable,
			_links,
		});

		return selectorValueQuestion;
	},
});
