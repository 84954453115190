import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import { s7ContentPath } from '~/global/global.constants';
import { SelectorValueInsert } from '~/product/casegoods/selector/Components/insert/SelectorValueInsert';
import { getShouldShowShadowY } from '~/product/common/selector/Utils/Selector.utils';

import frameStyles from '#/product/casegoods/selector/frame/selector-frame.module.scss';
import styles from '#/product/casegoods/selector/insert/selector-insert.module.scss';

export const SelectorInsertTabccordionTab = (props = {}) => {
	const {
		tab: {
			isActive,
			selectorValueModel: {
				selectedSelector: {
					selectorValues = [],
				},
			},
		} = {},
	} = props;
	const scrollingParentRef = useRef();
	const scrollingChildRef = useRef();
	const messageRef = useRef();
	const [showMessage, setShowMessasge] = useState(true);
	const [showShadow, setShowShadow] = useState(false);
	let ticking = false;
	function setShowShadowState() {
		const shouldShowShadow = getShouldShowShadowY({
			isActive,
			scrollingChildRef,
			scrollingParentRef,
		});

		setShowShadow(shouldShowShadow);
	}
	function handleScroll() {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				ticking = false;
				setShowShadowState();
				setShowMessasge(false);
			});
			ticking = true;
		}
	}

	return (<div className={classNames('selector-insert-wrapper')}>
		<div
			className={styles['selector-insert-container']}
			onScroll={handleScroll}
			ref={scrollingParentRef}
		>
			<div
				className={
					classNames(`${frameStyles['selector-frame-message']} md:tw-hidden`, {
						'tw-opacity-0': !showMessage,
					})
				}
				ref={messageRef}
			>
				<img
					alt=""
					className={frameStyles['selector-frame-message-image']}
					src={`${s7ContentPath}/swipe-arrows`}
				/>
				<span className={frameStyles['selector-frame-message-text']}>Swipe for <br />options</span>
			</div>
			<div
				className={
					classNames(styles['selector-insert'], { [styles['selector-insert-shadow']]: showShadow })
				}
				ref={scrollingChildRef}
			>
				<div className={styles['selector-values-insert']} data-qa="selector-values-insert">
					{selectorValues.length
						? selectorValues.map((selectorValue, index) => {
							return (
								<SelectorValueInsert
									key={`selector-value-${index}`}
									label={'label thing'}
									selectorValue={selectorValue}
								/>
							);
						})
						: <div className="tw-pt-16 tw-pb-32">No inserts available for these frames</div>
					}
				</div>
			</div>
		</div>
	</div>);
};
