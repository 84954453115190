import type { ComponentPropsWithoutRef } from 'react';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

type Props = Partial<{
	isPlural: boolean
	isDisabled: boolean
	isDisabledBeforeMount: boolean
	isAnimating: boolean
	isPending: boolean
	isResolved: boolean
}> & ComponentPropsWithoutRef<'button'>

export const ButtonFlasher = observer((props: Props) => {
	const {
		children,
		className = '',
		isPlural = false,
		isDisabled = false,
		isDisabledBeforeMount = false,
		isAnimating = false,
		isPending = false,
		isResolved = false,
		onClick,
		...attrs
	} = props;

	// react did not like reconciling the disabled attribute being present in SSR but not client,
	// updating it after mount is a workaround
	const [didMountState, setDidMountState] = useState(!isDisabledBeforeMount);
	const disabled = didMountState ? isDisabled || isAnimating : isDisabledBeforeMount;

	useEffect(() => {
		setDidMountState(true);
	}, []);

	return (
		<button
			{...attrs}
			disabled={disabled}
			className={cn('Button', 'js-button-flasher', className, {
				'is-animating': isAnimating,
				'is-animating-in': isAnimating,
				'is-animating-out': !isAnimating,
				'is-pending': isPending,
				'is-resolved': isResolved
			})}
			data-is-plural={isPlural}
			onClick={onClick}
		>
			{children}
		</button>
	);
});
