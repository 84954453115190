import { SelectorInsertOptionsModelFactory } from '~/product/casegoods/selector/Models/SelectorInsertOptions.model';
import { SelectorValueInsertOptionsModelFactory } from '~/product/casegoods/selector/Models/SelectorValueInsertOptions.model';

export const createSelectorInsertOptionsModel = ({
	selectorData = {},
	wasActive = false,
}) => {
	const {
		label = '',
		type = '',
		values: selectorValuesData = [],
	} = selectorData;

	const selectorValues = selectorValuesData.map((selectorValueData = {}) => {
		return createSelectorValueInsertOptionsModel({
			parentLabel: label,
			parentType: type,
			selectorValueData,
			wasActive,
		});
	});

	const selectorInsertOptionsModel = SelectorInsertOptionsModelFactory.create({
		...selectorData,
		selectorValues,
		wasActive,
	});

	return selectorInsertOptionsModel;
};

const createSelectorValueInsertOptionsModel = ({
	parentLabel = '',
	parentType = '',
	selectorValueData = {},
	selectorValueData: {
		selectors: selectorsData = [],
	} = {},
	wasActive = false,
}) => {
	const selectors = selectorsData.map((selectorData = {}) => {
		return createSelectorInsertOptionsModel({
			selectorData,
			wasActive,
		});
	});

	const selectorValueInsertOptionsModel = SelectorValueInsertOptionsModelFactory.create({
		...selectorValueData,
		parentLabel,
		parentType,
		selectors,
	});

	return selectorValueInsertOptionsModel;
};