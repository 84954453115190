import { computed, makeObservable } from 'mobx';

class AddedToWishlist {
	data

	productGroups

	showQuantity

	constructor() {
		makeObservable(this, {
			cartProductGroupIds: computed,
			articleNumbers: computed,
			returnMessageTypes: computed,
			cartRooms: computed,
			interstitialRenderer: computed,
			isSaveDisabled: computed,
		});
	}

	get cartProductGroupIds() {
		return this.productGroups.map(({ id }) => id);
	}

	get articleNumbers() {
		const result = [];

		this.productGroups.forEach((productGroup) => {
			productGroup.products.forEach((product) => {
				result.push(product.articleNumber);
			});
		});
		return result;
	}

	get returnMessageTypes() {
		const result = [];

		this.productGroups.forEach((productGroup) => {
			productGroup.products.forEach(({ returnMessageType }) => {
				result.push(returnMessageType);
			});
		});
		return result;
	}

	get cartRooms() {
		return this.data.cartRooms;
	}

	get interstitialRenderer() {
		return this.data.interstitialRenderer;
	}

	get isSaveDisabled() {
		const { existingRoomName, newRoomName, wishlistAction } = this.form.model;
		const hasNoRoomSelected = !existingRoomName && wishlistAction === 'EXISTING_ROOM';
		const hasNoRoomEntered = !newRoomName.trim() && wishlistAction === 'NEW_ROOM';

		return hasNoRoomEntered || hasNoRoomSelected;
	}
}

export const AddedToWishlistFactory = ({
	create: (data = {}, productGroups = [], showQuantity = true) => {
		const instance = new AddedToWishlist();

		instance.data = data;
		instance.productGroups = productGroups;
		instance.showQuantity = showQuantity;

		return instance;
	},
});
