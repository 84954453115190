export const mattressConstructionData = {
	MATTRESS_BASIC: {
		title: 'Construction of Basic Kids Mattress',
		img: 'ConstructionMattressKidsBasic',
		list: [
			'100% GOTS certified organic cotton cover',
			'Wood pulp-based fiber made with no flame retardant chemicals',
			'0.75" CertiPUR-US® certified super-soft foam',
			'1.5" CertiPUR-US® certified convoluted soft foam',
			'1" CertiPUR-US® certified soft foam',
			'Insulator fabric to protect foam from springs',
			'Innerspring coils for even support',
			'Protective fabric wrap and non-skid base',
		],
	},
	MATTRESS_BASIC_BUNK: {
		title: 'Construction of Basic Bunk Mattress',
		img: 'ConstructionMattressKidsBasicBunk',
		list: [
			'100% GOTS certified organic cotton cover',
			'Wood pulp-based fiber made with no flame retardant chemicals',
			'0.5" CertiPUR-US® certified soft foam',
			'1.5" CertiPUR-US® certified convoluted soft foam',
			'Insulator fabric to protect foam from springs',
			'Innerspring coils for even support',
			'Protective fabric wrap and non-skid base',
		],
	},
	MATTRESS_BASIC_CRIB: {
		title: 'Construction of Basic Crib Mattress',
		img: 'ConstructionMattressCribBasic',
		list: [
			'100% GOTS certified organic cotton cover',
			'Wood pulp-based fiber made with no flame retardant chemicals',
			'0.75" CertiPUR-US® certified soft foam',
			'Insulator fabric to protect foam from springs',
			'Innerspring coils for even support',
			'Protective fabric wrap',
			'Flippable design; same layers mirrored on other side of innerspring coils',
		],
	},
	MATTRESS_ESSENTIAL_BUNK: {
		title: 'Construction of Essential Foam & Coil Bunk Mattress',
		img: 'ConstructionMattressFoamCoilBunk',
		list: [
			'100% GOTS certified organic cotton cover',
			'Wood pulp-based fiber made with no flame retardant chemicals',
			'0.5" CertiPUR-US® certified soft foam',
			'1.5" CertiPUR-US® certified convoluted soft foam',
			'Individually wrapped encased coils for proper orthopedic support',
			'5" wide CertiPUR-US® certified foam perimeter for edge support and maximized sleep surface',
			'Protective fabric wrap and non-skid base',
		],
	},
	MATTRESS_ESSENTIAL_HYBRID: {
		title: 'Construction of Essential Foam & Coil Hybrid Mattress',
		img: 'ConstructionMattressEssentialFoamCoilHybrid',
		list: [
			'100% GOTS certified organic cotton cover',
			'Wood pulp-based fiber made with no flame retardant chemicals',
			'0.5" CertiPUR-US® certified soft foam',
			'2" CertiPUR-US® certified convoluted soft foam',
			'Individually wrapped encased coils for proper orthopedic support',
			'5" wide CertiPUR-US® certified foam perimeter for edge support and maximized sleep surface',
			'Protective fabric wrap and non-skid base',
		],
	},
	MATTRESS_ESSENTIAL_SOFT_HYBRID: {
		title: 'Construction of Essential Soft Foam & Coil Hybrid Mattress',
		img: 'ConstructionMattressEssentialSoftFoamCoilHybrid',
		list: [
			'100% GOTS certified organic cotton cover',
			'Wood pulp-based fiber made with no flame retardant chemicals',
			'0.75" CertiPUR-US® certified super-soft foam',
			'2.5" CertiPUR-US® certified convoluted super-soft foam',
			'Individually wrapped encased coils for proper orthopedic support',
			'5" wide CertiPUR-US® certified foam perimeter for edge support and maximized sleep surface',
			'Protective fabric wrap and non-skid base',
		],
	},
	MATTRESS_LUXURY_HYBRID: {
		title: 'Construction of Luxury Serene® Foam & Coil Hybrid Mattress',
		img: 'MATTRESS_LUXURY_HYBRID',
		list: [
			'100% GOTS certified organic cotton cover',
			'Wood pulp-based fiber made with no flame retardant chemicals',
			'0.75" CertiPUR-US® certified super-soft foam',
			'3" CertiPUR-US® certified Serene® foam',
			'Individually wrapped encased coils for proper orthopedic support',
			'5" wide CertiPUR-US® certified foam perimeter for edge support and maximized sleep surface',
			'Protective fabric wrap and non-skid base',
		],
	},
	MATTRESS_LUXURY_SERENE_FOAM: {
		title: 'Construction of Luxury Serene® Foam Mattress',
		img: 'MATTRESS_LUXURY_SERENE_FOAM',
		list: [
			'100% GOTS certified organic cotton cover',
			'Wood pulp-based fiber made with no flame retardant chemicals',
			'0.75" CertiPUR-US® certified super-soft foam',
			'2" CertiPUR-US® certified Serene® foam',
			'6" CertiPUR-US® certified firm foam core',
			'Protective fabric wrap and non-skid base',
		],
	},
	MATTRESS_NATURAL_LATEX_COIL_HYBRID: {
		title: 'Construction of Natural Organic Latex & Coil Hybrid Mattress',
		img: 'Construction_Mattress_Natural_Organic_Latex_Coil_Hybrid_v2',
		list: [
			'100% GOTS certified organic cotton cover',
			'Wood pulp-based fiber made with no flame retardant chemicals',
			'1.7 oz/sq. ft. New Zealand Joma™ wool',
			'3" GOLS certified organic latex soft foam created using Dunlop process',
			'Individually wrapped encased coils for proper orthopedic support',
			'5" wide CertiPUR-US® certified foam perimeter for edge support and maximized sleep surface',
			'Protective fabric wrap and non-skid base',
		],
	},
	MATTRESS_NATURAL_LATEX_SPRING_CRIB: {
		title: 'Construction of Natural Organic Latex & Spring Crib Mattress',
		img: 'ConstructionMattressNaturalOrganicLatexSpringCrib',
		list: [
			'100% GOTS certified organic cotton cover',

			'Naturally non-toxic fire-retardant fiber made from wood pulp',
			'1 oz/sq. ft. New Zealand Joma™ wool',
			'0.75" GOLS certified organic latex firm foam created using the Dunlop process',
			'Insulator fabric to protect foam from springs',
			'Innerspring coils for even support',
			'Protective fabric wrap',
			'Flippable design; same layers mirrored on other side of innerspring coils',
		],
	},
	MATTRESS_NATURAL_ORGANIC_CRIB: {
		title: 'Construction of Natural Organic Latex Crib Mattress',
		img: 'ConstructionMattressNaturalOrganicLatexCrib',
		list: [
			'100% GOTS certified organic cotton cover',
			'Wood pulp-based fiber made with no flame retardant chemicals',
			'1 oz/sq. ft. New Zealand Joma™ wool',
			'4" GOLS certified organic latex firm foam created using the Dunlop process',
			'Protective fabric wrap',
			'Flippable design; same layers mirrored on other side of foam',
		],
	},
	MATTRESS_NATURAL_ORGANIC_LATEX: {
		title: 'Construction of Natural Organic Latex Mattress',
		img: 'Construction_Mattress_Natural_Organic_Latex',
		list: [
			'100% GOTS certified organic cotton cover',
			'Wood pulp-based fiber made with no flame retardant chemicals',
			'1.7 oz/sq. ft. New Zealand Joma™ wool',
			'1.5" GOLS certified organic latex soft foam created using Dunlop process',
			'6" GOLS certified organic latex firm foam core created using the Dunlop process',
			'Protective fabric wrap and non-skid base',
			'Flippable for firmer side; layers not pictured on opposite side: Joma™ wool (1 oz/sq. ft.), natural fire-retardant layer, organic cotton cover',
		],
	},
};
