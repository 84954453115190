import { computed, makeObservable } from 'mobx';

import { chunk } from '~/util/chunk';

class SelectorFilter {
	facetsColumnCount

	facetsModel

	sort

	title

	type

	constructor() {
		makeObservable(this, {
			chunkedFacets: computed,
			facetsToRender: computed,
			filteredSelectorValues: computed,
			hasSelectedFacets: computed,
			selectedFacets: computed,
			selectedFacetsCount: computed,
		});
	}

	get chunkedFacets() {
		const facetsPerColumnCount = Math.ceil(this.facetsToRender.length / this.facetsColumnCount);

		return chunk(this.facetsToRender, facetsPerColumnCount);
	}

	get facetsToRender() {
		return this.facetsModel.filter(({ intersectingFilteredSelectorValuesCount }) => Boolean(intersectingFilteredSelectorValuesCount));
	}

	get filteredSelectorValues() {
		return this.selectedFacets.reduce((accumulatedSelectorValues, { intersectingFilteredSelectorValues = [] }) => {
			return [...accumulatedSelectorValues, ...intersectingFilteredSelectorValues];
		}, []);
	}

	get hasSelectedFacets() {
		return Boolean(this.selectedFacetsCount);
	}

	get selectedFacets() {
		return this.facetsModel.filter(({ selected }) => selected);
	}

	get selectedFacetsCount() {
		return this.selectedFacets.length;
	}
}

export const SelectorFilterModelFactory = ({
	create: ({
		facetsColumnCount = 2,
		facetsModel = [],
		sort = 0,
		title = '',
		type = '',
	}) => {
		const selectorFilter = new SelectorFilter();

		Object.assign(selectorFilter, {
			facetsColumnCount,
			facetsModel,
			sort,
			title,
			type,
		});

		return selectorFilter;
	},
});
