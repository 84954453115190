import { isEngage, isOnServer } from '~/global/global.constants';
import { ICylindoJSON } from '~/tracking/vendor-events/cylindo/Interfaces/ICylindoPresentEvent.tracking';
import { CylindoPresentEventTrackingModel } from '~/tracking/vendor-events/cylindo/Models/CylindoPresentEvent.tracking.model';

class CylindoPresentEventTrackingStore {
	tsWindow = global as any;

	mediaCylindoModel: any

	constructor(mediaCylindoModel: any) {
		this.mediaCylindoModel = mediaCylindoModel;
	}

	trackCylindoPresent(articleNumber: string) {
		if (!this.tsWindow.eventDataLayer || isOnServer || isEngage) {
			return;
		}

		const {
			hasCylindo = false,
			hasCylindoDimensionImage = false,
		} = this.mediaCylindoModel;

		const cylindo360 = hasCylindo ? 1 : 0;
		const cylindoAR = hasCylindo ? 1 : 0;
		const cylindoDim = hasCylindoDimensionImage ? 1 : 0;

		const cylindoPresentEventTrackingModel = new CylindoPresentEventTrackingModel({
			cylindo360,
			cylindoAR,
			cylindoDim,
			articleNumber,
		});

		this.pushToEventDataLayer(cylindoPresentEventTrackingModel.trackingJson);
	}

	pushToEventDataLayer(json: ICylindoJSON) {
		this.tsWindow.eventDataLayer.push(json);
	}
}

export const CylindoPresentEventTrackingStoreFactory = {
	create(mediaCylindoModel = {}) {
		const cylindoPresentTracking = new CylindoPresentEventTrackingStore(mediaCylindoModel);

		return cylindoPresentTracking;
	},
};
