import { createMediaWorkspaceCasegoodsModel } from '~/product/casegoods/media-set/Helpers/MediaWorkspaceCasegoods.init';
import { createMediaSetModel } from '~/media-set/Helpers/MediaSet.init';

export const createMediaSetDetailCasegoodsModel = ({ workspaceModel = {} }) => {
	const mediaWorkspaceCasegoodsModel = createMediaWorkspaceCasegoodsModel({ workspaceModel });

	const mediaSetDetailCasegoodsModel = createMediaSetModel({ mediaModelsHead: [mediaWorkspaceCasegoodsModel] });

	return mediaSetDetailCasegoodsModel;
};
