import { computed, makeObservable, observable } from 'mobx';

import type { IWorkspaceProductsChunkSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductsChunkSectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

import { perpendicularOrientationsMap } from '~/product/sectionals/workspace/workspace-sectionals.constants';
import { minBy } from '~/util/minBy';

class WorkspaceProductsChunkSectionals implements IWorkspaceProductsChunkSectionals {
	isAdditionalWorkspaceProductsChunk: boolean

	isForcedWorkspaceProductsChunk: boolean

	isNewChunk: boolean

	prevWorkspaceProductsChunkModel: IWorkspaceProductsChunkSectionals

	workspaceProductModels: Array<IWorkspaceProductSectionals>

	workspaceProductsChunkOrientation: string

	constructor({
		isAdditionalWorkspaceProductsChunk = false,
		isForcedWorkspaceProductsChunk = false,
		isNewChunk = false,
		prevWorkspaceProductsChunkModel = null,
		workspaceProductsChunkOrientation = '',
		workspaceProductModels = [],
	}: any = {}) {
		makeObservable(this, {
			prevWorkspaceProductsChunkModel: observable,
			workspaceProductModels: observable,
			afterWorkspaceProductModel: computed,
			beforeWorkspaceProductModel: computed,
			firstWorkspaceProductModel: computed,
			firstWorkspaceProductModelInChunk: computed,
			isDepthWorkspaceProductsChunkModel: computed,
			isReversedDirection: computed,
			isWidthWorkspaceProductsChunkModel: computed,
			lastWorkspaceProductModel: computed,
			lastWorkspaceProductModelInChunk: computed,
			workspaceProductsChunkDepth: computed,
			workspaceProductsChunkWidth: computed,
			workspaceProductModelsWithAdjacentWorkspaceProductModels: computed,
		});

		this.isAdditionalWorkspaceProductsChunk = isAdditionalWorkspaceProductsChunk;
		this.isForcedWorkspaceProductsChunk = isForcedWorkspaceProductsChunk;
		this.isNewChunk = isNewChunk;
		this.prevWorkspaceProductsChunkModel = prevWorkspaceProductsChunkModel;
		this.workspaceProductModels = workspaceProductModels;
		this.workspaceProductsChunkOrientation = workspaceProductsChunkOrientation;
	}

	get afterWorkspaceProductModel(): IWorkspaceProductSectionals {
		const lastWorkspaceProductModel = this.workspaceProductModels[this.workspaceProductModels.length - 1];

		const {
			orientation = '',
			workspaceProductJoinableModelsByOrientation = {},
		} = lastWorkspaceProductModel;

		const perpendicularOrientations = perpendicularOrientationsMap[orientation as keyof typeof perpendicularOrientationsMap];

		const lastWorkspaceProductJoinableModel = perpendicularOrientations
			.map((perpendicularOrientation = '') => workspaceProductJoinableModelsByOrientation[perpendicularOrientation])
			.filter(Boolean)
			.find(({ nextWorkspaceProductModel }) => nextWorkspaceProductModel);

		const { nextWorkspaceProductModel = null } = lastWorkspaceProductJoinableModel || {};

		return nextWorkspaceProductModel;
	}

	get beforeWorkspaceProductModel(): IWorkspaceProductSectionals {
		const {
			orientation = '',
			workspaceProductJoinableModelsByOrientation = {},
		} = this.firstWorkspaceProductModel;

		const perpendicularOrientations = perpendicularOrientationsMap[orientation as keyof typeof perpendicularOrientationsMap];

		const firstWorkspaceProductJoinableModel = perpendicularOrientations
			.map((perpendicularOrientation = '') => workspaceProductJoinableModelsByOrientation[perpendicularOrientation])
			.filter(Boolean)
			.find(({ prevWorkspaceProductModel }) => prevWorkspaceProductModel);

		const { prevWorkspaceProductModel = null } = firstWorkspaceProductJoinableModel || {};

		return prevWorkspaceProductModel;
	}

	get firstWorkspaceProductModel(): IWorkspaceProductSectionals {
		const [firstWorkspaceProductModel] = this.workspaceProductModels;

		return firstWorkspaceProductModel;
	}

	get firstWorkspaceProductModelInChunk(): IWorkspaceProductSectionals {
		const [firstWorkspaceProductModelInChunk] = this.workspaceProductModelsWithAdjacentWorkspaceProductModels;

		return firstWorkspaceProductModelInChunk;
	}

	get isDepthWorkspaceProductsChunkModel(): boolean {
		return ['E', 'W'].includes(this.workspaceProductsChunkOrientation);
	}

	get isReversedDirection(): boolean {
		const {
			workspaceProductJoinableModelE: {
				prevWorkspaceProductModel: prevWorkspaceProductModelE = null,
			} = {},
			workspaceProductJoinableModelN: {
				prevWorkspaceProductModel: prevWorkspaceProductModelN = null,
			} = {},
		} = this.firstWorkspaceProductModel;

		const {
			workspaceProductJoinableModelN: {
				nextWorkspaceProductModel: workspaceProductModelN = null,
			} = {},
			workspaceProductJoinableModelW: {
				nextWorkspaceProductModel: workspaceProductModelW = null,
			} = {},
		} = this.firstWorkspaceProductModelInChunk || {};

		if (this.workspaceProductModelsWithAdjacentWorkspaceProductModels.length > 1) {
			const secondWorkspaceProductModelInChunk = this.workspaceProductModelsWithAdjacentWorkspaceProductModels[1];

			return Boolean((this.isDepthWorkspaceProductsChunkModel && workspaceProductModelN === secondWorkspaceProductModelInChunk) || (this.isWidthWorkspaceProductsChunkModel && workspaceProductModelW === secondWorkspaceProductModelInChunk));
		}

		return Boolean((this.isDepthWorkspaceProductsChunkModel && prevWorkspaceProductModelE) || (this.isWidthWorkspaceProductsChunkModel && prevWorkspaceProductModelN));
	}

	get isWidthWorkspaceProductsChunkModel(): boolean {
		return ['N', 'S'].includes(this.workspaceProductsChunkOrientation);
	}

	get lastWorkspaceProductModel(): IWorkspaceProductSectionals {
		return this.workspaceProductModels[this.workspaceProductModels.length - 1];
	}

	get lastWorkspaceProductModelInChunk(): IWorkspaceProductSectionals {
		return this.workspaceProductModelsWithAdjacentWorkspaceProductModels[this.workspaceProductModelsWithAdjacentWorkspaceProductModels.length - 1];
	}

	get workspaceProductModelsWithAdjacentWorkspaceProductModels(): Array<IWorkspaceProductSectionals> {
		if (this.isAdditionalWorkspaceProductsChunk || this.isForcedWorkspaceProductsChunk) {
			return this.workspaceProductModels;
		}

		return [this.beforeWorkspaceProductModel, ...this.workspaceProductModels, this.afterWorkspaceProductModel].filter(Boolean);
	}

	get workspaceProductsChunkDepth(): number {
		const minDepthWorkspaceProduct = minBy(this.workspaceProductModelsWithAdjacentWorkspaceProductModels, ({ workspaceProductDepth = 0 }) => workspaceProductDepth) || {};

		const { workspaceProductDepth: minWorkspaceProductDepth = 0 } = minDepthWorkspaceProduct;

		if (this.isWidthWorkspaceProductsChunkModel) {
			return minWorkspaceProductDepth;
		}

		return this.workspaceProductModelsWithAdjacentWorkspaceProductModels.reduce((accumulatedWorkspaceProductsChunkDepth = 0, { workspaceProductDepth = 0 }: IWorkspaceProductSectionals) => {
			return accumulatedWorkspaceProductsChunkDepth + workspaceProductDepth;
		}, 0);
	}

	get workspaceProductsChunkWidth(): number {
		const minWidthWorkspaceProduct = minBy(this.workspaceProductModelsWithAdjacentWorkspaceProductModels, ({ workspaceProductWidth = 0 }) => workspaceProductWidth) || {};

		const { workspaceProductWidth: minWorkspaceProductWidth = 0 } = minWidthWorkspaceProduct;

		if (this.isDepthWorkspaceProductsChunkModel) {
			return minWorkspaceProductWidth;
		}

		return this.workspaceProductModelsWithAdjacentWorkspaceProductModels.reduce((accumulatedWorkspaceProductsChunkWidth = 0, { workspaceProductWidth = 0 }: IWorkspaceProductSectionals) => {
			return accumulatedWorkspaceProductsChunkWidth + workspaceProductWidth;
		}, 0);
	}
}

export const WorkspaceProductsChunkSectionalsModelFactory = ({
	create: (data: any = {}) => {
		return new WorkspaceProductsChunkSectionals(data);
	},
});
