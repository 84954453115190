import { computed, makeObservable } from 'mobx';

import {
	highestComfortRatings,
	lowestComfortRatings,
	meterDataMap,
	meterTitles,
} from '~/product/common/comfort-spectrum/ComfortSpectrum.constants';
import { inRange } from '~/util/inRange';

class ComfortSpectrumMeter {
	ratingType

	ratingValue

	constructor() {
		makeObservable(this, {
			comfortRating: computed,
			highestComfortRating: computed,
			lowestComfortRating: computed,
			meterValuesData: computed,
			meterValueData: computed,
			meterValue: computed,
			title: computed,
		});
	}

	get comfortRating() {
		const { comfortRating = '' } = this.meterValueData;

		return comfortRating;
	}

	get highestComfortRating() {
		return highestComfortRatings[this.ratingType] || '';
	}

	get lowestComfortRating() {
		return lowestComfortRatings[this.ratingType] || '';
	}

	get meterValuesData() {
		return meterDataMap[this.ratingType] || [];
	}

	get meterValueData() {
		const meterValueDataByType = this.meterValuesData.find(({ type }) => type === this.ratingValue);

		const meterValueByRange = this.meterValuesData.find(({
			min = 0,
			max = 0,
		}) => {
			return inRange(this.ratingValue, min, (max + 1));
		});
		return meterValueDataByType || meterValueByRange || {};
	}

	get meterValue() {
		const { meterValue = 0 } = this.meterValueData;

		return meterValue;
	}

	get title() {
		return meterTitles[this.ratingType] || '';
	}
}

export const ComfortSpectrumMeterModelFactory = ({
	create: ({
		ratingType = '',
		ratingValue = '',
	}) => {
		const comfortSpectrumMeter = new ComfortSpectrumMeter();

		Object.assign(comfortSpectrumMeter, {
			ratingType,
			ratingValue,
		});

		return comfortSpectrumMeter;
	},
});
