import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { SelectorHeadingFabricMoreOptions } from '~/product/common/selector/Components/fabric/SelectorHeadingFabricMoreOptions';
import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector.module.scss';

export const SelectorHeadingFabric = observer((props) => {
	const {
		isActive = true,
		selectedSelector: {
			label = '',
			selectedSelectorValue: {
				statusToRender = '',
				subtitle = '',
				title = '',
			} = {},
		} = {},
	} = props;

	return (
		<>
			<div
				className={`selector-heading ${styles['selector-heading']} selector-heading-fabric`}
				data-qa={`${kebabCase(label)}-selector-heading`}
			>
				<SelectorHeadingLabel label={label} />
				<span
					className={styles['selector-heading-title']}
					data-qa={`${kebabCase(label)}-selector-heading-title`}
				>
					{title}{subtitle && ` (${subtitle})`}
					{
						isActive && statusToRender && (
							<span className={
								classNames(styles['selector-heading-status'], {
									[styles['selector-heading-status-new']]: statusToRender === 'New',
								})
							}>{statusToRender}</span>
						)
					}
				</span>
			</div>
			{
				!isActive && (
					<SelectorHeadingFabricMoreOptions />
				)
			}
		</>
	);
});
