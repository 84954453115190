import { computed, observable, makeObservable } from 'mobx';

class SelectorOptions {
	isValid

	label

	renderer

	selected

	selectorValues

	type

	wasActive

	constructor() {
		makeObservable(this, {
			isValid: observable,
			selected: observable,
			wasActive: observable,
			childSelectors: computed,
			defaultSelectorValue: computed,
			selectedSelectorValue: computed,
		});
	}

	get childSelectors() {
		const { selectors = [] } = this.selectorValues[0] || {};

		return selectors;
	}

	get defaultSelectorValue() {
		return this.selectorValues.find(({ answer = '' }) => ['N', 'NA'].includes(answer));
	}

	get selectedSelectorValue() {
		return this.selectorValues.find(({ selected = false }) => selected);
	}
}

export const SelectorOptionsModelFactory = ({
	create: ({
		isValid = false,
		label = '',
		renderer = '',
		selected = false,
		selectorValues = [],
		type = '',
		wasActive = false,
	}) => {
		const selectorOptions = new SelectorOptions();

		Object.assign(selectorOptions, {
			isValid,
			label,
			renderer,
			selected,
			selectorValues,
			type,
			wasActive,
		});

		return selectorOptions;
	},
});
