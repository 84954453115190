import cn from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import type { ISelectorProps } from '~/product/casegoods/selector/Types/SelectorCasegoods.types';

import { SelectorValueFrameButton } from '~/product/casegoods/selector/Components/frame/SelectorValueFrameButton';
import { SelectorValueFrame } from '~/product/casegoods/selector/Components/frame/SelectorValueFrame';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/selector/frame/selector-frame.module.scss';

export const SelectorDraggable = observer(({
	selectedSelector: {
		selectorValuesToRender,
	},
}: ISelectorProps) => {
	const {
		selectorConfigModel: {
			settings: {
				frameSelectorValuesPerRow = 0,
				isDraggableOnSmall = false,
			} = {},
		} = {},
	} = useProductContext();

	return (
		<div className={styles['selector-values-group-frame']}>
			{
				selectorValuesToRender.map(({
					productModels = [],
					selectedSelector: selectedDepthSelector = {},
					title = '',
				}, index = 0) => {
					const [firstProductModel = {}] = productModels;

					const { hasAllowAllSlot = false } = firstProductModel;

					const dataQaSuffix = hasAllowAllSlot ? 'ALL' : 'NONE';

					return (
						<div
							className={styles[`selector-value-frame-container-${frameSelectorValuesPerRow}`]}
							key={`selector-value-${index}`}
						>
							<div
								className={
									cn('md:tw-block', {
										'tw-hidden': !isDraggableOnSmall,
									})
								}
								data-qa={`selector-value-frame-container-md-${dataQaSuffix}`}
							>
								<SelectorValueFrame
									isDraggable
									productModel={firstProductModel}
									productModels={productModels}
									selectedDepthSelector={selectedDepthSelector}
									title={title}
								/>
							</div>
							{!isDraggableOnSmall &&
								<div
									className="md:tw-hidden"
									data-qa={`selector-value-frame-container-${dataQaSuffix}`}
								>
									<SelectorValueFrameButton
										productModel={firstProductModel}
										productModels={productModels}
										selectedDepthSelector={selectedDepthSelector}
										title={title}
									/>
								</div>
							}
						</div>
					);
				})
			}
		</div>
	);
});
