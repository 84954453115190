import { observer } from 'mobx-react';
import React from 'react';

import { WorkspaceProductInsertCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-insert/WorkspaceProductInsertCasegoods';

import styles from '#/product/casegoods/workspace/product-slot/workspace-product-slot.module.scss';

export const WorkspaceProductSlotCasegoods = observer((props) => {
	const {
		imageScale = 0,
		slotHeightPercentage = 0,
		slotModel = {},
		slotModel: {
			hasInsertProductModel = false,
			insertProductModel = {},
		} = {},
		slotWidthPercentage = 0,
	} = props;

	return (
		<div
			className={styles['workspace-product-slot']}
			style={{
				height: `${slotHeightPercentage}%`,
				width: `${slotWidthPercentage}%`,
			}}
		>
			{
				hasInsertProductModel && (
					<WorkspaceProductInsertCasegoods
						imageScale={imageScale}
						insertProductModel={insertProductModel}
						slotModel={slotModel}
					/>
				)
			}
		</div>
	);
});
