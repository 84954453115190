import { observer } from 'mobx-react';
import React from 'react';

import { SelectorValueLayoutDraggable } from '~/product/sectionals/selector/Components/layout/SelectorValueLayoutDraggable';

import styles from '~/product/sectionals/selector/Components/layout/SelectorLayout.module.scss';

export const SelectorValueLayout = observer((props) => {
	const {
		selectorValue = {},
		selectorValue: {
			subtitle = '',
			title = '',
		} = {},
	} = props;

	const imageScale = 11.5;

	return (
		<div className={`${styles['selector-value-layout']} ${styles['selector-value-layout-3']}`}>
			<SelectorValueLayoutDraggable
				imageScale={imageScale}
				selectorValue={selectorValue}
			/>
			<div className={styles['selector-value-layout-text']}>
				<span
					className={styles['selector-value-layout-title']}
					data-qa="layout-selector-value-title"
				>
					{title}
				</span>
				{
					subtitle && (
						<span
							className={styles['selector-value-layout-subtitle']}
							data-qa="layout-selector-value-subtitle"
						>
							{subtitle}
						</span>
					)
				}
			</div>
		</div>
	);
});
