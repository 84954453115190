import React from 'react';

import { SelectorFiltersFacet } from '~/product/common/selector/Components/filters/SelectorFiltersFacet';
import styles from '#/product/standard/selector/selector-filters.module.scss';

export const SelectorFiltersFacetsChunk = (props = {}) => {
	const {
		facetsChunk = [],
		filtersStore = {},
	} = props;

	return (
		<div className={styles['selector-filters-facets-chunk']}>
			{
				facetsChunk.map((facetModel = {}, index) => {
					return (
						<SelectorFiltersFacet
							facetModel={facetModel}
							filtersStore={filtersStore}
							key={`filters-facet-${index}`}
						/>
					);
				})
			}
		</div>
	);
};
