import { computed, makeObservable } from 'mobx';

import { s7ImagePath } from '~/global/global.constants';

class ConfigurationOverride {
	defaultConfig

	defaultProduct

	imageData

	constructor() {
		makeObservable(this, {
			articleNumber: computed,
			defaultAnswers: computed,
			hasConfigurationOverride: computed,
			hasDefaultAnswers: computed,
			imageUrl: computed,
		});
	}

	get articleNumber() {
		const { articleNumber = '' } = this.defaultProduct;

		return articleNumber;
	}

	get defaultAnswers() {
		return this.defaultConfig;
	}

	get hasConfigurationOverride() {
		return Boolean(this.articleNumber) || this.hasDefaultAnswers;
	}

	get hasDefaultAnswers() {
		return Boolean(this.defaultAnswers.length);
	}

	get imageUrl() {
		const imageData = this.imageData[this.articleNumber] || {};

		const { imageUrl = `${s7ImagePath}/noimage` } = imageData;

		return imageUrl;
	}
}

export const ConfigurationOverrideModelFactory = ({
	create: ({
		defaultConfig = [],
		defaultProduct = {},
		imageData = {},
	} = {}) => {
		const configurationOverride = new ConfigurationOverride();

		Object.assign(configurationOverride, {
			defaultConfig,
			defaultProduct,
			imageData,
		});

		return configurationOverride;
	},
});
