import { SelectorFabric } from '~/product/common/selector/Components/fabric/SelectorFabric';
import { SelectorDropDown } from '~/product/common/selector/Components/drop-down/SelectorDropDown';
import { SelectorRadioVertical } from '~/product/common/selector/Components/radio-vertical/SelectorRadioVertical';
import { SelectorMediumImage } from '~/product/common/selector/Components/medium-image/SelectorMediumImage';
import { SelectorNone } from '~/product/common/selector/Components/none/SelectorNone';
import { SelectorSmallImage } from '~/product/common/selector/Components/small-image/SelectorSmallImage';
import { SelectorStatic } from '~/product/common/selector/Components/static/SelectorStatic';
import { SelectorSwatch } from '~/product/common/selector/Components/swatch/SelectorSwatch';
import { SelectorCustomMirrorDimension } from '~/product/common/selector/Components/custom-mirror-dimension/SelectorCustomMirrorDimension';
import { SelectorCustomRugDimension } from '~/product/common/selector/Components/custom-rug-dimension/SelectorCustomRugDimension';
import { SelectorCustomTableDimension } from '~/product/common/selector/Components/custom-table-dimension/SelectorCustomTableDimension';
import { SelectorTwoColumn } from '~/product/common/selector/Components/two-column/SelectorTwoColumn';

export const selectorComponents = {
	// we can probably deprecate all of the CUSTOM_XXX renderer keys
	CUSTOM_DROP_DOWN: SelectorDropDown,
	CUSTOM_FABRIC_LEATHER: SelectorFabric,
	// we can probably deprecate CUSTOM_FABRIC_LEATHER_QUESTION_AGGREGATOR as it is handled by FABRIC now
	CUSTOM_FABRIC_LEATHER_QUESTION_AGGREGATOR: SelectorFabric,
	CUSTOM_SWATCH: SelectorSwatch,
	DROP_DOWN: SelectorDropDown,
	FABRIC: SelectorFabric,
	// we can probably deprecate FABRIC_QUESTION_AGGREGATOR as it is handled by FABRIC now
	FABRIC_QUESTION_AGGREGATOR: SelectorFabric,
	MEDIUM_IMAGE: SelectorMediumImage,
	NONE: SelectorNone,
	RADIO_VERTICAL: SelectorRadioVertical,
	SMALL_IMAGE: SelectorSmallImage,
	STATIC: SelectorStatic,
	SWATCH: SelectorSwatch,
	CUSTOM_MIRROR_DIMENSION: SelectorCustomMirrorDimension,
	CUSTOM_RUG_DIMENSION: SelectorCustomRugDimension,
	CUSTOM_TABLE_DIMENSION: SelectorCustomTableDimension,
	TWO_COLUMN: SelectorTwoColumn,
};
