import React from 'react';
import { observer } from 'mobx-react';

import { Field } from '~/util/formz/Components/fields/Field';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

export const CuralateUploadInfo = observer((props) => {
	const {
		displayName = {},
		emailAddress = {},
	} = props.store?.form?.fields || {};

	return (
		<div className={styles['curalate-upload-info']}>
			<fieldset>
				<div className={styles['upload-info-fieldset']}>
					<legend className="tw-heading-5 tw-font-normal tw-mb-4">All information is required.</legend>
					<Field field={displayName} />
					<Field field={emailAddress} />
				</div>
				<div>
					<p>
						{'By submitting your media, you agree to Room & Board’s '}
						<a href="/terms-of-use/" target="_blank" rel="noreferrer noopener">Terms of Use</a>.
					</p>
				</div>
			</fieldset>
		</div>
	);
});
