import React from 'react';
import dynamic from 'next/dynamic';

import { bookcaseConstructionData } from '~/product/common/promos/Data/BookcaseConstruction.data';
import { mattressConstructionData } from '~/product/common/promos/Data/MattressConstruction.data';
import { officeChairsFeaturesData } from '~/product/common/promos/Data/OfficeChairFeatures.data';
import { rugConstructionData } from '~/product/common/promos/Data/RugConstruction.data';

const ConstructionBookcase = dynamic(() => import('./ConstructionBookcase').then(mod => mod.ConstructionBookcase), { ssr: false });
const ConstructionMattress = dynamic(() => import('./ConstructionMattress').then(mod => mod.ConstructionMattress), { ssr: false });
const ConstructionRug = dynamic(() => import('./ConstructionRug').then(mod => mod.ConstructionRug), { ssr: false });
const DimensionPromoWillItFit = dynamic(() => import('./DimensionPromoWillItFit').then(mod => mod.DimensionPromoWillItFit), { ssr: false });
const DimensionPromoBenchingPDF = dynamic(() => import('./DimensionPromoBenchingPDF').then(mod => mod.DimensionPromoBenchingPDF), { ssr: false });
const DimensionPromoSectionalPDF = dynamic(() => import('./DimensionPromoSectionalPDF').then(mod => mod.DimensionPromoSectionalPDF), { ssr: false });
const DimensionPromoVanitiesPDF = dynamic(() => import('./DimensionPromoVanitiesPDF').then(mod => mod.DimensionPromoVanitiesPDF), { ssr: false });
const FeaturesOfficeChair = dynamic(() => import('./FeaturesOfficeChair').then(mod => mod.FeaturesOfficeChair), { ssr: false });
const OrderSwatchExamplePromo = dynamic(() => import('./OrderSwatchExamplePromo').then(mod => mod.OrderSwatchExamplePromo), { ssr: false });
const SelectorPromoCustomBookcase = dynamic(() => import('./SelectorPromoCustomBookcase').then(mod => mod.SelectorPromoCustomBookcase), { ssr: false });
const SelectorPromoCustomCabinet = dynamic(() => import('./SelectorPromoCustomCabinet').then(mod => mod.SelectorPromoCustomCabinet), { ssr: false });
const SelectorPromoCustomSectionals = dynamic(() => import('./SelectorPromoCustomSectionals').then(mod => mod.SelectorPromoCustomSectionals), { ssr: false });
const SelectorPromoCustomSectionalsStatic = dynamic(() => import('./SelectorPromoCustomSectionalsStatic').then(mod => mod.SelectorPromoCustomSectionalsStatic), { ssr: false });
const SelectorPromoCustomSize = dynamic(() => import('./SelectorPromoCustomSize').then(mod => mod.SelectorPromoCustomSize), { ssr: false });
const SummaryPromoBeddingBasics = dynamic(() => import('./SummaryPromoBeddingBasics').then(mod => mod.SummaryPromoBeddingBasics), { ssr: false });
const SummaryPromoCustomBeam = dynamic(() => import('./SummaryPromoCustomBeam').then(mod => mod.SummaryPromoCustomBeam), { ssr: false });
const SummaryPromoCustomBookcase = dynamic(() => import('./SummaryPromoCustomBookcase').then(mod => mod.SummaryPromoCustomBookcase), { ssr: false });
const SummaryPromoCustomCabinet = dynamic(() => import('./SummaryPromoCustomCabinet').then(mod => mod.SummaryPromoCustomCabinet), { ssr: false });
const SummaryPromoCustomSize = dynamic(() => import('./SummaryPromoCustomSize').then(mod => mod.SummaryPromoCustomSize), { ssr: false });
const SummaryPromoCustomSlim = dynamic(() => import('./SummaryPromoCustomSlim').then(mod => mod.SummaryPromoCustomSlim), { ssr: false });
const SummaryPromoCustomTaylor = dynamic(() => import('./SummaryPromoCustomTaylor').then(mod => mod.SummaryPromoCustomTaylor), { ssr: false });
const SummaryPromoChooseMattress = dynamic(() => import('./SummaryPromoChooseMattress').then(mod => mod.SummaryPromoChooseMattress), { ssr: false });
const SummaryPromoDynamicFabric = dynamic(() => import('./SummaryPromoDynamicFabric').then(mod => mod.SummaryPromoDynamicFabric), { ssr: false });
const SummaryPromoDynamicLeather = dynamic(() => import('./SummaryPromoDynamicLeather').then(mod => mod.SummaryPromoDynamicLeather), { ssr: false });
const SummaryPromoDiningMeasureGuide = dynamic(() => import('./SummaryPromoDiningMeasureGuide').then(mod => mod.SummaryPromoDiningMeasureGuide), { ssr: false });
const SummaryPromoFindMattress = dynamic(() => import('./SummaryPromoFindMattress').then(mod => mod.SummaryPromoFindMattress), { ssr: false });
const SummaryPromoMaterialSamples = dynamic(() => import('./SummaryPromoMaterialSamples').then(mod => mod.SummaryPromoMaterialSamples), { ssr: false });
const SummaryPromoOutdoorCovers = dynamic(() => import('./SummaryPromoOutdoorCovers').then(mod => mod.SummaryPromoOutdoorCovers), { ssr: false });
const SummaryPromoFabricGuide = dynamic(() => import('./SummaryPromoFabricGuide').then(mod => mod.SummaryPromoFabricGuide), { ssr: false });
const SummaryPromoLeatherGuide = dynamic(() => import('./SummaryPromoLeatherGuide').then(mod => mod.SummaryPromoLeatherGuide), { ssr: false });
const SummaryPromoMattressGuarantee = dynamic(() => import('./SummaryPromoMattressGuarantee').then(mod => mod.SummaryPromoMattressGuarantee), { ssr: false });
const SummaryPromoReplacementBulbs = dynamic(() => import('./SummaryPromoReplacementBulbs').then(mod => mod.SummaryPromoReplacementBulbs), { ssr: false });
const SummaryPromoOutdoorMaterials = dynamic(() => import('./SummaryPromoOutdoorMaterials').then(mod => mod.SummaryPromoOutdoorMaterials), { ssr: false });
const SummaryPromoPillowGuide = dynamic(() => import('./SummaryPromoPillowGuide').then(mod => mod.SummaryPromoPillowGuide), { ssr: false });
const SummaryPromoRugSamples = dynamic(() => import('./SummaryPromoRugSamples').then(mod => mod.SummaryPromoRugSamples), { ssr: false });
const SummaryPromoRugSize = dynamic(() => import('./SummaryPromoRugSize').then(mod => mod.SummaryPromoRugSize), { ssr: false });
const SummaryPromoSectionalIdeas = dynamic(() => import('./SummaryPromoSectionalIdeas').then(mod => mod.SummaryPromoSectionalIdeas), { ssr: false });
const SummaryPromoSleeperSheets = dynamic(() => import('./SummaryPromoSleeperSheets').then(mod => mod.SummaryPromoSleeperSheets), { ssr: false });
const SummaryPromoSwatches = dynamic(() => import('./SummaryPromoSwatches').then(mod => mod.SummaryPromoSwatches), { ssr: false });
const SummaryPromoSwatchesDynamic = dynamic(() => import('./SummaryPromoSwatchesDynamic').then(mod => mod.SummaryPromoSwatchesDynamic), { ssr: false });
const SummaryPromoTopMaterialGuide = dynamic(() => import('./SummaryPromoTopMaterialGuide').then(mod => mod.SummaryPromoTopMaterialGuide), { ssr: false });
const SummaryPromoPaidRugSample = dynamic(() => import('./SummaryPromoPaidRugSample').then(mod => mod.SummaryPromoPaidRugSample), { ssr: false });

export const PromoComponents = {
	CST_BKCS_FEATURES_KEATON: (props) => {
		return <ConstructionBookcase {...props} details={bookcaseConstructionData.CST_BKCS_FEATURES_KEATON} />;
	},
	CST_BKCS_FEATURES_WOODWIND: (props) => {
		return <ConstructionBookcase {...props} details={bookcaseConstructionData.CST_BKCS_FEATURES_WOODWIND} />;
	},
	CST_BKCS_FEATURES_WOODWIND_OPEN: (props) => {
		return <ConstructionBookcase {...props} details={bookcaseConstructionData.CST_BKCS_FEATURES_WOODWIND_OPEN} />;
	},
	FEATURES_TAYLOR_BUILDER: (props) => {
		return <ConstructionBookcase {...props} details={bookcaseConstructionData.FEATURES_TAYLOR_BUILDER} />;
	},
	DIM_FIT: (props) => {
		return <DimensionPromoWillItFit {...props} />;
	},
	DIM_BENCHING_PDF: (props) => {
		return <DimensionPromoBenchingPDF {...props} />;
	},
	DIM_SECTIONALPDF: (props) => {
		return <DimensionPromoSectionalPDF {...props} />;
	},
	DIM_VANITIES_PDF: (props) => {
		return <DimensionPromoVanitiesPDF {...props} />;
	},
	FREE_DELIVERY: (props) => {
		return <OrderSwatchExamplePromo {...props} />;
	},
	MATTRESS_BASIC: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_BASIC} />;
	},
	MATTRESS_BASIC_BUNK: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_BASIC_BUNK} />;
	},
	MATTRESS_BASIC_CRIB: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_BASIC_CRIB} />;
	},
	MATTRESS_ESSENTIAL_BUNK: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_ESSENTIAL_BUNK} />;
	},
	MATTRESS_ESSENTIAL_HYBRID: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_ESSENTIAL_HYBRID} />;
	},
	MATTRESS_ESSENTIAL_SOFT_HYBRID: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_ESSENTIAL_SOFT_HYBRID} />;
	},
	MATTRESS_LUXURY_HYBRID: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_LUXURY_HYBRID} />;
	},
	MATTRESS_LUXURY_SERENE_FOAM: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_LUXURY_SERENE_FOAM} />;
	},
	MATTRESS_NATURAL_LATEX_COIL_HYBRID: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_NATURAL_LATEX_COIL_HYBRID } />;
	},
	MATTRESS_NATURAL_LATEX_SPRING_CRIB: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_NATURAL_LATEX_SPRING_CRIB} />;
	},
	MATTRESS_NATURAL_ORGANIC_CRIB: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_NATURAL_ORGANIC_CRIB} />;
	},
	MATTRESS_NATURAL_ORGANIC_LATEX: (props) => {
		return <ConstructionMattress {...props} details={mattressConstructionData.MATTRESS_NATURAL_ORGANIC_LATEX } />;
	},
	MISSING: ({ promoKey = '' }) => {
		return <div>Missing component for message key: "{promoKey}"</div>;
	},
	OFFICE_CHR_FEATURES_CHORAL: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_CHORAL} />;
	},
	OFFICE_CHR_FEATURES_CORA: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_CORA} />;
	},
	OFFICE_CHR_FEATURES_CYNARA: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_CYNARA} />;
	},
	OFFICE_CHR_FEATURES_DIFFRIENT_WORLD: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_DIFFRIENT_WORLD} />;
	},
	OFFICE_CHR_FEATURES_FINORA: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_FINORA} />;
	},
	OFFICE_CHR_FEATURES_FREEDOM: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_FREEDOM} />;
	},
	OFFICE_CHR_FEATURES_LIBERTY_OCEAN: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_LIBERTY_OCEAN} />;
	},
	OFFICE_CHR_FEATURES_LIRA: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_LIRA} />;
	},
	OFFICE_CHR_FEATURES_LUCE: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_LUCE} />;
	},
	OFFICE_CHR_FEATURES_LW: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_LW} />;
	},
	OFFICE_CHR_FEATURES_NICO: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_NICO} />;
	},
	OFFICE_CHR_FEATURES_PATH: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_PATH} />;
	},
	OFFICE_CHR_FEATURES_PLIMODE: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_PLIMODE} />;
	},
	OFFICE_CHR_FEATURES_RUNA: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_RUNA} />;
	},
	OFFICE_CHR_FEATURES_SYLPHY: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_SYLPHY} />;
	},
	OFFICE_CHR_FEATURES_TENLEY: (props) => {
		return <FeaturesOfficeChair {...props} details={officeChairsFeaturesData.OFFICE_CHR_FEATURES_TENLEY} />;
	},
	ORDER_SWATCH_EXAMPLE: (props) => {
		return <SummaryPromoSwatches {...props} />;
	},
	RUGS_HAND_KNOTTED: (props) => {
		return <ConstructionRug {...props} details={rugConstructionData.RUGS_HAND_KNOTTED} />;
	},
	RUGS_HAND_TUFTED: (props) => {
		return <ConstructionRug {...props} details={rugConstructionData.RUGS_HAND_TUFTED} />;
	},
	RUGS_HAND_WOVEN: (props) => {
		return <ConstructionRug {...props} details={rugConstructionData.RUGS_HAND_WOVEN} />;
	},
	RUGS_MACHINE_LOOMED_HAND_FINISH: (props) => {
		return <ConstructionRug {...props} details={rugConstructionData.RUGS_MACHINE_LOOMED_HAND_FINISH} />;
	},
	SEL_CSTBOOKCASE: (props) => {
		return <SelectorPromoCustomBookcase {...props} />;
	},
	SEL_CSTCABINET: (props) => {
		return <SelectorPromoCustomCabinet {...props} />;
	},
	SEL_CSTSECTIONAL: (props) => {
		return <SelectorPromoCustomSectionals {...props} />;
	},
	SEL_CSTSECTIONAL_STATIC: (props) => {
		return <SelectorPromoCustomSectionalsStatic {...props} />;
	},
	SEL_CSTCUSTOMSIZE: (props) => {
		return <SelectorPromoCustomSize {...props} />;
	},
	SEL_PRECONSECTIONAL: (props) => {
		return <SelectorPromoCustomSectionals {...props} />;
	},
	SUM_C_BASICS: (props) => {
		return <SummaryPromoBeddingBasics {...props} />;
	},
	SUM_C_BULBS: (props) => {
		return <SummaryPromoReplacementBulbs {...props} />;
	},
	SUM_C_MATTRESSES: (props) => {
		return <SummaryPromoFindMattress {...props} />;
	},
	SUM_C_OUT_COVER: (props) => {
		return <SummaryPromoOutdoorCovers {...props} />;
	},
	SUM_CONFIG_BEAM: (props) => {
		return <SummaryPromoCustomBeam {...props} />;
	},
	SUM_CONFIG_TAYLOR: (props) => {
		return <SummaryPromoCustomTaylor {...props} />;
	},
	SUM_CSTBOOKCASE: (props) => {
		return <SummaryPromoCustomBookcase {...props} />;
	},
	SUM_CSTCABINET: (props) => {
		return <SummaryPromoCustomCabinet {...props} />;
	},
	SUM_CSTCUSTOMSIZE: (props) => {
		return <SummaryPromoCustomSize {...props} />;
	},
	SUM_CONFIG_SLIM: (props) => {
		return <SummaryPromoCustomSlim {...props} />;
	},
	SUM_FAB_DYNAMIC: (props) => {
		return <SummaryPromoDynamicFabric {...props} />;
	},
	SUM_GD_FABRIC: (props) => {
		return <SummaryPromoFabricGuide {...props} />;
	},
	SUM_GD_LEATHER: (props) => {
		return <SummaryPromoLeatherGuide {...props} />;
	},
	SUM_GD_PILLOW: (props) => {
		return <SummaryPromoPillowGuide {...props} />;
	},
	SUM_LTHR_DYNAMIC: (props) => {
		return <SummaryPromoDynamicLeather {...props} />;
	},
	SUM_SW_MATERIALS_DYNAMIC: (props) => {
		return <SummaryPromoMaterialSamples {...props} />;
	},
	SUM_GD_MATTRESS: (props) => {
		return <SummaryPromoChooseMattress {...props} />;
	},
	SUM_GD_MEASUREDINE: (props) => {
		return <SummaryPromoDiningMeasureGuide {...props} />;
	},
	SUM_GD_OUTMAT: (props) => {
		return <SummaryPromoOutdoorMaterials {...props} />;
	},
	SUM_GD_SCTLIDEAS: (props) => {
		return <SummaryPromoSectionalIdeas {...props} />;
	},
	SUM_SMP_RUGS: (props) => {
		return <SummaryPromoRugSamples {...props} />;
	},
	SUM_GD_RUGSIZE: (props) => {
		return <SummaryPromoRugSize {...props} />;
	},
	SUM_GD_TOPMATERIAL: (props) => {
		return <SummaryPromoTopMaterialGuide {...props} />;
	},
	SUM_MATT_GUARANTEE: (props) => {
		return <SummaryPromoMattressGuarantee {...props} />;
	},
	SUM_SW_SWATCHES: (props) => {
		return <SummaryPromoSwatches {...props} />;
	},
	SUM_SLEEPER_SHEETS: (props) => {
		return <SummaryPromoSleeperSheets {...props} />;
	},
	SUM_SW_SWATCHES_DYNAMIC: (props) => {
		return <SummaryPromoSwatchesDynamic {...props} />;
	},
	SUMMARY_PAID_RUG_SAMPLE: (props) => {
		return <SummaryPromoPaidRugSample {...props} />;
	},
};
