import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { addToUrl } from '~/util/addToUrl';
import { kebabCase } from '~/util/kebabCase';

import styles from '~/product/sectionals/selector/Components/swatch/selector-swatch.module.scss';

export const SelectorValueSwatch = observer((props = {}) => {
	const {
		isActive = false,
		label = '',
		selectorValue,
		selectorValue: {
			imageUrl = '',
			selected,
			title = '',
		},
		isAggregatedQuestionSelector = false,
	} = props;

	const {
		mediaSetDetailStore = {},
		selectorConfigStore = {},
		workspaceModel: {
			workspaceProductModels = [],
		} = {},
	} = useProductContext();

	function handleChange() {
		mediaSetDetailStore.selectMediaModelHead();

		selectorConfigStore.selectSelectorValue(selectorValue, isAggregatedQuestionSelector, workspaceProductModels);
	}

	return (
		<label
			className={
				classNames(styles['selector-value-swatch'], {
					[styles['selector-value-swatch-selected']]: selected,
				})
			}
			data-qa={`${kebabCase(label)}-selector-value`}
		>
			<S7Image
				alt=""
				className={styles['selector-value-swatch-image']}
				height="70"
				priority={isActive}
				src={addToUrl(imageUrl, '$swsmall$')}
				width="70"
			/>
			<div className={styles['selector-value-swatch-text']}>
				<span
					className={styles['selector-value-swatch-title']}
					data-qa={`${kebabCase(label)}-selector-value-title`}
				>
					{title}
				</span>
			</div>
			<input
				checked={selected}
				className="tw-sr-only"
				data-tr-link-event-track={false}
				onChange={handleChange}
				type="radio"
			/>
		</label>
	);
});
