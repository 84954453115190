import { computed, observable, makeObservable } from 'mobx';

class SelectorValueInsertOptions {
	dependentQuestionAnswers

	overrideQuestionAnswers

	parentLabel

	parentType

	questionAnswers

	selected

	selectors

	title

	upcharge

	woodUpcharges

	constructor() {
		makeObservable(this, {
			selected: observable,
			answer: computed,
			hasOverrideQuestionAnswers: computed,
			question: computed,
			selectedSelector: computed,
			showOverrideWarning: computed,
		});
	}

	get answer() {
		const answer = this.questionAnswers[this.question];

		return answer.answerKey;
	}

	get hasOverrideQuestionAnswers() {
		return Boolean(Object.keys(this.overrideQuestionAnswers).length);
	}

	get question() {
		return Object.keys(this.questionAnswers)[0] || '';
	}

	get selectedSelector() {
		return this.selectors.find(({ selected }) => selected) || this.selectors[0];
	}

	get showOverrideWarning() {
		return this.question.includes('WIRE_MGT') && this.hasOverrideQuestionAnswers;
	}
}

export const SelectorValueInsertOptionsModelFactory = ({
	create: ({
		dependentQuestionAnswers = {},
		overrideQuestionAnswers = {},
		parentLabel = '',
		parentType = '',
		questionAnswers = {},
		selected = false,
		selectors = [],
		title = '',
		upcharge = '',
		woodUpcharges = {},
	}) => {
		const selectorValueInsertOptions = new SelectorValueInsertOptions();

		Object.assign(selectorValueInsertOptions, {
			dependentQuestionAnswers,
			overrideQuestionAnswers,
			parentLabel,
			parentType,
			questionAnswers,
			selected,
			selectors,
			title,
			upcharge,
			woodUpcharges,
		});

		return selectorValueInsertOptions;
	},
});
