import { observer } from 'mobx-react';
import React, { useRef } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { MediaControlCylindo } from '~/product/common/media-set/Components/MediaControlCylindo';
import { MediaControlCylindoAR } from '~/product/common/media-set/Components/MediaControlCylindoAR';
import { MediaControlCylindoDimensions } from '~/product/common/media-set/Components/MediaControlCylindoDimensions';
import { MediaCylindo } from '~/product/common/media-set/Components/MediaCylindo';
import { MediaControlFullscreenClose, MediaControlFullscreenOpen } from '~/media-set/Components/MediaControlFullscreen';
import { MediaSet } from '~/media-set/Components/MediaSet';
import {
	mediaSetDetailFullscreenProps,
	mediaSetDetailProps,
	mediaSetModalSettings,
	openModalMediaCriteria,
} from '~/product/common/media-set/media-set.constants';

import styles from '#/product/common/media-set/media-set-detail.module.scss';

export const MediaSetDetail = observer(({
	className = '',
	hideMediaCylindo = false,
	mediaComponentsOverride = {},
}) => {
	const {
		magicModal = {},
	} = useGlobalContext();

	// unbox selectedMediaIndex at initialization time - we don't want initialSlide prop to be observable
	const {
		mediaCylindoModel = {},
		mediaCylindoStore = {},
		mediaSetDetailModel = {},
		mediaSetDetailModel: {
			hasMediaModels = false,
			selectedMediaIndex = 0,
		} = {},
		mediaSetDetailStore = {},
		productCommons: {
			printModule = {}
		},
		productGroupModel: {
			name: groupName,
		} = {},
		productModel: {
			longSalesText: productName,
			salesText: shortProductName,
		} = {},
		selectorConfigModel = {},
	} = useProductContext();

	if (!hasMediaModels) {
		return null;
	}

	const mediaControlFullscreenRef = useRef();

	const mediaControlCylindoARRef = useRef();

	const mediaSetDetailRef = useRef();

	const mediaSetOverrideBeforeChange = () => {
		if (mediaCylindoStore.setIsCylindoActive) {
			mediaCylindoStore.setIsCylindoActive(false);
		}

		if (mediaCylindoStore.setIsCylindoARActive) {
			mediaCylindoStore.setIsCylindoARActive(false);
		}

		if (mediaCylindoStore.setIsCylindoDimensionsActive) {
			mediaCylindoStore.setIsCylindoDimensionsActive(false);
		}

		mediaSetDetailStore.setIsMediaSetOverrideActive(false);
	};

	const mediaControlsFullscreen = (
		<>
			<MediaControlCylindo
				mediaCylindoStore={mediaCylindoStore}
				mediaCylindoModel={mediaCylindoModel}
				mediaSetStore={mediaSetDetailStore}
			/>
			<MediaControlCylindoAR
				buttonRef={mediaControlCylindoARRef}
				mediaCylindoStore={mediaCylindoStore}
				mediaCylindoModel={mediaCylindoModel}
				mediaSetStore={mediaSetDetailStore}
			/>
			<MediaControlCylindoDimensions
				mediaCylindoModel={mediaCylindoModel}
				mediaCylindoStore={mediaCylindoStore}
				mediaSetStore={mediaSetDetailStore}
			/>
			<MediaControlFullscreenClose onClick={magicModal.closeModal} />
		</>
	);

	const mediaSetOverrideFullscreen = (
		<MediaCylindo
			hideMediaCylindoARClose={true}
			mediaCylindoId="media-cylindo-fullscreen"
			tooltipDragText="Drag to rotate. Click to zoom."
			tooltipZoomText="Move mouse to pan"
			mediaControlCylindoARRef={mediaControlCylindoARRef}
			mediaCylindoModel={mediaCylindoModel}
			mediaCylindoStore={mediaCylindoStore}
			mediaSetStore={mediaSetDetailStore}
			selectorConfigModel={selectorConfigModel}
			zoom={true}
		/>
	);

	const openFullScreenModal = ({ isMediaMainButtonClick = false }) => {
		// unbox selectedMediaIndex at initialization time - we don't want initialSlide prop to be observable
		const { selectedMediaIndex: selectedMediaIndexFullscreen = 0 } = mediaSetDetailModel;

		magicModal.openModal({
			...mediaSetModalSettings,
			alignToTopOfWindow: true,
			content: {
				children: (
					<div
						aria-label="Product detail media carousel"
						className={styles['media-set-detail-fullscreen']}
						data-qa="media-set-detail-fullscreen"
						role="region"
					>
						<MediaSet
							{...mediaSetDetailFullscreenProps}
							allowPinchZoom={true}
							initialSlide={selectedMediaIndexFullscreen}
							mediaComponentsOverride={mediaComponentsOverride}
							mediaControls={mediaControlsFullscreen}
							mediaSetModel={mediaSetDetailModel}
							mediaSetOverride={mediaSetOverrideFullscreen}
							mediaSetOverrideBeforeChange={mediaSetOverrideBeforeChange}
							mediaSetStore={mediaSetDetailStore}
							showDots={!window.matchMedia(openModalMediaCriteria).matches}
							showArrowsForSmall={true}
							trLinkEventCompName={groupName || productName || shortProductName}
						/>
					</div>
				),
			},
			onCloseModal: () => {
				const onCloseFocusElement = mediaSetDetailRef.current && mediaSetDetailRef.current.querySelector('[data-media-main-button-selected="true"][tabindex="0"]');

				if (isMediaMainButtonClick && onCloseFocusElement) {
					setTimeout(() => {
						onCloseFocusElement.focus();
					}, 250);
				}
			},
		});
	};

	const mediaControls = (
		<>
			<MediaControlCylindo
				mediaCylindoModel={mediaCylindoModel}
				mediaCylindoStore={mediaCylindoStore}
				mediaSetStore={mediaSetDetailStore}
			/>
			<MediaControlCylindoAR
				buttonRef={mediaControlCylindoARRef}
				mediaCylindoModel={mediaCylindoModel}
				mediaCylindoStore={mediaCylindoStore}
				mediaSetStore={mediaSetDetailStore}
			/>
			<MediaControlCylindoDimensions
				mediaCylindoModel={mediaCylindoModel}
				mediaCylindoStore={mediaCylindoStore}
				mediaSetStore={mediaSetDetailStore}
			/>
			<MediaControlFullscreenOpen
				buttonRef={mediaControlFullscreenRef}
				onClick={openFullScreenModal}
			/>
		</>
	);

	const mediaSetOverride = (
		<>
			{
				!hideMediaCylindo && (
					<MediaCylindo
						mediaControlCylindoARRef={mediaControlCylindoARRef}
						mediaCylindoModel={mediaCylindoModel}
						mediaCylindoStore={mediaCylindoStore}
						mediaSetStore={mediaSetDetailStore}
						selectorConfigModel={selectorConfigModel}
					/>
				)
			}
		</>
	);

	return (
		<div
			aria-label="Product detail media carousel"
			className={`${styles['media-set-detail']} ${printModule['print-media-set-detail']} ${className}`}
			data-qa="media-set-detail"
			ref={mediaSetDetailRef}
			role="region"
		>
			<p className="tw-sr-only">
				This section of the page contains a carousel that visually displays various linked images one at a time. For screen reader users, these images appear in a list below. Selecting the links changes the main slide visually.
			</p>
			<MediaSet
				{...mediaSetDetailProps}
				initialSlide={selectedMediaIndex}
				mediaComponentsOverride={mediaComponentsOverride}
				mediaControls={mediaControls}
				mediaMainOnClick={openFullScreenModal}
				mediaSetModel={mediaSetDetailModel}
				mediaSetOverride={mediaSetOverride}
				mediaSetOverrideBeforeChange={mediaSetOverrideBeforeChange}
				mediaSetStore={mediaSetDetailStore}
				trLinkEventCompName={groupName || productName || shortProductName}
				variant="Product Detail"
			/>
		</div>
	);
});
