import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { DetailsList } from '~/product/common/details/Components/DetailsList';

export const DetailComponentDimensions = observer(() => {
	const {
		summaryModel: {
			details: {
				dimensions = [],
			} = {},
		} = {},
	} = useProductContext();

	if (!dimensions.length) {
		return null;
	}

	return (
		<DetailsList details={dimensions} isList={false} />
	);
});
