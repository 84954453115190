import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector.module.scss';

export const SelectorHeadingCustomMirrorDimension = observer(({
	isActive = false,
	selectedSelector: {
		label = '',
	} = {},
}) => {
	const {
		selectorConfigModel: {
			selectedProductQuestionAnswers = [],
		} = {},
	} = useProductContext();

	function getDimensionByQuestionGroup(group = '') {
		const productQuestion = selectedProductQuestionAnswers.find(({ group: productQuestionGroup = '' }) => group === productQuestionGroup);

		const {
			selectedAnswer: {
				name = '',
			} = {},
		} = productQuestion || {};

		return name;
	}

	const widthText = getDimensionByQuestionGroup('WIDTH') ? `${getDimensionByQuestionGroup('WIDTH')}"w ` : '';

	const heightText = getDimensionByQuestionGroup('HEIGHT') ? `${getDimensionByQuestionGroup('HEIGHT')}"h ` : '';

	const diameterText = getDimensionByQuestionGroup('DIAMETER') ? `${getDimensionByQuestionGroup('DIAMETER')}" ` : '';

	const title = `${diameterText} ${widthText} ${heightText}`;

	return (
		<div
			className="selector-heading"
			data-qa={`${kebabCase(label)}-selector-heading`}
		>
			<div className="selector-heading-label-container">
				<SelectorHeadingLabel label={label} />
			</div>
			<span
				className={styles['selector-heading-title']}
				data-qa={`${kebabCase(label)}-selector-heading-title`}
			>
				{!isActive && title}
			</span>
		</div>
	);
});
