import styles from '~/components/cart-svg.module.scss';

type Props = Partial<{
	'aria-hidden': boolean
	'className': string
}>;

export const CartSvg = (props: Props) => {
	const className = props.className || 'tw-h-5 md:tw-h-[27px]';
	const ariaHidden = props['aria-hidden'] || false;

	return (
		<svg
			className={`${styles.cartSvg} ${className}`}
			xmlns="http://www.w3.org/2000/svg"
			width="30.855"
			height="21.7"
			viewBox="0 0 30.855 21.7"
			focusable="false"
			aria-hidden={ariaHidden}
		>
			<g data-name="Group 39" transform="translate(-1209 -13.5)">
				<g transform="translate(1203.7 9.7)">
					<path
						data-name="Path 22"
						d="M5.3,4.3h7.1l3.7,14.2H32.6L35.5,7.9H15.7"
						fill="#fff"
						stroke="#333"
						strokeMiterlimit="10"
						strokeWidth="1"
					/>
					<circle
						cx="2"
						cy="2"
						r="2"
						transform="translate(15.8 21)"
						fill="#fff"
						stroke="#333"
						strokeMiterlimit="10"
						strokeWidth="1"
					/>
					<circle
						cx="2"
						cy="2"
						r="2"
						transform="translate(28.4 21)"
						fill="#fff"
						stroke="#333"
						strokeMiterlimit="10"
						strokeWidth="1"
					/>
				</g>
				<path
					className={styles.hoverFill}
					d="M13336.25-190.375h21.859l-2.764,10.406h-16.332Z"
					transform="translate(-12119.109 207.969)"
					fill="#fff"
					stroke="#333"
					strokeWidth="1"
				/>
			</g>
		</svg>
	);
};
