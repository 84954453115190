
import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import styles from '#/product/standard/details/details-attributes.module.scss';

export const DetailDimensionsCasegoods = observer((props) => {
	const {
		workspaceModel: {
			workspaceDimensionsDisplayText = '',
		} = {},
	} = useProductContext();

	const {
		detail: {
			label = '',
		} = {},
	} = props;

	if (!workspaceDimensionsDisplayText) {
		return null;
	}

	return (
		<div
			className={`${styles['detail']} detail-name-value`}
			data-qa="detail-name-value"
		>
			<strong data-qa="detail-name-value-name">{label}{workspaceDimensionsDisplayText && ':'}</strong>
			<span data-qa="detail-name-value-value"> {workspaceDimensionsDisplayText} </span>
		</div>
	);
});
