import { MediaImage } from '~/media-set/Components/media/MediaImage';
import { MediaImageWithCaption } from '~/media-set/Components/media/MediaImageWithCaption';
import { MediaProduct } from '~/product/common/media-set/Components/media/MediaProduct';
import { MediaVideoWistia } from '~/media-set/Components/media/MediaVideoWistia';

export const mediaComponents = {
	IMAGE: MediaImage,
	IMAGE_WITH_CAPTION: MediaImageWithCaption,
	PRODUCT: MediaProduct,
	VIDEO_WISTIA: MediaVideoWistia,
};
