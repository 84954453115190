import { observer } from 'mobx-react';
import React from 'react';

import { sortBy } from '~/util/sortBy';
import { intersection } from '~/util/intersection';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { DetailsList } from '~/product/common/details/Components/DetailsList';
import styles from '#/product/standard/details/details-component.module.scss';

export const DetailMultiComponent = observer(({
	detail: {
		primaryQuestionKey = '',
		secondaryQuestionKeys = [],
	} = {},
} = {}) => {
	const {
		selectorConfigModel: {
			selectedProduct: {
				components = [],
			} = {},
			selectedProductQuestionAnswers = [],
		},
	} = useProductContext();

	const primaryQuestionModel = selectedProductQuestionAnswers.find(({ key = '' }) => key === primaryQuestionKey);

	const {
		selectedAnswer: {
			componentIds: primaryComponentIds = [],
		} = {},
	} = primaryQuestionModel || {};

	const primaryComponents = sortBy(components.filter(({ detail = {} }) => {
		return detail.additionalInformation.find(({ label = '' }) => label === 'Top');
	}), 'sort');

	const additionalComponentsList = primaryComponents.filter(({ id = '' }) => primaryComponentIds.includes(id));

	const primaryComponentsArticleNumbers = additionalComponentsList.map(({ articleNumber = '' }) => articleNumber);

	const secondaryComponentIdsChunks = secondaryQuestionKeys.map((secondaryQuestionKey) => {
		const secondaryQuestionModel = selectedProductQuestionAnswers.find(({ key = '' }) => key === secondaryQuestionKey);

		const {
			selectedAnswer: {
				componentIds: secondaryComponentIds = [],
			} = {},
		} = secondaryQuestionModel || {};

		return secondaryComponentIds;
	});

	const secondaryComponentIds = primaryComponentsArticleNumbers.length < 2 ? intersection(...secondaryComponentIdsChunks) : [];

	const secondaryComponents = components.filter(({
		detail: {
			dimensions = [],
		} = {},
		id = '',
	}) => {
		// this prevents the actual base article number from being rendered twice
		const hasLabelEndingWithQuoteH = dimensions.find(({ displayText = '' }) => displayText.endsWith('"h'));

		return secondaryComponentIds.includes(id) && !hasLabelEndingWithQuoteH;
	});

	const secondaryComponentArticleNumbers = secondaryComponents.map(({ articleNumber = '' }) => articleNumber);

	let foundTopDetail = false;

	const additionalDetailsList = additionalComponentsList.map(({
		detail: {
			additionalInformation = [],
		} = {},
	}) => {
		const topDetail = additionalInformation.find(({ label = '' }) => label === 'Top');

		if (!topDetail || !foundTopDetail) {
			foundTopDetail = topDetail;

			return [...additionalInformation];
		}

		return null;
	}).filter(Boolean);

	additionalDetailsList.forEach((detail) => {
		const detailItemNumber = detail.find(({ label = '' }) => label === 'Item Number');

		if (detailItemNumber) {
			detailItemNumber.displayText = primaryComponentsArticleNumbers.concat(secondaryComponentArticleNumbers).join(', ');
		}
	});

	return (
		<div className={`${styles['detail-component']}`}>
			{
				additionalDetailsList.map((detailsList, i) => (
					<DetailsList
						key={`details-${i}`}
						details={detailsList}
						isList={false}
					/>
				))
			}
		</div>
	);
});
