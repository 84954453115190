import { createComfortSpectrumModel } from '~/product/common/comfort-spectrum/Helpers/ComfortSpectrum.init';
import { createDetailsModel } from '~/product/common/details/Helpers/Details.init';
import { createMediaSetModel } from '~/media-set/Helpers/MediaSet.init';
import { ProductAnswerModelFactory } from '~/product/common/product/Models/ProductAnswer.model';
import { ProductQuestionModelFactory } from '~/product/common/product/Models/ProductQuestion.model';
import { ProductSectionalsModelFactory } from '~/product/sectionals/Models/ProductSectionals.model';

const createAnswersModel = (answersData = []) => {
	const answersModel = answersData.map((answerData) => {
		const answerModel = ProductAnswerModelFactory.create(answerData);

		return answerModel;
	});

	return answersModel;
};

const createQuestionModel = (questionData: any = {}) => {
	const { answers: answersData = [] } = questionData;

	const answersModel = createAnswersModel(answersData);

	const questionModel = ProductQuestionModelFactory.create({
		...questionData,
		answersModel,
	});

	return questionModel;
};

const createQuestionsModel = (questionsData = []) => {
	return questionsData.map((questionData: any) => createQuestionModel(questionData));
};

export const createProductSectionalsModel = ({
	defaultProductData = {},
	defaultProductData: {
		articleNumber: defaultProductArticleNumber = '',
	} = {},
	productData = {},
	productData: {
		_links = {},
		articleNumber = '',
		questionToAggregate: questionToAggregateData = {},
		siblingArticleNumber = null,
	} = {},
}: any = {}) => {
	const isDefaultProduct = articleNumber === defaultProductArticleNumber;

	const productDataToUse = isDefaultProduct ? defaultProductData : productData;

	const {
		comfortSpectrum: comfortSpectrumData = {},
		comfortSpectrumKeys: comfortSpectrumKeysData = {},
		detail: {
			additionalInformation: detailsAdditionalData = [],
			details: detailsAttributesData = [],
			dimensions: detailsDimensionsData = [],
			materials: detailsMaterialsData = [],
			sustainability: detailsSustainabilityData = [],
		} = {},
		dimensionMediaSet: mediaSetDimensionData = [],
		questions: questionsData = [],
	} = productDataToUse;

	const comfortSpectrumModel = createComfortSpectrumModel(comfortSpectrumData, comfortSpectrumKeysData);

	const detailsAdditionalModel = createDetailsModel(detailsAdditionalData);

	const detailsAttributesModel = createDetailsModel(detailsAttributesData);

	const detailsDimensionsModel = createDetailsModel(detailsDimensionsData);

	const detailsMaterialsModel = createDetailsModel(detailsMaterialsData);

	const detailsSustainabilityModel = createDetailsModel(detailsSustainabilityData);

	const mediaSetDimensionsModel = createMediaSetModel({ mediaSetData: mediaSetDimensionData });

	const questionModels = createQuestionsModel(questionsData);

	const questionToAggregateModel = createQuestionModel(questionToAggregateData);

	const productSectionalsModel = ProductSectionalsModelFactory.create({
		...productDataToUse,
		comfortSpectrumModel,
		detailsAdditionalModel,
		detailsAttributesModel,
		detailsDimensionsModel,
		detailsMaterialsModel,
		detailsSustainabilityModel,
		isLoaded: isDefaultProduct,
		_links,
		mediaSetDimensionsModel,
		questionModels,
		questionToAggregateModel,
		siblingArticleNumber,
	});

	return productSectionalsModel;
};

export const createProductSectionalsModels = ({
	defaultProductData = {},
	productsData = [],
}) => {
	const productSectionalsModels = productsData.map((productData: any) => createProductSectionalsModel({
		defaultProductData,
		productData,
	}));

	return productSectionalsModels;
};

export const updateProductSectionalsModel = ({
	productData: {
		_links = {},
		accentPillowCount = 0,
		comfortSpectrum: comfortSpectrumData = {},
		comfortSpectrumKeys: comfortSpectrumKeysData = [],
		detail: {
			additionalInformation: detailsAdditionalData = [],
			details: detailsAttributesData = [],
			dimensions: detailsDimensionsData = [],
			materials: detailsMaterialsData = [],
			sustainability: detailsSustainabilityData = [],
		} = {},
		dimensionMediaSet: mediaSetDimensionData = [],
		height = 0,
		longSalesText = '',
		originImage = '',
		originImageText = '',
		nonCustomArticles = [],
		questions: questionsData = [],
		shortSalesText = '',
		topCushionHeight = 0,
		width = 0,
	} = {},
	productSectionalsModel = {},
	productSectionalsModel: {
		_links: _productSectionalsModelLinks = {},
	} = {},
}) => {
	const _linksToUse = {
		..._productSectionalsModelLinks,
		..._links,
	};

	const comfortSpectrumModel = createComfortSpectrumModel(comfortSpectrumData, comfortSpectrumKeysData);

	const detailsAdditionalModel = createDetailsModel(detailsAdditionalData);

	const detailsAttributesModel = createDetailsModel(detailsAttributesData);

	const detailsDimensionsModel = createDetailsModel(detailsDimensionsData);

	const detailsMaterialsModel = createDetailsModel(detailsMaterialsData);

	const detailsSustainabilityModel = createDetailsModel(detailsSustainabilityData);

	const mediaSetDimensionsModel = createMediaSetModel({ mediaSetData: mediaSetDimensionData });

	const questionModels = createQuestionsModel(questionsData);

	Object.assign(productSectionalsModel, {
		_links: _linksToUse,
		accentPillowCount,
		comfortSpectrumModel,
		detailsAdditionalModel,
		detailsAttributesModel,
		detailsDimensionsModel,
		detailsMaterialsModel,
		detailsSustainabilityModel,
		height,
		isLoaded: true,
		longSalesText,
		mediaSetDimensionsModel,
		nonCustomArticles,
		originImage,
		originImageText,
		questionModels,
		shortSalesText,
		topCushionHeight,
		width,
	});

	return productSectionalsModel;
};
