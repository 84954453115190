import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { isOnServer } from '~/global/global.constants';

const SelectorCustomTableDimensionModal = dynamic(
	() => import('~/product/common/selector/Components/custom-table-dimension/SelectorCustomTableDimensionModal').then(module => module.SelectorCustomTableDimensionModal),
);

export const SelectorCustomTableDimension = observer(({
	selectedSelector: {
		dimensionQuestionSelectorModels = [],
		label = '',
	} = {},
}) => {
	const { magicModal = {} } = useGlobalContext();

	const {
		selectorConfigModel: {
			selectedProduct: {
				questionsModel = [],
			} = {},
		} = {},
		selectorConfigStore = {},
		summaryModel: {
			isLoading = false,
		} = {},
	} = useProductContext();

	const hasTopOptionQuestionGroup = questionsModel.some(({ group = '' }) => group === 'TOP_OPTION');

	function openModal() {
		magicModal.openModal({
			id: 'vimeoShowcase',
			title: 'Choosing Table Size',
			maxWidth: '784px',
			content: {
				children: <SelectorCustomTableDimensionModal showTopConsiderations={hasTopOptionQuestionGroup} />
			},
		});
	}

	return (
		<div className="selector-custom-table-dimension-wrapper tw-w-full md:tw-mt-0 md:tw-mb-8 tw-mt-5 tw-mb-5">
			<div className="selector-custom-table-dimension-container">
				<fieldset className="selector-values-custom-table-dimension-container">
					<legend className="tw-sr-only">Choose a {label}</legend>
					<div className="selector-values-custom-table-dimension tw-flex tw-space-x-5">
						{
							dimensionQuestionSelectorModels.map(({
								label: dimensionLabel = '',
								selectedSelectorValue: {
									peerId = '',
								} = {},
								validSelectorValues = [],
							}) => {
								return (
									<div
										className="tw-w-3/12"
										key={peerId}
									>
										<label
											data-qa={`custom-table-dimension-label_${dimensionLabel}`}
											htmlFor={`custom-table-dimension-select_${dimensionLabel}`}
										>
											{dimensionLabel}:
										</label>
										<select
											data-qa={`custom-table-dimension-select_${dimensionLabel}`}
											disabled={isOnServer || isLoading}
											id={`custom-table-dimension-select_${dimensionLabel}`}
											onChange={(e) => {
												selectorConfigStore.selectSelectorValueByPeerId(e.target.value, validSelectorValues);
											}}
											value={peerId}
										>
											{
												validSelectorValues.map(({
													peerId: nestedPeerId = '',
													title = '',
													unavailable = false,
												}) => {
													return (
														<option
															disabled={unavailable}
															key={nestedPeerId}
															value={nestedPeerId}
														>
															{title}
														</option>
													);
												})
											}
										</select>
									</div>
								);
							})
						}
					</div>
				</fieldset>
				<p className="tw-mb-2 tw-mt-5">
					Width must be equal to or greater than depth.
					{
						hasTopOptionQuestionGroup && (
							<span className="tw-block">
								Larger tables with stone tops may require two pieces.
							</span>
						)
					}
				</p>
				<button
					className="Button ButtonAnchor tw-mb-4"
					onClick={openModal}
					data-qa="custom-table-dimension-tips-modal-button"
				>
					Tips for choosing a table size
				</button>
			</div>
		</div>
	);
});
