import React from 'react';

import {
	availabilityMessages,
	availabilityMessageClasses,
	availabilitySchemas,
} from '~/product/common/summary/summary.constants';

export const getProductAvailabilityMessage = (productAvailability = {}) => {
	const {
		availableDate,
		inStock,
		inStockDate,
		specialOrder,
		stocked,
		stockedItem,
		leadTime,
		discontinued,
	} = productAvailability;
	const availableOrInStockDate = availableDate || inStockDate;

	if (discontinued) {
		return availabilityMessages.clearance;
	}

	if (inStock) {
		return availabilityMessages.inStock;
	}

	if (!specialOrder && stocked) {
		return null;
	}

	if (!specialOrder && stockedItem) {
		return availabilityMessages.stockedItem;
	}

	return !availableOrInStockDate && leadTime
		? `${availabilityMessages.madeForYou} in ${leadTime}`
		: availabilityMessages.madeForYou;
};

export const getProductAvailabilityMessageClassNames = (productAvailability = {}) => {
	const {
		inStock,
		specialOrder,
		stocked,
		stockedItem,
		discontinued,
	} = productAvailability;

	if (discontinued) {
		return availabilityMessageClasses.clearance;
	}

	if (inStock) {
		return availabilityMessageClasses.inStock;
	}

	if (!specialOrder && stocked) {
		return availabilityMessageClasses.stocked;
	}

	if (!specialOrder && stockedItem) {
		return availabilityMessageClasses.stockedItem;
	}

	return availabilityMessageClasses.madeForYou;
};

export const getProductAvailabilitySchema = () => {
	return availabilitySchemas.inStock;
};

export const getProductStoreQuantityMessage = (productAvailability = {}) => {
	const { storeQuantity } = productAvailability;

	if (storeQuantity) {
		return `Store inventory (${storeQuantity})`;
	}

	return '';
};

export const getProductSummaryStatusSettings = (statusType = 'DEFAULT', productName, fabric = '', colorway = '', collectionName = '') => {
	const summaryStatusModalSettings = {
		'DEFAULT': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} is available in limited quantities. Order now to ensure availability.`,
			showContact: true,
		},
		'FABRIC_DROP_FLOOR_CHANGE_WITH_REPLACE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} will no longer be stocked in ${fabric} fabric. Remaining stock is limited. Order now to ensure availability. Look for new fabric for this collection in the future.`,
			showContact: true,
		},
		'FABRIC_DROP_MID_YEAR_WITH_REPLACE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} will no longer be stocked in ${fabric} fabric. Remaining stock is limited. Order now to ensure availability. Look for new fabric for this collection in the future.`,
			showContact: true,
		},
		'FABRIC_DROP_FLOOR_CHANGE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} will no longer be stocked in ${fabric} fabric. Remaining stock is limited. Order now to ensure availability.`,
			showContact: true,
		},
		'FABRIC_DROP_MID_YEAR': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} will no longer be stocked in ${fabric} fabric. Remaining stock is limited. Order now to ensure availability.`,
			showContact: true,
		},
		'LEATHER_DROP_FLOOR_CHANGE_WITH_REPLACE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} will no longer be stocked in ${fabric} leather. Remaining stock is limited. Order now to ensure availability. Look for new leather for this collection in the future.`,
			showContact: true,
		},
		'LEATHER_DROP_MID_YEAR_WITH_REPLACE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} will no longer be stocked in ${fabric} leather. Remaining stock is limited. Order now to ensure availability. Look for new leather for this collection in the future.`,
			showContact: true,
		},
		'LEATHER_DROP_FLOOR_CHANGE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} will no longer be stocked in ${fabric} leather. Remaining stock is limited. Order now to ensure availability.`,
			showContact: true,
		},
		'LEATHER_DROP_MID_YEAR': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} will no longer be stocked in ${fabric} leather. Remaining stock is limited. Order now to ensure availability.`,
			showContact: true,
		},
		'SIZE_DROP_FLOOR_CHANGE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} has been discontinued. Remaining stock is limited. Order now to ensure availability.`,
			showContact: true,
		},
		'SIZE_DROP_MID_YEAR': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} has been discontinued. Remaining stock is limited. Order now to ensure availability.`,
			showContact: true,
		},
		'SIZE_DROP_FLOOR_CHANGE_WITH_REPLACE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} has been discontinued. Remaining stock is limited. Order now to ensure availability. Look for new ${collectionName} options in the near future.`,
			showContact: true,
		},
		'SIZE_DROP_MID_YEAR_WITH_REPLACE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} has been discontinued. Remaining stock is limited. Order now to ensure availability. Look for new ${collectionName} options in the near future.`,
			showContact: true,
		},
		'COLORWAY_DROP_FLOOR_CHANGE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${fabric} ${colorway} is being discontinued. Remaining stock in this color is limited. Order now to ensure availability. Look for new color options in the future.`,
			showContact: true,
		},
		'COLORWAY_DROP_MID_YEAR': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${fabric} ${colorway} is being discontinued. Remaining stock in this color is limited. Order now to ensure availability. Look for new color options in the future.`,
			showContact: true,
		},
		'OPTION_DROP_FLOOR_CHANGE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} with the options you've selected is available in limited quantities. Order now to ensure availability.`,
			showContact: true,
		},
		'OPTION_DROP_MID_YEAR': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} with the options you've selected is available in limited quantities. Order now to ensure availability.`,
			showContact: true,
		},
		'OPTION_DROP_FLOOR_CHANGE_WITH_REPLACE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} with the options you've selected is available in limited quantities. Order now to ensure availability. Look for new options in the future.`,
			showContact: true,
		},
		'OPTION_DROP_MID_YEAR_WITH_REPLACE': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} with the options you've selected is available in limited quantities. Order now to ensure availability. Look for new options in the future.`,
			showContact: true,
		},
		'LIMITED_EDITION': {
			modalTitle: 'Limited Edition',
			modalContent: `The ${productName} is a limited edition item. Order now to ensure availability.`,
			showContact: true,
		},
		'LIMITED_QUANTITY': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} is available in a limited quantity. Order now to ensure availability.`,
			showContact: true,
		},
		'LIMITED_QUANTITY_ONE_OF_A_KIND': {
			modalTitle: 'Limited Quantity',
			modalContent: `The ${productName} is available in a limited quantity. Each piece is a one-of-a-kind. Order now to ensure availability.`,
			showContact: true,
		},
		'SAMPLE_REFUND_ON_RETURN': {
			modalTitle: 'Material Samples',
			modalContent: <>
				<p>See how this material would look and feel in your home by ordering a sample. Each sample is available for a refundable fee if returned within six weeks. For your convenience, online sample orders will include a pre-paid return shipping label.</p>
				<p>Samples are created to show accurate color and texture—please use product images for a representation of the overall pattern.</p>
			</>,
			showContact: false,
		},
	};

	return summaryStatusModalSettings[statusType];
};
