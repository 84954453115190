import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import React, { ForwardedRef, forwardRef } from 'react';

import { addToUrl } from '~/util/addToUrl';

const cylindoImageLoader = ({
	src = '',
	width = 0,
}: ImageLoaderProps) => {
	return addToUrl(src, `size=${width}`);
};

export const CylindoImage = forwardRef((props: ImageProps, ref: ForwardedRef<HTMLImageElement>) => {
	return (
		<Image
			{...props}
			loader={cylindoImageLoader}
			ref={ref}
		/>
	);
});
