import {
	computed, observable, action, makeObservable,
} from 'mobx';

import { productContentTypes } from '~/product/common/promos/promos.constants';

export class ProductGroupModel {
	data; // Mobx Map

	blurbs;

	careGuides;

	collections;

	detailsAdditionalModel;

	detailsAttributesModel;

	detailsContentTabModel;

	detailsMaterialsModel;

	detailsSustainabilityModel;

	dimensionMedia;

	helpfulLinks;

	imageData;

	media;

	mobileCollections;

	promos;

	videoMedia;

	constructor() {
		makeObservable(this, {
			data: observable,
			blurbs: observable,
			careGuides: observable,
			collections: observable,
			detailsAdditionalModel: observable,
			detailsAttributesModel: observable,
			detailsContentTabModel: observable,
			detailsMaterialsModel: observable,
			detailsSustainabilityModel: observable,
			dimensionMedia: observable,
			helpfulLinks: observable,
			imageData: observable,
			media: observable,
			mobileCollections: observable,
			promos: observable,
			videoMedia: observable,
			update: action.bound,
			_links: computed,
			id: computed,
			pageTitle: computed,
			title: computed,
			key: computed,
			name: computed,
			longName: computed,
			description: computed,
			summaryTitle: computed,
			editorialDescription: computed,
			mainImageCaption: computed,
			mainImageOverrideFilename: computed,
			metaDescription: computed,
			summaryPromos: computed,
			dimensionPromos: computed,
			introBlurb: computed,
			beforeMaterialsContent: computed,
			afterMaterialsContent: computed,
			template: computed,
			priceRange: computed,
			reviewProductGroupKey: computed,
			hasStaticMainImage: computed,
			showPriceGuarantee: computed,
			hideCustomerImages: computed,
			isLimitedEditionSoldOut: computed,
			showComfortSpectrum: computed,
			socialProof: computed,
			staticUrl: computed,
			reviewsCount: computed,
			reviewsAverageOverallRating: computed,
			canonicalUrl: computed,
			featuredContent: computed,
			ugcProductGroupId: computed,
		});
	}

	update(newData) {
		if (newData) {
			this.data.replace(newData);
		} else {
			this.data.clear();
		}
	}

	get _links() {
		return this.data.get('_links') || {};
	}

	get id() {
		return this.data.get('id');
	}

	get pageTitle() {
		return this.data.get('pageTitle') || this.longName || this.description;
	}

	get title() {
		return this.longName || this.description;
	}

	get key() {
		return this.data.get('key');
	}

	get name() {
		return this.data.get('name');
	}

	get longName() {
		return this.data.get('longName');
	}

	get description() {
		return this.data.get('description');
	}

	get summaryTitle() {
		return this.data.get('summaryTitle');
	}

	get editorialDescription() {
		return this.data.get('editorialDescription');
	}

	get mainImageCaption() {
		return this.data.get('mainImageCaption');
	}

	get mainImageOverrideFilename() {
		return this.data.get('mainImageOverrideFilename');
	}

	get metaDescription() {
		return this.data.get('metaDescription');
	}

	get summaryPromos() {
		return this.data.get('promos')?.SUMMARY;
	}

	get dimensionPromos() {
		return this.data.get('promos')?.DIMENSION;
	}

	get introBlurb() {
		if (this.blurbs) {
			let introBlurb = this.blurbs.find((blurb) => {
				return blurb.position === 'VENDOR_STORY';
			});

			if (!introBlurb) {
				introBlurb = this.blurbs.filter((blurb) => {
					return blurb.position === 'CRAFTSMEN_LINK';
				});
			}

			return introBlurb;
		}
		return null;
	}

	get beforeMaterialsContent() {
		const { beforeMaterials } = productContentTypes;

		if (this.detailsContentTabModel) {
			const beforeMaterialsPromo = this.detailsContentTabModel
				.find(({ type }) => type === beforeMaterials);

			return beforeMaterialsPromo;
		}

		return null;
	}

	get afterMaterialsContent() {
		const { afterMaterials } = productContentTypes;

		if (this.detailsContentTabModel) {
			const afterMaterialsPromo = this.detailsContentTabModel
				.find(({ type }) => type === afterMaterials);

			return afterMaterialsPromo;
		}

		return null;
	}

	get template() {
		return this.data.get('productPageTemplate');
	}

	get priceRange() {
		return this.data.get('priceRange');
	}

	get reviewProductGroupKey() {
		return this.data.get('reviewProductGroupKey');
	}

	get hasStaticMainImage() {
		return Boolean(this.data.get('staticMainImage'));
	}

	get showPriceGuarantee() {
		return Boolean(this.data.get('showPriceGuarantee'));
	}

	get hideCustomerImages() {
		return Boolean(this.data.get('hideCustomerImages'));
	}

	get isLimitedEditionSoldOut() {
		return Boolean(this.data.get('limitedEditionSoldOut'));
	}

	get showComfortSpectrum() {
		return Boolean(this.data.get('showComfortSpectrum'));
	}

	get socialProof() {
		return this.data.get('socialProof');
	}

	get staticUrl() {
		return this.data.get('staticUrl');
	}

	get reviewsCount() {
		return this.data.get('reviewsCount') || 0;
	}

	get reviewsAverageOverallRating() {
		return this.data.get('reviewsAverageOverallRating') || 0;
	}

	get canonicalUrl() {
		return this.data.get('canonicalUrl');
	}

	get featuredContent() {
		return this.data.get('featuredContent');
	}

	get productPageType() {
		return this.data.get('productPageType');
	}

	get ugcProductGroupId() {
		return this.data.get('ugcProductGroupId');
	}
}

const ProductGroupModelFactory = {
	create: (data) => {
		const instance = new ProductGroupModel();
		instance.data = new Map(data);

		return instance;
	},
};

export { ProductGroupModelFactory };
