import { action, makeObservable, reaction } from 'mobx';

import { isOnServer } from '~/global/global.constants';
import { noop } from '~/util/noop';

class WorkspaceModalInsertOptionsCasegoodsStore {
	selectedSelectorValuesChangedDisposer

	workspaceModalInsertOptionsModel

	constructor() {
		makeObservable(this, {
			selectSelectorValue: action,
			setSlotModel: action,
			setSelectedSelectorValues: action,
		});
	}

	selectSelectorValue(selectorValueToSelect = {}) {
		const { parentType: parentTypeToReplace = '' } = selectorValueToSelect;

		const { selectedSelectorValues = [] } = this.workspaceModalInsertOptionsModel;

		const selectorValueToReplace = selectedSelectorValues.find(({ parentType = '' }) => parentType === parentTypeToReplace);

		const filteredSelectedSelectorValues = selectedSelectorValues.filter(selectorValue => selectorValue !== selectorValueToReplace);

		this.setSelectedSelectorValues([...filteredSelectedSelectorValues, selectorValueToSelect]);
	}

	setSlotModel(slotModel = {}) {
		this.workspaceModalInsertOptionsModel.slotModel = slotModel;
	}

	setSelectedSelectorValues(selectorValues = []) {
		this.workspaceModalInsertOptionsModel.selectedSelectorValues = selectorValues;
	}
}

export const WorkspaceModalInsertOptionsCasegoodsStoreFactory = ({
	create: ({ workspaceModalInsertOptionsModel = {} }) => {
		const workspaceModalInsertOptionsCasegoodsStore = new WorkspaceModalInsertOptionsCasegoodsStore();

		let selectedSelectorValuesChangedDisposer = noop;

		Object.assign(workspaceModalInsertOptionsCasegoodsStore, {
			selectedSelectorValuesChangedDisposer,
			workspaceModalInsertOptionsModel,
		});

		if (!isOnServer) {
			selectedSelectorValuesChangedDisposer = reaction(() => workspaceModalInsertOptionsModel.selectedSelectorValuesWithDependentQuestionAnswers, (newSelectedSelectorValuesWithDependentQuestionAnswers = []) => {
				const {
					selectedSelectorValues = [],
					slotModel: {
						insertOptionsSelectors = [],
					} = {},
				} = workspaceModalInsertOptionsModel;

				newSelectedSelectorValuesWithDependentQuestionAnswers.forEach(({ dependentQuestionAnswers = {} }) => {
					Object.entries(dependentQuestionAnswers).forEach(([question = '', answer = '']) => {
						insertOptionsSelectors.forEach(({
							negativeSelectorValue = {},
							selectorValues = [],
						}) => {
							const matchingSelectorValue = selectorValues.find(({
								answer: selectorValueAnswer,
								question: selectorValueQuestion,
							}) => {
								return answer === selectorValueAnswer && question === selectorValueQuestion;
							});

							if (matchingSelectorValue && !selectedSelectorValues.includes(negativeSelectorValue) && !selectedSelectorValues.includes(matchingSelectorValue)) {
								workspaceModalInsertOptionsCasegoodsStore.selectSelectorValue(matchingSelectorValue);
							}
						});
					});
				});
			});
		}

		return workspaceModalInsertOptionsCasegoodsStore;
	},
});
