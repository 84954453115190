// import dynamic from 'next/dynamic';
import React, { createContext, useContext } from 'react';

import { ProductCasegoodsContextProvider } from '~/product/casegoods/Contexts/ProductCasegoods.context';
import { ProductClearanceContextProvider } from '~/product/clearance/Contexts/ProductClearance.context';
import { ProductMultiContextProvider } from '~/product/multi/Contexts/ProductMulti.context';
import { ProductMultiOptionsContextProvider } from '~/product/multi-options/Contexts/ProductMultiOptions.context';
import { ProductSectionalsContextProvider } from '~/product/sectionals/Contexts/ProductSectionals.context';
import { ProductStandardContextProvider } from '~/product/standard/Contexts/ProductStandard.context';

// revisit code splitting these someday - doing so currently causes hydration to happen after page load and a poor TTI in automation
// const ProductCasegoodsContextProvider = dynamic(() => import('~/product/casegoods/Contexts/ProductCasegoods.context').then(mod => mod.ProductCasegoodsContextProvider));
// const ProductClearanceContextProvider = dynamic(() => import('~/product/clearance/Contexts/ProductClearance.context').then(mod => mod.ProductClearanceContextProvider));
// const ProductMultiContextProvider = dynamic(() => import('~/product/multi/Contexts/ProductMulti.context').then(mod => mod.ProductMultiContextProvider));
// const ProductMultiOptionsContextProvider = dynamic(() => import('~/product/multi-options/Contexts/ProductMultiOptions.context').then(mod => mod.ProductMultiOptionsContextProvider));
// const ProductSectionalsContextProvider = dynamic(() => import('~/product/sectionals/Contexts/ProductSectionals.context').then(mod => mod.ProductSectionalsContextProvider));
// const ProductStandardContextProvider = dynamic(() => import('~/product/standard/Contexts/ProductStandard.context').then(mod => mod.ProductStandardContextProvider));

const productContext = createContext();

export const ProductContextProvider = (pageProps) => {
	const {
		children,
		productPageTemplate = '',
	} = pageProps;

	const contextProviderMap = {
		CASEGOODS: () => <ProductCasegoodsContextProvider pageProps={pageProps} productContext={productContext} />,
		CLEARANCE: () => <ProductClearanceContextProvider pageProps={pageProps} productContext={productContext} />,
		MULTI_SELECT: () => <ProductMultiContextProvider pageProps={pageProps} productContext={productContext} />,
		MULTI_SELECT_WITH_OPTIONS: () => <ProductMultiOptionsContextProvider pageProps={pageProps} productContext={productContext} />,
		SECTIONAL_DESIGNER: () => <ProductSectionalsContextProvider pageProps={pageProps} productContext={productContext} />,
		STANDARD: () => <ProductStandardContextProvider pageProps={pageProps} productContext={productContext} />,
	};

	const ContextProvider = contextProviderMap[productPageTemplate] && contextProviderMap[productPageTemplate]();

	if (!ContextProvider) {
		console.warn(`No ProductContextProvider found for productPageTemplate ${productPageTemplate}`);

		return <productContext.Provider value={{}}>{children}</productContext.Provider>;
	}

	return ContextProvider;
};

export const useProductContext = () => useContext(productContext);
