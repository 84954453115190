import React from 'react';
import { observer } from 'mobx-react';

import { DeliveryPackageIcon } from '~/delivery/Components/DeliveryPackageIcon';

export const DeliveryUps = observer((props) => {
	const getMessage = (shippingMethod) => {
		const messages = {
			UP2: {
				instruction: '$25 UPS Shipping',
			},
			UP3: {
				instruction: 'Free UPS Shipping',
			},
			DEFAULT: {
				instruction: 'Free UPS Shipping',
			},
		};

		return messages[shippingMethod] || messages.DEFAULT;
	};

	const {
		store: {
			shippingMethod = '',
		}
	} = props;

	const {
		instruction,
	} = getMessage(shippingMethod);

	return (
		<div data-qa={`delivery ${shippingMethod}`} className="delivery-container delivery-container-standard delivery-container-ups">
			<div className="delivery-icon">
				<DeliveryPackageIcon />
			</div>

			<div className="delivery-content">
				<span className="delivery-instructions tw-font-semibold">{instruction}</span>
			</div>
		</div>
	);
});
