import { computed, observable, makeObservable } from 'mobx';

import type { IProductConnectablesSectionals } from '~/product/sectionals/Interfaces/ProductConnectablesSectionals.interface';
import type { IProductQuestionSectionals } from '~/product/sectionals/Interfaces/ProductQuestionSectionals.interface';
import type { IProductSectionals, IProductSectionalsApiLinks } from '~/product/sectionals/Interfaces/ProductSectionals.interface';

import { opposingSidesMap } from '~/product/sectionals/workspace/workspace-sectionals.constants';

export class ProductSectionalsModel implements IProductSectionals {
	_links: IProductSectionalsApiLinks;

	accentPillowCount: number;

	articleNumber: string;

	comfortSpectrumModel: Array<any>;

	connectables: IProductConnectablesSectionals;

	depth: number;

	detailsAdditionalModel: Array<any>;

	detailsAttributesModel: Array<any>;

	detailsConstructionModel: Array<any>;

	detailsDimensionsModel: Array<any>;

	detailsMaterialsModel: Array<any>;

	detailsSustainabilityModel: Array<any>;

	drapeableImageUrl: string;

	frameDepth: number;

	height: number;

	imageData: any;

	isLoaded: boolean;

	longSalesText: string;

	nonCustomArticles: Array<string>;

	originImage: string;

	originImageText: string;

	pricing: any;

	questionModels: Array<IProductQuestionSectionals>;

	questionToAggregateModel: IProductQuestionSectionals;

	shortSalesText: string;

	topCushionHeight: number;

	width: number;

	mediaSetDimensionsModel: any;

	siblingArticleNumber: string;

	constructor({
		_links = {},
		accentPillowCount = 0,
		articleNumber = '',
		comfortSpectrumModel = [],
		connectables = {},
		depth = 0,
		detailsAdditionalModel = [],
		detailsAttributesModel = [],
		detailsConstructionModel = [],
		detailsDimensionsModel = [],
		detailsMaterialsModel = [],
		detailsSustainabilityModel = [],
		drapeableImageUrl = '',
		frameDepth = null,
		height = 0,
		imageData = {},
		isLoaded = false,
		longSalesText = '',
		nonCustomArticles = [],
		originImage = '',
		originImageText = '',
		pricing = {},
		questionModels = [],
		questionToAggregateModel = {},
		shortSalesText = '',
		topCushionHeight = 0,
		width = 0,
		mediaSetDimensionsModel = {},
		siblingArticleNumber = null,
	}: any = {}) {
		makeObservable(this, {
			accentPillowCount: observable.ref,
			comfortSpectrumModel: observable.ref,
			detailsAdditionalModel: observable.ref,
			detailsAttributesModel: observable.ref,
			detailsConstructionModel: observable.ref,
			detailsDimensionsModel: observable.ref,
			detailsMaterialsModel: observable.ref,
			detailsSustainabilityModel: observable.ref,
			depth: observable.ref,
			drapeableImageUrl: observable.ref,
			frameDepth: observable.ref,
			height: observable.ref,
			imageData: observable.ref,
			isLoaded: observable,
			longSalesText: observable.ref,
			nonCustomArticles: observable.ref,
			originImage: observable.ref,
			originImageText: observable.ref,
			shortSalesText: observable.ref,
			topCushionHeight: observable.ref,
			width: observable.ref,
			drapeableImageApiLink: computed,
			filteredJoinableSides: computed,
			filteredBackSides: computed,
			imageUrl: computed,
			isDeep: computed,
			isEveryJoinableSides: computed,
			isNoBackSides: computed,
			isWide: computed,
			productApiLink: computed,
			upcharge: computed,
		});

		this._links = _links;
		this.accentPillowCount = accentPillowCount;
		this.articleNumber = articleNumber;
		this.comfortSpectrumModel = comfortSpectrumModel;
		this.connectables = connectables;
		this.depth = depth;
		this.drapeableImageUrl = drapeableImageUrl;
		this.frameDepth = frameDepth || depth;
		this.height = height;
		this.detailsAdditionalModel = detailsAdditionalModel;
		this.detailsAttributesModel = detailsAttributesModel;
		this.detailsConstructionModel = detailsConstructionModel;
		this.detailsDimensionsModel = detailsDimensionsModel;
		this.detailsMaterialsModel = detailsMaterialsModel;
		this.detailsSustainabilityModel = detailsSustainabilityModel;
		this.imageData = imageData;
		this.isLoaded = isLoaded;
		this.longSalesText = longSalesText;
		this.nonCustomArticles = nonCustomArticles;
		this.originImage = originImage;
		this.originImageText = originImageText;
		this.pricing = pricing;
		this.questionModels = questionModels;
		this.questionToAggregateModel = questionToAggregateModel;
		this.shortSalesText = shortSalesText;
		this.topCushionHeight = topCushionHeight;
		this.width = width;
		this.mediaSetDimensionsModel = mediaSetDimensionsModel;
		this.siblingArticleNumber = siblingArticleNumber;
	}

	get drapeableImageApiLink(): string {
		const {
			drapeableImage: {
				href = '',
			} = {},
		} = this._links;

		return href;
	}

	get filteredBackSides(): Array<string> {
		const {
			backSides = [],
			joinableSides = [],
		} = this.connectables;

		const oppositeJoinableSides = joinableSides.map((joinableSide = '') => opposingSidesMap[joinableSide as keyof typeof opposingSidesMap]);

		if (this.isEveryJoinableSides) {
			return ['A'];
		}

		if (this.isNoBackSides) {
			return oppositeJoinableSides;
		}

		return backSides;
	}

	get filteredJoinableSides(): Array<string> {
		const { joinableSides = [] } = this.connectables;

		return this.isEveryJoinableSides ? ['B', 'D'] : joinableSides;
	}

	get imageUrl(): string {
		const { imageUrl = '' } = this.imageData;

		return imageUrl;
	}

	get isDeep(): boolean {
		return this.depth > this.frameDepth;
	}

	get isEveryJoinableSides(): boolean {
		const { joinableSides = [] } = this.connectables;

		return ['A', 'B', 'C', 'D'].every((joinableSide = '') => joinableSides.includes(joinableSide));
	}

	get isNoBackSides(): boolean {
		const { backSides = [] } = this.connectables;

		return !backSides.length;
	}

	get isWide(): boolean {
		return this.width > this.depth;
	}

	get productApiLink(): string {
		const {
			product: {
				href = '',
			} = {},
		} = this._links;

		return href;
	}

	get upcharge() {
		return this.questionModels
			.map(({ selectedAnswer }) => selectedAnswer)
			.reduce((acc, answer) => acc + (answer ? answer.upcharge : 0), 0);
	}
}

export const ProductSectionalsModelFactory = ({
	create: (data: any = {}) => {
		return new ProductSectionalsModel(data);
	},
});
