import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { DetailsList } from '~/product/common/details/Components/DetailsList';
import { intersection } from '~/util/intersection';
import { sortBy } from '~/util/sortBy';
import { without } from '~/util/without';

import styles from '#/product/standard/details/details-component.module.scss';

export const DetailComponent = observer(({
	detail: {
		componentId: detailComponentId = '',
		questionKey: detailQuestionKey = '',
	} = {},
} = {}) => {
	const {
		selectorConfigModel: {
			questionSelectorModels = [],
			selectedProduct: {
				components = [],
				detailsAdditionalModel = [],
			} = {},
			selectedProductQuestionAnswers = [],
		},
	} = useProductContext();

	const componentIds = [];

	const multiComponentDetail = detailsAdditionalModel.find(({ type = '' }) => type === 'multiComponent');

	const questionSelectorModel = questionSelectorModels.find(({ selectorValues = [] }) => {
		return selectorValues.some(({ questionAnswers = [] }) => {
			return questionAnswers.some(({ question = '' }) => question === detailQuestionKey);
		});
	});

	const { selectedSelectorValue: questionSelectorValue } = questionSelectorModel || {};

	const localQuestionModel = selectedProductQuestionAnswers.find(({ key = '' }) => key === detailQuestionKey);

	const {
		selectedAnswer: {
			componentIds: localComponentIds = [],
		} = {},
	} = localQuestionModel || {};

	if (detailComponentId) { // Add componentId on detail
		componentIds.push(detailComponentId);
	} else if (multiComponentDetail) {
		const { primaryQuestionKey = '' } = multiComponentDetail;

		const primaryQuestionModel = selectedProductQuestionAnswers.find(({ key = '' }) => key === primaryQuestionKey);

		const { answersModel = [] } = primaryQuestionModel || {};

		const primaryComponentIdChunks = answersModel.map(({ componentIds: answerModelComponentIds = [] }) => answerModelComponentIds);

		const matchingPrimaryComponentIds = primaryComponentIdChunks.reduce((accumulatedComonentIds = [], componentIdChunk = []) => {
			if (intersection(componentIdChunk, localComponentIds).length) {
				return [...accumulatedComonentIds, ...componentIdChunk];
			}

			return accumulatedComonentIds;
		}, []);

		const primaryComponentIds = without(localComponentIds, ...matchingPrimaryComponentIds);

		componentIds.push(...primaryComponentIds);

		// this is mostly to deal with backfilling the base detail for Aria top and base, when an attachment kit component article is not present
		if (!componentIds.length) {
			componentIds.push(...localComponentIds);
		}
	} else { // Otherwise look up IDs from the selected answer
		componentIds.push(...localComponentIds);
	}

	if (detailComponentId || questionSelectorValue) {
		let componentsToUse = sortBy(components, 'sort');

		let nonQuestionBaseComponents = [];

		if (detailQuestionKey.includes('COLL_TOP')) {
			componentsToUse = components.filter(({
				detail: {
					additionalInformation = [],
				} = {},
			}) => additionalInformation.find(({ label = '' }) => label === 'Top'));

			const hasBaseQuestion = selectedProductQuestionAnswers.find(({ key = '' }) => key.includes('COLL_BASE'));

			if (!hasBaseQuestion) {
				nonQuestionBaseComponents = components.filter(({
					detail: {
						additionalInformation = [],
					} = {},
				}) => additionalInformation.find(({ label = '' }) => label === 'Base'));
			}
		} else {
			if (detailQuestionKey.includes('COLL_BASE')) {
				const selectedAnswers = selectedProductQuestionAnswers.map(({ selectedAnswer = {} }) => selectedAnswer).filter(Boolean);

				// Find the componentId that appears in every selected answers' componentIds
				const baseComponentId = componentIds.find((componentId = '') => {
					return selectedAnswers.every(({ componentIds: selectedAnswerComponentIds = [] }) => selectedAnswerComponentIds.includes(componentId));
				});

				if (baseComponentId) {
					componentIds.splice(0, componentIds.length);
					componentIds.push(baseComponentId);
				}
			}

			componentsToUse = components.filter(({ id = '' }) => componentIds.includes(id));
		}

		const additionalDetailsList = componentsToUse.reduce((acc, { id, detail }) => {
			if (componentIds.includes(id)) {
				acc.push(detail.additionalInformation);
			}

			return acc;
		}, []);

		const additionalNonQuestionBaseDetailsList = nonQuestionBaseComponents.reduce((acc, { id, detail }) => {
			if (componentIds.includes(id)) {
				acc.push(detail.additionalInformation);
			}

			return acc;
		}, []);

		return (
			<>
				<div className={styles['detail-component']}>
					{
						additionalDetailsList.map((detailsList, i) => (
							<DetailsList
								key={`details-${i}`}
								details={detailsList}
								isList={false}
							/>
						))
					}
				</div>
				{
					Boolean(additionalNonQuestionBaseDetailsList.length) && (
						<div className={styles['detail-component']}>
							{
								additionalNonQuestionBaseDetailsList.map((detailsList, i) => (
									<DetailsList
										key={`details-${i}`}
										details={detailsList}
										isList={false}
									/>
								))
							}
						</div>
					)
				}
			</>
		);
	}

	return null;
});
