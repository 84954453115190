import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { s7ImagePath } from '~/global/global.constants';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { addToUrl } from '~/util/addToUrl';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector-medium-image.module.scss';

export const SelectorValueMediumImage = observer((props) => {
	const {
		isActive = false,
		label = '',
		selectorValue,
		selectorValue: {
			image,
			image: {
				imageSrc = '',
			},
			selected,
			title = '',
		},
	} = props;

	const imageToUse = imageSrc || image;

	const { selectorConfigStore } = useProductContext();

	function handleChange() {
		selectorConfigStore.selectSelectorValue(selectorValue);
	}

	return (
		<label
			className={
				classNames([styles['selector-value-medium-image']], {
					[styles['selector-value-medium-image-selected']]: selected,
				})
			}
			data-qa={`${kebabCase(label)}-selector-value`}
		>
			<S7Image
				alt=""
				className={styles['selector-value-medium-image-image']}
				height="70"
				priority={isActive}
				src={addToUrl(`${s7ImagePath}/${imageToUse}`, '$proddd$')}
				width="105"
			/>
			<div className={styles['selector-value-medium-image-text']}>
				<span
					className={styles['selector-value-medium-image-title']}
					data-qa={`${kebabCase(label)}-selector-value-title`}
				>
					{title}
				</span>
			</div>
			<input
				checked={selected}
				className="tw-sr-only"
				data-tr-link-event-track={false}
				onChange={handleChange}
				type="radio"
			/>
		</label>
	);
});
