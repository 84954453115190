import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import { s7ContentPath } from '~/global/global.constants';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '~/product/sectionals/workspace/Components/controls/workspace-control-rotate.module.scss';

interface Props {
	isMainWorkspace: boolean
}

export const WorkspaceControlRotateSectionals = observer(({ isMainWorkspace = false }: Props) => {
	const {
		workspaceModel: {
			firstWorkspaceProductModel = {},
			hasWorkspaceProductModels = false,
		} = {},
		workspaceStore = {},
	} = useProductContext();

	function handleClick(): void {
		runInAction(() => {
			workspaceStore.updateWorkspaceProductModelsOrientations({
				orientationChange: 90,
				workspaceProductModel: firstWorkspaceProductModel,
			});

			workspaceStore.updateFirstWorkspaceProductModel();
		});
	}

	return (
		<button
			className={
				classNames(
					styles['workspace-control-rotate'],
					'ButtonAnchor', {
						'tw-hidden': !isMainWorkspace || !hasWorkspaceProductModels,
					}
				)
			}
			data-qa="workspace-control-rotate"
			onClick={handleClick}
		>
			<img
				alt=""
				className={styles['workspace-control-rotate-image']}
				src={`${s7ContentPath}/rotate`}
			/>
			<span className={`${styles['workspace-control-rotate-text']} tw-hidden md:tw-block`}>
				Rotate
			</span>
		</button>
	);
});
