import { observer } from 'mobx-react';
import React from 'react';

import { DetailImage } from '~/product/common/details/Components/details/DetailImage';
import { DetailsList } from '~/product/common/details/Components/DetailsList';
import styles from '#/product/standard/details/details-materials.module.scss';

export const DetailNameValueGroup = observer((props) => {
	const {
		detail: {
			detailsGroup = [],
		} = {},
	} = props;

	const detailImage = detailsGroup.find(({ type }) => type === 'image');

	const filteredDetails = detailsGroup.filter(({ type }) => type !== 'image');

	return (
		<div className={styles['detail-name-value-group']} data-qa="detail-name-value-group">
			{
				detailImage && (
					<DetailImage detail={detailImage} />
				)
			}
			{
				Boolean(filteredDetails.length) && (
					<DetailsList details={filteredDetails} isList={false} />
				)
			}
		</div>
	);
});
