import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector.module.scss';

export const SelectorHeadingInsert = observer((props) => {
	const {
		isActive = false,
		selectedSelector: {
			combinedChunkedProductModelsCount = 0,
			label = '',
		} = {},
	} = props;

	const {
		selectorConfigModel = {},
		workspaceModel = {},
	} = useProductContext();

	const {
		settings: {
			insertSelectorHeadingTitle = '',
			insertSelectorHeadingTitlePrefix = '',
			showInsertSelectorHeading = false,
		} = {},
	} = selectorConfigModel;

	const {
		hasWorkspaceProducts = false,
		workspaceProducts = [],
	} = workspaceModel;

	return (
		<div
			className="selector-heading-insert selector-heading"
			data-qa={`${kebabCase(label)}-selector-heading`}
		>
			<div className="selector-heading-label-container">
				<SelectorHeadingLabel label={label} />
				{
					!isActive && hasWorkspaceProducts && combinedChunkedProductModelsCount > 0 && (
						<span
							className={styles['selector-heading-count']}
							data-qa={`${kebabCase(label)}-selector-heading-count`}
						>
							{combinedChunkedProductModelsCount} options
						</span>
					)
				}
			</div>
			{
				isActive && insertSelectorHeadingTitle && (
					<span className={classNames(styles['selector-heading-title'], 'tw-hidden md:tw-block')}>
						{insertSelectorHeadingTitle}
					</span>
				)
			}
			{
				showInsertSelectorHeading && !isActive && hasWorkspaceProducts && (
					<span
						className={`${styles['selector-heading-title']} tw-flex tw-flex-col`}
						data-qa={`${kebabCase(label)}-selector-heading-title`}
					>
						{
							workspaceProducts.map(({ workspaceProductDimensions = '' }, index) => {
								const workspaceDimensionsText = insertSelectorHeadingTitlePrefix
									? `${insertSelectorHeadingTitlePrefix} ${index + 1}: ${workspaceProductDimensions}`
									: workspaceProductDimensions;
								return (
									<span
										className="selector-heading-title-item"
										key={`insert-product-${index}`}
									>
										{workspaceDimensionsText}
									</span>
								);
							})
						}
					</span>
				)
			}
		</div>
	);
});
