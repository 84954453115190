import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import type { IWorkspaceProductsChunkSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductsChunkSectionals.interface';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { imageInchesToPixelsFactor } from '~/product/sectionals/workspace/workspace-sectionals.constants';

import styles from '~/product/sectionals/workspace/Components/product/workspace-products-chunk-width.module.scss';

interface Props {
	imageScale: number
	workspaceProductsChunkModel: IWorkspaceProductsChunkSectionals
}

export const WorkspaceProductsChunkWidthSectionals = observer(({
	imageScale = 0,
	workspaceProductsChunkModel: {
		isReversedDirection = false,
		workspaceProductModelsWithAdjacentWorkspaceProductModels = [],
		workspaceProductsChunkOrientation = '',
		workspaceProductsChunkWidth = 0,
	},
}: Props) => {
	const {
		workspaceModel: {
			isDragging = false,
		} = {},
	} = useProductContext();

	const workspaceProductsChunkPixelsWidth: number = workspaceProductModelsWithAdjacentWorkspaceProductModels.reduce((accumulatedWorkspaceProductsWidth, { workspaceProductWidth = 0 }) => {
		return accumulatedWorkspaceProductsWidth + (imageInchesToPixelsFactor * workspaceProductWidth) / imageScale;
	}, 0);

	return (
		<div
			className={
				classNames(
					styles['workspace-products-chunk-width'],
					styles[`workspace-products-chunk-width-${workspaceProductsChunkOrientation}`], {
						[styles['workspace-products-chunk-width-reversed']]: isReversedDirection,
						'tw-hidden': isDragging,
					}
				)
			}
			style={{ width: workspaceProductsChunkPixelsWidth }}
		>
			<span className={styles['workspace-products-chunk-width-value']}>
				{workspaceProductsChunkWidth}"
			</span>
		</div>
	);
});
