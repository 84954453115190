/* eslint-disable @typescript-eslint/no-use-before-define */
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { createDetailsModel } from '~/product/common/details/Helpers/Details.init';
import { BlurbModel } from '~/product/common/Models/Blurb.model';
import { createSelectorFiltersModel } from '~/product/common/selector/Helpers/SelectorFilters.init';
import { createSelectorFiltersMagicTabccordionStore } from '~/product/common/selector/Helpers/SelectorFiltersTabccordion.init';
import { SelectorProductModelFactory } from '~/product/common/selector/Models/SelectorProduct.model';
import { SelectorValueProductModelFactory } from '~/product/common/selector/Models/SelectorValueProduct.model';
import { SelectorFiltersStoreFactory } from '~/product/common/selector/Stores/SelectorFilters.store';
import { getStockedFiltersData, getStockedFiltersSelectorValues, setFilters } from '~/product/common/selector/Utils/SelectorFilters.utils';

export const createSelectorProductModel = ({
	hasConfigurationOverride = false,
	productModels = [],
	selectorData = {},
}: any) => {
	const {
		allowUnselected = false,
		blurbs: blurbsData = [],
		displayType = '',
		filter: filtersData = {},
		label = '',
		renderer = '',
		type = '',
		values: selectorValuesData = [],
	} = selectorData;

	const { linkEventStore } = useGlobalContext();

	const firstSelectorValueData = selectorValuesData[0] || {};

	const blurbs = blurbsData.map((blurb: any) => new BlurbModel(blurb));

	const selectorValues = selectorValuesData.map((selectorValueData = {}) => {
		return createSelectorValueProductModel({
			hasConfigurationOverride,
			parentDisplayType: displayType,
			parentLabel: label,
			parentRenderer: renderer,
			parentType: type,
			productModels,
			selectorValueData,
		});
	}).filter(Boolean);

	const stockedFiltersData = getStockedFiltersData(selectorValues);

	const hasStockedFilters = Boolean(Object.keys(stockedFiltersData).length);

	const stockedFiltersSelectorValues = getStockedFiltersSelectorValues(selectorValues, hasStockedFilters);

	const filtersModel = createSelectorFiltersModel({
		filtersData,
		selectorValues,
	});

	const filtersStore = SelectorFiltersStoreFactory.create({ filtersModel });

	const filtersMagicTabccordionStore = createSelectorFiltersMagicTabccordionStore(filtersStore, linkEventStore);

	const selectorProductModel = SelectorProductModelFactory.create({
		...selectorData,
		blurbs,
		filter: filtersData,
		filtersMagicTabccordionStore,
		filtersModel,
		filtersStore,
		firstSelectorValueData,
		selectorValues,
		unselected: !hasConfigurationOverride && allowUnselected,
	});

	// apply the stocked filters
	if (hasStockedFilters) {
		const stockedFiltersModel = createSelectorFiltersModel({
			filtersData: stockedFiltersData,
			parentSelectorModel: selectorProductModel,
			selectorValues: stockedFiltersSelectorValues,
		});

		const stockedFiltersStore = SelectorFiltersStoreFactory.create({ filtersModel: stockedFiltersModel });

		const stockedFiltersMagicTabccordionStore = createSelectorFiltersMagicTabccordionStore(stockedFiltersStore, linkEventStore);

		setFilters(selectorValues, stockedFiltersMagicTabccordionStore, stockedFiltersModel, stockedFiltersStore);
	}

	return selectorProductModel;
};

const createSelectorValueProductModel = ({
	hasConfigurationOverride = false,
	parentDisplayType = '',
	parentLabel = '',
	parentRenderer = '',
	parentType = '',
	productModels = [],
	selectorValueData = {},
	selectorValueData: {
		articleNumbers = [],
		details: detailsData = [],
		id = '',
		selectorDetails: selectorDetailsData = [],
		selectors: selectorsData = [],
	} = {},
}) => {
	const products = productModels.filter(({ articleNumber: articleNumberToLookup }) => articleNumbers.includes(articleNumberToLookup));

	if (!products.length) {
		console.warn(`Could not create selectorValue for id "${id}". Could not find a product(s) for article(s) ${articleNumbers}.`);

		return null;
	}

	const detailsModel = createDetailsModel(detailsData);

	//TODO: this is a shim until the server stops sending null for selectorDetails on non-configurables
	const selectorDetailsDataToMap = selectorDetailsData || [];

	const selectorDetailsModel = createDetailsModel(selectorDetailsDataToMap);

	const selectors = selectorsData.map((selectorData: any = {}) => {
		return createSelectorProductModel({
			hasConfigurationOverride,
			productModels,
			selectorData,
		});
	});

	const selectorValueProductModel = SelectorValueProductModelFactory.create({
		...selectorValueData,
		detailsModel,
		parentDisplayType,
		parentLabel,
		parentRenderer,
		parentType,
		products,
		selectorDetailsModel,
		selectors,
	});

	return selectorValueProductModel;
};
