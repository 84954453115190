import { observer } from 'mobx-react';
import React from 'react';

import { DetailLink } from '~/product/common/details/Components/details/DetailLink';
import styles from '#/product/standard/details/details-attributes.module.scss';
import stylesDetail from '#/product/standard/details/details.module.scss';

export const DetailNameValue = observer((props) => {
	const {
		detail = {},
		detail: {
			displayText = '',
			hasLink = false,
			label = '',
		} = {},
	} = props;

	return (
		<div
			className={`${styles['detail']} ${stylesDetail['detail']} detail-name-value`}
			data-qa="detail-name-value"
		>
			<strong data-qa="detail-name-value-name">{label}{displayText && ':'}</strong>
			<span data-qa={label.toLowerCase().endsWith('item number') ? 'details-article-number' : 'detail-name-value-value'}> {displayText} </span>
			{
				hasLink && (
					<DetailLink detail={detail} />
				)
			}
		</div>
	);
});
