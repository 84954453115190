import { createDetailsModel } from '~/product/common/details/Helpers/Details.init';
import { ProductAnswerModelFactory } from '~/product/common/product/Models/ProductAnswer.model';
import { ProductQuestionModelFactory } from '~/product/common/product/Models/ProductQuestion.model';
import { InsertProductModelFactory } from '~/product/casegoods/Models/InsertProduct.model';

const createAnswersModel = (answersData = [], forceSelected = false) => {
	const answersModel = answersData.map((answerData) => {
		const answerModel = ProductAnswerModelFactory.create({
			...answerData,
			selected: forceSelected,
		});

		return answerModel;
	});

	return answersModel;
};

const createQuestionsModel = (questionsData = []) => {
	const questionsModel = questionsData.map((questionData) => {
		const {
			answers: answersData = [],
			mappedFromFrameQuestionGroup: mappedFromFrameQuestionGroupData = '',
		} = questionData;

		const forceSelected = mappedFromFrameQuestionGroupData === 'INSERT';

		const answersModel = createAnswersModel(answersData, forceSelected);

		const questionModel = ProductQuestionModelFactory.create({
			...questionData,
			answersModel,
			mappedFromQuestionGroup: mappedFromFrameQuestionGroupData,
		});

		return questionModel;
	});

	return questionsModel;
};

export const createInsertProductModel = ({
	configurationOverrideImageData = {},
	insertProductData = {},
}) => {
	const {
		articleNumber = '',
		detail: {
			additionalInformation: detailsAdditionalData = [],
			details: detailsAttributesData = [],
			dimensions: detailsDimensionsData = [],
			insertDynamicDetails = {},
			materials: detailsMaterialsData = [],
			sustainability: detailsSustainabilityData = [],
		} = {},
		filters: filtersData = [],
		imageUrl: productImageUrl = '',
		questions: questionsData = [],
	} = insertProductData;

	const filters = filtersData.length ? { types: filtersData } : {};

	const matchingConfigurationOverrideImageData = configurationOverrideImageData[articleNumber] || {};

	const { imageUrl: matchingConfigurationOverrideImageUrl = '' } = matchingConfigurationOverrideImageData;

	const imageUrl = matchingConfigurationOverrideImageUrl || productImageUrl;

	const detailsAdditionalModel = createDetailsModel(detailsAdditionalData);

	const detailsAttributesModel = createDetailsModel(detailsAttributesData);

	const detailsDimensionsModel = createDetailsModel(detailsDimensionsData);

	const detailsMaterialsModel = createDetailsModel(detailsMaterialsData);

	const detailsSustainabilityModel = createDetailsModel(detailsSustainabilityData);

	const questionModels = createQuestionsModel(questionsData);

	const insertProductModel = InsertProductModelFactory.create({
		...insertProductData,
		detailsAdditionalModel,
		detailsAttributesModel,
		detailsDimensionsModel,
		detailsMaterialsModel,
		detailsSustainabilityModel,
		filters,
		imageUrl,
		insertDynamicDetails,
		questionModels,
	});

	return insertProductModel;
};

export const createInsertProductModels = ({
	configurationOverrideModel = {},
	insertProductsData = [],
}) => {
	const {
		imageData: {
			inserts: configurationOverrideImageData = {},
		} = {},
		insertProducts: configurationOverrideInsertProductsData = [],
	} = configurationOverrideModel;

	const insertProductDataToUse = configurationOverrideInsertProductsData.length ? configurationOverrideInsertProductsData : insertProductsData;

	const insertProductModels = insertProductDataToUse.map((insertProductData = {}) => createInsertProductModel({
		configurationOverrideImageData,
		insertProductData,
	}));

	return insertProductModels;
};
