import {
	action, computed, extendObservable, observable, reaction, makeObservable,
} from 'mobx';
import axios from 'axios';

import { curalateUploadFormModel, curalateUploadFormSettings } from '~/util/curalate/Models/CuralateUpload.model';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { curalateApiUrl } from '~/global/global.constants';

const uploadSources = [
	{
		active: true,
		source: 'Upload',
	},
	{
		active: false,
		source: 'Instagram',
	},
	{
		active: false,
		source: 'Facebook',
	},
];

const fileObsFactory = (fileData) => {
	const {
		caption = '',
		selected = false,
	} = fileData;

	return extendObservable(fileData, {
		caption,
		selected,
	});
};

const uploadTypeObsFactory = (uploadSource, index) => {
	const { active } = uploadSource;

	return extendObservable({
		...uploadSource,
		index,
	}, { active });
};

class CuralateUploadStore {
	facebookToken = null;

	files = [];

	form = null;

	hasError = false;

	instagramToken = null;

	isImagesComplete = false;

	isSubmitted = false;

	uploadTypes = uploadSources.map((uploadSource, index) => uploadTypeObsFactory(uploadSource, index));

	get activeUploadType() {
		const { uploadTypes } = this;

		return uploadTypes.find(({ active = false }) => active);
	}

	get facebookFiles() {
		const { files } = this;

		return files.filter(file => file.source === 'Facebook');
	}

	get instagramFiles() {
		const { files } = this;

		return files.filter(file => file.source === 'Instagram');
	}

	get selectedFiles() {
		const { files } = this;

		return files.filter(file => file.selected);
	}

	get uploadFiles() {
		const { files } = this;

		return files.filter(file => file.source === 'Desktop');
	}

	dataSourceId = null;

	fanreelId = null;

	constructor(settings) {
		makeObservable(this, {
			facebookToken: observable,
			files: observable,
			form: observable,
			hasError: observable,
			instagramToken: observable,
			isImagesComplete: observable,
			isSubmitted: observable,
			uploadTypes: observable,
			activeUploadType: computed,
			facebookFiles: computed,
			instagramFiles: computed,
			selectedFiles: computed,
			uploadFiles: computed,
			addFacebookMedia: action.bound,
			addFiles: action.bound,
			addInstagramMedia: action.bound,
			resetUploadCuralate: action.bound,
			setFacebookToken: action.bound,
			setInstagramToken: action.bound,
			setIsImagesComplete: action.bound,
			setUploadTypeActive: action.bound,
			submitCuralateMedia: action,
			toggleFileSelected: action,
			updateFileCaption: action,
			uploadCuralateBytes: action,
			controlReactPropsOverride: computed,
		});

		Object.assign(this, settings);

		this.form = new FormBuilder(
			curalateUploadFormModel({}, this),
			curalateUploadFormSettings(this),
		);

		reaction(() => this.facebookToken, (facebookToken) => {
			if (facebookToken) {
				this.addFacebookMedia();
			}
		});

		reaction(() => this.instagramToken, (instagramToken) => {
			if (instagramToken) {
				this.addInstagramMedia();
			}
		});

		reaction(() => this.hasError, (hasError) => {
			this.magicModal.alterModal({
				title: hasError ? 'Error' : 'Select Your Content',
			});
		});

		reaction(() => this.isImagesComplete, (isImagesComplete) => {
			this.magicModal.alterModal({
				title: isImagesComplete ? 'Tell Us About Yourself' : 'Select Your Content',
			});
		});

		reaction(() => this.isSubmitted, (isSubmitted) => {
			this.magicModal.alterModal({
				title: isSubmitted ? 'Success' : 'Select Your Content',
			});
		});
	}

	addFacebookMedia() {
		this.getFacebookMedia().then((resp) => {
			const { items } = resp.data.data;

			const files = items.map((item, index) => {
				return fileObsFactory(
					Object.assign(item, {
						name: `Facebook image ${index + 1}`,
						selected: false,
						source: 'Facebook',
					}),
				);
			});

			this.files.push(...files);
		}).catch(() => {
			this.hasError = true;
		});
	}

	addFiles(filesToAdd) {
		this.uploadCuralateBytes(filesToAdd).then((resp) => {
			const { images } = resp.data.data;

			const files = filesToAdd.map((fileToAdd, index) => {
				const { name } = fileToAdd;

				const { link } = images[index].original;

				return fileObsFactory({
					link,
					name,
					selected: true,
					source: 'Desktop',
				});
			});

			this.files.push(...files);
		}).catch(() => {
			this.hasError = true;
		});
	}

	addInstagramMedia() {
		this.getInstagramMedia().then((resp) => {
			const { items } = resp.data.data;

			const files = items.map((item, index) => {
				return fileObsFactory(
					Object.assign(item, {
						name: `Instagram image ${index + 1}`,
						selected: false,
						source: 'Instagram',
					}),
				);
			});

			this.files.push(...files);
		}).catch(() => {
			this.hasError = true;
		});
	}

	// if the user hits 'back' on the Tell Us About Yourself screen we go back to the upload form which has a value in the files field
	//we need to set the upload files field value to an empty string, setting it programmatically as anything other than an empty string
	//is a security issue and the site errors out.
	get controlReactPropsOverride() {
		if (!this.isImagesComplete) {
			return {
				'value': '',
			};
		}
		return {};
	}

	getFacebookMedia() {
		const {
			facebookToken,
		} = this;

		return axios.get(`${curalateApiUrl}/v1/facebook/media?accessToken=${facebookToken}`);
	}

	getInstagramMedia() {
		const {
			instagramToken,
		} = this;

		return axios.get(`${curalateApiUrl}/v1/instagram/media?accessToken=${instagramToken}`);
	}

	resetUploadCuralate() {
		const { model } = this.form;

		Object.assign(model, { files: [] });

		Object.assign(this, {
			facebookToken: null,
			files: [],
			hasError: false,
			instagramToken: null,
			isImagesComplete: false,
			isSubmitted: false,
		});

		this.setUploadTypeActive(this.uploadTypes[0]);
	}

	setFacebookToken(facebookToken) {
		this.facebookToken = facebookToken;
	}

	setInstagramToken(instagramToken) {
		this.instagramToken = instagramToken;
	}

	setIsImagesComplete(isImagesComplete) {
		Object.assign(this, { isImagesComplete });
	}

	setUploadTypeActive(uploadTypeToSetActive) {
		this.uploadTypes.forEach((uploadType) => {
			uploadType.active = false;
		});

		uploadTypeToSetActive.active = true;
	}

	submitCuralateMedia(formModel) {
		const {
			dataSourceId,
			fanreelId,
			selectedFiles,
		} = this;

		const media = selectedFiles.map((file) => {
			const {
				caption,
				source,
				link: url,
			} = file;

			return {
				caption,
				source,
				type: 'Image',
				url,
			};
		});

		const {
			displayName,
			emailAddress,
		} = formModel;

		const postData = {
			displayName,
			emailAddress,
			emailOptIn: false,
			media,
			mediaContainerId: fanreelId,
			mediaContainerType: 'Fanreel',
		};

		return axios.post(`${curalateApiUrl}/v1/upload/${dataSourceId}/submit`, postData);
	}

	toggleFileSelected(fileToToggleSelected) {
		fileToToggleSelected.selected = !fileToToggleSelected.selected;
	}

	updateFileCaption(fileToUpdateCaption, caption) {
		fileToUpdateCaption.caption = caption;
	}

	uploadCuralateBytes(files) {
		const {
			dataSourceId,
		} = this;

		const postData = new FormData();

		for (let i = 0; i < files.length; i++) {
			const file = files[i];

			postData.append(file.name, file, file.name);
		}

		return axios.post(`${curalateApiUrl}/v1/upload/${dataSourceId}`, postData);
	}
}

export { CuralateUploadStore };
