import {
	_async,
	_await,
	idProp,
	Model, model, modelFlow, prop,
} from 'mobx-keystone';
import axios, { AxiosResponse } from 'axios';

import { modelNamespace } from '~/util/modelNamespace';

import { apiUrl } from '~/global/global.constants';
import { IFavoritesListSummary } from '~/favorites/Types/Favorites.interface';
import { AddToFavoritesButtonModel } from '~/components/Buttons/ButtonAddToFavorites/Models/AddToFavoritesButton.model';

@model(`${modelNamespace.COMPONENT}/AddToFavoritesStore`)
export class AddToFavoritesButtonStore extends Model({
	id: idProp.withSetter(),
	model: prop<AddToFavoritesButtonModel>(),
}) {
	@modelFlow
	getTargetFavoriteListID = _async(function* (this: AddToFavoritesButtonStore) {
		try {
			const promise = axios
				.get(`${apiUrl}/api/favorites`);
			const {
				data: {
					favoritesListSummaries = [],
				} = {},
			}: AxiosResponse<any> = yield* _await(promise);

			const favList = favoritesListSummaries.filter((fav: IFavoritesListSummary) => fav.defaultList);

			const favListID = favList[0].id || '';
			this.model.setTargetFavoriteListID(favListID);
		} catch (error) {
			console.log(error);
		}
	});

	@modelFlow
	getFavoritesProperties = _async(function* (this: AddToFavoritesButtonStore) {
		try {
			const promise = axios
				.get(`${apiUrl}${this.model.addToFavoritesPropertiesAPI}`);
			const {
				data = {},
			}: AxiosResponse<any> = yield* _await(promise);

			const {
				articleNumber = '',
				configuration = [],
				productGroupKey = '',
				salesText = '',
			} = data;

			this.model.setArticleNumber(articleNumber);
			this.model.setConfiguration(configuration);
			this.model.setProductGroupKey(productGroupKey);
			this.model.setSalesText(salesText);

			if (!this.model.targetFavoriteListID) {
				yield this.getTargetFavoriteListID();
			}
		} catch (error) {
			console.error(error);
		}
	});
}
