import { observer } from 'mobx-react';
import React from 'react';

import { Reviews } from '~/util/power-reviews/Components/Reviews';
import { QuestionAndAnswers } from '~/util/power-reviews/Components/QuestionAndAnswers';

const ProductDetailTab = observer((props) => {
	const TabComponent = props.tab.content;

	return (
		<div className="power-reviews-tab-content">
			<TabComponent />
		</div>
	);
});

export const generateDetailsTabData = () => {
	return {
		allowMultipleAccordionsOpen: false,
		id: 'product-detail-tabs',
		renderMode: 'TABS',
		responsiveAccordionDefaultClosed: true, // on mobile make accordions default closed?
		tabSettings: {
			tabTitleClass: 'product-page-minimal-tabs',
			tabTitleTag: 'div',
		},
		wrapperRenderer: ProductDetailTab,
		tabGroups: [
			{
				tabs: [
					{
						title: 'Reviews',
						tabTitleClass: 'tw-uppercase',
						listElementClass: 'Tabccordion-listItem--minimal',
						content: observer(() => {
							return (
								<Reviews />
							);
						}),
					},
					{
						title: 'Q & A',
						tabTitleClass: 'tw-uppercase',
						listElementClass: 'Tabccordion-listItem--minimal',
						content: observer(() => {
							return (
								<QuestionAndAnswers />
							);
						}),
					},
				],
			},
		],
	};
};
