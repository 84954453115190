/* eslint-disable arrow-parens */
import dynamic from 'next/dynamic';
import React from 'react';

import { isOnServer } from '~/global/global.constants';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';

const AnsiBIFMA = dynamic(
	() => import('~/product/common/details/Components/modals/Ansi-BIFMA').then((module) => module.AnsiBIFMA),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const ASTMAntiTip = dynamic(
	() => import('~/product/common/details/Components/modals/ASTM-AntiTip').then((module) => module.ASTMAntiTip),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const ASTMCertification = dynamic(
	() => import('~/product/common/details/Components/modals/ASTM-Certification').then((module) => module.ASTMCertification),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const C2CBronze = dynamic(
	() => import('~/product/common/details/Components/modals/C2C-Bronze').then((module) => module.C2CBronze),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const C2CSilver = dynamic(
	() => import('~/product/common/details/Components/modals/C2C-Silver').then((module) => module.C2CSilver),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const CushionDownVideo = dynamic(
	() => import('~/product/common/details/Components/modals/Cushion-Down').then((module) => module.CushionDownVideo),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const CushionFoamVideo = dynamic(
	() => import('~/product/common/details/Components/modals/Cushion-Foam').then((module) => module.CushionFoamVideo),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const CushionTightVideo = dynamic(
	() => import('~/product/common/details/Components/modals/Cushion-Tight').then((module) => module.CushionTightVideo),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const FireTabeCertification = dynamic(
	() => import('~/product/common/details/Components/modals/Fire-Table-Certification').then((module) => module.FireTabeCertification),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const FitVideoModal = dynamic(
	() => import('~/product/common/details/Components/modals/FitVideoModal').then((module) => module.FitVideoModal),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const LightingLEDBulbs = dynamic(
	() => import('~/product/common/details/Components/modals/Lighting-LEDBulbs').then((module) => module.LightingLEDBulbs),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const LightingTypes = dynamic(
	() => import('~/product/common/details/Components/modals/Lighting-Types').then((module) => module.LightingTypes),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const LightingUL = dynamic(
	() => import('~/product/common/details/Components/modals/Lighting-UL').then((module) => module.LightingUL),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const MaterialSafetyData = dynamic(
	() => import('~/product/common/details/Components/modals/MaterialSafetyData').then((module) => module.MaterialSafetyData),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const MattressGuarantee = dynamic(
	() => import('~/product/common/details/Components/modals/Mattress-Guarantee').then((module) => module.MattressGuarantee),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const PaintRecycling = dynamic(
	() => import('~/product/common/details/Components/modals/Paint-Recycling').then((module) => module.PaintRecycling),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const Prop65CarbonMonoxide = dynamic(
	() => import('~/product/common/details/Components/modals/Prop65-CarbonMonoxide').then((module) => module.Prop65CarbonMonoxide),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const Prop65DEHP = dynamic(
	() => import('~/product/common/details/Components/modals/Prop65-DEHP').then((module) => module.Prop65DEHP),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const Prop65Formaldehyde = dynamic(
	() => import('~/product/common/details/Components/modals/Prop65-Formaldehyde').then((module) => module.Prop65Formaldehyde),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const Prop65General = dynamic(
	() => import('~/product/common/details/Components/modals/Prop65-General').then((module) => module.Prop65General),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const Prop65Lead = dynamic(
	() => import('~/product/common/details/Components/modals/Prop65-Lead').then((module) => module.Prop65Lead),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const Prop65WoodDust = dynamic(
	() => import('~/product/common/details/Components/modals/Prop65-WoodDust').then((module) => module.Prop65WoodDust),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const Standard100 = dynamic(
	() => import('~/product/common/details/Components/modals/Standard-100').then((module) => module.Standard100),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const SuspensionDualFlex = dynamic(
	() => import('~/product/common/details/Components/modals/Suspension-DualFlex').then((module) => module.SuspensionDualFlex),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const SuspensionSinuousCoil = dynamic(
	() => import('~/product/common/details/Components/modals/Suspension-SinuousCoil').then((module) => module.SuspensionSinuousCoil),
	{ loading: () => <LoadingSpinner isLoading /> },
);

const SuspensionWebbing = dynamic(
	() => import('~/product/common/details/Components/modals/Suspension-Webbing').then((module) => module.SuspensionWebbing),
	{ loading: () => <LoadingSpinner isLoading /> },
);

export const detailModalComponents = () => {
	const modalWidth = () => {
		if (isOnServer) {
			return '60vw';
		}
		return window.matchMedia('(min-width: 40em)').matches ? '60vw' : '90vw';
	};

	const suspensionModalWidth = () => {
		if (isOnServer) {
			return '450px';
		}
		return window.matchMedia('(min-width: 40em)').matches ? '450px' : '90vw';
	};

	const modalData = {
		'ANSI_BIFMA': {
			id: 'detail-modal',
			title: 'BIFMA Conformance',
			width: modalWidth(),
			content: {
				children: <AnsiBIFMA />,
			},
		},
		'ASTM_ANTI_TIP': {
			id: 'detail-modal',
			title: 'Anti-tip Safety Certification',
			width: modalWidth(),
			content: {
				children: <ASTMAntiTip />,
			},
		},
		'C2C_BRONZE': {
			id: 'detail-modal',
			title: 'Cradle to Cradle Certified™',
			width: modalWidth(),
			content: {
				children: <C2CBronze />,
			},
		},
		'C2C_SILVER': {
			id: 'detail-modal',
			title: 'Cradle to Cradle Certified™',
			width: modalWidth(),
			content: {
				children: <C2CSilver />,
			},
		},
		'CPSC_ASTM_F2057': {
			id: 'detail-modal',
			title: 'Anti-tip Safety Certification',
			width: modalWidth(),
			content: {
				children: <ASTMCertification />,
			},
		},
		'CUSHION_DOWN_VIDEO': {
			id: 'detail-modal',
			title: 'Down Cushion Maintenance',
			width: modalWidth(),
			content: {
				children: <CushionDownVideo />,
			},
		},
		'CUSHION_FOAM_VIDEO': {
			id: 'detail-modal',
			title: 'Foam Cushion Maintenance',
			width: modalWidth(),
			content: {
				children: <CushionFoamVideo />,
			},
		},
		'CUSHION_TIGHT_VIDEO': {
			id: 'detail-modal',
			title: 'Tight Cushion Maintenance',
			width: modalWidth(),
			content: {
				children: <CushionTightVideo />,
			},
		},
		'FIRE_TABLE_CERTIFICATION': {
			id: 'detail-modal',
			title: 'Fire Table Safety Certifications',
			width: modalWidth(),
			content: {
				children: <FireTabeCertification />,
			},
		},
		'FIT_MODAL_VIDEO': {
			id: 'detail-modal',
			title: 'Measuring for Furniture Delivery',
			width: modalWidth(),
			content: {
				children: <FitVideoModal />,
			},
		},
		'LIGHTING_LED_BULBS': {
			id: 'detail-modal',
			title: 'Room & Board LED Light Bulbs',
			width: modalWidth(),
			content: {
				children: <LightingLEDBulbs />,
			},
		},
		'LIGHTING_TYPES': {
			id: 'detail-modal',
			title: 'Lighting Types',
			width: modalWidth(),
			content: {
				children: <LightingTypes />,
			},
		},
		'LIGHTING_UL': {
			id: 'detail-modal',
			title: 'UL/ETL Listed',
			width: modalWidth(),
			content: {
				children: <LightingUL />,
			},
		},
		'MATTRESS_GUARANTEE': {
			id: 'detail-modal',
			title: 'Our Guarantee',
			width: modalWidth(),
			content: {
				children: <MattressGuarantee />,
			},
		},
		'PAINT_RECYCLING': {
			id: 'detail-modal',
			title: 'Paint Recycling Fee',
			width: modalWidth(),
			content: {
				children: <PaintRecycling />,
			},
		},
		'PROP_65_CARBON_MONOXIDE': {
			id: 'detail-modal',
			title: 'Carbon Monoxide',
			width: modalWidth(),
			content: {
				children: <Prop65CarbonMonoxide />,
			},
		},
		'PROP_65_DEHP': {
			id: 'detail-modal',
			title: 'DEHP',
			width: modalWidth(),
			content: {
				children: <Prop65DEHP />,
			},
		},
		'PROP_65_FORMALDEHYDE': {
			id: 'detail-modal',
			title: 'Formaldehyde',
			width: modalWidth(),
			content: {
				children: <Prop65Formaldehyde />,
			},
		},
		'PROP_65_LEAD': {
			id: 'detail-modal',
			title: 'Lead',
			width: modalWidth(),
			content: {
				children: <Prop65Lead />,
			},
		},
		'PROP_65_WOOD_DUST': {
			id: 'detail-modal',
			title: 'Wood Dust',
			width: modalWidth(),
			content: {
				children: <Prop65WoodDust />,
			},
		},
		'PROP_65_GENERAL': {
			id: 'detail-modal',
			title: 'California Proposition 65 Warning',
			width: modalWidth(),
			content: {
				children: <Prop65General />,
			},
		},
		'STANDARD_100': {
			id: 'detail-modal',
			title: 'STANDARD 100 by OEKO-TEX®',
			width: modalWidth(),
			content: {
				children: <Standard100 />,
			},
		},
		'SUSPENSION_DUAL_FLEX': {
			id: 'detail-modal',
			title: 'Dual Flexolator Seat Suspension',
			width: suspensionModalWidth(),
			content: {
				children: <SuspensionDualFlex />,
			},
		},
		'SUSPENSION_SINUOUS_COIL': {
			id: 'detail-modal',
			title: 'Sinuous Coil Seat Suspension',
			width: suspensionModalWidth(),
			content: {
				children: <SuspensionSinuousCoil />,
			},
		},
		'SUSPENSION_WEBBING': {
			id: 'detail-modal',
			title: 'Webbing Seat Suspension',
			width: suspensionModalWidth(),
			content: {
				children: <SuspensionWebbing />,
			},
		},
		'WOOD_CLEANER': {
			id: 'detail-modal',
			title: 'Material Safety Data Sheet',
			width: modalWidth(),
			content: {
				children: <MaterialSafetyData materialKey="woodCleaner" />,
			},
		},
	};

	return modalData;
};
