import { observer } from 'mobx-react';
import React from 'react';

import { SelectorValueRadio } from '~/product/casegoods/selector/Components/radio/SelectorValueRadio';

import styles from '~/product/casegoods/selector/Components/radio/selector-radio.module.scss';

export const SelectorRadio = observer((props = {}) => {
	const {
		selectedSelector: {
			label = '',
			selectorValues = [],
		} = {},
	} = props;

	return (
		<div className={styles['selector-radio']}>
			<div className={styles['selector-radio-label']}>
				{label}
			</div>
			<fieldset>
				<div className={styles['selector-values-radio']}>
					{
						selectorValues.map((selectorValue = {}, index) => {
							return (
								<SelectorValueRadio
									key={`selector-${index}`}
									label={label}
									selectorValue={selectorValue}
								/>
							);
						})
					}
				</div>
			</fieldset>
		</div>
	);
});
