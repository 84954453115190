import {
	computed, observable, makeObservable,
} from 'mobx';

import type { IProductSectionals } from '~/product/sectionals/Interfaces/ProductSectionals.interface';
import type { ISelectorProduct } from '~/product/sectionals/selector/Interfaces/SelectorProduct.interface';
import type { ISelectorValueProduct } from '~/product/sectionals/selector/Interfaces/SelectorValueProduct.interface';
import type { ISelectorPromo } from '~/product/sectionals/selector/Interfaces/SelectorPromo.interface';

export class SelectorProductModel implements ISelectorProduct {
	displayOrder: number;

	isValid: Boolean;

	label: string;

	productModels: Array<IProductSectionals>;

	renderer: string;

	selected: boolean;

	selectorValues: Array<ISelectorValueProduct>;

	type: string;

	promos: Array<ISelectorPromo>;

	constructor({
		displayOrder = 0,
		isValid = true,
		label = '',
		productModels = [],
		renderer = '',
		selected = false,
		selectorValues = [],
		type = '',
		promos = [],
	}: any = {}) {
		makeObservable(this, {
			isValid: observable,
			selected: observable,
			selectedSelectorValue: computed,
			selectorValuesCount: computed,
			spoSelectorValues: computed,
			spoSelectorValuesCount: computed,
			stockedSelectorValues: computed,
			stockedSelectorValuesCount: computed,
		});

		this.displayOrder = displayOrder;
		this.isValid = isValid;
		this.label = label;
		this.productModels = productModels;
		this.renderer = renderer;
		this.selected = selected;
		this.selectorValues = selectorValues;
		this.type = type;
		this.promos = promos;
	}

	get selectedSelectorValue(): ISelectorValueProduct {
		return this.selectorValues.find(({ selected = false }) => selected) || this.selectorValues[0];
	}

	get selectorValuesCount(): number {
		return this.selectorValues.length;
	}

	get selectorValuesToRender(): Array<ISelectorValueProduct> {
		return this.selectorValues;
	}

	get spoSelectorValues(): Array<ISelectorValueProduct> {
		return this.selectorValuesToRender.filter(({ stocked = false }) => !stocked);
	}

	get spoSelectorValuesCount(): number {
		return this.spoSelectorValues.length;
	}

	get stockedSelectorValues(): Array<ISelectorValueProduct> {
		return this.selectorValuesToRender.filter(({ stocked = false }) => stocked);
	}

	get stockedSelectorValuesCount(): number {
		return this.stockedSelectorValues.length;
	}
}

export const SelectorProductModelFactory = ({
	create: (data: any = {}) => {
		return new SelectorProductModel(data);
	},
});
