import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import classNames from 'classnames';

import { S7Image } from '~/components/Images/S7Image';
import { scaleLayeredImage } from '~/util/scaleLayeredImage';
import { addToUrl } from '~/util/addToUrl';
import { useDrift } from '~/hooks/useDrift';

export const MediaImage = observer((props = {}) => {
	const {
		imageHeight = 0,
		imagePreset = '',
		imageWidth = 0,
		isMediaThumbnail = false,
		mediaModel: {
			altText = '',
			imageUrl = '',
		} = {},
		shouldLoad = false,
		showDriftZoom = false,
	} = props;
	const mediaImageRef = useRef();
	useDrift({ driftRef: mediaImageRef, showDriftZoom });
	const src = addToUrl(imageUrl, imagePreset);
	const zoomSrc = scaleLayeredImage(src, imageWidth * 2, imageHeight * 2);
	return (<S7Image
		alt={altText}
		className={classNames({ 'show-drift': showDriftZoom })}
		data-zoom={showDriftZoom ? zoomSrc : null}
		height={imageHeight}
		priority={shouldLoad}
		ref={mediaImageRef}
		sizes={!isMediaThumbnail ? `(max-width: 900px) 100vw, ${imageWidth}px` : null}
		src={src}
		width={imageWidth}
	/>);
});
