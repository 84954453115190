import { makeObservable, observable } from 'mobx';

import { ISelectorDownload } from '../Interfaces/SelectorDownload.interface';

export class SelectorDownload implements ISelectorDownload {
	isValid = false;

	label = 'Download Your Design';

	renderer: 'DOWNLOAD' = 'DOWNLOAD';

	type: string = 'DOWNLOAD';

	constructor({
		isValid, label, renderer, type,
	}: ISelectorDownload) {
		makeObservable(this, {
			isValid: observable,
		});
		this.isValid = isValid;
		this.label = label;
		this.renderer = renderer;
		this.type = type;
	}
}
