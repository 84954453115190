import { sortBy } from '~/util/sortBy';

const getDetailsColumnsByTypes = (columnsMap = [], detailsAttributesModel = []) => {
	return columnsMap
		.reduce((columnsModel, columnMap) => {
			const reservedColumnTypes = [].concat(...columnsMap);
			const mappedColumnTypes = [].concat(...columnsModel).map(({ type = '' }) => type);

			columnsModel.push(
				detailsAttributesModel.filter(({ type = '' }) => {
					return (
						(columnMap.includes(type) && !mappedColumnTypes.includes(type))
						|| (
							!columnMap.length
							&& !reservedColumnTypes.includes(type)
							&& !mappedColumnTypes.includes(type)
						)
					);
				}),
			);

			return columnsModel;
		}, [])
		.filter(columnModel => columnModel.length > 0);
};

const getProcessedDetailsData = (detailsData = []) => {
	let remainingDetails = [...detailsData];

	// deal with detailGroups
	const processedDetailsData = detailsData.reduce((accumlatedDetailsData, detailData) => {
		// if detail already belongs to a detailsGroup, throw it out
		if (!remainingDetails.includes(detailData)) {
			return accumlatedDetailsData;
		}

		const { type = '' } = detailData;

		const isNameValueGroupDetail = type === 'nameValue_group';

		if (!isNameValueGroupDetail) {
			remainingDetails = remainingDetails.slice(1, remainingDetails.length);

			return [...accumlatedDetailsData, detailData];
		}

		// convert nameValue_group detail to a nameValue detail, now that we've identified the group
		const processedDetailData = {
			...detailData,
			type: 'nameValue',
		};

		const nextDetails = remainingDetails.slice(1, detailsData.length);

		const nextNameValueGroupDetail = nextDetails.find(({ type: typeToFind = '' }) => typeToFind === 'nameValue_group');

		const nextNameValueGroupIndex = nextNameValueGroupDetail ? remainingDetails.indexOf(nextNameValueGroupDetail) : remainingDetails.length;

		const detailsGroupData = [processedDetailData, ...remainingDetails.slice(1, nextNameValueGroupIndex)];

		remainingDetails = remainingDetails.slice(nextNameValueGroupIndex, remainingDetails.length);

		return [...accumlatedDetailsData, { ...detailData, detailsGroupData }];
	}, []);

	return processedDetailsData;
};

const getComponentDetailsByIds = (componentIds = [], selectorConfigModel = {}) => {
	const {
		selectedProduct: {
			components: selectedProductComponents = [],
		} = {},
		selectedProductQuestionAnswers = [],
	} = selectorConfigModel;

	function getComponentsByIds() {
		const componentsByIds = selectedProductComponents
			.filter((component) => {
				try {
					return componentIds.includes(component.articleNumber);
				} catch (error) {
					return false;
				}
			});

		return sortBy(componentsByIds, 'sort');
	}

	function calculateDynamicValue(detail) {
		const dimensionAnswer = selectedProductQuestionAnswers.find(({ group = '' }) => detail.questionGroup === group)?.selectedAnswer;

		if (!dimensionAnswer || !dimensionAnswer.key) {
			return 0;
		}

		return parseInt(dimensionAnswer.key, 10) - parseFloat(detail.subtrahend);
	}

	const components = getComponentsByIds(componentIds);
	const detailSets = components
		.map(({ detail = {} }) => detail);

	if (!components.length) {
		return [];
	}

	return Object.values(detailSets)
		.map((componentDetails) => {
			Object.values(componentDetails)
				.forEach((detailList) => {
					detailList.forEach((detail) => {
						const {
							label,
							subtrahend,
							suffix = '',
						} = detail;

						if (subtrahend) {
							const dynamicValue = calculateDynamicValue(detail);

							if (!dynamicValue) {
								console.error('This product failed to display detail:', label);
								return null;
							}

							detail.displayText = `${dynamicValue.toString()}${suffix}`;
						}

						return true;
					});
				});

			return componentDetails;
		});
};

export {
	getDetailsColumnsByTypes,
	getProcessedDetailsData,
	getComponentDetailsByIds,
};
