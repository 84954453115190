import React from 'react';

import { PromoComponents } from './PromoComponents';

import { useGlobalContext } from '~/global/Contexts/Global.context';

export const ProductPromos = (props) => {
	const {
		listClassName = '',
		trLinkEventCompName = null,
		trLinkEventCompType = null,
		promos = [],
	} = props;

	const {
		featureTogglesModel: {
			isOn,
		} = {},
	} = useGlobalContext();

	if (promos.length === 0) {
		return null;
	}

	const lowercaseKeyedComponents = {};
	Object.keys(PromoComponents).forEach((key) => {
		lowercaseKeyedComponents[key.toLowerCase()] = PromoComponents[key];
	});

	return (
		<ul className={`${listClassName}`} data-tr-link-event-comp-name={trLinkEventCompName} data-tr-link-event-comp-type={trLinkEventCompType}>
			{
				promos.map((promo, index) => {
					const { key } = promo;

					if (!key) {
						return null;
					}

					const MessageTag = lowercaseKeyedComponents[key.toLowerCase()] || (isOn('DEBUG_MODE') && PromoComponents.MISSING);
					if (!MessageTag) {
						return null;
					}

					return (
						<MessageTag
							key={`message-${index}`}
							promo={promo}
							promoKey={key}
						/>
					);
				})
			}
		</ul>
	);
};
