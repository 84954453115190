import { createDetailsModel } from '~/product/common/details/Helpers/Details.init';
import { ProductAnswerModelFactory } from '~/product/common/product/Models/ProductAnswer.model';
import { ProductQuestionModelFactory } from '~/product/common/product/Models/ProductQuestion.model';
import { FrameProductModelFactory } from '~/product/casegoods/Models/FrameProduct.model';
import { sortBy } from '~/util/sortBy';

const createAnswersModel = (answersData = []) => {
	const answersModel = answersData.map((answerData) => {
		const answerModel = ProductAnswerModelFactory.create(answerData);

		return answerModel;
	});

	return answersModel;
};

const createQuestionsModel = (questionsData = []) => {
	const questionsModel = questionsData.map((questionData) => {
		const { answers: answersData = [] } = questionData;

		const answersModel = createAnswersModel(answersData);

		const questionModel = ProductQuestionModelFactory.create({
			...questionData,
			answersModel,
		});

		return questionModel;
	});

	return questionsModel;
};

export const createFrameProductModel = ({
	configurationOverrideModel = {},
	frameProductData = {},
	frameProductData: {
		articleNumber = '',
	} = {},
	applicableInsertValidationData,
}) => {
	const {
		additionalFrameProductList = [],
		frameProduct: configurationOverrideFrameProductData = {},
		frameProduct: {
			articleNumber: configurationOverrideArticleNumberData = '',
		} = {},
		imageData: configurationOverrideImageData = {},
	} = configurationOverrideModel;

	const matchingOverrideData = (articleNumber === configurationOverrideArticleNumberData) ? configurationOverrideFrameProductData : additionalFrameProductList.find(frameProduct => frameProduct.articleNumber === articleNumber);

	const frameProductDataToUse = matchingOverrideData && Object.keys(matchingOverrideData).length ? matchingOverrideData : frameProductData;

	const {
		articleNumber: articleNumberData = '',
		detail: {
			additionalInformation: detailsAdditionalData = [],
			details: detailsAttributesData = [],
			dimensions: detailsDimensionsData = [],
			materials: detailsMaterialsData = [],
			sustainability: detailsSustainabilityData = [],
		} = {},
		imageUrl: productImageUrl = '',
		questions: questionsData = [],
		width: widthData = 0,
	} = frameProductDataToUse;

	const topImageOverrideData = configurationOverrideImageData?.top?.[articleNumberData];
	const topImageUrl = topImageOverrideData?.imageUrl;
	const baseImageOverrideData = configurationOverrideImageData?.base?.[articleNumberData];
	const baseImageUrl = baseImageOverrideData?.imageUrl;

	let matchingConfigurationOverrideImageData = configurationOverrideImageData[articleNumber] || {};

	// TODO: have server normalize Beam data into array as well
	if (!Array.isArray(matchingConfigurationOverrideImageData)) {
		matchingConfigurationOverrideImageData = [matchingConfigurationOverrideImageData];
	}

	const sortedMatchingConfigurationOverrideImageData = sortBy(matchingConfigurationOverrideImageData, 'widthFrom');

	const { imageUrl: matchingConfigurationOverrideImageUrl = '' } = sortedMatchingConfigurationOverrideImageData[0];

	const imageUrl = matchingConfigurationOverrideImageUrl || productImageUrl;

	const detailsAdditionalModel = createDetailsModel(detailsAdditionalData);

	const detailsAttributesModel = createDetailsModel(detailsAttributesData);

	const detailsDimensionsModel = createDetailsModel(detailsDimensionsData);

	const detailsMaterialsModel = createDetailsModel(detailsMaterialsData);

	const detailsSustainabilityModel = createDetailsModel(detailsSustainabilityData);

	const questionModels = createQuestionsModel(questionsData);

	const frameProductModel = FrameProductModelFactory.create({
		...frameProductDataToUse,
		detailsAdditionalModel,
		detailsAttributesModel,
		detailsDimensionsModel,
		detailsMaterialsModel,
		detailsSustainabilityModel,
		imageUrl,
		topImageUrl,
		baseImageUrl,
		questionModels,
		widthData,
		applicableInsertValidationData,
	});

	return frameProductModel;
};

export const createFrameProductModels = ({
	configurationOverrideModel = {},
	frameProductsData = [],
	applicableInsertValidationData = {},
}) => {
	const frameProductModels = frameProductsData.map((frameProductData = {}) => createFrameProductModel({
		configurationOverrideModel,
		frameProductData,
		applicableInsertValidationData,
	}));

	return frameProductModels;
};
