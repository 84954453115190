import { MediaWorkspaceSectionalsModelFactory } from '~/product/sectionals/media-set/Models/MediaWorkspaceSectionals.model';

export const createMediaWorkspaceSectionalsModel = ({
	disableClick = false,
	mediaData = {},
	workspaceModel = {},
}) => {
	const mediaWorkspaceModel = MediaWorkspaceSectionalsModelFactory.create({
		...mediaData,
		disableClick,
		workspaceModel,
	});

	return mediaWorkspaceModel;
};
