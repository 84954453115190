import { observable } from 'mobx';

import axios from '~/lib/axios';
import {
	assignRoomApiUrl,
	assignRoomNoteApiUrl,
	interstitialViewstates,
} from '~/product/common/interstitial/interstitial.constants';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

const {
	LOADING,
	WISHLIST_ASSIGNED,
} = interstitialViewstates;

export const interstitialFormModel = (store) => {
	const { cartProductGroupIds } = store.model;
	const model = observable({
		note: '',
		wishlistAction: 'EXISTING_ROOM',
		existingRoomName: '',
		newRoomName: '',
		cartProductGroupIds,
	});

	return model;
};

export const wishlistRoomsFormSettings = (store) => {
	const formSettings = {
		id: 'wishlistRoomsForm',
		reactProps: {
			method: 'POST',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler: (form) => {
							const {
								model: {
									wishlistAction,
									cartProductGroupIds,
									newRoomName = '',
									existingRoomName = '',
									note = '',
								},
							} = form;
							const cartRoomName = {
								'NEW_ROOM': newRoomName,
								'EXISTING_ROOM': existingRoomName,
							}[wishlistAction];

							store.viewState.goTo(LOADING);

							if (cartRoomName) {
								return axios.post(assignRoomApiUrl, {
									cartProductGroupIds,
									cartRoomName,
									note,
								})
									.then(() => {
										store.viewState.goTo(WISHLIST_ASSIGNED);
									});
							}

							if (note) {
								return axios.post(assignRoomNoteApiUrl, {
									cartProductGroupIds,
									note,
								})
									.then(() => {
										store.viewState.goTo(WISHLIST_ASSIGNED);
									});
							}

							return store.magicModal.closeModal();
						},
					},
				}),

			],
		},
		fields: {
			wishlistAction: {
				reverseOrder: true,
				radios: [
					{
						control: {
							reactProps: {
								'value': 'EXISTING_ROOM',
								'data-qa': 'wishlist-assign-existing-room-radio',
							},
						},
						label: {
							reactProps: {
								children: 'Existing room',
							},
						},
					},
					{
						control: {
							reactProps: {
								'value': 'NEW_ROOM',
								'data-qa': 'wishlist-assign-new-room-radio',
							},
						},
						label: {
							reactProps: {
								children: 'New room',
							},
						},
					},
				],
			},
			existingRoomName: {
				control: {
					reactProps: {
						'data-qa': 'wishlist-assign-room-name',
					},
				},
				label: {
					reactProps: {
						children: 'Existing rooms',
					},
				},
			},
			newRoomName: {
				control: {
					reactProps: {
						'type': 'text',
						'data-qa': 'wishlist-assign-room-name',
						'maxLength': 100,
					},
				},
				label: {
					reactProps: {
						children: 'Room name',
					},
				},
			},
		},
	};

	return formSettings;
};
