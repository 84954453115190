'use client';

import React from 'react';
import { observer } from 'mobx-react';

import { CuralateUploadFacebook } from '~/util/curalate/Components/CuralateUploadFacebook';
import { CuralateUploadFiles } from '~/util/curalate/Components/CuralateUploadFiles';
import { CuralateUploadInstagram } from '~/util/curalate/Components/CuralateUploadInstagram';
import { CuralateUploadTab } from '~/util/curalate/Components/CuralateUploadTab';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

const CuralateUploadTabset = observer(class CuralateUploadTabset extends React.Component {
	constructor(props) { // eslint-disable-line no-useless-constructor
		super(props);

		this.addUploadTypeRef = this.addUploadTypeRef.bind(this);
		this.keyDownHandler = this.keyDownHandler.bind(this);

		this.uploadTypeRefs = [];
	}

	render() {
		const { store } = this.props;

		const {
			activeUploadType: {
				source = '',
			},
			setUploadTypeActive,
			uploadTypes,
		} = store;

		let uploader;

		const uploaderTabs = (
			uploadTypes.map((uploadType, index) => {
				return (
					<CuralateUploadTab addUploadTypeRef={this.addUploadTypeRef} key={`curalate-upload-tab-${index}`} setUploadTypeActive={setUploadTypeActive} uploadType={uploadType} />
				);
			})
		);

		const curalateUploadFiles = (
			<CuralateUploadFiles store={store} />
		);

		const curalateUploadInstagram = (
			<CuralateUploadInstagram store={store} />
		);

		const curalateUploadFacebook = (
			<CuralateUploadFacebook store={store} />
		);

		switch (source) {
			case 'Facebook':
				uploader = curalateUploadFacebook;
				break;
			case 'Instagram':
				uploader = curalateUploadInstagram;
				break;
			case 'Upload':
				uploader = curalateUploadFiles;
				break;
			default:
				uploader = curalateUploadFiles;
				break;
		}

		return (
			<div className={styles['curalate-upload-tabset']}>
				{/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-noninteractive-element-to-interactive-role */}
				<ul className={`${styles['curalate-upload-tabs']} u-noListStyle`} onKeyDown={this.keyDownHandler} role="tablist">
					{uploaderTabs}
				</ul>
				<div aria-labelledby={`curalate-upload-tab-${source}`} id={`curalate-upload-tabpanel-${source}`} role={'tabpanel'}>
					{uploader}
				</div>
			</div>
		);
	}

	addUploadTypeRef(uploadTypeRef) {
		this.uploadTypeRefs.push(uploadTypeRef);
	}

	keyDownHandler(event) {
		const {
			store: {
				activeUploadType: {
					index = 0,
				} = {},
				setUploadTypeActive,
				uploadTypes = [],
			}
		} = this.props;

		let uploadTypeIndexToSelect;

		switch (event.keyCode) {
			case 35:
				uploadTypeIndexToSelect = uploadTypes.length - 1;
				break;
			case 36:
				uploadTypeIndexToSelect = 0;
				break;
			case 37:
				if (index === 0) {
					uploadTypeIndexToSelect = uploadTypes.length - 1;
				} else {
					uploadTypeIndexToSelect = index - 1;
				}
				break;
			case 39:
				if (index === uploadTypes.length - 1) {
					uploadTypeIndexToSelect = 0;
				} else {
					uploadTypeIndexToSelect = index + 1;
				}
				break;
			default:
				break;
		}

		if (typeof uploadTypeIndexToSelect !== 'undefined') {
			event.preventDefault();

			setUploadTypeActive(uploadTypes[uploadTypeIndexToSelect]);

			this.uploadTypeRefs[uploadTypeIndexToSelect].focus();
		}
	}
});

export { CuralateUploadTabset };
