import { VendorEventTrackingModel } from '~/tracking/vendor-events/common/Models/VendorEvent.tracking.model';
import { ICylindoJSON, ICylindoPresentEvent } from '~/tracking/vendor-events/cylindo/Interfaces/ICylindoPresentEvent.tracking';

export class CylindoPresentEventTrackingModel {
	articleNumber;

	cylindo360;

	cylindoAR;

	cylindoDim;

	constructor({
		articleNumber = '',
		cylindo360 = 0,
		cylindoAR = 0,
		cylindoDim = 0,
	}: ICylindoPresentEvent) {
		this.articleNumber = articleNumber;
		this.cylindo360 = cylindo360;
		this.cylindoAR = cylindoAR;
		this.cylindoDim = cylindoDim;
	}

	get trackingJson(): ICylindoJSON {
		const vendorEventTrackingModel = new VendorEventTrackingModel();
		return {
			event: 'cylpresent',
			vendor: {
				article: this.articleNumber,
				cyl360Present: this.cylindo360,
				cylARPresent: this.cylindoAR,
				cylDimPresent: this.cylindoDim,
			},
			content: vendorEventTrackingModel.pageID,
			page: {
				id: vendorEventTrackingModel.pageID,
			},
		};
	}
}
