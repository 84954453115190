import React from 'react';
import { autorun } from 'mobx';
import dynamic from 'next/dynamic';

import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { interstitialViewstates } from '~/product/common/interstitial/interstitial.constants';
import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import {
	interstitialFormModel,
	interstitialFormSettings,
	InterstitialFactory
} from '~/product/common/interstitial/Models/Interstitial.model';
import { MagicRecosRfkStoreFactory } from '~/components/magic-recos-rfk/Stores/MagicRecosRfk.store';
import { isOnServer } from '~/global/global.constants';

const WishlistInterstitial = dynamic(() => {
	return import('~/product/common/interstitial/Components/WishlistInterstitial').then(module => module.WishlistInterstitial);
}, { loading: () => <LoadingSpinner isLoading /> });

const {
	LOADING,
	WISHLIST_INTERSTITIAL,
} = interstitialViewstates;

class InterstitialStore {
	magicModal;

	observers = [];

	viewState;

	loadRfkRecos;
}

export const InterstitialStoreFactory = {
	create(interstitialData, context = {}, options = {}) {
		const interstitialStore = new InterstitialStore();

		interstitialStore.loadRfkRecos = options.loadRfkRecos;
		interstitialStore.magicModal = context.magicModal;
		interstitialStore.notesMaxLength = 350;
		interstitialStore.model = InterstitialFactory.create(interstitialData, context);
		interstitialStore.model.form = new FormBuilder(
			interstitialFormModel(interstitialStore),
			interstitialFormSettings(interstitialStore),
		);

		if (
			Boolean(context.globalDynamicModel)
			&& Boolean(context.globalStaticModel)
			&& interstitialStore.loadRfkRecos
			&& !isOnServer
		) {
			interstitialStore.observers.push(
				autorun(() => {
					if (interstitialStore.productRecosStore) {
						return;
					}

					const { reflektionSkuKeys } = interstitialStore.model;

					interstitialStore.productRecosStore = MagicRecosRfkStoreFactory.create(
						'rfkid_4b',
						window.location.pathname,
						{
							globalDynamicModel: context.globalDynamicModel,
							globalStaticModel: context.globalStaticModel,
							featureTogglesModel: context.featureTogglesModel,
						},
						{
							sku: reflektionSkuKeys,
							title: 'You may also like',
							analytics: {
								pageId: 'AddtoCart',
							},
							maxItems: 18,
						},
					);
				})
			);
		}
		interstitialStore.viewState = new ViewStateStore(interstitialStore, {
			[LOADING]: {
				component: () => (
					<div style={{ minHeight: '400px' }}>
						<LoadingSpinner isLoading />
					</div>
				),
			},
			[WISHLIST_INTERSTITIAL]: {
				component: WishlistInterstitial,
				onEnter: (viewState, store) => {
					return { store };
				},
			},
		}, {
			scrollToTopOfModal: true,
		});

		interstitialStore.viewState.goTo(WISHLIST_INTERSTITIAL);

		return interstitialStore;
	},
	destroy(store) {
		store.observers?.forEach?.(dispose => dispose());
	},
};
