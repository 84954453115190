import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { s7ContentPath } from '~/global/global.constants';
import { SelectorRecursive } from '~/product/casegoods/selector/Components/recursive/SelectorRecursive';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { SelectorFilters } from '~/product/common/selector/Components/filters/SelectorFilters';

import styles from '#/product/casegoods/selector/frame/selector-frame.module.scss';

export const SelectorFrame = observer((props = {}) => {
	const {
		selectedSelector: {
			title: selectedSelectorTitle = '',
			selectedSelectorValue: {
				selectedSelector: rootRecursionSelector = {},
				selectedSelector: {
					filtersMagicTabccordionStore = {},
					filtersModel = {},
					filtersStore = {},
				} = {},
			} = {},
		} = {},
	} = props;

	const {
		selectorConfigModel: {
			settings: {
				hideFrameFilters = false,
				onlyOneDimension = false,
			} = {},
		} = {},
	} = useProductContext();

	const messageRef = useRef();
	const [showMessage, setShowMessasge] = useState(true);
	let ticking = false;

	function handleScroll() {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				ticking = false;
				setShowMessasge(false);
			});
			ticking = true;
		}
	}

	function handleTouchStart() {
		setShowMessasge(false);
	}

	return (
		<div>
			{
				selectedSelectorTitle && (
					<div className={`${styles['selector-frame-description']} md:tw-hidden`}>
						{`${selectedSelectorTitle}`}
					</div>
				)
			}
			{
				!hideFrameFilters && (
					<SelectorFilters
						filtersMagicTabccordionStore={filtersMagicTabccordionStore}
						filtersModel={filtersModel}
						filtersStore={filtersStore}
						useSingleColumn={true}
					/>
				)
			}
			<div className={styles['selector-values-frame']}>
				<ConditionalWrapper
					condition={onlyOneDimension}
					wrapper={children => (
						<div className={styles['selector-values-groups-frame']} onScroll={handleScroll} onTouchStart={handleTouchStart}>{children}</div>
					)}>
					<SelectorRecursive selectedSelector={rootRecursionSelector} scrollHandler={handleScroll} touchStartHandler={handleTouchStart} />
				</ConditionalWrapper>
				<div
					className={
						classNames(`${styles['selector-frame-message']} md:tw-hidden`, {
							'tw-opacity-0': !showMessage,
						})
					}
					ref={messageRef}>
					<img
						alt=""
						className={styles['selector-frame-message-image']}
						src={`${s7ContentPath}/swipe-arrows`}
					/>
					<span className={styles['selector-frame-message-text']}>Swipe for <br />options</span>
				</div>
			</div>
		</div>
	);
});
