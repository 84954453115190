import { computed, makeObservable, observable } from 'mobx';

import type { IWorkspaceProductJoinableSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductJoinableSectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

import { opposingOrientationMap, orientationByJoinableSidesMapByOrientation } from '~/product/sectionals/workspace/workspace-sectionals.constants';

class WorkspaceProductJoinableSectionalsModel implements IWorkspaceProductJoinableSectionals {
	joinableSide: string

	nextWorkspaceProductJoinableModel: IWorkspaceProductJoinableSectionals | null

	nextWorkspaceProductModel: IWorkspaceProductSectionals | null

	prevWorkspaceProductJoinableModel: IWorkspaceProductJoinableSectionals | null

	prevWorkspaceProductModel: IWorkspaceProductSectionals | null

	parentOrientation: string

	constructor({
		joinableSide = '',
		nextWorkspaceProductJoinableModel = null,
		nextWorkspaceProductModel = null,
		prevWorkspaceProductJoinableModel = null,
		prevWorkspaceProductModel = null,
		parentOrientation = '',
	}: any = {}) {
		makeObservable(this, {
			nextWorkspaceProductJoinableModel: observable.ref,
			nextWorkspaceProductModel: observable.ref,
			prevWorkspaceProductJoinableModel: observable.ref,
			prevWorkspaceProductModel: observable.ref,
			parentOrientation: observable,
			joinableSideOrientation: computed,
			opposingJoinableSideOrientation: computed,
			orientationByJoinableSidesMap: computed,
		});

		this.joinableSide = joinableSide;
		this.nextWorkspaceProductJoinableModel = nextWorkspaceProductJoinableModel;
		this.nextWorkspaceProductModel = nextWorkspaceProductModel;
		this.prevWorkspaceProductJoinableModel = prevWorkspaceProductJoinableModel;
		this.prevWorkspaceProductModel = prevWorkspaceProductModel;
		this.parentOrientation = parentOrientation;
	}

	get joinableSideOrientation(): string {
		const { orientationByJoinableSidesMap } = this;

		return orientationByJoinableSidesMap[this.joinableSide as keyof typeof orientationByJoinableSidesMap];
	}

	get opposingJoinableSideOrientation(): string {
		return opposingOrientationMap[this.joinableSideOrientation as keyof typeof opposingOrientationMap];
	}

	get orientationByJoinableSidesMap(): any {
		return orientationByJoinableSidesMapByOrientation[this.parentOrientation as keyof typeof orientationByJoinableSidesMapByOrientation];
	}
}

export const WorkspaceProductJoinableSectionalsModelFactory = ({
	create: (data: any = {}) => {
		return new WorkspaceProductJoinableSectionalsModel(data);
	},
});
