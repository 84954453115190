import axios from 'axios';
import { action, runInAction, makeObservable } from 'mobx';

import { apiUrl, s7ImagePath } from '~/global/global.constants';
import { createFrameProductModel } from '~/product/casegoods/Helpers/FrameProducts.init';
import { createInsertProductModel } from '~/product/casegoods/Helpers/InsertProducts.init';
import { createSelectorInsertModel } from '~/product/casegoods/selector/Helpers/SelectorInsert.init';
import { createSelectorOptionsModel } from '~/product/casegoods/selector/Helpers/SelectorOptions.init';
import { selectFrameProductQuestionAnswers, selectProductMaterialQuestionAnswers, selectProductOptionsQuestionAnswers } from '~/product/casegoods/selector/Utils/SelectorConfigCasegoods.utils';
import {
	getParentSelector,
	getSelectorValueByArticleNumber,
	selectSelectorValue,
	preselectSelectorsAndSelectorValues,
} from '~/product/common/selector/Utils/SelectorConfig.utils';
import { uniqBy } from '~/util/uniqBy';

export class SelectorConfigCasegoodsStore {
	linkEventStore;

	productGroupModel;

	selectorConfigModel;

	constructor() {
		makeObservable(this, {
			clearAllFilters: action,
			getInsertProductImagesData: action,
			getProductImagesData: action,
			getSelectorData: action,
			getWorkspaceProductImagesData: action,
			overrideOptionsSelectorValues: action,
			selectMaterialSelectorValue: action,
			selectQuestionSelectorValue: action,
			selectOptionsSelectorValue: action,
			selectSelectorValue: action,
			setWorkspaceProductStores: action,
			toggleFacet: action,
		});
	}

	clearAllFilters() {
		const { flattenedSelectors = [] } = this.selectorConfigModel;

		flattenedSelectors.forEach(({ filtersStore = {} }) => {
			filtersStore.clearAllFilters();
		});
	}

	getInsertProductImagesData() {
		const {
			id = '',
			_links: {
				images: {
					href = `/api/web/product-group/${id}/image`,
				} = {},
			} = {},
		} = this.productGroupModel;

		const {
			insertsSelectorModel: {
				productModels: insertProductModels = [],
			},
			selectedProduct: {
				articleNumber = '',
				questionModels = [],
			} = {},
		} = this.selectorConfigModel;

		const url = `${apiUrl}${href}/${articleNumber}`;

		const insertMaterialQuestionModels = questionModels.filter(({ group = '' }) => ['FINISH', 'KNOB_PULL'].includes(group));

		const questionParam = insertMaterialQuestionModels.map(({
			group = '',
			selectedAnswer: {
				key = '',
			} = {},
		}) => `${group}:${key}`).filter(Boolean);

		const params = { question: questionParam };

		return axios.request({
			url,
			method: 'get',
			params,
			paramsSerializer: (paramsToSerialize) => {
				return Object.entries(paramsToSerialize).map(([key, value]) => {
					if (Array.isArray(value)) {
						return value.map((subValue) => {
							return `${key}=${encodeURIComponent(subValue)}`;
						}).join('&');
					}

					return `${key}=${encodeURIComponent(value)}`;
				}).join('&');
			},
		})
			.then(({ data = {} }) => {
				insertProductModels.forEach((insertProductModel) => {
					const { imageUrl = `${s7ImagePath}/noimage` } = data[insertProductModel.articleNumber] || {};

					insertProductModel.imageUrl = imageUrl;
				});
			})
			.catch((error) => {
				console.info(error);
			});
	}

	getProductImagesData() {
		const {
			id = '',
			_links: {
				images: {
					href = `/api/web/product-group/${id}/image`,
				} = {},
			} = {},
		} = this.productGroupModel;

		const {
			allProductQuestionModels = [],
			insertsSelectorModel: {
				productModels: insertProductModels = [],
			} = {},
			productModels = [],
			settings: {
				useInsertImageEndpoint = false,
			} = {},
		} = this.selectorConfigModel;

		const url = `${apiUrl}${href}`;

		const uniqProductQuestionModels = uniqBy(allProductQuestionModels, 'group');

		const questionParam = uniqProductQuestionModels.map(({
			group = '',
			selectedAnswer: {
				key = '',
			} = {},
		}) => `${group}:${key}`).filter(Boolean);

		const params = { question: questionParam };

		return axios.request({
			url,
			method: 'get',
			params,
			paramsSerializer: (paramsToSerialize) => {
				return Object.entries(paramsToSerialize).map(([key, value]) => {
					if (Array.isArray(value)) {
						return value.map((subValue) => {
							return `${key}=${encodeURIComponent(subValue)}`;
						}).join('&');
					}

					return `${key}=${encodeURIComponent(value)}`;
				}).join('&');
			},
		})
			.then(({ data = {} }) => {
				const {
					base = {},
					inserts = {},
					top = {},
				} = data;

				productModels.forEach((frameProduct = {}) => {
					const {
						articleNumber = '',
						width = 0,
					} = frameProduct;

					// TODO: have the API provide a consistent format for imageData
					const frameImageData = data[articleNumber];

					const { height: baseHeight, imageUrl: baseImageUrl = `${s7ImagePath}/noimage` } = base[articleNumber] || {};

					const { height: topHeight, imageUrl: topImageUrl = `${s7ImagePath}/noimage` } = top[articleNumber] || {};

					// deal with custom bookcase width range imageData provided as an array, otherwise deal with standard image data
					if (frameImageData && frameImageData.length) {
						const [firstImageData = {}] = frameImageData;

						const widthImageData = frameImageData.find(({
							widthFrom = 0,
							widthTo = 0,
						}) => (width >= widthFrom) && (width <= widthTo));

						const frameImageDataToUse = widthImageData || firstImageData;

						const { imageUrl = `${s7ImagePath}/noimage` } = frameImageDataToUse;

						frameProduct.imageUrl = imageUrl;
					} else if (frameImageData) {
						const { imageUrl = `${s7ImagePath}/noimage` } = frameImageData;

						frameProduct.imageUrl = imageUrl;
					} else {
						if (baseHeight) frameProduct.baseHeight = baseHeight;
						if (topHeight) frameProduct.topHeight = topHeight;
						frameProduct.baseUrl = baseImageUrl;
						frameProduct.topUrl = topImageUrl;
					}
				});

				// deal with insert imageData here when useInsertImageEndpoint setting is not enabled
				if (!useInsertImageEndpoint) {
					insertProductModels.forEach((insertProductModel = {}) => {
						const { imageUrl = `${s7ImagePath}/noimage` } = inserts[insertProductModel.articleNumber] || {};

						insertProductModel.imageUrl = imageUrl;
					});
				}
			})
			.catch((error) => {
				console.info(error);
			});
	}

	getSelectorData({
		currentInsertArticles = [],
		currentProductModels = [],
		currentWorkspaceProducts = [],
		currentFrameProductArticleNumber,
		insertAnswerKey = '',
		hrefToUse,
	}) {
		const {
			insertsSelectorModel: {
				insertGroupsData: currentInsertGroupsData,
				productModels: currentInsertProductModels,
			},
			settings: {
				useAdditionalFrameProductsParam = false,
				frameFirstBuilder,
			} = {},
		} = this.selectorConfigModel;

		const articleNumberToUse = currentFrameProductArticleNumber || currentWorkspaceProducts[0]?.frameProductModel?.articleNumber || null;
		const currentArticle = currentInsertArticles?.length
			? currentInsertArticles.find(insertArticle => insertArticle.article === articleNumberToUse)
			: currentWorkspaceProducts.find(product => product.frameProductModel?.articleNumber)?.depthSelector?.combinedArticles?.find(insertArticle => insertArticle.article === articleNumberToUse);
		const additionalFrameArticles = [currentFrameProductArticleNumber, ...currentWorkspaceProducts.map(product => product?.frameProductModel?.articleNumber)].filter(Boolean).join(',');

		this.selectorConfigModel.insertQuestionAnswer = insertAnswerKey ? `?question=INSERT%3A${insertAnswerKey}` : '';

		const {
			_links: {
				FRAME_DOCUMENT,
				STACK_DOCUMENT,
			} = {},
		} = currentArticle || {};

		this.selectorConfigModel.articleHref = hrefToUse || FRAME_DOCUMENT?.href || STACK_DOCUMENT?.href;

		if (frameFirstBuilder && !this.selectorConfigModel.articleHref) { // if no currentArticle exists, this means the workspace is empty. Clear inserts.
			this.selectorConfigModel.insertsSelectorModel.productModels = [];
			console.warn('GetSelectorData could not find an href to use');
			// eslint-disable-next-line no-promise-executor-return
			return new Promise(resolve => resolve());
		}

		return axios.get(this.selectorConfigModel.frameAPIUrl, {
			params: { ...(useAdditionalFrameProductsParam && { additionalFrameArticles }) },
		})
			.then(({ data = {} }) => {
				const {
					frameProduct: frameProductData = {},
					frameProduct: {
						articleNumber: articleNumberData = '',
					} = {},
					insertGroups: insertGroupsData,
					insertProducts: insertProductsData = [],
					insertOptions: applicableInsertValidationData,
					insertSelector: insertSelectorData = {},
					insertPlaceholderSelector,
					optionsSelector: optionsSelectorData = {},
				} = data;

				const frameProductModel = createFrameProductModel({ frameProductData, applicableInsertValidationData });

				const {
					detailsAdditionalModel = [],
					detailsAttributesModel = [],
					detailsDimensionsModel = [],
					detailsMaterialsModel = [],
					detailsSustainabilityModel = [],
					insertSizeKey = '',
					questionModels = [],
					slots = [],
				} = frameProductModel;

				const newInsertProductModels = insertProductsData.map((insertProductData = {}) => {
					const { articleNumber: insertArticleNumberData = '' } = insertProductData;

					const matchingInsertProductModel = currentInsertProductModels.find(({ articleNumber = '' }) => articleNumber === insertArticleNumberData);

					return matchingInsertProductModel || createInsertProductModel({ insertProductData });
				});

				const insertProductModels = newInsertProductModels?.length ? newInsertProductModels : currentProductModels; // if data isn't provided by payload, persist provided insertProductModels

				const insertGroups = insertGroupsData || currentInsertGroupsData;

				const insertsSelectorModel = createSelectorInsertModel({
					linkEventStore: this.linkEventStore,
					productModels: insertProductModels,
					selectorData: insertSelectorData,
					insertPlaceholderSelector,
					insertGroupsData: insertGroups,
					isValid: true,
				});

				const optionsSelectorModel = createSelectorOptionsModel({
					selectorData: optionsSelectorData,
				});

				runInAction(() => {
					Object.assign(this.selectorConfigModel, {
						insertsSelectorModel: frameFirstBuilder ? insertsSelectorModel : this.selectorConfigModel.insertsSelectorModel,
						optionsSelectorModel,
					});

					const {
						allProductModels = [],
						flattenedOptionsSelectors = [],
						productModels: frameProductModels = [],
						selectedAnswerSelectorValues = [],
						selectedMaterialSelectorValues = [],
					} = this.selectorConfigModel;

					const matchingFrameProductModel = frameProductModels.find(({ articleNumber = '' }) => articleNumber === articleNumberData);

					if (matchingFrameProductModel) {
						Object.assign(matchingFrameProductModel, {
							detailsAdditionalModel,
							detailsAttributesModel,
							detailsDimensionsModel,
							detailsMaterialsModel,
							detailsSustainabilityModel,
							insertSizeKey,
							questionModels,
							slots,
							applicableInsertValidationData,
						});

						selectFrameProductQuestionAnswers(selectedAnswerSelectorValues);
					}

					flattenedOptionsSelectors.forEach(({ defaultSelectorValue }) => {
						if (defaultSelectorValue) {
							this.selectOptionsSelectorValue(defaultSelectorValue, true);
						}
					});

					if (insertProductsData?.length) {
						this.selectorConfigModel.insertsSelectorModel.productModels.forEach((insertProductModel) => {
							const { imageUrl = `${s7ImagePath}/noimage` } = insertProductsData.find((insert) => {
								return insert.articleNumber === insertProductModel.articleNumber;
							}) || {};
							insertProductModel.imageUrl = imageUrl;
						});
					}

					selectProductMaterialQuestionAnswers(selectedMaterialSelectorValues, allProductModels, insertProductModels);
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}

	getWorkspaceProductImagesData() {
		const { workspaceProductStores = [] } = this.selectorConfigModel;

		workspaceProductStores.forEach((workspaceProductStore = {}) => {
			workspaceProductStore.getWorkspaceProductImagesData();
		});
	}

	overrideOptionsSelectorValues(overrideQuestionAnswers = {}) {
		const {
			optionsSelectorModel: {
				selectorValues: optionsSelectorValues = [],
			} = {},
		} = this.selectorConfigModel;

		const [firstOptionsSelectorValue] = optionsSelectorValues;

		const { selectors: optionsSelectors = [] } = firstOptionsSelectorValue;

		optionsSelectors.forEach(({ selectorValues = [] }) => {
			selectorValues.forEach((selectorValue = {}) => {
				const {
					answer = {},
					question = {},
				} = selectorValue;

				Object.entries(overrideQuestionAnswers).forEach(([overrideQuestion, overrideAnswer]) => {
					if (question === overrideQuestion && answer === overrideAnswer) {
						this.selectOptionsSelectorValue(selectorValue);
					}
				});
			});
		});
	}

	selectMaterialSelectorValue(selectorValueToSelect = {}, disableGetImagesData = false) {
		const {
			settings: {
				useInsertImageEndpoint = false,
			} = {},
		} = this.selectorConfigModel;

		const matchingParent = [...this.selectorConfigModel.materialSelectorModels, ...this.selectorConfigModel.insertMaterialSelectorModels].find(({ selectorValues = [] }) => selectorValues.includes(selectorValueToSelect));

		if (!matchingParent) {
			console.warn(`Could not find parent material selector for selectorValue type ${selectorValueToSelect?.parentType}`);
			return;
		}

		matchingParent.selectorValues.forEach((selectorValue) => { selectorValue.selected = false; });

		selectorValueToSelect.selected = true;

		selectProductMaterialQuestionAnswers(this.selectorConfigModel.selectedMaterialSelectorValues, this.selectorConfigModel.allProductModels, this.selectorConfigModel.insertsSelectorModel.productModels);

		this.selectorConfigModel.lastSelectedSelectorValue = selectorValueToSelect;

		if (!disableGetImagesData) {
			this.getProductImagesData();

			this.getWorkspaceProductImagesData();

			if (useInsertImageEndpoint) {
				this.getInsertProductImagesData();
			}
		}
	}

	selectOptionsSelectorValue(selectorValueToSelect = {}, disableSetLastSelectedSelectorValue = false) {
		const { parentType = '' } = selectorValueToSelect;

		const { flattenedOptionsSelectorsAndSelectorValues = [] } = this.selectorConfigModel;

		const parentSelector = getParentSelector(flattenedOptionsSelectorsAndSelectorValues, selectorValueToSelect);

		if (parentSelector) {
			parentSelector.selectorValues.forEach((selectorValue) => {
				selectorValue.selected = false;
			});
		} else {
			console.warn(`Could not find parent selector for selectorValue type ${parentType}`);
		}

		selectorValueToSelect.selected = true;

		selectProductOptionsQuestionAnswers(this.selectorConfigModel.selectedOptionsSelectorValues, this.selectorConfigModel.productModels);

		if (!disableSetLastSelectedSelectorValue) {
			this.selectorConfigModel.lastSelectedSelectorValue = selectorValueToSelect;
		}
	}

	selectQuestionSelectorValue(selectorValueToSelect = {}, disableGetImagesData = false) {
		const { flattenedSelectorsAndSelectorValues = [] } = this.selectorConfigModel;

		const {
			settings: {
				useInsertImageEndpoint = false,
			} = {},
		} = this.selectorConfigModel;

		selectSelectorValue(flattenedSelectorsAndSelectorValues, selectorValueToSelect, true);

		preselectSelectorsAndSelectorValues(flattenedSelectorsAndSelectorValues, [this.selectorConfigModel.selectedProductSelectorValue, ...this.selectorConfigModel.selectedAnswerSelectorValues]);

		this.selectorConfigModel.lastSelectedSelectorValue = selectorValueToSelect;

		if (!disableGetImagesData) {
			this.getProductImagesData();

			this.getWorkspaceProductImagesData();

			if (useInsertImageEndpoint) {
				this.getInsertProductImagesData();
			}
		}
	}

	selectSelectorValue(selectorValueToSelect = {}, disableGetImagesData = false) {
		const { question = '' } = selectorValueToSelect;

		const { flattenedSelectorsAndSelectorValues = [] } = this.selectorConfigModel;

		if (question) {
			this.selectMaterialSelectorValue(selectorValueToSelect, disableGetImagesData);
		} else {
			flattenedSelectorsAndSelectorValues.forEach((selectorOrSelectorValue = {}) => {
				selectorOrSelectorValue.selected = false;
			});

			selectSelectorValue(flattenedSelectorsAndSelectorValues, selectorValueToSelect);
		}
	}

	setWorkspaceProductStores(workspaceProductStores = []) {
		this.selectorConfigModel.workspaceProductStores = workspaceProductStores;
	}

	toggleFacet({
		parentType = '',
		title = '',
	}) {
		const {
			flattenedSelectors = [],
			insertsSelectorModel = {},
		} = this.selectorConfigModel;

		[...flattenedSelectors, insertsSelectorModel].forEach(({
			filtersModel: {
				filterModels = [],
			} = {},
			filtersStore = {},
		}) => {
			filterModels.forEach(({ facetsModel = [] }) => {
				const matchingFacetModel = facetsModel.find(({
					parentType: matchingParentType = '',
					title: matchingTitle = '',
				}) => {
					return parentType === matchingParentType && title === matchingTitle;
				});

				if (matchingFacetModel) {
					filtersStore.toggleFacet(matchingFacetModel);
				}
			});
		});
	}
}

export const SelectorConfigCasegoodsStoreFactory = ({
	create: ({
		linkEventStore = {},
		productGroupModel = {},
		selectorConfigModel = {},
	}) => {
		const {
			defaultConfigModel: {
				defaultAnswers = [],
			} = {},
			defaultInsertConfigModel: {
				defaultAnswers: defaultInsertAnswers = [],
			} = {},
			defaultProduct: {
				articleNumber: defaultProductArticleNumber = '',
			} = {},
			flattenedOptionsSelectors = [],
			insertMaterialSelectorModels = [],
			materialSelectorModels = [],
			productSelectorValues = [],
			selectedProduct: {
				articleNumber = '',
			} = {},
		} = selectorConfigModel;

		const selectorConfigCasegoodsStore = new SelectorConfigCasegoodsStore();

		// find the end-of-the-line product selectorValue, which will drive selectedProduct
		const defaultProductSelectorValue = getSelectorValueByArticleNumber(productSelectorValues, defaultProductArticleNumber || articleNumber);

		Object.assign(selectorConfigCasegoodsStore, {
			linkEventStore,
			productGroupModel,
			selectorConfigModel,
		});

		runInAction(() => {
			if (defaultProductSelectorValue) {
				selectorConfigCasegoodsStore.selectSelectorValue(defaultProductSelectorValue);
			}

			const { selectedQuestionSelectors = [] } = selectorConfigModel;

			defaultInsertAnswers.forEach(({
				answer: defaultConfigAnswer = '',
				question: defaultConfigQuestion = '',
			}) => {
				insertMaterialSelectorModels.forEach(({ selectorValues = [] }) => {
					const matchingInsertMaterialSelectorValue = selectorValues.find(({
						answer = '',
						question = '',
					}) => {
						return defaultConfigAnswer === answer && defaultConfigQuestion === question;
					});

					if (matchingInsertMaterialSelectorValue) {
						selectorConfigCasegoodsStore.selectMaterialSelectorValue(matchingInsertMaterialSelectorValue, true);
					}
				});
			});

			defaultAnswers.forEach(({
				answer: defaultConfigAnswer = '',
				question: defaultConfigQuestion = '',
			}) => {
				selectedQuestionSelectors.forEach(({ selectorValues = [] }) => {
					const matchingQuestionAnswerSelectorValue = selectorValues.find(({
						answer = '',
						question = '',
					}) => {
						return defaultConfigAnswer === answer && defaultConfigQuestion === question;
					});

					if (matchingQuestionAnswerSelectorValue) {
						selectorConfigCasegoodsStore.selectQuestionSelectorValue(matchingQuestionAnswerSelectorValue, true);
					}
				});

				selectFrameProductQuestionAnswers(selectorConfigCasegoodsStore.selectorConfigModel.selectedAnswerSelectorValues);

				materialSelectorModels.forEach(({ selectorValues = [] }) => {
					const matchingMaterialSelectorValue = selectorValues.find(({
						answer = '',
						question = '',
					}) => {
						return defaultConfigAnswer === answer && defaultConfigQuestion === question;
					});

					if (matchingMaterialSelectorValue) {
						selectorConfigCasegoodsStore.selectMaterialSelectorValue(matchingMaterialSelectorValue, true);
					}
				});

				flattenedOptionsSelectors.forEach(({ selectorValues = [] }) => {
					const matchingOptionsSelectorValue = selectorValues.find(({
						answer = '',
						question = '',
					}) => {
						return defaultConfigAnswer === answer && defaultConfigQuestion === question;
					});

					if (matchingOptionsSelectorValue) {
						selectorConfigCasegoodsStore.selectOptionsSelectorValue(matchingOptionsSelectorValue);
					}
				});
			});
		});

		return selectorConfigCasegoodsStore;
	},
});
