import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceModalInsertOptionsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-insert-options/WorkspaceModalInsertOptionsCasegoods';

import styles from '#/product/casegoods/workspace/product-insert/workspace-product-insert-options-edit.module.scss';

export const WorkspaceProductInsertOptionsEditCasegoods = observer((props = {}) => {
	const {
		imageScale = 0,
		slotModel = {},
		slotModel: {
			hasInsertOptionsSelectors = false,
			insertOptionsSelectorValues = [],
		} = {},
	} = props;

	const { magicModal = {} } = useGlobalContext();

	const { workspaceModalInsertOptionsStore = {} } = useProductContext();

	function handleClick() {
		runInAction(() => {
			workspaceModalInsertOptionsStore.setSlotModel(slotModel);

			workspaceModalInsertOptionsStore.setSelectedSelectorValues(insertOptionsSelectorValues);
		});

		magicModal.openModal({
			content: {
				children: (
					<WorkspaceModalInsertOptionsCasegoods imageScale={imageScale} />
				),
			},
			id: 'workspace-insert-modal',
			maxWidth: '515px',
			title: 'Editing Insert',
			onCloseModal: () => {
				const selectorOptionsElement = document.querySelector('[data-selector-heading-options]');

				if (selectorOptionsElement) {
					setTimeout(() => {
						window.scrollTo(0, selectorOptionsElement.offsetTop - 20);
					}, 0);
				}
			},
		});
	}

	return (
		<div className={styles['workspace-product-insert-options-edit']}>
			<button
				className={styles['workspace-product-insert-options-edit-button']}
				data-disable-media-interaction={true}
				data-qa="workspace-product-insert-options-edit"
				disabled={!hasInsertOptionsSelectors}
				onClick={handleClick}
			>
				{hasInsertOptionsSelectors ? 'Edit' : 'No optional features'}
			</button>
		</div>
	);
});
