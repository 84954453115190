import { observer } from 'mobx-react';
import React from 'react';

import { addToUrl } from '~/util/addToUrl';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '~/product/sectionals/selector/Components/layout/SelectorValueLayoutImage.module.scss';

export const SelectorValueLayoutImage = observer((props = {}) => {
	const {
		imageScale = 0,
		selectorValue: {
			imageUrl = '',
		},
	} = props;

	const { workspaceStore = {} } = useProductContext();

	const srcParamsRoot = '$proddd$&fmt=png-alpha';

	const srcParams = `${srcParamsRoot}&scl=${imageScale}`;

	const srcSetParams = `${srcParamsRoot}&scl=${imageScale / 2}`;

	const srcAttr = addToUrl(imageUrl, srcParams);

	const srcSetAttr = addToUrl(imageUrl, srcSetParams);

	const onImgLoad = ({ target: img }) => {
		img.offsetHeight && workspaceStore.setDragPreviewHeight(img.offsetHeight);
		img.offsetWidth && workspaceStore.setDragPreviewWidth(img.offsetWidth);
	};

	return (
		<img
			alt=""
			className={styles['selector-value-layout-image']}
			draggable={false}
			onLoad={onImgLoad}
			src={srcAttr}
			srcSet={`${srcSetAttr} 2x`}
		/>
	);
});
