import { reaction, extendObservable } from 'mobx';
import React from 'react';

import { MagicTabccordionStore } from '~/components/magic-tabccordion/Stores/magicTabccordion.store';
import { isOnServer } from '~/global/global.constants';
import { SelectorTabccordionTab } from '~/product/common/selector/Components/SelectorTabccordionTab';
import { SelectorHeading } from '~/product/common/selector/Components/SelectorHeading';
import { scrollSelectorToStickySummary } from '~/product/common/selector/Utils/Selector.utils';
import { useGlobalContext } from '~/global/Contexts/Global.context';

const isMobile = isOnServer || !window.matchMedia('(min-width: 40em)').matches;

const getTabGroups = (selectedSelectorsToMap, featureTogglesModel = {}, stickySummaryModel = {}, activeIndex = 0) => {
	return [
		{
			tabs: selectedSelectorsToMap.map((selectedSelector, index) => {
				return extendObservable({
					selectedSelector,
					tabTitleComponent: ({
						tab: {
							isActive,
						},
					}) => {
						return (
							<SelectorHeading
								isActive={isActive}
								selectedSelector={selectedSelector}
							/>
						);
					},
				}, {
					isActive: index === activeIndex,
					...(featureTogglesModel.isOn('PRODUCT_PAGE_MOBILE_LAYOUT_REDESIGN') && {
						scrollBehaviorOverride: (selectorRef) => {
							scrollSelectorToStickySummary(selectorRef, stickySummaryModel);
						},
					}),
				});
			}),
		},
	];
};

export const createSelectorMagicTabccordionStore = (selectorConfigModel = {}, stickySummaryModel = {}) => {
	const { sortedSelectedSelectorsToRender = [] } = selectorConfigModel;

	const {
		featureTogglesModel,
		linkEventStore,
	} = useGlobalContext();

	const selectorTabccordionData = {
		disableAriaRoles: true,
		scrollToActiveTab: isMobile && !featureTogglesModel.isOn('PRODUCT_PAGE_MOBILE_LAYOUT_REDESIGN'),
		scrollToActiveTabOffset: 80,
		id: 'selector-tabccordion',
		keepActiveTabOpen: isMobile,
		renderMode: 'ACCORDION',
		tabGroups: getTabGroups(sortedSelectedSelectorsToRender, featureTogglesModel, stickySummaryModel),
		tabSettings: {},
		wrapperRenderer: SelectorTabccordionTab,
	};

	//@ts-ignore
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const updateTabGroupsDisposer = reaction(() => {
		return selectorConfigModel.sortedSelectedSelectorsToRender;
	}, (newSortedSelectedSelectorsToRender) => {
		const {
			tabGroups = [],
			tabs = [],
		} = selectorMagicTabccordionStore;

		const [firstTabGroup = {}] = tabGroups;

		const { tabs: firstTabGroupTabs = [] } = firstTabGroup;

		const [firstTab = {}] = tabs;

		const activeTab = firstTabGroupTabs.find(({ isActive = false }) => isActive) || firstTab;

		const activeTabIndex = newSortedSelectedSelectorsToRender.indexOf(activeTab.selectedSelector);

		const newTabGroups = getTabGroups(newSortedSelectedSelectorsToRender, featureTogglesModel, stickySummaryModel, activeTabIndex);

		selectorMagicTabccordionStore.updateTabs(newTabGroups, selectorTabccordionData.id);
	});

	const selectorMagicTabccordionStore = new MagicTabccordionStore(selectorTabccordionData, linkEventStore);

	return selectorMagicTabccordionStore;
};
