import {
	computed, observable, makeObservable,
} from 'mobx';

class CuralateItemModel {
	data;

	store;

	hasLoadedSrc;

	hasLoadedImage;

	sliderItem;

	index;

	focused;

	placeholder;

	constructor() {
		makeObservable(this, {
			data: observable,
			store: observable,
			hasLoadedSrc: observable,
			hasLoadedImage: observable,
			sliderItem: observable,
			index: observable,
			focused: observable,
			placeholder: observable,
			id: computed,
			labels: computed,
			media: computed,
			products: computed,
			source: computed,
			shouldLoadImage: computed,
		});
	}

	get id() {
		return this.data.id;
	}

	get labels() {
		return this.data.labels;
	}

	get media() {
		return this.data.media;
	}

	get products() {
		return this.data.products;
	}

	get source() {
		return this.data.source;
	}

	get shouldLoadImage() {
		// const {
		// 	elementWidthsSet,
		// 	itemsContainerWidth,
		// 	scrollLeft,
		// } = this.store;
		// const vMin = scrollLeft - itemsContainerWidth;
		// const vMax = scrollLeft + (itemsContainerWidth * 2);
		// const shouldLoadImage = this.itemOffset >= vMin && this.itemOffset <= vMax;
		// console.info(this.store, vMin, vMax, shouldLoadImage);
		// if (!itemsContainerWidth || elementWidthsSet === false) { return false; }

		// // if (shouldLoadImage) {
		// // 	this.hasLoadedImage = true;
		// // }

		return true;
	}
}

const CuralateItemModelFactory = {
	create: (data, index) => {
		const instance = new CuralateItemModel();
		instance.data = data;
		instance.index = index;

		return instance;
	},
};

export { CuralateItemModelFactory };
