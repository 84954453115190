class HelpfulLinkModel {
	data;

	constructor(data) {
		this.data = data || {};
	}

	get id() {
		return this.data.id;
	}

	get key() {
		return this.data.key;
	}

	get linkTitle() {
		return this.data.linkTitle;
	}

	get url() {
		return this.data.link;
	}

	get behavior() {
		return this.data.behavior || '';
	}

	get shouldOpenInNewTab() {
		return this.behavior === 'NEW';
	}
}

export { HelpfulLinkModel };
