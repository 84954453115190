import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import type { IWorkspaceProductsChunkSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductsChunkSectionals.interface';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { imageInchesToPixelsFactor } from '~/product/sectionals/workspace/workspace-sectionals.constants';

import styles from '~/product/sectionals/workspace/Components/product/workspace-products-chunk-depth.module.scss';

interface Props {
	imageScale: number
	workspaceProductsChunkModel: IWorkspaceProductsChunkSectionals
}

export const WorkspaceProductsChunkDepthSectionals = observer(({
	imageScale = 0,
	workspaceProductsChunkModel: {
		isReversedDirection = false,
		workspaceProductModelsWithAdjacentWorkspaceProductModels = [],
		workspaceProductsChunkOrientation = '',
		workspaceProductsChunkDepth = 0,
	},
}: Props) => {
	const {
		workspaceModel: {
			isDragging = false,
		} = {},
	} = useProductContext();

	const workspaceProductsChunkPixelsHeight: number = workspaceProductModelsWithAdjacentWorkspaceProductModels.reduce((accumulatedWorkspaceProductsDepth, { workspaceProductDepth = 0 }) => {
		return accumulatedWorkspaceProductsDepth + (imageInchesToPixelsFactor * workspaceProductDepth) / imageScale;
	}, 0);

	return (
		<div
			className={
				classNames(
					styles['workspace-products-chunk-depth'],
					styles[`workspace-products-chunk-depth-${workspaceProductsChunkOrientation}`], {
						[styles['workspace-products-chunk-depth-reversed']]: isReversedDirection,
						'tw-hidden': isDragging,
					}
				)
			}
			style={{ height: workspaceProductsChunkPixelsHeight }}
		>
			<div className={styles['workspace-products-chunk-depth-line']}>
				<span className={styles['workspace-products-chunk-depth-value']}>
					{workspaceProductsChunkDepth}"
				</span>
			</div>
		</div>
	);
});
