import { IStyliticsViewJSON } from '~/tracking/vendor-events/stylitics/style-view/Interfaces/IStyliticsViewEvent.tracking';
import { sanitize } from '~/util/sanitizeString';
import { VendorEventTrackingModel } from '~/tracking/vendor-events/common/Models/VendorEvent.tracking.model';

export class StyliticsViewEventTrackingModel {
	articleNumber: string;

	id: string;

	items: object;

	material: string | null;

	constructor(articleNumber: string, id: string, items: object, material: string | null) {
		this.articleNumber = articleNumber;
		this.id = id;
		this.items = items;
		this.material = material;
	}

	get bundleItems(): string {
		return Object.values(this.items).map((item) => {
			const sku = item.skus[0].split('_')[0];
			return `${this.id}:${sku}`;
		}).join('|');
	}

	get bundleItems_arr(): Object[] {
		return Object.values(this.items).map((item) => {
			const sku = item.skus[0].split('_')[0];
			return { value: `${this.id}:${sku}` };
		});
	}

	get styleKey() {
		if (this.material) {
			return `${this.articleNumber}|${this.material}`;
		}
		return this.articleNumber;
	}

	get trackingJson(): IStyliticsViewJSON {
		const vendorEventTrackingModel = new VendorEventTrackingModel();
		return {
			event: 'styleview',
			stylitics: this.bundleItems,
			stylitics_arr: this.bundleItems_arr,
			content: vendorEventTrackingModel.pageID,
			page: {
				id: vendorEventTrackingModel.pageID,
			},
			vendor: {
				styleKey: sanitize(this.styleKey),
			},
		};
	}
}
