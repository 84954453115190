import { observer } from 'mobx-react';
import React from 'react';

import type { ISelectorProps } from '~/product/casegoods/selector/Types/SelectorCasegoods.types';

import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { SelectorRecursive } from '~/product/casegoods/selector/Components/recursive/SelectorRecursive';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/selector/frame/selector-frame.module.scss';

export const SelectorStaticHeading = observer(({
	selectedSelector: {
		selectorValuesToRender,
	},
	scrollHandler,
	touchStartHandler,
}: ISelectorProps) => {
	const {
		selectorConfigModel: {
			settings: {
				onlyOneDimension = false,
			} = {},
		} = {},
	} = useProductContext();

	return (
		<ConditionalWrapper
			condition={!onlyOneDimension}
			wrapper={(children: React.ReactElement<any, any>) => (
				<div className={styles['selector-values-groups-frame']} onScroll={scrollHandler} onTouchStart={touchStartHandler}>{children}</div>
			)}>
			{
				selectorValuesToRender.map(({
					selectedSelector = {},
					title = '',
				}, index = 0) => {
					return (
						<div
							className={styles['selector-values-group-frame-container']}
							data-qa="selector-values-group-frame-container"
							key={`selector-values-group-frame-container-${index}`}
						>
							<span className={styles['selector-values-group-frame-title']}>
								{title}
							</span>
							<SelectorRecursive selectedSelector={selectedSelector} />
						</div>
					);
				})
			}
		</ConditionalWrapper>
	);
});
