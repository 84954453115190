import axios from 'axios';
import { action, makeObservable, runInAction } from 'mobx';

import type { AxiosResponse } from 'axios';

import type { ISelectorConfigSectionals } from '~/product/sectionals/selector/Interfaces/SelectorConfigSectionals.interface';
import type { ISelectorQuestion } from '~/product/sectionals/selector/Interfaces/SelectorQuestion.interface';
import type { ISelectorValueProduct } from '~/product/sectionals/selector/Interfaces/SelectorValueProduct.interface';
import type { ISelectorValueQuestion } from '~/product/sectionals/selector/Interfaces/SelectorValueQuestion.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

import { apiUrl, isOnServer, s7ImagePath } from '~/global/global.constants';
import { deselectSelectorValue, getSelectorValueByArticleNumber, selectSelectorValue } from '~/product/common/selector/Utils/SelectorConfig.utils';
import { updateProductSectionalsModel } from '~/product/sectionals/Helpers/ProductsSectionals.init';
import { selectProductQuestionAnswers } from '~/product/sectionals/selector/Utils/SelectorConfigSectionals.utils';

export class SelectorConfigSectionalsStore {
	linkEventStore: any;

	selectorConfigModel: ISelectorConfigSectionals;

	constructor({
		linkEventStore = {},
		selectorConfigModel = {},
	}: any) {
		makeObservable(this, {
			getProductDataBySelectorValue: action,
			selectProductSelectorValue: action,
			selectQuestionSelectorValue: action,
			selectSelectorValue: action,
			updateProductModelsQuestionAnswers: action,
			updateQuestionSelectorModels: action,
		});

		this.linkEventStore = linkEventStore;
		this.selectorConfigModel = selectorConfigModel;
	}

	getDrapeableImageData(): Promise<AxiosResponse | void> {
		const {
			selectedProduct,
			selectedProduct: {
				drapeableImageApiLink = '',
				isLoaded = false,
				questionModels = [],
			} = {},
		} = this.selectorConfigModel;

		const isCompleteConfiguration = questionModels.every(({ selectedAnswer }) => selectedAnswer);

		const questionParam = questionModels.map(({
			group = '',
			selectedAnswer: {
				key = '',
			} = {},
		}) => `${group}:${key}`).filter(Boolean);

		const params = { question: questionParam };

		if (drapeableImageApiLink && isLoaded && isCompleteConfiguration) {
			const url = `${apiUrl}${drapeableImageApiLink}`;

			return axios.request({
				url,
				method: 'get',
				params,
				paramsSerializer: (paramsToSerialize) => {
					return Object.entries(paramsToSerialize).map(([key, value]: any) => {
						if (Array.isArray(value)) {
							return value.map((subValue) => {
								return `${key}=${encodeURIComponent(subValue as any)}`;
							}).join('&');
						}

						return `${key}=${encodeURIComponent(value)}`;
					}).join('&');
				},
			})
				.then(({ data = {} }) => {
					const [drapeableImageUrl = ''] = Object.values(data);

					if (typeof drapeableImageUrl !== 'string') {
						return;
					}
					selectedProduct.drapeableImageUrl = drapeableImageUrl;
				})
				.catch((error) => {
					console.error(error);
				});
		}

		return new Promise((resolve) => { resolve(); });
	}

	getOttomanImageData() {
		if (!this.selectorConfigModel.unattachedOttomanProductModel) {
			return null;
		}

		const {
			selectedProduct: {
				questionModels = [],
			} = {},
			ottomanProductGroup: {
				_links: {
					images: {
						href: url = '',
					} = {},
				} = {},
			} = {},
		} = this.selectorConfigModel;

		const questionParam = questionModels.map(({
			group = '',
			selectedAnswer: {
				key = '',
			} = {},
		}) => `${group}:${key}`).filter(Boolean);

		const params = { question: questionParam };

		if (url) {
			return axios.request({
				url,
				method: 'get',
				params,
				paramsSerializer: (paramsToSerialize) => {
					return Object.entries(paramsToSerialize).map(([key, value]) => {
						if (Array.isArray(value)) {
							return value.map((subValue) => {
								return `${key}=${encodeURIComponent(subValue as any)}`;
							}).join('&');
						}

						return `${key}=${encodeURIComponent(value)}`;
					}).join('&');
				},
			})
				.then(({ data = {} }) => {
					if (this.selectorConfigModel.unattachedOttomanProductModel !== null) {
						const {
							unattachedOttomanProductModel: {
								articleNumber = '',
							} = {},
						} = this.selectorConfigModel;
						this.selectorConfigModel.unattachedOttomanProductModel.imageData = data[articleNumber] || { imageUrl: `${s7ImagePath}/noimage` };
					}
				})
				.catch((error) => {
					console.info(error);
				});
		}
		return new Promise<void>((resolve) => {
			resolve();
		});
	}

	getProductDataBySelectorValue(selectorValue: ISelectorValueProduct): Promise<AxiosResponse | void> {
		const {
			preselectedProductModel = {},
			preselectedProductModel: {
				isLoaded = false,
				productApiLink = '',
			} = {},
		} = selectorValue;

		const { selectedQuestionSelectorValues = [] } = this.selectorConfigModel;

		if (!isLoaded && productApiLink) {
			const url = `${apiUrl}${productApiLink}`;

			return axios.get(url)
				.then(({ data = {} }) => {
					if (preselectedProductModel) {
						updateProductSectionalsModel({
							productData: data,
							productSectionalsModel: preselectedProductModel,
						});

						selectProductQuestionAnswers({
							productModels: [preselectedProductModel],
							questionSelectorValues: selectedQuestionSelectorValues,
						});
					} else {
						console.warn('No product found for selectorValue:', selectorValue);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}

		return new Promise<void>((resolve) => {
			resolve();
		});
	}

	deselectOttomanSelectorValue(selectorValueToSelect: ISelectorValueProduct): void {
		const { flattenedOttomanSelectorsAndSelectorValues = [] } = this.selectorConfigModel;

		deselectSelectorValue(flattenedOttomanSelectorsAndSelectorValues, selectorValueToSelect);
	}

	selectOttomanSelectorValue(selectorValueToSelect: ISelectorValueProduct): void {
		const { flattenedOttomanSelectorsAndSelectorValues = [] } = this.selectorConfigModel;

		selectSelectorValue(flattenedOttomanSelectorsAndSelectorValues, selectorValueToSelect);
	}

	selectProductSelectorValue(selectorValueToSelect: ISelectorValueProduct): void {
		const { flattenedProductSelectorsAndSelectorValues = [] } = this.selectorConfigModel;

		selectSelectorValue(flattenedProductSelectorsAndSelectorValues, selectorValueToSelect);
	}

	selectQuestionSelectorValue(selectorValueToSelect: ISelectorValueQuestion): void {
		if (!selectorValueToSelect) {
			return;
		}

		const { questionAnswers = [] } = selectorValueToSelect;

		const matchingParentQuestionSelectorModel = this.selectorConfigModel.questionSelectorModels.find(({ questionGroups = [] }: ISelectorQuestion): boolean => {
			return questionAnswers.every(({ group = '' }) => questionGroups.includes(group));
		});

		if (matchingParentQuestionSelectorModel) {
			matchingParentQuestionSelectorModel.selectorValues.forEach((selectorValue: ISelectorValueQuestion): void => {
				selectorValue.selected = false;
			});
		} else {
			console.warn('Could not find parent question selector for questionAnswers', questionAnswers);
		}

		selectorValueToSelect.selected = true;
	}

	selectSelectorValue(selectorValueToSelect: ISelectorValueProduct | ISelectorValueQuestion | any, isAggregatedQuestionSelector: boolean, workspaceProductModels: Array<IWorkspaceProductSectionals>): void {
		const { questionAnswers } = selectorValueToSelect;

		if (questionAnswers) {
			this.selectQuestionSelectorValue(selectorValueToSelect);
		} else {
			this.selectProductSelectorValue(selectorValueToSelect);
		}

		this.getProductDataBySelectorValue(this.selectorConfigModel.selectedProductSelectorValue)
			.then(() => {
				this.updateQuestionSelectorModels();

				this.updateProductModelsQuestionAnswers();

				this.getDrapeableImageData();
			})
			.catch((error) => {
				console.error(error);
			});

		if (isAggregatedQuestionSelector) {
			workspaceProductModels.forEach(({ productSelectorValue }) => {
				this.getProductDataBySelectorValue(productSelectorValue)
					.catch((error) => {
						console.error(error);
					});
			});
		}

		// Update the ottoman product model if applicable
		if (this.selectorConfigModel.unattachedOttomanProductModel) {
			if (isAggregatedQuestionSelector) {
				this.persistUnattachedOttomanSelection();
			}
			const unattachedOttoman = this.selectorConfigModel.unattachedOttomanProductModel;
			const unattachedOttomanSelectorValue: ISelectorValueProduct = getSelectorValueByArticleNumber(this.selectorConfigModel.flattenedOttomanSelectorValues, unattachedOttoman.articleNumber);
			this.getProductDataBySelectorValue(unattachedOttomanSelectorValue)
				.then(() => {
					this.getOttomanImageData();
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}

	setWorkspaceProductModels(workspaceProductModels: Array<IWorkspaceProductSectionals>): void {
		const { materialQuestionSelector } = this.selectorConfigModel;

		if (materialQuestionSelector) {
			materialQuestionSelector.workspaceProductModels = workspaceProductModels;
		}
	}

	persistUnattachedOttomanSelection(): void {
		// for product groups with an aggregated question selector a new selection may change the unattached ottoman article
		// in this case, we need to persist if the unattached ottoman has been selected when
		if (!this.selectorConfigModel.unattachedOttomanSelectorValue.selected || !this.selectorConfigModel.unattachedOttomanProductModel) {
			return;
		}

		const currentOttomanSelectorValue = this.selectorConfigModel.unattachedOttomanSelectorValue;
		// new ottomanSelectorValue after making an aggregated question selection
		const newOttomanSelectorValue = getSelectorValueByArticleNumber(this.selectorConfigModel.flattenedOttomanSelectorValues, this.selectorConfigModel.unattachedOttomanProductModel.articleNumber);

		if (currentOttomanSelectorValue.articleNumbers[0] !== newOttomanSelectorValue.articleNumbers[0]) {
			this.selectOttomanSelectorValue(newOttomanSelectorValue);
		}
	}

	updateQuestionSelectorModels(): void {
		const { questionSelectorModels = [] } = this.selectorConfigModel;

		questionSelectorModels.forEach((questionSelectorModel) => {
			questionSelectorModel.selectedProduct = this.selectorConfigModel.selectedProduct;

			const {
				isValidSelectedSelectorValue = false,
				validFilteredSelectorValues = [],
			} = questionSelectorModel;

			const [firstValidFilteredSelectorValue] = validFilteredSelectorValues;

			if (!isValidSelectedSelectorValue) {
				this.selectQuestionSelectorValue(firstValidFilteredSelectorValue);
			}
		});

		questionSelectorModels.forEach(({
			isValidSelectedSelectorValue = false,
			validFilteredSelectorValues = [],
		}) => {
			const [firstValidFilteredSelectorValue] = validFilteredSelectorValues;

			if (!isValidSelectedSelectorValue) {
				this.selectQuestionSelectorValue(firstValidFilteredSelectorValue);
			}
		});
	}

	updateProductModelsQuestionAnswers(): void {
		const {
			loadedProductModels = [],
			selectedQuestionSelectorValues = [],
		} = this.selectorConfigModel;

		selectProductQuestionAnswers({
			productModels: loadedProductModels,
			questionSelectorValues: selectedQuestionSelectorValues,
		});
	}
}

export const SelectorConfigSectionalsStoreFactory = ({
	create: ({
		defaultConfigOverrideModels = [],
		linkEventStore = {},
		ottomanArticleNumberOverride = null,
		productArticleNumberOverride = '',
		selectorConfigModel = {},
		selectorConfigModel: {
			defaultConfigModels = [],
			defaultProductModel: {
				drapeableImageUrl = '',
				productArticleNumber = '',
			} = {},
			flattenedQuestionSelectorValues = [],
			ottomanSelectorValues = [],
			productSelectorValues = [],
		} = {},
	}: any) => {
		const selectorConfigSectionalsStore = new SelectorConfigSectionalsStore({
			linkEventStore,
			selectorConfigModel,
		});

		const productArticleNumberToUse = productArticleNumberOverride || productArticleNumber;

		const unattachedOttomanSelectorValue = ottomanArticleNumberOverride ? getSelectorValueByArticleNumber(ottomanSelectorValues, ottomanArticleNumberOverride) : null;

		const defaultConfigModelsToUse = defaultConfigOverrideModels.length ? defaultConfigOverrideModels : defaultConfigModels;

		// find the end-of-the-line product selectorValue, which will drive selectedProduct
		const defaultProductSelectorValue = getSelectorValueByArticleNumber(productSelectorValues, productArticleNumberToUse);

		// filter the question selectorValues to those that represent defaultConfig
		const defaultConfigQuestionSelectorValues = flattenedQuestionSelectorValues.filter(({ questionAnswers = [] }) => {
			return questionAnswers.every(({
				answer = '',
				group = '',
			}) => {
				return defaultConfigModelsToUse.find(({
					answer: defaultConfigAnswer = '',
					group: defaultConfigGroup = '',
				}) => {
					return answer === defaultConfigAnswer && group === defaultConfigGroup;
				});
			});
		});

		// verify that all defaultConfig entries have found a matching question selectorValue
		defaultConfigModelsToUse.forEach((defaultConfigModel: any = {}) => {
			const {
				answer: defaultConfigAnswer = '',
				group: defaultConfigGroup = '',
			} = defaultConfigModel;

			const defaultConfigQuestionSelectorValue = defaultConfigQuestionSelectorValues.find(({ questionAnswers = [] }) => {
				return questionAnswers.some(({
					answer = '',
					group = '',
				}) => {
					return answer === defaultConfigAnswer && group === defaultConfigGroup;
				});
			});

			if (!defaultConfigQuestionSelectorValue) {
				console.warn('Could not find Question selectorValue for defaultConfig', defaultConfigModel);
			}
		});

		if (unattachedOttomanSelectorValue) {
			selectorConfigSectionalsStore.selectOttomanSelectorValue(unattachedOttomanSelectorValue);
		}

		// select the product selectorValue and question selectorValues
		runInAction(() => {
			if (defaultProductSelectorValue) {
				selectorConfigSectionalsStore.selectProductSelectorValue(defaultProductSelectorValue);

				defaultConfigQuestionSelectorValues.forEach((defaultConfigQuestionSelectorValue: ISelectorValueQuestion) => {
					selectorConfigSectionalsStore.selectQuestionSelectorValue(defaultConfigQuestionSelectorValue);
				});

				if (!isOnServer || !productArticleNumberOverride) {
					selectorConfigSectionalsStore.getProductDataBySelectorValue(defaultProductSelectorValue)
						.then(() => {
							selectorConfigSectionalsStore.updateQuestionSelectorModels();

							selectorConfigSectionalsStore.updateProductModelsQuestionAnswers();

							selectorConfigSectionalsStore.getOttomanImageData();

							// TODO: have the API send defaultProduct.drapeableImageUrl on initial payload
							if (!drapeableImageUrl) {
								selectorConfigSectionalsStore.getDrapeableImageData();
							}
						})
						.catch((error) => {
							console.error(error);
						});
				}
			}
		});

		return selectorConfigSectionalsStore;
	},
});
