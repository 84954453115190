import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { MediaControl } from '~/media-set/Components/MediaControl';
import { MediaCylindo } from '~/product/common/media-set/Components/MediaCylindo';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

import styles from '#/product/common/media-set/media-control-cylindo.module.scss';

export const MediaControlCylindo = observer((props = {}) => {
	const {
		mediaCylindoModel = {},
		mediaCylindoModel: {
			hasCylindo = false,
			isCylindoActive = false,
		} = {},
		mediaCylindoStore = {},
		mediaSetStore = {},
	} = props;

	const {
		magicModal = {},
	} = useGlobalContext();

	if (!hasCylindo) {
		return null;
	}

	function handleClick(event) {
		event.stopPropagation();

		if (isCylindoActive) {
			mediaCylindoStore.setIsCylindoActive(false);

			mediaCylindoStore.setIsCylindoARActive(false);

			mediaCylindoStore.setIsCylindoDimensionsActive(false);

			mediaSetStore.setIsMediaSetOverrideActive(false);
		} else {
			mediaCylindoStore.getCylindoData()
				.then(() => {
					if (window.matchMedia('(max-width: 40em)').matches) {
						mediaCylindoStore.setIsCylindoARActive(false);

						mediaCylindoStore.setIsCylindoDimensionsActive(false);

						magicModal.openModal({
							title: '',
							content: {
								children: (
									<MediaCylindo
										forceLoad={true}
										mediaCylindoId="media-cylindo-modal"
										mediaCylindoModel={mediaCylindoModel}
										mediaCylindoStore={mediaCylindoStore}
										mediaSetStore={mediaSetStore}
										tooltipZoomText="Drag to pan"
										zoom={true}
									/>
								),
							},
							width: '100vw',
							maxWidth: '725px',
						});
					} else {
						runInAction(() => {
							mediaCylindoStore.setIsCylindoActive(true);

							mediaCylindoStore.setIsCylindoARActive(false);

							mediaCylindoStore.setIsCylindoDimensionsActive(false);

							mediaSetStore.setIsMediaSetOverrideActive(true);
						});
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}

	return (
		<MediaControl>
			<button
				aria-current={isCylindoActive}
				className={
					classNames(styles['media-control-cylindo'], {
						[styles['media-control-cylindo-active']]: isCylindoActive,
					})
				}
				data-tr-link-event-name="cylindo 360 view"
				data-tr-link-event-track={isCylindoActive ? false : null}
				data-tr-link-event-type={LinkEventTypes.SITE_ACTION}
				data-qa="media-control-cylindo"
				onClick={handleClick}
			>
				<span className={styles['media-control-cylindo-text']}>
					<span className="tw-sr-only">View </span>360º<span className="tw-sr-only"> media</span>
				</span>
			</button>
		</MediaControl>
	);
});
