import { MediaWorkspaceCasegoodsModelFactory } from '~/product/casegoods/media-set/Models/MediaWorkspaceCasegoods.model';

export const createMediaWorkspaceCasegoodsModel = ({
	mediaData = {},
	workspaceModel = {},
}) => {
	const mediaWorkspaceModel = MediaWorkspaceCasegoodsModelFactory.create({
		...mediaData,
		workspaceModel,
	});

	return mediaWorkspaceModel;
};
