import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';

import type { IWorkspaceProductJoinableSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductJoinableSectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { getValidWorkspaceProductOrientations, updateWorkspacePreflightModel } from '~/product/sectionals/workspace/Utils/WorkspaceSectionals.utils';

import styles from '~/product/sectionals/workspace/Components/product/workspace-product-joinable-droppable.module.scss';

interface Props {
	imageScale: number,
	workspaceProductJoinableModel: IWorkspaceProductJoinableSectionals
	workspaceProductModel: IWorkspaceProductSectionals
	workspaceProductFrameDepthRatioToUse: number
	workspaceProductFrameWidthRatioToUse: number
	workspaceProductPixelsHeight: number
	workspaceProductPixelsWidth: number
}

export const WorkspaceProductJoinableDroppableSectionals = observer(({
	imageScale = 0,
	workspaceProductJoinableModel,
	workspaceProductJoinableModel: {
		joinableSideOrientation = '',
		nextWorkspaceProductModel = null,
		prevWorkspaceProductModel = null,
	},
	workspaceProductModel,
	workspaceProductFrameDepthRatioToUse = 0,
	workspaceProductFrameWidthRatioToUse = 0,
	workspaceProductPixelsHeight = 0,
	workspaceProductPixelsWidth = 0,
}: Props) => {
	const {
		selectorConfigStore = {},
		workspaceModel = {},
		workspaceModel: {
			firstWorkspaceProductModel = {},
			dragOrientation = '',
			dragPreviewHeight = 0,
			dragPreviewWidth = 0,
			isDragging = false,
			isDraggingWorkspaceProductModel = null,
			workspaceProductModels = [],
		} = {},
		workspacePreflightModel = {},
		workspacePreflightStore = {},
		workspaceStore = {},
	} = useProductContext();

	const isDraggingCurrentWorkspaceProductModel = isDraggingWorkspaceProductModel === workspaceProductModel;

	const showDroppableIcon = isDragging && !isDraggingCurrentWorkspaceProductModel;

	const { orientation: nextWorkspaceProductModelOrientation = '' } = nextWorkspaceProductModel || {};

	const iconPadding = 10 * 6;

	const iconSize = 30 * 6;

	const iconPixelsPadding = showDroppableIcon ? iconPadding / imageScale : 0;

	const iconPixelsSize = showDroppableIcon ? iconSize / imageScale : 0;

	// these refs are needed inside the canDrop() function, as the values being passed from the collect() function are stale
	const hasOverlappingWorkspaceProductsRef = useRef<boolean>(false);

	const validNextWorkspaceProductOrientationsRef = useRef<any[]>([]);

	const validWorkspaceProductOrientationsRef = useRef<any[]>([]);

	const itemRef = useRef<any>(null);

	const [{
		canDrop = false,
		isOver = false,
		draggableHasOverlappingWorkspaceProducts = false,
		draggableValidNextWorkspaceProductOrientations = [],
		draggableValidWorkspaceProductOrientations = [],
	}, drop] = useDrop({
		accept: 'PRODUCT',
		canDrop: (): boolean => {
			const { isDraggingWorkspaceProductModel: draggableIsDraggingWorkspaceProductModel = null } = workspaceModel;

			const isDraggingNextWorkspaceProductModel = draggableIsDraggingWorkspaceProductModel === nextWorkspaceProductModel;

			if (draggableHasOverlappingWorkspaceProducts) {
				return false;
			}

			if (nextWorkspaceProductModel && !isDraggingNextWorkspaceProductModel) {
				return Boolean(validWorkspaceProductOrientationsRef.current.length) && Boolean(validNextWorkspaceProductOrientationsRef.current.length);
			}

			return Boolean(validWorkspaceProductOrientationsRef.current.length);
		},
		collect: (monitor) => {
			const item = monitor.getItem() || {};

			const {
				productSelectorValue,
				workspaceProductModel: draggableWorkspaceProductModel,
			}: any = item;

			const workspaceProductOrientations = productSelectorValue
				? getValidWorkspaceProductOrientations({
					nextWorkspaceProductModel,
					productSelectorValue,
					workspaceProductJoinableModel,
					workspaceProductModel,
				})
				: [];

			const [firstWorkspaceProductOrientation = {}] = workspaceProductOrientations;

			const { nextWorkspaceProductOrientations = [] } = firstWorkspaceProductOrientation;

			if (item !== itemRef.current && productSelectorValue) {
				const workspaceProductPreflightIndexToRemove = workspaceProductModels.indexOf(draggableWorkspaceProductModel);

				const workspaceProductPreflightIndex = workspaceProductModels.indexOf(workspaceProductModel);

				const nextWorkspaceProductPreflightIndex = workspaceProductModels.indexOf(nextWorkspaceProductModel);

				workspacePreflightStore.removeAllWorkspaceProductModels();

				workspacePreflightStore.addFirstWorkspaceProductModel(firstWorkspaceProductModel);

				const { firstWorkspaceProductModel: firstWorkspaceProductPreflightModel = {} } = workspacePreflightModel;

				updateWorkspacePreflightModel({
					workspacePreflightModel,
					workspacePreflightStore,
					workspaceProductModel: firstWorkspaceProductModel,
					workspaceProductPreflightModel: firstWorkspaceProductPreflightModel,
				});

				const { workspaceProductModels: workspaceProductPreflightModels = [] } = workspacePreflightModel;

				const workspaceProductPreflightModelToRemove = workspaceProductPreflightModels[workspaceProductPreflightIndexToRemove];

				const workspaceProductPreflightModel = workspaceProductPreflightModels[workspaceProductPreflightIndex];

				const nextWorkspaceProductPreflightModel = workspaceProductPreflightModels[nextWorkspaceProductPreflightIndex];

				const { orientation: nextWorkspaceProductPreflightModelOrientation = '' } = nextWorkspaceProductPreflightModel || {};

				const { workspaceProductJoinableModelsByOrientation: workspaceProductJoinablePreflightModelsByOrientation = {} } = workspaceProductPreflightModel;

				const workspaceProductJoinablePreflightModel = workspaceProductJoinablePreflightModelsByOrientation[joinableSideOrientation];

				const workspaceProductPreflightOrientations = getValidWorkspaceProductOrientations({
					nextWorkspaceProductModel: nextWorkspaceProductPreflightModel,
					productSelectorValue,
					workspaceProductJoinableModel: workspaceProductJoinablePreflightModel,
					workspaceProductModel: workspaceProductPreflightModel,
				});

				const [firstWorkspaceProductPreflightOrientation = {}] = workspaceProductPreflightOrientations;

				const { nextWorkspaceProductOrientations: nextWorkspaceProductPreflightOrientations = [] } = firstWorkspaceProductPreflightOrientation;

				const {
					orientation: preflightOrientation = 'N',
					workspaceProductJoinableModel: workspaceProductJoinablePreflightModelToAdd,
				} = firstWorkspaceProductPreflightOrientation;

				const matchingNextPreflightOrientations = nextWorkspaceProductPreflightOrientations.find((nextPreflightOrientations = []) => {
					return nextPreflightOrientations.find(({ orientation: nextPreflightOrientation = '' }) => nextPreflightOrientation === nextWorkspaceProductPreflightModelOrientation);
				});

				const [firstNextPreflightOrientations = []] = nextWorkspaceProductPreflightOrientations;

				const nextPreflightOrientationsToUse = matchingNextPreflightOrientations || firstNextPreflightOrientations;

				const [firstNextPreflightOrientation = {}] = nextPreflightOrientationsToUse;

				const {
					orientation: nextPreflightOrientation = 'N',
					workspaceProductJoinableModel: nextWorkspaceProductJoinablePreflightModelToAdd
				} = firstNextPreflightOrientation;

				// preflight removing an existing piece
				if (draggableWorkspaceProductModel && workspaceProductPreflightModelToRemove) {
					workspacePreflightStore.removeWorkspaceProductModel(workspaceProductPreflightModelToRemove);
				}

				workspacePreflightStore.addWorkspaceProductModel({
					nextOrientation: nextPreflightOrientation,
					nextWorkspaceProductJoinableModel: nextWorkspaceProductJoinablePreflightModelToAdd,
					orientation: preflightOrientation,
					productSelectorValue,
					workspaceProductJoinableModel: workspaceProductJoinablePreflightModelToAdd,
					workspaceProductModel: workspaceProductPreflightModel,
				});

				const { hasOverlappingWorkspaceProducts: preflightHasOverlappingWorkspaceProducts = false } = workspacePreflightModel;

				hasOverlappingWorkspaceProductsRef.current = preflightHasOverlappingWorkspaceProducts;
			}

			itemRef.current = item;

			validNextWorkspaceProductOrientationsRef.current = nextWorkspaceProductOrientations;

			validWorkspaceProductOrientationsRef.current = workspaceProductOrientations;

			return {
				canDrop: monitor.canDrop(),
				draggableHasOverlappingWorkspaceProducts: hasOverlappingWorkspaceProductsRef.current,
				draggableValidWorkspaceProductOrientations: validWorkspaceProductOrientationsRef.current,
				draggableValidNextWorkspaceProductOrientations: validNextWorkspaceProductOrientationsRef.current,
				isOver: monitor.isOver(),
			};
		},
		drop: ({
			productSelectorValue = {},
			workspaceProductModel: draggableWorkspaceProductModel = {},
		}: any): void => {
			const isDroppingPrevWorkspaceProductModel = draggableWorkspaceProductModel === prevWorkspaceProductModel;

			if (isDroppingPrevWorkspaceProductModel) {
				return;
			}

			const [firstOrientation = {}] = draggableValidWorkspaceProductOrientations;

			const {
				orientation = 'N',
				workspaceProductJoinableModel: workspaceProductJoinableModelToAdd,
			} = firstOrientation;

			const matchingNextOrientations = draggableValidNextWorkspaceProductOrientations.find((nextOrientations = []) => {
				return nextOrientations.find(({ orientation: nextOrientation = '' }) => nextOrientation === nextWorkspaceProductModelOrientation);
			});

			const [firstNextOrientations = []] = draggableValidNextWorkspaceProductOrientations;

			const nextOrientationsToUse = matchingNextOrientations || firstNextOrientations;

			const [firstNextOrientation = {}] = nextOrientationsToUse;

			const {
				orientation: nextOrientation = 'N',
				workspaceProductJoinableModel: nextWorkspaceProductJoinableModelToAdd
			} = firstNextOrientation;

			if (isDraggingWorkspaceProductModel) {
				if (nextWorkspaceProductModel !== draggableWorkspaceProductModel) {
					runInAction(() => {
						workspaceStore.removeWorkspaceProductModel(draggableWorkspaceProductModel);

						workspaceStore.addWorkspaceProductModel({
							nextOrientation,
							nextWorkspaceProductJoinableModel: nextWorkspaceProductJoinableModelToAdd,
							orientation,
							productSelectorValue,
							workspaceProductJoinableModel: workspaceProductJoinableModelToAdd,
							workspaceProductModel,
						});

						const { hasBufferingWorkspaceProducts = false } = workspaceModel;
						if (hasBufferingWorkspaceProducts) {
							workspaceStore.setShowMinimumSpacingMessage(true);
						}
					});
				}
			} else {
				selectorConfigStore.getProductDataBySelectorValue(productSelectorValue)
					.then(() => {
						runInAction(() => {
							workspaceStore.addWorkspaceProductModel({
								nextOrientation,
								nextWorkspaceProductJoinableModel: nextWorkspaceProductJoinableModelToAdd,
								orientation,
								productSelectorValue,
								workspaceProductJoinableModel: workspaceProductJoinableModelToAdd,
								workspaceProductModel,
							});

							selectorConfigStore.setWorkspaceProductModels(workspaceModel.workspaceProductModels);

							const { hasBufferingWorkspaceProducts = false } = workspaceModel;

							if (hasBufferingWorkspaceProducts) {
								workspaceStore.setShowMinimumSpacingMessage(true);
							}
						});
					})
					.catch((error: any) => {
						console.error(error);
					});
			}
		},
		hover: (): void => {
			const [firstOrientation = {}] = draggableValidWorkspaceProductOrientations;

			const { orientation = 'N' } = firstOrientation;

			workspaceStore.setDragOrientation(orientation);
		},
	});

	const minJoinableSize = iconPixelsSize + iconPixelsPadding * 2;
	const joinableHeight = ['E', 'W'].includes(joinableSideOrientation) ? workspaceProductPixelsHeight * workspaceProductFrameDepthRatioToUse : minJoinableSize;
	const joinableWidth = ['N', 'S'].includes(joinableSideOrientation) ? workspaceProductPixelsWidth * workspaceProductFrameWidthRatioToUse : minJoinableSize;

	const dragOrientedWidth = ['N', 'S'].includes(dragOrientation) ? dragPreviewWidth : dragPreviewHeight;
	const dragOrientedHeight = ['N', 'S'].includes(dragOrientation) ? dragPreviewHeight : dragPreviewWidth;

	const dropTargetWidth = joinableWidth + dragOrientedWidth;
	const dropTargetHeight = joinableHeight + dragOrientedHeight;

	return (
		<div
			className={
				classNames(styles['workspace-product-joinable-droppable'], {
					[styles['workspace-product-joinable-droppable-active']]: showDroppableIcon,
					[styles['workspace-product-joinable-droppable-error']]: !canDrop,
					[styles['workspace-product-joinable-droppable-error-hover']]: !canDrop && isOver,
					[styles['workspace-product-joinable-droppable-hover']]: canDrop && isOver,
				})
			}
			data-qa={`workspace-product-joinable-droppable-${joinableSideOrientation}`}
			ref={drop}
			style={{ padding: iconPixelsPadding }}
		>
			<div
				className={styles['workspace-product-joinable-droppable-icon']}
				style={{
					height: iconPixelsSize,
					width: iconPixelsSize,
				}}
			/>
			<div
				className={styles['workspace-product-joinable-droppable-hitbox']}
				style={{
					height: dropTargetHeight,
					width: dropTargetWidth,
				}}
			/>
		</div>
	);
});
