import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import type { IWorkspaceProductsChunkSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductsChunkSectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductChildrenSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductChildrenSectionals';
import { imageInchesToPixelsFactor } from '~/product/sectionals/workspace/workspace-sectionals.constants';

import styles from '~/product/sectionals/workspace/Components/product/workspace-product-draggable.module.scss';

interface Props {
	imageScale: number
	isDraggable: boolean
	showDimensions: boolean
	workspaceProductModel: IWorkspaceProductSectionals
}

export const WorkspaceProductDraggableSectionals = observer(({
	imageScale = 0,
	isDraggable = false,
	showDimensions = false,
	workspaceProductModel,
	workspaceProductModel: {
		productSelectorValue,
		workspaceProductDepth = 0,
		workspaceProductWidth = 0,
	},
}: Props) => {
	const {
		workspaceModel: {
			isDragging = false,
			workspaceProductsChunkModels = [],
		} = {},
		workspaceStore = {},
	} = useProductContext();

	const workspaceProductsChunkModel: IWorkspaceProductsChunkSectionals = workspaceProductsChunkModels.find(({ workspaceProductModels = [] }: IWorkspaceProductsChunkSectionals) => workspaceProductModels.includes(workspaceProductModel));

	const {
		isReversedDirection = false,
		workspaceProductsChunkOrientation = '',
		workspaceProductModels = [],
	} = workspaceProductsChunkModel;

	const adjustedImageScale = workspaceProductModels.length > 1 ? imageScale + workspaceProductModels.length : imageScale;

	const imageScaleToUse = isDragging ? adjustedImageScale : imageScale;

	const workspaceProductPixelsHeight = (imageInchesToPixelsFactor * workspaceProductDepth) / imageScaleToUse;

	const workspaceProductPixelsWidth = (imageInchesToPixelsFactor * workspaceProductWidth) / imageScaleToUse;

	const dragItem = {
		imageScale,
		productSelectorValue,
		workspaceProductModel,
	};

	const dragItemRef = useRef(dragItem);

	const [, drag, preview] = useDrag(() => ({
		end: (() => {
			runInAction(() => {
				workspaceStore.setDragOrientation('N');
				workspaceStore.setIsDragging(false);
				workspaceStore.setIsDraggingWorkspaceProductModel(null);
			});
		}),
		item: () => {
			const {
				current: {
					workspaceProductModel: draggableWorkspaceProductModel = null,
				} = {},
			} = dragItemRef;

			// allow react-dnd to capture the drag preview before hiding the element being dragged
			setTimeout(() => {
				runInAction(() => {
					workspaceStore.setIsDragging(true);
					workspaceStore.setIsDraggingWorkspaceProductModel(draggableWorkspaceProductModel);
				});
			}, 0);

			return dragItemRef.current;
		},
		type: 'PRODUCT',
	}));

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	useEffect(() => {
		dragItemRef.current = dragItem;
	});

	return (
		<div
			className={
				classNames(
					styles['workspace-product-draggable'],
					styles[`workspace-product-draggable-${workspaceProductsChunkOrientation}`], {
						[styles[`workspace-product-draggable-${workspaceProductsChunkOrientation}-reversed`]]: isReversedDirection,
					}
				)
			}
			data-qa="workspace-product-draggable"
			ref={drag}
			style={{
				height: workspaceProductPixelsHeight,
				width: workspaceProductPixelsWidth,
			}}
		>
			<WorkspaceProductChildrenSectionals
				imageScale={imageScale}
				isDraggable={isDraggable}
				showDimensions={showDimensions}
				workspaceProductModel={workspaceProductModel}
				workspaceProductPixelsHeight={workspaceProductPixelsHeight}
				workspaceProductPixelsWidth={workspaceProductPixelsWidth}
			/>
		</div>
	);
});
