import { reaction } from 'mobx';

import { isOnServer } from '~/global/global.constants';
import { createMediaModel } from '~/media-set/Helpers/Media.init';
import { createMediaProductModel } from '~/product/common/media-set/Helpers/MediaProduct.init';
import { createMediaSetModel } from '~/media-set/Helpers/MediaSet.init';
import { MediaSetStoreFactory } from '~/media-set/Stores/MediaSet.store';

export const createMediaSetDetailModel = ({
	productGroup: {
		detailMediaSet: mediaSetData = [],
		mainImageCaption = '',
		mainImageOverrideFilename = '',
	} = {},
	selectorConfigModel = {},
}) => {
	// presence of mainImageOverrideFilename means we should create a standard media model, since it will be static
	const mediaProductModelHelperToUse = mainImageOverrideFilename ? createMediaModel : createMediaProductModel;

	const mediaProductModel = mediaProductModelHelperToUse({
		mediaData: {
			caption: mainImageCaption,
			fileName: mainImageOverrideFilename,
		},
		selectorConfigModel,
	});

	const mediaSetDetailModel = createMediaSetModel({
		mediaModelsHead: [mediaProductModel],
		mediaSetData,
	});

	return mediaSetDetailModel;
};

export const createMediaSetDetailStore = ({
	mediaSetModel = {},
	selectorConfigModel = {},
}) => {
	const mediaSetDetailStore = MediaSetStoreFactory.create({ mediaSetModel });

	const mediaSetDisposers = [];

	if (!isOnServer) {
		const lastSelectedSelectorValueChangedDisposer = reaction(() => selectorConfigModel.lastSelectedSelectorValue, () => {
			mediaSetDetailStore.setIsMediaSetOverrideActive(false);

			mediaSetDetailStore.selectMediaModelHead();
		});

		mediaSetDisposers.push(lastSelectedSelectorValueChangedDisposer);

		mediaSetDetailStore.setMediaSetDisposers(mediaSetDisposers);
	}

	return mediaSetDetailStore;
};
