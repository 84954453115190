import { observable, makeObservable } from 'mobx';

import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { DeliveryUps } from '~/delivery/Components/DeliveryUps';

class DeliveryUpsStore {
	hasLoaded;

	shippingMethod;

	results;

	isUpsDelivery;

	viewState

	constructor() {
		makeObservable(this, {
			hasLoaded: observable,
			shippingMethod: observable,
			results: observable,
			isUpsDelivery: observable,
		});
	}
}

export const DeliveryUpsStoreFactory = {
	create(productCommons) {
		const deliveryUpsStore = new DeliveryUpsStore();

		deliveryUpsStore.hasLoaded = true;
		deliveryUpsStore.shippingMethod = productCommons.productModel.delivery.shippingMethod;
		deliveryUpsStore.results = true;
		deliveryUpsStore.isUpsDelivery = true;
		deliveryUpsStore.viewState = new ViewStateStore(deliveryUpsStore, {
			ups: {
				component: DeliveryUps,
				onEnter: () => ({ store: deliveryUpsStore }),
			},
		});
		deliveryUpsStore.viewState.goTo('ups');

		return deliveryUpsStore;
	},
};
