'use client';

import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react';

import { CuralateItemReportForm } from '~/util/curalate/Components/CuralateItemReportForm';
// import { magicModal } from '~/components/magic-modal/Stores/MagicModal.store';

export const CuralateItemReport = observer((props) => {
	const {
		item,
		store,
		store: {
			hasError,
			isSubmitted,
		},
		magicModal,
		magicModal: {
			closeModal,
		},
	} = props;

	const closeButtonRef = useRef();

	useEffect(() => {
		if (closeButtonRef.current) {
			closeButtonRef.current.focus();
		}
	}, [closeButtonRef]);

	if (hasError) {
		return (
			<div>
				<p className="tw-text-red">Failed to report this media.</p>
				<button className={'ButtonAnchor'} onClick={closeModal} ref={closeButtonRef}>Close</button>
			</div>
		);
	}

	if (isSubmitted) {
		return (
			<div>
				<p>This media has been reported.</p>
				<button className={'ButtonAnchor'} onClick={closeModal} ref={closeButtonRef}>Close</button>
			</div>
		);
	}

	return (
		<div>
			<CuralateItemReportForm item={item} store={store} magicModal={magicModal} />
		</div>
	);
});
