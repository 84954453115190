import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { s7ImagePath } from '~/global/global.constants';

import styles from '#/product/casegoods/workspace/modal-help/workspace-modal-help.module.scss';

export const casegoodsHelpModals = {
	'CUSTOM_CABINET': (<div>
		<ul>
			<li className={styles['workspace-modal-help-step-cabinets']}>
				Choose Size
				<ul>
					<li>
						Filter cabinets by height or width.
					</li>
					<li>
						Select a cabinet, then select its depth.
					</li>
				</ul>
			</li>
			<li className={styles['workspace-modal-help-step-cabinets']}>
				Choose Materials
				<ul>
					<li>
						Choose your cabinet material, base and hardware.
					</li>
				</ul>
			</li>
			<li className={styles['workspace-modal-help-step-cabinets']}>
				Choose Inserts
				<ul>
					<li>
						Filter inserts by type, then drag and drop inserts onto your cabinet.
					</li>
					<li>
						All spaces must contain an insert. For an open space, select an open space insert.
					</li>
					<li>
						For safety, some inserts are not allowed on upper shelves.
					</li>
					<li>
						Remove inserts by dragging them off your cabinet.
					</li>
					<li>
						Your custom cabinet will be built per your design; inserts are not removable.
					</li>
				</ul>
			</li>
			<li className={styles['workspace-modal-help-step-cabinets']}>
				Choose Optional Features
				<ul>
					<li>
						Choose optional features such as extra shelves, cord openings, and more.
					</li>
				</ul>
			</li>
		</ul>
	</div>),
	'DEFAULT': (<div>
		<h2 className="Heading Heading--5 tw-text-gray tw-mb-3">Building Your Bookcase</h2>
		<ol className={styles['workspace-modal-help-steps']}>
			<li className={styles['workspace-modal-help-step']}>
				Choose Style and Size
				<ul>
					<li className="tw-hidden md:tw-list-item">
						Drag and drop up to three pieces onto the canvas.
					</li>
					<li className="md:tw-hidden">
						Tap to add up to three pieces to the canvas.
					</li>
					<li>
						Select a depth for each piece.
					</li>
					<li>
						Drag and drop pieces to rearrange.
					</li>
				</ul>
			</li>
			<li className={styles['workspace-modal-help-step']}>
				Choose Materials
				<ul>
					<li>
						Shelves and wood inserts are the same material.
					</li>
					<li>
						Frames, hardware and metal inserts are the same material.
					</li>
				</ul>
			</li>
			<li className={styles['workspace-modal-help-step']}>
				Choose Inserts
				<ul>
					<li>
						Drag and drop inserts onto shelves.
					</li>
					<li>
						You can always buy more inserts later.
					</li>
				</ul>
			</li>
		</ol>
		<div className="tw-my-11"><b>Need Help?</b> Contact Customer Care for <a href="/free-design-services">free design services</a> or assistance via <a href="/contact-us">phone, email, or chat.</a></div>
		<h2 className="Heading Heading--5 tw-text-gray">Notes About Inserts</h2>
		<div className="md:tw-grid md:tw-gap-4 md:tw-grid-cols-2">
			<figure className="tw-max-w-xs tw-m-auto">
				<img
					alt="diagram of beam bookcase showing invalid insert positions"
					src="https://rnb.scene7.com/is/image/roomandboard/Beam-help-diagram-1"
				/>
				<figcaption>Inserts may not be placed on top shelf or below bottom shelf.</figcaption>
			</figure>
			<figure className="tw-max-w-xs tw-m-auto">
				<img
					alt="diagram of beam bookcase with top and bottom clothing bar"
					src="https://rnb.scene7.com/is/image/roomandboard/Beam-help-diagram-2"
				/>
				<figcaption>No inserts are available for units with top and bottom clothing bar.</figcaption>
			</figure>
			<figure className="tw-max-w-xs tw-m-auto">
				<img
					alt="diagram of beam bookcase showing a valid drop-down insert position"
					src="https://rnb.scene7.com/is/image/roomandboard/Beam-help-diagram-3"
				/>
				<figcaption>Drop-down surface is only available at desk height.</figcaption>
			</figure>
			<figure className="tw-max-w-xs tw-m-auto">
				<img
					alt="diagram of beam bookcase showing upper shelves are invalid insert positions for drawers"
					src="https://rnb.scene7.com/is/image/roomandboard/Beam-help-diagram-4"
				/>
				<figcaption>For safety and functionality, drawers may not be placed on upper shelves.</figcaption>
			</figure>
		</div>
	</div>),
	'slim': (<div>
		<h2 className="Heading Heading--5 tw-text-gray tw-mb-4">Building Your Wall Unit</h2>
		<div className={styles['workspace-modal-help-stack']}>
			<ul>
				<li>Drag and drop up to three pieces onto the canvas.</li>
				<li>Position the pieces in your desired order by dragging and dropping.</li>
				<li>Drag and drop inserts onto shelves. Inserts are width-specific.</li>
				<li>Choose the frame color, type of wood for your inserts, and hardware preference for inserts with doors.</li>
			</ul>
		</div>
		<div className="tw-my-11"><b>Need Help?</b> Contact Customer Care for <a href="/free-design-services">free design services</a> or assistance via <a href="/contact-us">phone, email, or chat.</a></div>
		<h2 className="Heading Heading--5 tw-text-gray tw-mb-4">Notes About Inserts</h2>
		<div className="tw-grid tw-gap-4 md:tw-grid-cols-1">
			<figure className="tw-pb-3">
				<S7Image
					className="tw-block md:tw-hidden"
					alt=""
					src={`${s7ImagePath}/Slim_Inserts_Help_Modal_Mobile_01`}
					height="236"
					width="600" />
				<S7Image
					className="md:tw-block tw-hidden"
					alt=""
					src={`${s7ImagePath}/Slim_Inserts_Help_Modal_01`}
					height="238"
					width="600" />
				<figcaption>42- and 34-inch wide double door inserts have an adjustable shelf behind each door and a vertical divider between the doors.</figcaption>
			</figure>
			<figure className="tw-pb-3">
				<S7Image
					className="tw-block md:tw-hidden"
					alt=""
					src={`${s7ImagePath}/Slim_Inserts_Help_Modal_Mobile_02`}
					height="236"
					width="600" />
				<S7Image
					className="md:tw-block tw-hidden"
					alt=""
					src={`${s7ImagePath}/Slim_Inserts_Help_Modal_02`}
					height="238"
					width="600" />
				<figcaption>24-inch wide double door inserts and 15-inch wide single door inserts each have one adjustable shelf behind the door(s).</figcaption>
			</figure>
			<figure className="tw-pb-3">
				<S7Image
					className="tw-block md:tw-hidden"
					alt=""
					src={`${s7ImagePath}/Slim_Inserts_Help_Modal_Mobile_03`}
					height="236"
					width="600" />
				<S7Image
					className="md:tw-block tw-hidden"
					alt=""
					src={`${s7ImagePath}/Slim_Inserts_Help_Modal_03`}
					height="220"
					width="600" />
				<figcaption>All inserts with doors include an adjustable shelf. Shelves in all inserts are adjustable.</figcaption>
			</figure>
		</div>
	</div>),
	'STACK': (<div>
		<h2 className="Heading Heading--5 tw-text-gray tw-mb-4">Building Your Wall Unit</h2>
		<div className={styles['workspace-modal-help-stack']}>
			<ul>
				<li>Drag and drop pieces to build your wall unit.</li>
				<li>Pieces can be stacked or placed next to each other.</li>
				<li>Rearrange individual pieces by dragging pieces within the wall unit.</li>
				<li>Click the "Remove" link to remove that entire unit; drag individual pieces off the workspace to remove them.</li>
			</ul>
		</div>
		<div className="tw-my-11"><b>Need Help?</b> Contact Customer Care for <a href="/free-design-services">free design services</a> or assistance via <a href="/contact-us">phone, email, or chat.</a></div>
		<h2 className="Heading Heading--5 tw-text-gray tw-mb-4">Notes About Cabinet Pieces</h2>
		<div className="tw-grid tw-gap-4 md:tw-grid-cols-2">
			<figure className={styles['figure-stack']}>
				<img
					height={500}
					alt="diagram of beam bookcase showing invalid insert positions"
					src="https://rnb.scene7.com/is/image/roomandboard/1_top_base"
				/>
				<figcaption>Each unit comes with a .75" finished top and 4" base.</figcaption>
			</figure>
			<figure className={styles['figure-stack']}>
				<img
					height={500}
					alt="diagram of beam bookcase with top and bottom clothing bar"
					src="https://rnb.scene7.com/is/image/roomandboard/2_drawer"
				/>
				<figcaption>For safety and functionality, drawer pieces cannot be placed above 36".</figcaption>
			</figure>
			<figure className={styles['figure-stack']}>
				<img
					height={500}
					alt="diagram of beam bookcase showing a valid drop-down insert position"
					src="https://rnb.scene7.com/is/image/roomandboard/3_dropdown_surface"
				/>
				<figcaption>Drop-down Surface can only be used as a bottom piece.</figcaption>
			</figure>
			<div className="tw-flex tw-items-center">
				<div>Want to reconfigure or add to your wall unit? You can buy more pieces, tops or bases by contacting <a href="/contact-us">Customer Care.</a></div>
			</div>
		</div>
	</div>),
};
