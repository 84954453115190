import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { s7ImagePath } from '~/global/global.constants';
import { addToUrl } from '~/util/addToUrl';

import styles from '~/product/casegoods/selector/Components/insert/selector-value-insert-product-image.module.scss';

export const SelectorValueInsertProductImage = observer((props) => {
	const {
		imageScale = 0,
		image = '',
		productModel: {
			salesTextToRender = '',
		} = {},
	} = props;

	const srcParams = `$proddd$&scl=${imageScale}`;

	const srcSetParams = `$proddd$&scl=${imageScale / 2}`;

	const srcAttr = typeof image === 'string' ? addToUrl(image, srcParams) : `${s7ImagePath}/web-apple-touch-icon-180x180`;

	const srcSetAttr = typeof image === 'string' ? addToUrl(image, srcSetParams) : `${s7ImagePath}/web-apple-touch-icon-180x180`;

	return (
		<img
			alt={salesTextToRender}
			className={classNames(styles['selector-value-insert-image'], 'tw-user-drag-none tw-user-select-none')}
			data-qa="insert-selector-value-image"
			draggable={false}
			src={srcAttr}
			srcSet={`${srcSetAttr} 2x`}
		/>
	);
});
