import { action, observable, makeObservable } from 'mobx';

class DeliveryTrackingStore {
	hasLoaded;

	constructor() {
		makeObservable(this, {
			hasLoaded: observable,
			trackZipCode: action.bound,
		});
	}

	trackZipCode() {
		// TODO
	}
}

export const DeliveryTrackingStoreFactory = {
	create() {
		return new DeliveryTrackingStore();
	},
};
