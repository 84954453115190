import React from 'react';

import { kebabCase } from '~/util/kebabCase';

import styles from '~/product/common/selector/Components/SelectorHeadingLabel.module.scss';

export const SelectorHeadingLabel = ({ label = '' }) => {
	return (
		<h2
			className={styles['selector-heading-label']}
			data-qa={`${kebabCase(label)}-selector-heading-label`}
		>
			{label}
		</h2>
	);
};
