import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { SelectorValueFrameImage } from '~/product/casegoods/selector/Components/frame/SelectorValueFrameImage';
import { SelectorValueFrameDraggable } from '~/product/casegoods/selector/Components/frame/SelectorValueFrameDraggable';
import { SelectorValueFrameTitle } from '~/product/casegoods/selector/Components/frame/SelectorValueFrameTitle';

import styles from '#/product/casegoods/selector/frame/selector-value-frame.module.scss';

export const SelectorValueFrame = observer((props) => {
	const {
		isDraggable = false,
		productModel = {},
		productModel: {
			articleNumber,
			salesTextToRender = '',
		} = {},
		productModels = [],
		selectedDepthSelector = {},
		title,
	} = props;

	const {
		selectorConfigModel = {},
		productGroupModel: {
			imageData = {},
		} = {},
	} = useProductContext();
	const {
		imageScale: {
			medium: imageScaleMedium = 0,
			small: imageScaleSmall = 0,
		} = {}
	} = selectorConfigModel;

	const imageOverride = Array.isArray(imageData[articleNumber]) ? imageData[articleNumber][0].imageUrl : imageData[articleNumber]?.imageUrl;

	return (
		<div className={styles['selector-value-frame']}>
			<div className={styles['selector-value-frame-image-container']}>
				{
					isDraggable
						? <SelectorValueFrameDraggable
							imageScale={imageScaleMedium}
							imageOverride={imageOverride}
							productModel={productModel}
							productModels={productModels}
							selectedDepthSelector={selectedDepthSelector}
						/>
						: <SelectorValueFrameImage
							imageScale={imageScaleSmall}
							imageOverride={imageOverride}
							productModel={productModel}
							title={title}
						/>
				}
			</div>
			<SelectorValueFrameTitle title={title || salesTextToRender} />
		</div>
	);
});
