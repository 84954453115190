import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';

import { isOnServer } from '~/global/global.constants';
import { DetailLink } from '~/product/common/details/Components/details/DetailLink';
import { SelectorBlurbs } from '~/product/common/selector/Components/SelectorBlurbs';
import { selectorComponents } from '~/product/common/selector/Components/selector-components';
import { MediaSetDetail } from '~/product/common/media-set/Components/MediaSetDetail';
import { useGlobalContext } from '~/global/Contexts/Global.context';

import styles from '#/product/standard/selector/selector.module.scss';

const DetailLinkDynamic = observer(({
	selectedSelector: {
		selectedMaterialSelector: {
			selectedSelectorValue: {
				selectorDetailLink: materialSelectorDetailLink,
			} = {},
			showSelectorDetails: materialSelectorShowSelectorDetails = false,
		} = {},
		showSelectorDetails: selectedSelectorShowSelectorDetails = false,
		selectedSelectorValue: {
			selectorDetailLink: selectedSelectorDetailLink,
		} = {},
	} = {},
}) => {
	const selectorDetailLink = materialSelectorDetailLink || selectedSelectorDetailLink;

	const showSelectorDetails = materialSelectorShowSelectorDetails || selectedSelectorShowSelectorDetails;

	if (!showSelectorDetails || !selectorDetailLink) {
		return null;
	}

	return (
		<div className="md:tw-hidden">
			<DetailLink detail={selectorDetailLink} />
		</div>
	);
});

export const SelectorTabccordionTab = (props) => {
	const {
		tab: {
			isActive,
			selectedSelector = {},
			selectedSelector: {
				blurbs = [],
				renderer = '',
			} = {},
		},
	} = props;

	const {
		featureTogglesModel
	} = useGlobalContext();

	const tabccordionTabRef = useRef();

	// magicSlider was triggering webkitAnimationEnd events as elements are modified on-the-fly
	// this was ultimately tiggering unwanted calls to magicTabccordionStore.smoothScroll()
	// so we stop these events from propagating from beyond this component
	useEffect(() => {
		function handleAnimationEnd(event) {
			event.stopPropagation();
		}

		if (tabccordionTabRef.current && !isOnServer) {
			tabccordionTabRef.current.addEventListener('webkitAnimationEnd', handleAnimationEnd);
		}

		return function cleanupAnimationEnd() {
			if (tabccordionTabRef.current) {
				tabccordionTabRef.current.removeEventListener('webkitAnimationEnd', handleAnimationEnd);
			}
		};
	}, []);

	let SelectorRenderer = selectorComponents[renderer];

	if (!SelectorRenderer) {
		SelectorRenderer = selectorComponents.DROP_DOWN;

		console.warn(`No selector renderer found for "${renderer}"`);
	}

	return (
		<div
			className={styles['selector-tabccordion-tab']}
			ref={tabccordionTabRef}
		>
			{
				isActive && (
					<DetailLinkDynamic selectedSelector={selectedSelector} />
				)
			}
			{
				isActive && !featureTogglesModel.isOn('PRODUCT_PAGE_MOBILE_LAYOUT_REDESIGN') && (
					<div className="md:tw-hidden">
						<MediaSetDetail hideMediaCylindo={true} />
					</div>
				)
			}
			{!featureTogglesModel.isOn('PRODUCT_PAGE_MOBILE_LAYOUT_REDESIGN') &&
				<SelectorBlurbs
					blurbs={blurbs}
					className="md:tw-hidden"
				/>
			}
			<SelectorRenderer
				isActive={isActive}
				selectedSelector={selectedSelector}
			/>
		</div>
	);
};
