'use client';

import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, createRef } from 'react';

import { openSignInModalHandler } from '~/account/sign-in/Handlers/openSignInModalHandler';
import { isOnServer } from '~/global/global.constants';
import { HeaderMobileMenuButton } from '~/global/header-mobile/Components/HeaderMobileMenuButton';
import { HeaderMobileOverlay } from '~/global/header-mobile/Components/HeaderMobileOverlay';
import { HeaderMobileSearch } from '~/global/header-mobile/Components/HeaderMobileSearch';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { HeaderMobileLogo } from '~/global/header-mobile/Components/HeaderMobileLogo';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import HeaderMobileAccount from '~/global/header-mobile/Components/HeaderMobileAccount';
import CartAnchor from '~/account/top-links/Components/cart/CartAnchor';
import { noop } from '~/util/noop';

import styles from '#/mobile/global/header.module.scss';

export const HeaderMobile = observer(function HeaderMobile({ sticky }: { sticky: Boolean }) {
	const {
		globalDynamicStore,
		headerMobileStore,
		headerMobileStore: {
			headerMobile,
			headerMobile: {
				headerHeight = 0,
				isLoggedIn = false,
				isAccountOpen = false,
				isScrollingUp = false,
				showHeaderOverlay,
				showHeaderShadow,
			},
			toggleIsAccountOpen,
			toggleIsMenuOpen,
			toggleIsSearchOpen,
		},
		HREF,
		magicModal,
		topMenuMobileStore: {
			isOpen,
		},
	} = useGlobalContext();

	// WIT-18500 on product pages we need to know if the sticky summary or mini sticky summary is visible
	const productContext = useProductContext ? useProductContext() : {};

	const {
		showMiniStickySummary: showProductPageMiniStickySummary = false,
		showStickySummary: showProductPageStickySummary = false,
	} = productContext?.stickySummaryModel || {};

	const headerRef = createRef<HTMLElement>();

	let ticking = false;

	const openSignInModal = (viewKey: string = 'signIn') => {
		openSignInModalHandler(viewKey, magicModal, globalDynamicStore, HREF);
	};

	const accountButtonClickHandler = () => {
		if (!isLoggedIn) {
			openSignInModal('signIn');
		} else {
			toggleIsAccountOpen();
		}
	};

	const scrollHandler = () => {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				headerMobileStore.setScrollY(window.scrollY);

				ticking = false;
			});

			ticking = true;
		}
	};

	useEffect(() => {
		if (isOnServer) {
			return noop;
		}
		window.addEventListener('scroll', scrollHandler);
		return () => {
			window.removeEventListener('scroll', scrollHandler);
		};
	}, []);

	useEffect(() => {
		if (headerRef.current) {
			headerMobileStore.setHeaderHeight(headerRef.current.clientHeight);
		}
	}, []);

	useEffect(() => {
		const htmlElement = document.documentElement;
		if (isAccountOpen) {
			htmlElement.classList.add('tw-overflow-hidden');
		} else {
			htmlElement.classList.remove('tw-overflow-hidden');
		}
	}, [isAccountOpen]);

	return (
		<div style={{ marginTop: sticky && isScrollingUp ? headerHeight : 0 }}>
			<section
				className={
					classNames(styles['header-mobile-container'], 'header-mobile-container tw-flex tw-hidden-for-print md:tw-hidden', {
						[styles['header-mobile-container-shadow']]: showHeaderShadow && (!showProductPageMiniStickySummary && !showProductPageStickySummary),
						[styles['header-mobile-container--stuck']]: sticky && isScrollingUp,
					})
				}
				id="page-header-container"
				data-qa="page-header-container"
			>
				<header
					className={styles['header-mobile']}
					id="page-header"
					data-tr-link-event-comp-type="header navigation"
					ref={headerRef}
				>
					<HeaderMobileMenuButton
						className={isOpen ? 'tw-invisible' : ''}
						clickHandler={toggleIsMenuOpen}
						dataQA="mobile-menu-open"
						headerMobile={headerMobile}
						label="Open navigation menu"
						id="mobileOpenBtn"
					/>
					<HeaderMobileSearch
						headerMobile={headerMobile}
						searchButtonClickHandler={toggleIsSearchOpen}
					/>
					<HeaderMobileLogo />
					<HeaderMobileAccount
						accountButtonClickHandler={accountButtonClickHandler}
						headerMobile={headerMobile}
						signOutClickHandler={toggleIsAccountOpen}
					/>
					<CartAnchor />
				</header>
				{
					showHeaderOverlay && <HeaderMobileOverlay clickHandler={toggleIsAccountOpen} />
				}
			</section>
		</div>
	);
});
