import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { DetailLink } from '~/product/common/details/Components/details/DetailLink';
import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { kebabCase } from '~/util/kebabCase';

import selectorStyles from '#/product/standard/selector/selector.module.scss';
import styles from '#/product/casegoods/selector/swatch-combined/selector-swatch-combined-swatch-heading.module.scss';

export const SelectorSwatchCombinedSwatchHeading = observer((props) => {
	const {
		isActive = true,
		selectedSelector: {
			label = '',
			selectedSelectorValue: {
				statusToRender = '',
				selectorDetailLink = {},
				selectorDetailLink: {
					url: selectorDetailLinkUrl = '',
				} = {},
				title = '',
			} = {},
			selectorValuesCount = 0,
			selectorValuesToRenderCount = null,
		} = {},
	} = props;

	return (
		<div
			className={styles['selector-heading']}
			data-qa={`${kebabCase(label)}-selector-heading`}
		>
			<div className="selector-heading-label-container">
				<h3
					className={styles['selector-heading-label']}
					data-qa={`${kebabCase(label)}-selector-heading-label`}
				>
					{label}
				</h3>
				<span
					className={`${selectorStyles['selector-heading-count']} md:tw-hidden`}
					data-qa={`${kebabCase(label)}-selector-heading-count`}
				>
					{selectorValuesToRenderCount === null ? selectorValuesCount : selectorValuesToRenderCount} options
				</span>
			</div>
			{
				title && (
					<span
						className={styles['selector-heading-title']}
						data-qa={`${kebabCase(label)}-selector-heading-title`}
					>
						{title}
						{
							isActive && statusToRender && (
								<span className={
									classNames(selectorStyles['selector-heading-status'], {
										[selectorStyles['selector-heading-status-new']]: statusToRender === 'New',
									})
								}>{statusToRender}</span>
							)
						}
					</span>
				)
			}
			{
				isActive && selectorDetailLinkUrl && (
					<DetailLink detail={selectorDetailLink} />
				)
			}
		</div>
	);
});
