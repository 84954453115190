import React from 'react';
import { observer } from 'mobx-react';

import { S7Image } from '~/components/Images/S7Image';
import styles from '~/product/sectionals/selector/Components/unattached-ottoman/selector-unattached-ottoman.module.scss';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { getSelectorValueByArticleNumber } from '~/product/common/selector/Utils/SelectorConfig.utils';
import { getSelectorValuePrice } from '~/product/sectionals/selector/Utils/SelectorConfigSectionals.utils';
import { ISelectorValueProduct } from '~/product/sectionals/selector/Interfaces/SelectorValueProduct.interface';

export const SelectorUnattachedOttoman = observer(() => {
	const {
		selectorConfigModel: {
			unattachedOttomanProductModel = {},
			flattenedOttomanSelectorValues = [],
		} = {},
		selectorConfigStore = {},
	} = useProductContext();

	if (!unattachedOttomanProductModel) {
		return null;
	}

	const {
		articleNumber = '',
		imageData: {
			imageUrl = '',
		},
	} = unattachedOttomanProductModel;

	const unattachedOttomanSelectorValue: ISelectorValueProduct = getSelectorValueByArticleNumber(flattenedOttomanSelectorValues, articleNumber);

	const unattachedOttomanPrice: number = getSelectorValuePrice(unattachedOttomanSelectorValue, unattachedOttomanProductModel);

	const {
		title = '',
		selected = false,
	} = unattachedOttomanSelectorValue;

	function handleChange() {
		if (selected) {
			selectorConfigStore.deselectOttomanSelectorValue(unattachedOttomanSelectorValue);
		} else {
			selectorConfigStore.selectOttomanSelectorValue(unattachedOttomanSelectorValue);
		}
	}

	return (
		<div
			className={`${styles['selector-unattached-ottoman']}`}
			data-qa="selector-unattached-ottoman"
		>
			<div className={`${styles['selector-unattached-ottoman-image']}`}>
				<S7Image src={imageUrl} width={170} height={97} alt="" />
			</div>
			<div className={`${styles['selector-unattached-ottoman-select-container']}`}>
				<label>
					<input
						checked={selected}
						data-qa="unattached-ottoman-checkbox"
						data-tr-link-event-track={false}
						onChange={handleChange}
						type="checkbox"
					/>
					<div className="tw-flex tw-flex-col tw-font-normal">
						<span data-qa="unattached-ottoman-title">{title}</span>
						{ unattachedOttomanPrice &&
							<span className="tw-text-gray-300" data-qa="unattached-ottoman-price">+{unattachedOttomanPrice}</span>
						}
					</div>
				</label>
			</div>
		</div>
	);
});
