import { observer } from 'mobx-react';
import React from 'react';

import styles from '#/product/casegoods/selector/frame/selector-value-frame-title.module.scss';

export const SelectorValueFrameTitle = observer((props) => {
	const { title = '' } = props;

	const titleParts = title.split(',');

	return (
		<div
			className={styles['selector-value-frame-title']}
			data-qa="frame-selector-value-title"
		>
			{
				titleParts.length > 1
					? titleParts.map((text, index) => {
						return (
							<span
								key={index}
							>
								{text}
							</span>
						);
					})
					: title
			}
		</div>
	);
});
