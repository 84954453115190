import { computed, observable, makeObservable } from 'mobx';

export class SelectorValueFrame {
	articleNumbers

	articles

	filters

	parentLabel

	parentType

	productModels

	selected

	selectors

	title

	value

	constructor() {
		makeObservable(this, {
			selected: observable,
			productSelectorValue: computed,
			selectedSelector: computed,
		});
	}

	get productSelectorValue() {
		let productSelectorValue = !this.selectors.length;

		const hasQuestionSelectors = this.selectors.find(({ displayType }) => displayType === 'QUESTION_ANSWER');

		const isQuestionSelector = this.parentType === 'QUESTION_ANSWER';

		if (hasQuestionSelectors) {
			productSelectorValue = true;
		} else if (isQuestionSelector) {
			productSelectorValue = false;
		}

		return productSelectorValue;
	}

	get selectedSelector() {
		return this.selectors.find(({ selected }) => selected) || this.selectors[0];
	}
}

export const SelectorValueFrameModelFactory = ({
	create: ({
		articleNumbers = [],
		articles = [],
		filters = {},
		parentLabel = '',
		parentType = '',
		productModels = [],
		selected = false,
		selectors = [],
		title = '',
		value = '',
	}) => {
		const selectorValueFrame = new SelectorValueFrame();

		Object.assign(selectorValueFrame, {
			articleNumbers,
			articles,
			filters,
			parentLabel,
			parentType,
			productModels,
			selected,
			selectors,
			title,
			value,
		});

		return selectorValueFrame;
	},
});
