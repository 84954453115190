import { observer } from 'mobx-react';
import React from 'react';

import { WorkspaceControlEnlargeCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/controls/WorkspaceControlEnlargeCasegoods';
import { WorkspaceControlHelpCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/controls/WorkspaceControlHelpCasegoods';
import { WorkspaceControlThumbnailsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/controls/WorkspaceControlThumbnailsCasegoods';

import styles from '#/product/casegoods/workspace/controls/workspace-controls.module.scss';

export const WorkspaceControlsCasegoods = observer((props = {}) => {
	const {
		showHelpControl = false,
		showEnlargeControl = false,
		showThumbnailsControl = false,
	} = props;

	return (
		<div className={`${styles['workspace-controls']} print:tw-hidden`}>
			{ showThumbnailsControl && <WorkspaceControlThumbnailsCasegoods /> }
			{ showEnlargeControl && <WorkspaceControlEnlargeCasegoods /> }
			{ showHelpControl && <WorkspaceControlHelpCasegoods /> }
		</div>
	);
});
