import { observable } from 'mobx';

import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

const curalateItemReportFormModel = (defaultData) => {
	const model = observable({
		userEmail: '',
		reportReason: '',
	});

	Object.assign(model, defaultData);

	return model;
};

const curalateItemReportFormSettings = (store) => {
	return {
		id: 'curalate-item-report',
		reactProps: {
			method: 'POST',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler: form => store.reportCuralateItem(form.model),
						promiseHandler: (promise) => {
							promise.then(() => {
								store.isSubmitted = true;
							});
							promise.catch(() => {
								store.hasError = true;
							});
						},
					},
				}),
			],
		},
		fields: {
			reportReason: {
				label: {
					reactProps: {
						children: 'Reason:',
					},
				},
				control: {
					reactProps: {
						type: 'textarea',
						maxLength: 2000,
					},
				},
				settings: {
					plugins: [{ pluginId: 'maxlengthRemainingCountdown' }],
					validationConstraints: {
						length: {
							maximum: 2000,
						},
						presence: {
							message: '^Please provide a reason.',
						},
						printableAscii: true,
					},
				},
			},
			userEmail: {
				control: {
					reactProps: {
						autoComplete: 'email',
						type: 'email',
					},
				},
				label: {
					reactProps: {
						children: 'Email:',
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Enter your email address',
						},
						email: {
							message: '^Please enter a valid email address.',
						},
					},
				},
			},
		},
	};
};

export { curalateItemReportFormModel, curalateItemReportFormSettings };
