import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';

import { isOnServer } from '~/global/global.constants';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { DetailLink } from '~/product/common/details/Components/details/DetailLink';
import { selectorCasegoodsComponents } from '~/product/casegoods/selector/Components/selector-casegoods-components';
import { WorkspaceCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/WorkspaceCasegoods';

import styles from '#/product/standard/selector/selector.module.scss';

const DetailLinkDynamic = observer(({
	selectedSelector: {
		selectedMaterialSelector: {
			selectedSelectorValue: {
				selectorDetailLink: materialSelectorDetailLink,
			} = {},
			showSelectorDetails: materialSelectorShowSelectorDetails = false,
		} = {},
		showSelectorDetails: selectedSelectorShowSelectorDetails = false,
		selectedSelectorValue: {
			selectorDetailLink: selectedSelectorDetailLink,
		} = {},
	} = {},
}) => {
	const selectorDetailLink = materialSelectorDetailLink || selectedSelectorDetailLink;

	const showSelectorDetails = materialSelectorShowSelectorDetails || selectedSelectorShowSelectorDetails;

	if (!showSelectorDetails || !selectorDetailLink) {
		return null;
	}

	return (
		<div className="md:tw-hidden">
			<DetailLink detail={selectorDetailLink} />
		</div>
	);
});

export const SelectorTabccordionTabCasegoods = (props) => {
	const {
		tab: {
			isActive = false,
			selectedSelector = {},
			selectedSelector: {
				renderer = '',
			} = {},
		},
	} = props;

	const {
		workspaceModel: {
			imageSettings = {},
		} = {}
	} = useProductContext() || {};

	const { small: imageSettingsSmall = 0 } = imageSettings;

	const tabccordionTabRef = useRef();

	// magicSlider was triggering webkitAnimationEnd events as elements are modified on-the-fly
	// this was ultimately tiggering unwanted calls to magicTabccordionStore.smoothScroll()
	// so we stop these events from propagating from beyond this component
	useEffect(() => {
		function handleAnimationEnd(event) {
			event.stopPropagation();
		}

		if (tabccordionTabRef.current && !isOnServer) {
			tabccordionTabRef.current.addEventListener('webkitAnimationEnd', handleAnimationEnd);
		}

		return function cleanupAnimationEnd() {
			if (tabccordionTabRef.current) {
				tabccordionTabRef.current.removeEventListener('webkitAnimationEnd', handleAnimationEnd);
			}
		};
	}, []);

	let SelectorRenderer = selectorCasegoodsComponents[renderer];

	if (!SelectorRenderer) {
		SelectorRenderer = selectorCasegoodsComponents.FRAME;

		console.warn(`No selector renderer found for "${renderer}"`);
	}

	return (
		<div
			className={styles['selector-tabccordion-tab']}
			ref={tabccordionTabRef}
		>
			{
				isActive && (
					<DetailLinkDynamic selectedSelector={selectedSelector} />
				)
			}
			{
				isActive &&
				<div className="md:tw-hidden" data-qa="workspace-container">
					<WorkspaceCasegoods imageSettings={imageSettingsSmall} />
				</div>
			}
			<SelectorRenderer
				isActive={isActive}
				selectedSelector={selectedSelector}
			/>
		</div>
	);
};
