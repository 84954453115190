import { createDetailsModel } from '~/product/common/details/Helpers/Details.init';
import { createSelectorFiltersModel } from '~/product/common/selector/Helpers/SelectorFilters.init';
import { createSelectorFiltersMagicTabccordionStore } from '~/product/common/selector/Helpers/SelectorFiltersTabccordion.init';
import { SelectorQuestionModelFactory } from '~/product/common/selector/Models/SelectorQuestion.model';
import { SelectorValueQuestionModelFactory } from '~/product/common/selector/Models/SelectorValueQuestion.model';
import { SelectorFiltersStoreFactory } from '~/product/common/selector/Stores/SelectorFilters.store';

export const createSelectorValueQuestionModel = ({
	detailsModel = undefined,
	parentLabel = '',
	parentType = '',
	selectorDetailsModel = undefined,
	selectorValueData = {},
	selectorValueData: {
		details: detailsData = [],
		selectorDetails: selectorDetailsData = [],
	} = {},
}) => {
	const detailsModelToUse = detailsModel || createDetailsModel(detailsData);

	const selectorDetailsModelToUse = selectorDetailsModel || createDetailsModel(selectorDetailsData);

	const selectorValueQuestionModel = SelectorValueQuestionModelFactory.create({
		...selectorValueData,
		detailsModel: detailsModelToUse,
		parentLabel,
		parentType,
		selectorDetailsModel: selectorDetailsModelToUse,
	});

	return selectorValueQuestionModel;
};

export const createSelectorQuestionModel = ({
	selectorData = {},
	selectorData: {
		dimensionSelectors: dimensionSelectorsData = [],
		filter: filtersData = {},
		label = '',
		questionGroups = [],
		renderer = '',
		type = 'QUESTION',
		values = [],
	} = {},
}) => {
	const isFabricSelector = questionGroups.some((questionGroup) => {
		return ['FABRIC', 'LEATHER'].includes(questionGroup);
	});

	const rendererToUse = isFabricSelector ? 'FABRIC' : renderer;

	const dimensionQuestionSelectorModels = dimensionSelectorsData.map((dimensionSelectorData: any = {}) => {
		return createSelectorQuestionModel({ selectorData: dimensionSelectorData });
	});

	const selectorValues = values.map((selectorValueData) => {
		return createSelectorValueQuestionModel({
			parentLabel: label,
			parentType: type,
			selectorValueData,
		});
	});

	const filtersModel = createSelectorFiltersModel({
		filtersData,
		selectorValues,
	});

	const filtersStore = SelectorFiltersStoreFactory.create({ filtersModel });

	const filtersMagicTabccordionStore = createSelectorFiltersMagicTabccordionStore(filtersStore);

	const selectorQuestionModel = SelectorQuestionModelFactory.create({
		...selectorData,
		dimensionQuestionSelectorModels,
		filtersMagicTabccordionStore,
		filtersModel,
		filtersStore,
		selectorValues,
		renderer: rendererToUse,
		type,
	});

	return selectorQuestionModel;
};
