import axios from 'axios';
import dynamic from 'next/dynamic';
import {
	action, reaction, observe, makeObservable
} from 'mobx';
import React from 'react';

import {
	apiUrl, isOnServer, recommendationsEnabled,
} from '~/global/global.constants';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { addToCartActionKeys, addProductsToCartErrorKeys } from '~/product/common/summary/summary.constants';
import { ViewState } from '~/util/viewState/Components/ViewState';
import { InterstitialStoreFactory } from '~/product/common/interstitial/Stores/Interstitial.store';
import { AddedToWishlistStoreFactory } from '~/product/common/interstitial/wishlist/Stores/AddedToWishlist.store';
import { loggedInAction } from '~/account/sign-in/Actions/loggedIn.action';
import { loggedOutAction } from '~/account/sign-in/Actions/loggedOut.action';
import { noop } from '~/util/noop';
import { ReflektionEventsTrackingStore } from '~/tracking/vendor-events/reflektion/Stores/ReflektionEvents.tracking.store';
import { getFavoritesInfoAndAddToFavoritesStore } from '~/product/common/summary/Helpers/AddToFavorites.helpers';

import styles from '~/product/common/interstitial/Components/wishlist-interstitial.module.scss';

const CartInterstitialCasegoods = dynamic(
	() => import('~/product/casegoods/interstitial/Components/CartInterstitialCasegoods').then(module => module.CartInterstitialCasegoods),
	{ loading: () => <LoadingSpinner isLoading /> }
);

const paramsSerializer = (paramsToSerialize) => {
	return Object.entries(paramsToSerialize).map(([key, value]) => {
		if (Array.isArray(value)) {
			return value.map((subValue) => {
				return `${key}=${encodeURIComponent(subValue)}`;
			}).join('&');
		}

		return `${key}=${encodeURIComponent(value)}`;
	}).join('&');
};

class SummaryCasegoodsStore {
	HREF;

	featureTogglesModel;

	magicDeliveryStore;

	observers;

	productGroupModel;

	productTrackingStore;

	rfkTrackingStore;

	summaryModel;

	workspaceModel;

	constructor() {
		makeObservable(this, {
			addToCartInit: action.bound,
			addProductToCart: action.bound,
			addProductToWishlist: action.bound,
			addToCartErrorHandler: action.bound,
			addToCartSuccessHandler: action.bound,
			openCartInterstitialModal: action.bound,
			openWishlistInterstitialModal: action.bound,
			stopAnimating: action.bound,
			getSummaryData: action.bound
		});
	}

	addToCartInit(addToCartAction) {
		this.summaryModel.isMaxQuantity = false;
		this.summaryModel.addToCartAction = addToCartAction;
		this.summaryModel.isAddToCartInProcess = true;
		this.summaryModel.isAddToCartDone = false;
		this.summaryModel.shouldButtonAnimate = true;
	}

	addProductToCart(event) {
		const {
			addToCartHref = '',
			addToCartParams = {},
		} = this.summaryModel;

		const endpoint = `${apiUrl}${addToCartHref}`;

		const restConfig = {
			...addToCartParams,
			paramsSerializer,
		};

		const onCloseFocusElement = event ? event.target : null;

		if (addToCartHref) {
			this.addToCartInit(addToCartActionKeys.CART);

			return axios
				.post(endpoint, restConfig)
				.then(({ data }) => {
					this.summaryModel.addToCartResponse = data;
					this.openCartInterstitialModal(onCloseFocusElement, data);
					this.addToCartSuccessHandler();
					this.productTrackingStore.trackAddToCart(data);
				}, ({
					response: {
						data: { errors = [] },
					}
				}) => {
					this.addToCartErrorHandler(onCloseFocusElement, errors);
				});
		}

		return Promise.reject();
	}

	addToFavorites(event) {
		const onCloseFocusElement = event ? event.target : null;

		const {
			isCustomerGuest,
		} = this.globalDynamicStore.model;

		const {
			addToFavoritesHref,
			addToFavoritesPayload,
			favoritesModalProductDto,
		} = this.summaryModel;

		const promise = getFavoritesInfoAndAddToFavoritesStore(
			addToFavoritesHref,
			addToFavoritesPayload,
			favoritesModalProductDto,
			null,
			this.addToCartErrorHandler,
			this.featureTogglesModel,
			this.globalDynamicStore,
			this.magicModal,
			onCloseFocusElement,
		);

		promise.then(() => {
			if (!isCustomerGuest) {
				this.magicModal.closeModal();
			}
		});

		return promise;
	}

	addProductToWishlist(event) {
		const onCloseFocusElement = event ? event.target : null;

		return this.addToFavorites(event)
			.then(() => {
				this.addToCartSuccessHandler();
			}, ({
				response: {
					data: { errors = [] },
				} = {}
			}) => {
				this.addToCartErrorHandler(onCloseFocusElement, errors);
			});
	}

	addToCartErrorHandler(onCloseFocusElement, errors) {
		this.summaryModel.isAddToCartDone = true;
		this.summaryModel.isAddToCartInProcess = false;
		this.stopAnimating();

		errors.forEach((err) => { console.error(err); });

		if (errors.find(({ errorKey }) => errorKey === addProductsToCartErrorKeys.maxQuantity)) {
			this.summaryModel.isMaxQuantity = true;
		}
	}

	addToCartSuccessHandler() {
		this.summaryModel.isAddToCartDone = true;
		this.summaryModel.isAddToCartInProcess = false;
		setTimeout(this.stopAnimating, 3000);
		this.globalDynamicStore.fetchData();
	}

	openCartInterstitialModal(onCloseFocusElement, data) {
		const loadRfkRecos = recommendationsEnabled;
		const store = InterstitialStoreFactory.create(
			data,
			{
				magicModal: this.magicModal,
				featureTogglesModel: this.featureTogglesModel,
				globalDynamicModel: this.globalDynamicStore.model,
				globalStaticModel: this.globalStaticModel,
				pageStore: this.pageStore,
			},
			{
				loadRfkRecos,
			},
		);

		this.magicModal.openModal({
			title: 'Nice choice! Your cart has been updated.',
			content: {
				children: (
					<CartInterstitialCasegoods store={store} />
				),
			},
			maxWidth: '725px',
		});
	}

	openWishlistInterstitialModal(onCloseFocusElement, data) {
		const store = AddedToWishlistStoreFactory.create(data, this.magicModal);

		this.magicModal.openModal({
			id: 'added-to-wishlist-modal',
			title: 'Added to Wish List!',
			maxWidth: '784px',
			onCloseFocusElement,
			content: {
				children: <ViewState viewState={store.viewState} classNames={styles['view-state-container-CUSTOM_SECTIONAL_BUILDER']} />,
			},
		});
	}

	stopAnimating() {
		this.summaryModel.shouldButtonAnimate = false;
		this.summaryModel.isAddToCartDone = false;
	}

	getDetailsData() {
		const {
			summaryParams = {},
			productGroupModel: {
				canonicalUrl,
			},
		} = this.summaryModel;

		const {
			hasWorkspaceProducts = false,
			sortedWorkspaceProducts,
		} = this.workspaceModel;

		const detailsHref = this.summaryModel?.productGroupModel?._links?.STACK_DETAIL?.href;

		if (!hasWorkspaceProducts || !detailsHref) return Promise.reject().catch(noop);

		if (!isOnServer) {
			const endpoint = `${apiUrl}${detailsHref}`;
			const restConfig = {
				...summaryParams,
				friendlyPath: canonicalUrl,
			};
			axios.post(endpoint, restConfig).then(({ data = {} }) => {
				sortedWorkspaceProducts?.forEach((prod) => {
					prod.slotModelsWithInserts.forEach((slot) => {
						data?.insertDetails?.forEach(stack => stack?.inserts?.forEach((insert) => {
							if (insert?.answer === slot?.insertProductModel?.answerKey) slot.detailsAttributesValues = insert.detail.details;
						}));
					});
				});
			});
		}

		return Promise.reject().catch(noop);
	}

	getSummaryData() {
		const {
			isValidQuantity = false,
			summaryHref = '',
			summaryParams = {},
			productGroupModel: {
				canonicalUrl,
			},
		} = this.summaryModel;

		const { lastInteractionWasRearrange = false } = this.workspaceModel;

		if (!isValidQuantity) {
			return Promise.reject()
				.catch(noop);
		}

		if (!isOnServer) {
			const endpoint = `${apiUrl}${summaryHref}`;

			const restConfig = {
				...summaryParams,
				friendlyPath: canonicalUrl,
			};

			this.summaryModel.isLoading = true;

			const promise = axios.post(endpoint, restConfig);

			return promise
				.then(({ data = {} }) => {
					this.summaryModel.isLoading = false;
					const {
						analytics = [],
						availability = {},
						deliveryMessages = [],
						deliveryRateMessages = [],
						prices = [],
						pricing = 0,
						vendor: {
							reflektion,
							variantIds = [],
						},
					} = data;

					const reflektionSkuKeys = reflektion.map(({ skuKey }) => {
						return skuKey;
					});

					Object.assign(this.summaryModel, {
						analytics,
						availability,
						deliveryMessages,
						deliveryRateMessages,
						hasError: false,
						isLoading: false,
						prices,
						pricing,
						reflektionSkuKeys,
						variantIds,
					});

					// don't track productviews on stack re-arrange interactions
					if (!lastInteractionWasRearrange) {
						this.productTrackingStore.trackProductView('pass', {
							trackAllWorkspaceProducts: true,
							// When a workspace is not a frameFirstBuilder (starts with inserts) we...
							// A) do not want to checkForBackToBackDuplicates, as we want each insert addition to be tracked (since frames are irrelevant)
							...!this.workspaceModel.settings.frameFirstBuilder && {
								checkForBackToBackDuplicates: false,
								// B) we want to ignoreReflektion when there is more than one insert, as reflektion doesn't need each insert (but does need the first to know what stack a user is building)
								...this.workspaceModel.lastSelectedWorkspaceProductModel.slotModelsWithInserts.length > 1 && {
									ignoreReflektion: true,
								}
							}
						});
					}
				})
				.catch(() => {
					this.summaryModel.hasError = false;
					this.summaryModel.isLoading = false;

					this.productTrackingStore.trackProductView('fail');
				});
		}

		return Promise.reject().catch(noop);
	}
}

export const SummaryCasegoodsStoreFactory = {
	create({
		featureTogglesModel = {},
		globalDynamicStore = {},
		globalStaticModel,
		HREF,
		isPreconfiguredWorkspace = false,
		magicDeliveryStore = {},
		magicModal = {},
		observers = [],
		pageStore = {},
		productTrackingStore,
		summaryModel = {},
		summaryStores = [],
		trackingDebugMode = {},
		workspaceModel,
	}) {
		const summaryStore = new SummaryCasegoodsStore();

		const rfkTrackingStore = new ReflektionEventsTrackingStore(featureTogglesModel);

		Object.assign(summaryStore, {
			featureTogglesModel,
			globalDynamicStore,
			globalStaticModel,
			HREF,
			magicDeliveryStore,
			magicModal,
			observers,
			pageStore,
			productTrackingStore,
			rfkTrackingStore,
			summaryModel,
			summaryStores,
			trackingDebugMode,
			workspaceModel,
		});

		if (!isOnServer) {
			if (isPreconfiguredWorkspace) {
				// without the setTimeout, this was causing major problems in the DOM being malformed
				// could not figure out the root cause, but possibly multiple observables were kicking of re-renders at the same time and colliding
				setTimeout(() => {
					summaryStore.getSummaryData();
					summaryStore.getDetailsData();
				}, 0);
			}

			summaryStore.observers.push(
				reaction(() => summaryModel.summaryParams, () => {
					summaryStore.getSummaryData();
					summaryStore.getDetailsData();
				}, { delay: 150 }),

				// Refresh summary if an Engage user creates an online account
				// (effectively changing who they're working with).
				observe(globalDynamicStore.model, (change) => {
					if (change.name !== 'workingWith') {
						return;
					}

					if (!change.oldValue.name && change.newValue.name) {
						return;
					}

					if (!change.oldValue.name && !change.newValue.name) {
						return;
					}

					summaryStore.getSummaryData();
				}),
			);

			// Refresh summary upon logging in or out.
			loggedInAction.add(() => {
				summaryStore.getSummaryData();
			});

			loggedOutAction.add(() => {
				summaryStore.getSummaryData();
			});
		}

		return summaryStore;
	},
	destroy(store) {
		store.observers?.forEach?.(dispose => dispose());
	},
};
