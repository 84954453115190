import React from 'react';

import { SelectorValueRadioFrameModal } from '~/product/casegoods/selector/Components/frame-modal/SelectorValueRadioFrameModal';

import styles from '~/product/casegoods/selector/Components/frame-modal/SelectorRadioFrameModal.module.scss';

export const SelectorRadioFrameModal = ({
	selectedSelector: {
		label = '',
		selectorValues = [],
	} = {},
	selectorConfigFrameModalStore = {},
	workspaceProductStoreFrameModal = {},
}) => {
	return (
		<div className={styles['selector-radio-frame-modal']}>
			<span className={styles['selector-radio-frame-modal-label']}>{label}:</span>
			<fieldset>
				<legend className="tw-sr-only">Choose a {label}</legend>
				{
					selectorValues.map((selectorValue, index = 0) => {
						return (
							<SelectorValueRadioFrameModal
								key={`selector-value-${index}`}
								label={label}
								selectorValue={selectorValue}
								selectorConfigFrameModalStore={selectorConfigFrameModalStore}
								workspaceProductStoreFrameModal={workspaceProductStoreFrameModal}
							/>
						);
					})
				}
			</fieldset>
		</div>
	);
};
