import React from 'react';

import styles from '#/product/casegoods/workspace/product-insert/workspace-product-insert-options-saved.module.scss';

export const WorkspaceProductInsertOptionsSavedCasegoods = () => {
	return (
		<div
			className={styles['workspace-product-insert-options-saved']}
			data-qa="workspace-product-insert-options-saved"
		>
			<div className={styles['workspace-product-insert-options-saved-check']} />
			<span className="tw-sr-only">
				Insert Options Saved
			</span>
		</div>
	);
};
