/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */

import React from 'react';
import { observer } from 'mobx-react';

import cn from 'classnames';

import styles from './RecoItemRfk.module.scss';

import { isEngage } from '~/global/global.constants';
import { MagicHeaderTag } from '~/components/magic-header';

export const RecoItemRfk = observer(({
	item, onClick, featureTogglesModel, bemVariant = '', totalItems = 0, headerLevel = 4,
}) => {
	const name = featureTogglesModel.isOn('SPOOF_RECOS') ? `## FPO ## ${item.name}` : item.name;
	const {
		index, imageWidth, imageHeight, imageSrc, imageSrcSet, formattedFinalPrice, formattedPrice, showRating, reviewRating, ratingCount,
		showClearancePrice, ratingImageSrc, priceRanges, url,
	} = item;

	return (
		<div aria-label={`Product ${index + 1} of ${totalItems}`} role="group">
			<a
				className={
					cn('RecoItem tw-outline-none', {
						[bemVariant]: Boolean(bemVariant),
						[styles['RecoItem']]: true,
						[styles[bemVariant]]: Boolean(bemVariant),
					})
				}
				data-tr-link-event-name={name}
				data-tr-link-event-comp-position={`${index + 1}:${totalItems}`}
				data-tr-link-event-track={!isEngage}
				href={url}
				onClick={(event) => {
					event.preventDefault();
					onClick(event, item);
				}}
				title={name}
			>
				<img
					className={styles['RecoItem-prodImage']}
					src={imageSrc}
					srcSet={imageSrcSet}
					alt=""
					width={imageWidth}
					height={imageHeight}
				/>
				<MagicHeaderTag headerLevel={headerLevel || 4} className={styles['RecoItem-title']}>{name}</MagicHeaderTag>
				{
					showClearancePrice &&
					<div className={`${styles['RecoItem-price']}`}>
						<span className={`${styles['RecoItem-salePrice']} tw-font-bold tw-text-red`}>{formattedFinalPrice}</span>
						<span className={styles['RecoItem-originalPrice']}>Was {formattedPrice}</span>
					</div>
				}
				{
					!showClearancePrice &&
					<div className={styles['RecoItem-price']}>{priceRanges}</div>
				}
				{
					showRating &&
					<div className={`${styles['RecoItem-review']} ${reviewRating}`} title={name}>
						<img
							src={ratingImageSrc}
							className={`star-rating-image ${styles['RecoItem-reviewImage']}`}
							width="85"
							height="14"
							alt={`${reviewRating} star rating`}
						/>
						{` (${ratingCount}`}<span className="tw-sr-only">reviews</span>)
					</div>
				}
			</a>
		</div>
	);
});
