import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import styles from '#/product/standard/details/details-attributes.module.scss';
import stylesDetail from '#/product/standard/details/details.module.scss';

export const DetailCustomBaseArticleNumber = observer((props) => {
	const {
		detail: {
			displayText = '',
			label = '',
		} = {},
	} = props;

	const {
		summaryModel: {
			customBaseArticleNumber,
		} = {},
	} = useProductContext();

	return (
		<div className={`${styles['detail']} ${stylesDetail['detail']} detail-custom-base-article-number`}>
			<strong>{label}:</strong>
			<span data-qa="details-base-number" suppressHydrationWarning> {customBaseArticleNumber || displayText} </span>
		</div>
	);
});
