import { extendObservable } from 'mobx';
import React from 'react';

import { MagicTabccordionStore } from '~/components/magic-tabccordion/Stores/magicTabccordion.store';
import { SelectorSwatchCombinedTabccordionTab } from '~/product/casegoods/selector/Components/swatch-combined/SelectorSwatchCombinedTabccordionTab';
import { SelectorSwatchCombinedTabccordionTabHeading } from '~/product/casegoods/selector/Components/swatch-combined/SelectorSwatchCombinedTabccordionTabHeading';

export const createSelectorMaterialCombinedMagicTabccordionStore = ({
	disableSmoothScroll = false,
	isDraggable = false,
	linkEventStore = {},
	renderMode = 'TABS',
	materialSelectorModels = [],
}) => {
	const tabGroups = [
		{
			tabs: materialSelectorModels.map((materialSelectorModel = {}, index = 0) => {
				return extendObservable({
					isDraggable,
					selectorModel: materialSelectorModel,
					tabTitleComponent: ({
						tab: {
							isActive,
						},
					}) => {
						return (
							<SelectorSwatchCombinedTabccordionTabHeading
								selectorModel={materialSelectorModel}
								isActive={isActive}
							/>
						);
					},
				}, {
					isActive: index === 0,
				});
			}),
		},
	];

	const selectorFrameTabccordionData = {
		disableSmoothScroll,
		id: 'selector-material-combined-tabccordion',
		renderMode,
		tabGroups,
		wrapperRenderer: SelectorSwatchCombinedTabccordionTab,
	};

	const selectorMaterialCombinedMagicTabccordionStore = new MagicTabccordionStore(selectorFrameTabccordionData, linkEventStore);

	return selectorMaterialCombinedMagicTabccordionStore;
};
