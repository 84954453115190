import {
	observable, computed, makeObservable,
} from 'mobx';

import {
	reviewsApiKey, reviewsMerchantGroupId, reviewsMerchantId, sitePath,
} from '~/global/global.constants';

class PowerReviewsStore {
	selectorConfigModel;

	productGroupModel;

	featureTogglesModel;

	LOCALE = 'en_US';

	REVIEW_DISPLAY_ID = 'pr-reviews-display';

	QA_DISPLAY_ID = 'pr-questions-and-answers-display';

	constructor() {
		makeObservable(this, {
			selectorConfigModel: observable,
			productGroupModel: observable,
			reviewsConfig: computed,
		});
	}

	// components: {
	// 	ReviewImageDisplay: null,
	// 	ReviewSnippet: null,
	// 	ReviewImageSnippet: null,
	// 	ReviewDisplay: null,
	// 	Write: null,
	// 	SellerRatings: null,
	// 	QuestionSnippet: null,
	// 	QuestionDisplay: null
	// }

	// @action.bound
	// addApiSetting(key, settings) {
	// 	// this.apiCallSettings.set(key, PR.api(settings, prObs));
	// }

	get reviewsConfig() {
		const pageId = this.productGroupModel.reviewProductGroupKey;

		const {
			selectedProduct: {
				articleNumber = '',
			} = {},
		} = this.selectorConfigModel;

		return {
			api_key: reviewsApiKey,
			locale: this.LOCALE,
			merchant_group_id: reviewsMerchantGroupId,
			merchant_id: reviewsMerchantId,
			page_id: pageId,
			structured_data_product_id: this.productGroupModel.reviewProductGroupKey,
			REVIEW_DISPLAY_SNAPSHOT_TYPE: 'SIMPLE',
			ENABLE_REVIEW_IMAGE_DISPLAY: false,
			enable_content_collection_modal: false,
			style_sheet: `${sitePath}/css/power-reviews.css`,
			on_write_review_click: () => {
				window.location = `${sitePath}/reviews/write-a-review?pr_page_id=${pageId}&articleNumber=${articleNumber}`;
				return false;
			},
			review_wrapper_url: `${sitePath}/reviews/write-a-review?pr_page_id=${pageId}&articleNumber=${articleNumber}`,
			components: {
				ReviewDisplay: this.REVIEW_DISPLAY_ID,
				QuestionDisplay: this.QA_DISPLAY_ID,
			},
		};
	}
}

const PowerReviewsStoreFactory = {
	create(selectorConfigModel, productGroupModel, featureTogglesModel) {
		const instance = new PowerReviewsStore();
		instance.apiCallSettings = new Map();
		instance.selectorConfigModel = selectorConfigModel;
		instance.productGroupModel = productGroupModel;
		instance.featureTogglesModel = featureTogglesModel;
		return instance;
	},
};

export {
	PowerReviewsStoreFactory,
};
