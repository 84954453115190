import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { s7ContentPath } from '~/global/global.constants';
import { addToUrl } from '~/util/addToUrl';
import shopRoomLinkStyles from '~/components/Buttons/Components/ShopRoomLink.module.scss';

import styles from '#/media-set/media-link-shop-this-room.module.scss';

export const MediaLinkShopThisRoom = observer((props = {}) => {
	const {
		mediaModel: {
			selected = false,
		} = {},
		link: {
			href = '',
		} = {},
	} = props;

	const linkRef = useRef();

	// don't allow .slick-cloned elements to be focusable
	useEffect(() => {
		if (linkRef.current && selected) {
			const isCloned = linkRef.current.closest('.slick-cloned');

			if (isCloned) {
				linkRef.current.setAttribute('tabindex', -1);
			}
		}
	});

	return (
		<a
			className={classNames({
				[styles['media-link-shop-this-room']]: true,
				[shopRoomLinkStyles.root]: true
			})}
			data-qa="media-link-shop-this-room"
			data-tr-link-event-name="shop"
			href={addToUrl(href, 'hideRoomNavLinks=true')}
			ref={linkRef}
			tabIndex={selected ? 0 : -1}
		>
			<img
				alt=""
				className={styles['media-link-shop-this-room-image']}
				src={`${s7ContentPath}/shopping-tag`}
			/>
			<span className={styles['media-link-shop-this-room-text']}>
				Shop<span className="tw-sr-only"> products in this image</span>
			</span>
		</a>
	);
});
