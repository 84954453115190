import React from 'react';

import styles from '#/product/casegoods/workspace/modal-frame/workspace-modal-frame-width.module.scss';

export const WorkspaceModalFrameWidthCasegoods = ({ width = 0 }) => {
	return (
		<div className={styles['workspace-modal-frame-width']}>
			<span className={styles['workspace-modal-frame-width-value']}>
				{width}"
			</span>
			<div className={styles['workspace-modal-frame-width-line-container']}>
				<div className={styles['workspace-modal-frame-width-line']} />
			</div>
		</div>
	);
};
