import { observer } from 'mobx-react';
import React from 'react';

import styles from '#/product/casegoods/workspace/product/workspace-product-depth.module.scss';

export const WorkspaceProductDepthCasegoods = observer((props) => {
	const {
		workspaceProduct: {
			workspaceProductDepth = 0,
		} = {},
	} = props;

	return (
		<div className={styles['workspace-product-depth']} data-qa="workpsace-product-depth-abbreviated">
			{workspaceProductDepth}" d
		</div>
	);
});
