import React from 'react';
import { action, makeObservable } from 'mobx';
import axios from 'axios';
import dynamic from 'next/dynamic';

import {
	apiUrl, isOnServer, recommendationsEnabled,
} from '~/global/global.constants';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import {
	addToCartActionKeys,
	addProductsToCartErrorKeys,
} from '~/product/common/summary/summary.constants';
import { noop } from '~/util/noop';
import { InterstitialStoreFactory } from '~/product/common/interstitial/Stores/Interstitial.store';
import { ReflektionEventsTrackingStore } from '~/tracking/vendor-events/reflektion/Stores/ReflektionEvents.tracking.store';


const CartInterstitial = dynamic(() => import('~/product/common/interstitial/Components/CartInterstitial').then(module => module.CartInterstitial),
	{ loading: () => <LoadingSpinner isLoading /> });

const paramsSerializer = (paramsToSerialize) => {
	return Object.entries(paramsToSerialize).map(([key, value]) => {
		if (Array.isArray(value)) {
			return value.map((subValue) => {
				return `${key}=${encodeURIComponent(subValue)}`;
			}).join('&');
		}

		return `${key}=${encodeURIComponent(value)}`;
	}).join('&');
};

class SummaryStore {
	observers = [];

	globalStaticModel

	productTrackingStore

	rfkTrackingStore

	constructor() {
		makeObservable(this, {
			setQuantity: action.bound,
			addToCartInit: action.bound,
			addProductToCart: action.bound,
			addToCartErrorHandler: action.bound,
			addToCartSuccessHandler: action.bound,
			openCartInterstitialModal: action.bound,
			getSummaryData: action.bound,
			stopAnimating: action.bound
		});
	}

	setQuantity(quantity) {
		this.summaryModel.quantity = quantity;
		this.getSummaryData(false);
	}

	addToCartInit(addToCartAction) {
		this.summaryModel.isMaxQuantity = false;
		this.summaryModel.addToCartAction = addToCartAction;
		this.summaryModel.isAddToCartInProcess = true;
		this.summaryModel.isAddToCartDone = false;
		this.summaryModel.shouldButtonAnimate = true;
	}

	addProductToCart(event) {
		const {
			addToCartHref,
		} = this.productModel;
		const onCloseFocusElement = event ? event.target : null;

		if (addToCartHref) {
			this.addToCartInit(addToCartActionKeys.CART);

			return axios
				.post(`${apiUrl}${addToCartHref}`, this.summaryModel.addProductsToCartRequestDto)
				.then(({ data }) => {
					this.summaryModel.addToCartResponse = data;
					this.openCartInterstitialModal(onCloseFocusElement, data);
					this.addToCartSuccessHandler();
					this.productTrackingStore.trackAddToCart(data);
				}, ({
					response: {
						data: { errors = [] },
					}
				}) => {
					this.addToCartErrorHandler(onCloseFocusElement, errors);
				});
		}

		return Promise.reject();
	}

	addToCartErrorHandler(onCloseFocusElement, errors) {
		this.summaryModel.isAddToCartDone = true;
		this.summaryModel.isAddToCartInProcess = false;
		this.stopAnimating();

		errors.forEach((err) => { console.error(err); });

		if (errors.find(({ errorKey }) => errorKey === addProductsToCartErrorKeys.maxQuantity)) {
			this.summaryModel.isMaxQuantity = true;
		}
	}

	addToCartSuccessHandler() {
		this.summaryModel.isAddToCartDone = true;
		this.summaryModel.isAddToCartInProcess = false;
		setTimeout(this.stopAnimating, 3000);
		this.globalDynamicStore.fetchData();
	}

	openCartInterstitialModal(onCloseFocusElement, data) {
		const loadRfkRecos = recommendationsEnabled;
		const store = InterstitialStoreFactory.create(
			data,
			{
				magicModal: this.magicModal,
				featureTogglesModel: this.featureTogglesModel,
				globalDynamicModel: this.globalDynamicStore.model,
				globalStaticModel: this.globalStaticModel,
				pageStore: this.pageStore,
			},
			{
				loadRfkRecos,
			},
		);

		this.magicModal.openModal({
			title: 'Nice choice! Your cart has been updated.',
			content: {
				children: (
					<CartInterstitial store={store} />
				),
			},
			maxWidth: '725px',
		});
	}

	getSummaryData(callTracking = true) {
		const {
			summaryParams: params,
			productModel: {
				summaryHref,
			} = {},
			apiConfiguration: {
				method = '',
				payload = {},
				uri = '',
			},
		} = this.summaryModel;

		if (!isOnServer && uri) {
			this.summaryModel.isLoading = true;

			const promise = axios[method](uri, payload);

			return promise
				.then(({
					data: {
						analytics,
						availability: {
							inStock,
							inStockDate,
						} = {},
						deliveryMessages = [],
						deliveryRateMessages = [],
						vendor: {
							reflektion = [],
						} = {},
					},
				}) => {
					Object.assign(this.summaryModel, {
						analytics,
						availability: {
							inStock,
							inStockDate,
						},
						deliveryMessages,
						deliveryRateMessages,
						hasError: false,
						isLoading: false,
						reflektionSkuKey: reflektion[0].skuKey,
					});

					if (callTracking) {
						this.productTrackingStore.trackProductView('pass');
					}
				})
				.catch(() => {
					Object.assign(this.summaryModel, {
						availability: null,
						hasError: true,
						isLoading: false,
						reflektionSkuKey: null,
					});

					if (callTracking) {
						this.productTrackingStore.trackProductView('fail');
					}
				});
		}
		if (!isOnServer && summaryHref) {
			this.summaryModel.isLoading = true;

			const promise = axios.get(`${apiUrl}${summaryHref}`, {
				params,
				paramsSerializer,
			});

			return promise
				.then(({
					data: {
						inStock,
						inStockDate,
						analytics,
						analytics: {
							reflektionSkuKey,
						} = {},
					},
				}) => {
					Object.assign(this.summaryModel, {
						analytics,
						availability: {
							inStock,
							inStockDate,
						},
						isLoading: false,
						reflektionSkuKey,
					});
					if (callTracking) {
						this.productTrackingStore.trackProductView('pass');
					}
				}, () => {
					Object.assign(this.summaryModel, {
						analytics: [],
						availability: null,
						isLoading: false,
						reflektionSkuKey: null,
					});
					if (callTracking) {
						this.productTrackingStore.trackProductView('fail');
					}
				});
		}
		return Promise.reject()
			.catch(noop);
	}

	stopAnimating() {
		this.summaryModel.shouldButtonAnimate = false;
		this.summaryModel.isAddToCartDone = false;
	}
}

export const SummaryStoreFactory = {
	create({
		summaryModel,
		productModel,
		magicModal,
		globalDynamicStore,
		globalStaticModel,
		featureTogglesModel,
		pageStore,
		productTrackingStore,
		magicDeliveryStore,
		trackingDebugMode = {},
	}) {
		const summaryStore = new SummaryStore();

		const rfkTrackingStore = new ReflektionEventsTrackingStore(featureTogglesModel);

		Object.assign(summaryStore, {
			summaryModel,
			productModel,
			magicModal,
			globalDynamicStore,
			globalStaticModel,
			featureTogglesModel,
			pageStore,
			productTrackingStore,
			magicDeliveryStore,
			rfkTrackingStore,
			trackingDebugMode,
		});

		summaryStore.isLoading = true;
		summaryStore.getSummaryData();

		return summaryStore;
	},
	destroy(store) {
		store.observers?.forEach?.(dispose => dispose());
	},
};
