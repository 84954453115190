import { computed, makeObservable } from 'mobx';

import type { IProductSectionals } from '~/product/sectionals/Interfaces/ProductSectionals.interface';
import type { ISelectorConfigSectionals } from '~/product/sectionals/selector/Interfaces/SelectorConfigSectionals.interface';
import type { ISelectorProduct } from '~/product/sectionals/selector/Interfaces/SelectorProduct.interface';
import type { ISelectorQuestion } from '~/product/sectionals/selector/Interfaces/SelectorQuestion.interface';
import type { ISelectorValueProduct } from '~/product/sectionals/selector/Interfaces/SelectorValueProduct.interface';
import type { ISelectorValueQuestion } from '~/product/sectionals/selector/Interfaces/SelectorValueQuestion.interface';
import type { ISelectorDownload } from '~/product/sectionals/selector/Interfaces/SelectorDownload.interface';

import { getFlattenedSelectors, getFlattenedSelectorValues, getSelectorValueByArticleNumber } from '~/product/common/selector/Utils/SelectorConfig.utils';
import { ISelectorOttomanProductGroup } from '~/product/sectionals/selector/Interfaces/SelectorOttomanProductGroup.interface';
import { sortBy } from '~/util/sortBy';

export class SelectorConfigSectionalsModel implements ISelectorConfigSectionals {
	defaultConfigModels: Array<any>;

	defaultOttomanProductModel: any;

	defaultProductModel: any;

	downloadSelectorModel: ISelectorDownload;

	featureTogglesModel: any;

	ottomanProductGroup: ISelectorOttomanProductGroup;

	ottomanProductModels: Array<IProductSectionals>;

	ottomanSelectorModel: ISelectorProduct;

	productModels: Array<IProductSectionals>;

	productSelectorModel: ISelectorProduct;

	questionSelectorModels: Array<ISelectorQuestion>;

	siblingDefaultConfigModels: Array<any>;

	constructor({
		defaultConfigModels = [],
		defaultOttomanProductModel = {},
		defaultProductModel = {},
		downloadSelectorModel = {},
		featureTogglesModel = {},
		ottomanProductGroup = {},
		ottomanProductModels = [],
		ottomanSelectorModel = {},
		productModels = [],
		productSelectorModel = {},
		questionSelectorModels = [],
		siblingDefaultConfigModels = [],
	}: any) {
		makeObservable(this, {
			flattenedOttomanSelectors: computed,
			flattenedOttomanSelectorsAndSelectorValues: computed,
			flattenedOttomanSelectorValues: computed,
			flattenedProductSelectors: computed,
			flattenedProductSelectorsAndSelectorValues: computed,
			flattenedProductSelectorValues: computed,
			flattenedQuestionSelectorValues: computed,
			includeUnattachedOttomanSelector: computed,
			hasSelectorsToRender: computed,
			loadedProductModels: computed,
			selectedProduct: computed,
			selectedProductDepth: computed,
			selectedProductSelectors: computed,
			selectedProductSelectorValue: computed,
			selectedQuestionSelectorValues: computed,
			selectedSelectorsToRender: computed,
			sortedSelectedSelectorsToRender: computed,
		});

		this.defaultConfigModels = defaultConfigModels;
		this.defaultOttomanProductModel = defaultOttomanProductModel;
		this.defaultProductModel = defaultProductModel;
		this.downloadSelectorModel = downloadSelectorModel;
		this.featureTogglesModel = featureTogglesModel;
		this.ottomanProductGroup = ottomanProductGroup;
		this.ottomanProductModels = ottomanProductModels;
		this.ottomanSelectorModel = ottomanSelectorModel;
		this.productModels = productModels;
		this.productSelectorModel = productSelectorModel;
		this.questionSelectorModels = questionSelectorModels;
		this.siblingDefaultConfigModels = siblingDefaultConfigModels;
	}

	get flattenedOttomanSelectors(): Array<ISelectorProduct> {
		return getFlattenedSelectors([this.ottomanSelectorModel]);
	}

	get flattenedOttomanSelectorsAndSelectorValues(): Array<ISelectorProduct | ISelectorValueProduct> {
		return [...this.flattenedOttomanSelectors, ...this.flattenedOttomanSelectorValues];
	}

	get flattenedOttomanSelectorValues(): Array<ISelectorValueProduct> {
		const { selectorValues = [] } = this.ottomanSelectorModel;

		return getFlattenedSelectorValues(selectorValues);
	}

	get flattenedProductSelectors(): Array<ISelectorProduct> {
		return getFlattenedSelectors([this.productSelectorModel]);
	}

	get flattenedProductSelectorsAndSelectorValues(): Array<ISelectorProduct | ISelectorValueProduct> {
		return [...this.flattenedProductSelectors, ...this.flattenedProductSelectorValues];
	}

	get flattenedProductSelectorValues(): Array<ISelectorValueProduct> {
		const { selectorValues = [] } = this.productSelectorModel;

		return getFlattenedSelectorValues(selectorValues);
	}

	get flattenedQuestionSelectorValues(): Array<ISelectorValueQuestion> {
		return this.questionSelectorModels.reduce((accumulatedQuestionSelectorValues: Array<ISelectorValueQuestion>, { selectorValues = [] }) => {
			return [...accumulatedQuestionSelectorValues, ...selectorValues];
		}, []);
	}

	get hasSelectorsToRender(): boolean {
		return Boolean(this.sortedSelectedSelectorsToRender.length);
	}

	get includeUnattachedOttomanSelector(): boolean {
		return Boolean(Object.keys(this.ottomanSelectorModel).length);
	}

	get loadedProductModels(): Array<IProductSectionals> {
		const loadedProductModels = this.productModels.filter(({ isLoaded = false }) => isLoaded);
		const loadedOttomanProductModels = this.ottomanProductModels.filter(({ isLoaded = false }) => isLoaded);
		return [...loadedProductModels, ...loadedOttomanProductModels];
	}

	get materialQuestionSelector(): ISelectorQuestion | undefined {
		return this.questionSelectorModels.find(({ questionGroups = [] }) => {
			return questionGroups.some((questionGroup = '') => {
				return ['FABRIC', 'LEATHER'].includes(questionGroup);
			});
		});
	}

	get productSelectorValues(): Array<ISelectorValueProduct> {
		return this.flattenedProductSelectorValues.filter(({ selectors = [] }) => !selectors.length);
	}

	get ottomanSelectorValues(): Array<ISelectorValueProduct> {
		return this.flattenedOttomanSelectorValues.filter(({ selectors = [] }) => !selectors.length);
	}

	get selectedProduct(): IProductSectionals {
		const { preselectedProductModel }: ISelectorValueProduct = this.selectedProductSelectorValue || {};

		const [firstProductModel] = this.productModels;

		return preselectedProductModel || firstProductModel;
	}

	get preselectedUnattachedOttoman(): IProductSectionals {
		const { preselectedProductModel }: ISelectorValueProduct = this.unattachedOttomanSelectorValue || {};

		const [firstProductModel] = this.ottomanProductModels;

		return preselectedProductModel || firstProductModel;
	}

	get selectedProductDepth(): number | undefined {
		return this.selectedProduct.frameDepth;
	}

	get selectedProductSelectors(): Array<ISelectorProduct> {
		return this.flattenedProductSelectors.filter(({ selected = false }) => selected);
	}

	get selectedProductSelectorValue(): ISelectorValueProduct {
		const [firstProductSelectorValue] = this.productSelectorValues;

		return this.productSelectorValues.find(({ selected = false }) => selected) || firstProductSelectorValue;
	}

	get selectedUnattachedOttomanSelectorValue(): IProductSectionals | null {
		if (!this.unattachedOttomanProductModel) {
			return null;
		}

		const unattachedOttomanSelectorValue = getSelectorValueByArticleNumber(this.flattenedOttomanSelectorValues, this.unattachedOttomanProductModel.articleNumber);

		return unattachedOttomanSelectorValue.selected ? this.unattachedOttomanProductModel : null;
	}

	get selectedSelectorsToRender(): Array<any> {
		const selectorsToRender = [
			...this.selectedProductSelectors,
			...this.questionSelectorModels,
			this.includeUnattachedOttomanSelector ? this.ottomanSelectorModel : null,
			this.downloadSelectorModel,
		];

		return selectorsToRender.filter(Boolean);
	}

	get selectedQuestionSelectorValues(): Array<ISelectorValueQuestion> {
		return this.flattenedQuestionSelectorValues.filter(({ selected = false }) => selected);
	}

	get sortedSelectedSelectorsToRender(): Array<any> {
		return sortBy(this.selectedSelectorsToRender, 'displayOrder');
	}

	get unattachedOttomanProductModel(): IProductSectionals | null {
		if (!this.selectedProductDepth || !this.ottomanProductModels.length) {
			return null;
		}

		let ottomanProductModel;

		const questionSelectorModelToFilterBy = this.questionSelectorModels.find(({ questionGroups = [] }: ISelectorQuestion) => {
			return questionGroups.includes(this.preselectedUnattachedOttoman.questionToAggregateModel.group);
		});

		if (questionSelectorModelToFilterBy) {
			const {
				selectedSelectorValue: {
					questionAnswers = [],
				} = {},
			}: any = questionSelectorModelToFilterBy;

			ottomanProductModel = this.ottomanProductModels.find(({
				frameDepth,
				questionToAggregateModel: {
					answersModel = [],
				},
			}: IProductSectionals) => {
				return answersModel.find(({ key = '' }) => {
					return questionAnswers.some(({ answer = '' }) => answer === key) && frameDepth === this.selectedProductDepth;
				});
			});
		} else {
			ottomanProductModel = this.ottomanProductModels.find(pm => pm.frameDepth === this.selectedProductDepth);
		}

		return ottomanProductModel || null;
	}

	get unattachedOttomanSelectorValue(): ISelectorValueProduct {
		const [firstOttomanSelectorValue] = this.ottomanSelectorValues;

		return this.ottomanSelectorValues.find(({ selected = false }) => selected) || firstOttomanSelectorValue;
	}
}

export const SelectorConfigSectionalsModelFactory = ({
	create: (data: any = {}) => {
		return new SelectorConfigSectionalsModel(data);
	},
});
