import { observer } from 'mobx-react';
import React from 'react';

import { s7ContentPath } from '~/global/global.constants';

export const DetailLinkIcon = observer((props) => {
	const {
		mediaIcon = '',
	} = props;

	const mediaIconData = {
		IMAGE: { src: `${s7ContentPath}/photo`, width: '25', height: '16' },
		VIDEO: { src: `${s7ContentPath}/video`, width: '24', height: '16' },
	}[mediaIcon];

	if (!mediaIcon || !mediaIconData) {
		return null;
	}

	return (
		<img
			className="detail-link-icon"
			src={mediaIconData.src}
			width={mediaIconData.width}
			height={mediaIconData.height}
			alt=""
		/>
	);
});
