import React from 'react';

import styles from '#/media-set/media-control.module.scss';

export const MediaControl = ({ children } = {}) => {
	return (
		<div className={styles['media-control']}>
			{children}
		</div>
	);
};
