import { extendObservable, observable } from 'mobx';

import { zipCodeInterceptor } from '~/util/formz/interceptors/validationInterceptors';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

import styles from '#/components/magic-delivery.module.scss';

export const magicDeliveryFormModel = (defaults = {}) => {
	const model = observable({
		zipCode: '',
	});

	extendObservable(model, defaults);

	zipCodeInterceptor(model, 'zipCode');

	return model;
};

export const magicDeliveryFormSettings = (store) => {
	const {
		globalDynamicStore = {},
		parentStore = {},
	} = store;

	const {
		linkEventStore
	} = useGlobalContext();

	const formSettings = {
		id: 'magicDeliveryForm',
		reactProps: {
			className: 'tw-space-y-2',
			method: 'POST',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler: (form) => {
							parentStore.magicDeliveryModel.isInvalidTransZone = false;

							if (!form.plugins.formValidator.hasErrors) {
								const {
									parentStore: {
										magicDeliveryModel: {
											trLinkEventCompName = null,
											trLinkEventCompType = null,
										} = {}
									} = {}
								} = store;

								store.trackZipCodeWidgetInteration(trLinkEventCompName, trLinkEventCompType, linkEventStore);

								return globalDynamicStore.setTransZone(form.model.zipCode);
							}

							return null;
						},
						promiseHandler: (promise) => {
							promise
								.then((response) => {
									parentStore.magicDeliveryModel.isInvalidTransZone = false;
									parentStore.onFetchTransZoneSuccess(response?.data);

									return response;
								})
								.catch(() => {
									parentStore.magicDeliveryModel.isInvalidTransZone = true;
								});

							return promise;
						},
					},
				}),
			],
		},
		fields: {
			zipCode: {
				reactProps: {
					className: 'tw-flex tw-flex-wrap tw-items-center tw-space-x-1',
				},
				control: {
					reactProps: {
						'className': `${styles['MagicDelivery-zipCodeControl']} tw-text-base`,
						'type': 'text',
						'autoComplete': 'postal-code',
						'data-qa': 'delivery-widget-input',
						'autoFocus': false,
					},
				},
				label: {
					reactProps: {
						className: 'tw-shrink-0 tw-font-normal tw-mr-4 tw-p-0',
						children: 'Enter ZIP Code',
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Please enter a ZIP Code',
						},
						length: {
							minimum: 5,
							maximum: 5,
							message: '^Please enter a valid ZIP Code',
						},
					},
				},
			},
		},
	};

	return formSettings;
};
