import { createDetailsModel } from '~/product/common/details/Helpers/Details.init';
import { BlurbModel } from '~/product/common/Models/Blurb.model';
import { CareGuideModel } from '~/product/common/Models/CareGuide.model';
import { HelpfulLinkModel } from '~/product/common/Models/HelpfulLink.model';
import { ProductGroupModelFactory } from '~/product/common/Models/ProductGroup.model';

export const createProductGroupModel = (productGroup) => {
	const {
		blurbs = [],
		careGuides = [],
		detail: {
			additionalInformation: detailsAdditionalData = [],
			contentTab: detailsContentTabData = [],
			details: detailsAttributesData = [],
			materials: detailsMaterialsData = [],
			sustainability: detailsSustainabilityData = [],
		} = {},
		helpfulLinks = [],
	} = productGroup;

	const productGroupModel = ProductGroupModelFactory.create(Object.entries(productGroup));

	productGroupModel.blurbs = blurbs.map((blurb) => {
		return new BlurbModel(blurb);
	});

	productGroupModel.careGuides = careGuides.map((guide) => {
		return new CareGuideModel(guide);
	});

	productGroupModel.detailsAdditionalModel = createDetailsModel(detailsAdditionalData);

	productGroupModel.detailsContentTabModel = createDetailsModel(detailsContentTabData);

	productGroupModel.detailsAttributesModel = createDetailsModel(detailsAttributesData);

	productGroupModel.detailsMaterialsModel = createDetailsModel(detailsMaterialsData);

	productGroupModel.detailsSustainabilityModel = createDetailsModel(detailsSustainabilityData);

	productGroupModel.helpfulLinks = helpfulLinks.map((guide) => {
		return new HelpfulLinkModel(guide);
	});

	return productGroupModel;
};
