import { DefaultProductModelFactory } from '~/product/common/selector/Models/DefaultProduct.model';
import { SelectorConfigModelFactory } from '~/product/common/selector/Models/SelectorConfig.model';
import { createSelectorProductModel } from '~/product/common/selector/Helpers/SelectorProduct.init';
import { createSelectorQuestionModel } from '~/product/common/selector/Helpers/SelectorQuestion.init';

export const createSelectorConfigModel = ({
	configurationOverrideModel = {},
	configurationOverrideModel: {
		hasConfigurationOverride = false,
	} = {},
	defaultConfigData = [],
	defaultProductData = {},
	productModels = [],
	productSelector: productSelectorData = {},
	questionSelectors: questionSelectorsData = [],
	showSingleSelectorValueSelectors = false,
}) => {
	const defaultProductModel = DefaultProductModelFactory.create({
		...defaultProductData,
		configurationOverrideModel,
	});

	const questionSelectorModels = questionSelectorsData.map((questionSelectorData = {}) => {
		return createSelectorQuestionModel({ selectorData: questionSelectorData });
	});

	const productSelectorModel = createSelectorProductModel({
		hasConfigurationOverride,
		productModels,
		selectorData: productSelectorData,
	});

	const selectorConfigModel = SelectorConfigModelFactory.create({
		defaultConfigModels: defaultConfigData,
		defaultProductModel,
		productSelectorModel,
		productsModel: productModels,
		questionSelectorModels,
		showSingleSelectorValueSelectors,
	});

	return selectorConfigModel;
};
