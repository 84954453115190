const getShouldShowShadowXShallow = ({
	isActive = false,
	parentWidth,
	childWidth,
	scrollLeft,
}) => {
	const childIsWider = childWidth > parentWidth;

	const maxScrollLeft = childWidth - parentWidth;

	const shouldShowShadowX = isActive && childIsWider && (scrollLeft === 0 || scrollLeft < (maxScrollLeft - 1));

	return shouldShowShadowX;
};

const getShouldShowShadowX = ({
	isActive = false,
	scrollingChildRef = {},
	scrollingParentRef = {},
}) => {
	const {
		current: {
			offsetWidth: parentWidth,
			scrollLeft,
		},
	} = scrollingParentRef;

	const {
		current: {
			scrollWidth: childWidth,
		},
	} = scrollingChildRef;

	const childIsWider = childWidth > parentWidth;

	const maxScrollLeft = childWidth - parentWidth;

	const shouldShowShadowX = isActive && childIsWider && (scrollLeft === 0 || scrollLeft < (maxScrollLeft - 1));

	return shouldShowShadowX;
};

const getShouldShowShadowY = ({
	isActive = false,
	scrollingChildRef = {},
	scrollingParentRef = {},
}) => {
	const {
		current: {
			offsetHeight: parentHeight,
			scrollTop,
		},
	} = scrollingParentRef;

	const {
		current: {
			scrollHeight: childHeight,
		},
	} = scrollingChildRef;

	const childIsTaller = childHeight > parentHeight;

	const maxScrollTop = childHeight - parentHeight;

	const shouldShowShadow = isActive && childIsTaller && (scrollTop === 0 || scrollTop < (maxScrollTop - 1));

	return shouldShowShadow;
};

const scrollSelectorToStickySummary = (selectorRef, stickySummaryModel = {}) => {
	const {
		showStickySummary,
	} = stickySummaryModel;

	const scrollSelectorToYPosition = (ref, yPosition) => {
		const scrollDiff = ref.getBoundingClientRect().top - yPosition;
		const currentY = window.scrollY;
		let targetY = currentY + scrollDiff;

		// Account for the mobile nav that appears when scrolling up
		if (targetY < currentY) {
			targetY -= 38;
		}

		window.scrollTo({ top: targetY, behavior: 'smooth' });
	};

	if (showStickySummary) {
		setTimeout(() => {
			scrollSelectorToYPosition(selectorRef, 240);
		}, 250);
	}
};

export {
	getShouldShowShadowX,
	getShouldShowShadowY,
	getShouldShowShadowXShallow,
	scrollSelectorToStickySummary,
};
