import React from 'react';

import { SelectorFiltersFacets } from '~/product/common/selector/Components/filters/SelectorFiltersFacets';
import styles from '#/product/standard/selector/selector-filters.module.scss';

export const SelectorFiltersTabccordionTab = (props = {}) => {
	const {
		magicTabccordionStore = {},
		tab = {},
		tab: {
			filterModel = {},
			filtersStore = {},
		} = {},
	} = props;

	return (
		<div className={styles['selector-filters-tabccordion-tab']}>
			<SelectorFiltersFacets
				filterModel={filterModel}
				filtersStore={filtersStore}
				magicTabccordionStore={magicTabccordionStore}
				tab={tab}
			/>
		</div>
	);
};
