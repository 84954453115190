import React from 'react';
import { useDrop } from 'react-dnd';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { workspaceEmptyTextSectionals } from '~/product/sectionals/workspace/workspace-sectionals.constants';

import styles from '~/product/sectionals/workspace/Components/workspace-empty-droppable.module.scss';

export const WorkspaceEmptyDroppableSectionals = () => {
	const {
		selectorConfigStore = {},
		workspaceModel = {},
		workspaceModel: {
			activeTabType = '',
		} = {},
		workspaceStore = {},
	} = useProductContext();

	const workspaceEmptyText = workspaceEmptyTextSectionals[activeTabType as keyof typeof workspaceEmptyTextSectionals] || workspaceEmptyTextSectionals.DEFAULT;

	const [, drop] = useDrop({
		accept: 'PRODUCT',
		drop: ({
			productSelectorValue = {},
		}: any): void => {
			selectorConfigStore.getProductDataBySelectorValue(productSelectorValue)
				.then(() => {
					workspaceStore.addFirstWorkspaceProductModel({
						orientation: 'N',
						productSelectorValue,
					});

					selectorConfigStore.setWorkspaceProductModels(workspaceModel.workspaceProductModels);
				})
				.catch((error: any) => {
					console.error(error);
				});
		},
	});

	return (
		<div
			className={`${styles['workspace-empty-droppable']} ${activeTabType === 'DEPTH' ? styles['isDepthTab'] : ''}`}
			data-qa="workspace-products-droppable"
			ref={drop}
		>
			<p className={styles['workspace-empty-droppable-text']}>
				{workspaceEmptyText}
			</p>
		</div>
	);
};
