import { action, makeObservable, observable } from 'mobx';

export class MediaSetStore {
	mediaSetDisposers;

	mediaSetModel = {};

	constructor() {
		makeObservable(this, {
			selectMediaModel: action,
			selectMediaModelHead: action,
			setIsMediaSetOverrideActive: action,
			mediaSetModel: observable.ref,
		});
	}

	selectMediaModel(mediaModelToSelect = {}) {
		const { mediaModels = [] } = this.mediaSetModel;

		mediaModels.forEach((mediaModel = {}) => {
			mediaModel.selected = false;
		});

		mediaModelToSelect.selected = true;
	}

	selectMediaModelHead() {
		const { mediaModelHead = {} } = this.mediaSetModel;

		this.selectMediaModel(mediaModelHead);
	}

	setIsMediaSetOverrideActive(isMediaSetOverrideActive) {
		this.mediaSetModel.isMediaSetOverrideActive = isMediaSetOverrideActive;
	}

	setMediaSetDisposers(mediaSetDisposers) {
		this.mediaSetDisposers = mediaSetDisposers;
	}

	setMediaSetModel(mediaSetModel) {
		this.mediaSetModel = mediaSetModel;
	}
}

export const MediaSetStoreFactory = ({
	create: ({
		mediaSetDisposers = [],
		mediaSetModel = {},
	}) => {
		const { mediaModelHead = {} } = mediaSetModel;

		const mediaSetStore = new MediaSetStore();

		Object.assign(mediaSetStore, {
			mediaSetDisposers,
			mediaSetModel,
		});

		mediaSetStore.selectMediaModel(mediaModelHead);

		return mediaSetStore;
	},
});
