import {
	computed, observable, action, makeObservable,
} from 'mobx';

import { CuralateItemModelFactory } from '~/util/curalate/Models/CuralateItem.model';

class CuralateModel {
	items;

	slickPageIndex;

	curalateItemProductViewed;

	filter;

	dataSourceId;

	experienceId;

	fanreelId;

	limit = 50;

	itemsPerPage;

	nextPageLink;

	persistentFilter;

	trLinkEventCompName = null;

	trLinkEventUseCompNameH1 = null;

	constructor() {
		makeObservable(this, {
			items: observable,
			slickPageIndex: observable,
			curalateItemProductViewed: observable,
			filter: observable,
			id: computed,
			hasNextItem: computed,
			hasPrevItem: computed,
			selectedItem: computed,
			filterAndPersistentFilter: computed,
			itemPlaceholders: computed,
			setItems: action.bound,
			addItems: action.bound,
			setSlickPageIndex: action.bound,
			trLinkEventCompName: observable,
		});
	}

	get id() {
		return this.data.id;
	}

	get hasNextItem() {
		return this.slickPageIndex + 1 < this.items.length;
	}

	get hasPrevItem() {
		return this.slickPageIndex > 0;
	}

	get selectedItem() {
		return this.items[this.slickPageIndex];
	}

	get filterAndPersistentFilter() {
		return [this.persistentFilter, this.filter].filter(Boolean).join(' and ');
	}

	get itemPlaceholders() {
		const itemPlaceholders = [];

		if (this.items.length < this.itemsPerPage) {
			for (let i = 0; i < this.pageItemsCount - this.itemsCount; i++) {
				itemPlaceholders.push(CuralateItemModelFactory.create(
					{ placeholder: true },
					(this.itemsCount + i),
				));
			}
		}

		return itemPlaceholders;
	}

	setItems(rawItems = []) {
		this.items.replace(rawItems.map((item, index) => {
			return CuralateItemModelFactory.create(item, index);
		}));
	}

	addItems(rawItems = []) {
		this.setItems(this.items.concat(rawItems.map((item, index) => {
			return CuralateItemModelFactory.create(item, index);
		})));
	}

	setSlickPageIndex(index) {
		this.slickPageIndex = index;
	}
}

const CuralateModelFactory = {
	create: (settings = {}) => {
		const instance = new CuralateModel();
		instance.items = [];

		// defaults
		instance.slickPageIndex = 0;
		instance.itemsPerPage = 5;

		Object.assign(instance, settings);

		return instance;
	},
};

export { CuralateModelFactory };
