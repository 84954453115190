/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React from 'react';
import { observer } from 'mobx-react';
import { useDragLayer } from 'react-dnd';

import { WorkspaceStackDropZone } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceStackDropZone';
import { WorkspaceProductStackSlotDraggableCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductStackSlotDraggableCasegoods';
import { addToUrl } from '~/util/addToUrl';
import { WorkspaceProductWidthDraggableCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductWidthDraggableCasegoods';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/product/workspace-product-stack.module.scss';

export const WorkspaceProductStackDraggableCasegoods = observer((props: any = {}) => {
	const {
		imageInchesToPixelsFactor,
		translateBottomInches,
		imageScale = 0,
		showDimensions = false,
		workspaceProduct = {},
		workspaceStore = {},
		productIndex,
	} = props;
	const {
		workspaceModel: {
			workspaceProducts = [],
			settings: {
				maxWorkspaceProducts = 0,
			} = {},
			workspaceProductsCount = 0,
		} = {},
	} = useProductContext();
	const widthInches = workspaceProduct?.workspaceProductWidth || 0;
	const widthPixels = (imageInchesToPixelsFactor * widthInches) / imageScale;
	const translateBottomPixels = (imageInchesToPixelsFactor * translateBottomInches) / imageScale;
	const srcParams = `$proddd$&fmt=png-alpha&scl=${imageScale}`;
	const srcSetParams = `$proddd$&fmt=png-alpha&scl=${imageScale / 2}`;
	const {
		isDragging,
		item,
		itemType,
	} = useDragLayer((monitor: any) => {
		return {
			isDragging: monitor.isDragging(),
			item: monitor.getItem(),
			itemType: monitor.getItemType(),
		};
	});
	const isDraggableWorkspaceInsertProduct = itemType === 'WORKSPACE_INSERT_PRODUCT';
	// do not show horizontal drop targets when own insert being dragged is from a single-insert stack
	const isDraggingOwnInsertProduct = workspaceProduct?.workspaceProductIndex === item?.productIndex;
	const isSingleWorkspaceInsertProduct = workspaceProduct?.slotModelsWithInserts.length === 1;
	// do not show horizontal drop targets when next adjacent insert being dragged is from a single-insert stack
	const nextWorkspaceProduct = workspaceProducts.length > workspaceProduct?.workspaceProductIndex + 1 ? workspaceProducts[workspaceProduct?.workspaceProductIndex + 1] : undefined;
	const isDraggingNextInsertProduct = nextWorkspaceProduct?.workspaceProductIndex === item?.productIndex;
	const isNextSingleWorkspaceInsertProduct = nextWorkspaceProduct?.slotModelsWithInserts.length === 1;
	// if we haven't met the maxWorkspaceProducts limit, greedily allow the horizontal drop targets to show
	const isMaxWorkspaceProducts = workspaceProductsCount === maxWorkspaceProducts;
	// if insert is being dragged from an existing stack, find its source workspaceProduct
	const sourceDraggableWorkspaceProduct = workspaceProducts.find(({ workspaceProductIndex = 0 }) => workspaceProductIndex === item?.productIndex);
	// single stack with a single insert will not show the horizontal drop targets
	const isSingleDraggableWorkspaceInsertProduct = isDraggableWorkspaceInsertProduct && workspaceProductsCount === 1 && sourceDraggableWorkspaceProduct?.slotModelsWithInserts.length === 1;
	// inserts coming from a single-insert stack will always allow the horizontal drop targets to show - the source workspaceProduct will be removed on drop and won't exceed isMaxWorkspaceProducts limit
	const showForDraggableWorkspaceInsertProduct = isDraggableWorkspaceInsertProduct && sourceDraggableWorkspaceProduct?.slotModelsWithInserts.length === 1;
	return (
		<div className={styles['workspace-product-stack']}>
			<div className="tw-flex tw-items-end">
				<WorkspaceStackDropZone
					show={
						isDragging
						&& workspaceProduct.workspaceProductIndex === 0
						&& !(isDraggingOwnInsertProduct && isSingleWorkspaceInsertProduct)
						&& !isSingleDraggableWorkspaceInsertProduct
						&& (!isMaxWorkspaceProducts || showForDraggableWorkspaceInsertProduct)
					}
					location="left"
					imageScale={imageScale}
					productIndex={productIndex}
					imageInchesToPixelsFactor={imageInchesToPixelsFactor}
				/>
				<div>
					{showDimensions
						&& <WorkspaceProductWidthDraggableCasegoods
							canShowWorkspaceErrorMessage={true}
							workspaceProduct={workspaceProduct}
							workspaceStore={workspaceStore}
						/>
					}
					<div className="tw-flex tw-flex-col">
						<img
							alt=""
							className="tw-z-20"
							src={addToUrl(workspaceProduct.frameProductModel.topUrl, srcParams)}
							srcSet={`${addToUrl(workspaceProduct.frameProductModel.topUrl, srcSetParams)} 2x`}
							style={{
								marginBottom: -translateBottomPixels,
								marginTop: translateBottomPixels,
								width: widthPixels,
							}}
						/>
						<div className="tw-flex tw-flex-col-reverse tw-z-10">
							{workspaceProduct.workspaceProductSlotModels.map((slot: any, index: number) => {
								return (!slot.hasInsertProductModel) ? null : (
									<WorkspaceProductStackSlotDraggableCasegoods
										key={index}
										workspaceProduct={workspaceProduct}
										slot={slot}
										index={index}
										translateBottomPixels={translateBottomPixels}
										imageInchesToPixelsFactor={imageInchesToPixelsFactor}
										imageScale={imageScale}
										productIndex={productIndex}
									/>
								);
							})}
						</div>
						<img
							alt=""
							src={addToUrl(workspaceProduct.frameProductModel.baseUrl, srcParams)}
							srcSet={`${addToUrl(workspaceProduct.frameProductModel.baseUrl, srcSetParams)} 2x`}
							style={{ width: widthPixels }}
						/>
					</div>
				</div>
				<WorkspaceStackDropZone
					show={
						isDragging
						&& !(isDraggingOwnInsertProduct && isSingleWorkspaceInsertProduct)
						&& !(isDraggingNextInsertProduct && isNextSingleWorkspaceInsertProduct)
						&& !isSingleDraggableWorkspaceInsertProduct
						&& (!isMaxWorkspaceProducts || showForDraggableWorkspaceInsertProduct)
					}
					location="right"
					imageScale={imageScale}
					productIndex={productIndex}
					imageInchesToPixelsFactor={imageInchesToPixelsFactor}
				/>
			</div>
		</div>
	);
});
