import {
	computed, makeObservable, observable, reaction,
} from 'mobx';

import { isOnServer } from '~/global/global.constants';
import { sortBy } from '~/util/sortBy';
import { maxBy } from '~/util/maxBy';
import { uniqBy } from '~/util/uniqBy';

export class WorkspaceCasegoods {
	didShowInsertMessage;

	dimensionsSettings;

	imageCollectionName;

	imageScale;

	imageSettings;

	insertProductIsDragging;

	insertWorkspaceProducts;

	isDragging;

	isHoveringOverFrames;

	isWorkspaceEnlarged;

	lastInteractionWasRearrange;

	lastSelectedInsertProductModel;

	lastSelectedWorkspaceProductModel;

	showErrorMessage;

	selectorConfigModel;

	selectorMagicTabccordionStore;

	settings;

	workspaceOverrideModel;

	workspaceProducts;

	workspaceProductStores;

	constructor() {
		makeObservable(this, {
			didShowInsertMessage: observable,
			insertProductIsDragging: observable,
			isDragging: observable,
			isWorkspaceEnlarged: observable,
			isHoveringOverFrames: observable,
			insertWorkspaceProducts: observable,
			lastSelectedInsertProductModel: observable,
			lastSelectedWorkspaceProductModel: observable,
			showErrorMessage: observable,
			workspaceProducts: observable,
			allProductModels: computed,
			insertProductModels: computed,
			combinedNonDefaultInsertOptionsSelectorValues: computed,
			dimensions: computed,
			frameProductModels: computed,
			hasSingleWorkspaceProduct: computed,
			hasWorkspaceProducts: computed,
			hasWorkspaceProductsWithHiddenSelector: computed,
			isDraggable: computed,
			isDraggableInserts: computed,
			showControls: computed,
			showDimensions: computed,
			showAllDimensionsByTabType: computed,
			showInsertMessage: computed,
			showInsertOptionsEdit: computed,
			showOptionalFeaturesSaved: computed,
			showOverallWidthDimension: computed,
			showSlotText: computed,
			sortedWorkspaceProducts: computed,
			workspaceProductsCount: computed,
			workspaceProductsHeight: computed,
			workspaceProductsDepth: computed,
			workspaceProductsWidth: computed,
			workspaceDimensionsDisplayText: computed,
			workspaceDetailsMaterials: computed,
		});
	}

	get activeTabType() {
		const {
			activeTab: {
				type = '',
			} = {},
		} = this.selectorMagicTabccordionStore;

		return type;
	}

	get combinedNonDefaultInsertOptionsSelectorValues() {
		return this.workspaceProducts.reduce((accumulatedNonDefaultInsertOptionsSelectorValues, { slotModelsWithNonDefaultInsertOptionsSelectorValues = [] }) => {
			const slotNonDefaultInsertOptionsSelectorValues = slotModelsWithNonDefaultInsertOptionsSelectorValues.reduce((accumulatedSlotSelectorValues, { nonDefaultInsertOptionsSelectorValues = [] }) => {
				return [...accumulatedSlotSelectorValues, ...nonDefaultInsertOptionsSelectorValues];
			}, []);

			return [...accumulatedNonDefaultInsertOptionsSelectorValues, ...slotNonDefaultInsertOptionsSelectorValues];
		}, []);
	}

	get dimensions() {
		const dimensions = this.dimensionsSettings;

		if (this.hasWorkspaceProducts) {
			const [firstFrameProduct] = this.frameProductModels;

			const { insertSizeKey = '' } = firstFrameProduct;

			const insertDimensionsSettings = dimensions[insertSizeKey];

			if (insertDimensionsSettings) {
				return {
					...dimensions,
					...insertDimensionsSettings,
				};
			}
		}

		return dimensions;
	}

	get allProductModels() {
		return [...this.insertProductModels, ...this.frameProductModels];
	}

	get insertProductModels() {
		return this.insertWorkspaceProducts;
	}

	get frameProductModels() {
		return this.sortedWorkspaceProducts.map(({ frameProductModel = {} }) => frameProductModel);
	}

	get hasSingleWorkspaceProduct() {
		return this.workspaceProductsCount === 1;
	}

	get hasWorkspaceProducts() {
		return Boolean(this.workspaceProductsCount);
	}

	get hasWorkspaceProductsWithHiddenSelector() {
		const { materialSelectorWithNonMatchingProducts = [] } = this.selectorConfigModel;

		const [firstMaterialSelectorValue] = materialSelectorWithNonMatchingProducts.selectorValues;

		const { question = '' } = firstMaterialSelectorValue;

		return this.workspaceProducts
			.some(({ frameProductModel = {} }) => {
				const { questionModels } = frameProductModel;

				return questionModels.some(({ key = '' }) => key === question);
			});
	}

	get isDraggable() {
		return this.activeTabType === 'SIZE' || this.activeTabType === 'INSERT' || this.activeTabType === 'HEIGHT' || this.activeTabType === 'WIDTH';
	}

	get isDraggableInserts() {
		return this.activeTabType === 'INSERT';
	}

	get isValidSlots() {
		return this.workspaceProducts.every(({ workspaceProductSlotModels = [] }) => {
			return workspaceProductSlotModels.every(({
				answeredBySlot = null,
				hasInsertProductModel = false,
			}) => answeredBySlot || hasInsertProductModel);
		});
	}

	get showAllDimensionsByTabType() {
		const { showAllDimensionsTabTypes = [] } = this.settings;
		return showAllDimensionsTabTypes.includes(this.activeTabType);
	}

	get showControls() {
		return ['HEIGHT', 'WIDTH', 'SIZE'].includes(this.activeTabType) && !this.settings.hideControls;
	}

	get showDimensions() {
		const { showDimensionsTabTypes = [] } = this.settings;
		return showDimensionsTabTypes.includes(this.activeTabType);
	}

	get showInsertAdditionalInfo() {
		const { showInsertMessage = false } = this.settings;

		return showInsertMessage && !this.didShowInsertMessage && this.activeTabType === 'WIDTH' && this.hasWorkspaceProducts;
	}

	get showInsertMessage() {
		const { showInsertMessage = false } = this.settings;

		return showInsertMessage && !this.didShowInsertMessage && this.activeTabType === 'INSERT';
	}

	get showInsertOptionsEdit() {
		const { showInsertMessage = false } = this.settings;

		return showInsertMessage && this.activeTabType === 'OPTIONS' && !this.isWorkspaceEnlarged;
	}

	get showOptionalFeaturesSaved() {
		return Boolean(this.combinedNonDefaultInsertOptionsSelectorValues.length) && this.activeTabType === 'OPTIONS';
	}

	get showOverallWidthDimension() {
		return this.workspaceProductsCount > 1;
	}

	get showSecondaryControls() {
		return !this.showControls;
	}

	get showSlotText() {
		const { showSlotText = false } = this.settings;

		return showSlotText && this.activeTabType === 'INSERT';
	}

	get sortedWorkspaceProducts() {
		return sortBy(this.workspaceProducts, 'workspaceProductIndex');
	}

	get workspaceProductsCount() {
		return this.workspaceProducts.length;
	}

	get workspaceProductsHeight() {
		let height = 0;
		if (this.settings.frameFirstBuilder) {
			const tallestFrameProductModel = maxBy(this.frameProductModels, o => o.height) || {};
			const { height: tallestFrameHeight = 0 } = tallestFrameProductModel;
			height = tallestFrameHeight;
		} else {
			height = maxBy(this.workspaceProducts, o => o.workspaceProductInsertsTotalHeight)?.workspaceProductInsertsTotalHeight;
		}
		return height;
	}

	get workspaceProductsDepth() {
		const deepestFrameProductModel = maxBy(this.frameProductModels, o => o.depth) || {};

		const { depth = 0 } = deepestFrameProductModel;

		return depth;
	}

	get workspaceProductsWidth() {
		return this.workspaceProducts.reduce((accumulatedWidth, { workspaceProductWidth = 0 }) => {
			return accumulatedWidth + workspaceProductWidth;
		}, 0);
	}

	get workspaceDimensionsDisplayText() {
		const {
			workspaceProductsWidth: width = 0,
			workspaceProductsDepth: depth = 0,
			workspaceProductsHeight: height = 0,
		} = this;

		return Boolean(width || depth || height)
			&& `${width}"w ${depth}"d ${height}"h`;
	}

	get workspaceDetailsMaterials() {
		const detailsMaterials = this.allProductModels
			.reduce((acc, { detailsMaterialsModel = {} }) => {
				if (detailsMaterialsModel.length) {
					acc.push(...detailsMaterialsModel);
				}
				return acc;
			}, []);

		return uniqBy(detailsMaterials, 'questionKey');
	}
}

export const WorkspaceCasegoodsModelFactory = ({
	create: ({
		didShowInsertMessage = false,
		dimensionsSettings = {},
		imageCollectionName = '',
		imageScale = {},
		imageSettings = {},
		insertProductIsDragging = false,
		insertWorkspaceProducts = [],
		isDragging = false,
		isWorkspaceEnlarged = false,
		lastInteractionWasRearrange = false,
		lastSelectedInsertProductModel,
		lastSelectedWorkspaceProductModel,
		selectorConfigModel = {},
		selectorMagicTabccordionStore = {},
		settings = {},
		showErrorMessage = false,
		workspaceOverrideModel = {},
		workspaceProducts = [],
		workspaceProductStores = [],
	} = {}) => {
		const workspaceCasegoods = new WorkspaceCasegoods();

		Object.assign(workspaceCasegoods, {
			didShowInsertMessage,
			dimensionsSettings,
			imageCollectionName,
			imageScale,
			imageSettings,
			insertProductIsDragging,
			insertWorkspaceProducts,
			isDragging,
			isWorkspaceEnlarged,
			lastInteractionWasRearrange,
			lastSelectedInsertProductModel,
			lastSelectedWorkspaceProductModel,
			selectorConfigModel,
			selectorMagicTabccordionStore,
			settings,
			showErrorMessage,
			workspaceOverrideModel,
			workspaceProducts,
			workspaceProductStores,
		});

		if (!isOnServer) {
			reaction(() => workspaceCasegoods.isValidSlots, (newIsValidSlots) => {
				const { optionsSelectorModel } = selectorConfigModel;

				if (optionsSelectorModel) {
					optionsSelectorModel.isValid = newIsValidSlots;
				}
			});
		}

		return workspaceCasegoods;
	},
});
