import { computed, observable, makeObservable } from 'mobx';

export class WorkspaceModalInsertOptionsCasegoods {
	selectedSelectorValues

	slotModel

	constructor() {
		makeObservable(this, {
			selectedSelectorValues: observable,
			slotModel: observable.ref,
			selectedSelectorValuesWithDependentQuestionAnswers: computed,
		});
	}

	get selectedSelectorValuesWithDependentQuestionAnswers() {
		return this.selectedSelectorValues.filter(({ dependentQuestionAnswers = {} }) => Boolean(Object.keys(dependentQuestionAnswers).length));
	}
}

export const WorkspaceModalInsertOptionsCasegoodsModelFactory = ({
	create: ({
		selectedSelectorValues = [],
		slotModel = {},
	} = {}) => {
		const workspaceModalInsertOptions = new WorkspaceModalInsertOptionsCasegoods();

		Object.assign(workspaceModalInsertOptions, {
			selectedSelectorValues,
			slotModel,
		});

		return workspaceModalInsertOptions;
	},
});
