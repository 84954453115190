import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import type { IWorkspaceProductsChunkSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductsChunkSectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductChildrenSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductChildrenSectionals';
import { imageInchesToPixelsFactor } from '~/product/sectionals/workspace/workspace-sectionals.constants';

import styles from '~/product/sectionals/workspace/Components/product/workspace-product.module.scss';

interface Props {
	imageScale: number
	isDraggable: boolean
	showDimensions: boolean
	workspaceProductModel: IWorkspaceProductSectionals
}

export const WorkspaceProductSectionals = observer(({
	imageScale = 0,
	isDraggable = false,
	showDimensions = false,
	workspaceProductModel,
	workspaceProductModel: {
		workspaceProductDepth = 0,
		workspaceProductWidth = 0,
	},
}: Props) => {
	const {
		workspaceModel: {
			workspaceProductsChunkModels = [],
		} = {},
	} = useProductContext();

	const workspaceProductsChunkModel: IWorkspaceProductsChunkSectionals = workspaceProductsChunkModels.find(({ workspaceProductModels = [] }: IWorkspaceProductsChunkSectionals) => workspaceProductModels.includes(workspaceProductModel));

	const {
		isReversedDirection = false,
		workspaceProductsChunkOrientation = '',
	} = workspaceProductsChunkModel;

	const workspaceProductPixelsHeight = (imageInchesToPixelsFactor * workspaceProductDepth) / imageScale;

	const workspaceProductPixelsWidth = (imageInchesToPixelsFactor * workspaceProductWidth) / imageScale;

	return (
		<div
			className={
				classNames(
					styles['workspace-product'],
					styles[`workspace-product-${workspaceProductsChunkOrientation}`], {
						[styles[`workspace-product-${workspaceProductsChunkOrientation}-reversed`]]: isReversedDirection,
					}
				)
			}
			style={{
				height: workspaceProductPixelsHeight,
				width: workspaceProductPixelsWidth,
			}}
		>
			<WorkspaceProductChildrenSectionals
				imageScale={imageScale}
				isDraggable={isDraggable}
				showDimensions={showDimensions}
				workspaceProductModel={workspaceProductModel}
				workspaceProductPixelsHeight={workspaceProductPixelsHeight}
				workspaceProductPixelsWidth={workspaceProductPixelsWidth}
			/>
		</div>
	);
});
