import { IResponsiveObject } from './Recos.types';

export const responsiveSettings: IResponsiveObject[] = [
	{
		breakpoint: 1200,
		settings: {
			slidesToShow: 5,
			slidesToScroll: 5,
			arrows: true,
		},
	},
	{
		breakpoint: 1180,
		settings: {
			slidesToShow: 4,
			slidesToScroll: 4,
			arrows: true,
		},
	},
	{
		breakpoint: 900,
		settings: {
			slidesToShow: 3,
			slidesToScroll: 3,
			arrows: true,
		},
	},
	{
		breakpoint: 670,
		settings: {
			slidesToShow: 2,
			slidesToScroll: 2,
			arrows: true,
		},
	},
	{
		breakpoint: 500,
		settings: {
			slidesToShow: 1.5,
			slidesToScroll: 1,
			arrows: false,
		},
	},
];
