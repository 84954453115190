import { computed, observable, makeObservable } from 'mobx';

import { getProductAvailabilityMessage } from '~/product/common/summary/summary.utils';
import { formatCurrency } from '~/util/formatCurrency';

export class Summary {
	addToCartAction;

	addToCartResponse;

	addToWishListResponse;

	analytics;

	availability;

	breadcrumbModels;

	comfortSpectrumKeys;

	customBaseArticleNumber;

	customTopAndBaseArticleNumber;

	customTopArticleNumber;

	deliveryMessages;

	deliveryRateMessages;

	details;

	hasError;

	isAddToCartDone;

	isAddToCartInProcess;

	isLoading;

	isMaxQuantity;

	pricing;

	quantity;

	reflektionSkuKey = 'NOT_SET';

	sizes;

	stockedArticleNumber;

	styliticsSku;

	variantIds;

	widthQuantity;

	constructor() {
		makeObservable(this, {
			analytics: observable.ref,
			addToCartAction: observable,
			addToCartResponse: observable,
			addToWishListResponse: observable,
			availability: observable,
			comfortSpectrumKeys: observable,
			customBaseArticleNumber: observable,
			customTopAndBaseArticleNumber: observable,
			customTopArticleNumber: observable,
			deliveryMessages: observable,
			deliveryRateMessages: observable,
			details: observable,
			hasError: observable,
			isAddToCartDone: observable,
			isAddToCartInProcess: observable,
			isLoading: observable,
			isMaxQuantity: observable,
			pricing: observable,
			quantity: observable,
			reflektionSkuKey: observable,
			sizes: observable,
			stockedArticleNumber: observable,
			styliticsSku: observable,
			variantIds: observable,
			widthQuantity: observable,
			addProductsToCartRequestDto: computed,
			availabilityMessage: computed,
			finalPrice: computed,
			hasComfortSpectrumKeys: computed,
			hasWidthQuantity: computed,
			isLargeOrder: computed,
			isUpsDeliveryGroup: computed,
			isValidQuantity: computed,
			isValidSummary: computed,
			originalPrice: computed,
			priceText: computed,
			questionsAnswers: computed,
			questionsAnswersHash: computed,
			selectedProductIsMadeForYou: computed,
			selectedProductRequestDto: computed,
			shippingMethod: computed,
			showPromoPricing: computed,
			summaryParams: computed,
		});
	}

	get addProductsToFavoritesRequestDto() {
		const {
			canonicalUrl: friendlyPath,
			key: productGroupKey,
		} = this.productGroupModel;

		const {
			selectorConfigModel: {
				selectedProductConfig,
				selectedProduct: {
					articleNumber,
					salesText,
					longSalesText,
				},
			},
		} = this;

		return {
			addToFavoritesPayload: {
				friendlyPath,
				quantity: this.quantity,
				productGroupKey,
				product: {
					articleNumber,
					salesText: longSalesText || salesText,
					...(selectedProductConfig.length > 0) && {
						configuration: selectedProductConfig,
					},
				},
				sourceBreadcrumbs: this.sourceBreadcrumbs,
			},
		};
	}

	get addProductsToCartRequestDto() {
		const {
			canonicalUrl: friendlyPath,
			key: productGroupKey,
		} = this.productGroupModel;

		return {
			friendlyPath,
			productGroupKey,
			productRequestDtos: [this.selectedProductRequestDto],
			quantity: this.quantity,
		};
	}

	get availabilityMessage() {
		return this.availability ? getProductAvailabilityMessage(this.availability) : '';
	}

	get finalPrice() {
		if (this.pricing) {
			if (this.showPromoPricing) {
				const {
					selectedProduct: { price: selectedProductFinalPrice },
				} = this.selectorConfigModel;
				return selectedProductFinalPrice;
			}
			return this.pricing;
		}

		return undefined;
	}

	get hasComfortSpectrumKeys() {
		return Boolean(this.comfortSpectrumKeys.length);
	}

	get hasWidthQuantity() {
		return this.widthQuantity > 1;
	}

	get isLargeOrder() {
		return this.quantity >= Number.MAX_SAFE_INTEGER;
	}

	get isUpsDeliveryGroup() {
		return /^UP(S|\d)$/.test(this.shippingMethod);
	}

	get isValidQuantity() {
		return this.quantity > 0 && this.quantity < Number.MAX_SAFE_INTEGER;
	}

	get isValidSummary() {
		return !this.isLoading && this.isValidQuantity;
	}

	get originalPrice() {
		const {
			selectedProduct: {
				originalPrice = 0,
			},
		} = this.selectorConfigModel;

		return originalPrice;
	}

	get priceText() {
		if (this.hasError) {
			return 'Call for pricing';
		}

		return this.finalPrice ? formatCurrency(this.finalPrice, true) : '';
	}

	get questionsAnswers() {
		const { selectedProductQuestionAnswers = [] } = this.selectorConfigModel;

		return selectedProductQuestionAnswers
			.map(({
				key: characteristicKey = '',
				selectedAnswer: {
					name: answerText = '',
					key: characteristicValueKey = '',
				} = {},
			}) => {
				return {
					answerText,
					characteristicKey,
					characteristicValueKey,
				};
			});
	}

	get questionsAnswersHash() {
		const {
			selectedProduct: {
				articleNumber = '',
				isLoaded = false,
			} = {},
		} = this.selectorConfigModel;

		const questionAnswersHash = this.questionsAnswers.map((questionsAnswer = {}) => {
			return Object.entries(questionsAnswer).join();
		}).join();

		return `${isLoaded ? articleNumber : ''}${questionAnswersHash}`;
	}

	get selectedProductIsMadeForYou() {
		const {
			selectedProductSelectorValue = {},
			selectedQuestionSelectorValues = [],
		} = this.selectorConfigModel;

		return selectedQuestionSelectorValues.length
			? selectedQuestionSelectorValues.some(({ stocked = false }) => !stocked)
			: selectedProductSelectorValue.stocked === false;
	}

	get selectedProductRequestDto() {
		const {
			questionsAnswers,
			selectorConfigModel: {
				selectedProductConfig,
				selectedProduct: { articleNumber },
			},
		} = this;

		return {
			articleNumber,
			configuration: selectedProductConfig,
			questionsAnswers,
		};
	}

	get sourceBreadcrumbs() {
		if (!this.breadcrumbModels) {
			return {};
		}

		const sourceCrumbs = {};

		this.breadcrumbModels.forEach((crumb) => {
			const { url = '', type = '' } = crumb.data[0];
			if (!url || !type) {
				return;
			}
			const lastIndex = url.lastIndexOf('/');
			const trimmedUrl = url.slice(lastIndex + 1);
			const sourceCrumb = { [type]: trimmedUrl };
			Object.assign(sourceCrumbs, sourceCrumb);
		});
		return sourceCrumbs;
	}

	get favoritesModalProductDto() {
		const {
			selectedProduct: {
				imageData: {
					imageUrl = undefined,
				} = {},
				salesText = '',
				longSalesText = '',
			},
		} = this.selectorConfigModel;

		return {
			imageUrl,
			salesText: salesText || longSalesText,
			quantity: this.quantity,
			price: this.finalPrice,
		};
	}

	get shippingMethod() {
		return this.selectorConfigModel?.selectedProduct?.delivery?.shippingMethod;
	}

	get showPromoPricing() {
		const {
			selectedProduct: {
				pricingInfo: { promotional = 0 },
			},
		} = this.selectorConfigModel;

		// Promo offset is negative
		return promotional < 0;
	}

	get summaryParams() {
		const { canonicalUrl = '' } = this.productGroupModel;

		const {
			selectedProduct: {
				delivery: {
					shippingMethod = '',
				} = {},
			} = {},
			selectedProductConfig = [],
		} = this.selectorConfigModel;

		return {
			question: selectedProductConfig,
			quantity: this.quantity,
			shippingMethod,
			productGroupKey: this.productGroupModel.key,
			friendlyPath: canonicalUrl,
		};
	}
}

export const SummaryModelFactory = ({
	create: ({
		analytics = [],
		breadcrumbModels = [],
		comfortSpectrumKeys = [],
		hasError = false,
		isMaxQuantity = false,
		productGroupModel = {},
		quantity = 1,
		reflektionSkuKey = 'NOT_SET',
		selectorConfigModel = {},
		styliticsSku = '',
		variantIds = [],
		widthQuantity = 0,
	}) => {
		const summary = new Summary();

		Object.assign(summary, {
			analytics,
			breadcrumbModels,
			comfortSpectrumKeys,
			hasError,
			isMaxQuantity,
			productGroupModel,
			quantity,
			reflektionSkuKey,
			selectorConfigModel,
			styliticsSku,
			variantIds,
			widthQuantity,
		});

		return summary;
	},
});
