import { autorun, observable, makeObservable } from 'mobx';

import { delivery } from '~/util/delivery';
import { Delivery } from '~/delivery/Components/Delivery';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { DeliveryUpsStoreFactory } from '~/delivery/Stores/DeliveryUps.store';
import { DeliveryInHomeStoreFactory } from '~/delivery/Stores/DeliveryInHome.store';
import { isOnServer } from '~/global/global.constants';

class DeliveryMainStore {
	hasLoaded;

	observers

	viewState

	constructor() {
		makeObservable(this, {
			hasLoaded: observable,
		});
	}
}

export const DeliveryMainStoreFactory = {
	create(productCommons, globalDynamicStore) {
		const deliveryMainStore = new DeliveryMainStore();

		deliveryMainStore.observers = [];
		deliveryMainStore.hasLoaded = true;
		deliveryMainStore.viewState = new ViewStateStore(deliveryMainStore, {
			ups: {
				component: Delivery,
				onEnter: () => ({ store: DeliveryUpsStoreFactory.create(productCommons) }),
			},
			inhome: {
				component: Delivery,
				onEnter: () => ({ store: DeliveryInHomeStoreFactory.create(productCommons, globalDynamicStore, { flavor: 'standard' }) }),
			},
		});

		deliveryMainStore.observers.push(
			autorun(() => {
				if (delivery.isUpsDelivery(productCommons.productModel.delivery.shippingMethod)) {
					deliveryMainStore.viewState.goTo('ups');
				} else {
					deliveryMainStore.viewState.goTo('inhome');
				}
			}),
		);

		if (isOnServer) {
			DeliveryMainStoreFactory.destroy(deliveryMainStore);
		}

		return deliveryMainStore;
	},
	destroy(store) {
		store.observers?.forEach?.(dispose => dispose());
	},
};
