import React from 'react';
import { observer } from 'mobx-react';

import styles from '~/subcategory/shopThisRoom/Components/ShopThisRoomLayout.module.scss';

import type { ShopThisRoomTrayStore } from '~/product/common/shop-this-room-tray/Stores/ShopThisRoomTray.store';
import { ShopThisRoomProductModel } from '~/subcategory/shopThisRoom/Models/ShopThisRoomProduct.model';
import { ShopThisRoomProduct } from '~/subcategory/shopThisRoom/Components/ShopThisRoomProduct';

interface Props {
	store: ShopThisRoomTrayStore,
}

export const ShopThisRoomTrayProducts = observer((props: Props) => {
	const {
		store: {
			mediaSetImage = '',
			shopThisRoomModel: {
				breadcrumbs = [],
				products = [],
				canonicalUrl = '',
			},
		},
	} = props;

	return (
		<div data-qa="product-groups-list">
			<div className="tw-flex tw-flex-col">
				<ul className={`${styles.ShopThisRoomProducts} ${styles.ShopThisRoomTray}`} data-tr-link-event-comp-name={mediaSetImage} data-tr-link-event-comp-type="shop this room tray">
					{products.map((product: ShopThisRoomProductModel) => {
						return (
							<ShopThisRoomProduct key={`product-${product.articleNumber}`} product={product} canonicalUrl={canonicalUrl} isTray={true} breadcrumbs={breadcrumbs} />
						);
					})}
				</ul>
			</div>
		</div>
	);
});
