import { ComfortSpectrumMeterModelFactory } from '~/product/common/comfort-spectrum/Models/ComfortSpectrumMeter.model';
import { ComfortSpectrumModelFactory } from '~/product/common/comfort-spectrum/Models/ComfortSpectrum.model';

export const createComfortSpectrumModel = (comfortSpectrumData = {}, comfortSpectrumKeysData = []) => {
	const metersModel = Object.entries(comfortSpectrumData).map(([ratingType, ratingValue]) => {
		return ComfortSpectrumMeterModelFactory.create({
			ratingType,
			ratingValue,
		});
	});

	const comfortSpectrumModel = ComfortSpectrumModelFactory.create({
		metersModel,
		comfortSpectrumKeys: comfortSpectrumKeysData,
	});

	return comfortSpectrumModel;
};
