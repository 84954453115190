import { computed, observable, makeObservable } from 'mobx';

class SelectorFacet {
	aggregatorSelectorValueCount

	count

	facetPosition

	isInclusive

	matchingFacetsModels

	nonMatchingFacetsModels

	parentSelectorModel

	parentType

	selected

	selectorValues

	shouldAggregateSelectorValues

	showMatchCount

	title

	constructor() {
		makeObservable(this, {
			selected: observable,
			facetPosition: observable,
			combinedIntersectingFilteredSelectorValues: computed,
			filteredSelectorValues: computed,
			hasMatchingFacets: computed,
			hasMatchingSelectedFacets: computed,
			hasNonMatchingSelectedFacets: computed,
			intersectingFilteredSelectorValues: computed,
			intersectingFilteredSelectorValuesCount: computed,
			matchingFilteredSelectorValues: computed,
			matchingIntersectingFilteredSelectorValues: computed,
			matchingSelectedFacets: computed,
			nonMatchingFilteredSelectorValues: computed,
			nonMatchingIntersectingFilteredSelectorValues: computed,
			nonMatchingSelectedFacets: computed,
			selectedParentSelectorValue: computed,
			selectorValuesToUse: computed,
			validSelectorValues: computed,
		});
	}

	get combinedIntersectingFilteredSelectorValues() {
		return this.filteredSelectorValues.filter((selectorValue) => {
			return this.matchingIntersectingFilteredSelectorValues.includes(selectorValue) && this.nonMatchingIntersectingFilteredSelectorValues.includes(selectorValue);
		});
	}

	get filteredSelectorValues() {
		return this.selectorValuesToUse.filter(({ filters: selectorValueFilters = {} }) => {
			const selectorValueFilter = selectorValueFilters[this.parentType] || [];

			return selectorValueFilter.includes(this.title);
		});
	}

	get hasMatchingFacets() {
		return Boolean(this.matchingFacetsModels.length);
	}

	get hasMatchingSelectedFacets() {
		return Boolean(this.matchingSelectedFacets.length);
	}

	get hasNonMatchingSelectedFacets() {
		return Boolean(this.nonMatchingSelectedFacets.length);
	}

	get intersectingFilteredSelectorValues() {
		// only matching selected facets
		if (this.hasMatchingSelectedFacets && !this.hasNonMatchingSelectedFacets) {
			return this.matchingIntersectingFilteredSelectorValues;
		}

		// only non-matching selected facets
		if (!this.hasMatchingSelectedFacets && this.hasNonMatchingSelectedFacets) {
			return this.nonMatchingIntersectingFilteredSelectorValues;
		}

		// both matching and non-matching selected facets
		if (this.hasMatchingSelectedFacets && this.hasNonMatchingSelectedFacets) {
			return this.combinedIntersectingFilteredSelectorValues;
		}

		// no selected facets
		return this.filteredSelectorValues;
	}

	get intersectingFilteredSelectorValuesCount() {
		if (this.aggregatorSelectorValueCount) {
			return Math.ceil(this.intersectingFilteredSelectorValues.length / this.aggregatorSelectorValueCount);
		}

		return this.intersectingFilteredSelectorValues.length;
	}

	get matchingFilteredSelectorValues() {
		if (this.matchingSelectedFacets.length) {
			return this.matchingSelectedFacets.reduce((accumulatedSelectorValues, { filteredSelectorValues = [] }) => {
				const intersectingFilteredSelectorValues = filteredSelectorValues.filter((selectorValue) => {
					return this.matchingSelectedFacets.every(({ filteredSelectorValues: selectorValuesToMatch = [] }) => {
						return selectorValuesToMatch.includes(selectorValue);
					});
				});

				return [...accumulatedSelectorValues, ...intersectingFilteredSelectorValues];
			}, []);
		}

		return this.filteredSelectorValues;
	}

	get matchingIntersectingFilteredSelectorValues() {
		if (!this.isInclusive && this.matchingSelectedFacets.length) {
			return this.filteredSelectorValues.filter((selectorValue) => {
				return this.matchingFilteredSelectorValues.includes(selectorValue);
			});
		}

		return this.filteredSelectorValues;
	}

	get matchingSelectedFacets() {
		return this.matchingFacetsModels.filter(({ selected }) => selected);
	}

	get nonMatchingFilteredSelectorValues() {
		return this.nonMatchingSelectedFacets.reduce((accumulatedSelectorValues, { filteredSelectorValues = [] }) => {
			const intersectingFilteredSelectorValues = filteredSelectorValues.filter((selectorValue) => {
				return this.nonMatchingSelectedFacets.some(({ filteredSelectorValues: selectorValuesToMatch = [] }) => {
					return selectorValuesToMatch.includes(selectorValue);
				});
			});

			return [...accumulatedSelectorValues, ...intersectingFilteredSelectorValues];
		}, []);
	}

	get nonMatchingIntersectingFilteredSelectorValues() {
		if (this.nonMatchingSelectedFacets.length) {
			return this.filteredSelectorValues.filter((selectorValue) => {
				return this.nonMatchingFilteredSelectorValues.includes(selectorValue);
			});
		}

		return this.filteredSelectorValues;
	}

	get nonMatchingSelectedFacets() {
		return this.nonMatchingFacetsModels.filter(({ selected }) => selected);
	}

	get selectedParentSelectorValue() {
		const { selectedSelectorValue = {} } = this.parentSelectorModel;

		return selectedSelectorValue;
	}

	get selectorValuesToUse() {
		if (this.shouldAggregateSelectorValues) {
			return this.selectorValues;
		}

		return this.validSelectorValues;
	}

	get validSelectorValues() {
		const {
			selectedSelector: {
				selectorValues = [],
			} = {},
		} = this.selectedParentSelectorValue;

		return selectorValues.length ? selectorValues : this.selectorValues;
	}
}

export const SelectorFacetModelFactory = ({
	create: ({
		aggregatorSelectorValueCount = 0,
		count = 0,
		facetPosition = '',
		isInclusive = false,
		matchingFacetsModels = [],
		nonMatchingFacetsModels = [],
		parentSelectorModel = {},
		parentType = '',
		selected = false,
		selectorValues = [],
		shouldAggregateSelectorValues = false,
		showMatchCount = true,
		title = '',
	}) => {
		const selectorFacet = new SelectorFacet();

		Object.assign(selectorFacet, {
			aggregatorSelectorValueCount,
			count,
			facetPosition,
			isInclusive,
			matchingFacetsModels,
			nonMatchingFacetsModels,
			parentSelectorModel,
			parentType,
			selected,
			selectorValues,
			shouldAggregateSelectorValues,
			showMatchCount,
			title,
		});

		return selectorFacet;
	},
});
