import { computed, observable, makeObservable } from 'mobx';

export class InsertProduct {
	answerKey;

	articleNumber;

	compatibleFrameDepth;

	compatibleFrameSize;

	compatibleFrameWidth;

	detailsAdditionalModel;

	detailsAttributesModel;

	detailsMaterialsModel;

	detailsSustainabilityModel;

	exceptionRows;

	filters;

	imageUrl;

	insertDynamicDetails;

	insertSpace;

	questionModels;

	salesText;

	salesTextOverride;

	subtitle;

	title;

	type;

	constructor() {
		makeObservable(this, {
			imageUrl: observable,
			configuration: computed,
			hasExceptionRows: computed,
			salesTextToRender: computed,
			selectedQuestionAnswers: computed,
			slotColumnCount: computed,
			slotRowCount: computed,
			titleToRender: computed,
		});
	}

	get configuration() {
		return this.selectedQuestionAnswers.reduce((accumulatedQuestionAnswers, {
			answer = '',
			question = '',
		}) => {
			accumulatedQuestionAnswers[question] = answer;

			return accumulatedQuestionAnswers;
		}, {});
	}

	get hasExceptionRows() {
		return Boolean(this.exceptionRows.length);
	}

	get slotColumnCount() {
		if (['DOUBLE WIDE', 'QUAD'].includes(this.insertSpace)) {
			return 2;
		}

		return 1;
	}

	get slotRowCount() {
		if (['DOUBLE TALL', 'QUAD'].includes(this.insertSpace)) {
			return 2;
		}

		return 1;
	}

	get salesTextToRender() {
		return this.salesTextOverride || this.salesText || this.subtitle;
	}

	get selectedQuestionAnswers() {
		return this.questionModels.map(({
			key,
			selectedAnswer,
		}) => {
			return {
				answer: selectedAnswer?.key,
				question: key,
			};
		});
	}

	get titleToRender() {
		return this.insertSpace ? this.title : '';
	}
}

export const InsertProductModelFactory = ({
	create: ({
		answerKey = '',
		articleNumber = '',
		compatibleFrameDepth = 0,
		compatibleFrameSize = 0,
		compatibleFrameWidth = 0,
		detailsAdditionalModel = [],
		detailsAttributesModel = [],
		detailsMaterialsModel = [],
		detailsSustainabilityModel = [],
		exceptionRows = [],
		filters = {},
		height,
		imageUrl = '',
		insertDynamicDetails = {},
		insertSpace = '',
		salesText = '',
		salesTextOverride = '',
		subtitle = '',
		questionModels = [],
		title = '',
		type = '',
	}) => {
		const insertProduct = new InsertProduct();

		Object.assign(insertProduct, {
			answerKey,
			articleNumber,
			compatibleFrameDepth,
			compatibleFrameSize,
			compatibleFrameWidth,
			detailsAdditionalModel,
			detailsAttributesModel,
			detailsMaterialsModel,
			detailsSustainabilityModel,
			exceptionRows,
			filters,
			height,
			imageUrl,
			insertDynamicDetails,
			insertSpace,
			salesText,
			salesTextOverride,
			subtitle,
			questionModels,
			title,
			type,
		});

		return insertProduct;
	},
});
