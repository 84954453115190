import type { IProductGroupOverrideSettings, IProductQuestion } from '~/product/casegoods/types/Casegoods.types';

const defaultImageScaleSelectorCasegoods = {
	lineDrawing: 8.46,
	lineDrawingDraggable: 8.46,
	lineDrawingDraggableSmall: 11,
	medium: 16.09,
	small: 15.25,
};

export const imageScaleSelectorCasegoods = {
	beam: { ...defaultImageScaleSelectorCasegoods },
	copenhagen: {
		...defaultImageScaleSelectorCasegoods,
		lineDrawing: 18,
		lineDrawingDraggable: 8,
		lineDrawingDraggableSmall: 13.5,
		medium: 36,
		small: 27,
	},
	DEFAULT: defaultImageScaleSelectorCasegoods,
	hudson: {
		...defaultImageScaleSelectorCasegoods,
		lineDrawing: 13,
		lineDrawingDraggable: 6.5,
		lineDrawingDraggableSmall: 9,
		medium: 27,
		small: 23,
	},
	keaton: {
		...defaultImageScaleSelectorCasegoods,
		medium: 27,
		small: 23,
	},
	linear: {
		...defaultImageScaleSelectorCasegoods,
		lineDrawing: 15,
		lineDrawingDraggable: 7.25,
		lineDrawingDraggableSmall: 10,
		medium: 32,
		small: 25,
	},
	slim: { ...defaultImageScaleSelectorCasegoods },
	taylor: {
		...defaultImageScaleSelectorCasegoods,
		lineDrawing: 15,
		lineDrawingDraggable: 7.25,
		lineDrawingDraggableSmall: 10,
		medium: 32,
		small: 25,
	},
	woodwind: {
		...defaultImageScaleSelectorCasegoods,
		medium: 27,
		small: 23,
	},
};

const defaultSettingsSelectorCasegoods = {
	downloadSelectorHeadingTitle: 'of your design as a handy reference.',
	frameFirstBuilder: true,
	frameModalHeadingTitle: 'Make your selections',
	frameSelectorHeadingTitle: '',
	frameSelectorHeadingTitlePrefix: '',
	frameSelectorValuesPerRow: 3,
	hideEditButton: false,
	hideFrameFilters: false,
	hideFrameSelector: false,
	insertSelectorHeadingTitle: '',
	insertSelectorHeadingTitlePrefix: '',
	interstitialTitlePrefix: '',
	isValidInsertsSelectorAlways: false,
	isValidSummaryRequireIsValidSlots: false,
	showAllMaterialSelectors: false,
	showFlattenedInsertProducts: false,
	showHeadingSalesText: false,
	showInsertSalesText: false,
	showInsertSelectorAsTabs: false,
	showInsertSelectorHeading: false,
	showInsertSelectorValuesCount: false,
	showInsertTitle: true,
	showModalFrameDimensions: false,
	showStandardInterstitial: false,
	useAdditionalFrameProductsParam: false,
	useInsertImageEndpoint: false,
	useInsertProductsSummaryParam: false,
	useMaterialsEndpoint: false,
	useProductsEndpoint: false,
};

export const settingsSelectorCasegoods = (productPageType = '') => {
	const settingsSelectorCasegoodsMap: any = {
		CUSTOM_BOOKCASE: {
			...defaultSettingsSelectorCasegoods,
			frameSelectorHeadingTitle: 'Select up to three pieces',
			frameSelectorHeadingTitlePrefix: 'Bookcase ',
			interstitialTitlePrefix: 'Bookcase ',
			showHeadingWorkspaceDimensions: true,
		},
		CUSTOM_CABINET: {
			...defaultSettingsSelectorCasegoods,
			frameModalHeadingTitle: 'Choose depth',
			frameSelectorValuesPerRow: 4,
			insertSelectorHeadingTitle: 'Drag and drop inserts onto your cabinet. No additional cost.',
			isValidSummaryRequireIsValidSlots: true,
			showFlattenedInsertProducts: true,
			showInsertSalesText: true,
			showInsertSelectorValuesCount: true,
			showModalFrameDimensions: true,
			showStandardInterstitial: true,
			useInsertImageEndpoint: true,
			useMaterialsEndpoint: true,
			useProductsEndpoint: true,
		},
		DEFAULT: defaultSettingsSelectorCasegoods,
		MODULAR_BOOKCASE: {
			...defaultSettingsSelectorCasegoods,
			downloadSelectorHeadingTitle: 'of your design as a handy reference when it’s time to place your inserts.',
			frameSelectorHeadingTitle: 'Select up to three pieces. Each is 78 inches tall',
			frameSelectorHeadingTitlePrefix: 'Unit',
			frameSelectorHeadingTitleSuffixSalesTextOverride: true,
			frameSelectorValuesPerRow: 3,
			hideFrameFilters: true,
			insertSelectorHeadingTitle: 'Drag and drop inserts onto shelves to build out your design.',
			interstitialTitlePrefix: 'Unit ',
			onlyTwoDimensions: true,
			showAllMaterialSelectors: true,
			showHeadingSalesText: false,
			showInsertSalesText: true,
			showInsertSelectorValuesCount: true,
			showInsertTitle: false,
			useAdditionalFrameProductsParam: true,
			useInsertProductsSummaryParam: true,
			useProductsEndpoint: true,
		},
		STACK: {
			...defaultSettingsSelectorCasegoods,
			frameFirstBuilder: false,
			frameModalHeadingTitle: 'Choose Depth',
			frameSelectorHeadingTitle: 'Drag and drop pieces to build your wall unit up to 84.75" tall',
			insertSelectorHeadingTitle: 'Add pieces up to three wide and 84.75” tall',
			insertSelectorHeadingTitlePrefix: 'Unit',
			interstitialTitlePrefix: 'Unit ',
			isValidInsertsSelectorAlways: true,
			hideEditButton: true,
			hideFrameSelector: true,
			showInsertSalesText: true,
			showInsertSelectorHeading: true,
			showInsertSelectorAsTabs: true,
			showInsertTitle: false,
			showFlattenedInsertProducts: true,
			useMaterialsEndpoint: true,
			useProductsEndpoint: true,
		},
	};

	return settingsSelectorCasegoodsMap[productPageType];
};

export const productGroupOverrideSettingsSelectorCasegoods: IProductGroupOverrideSettings = {
	slim: {
		frameSelectorHeadingTitleSuffixSalesTextOverride: false,
		frameSelectorValuesPerRow: 3,
		hideEditButton: true,
		isDraggableOnSmall: true,
		onlyOneDimension: true,
	},
};

export const questionParams: IProductQuestion = {
	'BEAM_COLL_FRAME': {
		param: 'COLOR',
	},
	'BEAM_COLL_SHELF': {
		param: 'SHELF',
	},
	'SLIM_COLL_COLOR': {
		param: 'COLOR',
	},
	'SLIM_COLL_HDWR': {
		param: 'KNOB_PULL',
	},
	'SLIM_COLL_WOOD': {
		param: 'FINISH',
	},
};
