import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductInsertImageCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-insert/WorkspaceProductInsertImageCasegoods';

import styles from '#/product/casegoods/workspace/product-insert/workspace-product-insert-draggable.module.scss';

export const WorkspaceProductInsertDraggableCasegoods = observer((props) => {
	const {
		imageScale = 0,
		insertProductModel = {},
		insertProductModel: {
			insertSpace = '',
		} = {},
		insertProps: {
			imageScale: insertPropsImageScale = 0,
			imageScaleSmall: insertPropsImageScaleSmall = 0,
			insertOptionsSelectors = [],
			lineDrawingImage = '',
			productModel = {},
			productModels = [],
		} = {},
		slotModel = {},
		slotModel: {
			answerForSlots = [],
			insertOptionsSelectorValues = [],
		} = {},
	} = props;

	const {
		workspaceModel: {
			dimensions: {
				imageInchesToPixelsFactor = 0,
				slotPaddingInches = 0,
			} = {},
			insertProductIsDragging = false,
			isDraggableInserts = false,
		} = {},
		workspaceStore = {},
	} = useProductContext();

	const workspaceProductInsertPixelsPadding = (imageInchesToPixelsFactor * slotPaddingInches) / imageScale;

	const dragItem = {
		imageScale: insertPropsImageScale,
		imageScaleSmall: insertPropsImageScaleSmall,
		insertOptionsSelectors,
		insertOptionsSelectorValues,
		lineDrawingImage,
		productModel,
		productModels,
		slotModel,
	};

	const dragItemRef = useRef(dragItem);

	const [isDraggingState, setIsDraggingState] = useState(false);

	const [{ isDragging = false }, drag, preview] = useDrag(() => ({
		collect: (monitor) => {
			return {
				isDragging: monitor.isDragging(),
			};
		},
		end: ((item, monitor) => {
			runInAction(() => {
				// insert was dropped outside the frame or workspace
				if (!monitor.didDrop()) {
					// clear the adjacent slots
					answerForSlots.forEach((slotModelToClear = {}) => {
						workspaceStore.clearSlot(slotModelToClear);
					});

					workspaceStore.clearSlot(slotModel);
				}

				workspaceStore.setInsertProductIsDragging(null);
			});

			setIsDraggingState(false);
		}),
		item: () => {
			// allow react-dnd to capture the drag preview before hiding the element being dragged
			setTimeout(() => {
				workspaceStore.setInsertProductIsDragging(true);

				setIsDraggingState(true);
			}, 0);

			return dragItemRef.current;
		},
		type: 'INSERT_PRODUCT',
	}));

	const disablePointerEvents = insertProductIsDragging && !isDragging;

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	useEffect(() => {
		dragItemRef.current = dragItem;
	});

	return (
		<div
			className={
				classNames(
					styles['workspace-product-insert-draggable'],
					styles[`workspace-product-insert-draggable-${insertSpace.replace(' ', '_')}`],
					{
						'tw-pointer-events-none': !isDraggableInserts,
					}
				)
			}
			data-qa="workspace-product-insert-draggable"
			ref={drag}
			style={{
				display: isDraggingState ? 'none' : 'block',
				padding: `${workspaceProductInsertPixelsPadding}px`,
				pointerEvents: disablePointerEvents ? 'none' : 'auto',
			}}
		>
			<WorkspaceProductInsertImageCasegoods
				imageScale={imageScale}
				insertProductModel={insertProductModel}
			/>
		</div>
	);
});
