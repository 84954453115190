import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { ShopThisRoomTrayStore, createStore } from '~/product/common/shop-this-room-tray/Stores/ShopThisRoomTray.store';
import { s7ContentPath } from '~/global/global.constants';
import shopRoomLinkStyles from '~/components/Buttons/Components/ShopRoomLink.module.scss';
import { useGlobalContext } from '~/global/Contexts/Global.context';

import styles from '#/media-set/media-link-shop-this-room.module.scss';

export const MediaButtonShopThisRoomTray = observer((props: any) => {
	const {
		mediaModel: {
			fileName = '',
			selected = false,
		} = {},
		link: {
			href = '',
		} = {},
	} = props;

	const {
		magicModal,
	} = useGlobalContext();

	const [store] = useState<ShopThisRoomTrayStore>(() => createStore(href, fileName));

	function handleClick() {
		store.setMagicModal(magicModal);
		store.setApiLink(href);
		store.openModal();
	}

	return (
		<button
			className={`${styles['media-link-shop-this-room']} ${shopRoomLinkStyles.root}`}
			onClick={handleClick}
			data-qa="media-button-shop-this-room"
			data-tr-link-event-name="open shop this room tray"
			tabIndex={selected ? 0 : -1}
		>
			<img
				alt=""
				className={styles['media-link-shop-this-room-image']}
				src={`${s7ContentPath}/shopping-tag`}
			/>
			<span className={styles['media-link-shop-this-room-text']}>
				Shop<span className="tw-sr-only"> products in this image</span>
			</span>
		</button>
	);
});
