import { observer } from 'mobx-react';
import React from 'react';

import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector.module.scss';

export const SelectorHeadingDropDown = observer((props) => {
	const {
		isActive = true,
		selectedSelector: {
			label = '',
			selectedSelectorValue: {
				title = '',
			} = {},
			selectorValuesToRenderCount = 0,
			groupValuesByStocked = false,
		} = {},
	} = props;

	return (
		<div
			className="selector-heading"
			data-qa={`${kebabCase(label)}-selector-heading`}
		>
			<div className="selector-heading-label-container">
				<SelectorHeadingLabel label={label} />
				{(!isActive || !groupValuesByStocked) &&
					<span
						className={styles['selector-heading-count']}
						data-qa={`${kebabCase(label)}-selector-heading-count`}
					>
						{selectorValuesToRenderCount} options
					</span>
				}
			</div>
			<span
				className={styles['selector-heading-title']}
				data-qa={`${kebabCase(label)}-selector-heading-title`}
			>
				{title}
			</span>
		</div>
	);
});
