import { IReviewEventJSON } from '~/tracking/review-event/Interfaces/IReviewEvent.tracking';
import { sanitize } from '~/util/sanitizeString';

export class ReviewStatsEvent {
	groupKey: string;

	average: string;

	count: string;

	constructor(groupKey: string, average: number, count: number) {
		this.groupKey = groupKey;
		this.average = average.toString();
		this.count = count.toString();
	}

	get pageId(): string {
		const { pathname = '' } = window.location;
		const normalizedPathname = pathname.replace(/^\/m/, '');

		return normalizedPathname.replace(/\/(?!$)/g, ':').replace(/\/|(\..+$)/g, '').toLowerCase();
	}

	get site(): string {
		return sanitize(window?.location?.hostname?.replace?.('www.', ''));
	}

	get trackingJson(): IReviewEventJSON {
		return {
			event: 'review',
			page: {
				id: this.pageId,
			},
			review: {
				groupKey: this.groupKey,
				average: this.average,
				count: this.count,
			},
			visitor: {
				site: this.site,
			},
		};
	}
}
