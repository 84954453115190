import React, { useRef } from 'react';
import { observer } from 'mobx-react';

import { TextButton } from '~/components/Buttons/Components/TextButton';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { saveWorkspaceImage } from '~/product/sectionals/selector/Utils/SelectorDownloadSectionals.utils';

export const SelectorDownload = observer(() => {
	const workspaceCanvas = useRef<HTMLCanvasElement>(null);
	const {
		workspaceModel: {
			sortedWorkspaceProductModels = [],
			workspaceProductsDepth = 0,
			workspaceProductsWidth = 0,
			workspaceData: {
				workspaceProduct: {
					orientation = 'N'
				} = {}
			} = {}
		} = {},
	} = useProductContext();

	return (
		<div className={'tw-pb-5 tw-pt-5 md:tw-pt-0'}>
			<TextButton
				data-qa={'download-photo-button'}
				data-tr-link-event-track={false}
				onClick={() => saveWorkspaceImage(workspaceCanvas, sortedWorkspaceProductModels, workspaceProductsDepth, workspaceProductsWidth, orientation)}
			>
				Download a photo
			</TextButton> of your sectional to easily remember dimensions and layout when planning your room.
			<div className={'tw-sr-only'}>
				<canvas
					ref={workspaceCanvas}
					height={0}
					width={0}
				/>
			</div>
		</div>
	);
});
