import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useDragLayer, useDrop } from 'react-dnd';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductInsertDraggableCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-insert/WorkspaceProductInsertDraggableCasegoods';
import { getMatchingInsertProductModel } from '~/product/casegoods/workspaces/defaultWorkspace/Utils/WorkspaceCasegoods.utils';

import styles from '#/product/casegoods/workspace/product-slot/workspace-product-slot-droppable.module.scss';

export const WorkspaceProductSlotDroppableCasegoods = observer((props) => {
	const {
		depth = 0,
		imageScale = 0,
		insertSizeKey = '',
		isHidden = false,
		slotHeightPercentage = 0,
		slotModel = {},
		slotModel: {
			allow = '',
			answeredBySlot = null,
			hasInsertProductModel = false,
			insertProps = {},
			insertProductModel = {},
			isMaxColumn = false,
			isMaxRow = false,
			isValid = false,
			rowId = '',
		} = {},
		slotWidthPercentage = 0,
		width = 0,
		workspaceProduct = {},
	} = props;

	const {
		workspaceModel: {
			settings: {
				showSlotPlus = false,
			},
			showSlotText = false,
			showErrorMessage = false
		} = {},
		workspaceStore = {},
	} = useProductContext();

	const draggableInsertProductModelRef = useRef({});

	const [{
		canDrop = false,
		draggableInsertProductModel,
		draggableInsertSpace,
		isOver = false,
		isValidInsertSpace = false,
	}, drop] = useDrop({
		accept: 'INSERT_PRODUCT',
		canDrop: () => {
			return isValid;
		},
		hover: () => {
			if (isOver && !draggableInsertProductModel) {
				!showErrorMessage && workspaceStore.setShowErrorMessage(true);
			} else if (isValidInsertSpace) {
				showErrorMessage && workspaceStore.setShowErrorMessage(false);
			}
		},
		collect: (monitor) => {
			const { productModels = [] } = monitor.getItem() || {};

			const matchingInsertProductModel = getMatchingInsertProductModel({
				allow,
				depth,
				insertProductModels: productModels,
				insertSizeKey,
				rowId,
				width,
			});

			// react-dnd has issue with draggableInsertProductModel turning undefined on drag spam events, store it in a ref so react can track it better
			const { insertSpace = '' } = draggableInsertProductModelRef.current;

			const isValidMatchingInsertSpace = !(isMaxColumn && ['DOUBLE WIDE', 'QUAD'].includes(insertSpace)) && !(isMaxRow && ['DOUBLE TALL', 'QUAD'].includes(insertSpace));

			if (matchingInsertProductModel) {
				draggableInsertProductModelRef.current = matchingInsertProductModel;
			}

			return {
				canDrop: monitor.canDrop(),
				draggableInsertProductModel: matchingInsertProductModel,
				draggableInsertSpace: insertSpace,
				isOver: monitor.isOver(),
				isValidInsertSpace: isValidMatchingInsertSpace,
			};
		},
		drop: (newInsertProps) => {
			const { slotModel: previousSlotModel } = newInsertProps;

			if (draggableInsertProductModel && (previousSlotModel !== slotModel) && isValidInsertSpace) {
				runInAction(() => {
					// clear the previous slot that the insert came from, if applicable
					if (previousSlotModel) {
						// clear the previous adjacent slots
						const { answerForSlots = [] } = previousSlotModel;

						answerForSlots.forEach((slotModelToClear = {}) => {
							workspaceStore.clearSlot(slotModelToClear);
						});

						workspaceStore.clearSlot(previousSlotModel);
					}

					workspaceStore.setInsertProduct({
						insertProductModel: draggableInsertProductModel,
						insertProps: newInsertProps,
						slotModel,
						workspaceProduct,
					});
				});
			}

			return { slotModel };
		},
	});

	const {
		isDragging,
		type,
	} = useDragLayer(monitor => ({
		isDragging: monitor.isDragging(),
		type: monitor.getItemType(),
	}));

	useEffect(() => {
		!isOver && showErrorMessage && workspaceStore.setShowErrorMessage(false);
	}, [isOver]);

	return (
		<div
			className={
				classNames(styles['workspace-product-slot-droppable'], {
					'tw-opacity-0': isHidden,
					'tw-pointer-events-auto': isDragging && type === 'INSERT_PRODUCT',
					[styles['workspace-product-slot-droppable-hover']]: canDrop && isOver && isValidInsertSpace,
					[styles[`workspace-product-slot-droppable-hover-${draggableInsertSpace.replace(' ', '_')}`]]: canDrop && isOver && isValidInsertSpace,
				})
			}
			data-qa={`workspace-product-slot-droppable-${allow}`}
			ref={drop}
			style={{
				height: `${slotHeightPercentage}%`,
				width: `${slotWidthPercentage}%`,
			}}
		>
			{
				showSlotText && !answeredBySlot && !hasInsertProductModel && (
					<span className={`${styles['workspace-product-slot-droppable-text']} tw-hidden md:tw-flex`}>
						Drag & Drop
						<br />
						Insert Here
					</span>
				)
			}
			{
				showSlotPlus && (
					<div className={styles['plus-icon']}>
						{canDrop && isValidInsertSpace && draggableInsertProductModel && <div data-qa="plus-symbol" className={styles['plus-symbol']} />}
					</div>
				)
			}
			{
				hasInsertProductModel && (
					<WorkspaceProductInsertDraggableCasegoods
						imageScale={imageScale}
						insertProps={insertProps}
						insertProductModel={insertProductModel}
						slotModel={slotModel}
					/>
				)
			}
		</div>
	);
});
