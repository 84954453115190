import { computed, makeObservable, observable } from 'mobx';

import { s7ImagePath } from '~/global/global.constants';

class MediaProduct {
	captionOverride

	fileName

	index

	selected

	selectorConfigModel

	type

	constructor() {
		makeObservable(this, {
			caption: computed,
			hasCaption: computed,
			imageUrl: computed,
			mediaImageUrl: computed,
			productAltText: computed,
			productImageUrl: computed,
			selected: observable,
		});
	}

	get caption() {
		const {
			selectedProduct: {
				caption = '',
			} = {},
		} = this.selectorConfigModel;

		return this.captionOverride || caption;
	}

	get hasCaption() {
		const { productsModel = [] } = this.selectorConfigModel;

		return Boolean(this.captionOverride) || productsModel.some(({ caption = '' }) => caption);
	}

	get imageUrl() {
		return `${s7ImagePath}/${this.fileName}`;
	}

	get mediaImageUrl() {
		return this.productImageUrl;
	}

	get productAltText() {
		const {
			selectedProduct: {
				longSalesText = '',
			} = {},
		} = this.selectorConfigModel;

		return longSalesText || this.caption;
	}

	get productImageUrl() {
		const {
			selectedProduct: {
				imageData: {
					imageUrl: productImageUrl = '',
				} = {},
			} = {},
		} = this.selectorConfigModel;

		return productImageUrl || this.imageUrl;
	}

	get productImageUrlWithDimensions() {
		const {
			selectedProduct: {
				imageData: {
					imageUrlWithDimensions: productImageUrlWithDimensions = '',
				} = {},
			} = {},
		} = this.selectorConfigModel;

		return productImageUrlWithDimensions || this.productImageUrl || this.imageUrl;
	}
}

export const MediaProductModelFactory = ({
	create: ({
		caption: captionOverride = '',
		fileName = '',
		index = 0,
		selected = false,
		selectorConfigModel = {},
		type = 'PRODUCT',
	}) => {
		const mediaProduct = new MediaProduct();

		Object.assign(mediaProduct, {
			captionOverride,
			fileName,
			index,
			selected,
			selectorConfigModel,
			type,
		});

		return mediaProduct;
	},
});
