import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { SelectorValueFrameImage } from '~/product/casegoods/selector/Components/frame/SelectorValueFrameImage';

import styles from '#/product/casegoods/selector/frame/selector-value-frame-draggable.module.scss';

export const SelectorValueFrameDraggable = observer((props) => {
	const {
		imageScale = 0,
		imageOverride,
		productModel = {},
		productModel: {
			hasAllowAllSlot = false,
		} = {},
		productModels = [],
		selectedDepthSelector = {},
		title = '',
	} = props;

	const dragItem = {
		productModel,
		productModels,
		selectedDepthSelector,
	};

	const dragItemRef = useRef(dragItem);

	const dataQaSuffix = hasAllowAllSlot ? 'ALL' : 'NONE';

	const [, drag, preview] = useDrag(() => ({
		item: () => dragItemRef.current,
		type: 'FRAME_PRODUCT',
	}));

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	useEffect(() => {
		dragItemRef.current = dragItem;
	});

	return (
		<div
			className={styles['selector-value-frame-draggable']}
			data-qa={`frame-selector-value-draggable-${dataQaSuffix}`}
			ref={drag}
		>
			<SelectorValueFrameImage
				imageScale={imageScale}
				imageOverride={imageOverride}
				productModel={productModel}
				title={title}
			/>
		</div>
	);
});
