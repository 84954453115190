import { observer } from 'mobx-react';
import React from 'react';

import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector.module.scss';

export const SelectorHeadingSwatchCombined = observer((props) => {
	const {
		isActive = false,
		selectedSelector: {
			combinedMaterialSelectorsLabel = '',
			combinedMaterialSelectedSelectorValuesTitle = '',
			label = '',
		} = {},
	} = props;

	return (
		<div
			className="selector-heading-swatch-combined selector-heading"
			data-qa={`${kebabCase(label)}-selector-heading`}
		>
			<div className="selector-heading-label-container">
				<SelectorHeadingLabel label={label} />
				{
					!isActive && (
						<span
							className={styles['selector-heading-count']}
							data-qa={`${kebabCase(label)}-selector-heading-count`}
						>
							{combinedMaterialSelectorsLabel}
						</span>
					)
				}
			</div>
			{
				!isActive && combinedMaterialSelectedSelectorValuesTitle && (
					<span
						className={styles['selector-heading-title']}
						data-qa={`${kebabCase(label)}-selector-heading-title`}
					>
						{combinedMaterialSelectedSelectorValuesTitle}
					</span>
				)
			}
		</div>
	);
});
