import { SelectorHeadingMediumImage } from '~/product/common/selector/Components/medium-image/SelectorHeadingMediumImage';
import { SelectorHeadingTwoColumn } from '~/product/common/selector/Components/two-column/SelectorHeadingTwoColumn';
import { SelectorHeadingRadio } from '~/product/common/selector/Components/radio-vertical/SelectorHeadingRadio';
import { SelectorHeadingFabric } from '~/product/common/selector/Components/fabric/SelectorHeadingFabric';
import { SelectorHeadingDropDown } from '~/product/common/selector/Components/drop-down/SelectorHeadingDropDown';
import { SelectorHeadingStatic } from '~/product/common/selector/Components/static/SelectorHeadingStatic';
import { SelectorHeadingCustomMirrorDimension } from '~/product/common/selector/Components/custom-mirror-dimension/SelectorHeadingCustomMirrorDimension';
import { SelectorHeadingCustomRugDimension } from '~/product/common/selector/Components/custom-rug-dimension/SelectorHeadingCustomRugDimension';
import { SelectorHeadingCustomTableDimension } from '~/product/common/selector/Components/custom-table-dimension/SelectorHeadingCustomTableDimension';

export const selectorHeadingComponents = {
	CUSTOM_DROP_DOWN: SelectorHeadingDropDown,
	CUSTOM_FABRIC_LEATHER: SelectorHeadingFabric,
	CUSTOM_FABRIC_LEATHER_QUESTION_AGGREGATOR: SelectorHeadingFabric,
	DROP_DOWN: SelectorHeadingDropDown,
	FABRIC: SelectorHeadingFabric,
	FABRIC_QUESTION_AGGREGATOR: SelectorHeadingFabric,
	MEDIUM_IMAGE: SelectorHeadingMediumImage,
	RADIO_VERTICAL: SelectorHeadingRadio,
	STATIC: SelectorHeadingStatic,
	CUSTOM_MIRROR_DIMENSION: SelectorHeadingCustomMirrorDimension,
	CUSTOM_RUG_DIMENSION: SelectorHeadingCustomRugDimension,
	CUSTOM_TABLE_DIMENSION: SelectorHeadingCustomTableDimension,
	TWO_COLUMN: SelectorHeadingTwoColumn,
};
