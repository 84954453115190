import { reaction } from 'mobx';
import { useRouter } from 'next/router';
import React from 'react';

import { createMagicDeliveryModel, createMagicDeliveryStore } from '~/components/magic-delivery/Helpers/MagicDelivery.init';
import { MagicPagerStore } from '~/components/magic-pager/Stores/MagicPager.store';
import { MagicTabccordionStore } from '~/components/magic-tabccordion/Stores/magicTabccordion.store';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import {
	curalateProductDataSourceId, curalateProductFanreelId, isEngage, isOnServer,
} from '~/global/global.constants';
import { BreadcrumbModel } from '~/layout/Models/Breadcrumb.model';
import { generateDetailsTabData } from '~/product/common/Data/tabs.data';
import { ErrorModals } from '~/product/common/errors/Components/ErrorModals';
import { ErrorsModel } from '~/product/common/errors/Models/Errors.model';
import { createProductRecoStore } from '~/product/common/Helpers/ProductRecos.init';
import { createProductGroupModel } from '~/product/common/Helpers/ProductGroup.init';
import { createMediaSetModel } from '~/media-set/Helpers/MediaSet.init';
import { createMediaSetDimensionsStore } from '~/product/common/media-set/Helpers/MediaSetDimensions.init';
import { ProductEventsTrackingStoreFactory } from '~/product/common/Stores/ProductEvents.tracking.store';
import { MediaSetStoreFactory } from '~/media-set/Stores/MediaSet.store';
import { ProductCommonsModelFactory } from '~/product/common/Models/ProductCommons.model';
import { createProductSectionalsModels } from '~/product/sectionals/Helpers/ProductsSectionals.init';
import { createMediaSetDetailSectionalsModel } from '~/product/sectionals/media-set/Helpers/MediaSetDetailSectionals.init';
import { createSelectorConfigSectionalsModel } from '~/product/sectionals/selector/Helpers/SelectorConfigSectionals.init';
import { createSelectorMagicTabccordionSectionalsStore } from '~/product/sectionals/selector/Helpers/SelectorTabccordionSectionals.init';
import { SelectorConfigSectionalsStoreFactory } from '~/product/sectionals/selector/Stores/SelectorConfigSectionals.store';
import { SummarySectionalsModelFactory } from '~/product/sectionals/summary/Models/SummarySectionals.model';
import { SummarySectionalsStoreFactory } from '~/product/sectionals/summary/Stores/SummarySectionals.store';
import { createWorkspaceSectionalsModel } from '~/product/sectionals/workspace/Helpers/WorkspaceSectionals.init';
import { WorkspaceSectionalsStoreFactory } from '~/product/sectionals/workspace/Stores/WorkspaceSectionals.store';
import { TrackingDebugModeFactory } from '~/tracking/debug/Tracking.debugMode';
import { LinkEventStoreFactory } from '~/tracking/link-event/Stores/LinkEvent.store';
import { ReviewEventTrackingStore } from '~/tracking/review-event/Stores/ReviewEvent.tracking.store';
import { PowerReviewsStoreFactory } from '~/util/power-reviews/Stores/PowerReviews.store';
import { CuralateStoreFactory } from '~/util/curalate/Stores/Curalate.store';

export const productSectionalsContext = (pageProps) => {
	const {
		pageType,
		printModule,
		productPageModel,
	} = pageProps;

	const router = useRouter();

	const {
		breadcrumbs,
		collectionSearchSegment,
		defaultConfig: defaultConfigData = [],
		defaultOttomanProduct: defaultOttomanProductData = {},
		defaultProduct: defaultProductData = {},
		downloadSelector: downloadSelectorData = {},
		ottomanProductGroup = {},
		ottomanProductPlaceholders: ottomanProductPlaceholdersData = [],
		ottomanSelector: ottomanSelectorData = {},
		productGroup = {},
		productGroup: {
			dimensionMediaSet = [],
			environmentMediaSet = [],
			videoMediaSet = [],
		} = {},
		productPlaceholders: productPlaceholdersData = [],
		productSelector: productSelectorData = {},
		query: {
			activeTabRenderer = null,
			limit,
			error = '',
			question: configurationParams = [],
			selectedOttomanArticleNumber = null,
		} = {},
		questionSelectors: questionSelectorsData = [],
		siblingDefaultConfig: siblingDefaultConfigData = [],
		workspace: workspaceData = {},
		workspace: {
			configuration: configurationData = {},
			selectedOttomanArticle: selectedOttomanArticleNumberData = '',
			selectedProductArticleNumber: selectedProductArticleNumberData = '',
		} = {},
	} = productPageModel;

	const {
		cookies,
		featureTogglesModel,
		globalDynamicModel,
		globalDynamicStore,
		globalStaticModel,
		HREF,
		magicModal,
	} = useGlobalContext();

	const linkEventStore = LinkEventStoreFactory.create(featureTogglesModel);

	const isPreconfiguredWorkspace = Boolean(Object.keys(workspaceData).length);

	const configurationParamsData = configurationParams.map((configurationParam = '') => {
		const configurationParamParts = configurationParam.split(':');

		return {
			answer: configurationParamParts[configurationParamParts.length - 1],
			group: configurationParamParts[0],
		};
	});

	const configurationDataToUse = configurationParamsData.length ? configurationParamsData : configurationData;

	const productGroupModel = createProductGroupModel(productGroup);

	const errorsModel = new ErrorsModel(error);

	const productModels = createProductSectionalsModels({
		defaultProductData,
		productsData: productPlaceholdersData
	});

	const ottomanProductModels = createProductSectionalsModels({
		defaultProductData: defaultOttomanProductData,
		productsData: ottomanProductPlaceholdersData,
	});

	const selectorConfigModel = createSelectorConfigSectionalsModel({
		defaultConfigData,
		defaultOttomanProductData,
		defaultProductData,
		downloadSelectorData,
		featureTogglesModel,
		ottomanProductGroup,
		ottomanProductModels,
		ottomanSelectorData,
		productModels,
		productSelectorData,
		questionSelectorsData,
		siblingDefaultConfigData,
	});

	const selectorConfigStore = SelectorConfigSectionalsStoreFactory.create({
		defaultConfigOverrideModels: configurationDataToUse,
		linkEventStore,
		selectorConfigModel,
		ottomanArticleNumberOverride: selectedOttomanArticleNumberData || selectedOttomanArticleNumber,
		productArticleNumberOverride: selectedProductArticleNumberData,
	});

	const selectorMagicTabccordionStore = createSelectorMagicTabccordionSectionalsStore({
		activeTabRenderer,
		linkEventStore,
		selectorConfigModel,
	});

	const workspaceModel = createWorkspaceSectionalsModel({
		selectorConfigModel,
		selectorMagicTabccordionStore,
	});

	const workspaceStore = WorkspaceSectionalsStoreFactory.create({
		isPreconfiguredWorkspace,
		selectorConfigModel,
		selectorConfigStore,
		workspaceData,
		workspaceModel,
	});

	const workspacePreflightModel = createWorkspaceSectionalsModel({
		selectorConfigModel,
		selectorMagicTabccordionStore,
	});

	const workspacePreflightStore = WorkspaceSectionalsStoreFactory.create({
		selectorConfigModel,
		workspaceModel: workspacePreflightModel,
	});

	const mediaSetDetailModel = createMediaSetDetailSectionalsModel({
		selectorConfigModel,
		workspaceModel,
	});

	const mediaSetDetailStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetDetailModel });

	const mediaSetEnvironmentModel = createMediaSetModel({ mediaSetData: environmentMediaSet });

	const mediaSetEnvironmentStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetEnvironmentModel });

	const mediaSetVideoModel = createMediaSetModel({ mediaSetData: videoMediaSet, featureTogglesModel });

	const mediaSetVideoStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetVideoModel });

	const mediaSetDimensionsModel = createMediaSetModel({ mediaSetData: dimensionMediaSet });

	const mediaSetDimensionsStore = createMediaSetDimensionsStore({
		mediaSetModel: mediaSetDimensionsModel,
		selectorConfigModel,
	});

	const breadcrumbModels = breadcrumbs.map((breadcrumb) => {
		return new BreadcrumbModel(breadcrumb);
	});

	const summaryModel = SummarySectionalsModelFactory.create({
		breadcrumbModels,
		productGroupModel,
		selectorConfigModel,
		workspaceModel,
	});

	const productTrackingStore = ProductEventsTrackingStoreFactory.create(
		summaryModel,
		featureTogglesModel,
	);

	const trackingDebugMode = TrackingDebugModeFactory.create(featureTogglesModel);

	const summaryStore = SummarySectionalsStoreFactory.create({
		featureTogglesModel,
		globalDynamicModel,
		globalDynamicStore,
		globalStaticModel,
		HREF,
		magicModal,
		productTrackingStore,
		summaryModel,
		trackingDebugMode,
	});

	const magicDeliveryModel = createMagicDeliveryModel({
		summaryModel,
		trLinkEventCompType: 'product summary',
		trLinkEventCompName: productGroupModel.name,
	});

	const magicDeliveryStore = createMagicDeliveryStore({
		featureTogglesModel,
		globalDynamicStore,
		magicDeliveryModel,
		summaryStore,
	});

	const {
		selectedProduct: {
			urlSuffix = 'empty',
		} = {},
	} = selectorConfigModel;

	const tabData = generateDetailsTabData();
	const reviewsMagicTabccordionStore = new MagicTabccordionStore(tabData, linkEventStore);
	const powerReviewsStore = PowerReviewsStoreFactory.create(selectorConfigModel, productGroupModel, featureTogglesModel);

	const categoryName = breadcrumbModels.find(breadcrumbModel => breadcrumbModel.isCategory)?.pageTitle;
	const subcategoryBreadcrumb = breadcrumbModels.find(breadcrumbModel => breadcrumbModel.isSubcategory);
	const subcategoryName = subcategoryBreadcrumb?.pageTitle;
	const subcategoryUrl = subcategoryBreadcrumb?.url;
	const lastBreadcrumb = breadcrumbModels[breadcrumbModels.length - 1];
	const lastBreadcrumbUrl = lastBreadcrumb?.url;
	const lastBreadcrumbTitle = lastBreadcrumb?.title;
	const defaultProductUrl = `${lastBreadcrumbUrl}/${urlSuffix}`;

	const recoItemWidth = 212;
	const recoItemHeight = 224;
	const recoPageWidth = recoItemWidth;
	const recoPageHeight = recoItemHeight;
	let productRecosStore;
	let productRecosPagerModel;

	if (!isEngage) {
		productRecosStore = createProductRecoStore(
			'rfkid_32',
			router.asPath,
			{
				globalDynamicModel,
				globalStaticModel,
				featureTogglesModel,
			},
			{
				sku: null,
				title: 'You May Also Like',
				analytics: {
					pageId: 'product',
				},
				maxItems: 18,
				forceNonSkuImage: true,
			},
		);

		productRecosPagerModel = new MagicPagerStore(recoPageWidth, recoPageHeight, recoItemWidth, recoItemHeight);
	}

	const productRecentsStore = createProductRecoStore(
		'rfkid_33',
		'',
		{
			globalDynamicModel,
			globalStaticModel,
			featureTogglesModel,
		},
		{
			sku: [],
			title: 'Recently Viewed',
			analytics: {
				pageId: 'product2_recently_viewed',
			},
			maxItems: 18,
		},
	);
	const productRecentsPagerModel = new MagicPagerStore(recoPageWidth, recoPageHeight, recoItemWidth, recoItemHeight);

	if (!isOnServer && productRecosStore) {
		const rfkSkuKeyReaction = reaction(
			() => summaryModel.reflektionSkuKeys,
			(reflektionSkuKeys) => {
				const value = Array.isArray(reflektionSkuKeys) ? reflektionSkuKeys : [reflektionSkuKeys];
				productRecosStore.model.setSku(value);
				rfkSkuKeyReaction();
			},
		);
	}

	const curalateStore = CuralateStoreFactory.create(cookies, magicModal, featureTogglesModel, {
		dataSourceId: curalateProductDataSourceId,
		experienceId: 'product-page-experience',
		fanreelId: curalateProductFanreelId,
		filter: `productId:${productGroupModel.ugcProductGroupId}`,
		limit,
		itemsPerPage: 5,
	});

	const productCommons = ProductCommonsModelFactory.create({
		breadcrumbModels,
		printModule,
		productGroupModel,
		summaryModel,
		selectorConfigModel,
		collectionSearchSegment,
		type: pageType,
	});

	if (!isOnServer && errorsModel.componentKey) {
		const Component = ErrorModals[errorsModel.componentKey];

		if (Component) {
			magicModal.openModal({
				id: 'product-error-modal',
				title: errorsModel.modalTitle,
				width: '90vw',
				maxWidth: '600px',
				content: {
					children: (
						<Component
							productGroupModel={productGroupModel}
							// selectorConfigModel={selectorConfigModel}
							goBackFunction={magicModal.closeModal}
						/>
					),
				},
			});
		}
	}

	if (!isOnServer && !isEngage) {
		const reviewEventTracking = new ReviewEventTrackingStore();
		const {
			reviewProductGroupKey = '',
			reviewsAverageOverallRating = 0,
			reviewsCount = 0,
		} = productGroupModel;
		reviewEventTracking.trackReviewStats(reviewProductGroupKey, reviewsAverageOverallRating, reviewsCount);
	}

	// roll up product stores and models here
	const context = {
		breadcrumbModels,
		categoryName,
		curalateStore,
		defaultProductUrl,
		errorsModel,
		lastBreadcrumbTitle,
		magicDeliveryModel,
		magicDeliveryStore,
		mediaSetDetailModel,
		mediaSetDetailStore,
		mediaSetEnvironmentModel,
		mediaSetEnvironmentStore,
		mediaSetVideoModel,
		mediaSetVideoStore,
		mediaSetDimensionsModel,
		mediaSetDimensionsStore,
		powerReviewsStore,
		productCommons,
		productGroupModel,
		productModels,
		productPageModel,
		productRecentsStore,
		productRecentsPagerModel,
		productRecosPagerModel,
		productRecosStore,
		productTrackingStore,
		reviewsMagicTabccordionStore,
		selectorConfigModel,
		selectorConfigStore,
		selectorMagicTabccordionStore,
		subcategoryName,
		subcategoryUrl,
		summaryModel,
		summaryStore,
		workspaceModel,
		workspaceStore,
		workspacePreflightModel,
		workspacePreflightStore,
	};

	return context;
};

export const ProductSectionalsContextProvider = ({
	pageProps = {},
	pageProps: {
		children,
	} = {},
	productContext = {},
}) => {
	return <productContext.Provider value={productSectionalsContext(pageProps)}>{children}</productContext.Provider>;
};
