/* eslint-disable jsx-a11y/no-static-element-interactions */
import { observer } from 'mobx-react';
import React from 'react';

import styles from '#/product/casegoods/workspace/product/workspace-product-width.module.scss';

export const WorkspaceProductWidthCasegoods = observer((props) => {
	const {
		workspaceProduct: {
			workspaceProductWidth = 0,
		} = {},
	} = props;

	return <>
		<div className={`${styles['workspace-product-width']} workspace-product-width`} data-qa="workspace-product-width">
			<span className={styles['workspace-product-width-value']}>
				{workspaceProductWidth}"
			</span>
		</div>
		<div className={styles['workspace-product-width-drag-area']} />
	</>;
});
