import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

export const QuestionAndAnswers = () => {
	const {
		powerReviewsStore: {
			QA_DISPLAY_ID,
		},
	} = useProductContext();

	return [
		<div key={QA_DISPLAY_ID} id={QA_DISPLAY_ID} data-tr-link-event-track={false} />,
	];
};
