import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { SelectorOptionsFrame } from '~/product/casegoods/selector/Components/options/SelectorOptionsFrame';
import { SelectorOptionsInserts } from '~/product/casegoods/selector/Components/options/SelectorOptionsInserts';

import styles from '~/product/casegoods/selector/Components/options/selector-options.module.scss';

export const SelectorOptions = observer((props = {}) => {
	const {
		selectedSelector: {
			selectorValues = [],
		} = {},
	} = props;

	const { selectors = [] } = selectorValues[0] || {};

	const {
		workspaceModel: {
			workspaceProducts = [],
		} = {},
	} = useProductContext();

	const [firstWorkspaceProduct = {}] = workspaceProducts;

	const { slotModelsWithInserts = [] } = firstWorkspaceProduct;

	return (
		<>
			{
				Boolean(slotModelsWithInserts.length) && (
					<div className={styles['selector-options-type']}>
						<div className={styles['selector-options-type-title']}>
							Insert Options
						</div>
						<span className={styles['selector-options-type-description']}>
							<span className="md:tw-hidden">Tap</span><span className="tw-hidden md:tw-inline">Click</span> an insert on your design to choose optional features. Options are saved but not shown in image.
						</span>
						<SelectorOptionsInserts slotModels={slotModelsWithInserts} />
					</div>
				)
			}
			{
				Boolean(selectors.length) && (
					<div className={styles['selector-options-type']}>
						<div className={styles['selector-options-type-title']}>
							Cabinet Options
						</div>
						<span className={styles['selector-options-type-description']}>
							Choose optional features for your cabinet. Options are saved but not shown in image.
						</span>
						<SelectorOptionsFrame selectors={selectors} />
					</div>
				)
			}
		</>
	);
});
