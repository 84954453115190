import type { SelectorFrame } from '~/product/casegoods/selector/Models/SelectorFrame.model';
import type { SelectorConfigCasegoods } from '~/product/casegoods/selector/Models/SelectorConfigCasegoods.model';
import type { SelectorConfigCasegoodsStore } from '~/product/casegoods/selector/Stores/SelectorConfigCasegoods.store';

import { DefaultConfigModelFactory } from '~/product/casegoods/Models/DefaultConfig.model';
import { createSelectorFrameModel } from '~/product/casegoods/selector/Helpers/SelectorFrame.init';
import { SelectorConfigCasegoodsModelFactory } from '~/product/casegoods/selector/Models/SelectorConfigCasegoods.model';
import { SelectorConfigCasegoodsStoreFactory } from '~/product/casegoods/selector/Stores/SelectorConfigCasegoods.store';
import { SelectorFrameModalModelFactory } from '~/product/casegoods/selector/Models/SelectorFrameModal.model';
import { createWorkspaceProductCasegoodsModel } from '~/product/casegoods/workspaces/defaultWorkspace/Helpers/WorkspaceProductCasegoods.init';
import { WorkspaceProductCasegoodsStoreFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Stores/WorkspaceProductCasegoods.store';

const createSelectorConfigFrameModalModel = ({
	defaultConfig,
	defaultnsertConfig,
	defaultProduct,
	selectedSelector: {
		productModels,
		selectorData,
	},
	selectorConfigModel,
	selectorConfigModel: {
		customQuestionAnswersData = {},
		defaultConfigModel = {},
		defaultInsertConfigModel = {},
	},
}: {
	defaultConfig: any,
	defaultnsertConfig: any,
	defaultProduct: any,
	selectedSelector: SelectorFrame,
	selectorConfigModel: SelectorConfigCasegoods,
}) => {
	const defaultConfigModelToUse = defaultConfig ? DefaultConfigModelFactory.create({ config: defaultConfig }) : defaultConfigModel;

	const defaultInsertConfigModelToUse = defaultnsertConfig ? DefaultConfigModelFactory.create({ config: defaultnsertConfig }) : defaultInsertConfigModel;

	// create a private frameSelector model stub for the modal, so we don't mutate the main selector tree
	const frameSelectorModel = createSelectorFrameModel({
		customQuestionAnswersData,
		productModels,
		selectorData,
	});

	return SelectorConfigCasegoodsModelFactory.create({
		...selectorConfigModel,
		defaultConfigModel: defaultConfigModelToUse,
		defaultInsertConfigModel: defaultInsertConfigModelToUse,
		defaultProduct,
		frameSelectorModel,
		productModels,
	});
};

const createSelectorConfigFrameModalStore = ({
	selectorConfigFrameModalModel,
	selectorConfigStore,
}: {
	selectorConfigFrameModalModel: SelectorConfigCasegoods,
	selectorConfigStore: SelectorConfigCasegoodsStore,
}) => {
	return SelectorConfigCasegoodsStoreFactory.create({
		...selectorConfigStore,
		selectorConfigModel: selectorConfigFrameModalModel,
	});
};

export const createSelectorFrameModalModel = ({
	defaultConfig,
	defaultnsertConfig,
	defaultProduct,
	productGroupModel,
	selectedSelector,
	selectorConfigModel,
	selectorConfigStore,
	workspaceProductImageData,
	workspaceProductIndex,
}: {
	defaultConfig: any,
	defaultnsertConfig: any,
	defaultProduct: any,
	productGroupModel: any,
	selectedSelector: SelectorFrame,
	selectorConfigModel: SelectorConfigCasegoods,
	selectorConfigStore: SelectorConfigCasegoodsStore,
	workspaceProductImageData?: any,
	workspaceProductIndex: number,
}) => {
	const selectorConfigFrameModalModel = createSelectorConfigFrameModalModel({
		defaultConfig,
		defaultnsertConfig,
		defaultProduct,
		selectedSelector,
		selectorConfigModel,
	});

	const { questionAnswerSelectors = [] } = selectorConfigFrameModalModel;

	questionAnswerSelectors.forEach((questionAnswerSelector = {}) => {
		const { selectorValues = [] } = questionAnswerSelector;

		selectorValues.forEach((selectorValue: any = {}) => {
			selectorValue.selected = false;
		});

		questionAnswerSelector.selected = false;
	});

	const workspaceProductFrameModal = createWorkspaceProductCasegoodsModel({
		selectorConfigFrameModalModel,
		workspaceProductImageData,
		workspaceProductIndex,
	});

	const workspaceProductStoreFrameModal = WorkspaceProductCasegoodsStoreFactory.create({
		productGroupModel,
		workspaceProductModel: workspaceProductFrameModal,
	});

	const selectorConfigFrameModalStore = createSelectorConfigFrameModalStore({
		selectorConfigFrameModalModel,
		selectorConfigStore,
	});

	return SelectorFrameModalModelFactory.create({
		productGroupModel,
		selectorConfigFrameModalModel,
		selectorConfigFrameModalStore,
		workspaceProductImageData,
		workspaceProductIndex,
		workspaceProductFrameModal,
		workspaceProductStoreFrameModal,
	});
};
