import { computed, observable, makeObservable } from 'mobx';

import type { IProductSectionals } from '~/product/sectionals/Interfaces/ProductSectionals.interface';
import type { ISelectorQuestion } from '~/product/sectionals/selector/Interfaces/SelectorQuestion.interface';
import type { ISelectorValueQuestion } from '~/product/sectionals/selector/Interfaces/SelectorValueQuestion.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

class SelectorQuestionModel implements ISelectorQuestion {
	displayOrder: number;

	filtersMagicTabccordionStore: any;

	filtersModel: any;

	filtersStore: any;

	isValid: boolean;

	label: string;

	questionGroups: Array<string>;

	renderer: string;

	selected: boolean;

	selectedProduct: IProductSectionals;

	selectorValues: Array<ISelectorValueQuestion>;

	showLinks: string[];

	type: string;

	showSelectorDetails: boolean;

	workspaceProductModels: Array<IWorkspaceProductSectionals>;

	constructor({
		displayOrder = 0,
		filtersMagicTabccordionStore = {},
		filtersModel = {},
		filtersStore = {},
		isValid = false,
		label = '',
		questionGroups = [],
		renderer = '',
		selected = false,
		selectedProduct = {},
		selectorValues = [],
		showLinks = [],
		type = '',
		showSelectorDetails = false,
		workspaceProductModels = [],
	}: any = {}) {
		makeObservable(this, {
			isValid: observable,
			selected: observable,
			selectedProduct: observable.ref,
			workspaceProductModels: observable.ref,
			isAggregatedQuestionSelector: computed,
			filteredSelectorValues: computed,
			isValidSelectedSelectorValue: computed,
			selectedSelectorValue: computed,
			selectorValuesCount: computed,
			selectorValuesToRender: computed,
			spoSelectorValues: computed,
			spoSelectorValuesByPriceGroup: computed,
			spoSelectorValuesCount: computed,
			stockedSelectorValues: computed,
			stockedSelectorValuesByPriceGroup: computed,
			stockedSelectorValuesCount: computed,
			validFilteredSelectorValues: computed,
		});

		this.displayOrder = displayOrder;
		this.filtersMagicTabccordionStore = filtersMagicTabccordionStore;
		this.filtersModel = filtersModel;
		this.filtersStore = filtersStore;
		this.isValid = isValid;
		this.label = label;
		this.questionGroups = questionGroups;
		this.renderer = renderer;
		this.selected = selected;
		this.selectedProduct = selectedProduct;
		this.selectorValues = selectorValues;
		this.showLinks = showLinks;
		this.type = type;
		this.showSelectorDetails = showSelectorDetails;
		this.workspaceProductModels = workspaceProductModels;
	}

	get filteredSelectorValues(): Array<ISelectorValueQuestion> {
		const { filteredSelectorValues = [] } = this.filtersModel;

		return filteredSelectorValues;
	}

	get isAggregatedQuestionSelector(): boolean {
		const {
			questionToAggregateModel: {
				group: questionGroupToAggregate = '',
			} = {},
		} = this.selectedProduct;

		return this.questionGroups.includes(questionGroupToAggregate);
	}

	get isValidSelectedSelectorValue(): boolean {
		return this.validFilteredSelectorValues.includes(this.selectedSelectorValue);
	}

	get selectedSelectorValue(): ISelectorValueQuestion {
		return this.selectorValues.find(({ selected = false }) => selected) || this.selectorValues[0];
	}

	get selectorValuesCount(): number {
		return this.selectorValuesToRender.length;
	}

	get selectorValuesToRender(): Array<ISelectorValueQuestion> {
		if (this.isAggregatedQuestionSelector) {
			return this.filteredSelectorValues;
		}

		return this.validFilteredSelectorValues;
	}

	get spoSelectorValues(): Array<ISelectorValueQuestion> {
		return this.selectorValuesToRender.filter(({ stocked = false }) => !stocked);
	}

	get spoSelectorValuesByPriceGroup(): any {
		if (this.workspaceProductModels.length) {
			return this.spoSelectorValues.reduce((accumulatedPriceGroups: any, selectorValue) => {
				const { pricesByArticle = {} } = selectorValue;

				const combinedPriceGroupPrice = this.workspaceProductModels.reduce((accumulatedPriceGroupPrice: number, {
					productModel: {
						articleNumber,
						nonCustomArticles = [],
						pricing: {
							base = 0,
						},
						upcharge = 0,
					},
				}: IWorkspaceProductSectionals) => {
					const matchingNonCustomArticleNumber = nonCustomArticles.find(nonCustomArticleNumber => pricesByArticle[nonCustomArticleNumber]) || '';

					const priceGroup = pricesByArticle[matchingNonCustomArticleNumber] || pricesByArticle[articleNumber] || {};

					const priceGroupPrice = (priceGroup.price || base) + upcharge;

					return accumulatedPriceGroupPrice + priceGroupPrice;
				}, 0);

				(accumulatedPriceGroups[combinedPriceGroupPrice] = accumulatedPriceGroups[combinedPriceGroupPrice] || []).push(selectorValue);

				return accumulatedPriceGroups;
			}, {});
		}

		return this.spoSelectorValues.reduce((accumulatedPriceGroups: any, selectorValue) => {
			const { pricesByArticle = {} } = selectorValue;

			const {
				articleNumber,
				nonCustomArticles = [],
				pricing: {
					base = 0,
				},
				upcharge = 0,
			} = this.selectedProduct;

			const matchingNonCustomArticleNumber = nonCustomArticles.find(nonCustomArticleNumber => pricesByArticle[nonCustomArticleNumber]) || '';

			const priceGroup = pricesByArticle[matchingNonCustomArticleNumber] || pricesByArticle[articleNumber] || {};

			const priceGroupPrice = (priceGroup.price || base) + upcharge;

			(accumulatedPriceGroups[priceGroupPrice] = accumulatedPriceGroups[priceGroupPrice] || []).push(selectorValue);

			return accumulatedPriceGroups;
		}, {});
	}

	get spoSelectorValuesCount(): number {
		return this.spoSelectorValues.length;
	}

	get stockedSelectorValues(): Array<ISelectorValueQuestion> {
		return this.selectorValuesToRender.filter(({ stocked = false }) => stocked);
	}

	get stockedSelectorValuesByPriceGroup(): any {
		if (this.workspaceProductModels.length) {
			return this.stockedSelectorValues.reduce((accumulatedPriceGroups: any, selectorValue) => {
				const { pricesByArticle = {} } = selectorValue;

				const combinedPriceGroupPrice = this.workspaceProductModels.reduce((accumulatedPriceGroupPrice: number, {
					productModel: {
						articleNumber,
						nonCustomArticles = [],
						pricing: {
							base = 0,
						},
						upcharge = 0,
					},
				}: IWorkspaceProductSectionals) => {
					const matchingNonCustomArticleNumber = nonCustomArticles.find(nonCustomArticleNumber => pricesByArticle[nonCustomArticleNumber]) || '';

					const priceGroup = pricesByArticle[matchingNonCustomArticleNumber] || pricesByArticle[articleNumber] || {};

					const priceGroupPrice = (priceGroup.price || base) + upcharge;

					return accumulatedPriceGroupPrice + priceGroupPrice;
				}, 0);

				(accumulatedPriceGroups[combinedPriceGroupPrice] = accumulatedPriceGroups[combinedPriceGroupPrice] || []).push(selectorValue);

				return accumulatedPriceGroups;
			}, {});
		}

		return this.stockedSelectorValues.reduce((accumulatedPriceGroups: any, selectorValue) => {
			const { pricesByArticle = {} } = selectorValue;

			const {
				articleNumber,
				nonCustomArticles = [],
				pricing: {
					base = 0,
				},
				upcharge = 0,
			} = this.selectedProduct;

			const matchingNonCustomArticleNumber = nonCustomArticles.find(nonCustomArticleNumber => pricesByArticle[nonCustomArticleNumber]) || '';

			const priceGroup = pricesByArticle[matchingNonCustomArticleNumber] || pricesByArticle[articleNumber] || {};

			const priceGroupPrice = (priceGroup.price || base) + upcharge;

			(accumulatedPriceGroups[priceGroupPrice] = accumulatedPriceGroups[priceGroupPrice] || []).push(selectorValue);

			return accumulatedPriceGroups;
		}, {});
	}

	get stockedSelectorValuesCount(): number {
		return this.stockedSelectorValues.length;
	}

	get validFilteredSelectorValues(): Array<ISelectorValueQuestion> {
		const { questionModels = [] } = this.selectedProduct;

		return this.filteredSelectorValues.filter(({ questionAnswers = [] }) => {
			return questionAnswers.every(({
				answer = '',
				group = '',
			}) => {
				const matchingQuestionModel = questionModels.find(({ group: questionGroup = '' }) => group === questionGroup);

				if (matchingQuestionModel) {
					const { answersModel = [] } = matchingQuestionModel;

					return answersModel.some(({ key = '' }) => answer === key);
				}

				return false;
			});
		});
	}
}

export const SelectorQuestionModelFactory = ({
	create: (data: any = {}) => {
		return new SelectorQuestionModel(data);
	},
});
