import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceModalFrameControlsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameControlsCasegoods';
import { WorkspaceModalFrameControlWidthCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameControlWidthCasegoods';
import { WorkspaceModalFrameControlDoorCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameControlDoorCasegoods';

import styles from '#/product/casegoods/workspace/modal-frame/workspace-modal-frame-attributes.module.scss';

export const WorkspaceModalFrameAttributesCasegoods = observer(() => {
	const {
		workspaceModalFrameModel: {
			doorSelectorValues = [],
			insertProductModel: {
				height: insertHeight = 0,
			} = {},
			workspaceProductModalFrameModel: {
				frameProductModel: {
					height: frameHeight = 0,
				} = {},

			} = {},
		} = {},
	} = useProductContext();

	return (
		<div className={styles['workspace-modal-frame-attributes']}>
			<div className={styles['workspace-modal-frame-attribute']}>
				<span className={styles['workspace-modal-frame-attribute-label']}>Height: </span>
				<span data-qa="frame-modal-height">{frameHeight || insertHeight} inches</span>
			</div>
			<div className={styles['workspace-modal-frame-attribute-width']}>
				<span className={styles['workspace-modal-frame-attribute-label']}>Width: </span>
				<WorkspaceModalFrameControlWidthCasegoods />
			</div>
			<div className={styles['workspace-modal-frame-attribute']}>
				<span className={styles['workspace-modal-frame-attribute-label']}>Depth: </span>
				<WorkspaceModalFrameControlsCasegoods />
			</div>
			{
				Boolean(doorSelectorValues.length) && (
					<div className={styles['workspace-modal-frame-attribute']}>
						<WorkspaceModalFrameControlDoorCasegoods />
					</div>
				)
			}
		</div>
	);
});
