import React from 'react';

const defaultDimensionsWorkspaceCasegoods = {
	imageInchesToPixelsFactor: 30,
	offsetBottomInches: 5.75,
	offsetTopInches: 1,
	offsetXInches: 0.75,
	slotPaddingInches: 0.25,
	translateBottomInches: 0,
};

const dimensionsWorkspaceCasegoods = {
	beam: {
		...defaultDimensionsWorkspaceCasegoods,
		imageInchesToPixelsFactor: 37,
		offsetBottomInches: 12,
		offsetTopInches: 6,
		offsetXInches: 0,
	},
	copenhagen: {
		...defaultDimensionsWorkspaceCasegoods,
		imageInchesToPixelsFactor: 40,
		offsetBottomInches: 5.5,
		offsetTopInches: 0.5,
		offsetXInches: 0.5,
		slotPaddingInches: 0.5,
	},
	DEFAULT: defaultDimensionsWorkspaceCasegoods,
	hudson: {
		...defaultDimensionsWorkspaceCasegoods,
		offsetBottomInches: 6.5,
		offsetTopInches: 0.25,
		offsetXInches: 0.25,
	},
	keaton: {
		...defaultDimensionsWorkspaceCasegoods,
		imageInchesToPixelsFactor: 43,
	},
	linear: {
		...defaultDimensionsWorkspaceCasegoods,
		offsetBottomInches: 5.75,
		offsetTopInches: 1,
		offsetXInches: 0.75,
	},
	slim: {
		...defaultDimensionsWorkspaceCasegoods,
		imageInchesToPixelsFactor: 40,
		offsetBottomInches: 6,
		offsetTopInches: 0.35,
		offsetXInches: 0.25,
		slotPaddingInches: 0.2,
	},
	taylor: {
		...defaultDimensionsWorkspaceCasegoods,
		imageInchesToPixelsFactor: 50,
		translateBottomInches: 0.75,
	},
	woodwind: {
		...defaultDimensionsWorkspaceCasegoods,
		imageInchesToPixelsFactor: 41.5,
	},
};

const defaultImageScaleWorkspaceCasegoods = {
	canvas: 1.85,
	medium: 7.23,
	modal: 14.45,
	small: 15.65,
	thumbnail: 77.5,
};

const imageScaleWorkspaceCasegoods = {
	beam: { ...defaultImageScaleWorkspaceCasegoods, small: 8 },
	copenhagen: {
		...defaultImageScaleWorkspaceCasegoods,
		small: 12.5,
	},
	DEFAULT: { ...defaultImageScaleWorkspaceCasegoods },
	hudson: {
		...defaultImageScaleWorkspaceCasegoods,
		medium: 6.5,
		small: 9,
	},
	keaton: { ...defaultImageScaleWorkspaceCasegoods },
	linear: {
		...defaultImageScaleWorkspaceCasegoods,
		small: 10,
	},
	slim: { ...defaultImageScaleWorkspaceCasegoods },
	taylor: { ...defaultImageScaleWorkspaceCasegoods },
	woodwind: { ...defaultImageScaleWorkspaceCasegoods },
};

const defaultImageSettingsWorkspaceCasegoods = {
	fullScreen: {
		defaultImageScale: 2,
		workspaceHeight: 560,
		workspaceWidth: 760,
	},
	medium: {
		defaultImageScale: 3,
		workspaceHeight: 480,
		workspaceWidth: 682,
	},
	small: {
		defaultImageScale: 5,
		workspaceHeight: 275,
		workspaceWidth: 292,
	},
	thumbnail: {
		defaultImageScale: 77.5,
		workspaceHeight: 40,
		workspaceWidth: 75,
	},
};

export const imageSettingsWorkspaceCasegoods = {
	beam: { ...defaultImageSettingsWorkspaceCasegoods },
	copenhagen: { ...defaultImageSettingsWorkspaceCasegoods },
	DEFAULT: { ...defaultImageSettingsWorkspaceCasegoods },
	hudson: { ...defaultImageSettingsWorkspaceCasegoods },
	keaton: { ...defaultImageSettingsWorkspaceCasegoods },
	linear: { ...defaultImageSettingsWorkspaceCasegoods },
	slim: { ...defaultImageSettingsWorkspaceCasegoods },
	taylor: {
		...defaultImageSettingsWorkspaceCasegoods,
		medium: {
			defaultImageScale: 5,
			workspaceHeight: 480,
			workspaceWidth: 682,
		},
		small: {
			defaultImageScale: 8,
			workspaceHeight: 275,
			workspaceWidth: 292,
		},
	},
	woodwind: { ...defaultImageSettingsWorkspaceCasegoods },
};

const defaultDownloadImageWorkspaceCasegoods = {
	compression: 0.7,
	mimeType: 'image/jpg',
	fileName: 'CASEGOODS.jpg',
	slotPaddingXMult: 0,
	slotPaddingYMult: 0,
};

const downloadImageWorkspaceCasegoods = {
	beam: {
		...defaultDownloadImageWorkspaceCasegoods,
		fileName: 'BEAM.jpg',
		slotPaddingXMult: 28,
		slotPaddingYMult: 14,
	},
	copenhagen: { ...defaultDownloadImageWorkspaceCasegoods },
	DEFAULT: { ...defaultDownloadImageWorkspaceCasegoods },
	hudson: { ...defaultDownloadImageWorkspaceCasegoods },
	keaton: { ...defaultDownloadImageWorkspaceCasegoods },
	linear: { ...defaultDownloadImageWorkspaceCasegoods },
	slim: {
		...defaultDownloadImageWorkspaceCasegoods,
		fileName: 'SLIM.jpg',
		slotPaddingXMult: 26,
		slotPaddingYMult: 6,
	},
	taylor: {
		...defaultDownloadImageWorkspaceCasegoods,
		fileName: 'TAYLOR.jpg',
	},
	woodwind: { ...defaultDownloadImageWorkspaceCasegoods },
};

const settingsWorkspaceCasegoods = {
	CUSTOM_BOOKCASE: {
		detailsProductHeadingTitle: 'Bookcase',
		frameFirstBuilder: true,
		maxWorkspaceProducts: 3,
		replaceWorkspaceProducts: false,
		showAllDimensionsTabTypes: ['SIZE'],
		showDepth: false,
		showDimensionsTabTypes: ['SIZE'],
		showFramePrice: true,
		showHelpControl: false,
		showInsertMessage: false,
		showInsertOptionsEdit: false,
		showMaxWorkspaceProductsWarning: true,
		showSlotText: false,
		showThumbnailsControl: true,
	},
	CUSTOM_CABINET: {
		hideControls: true,
		frameFirstBuilder: true,
		insertMessage: <><div className="md:tw-hidden">Drag and drop inserts onto your cabinet.</div>Every space must have an insert.</>,
		maxWorkspaceProducts: 1,
		replaceWorkspaceProducts: true,
		showDepth: true,
		showDimensionsTabTypes: ['SIZE'],
		showFramePrice: true,
		showHelpControl: true,
		showInsertMessage: true,
		showInsertOptionsEdit: true,
		showMaxWorkspaceProductsWarning: false,
		showSlotText: true,
		showThumbnailsControl: false,
	},
	MODULAR_BOOKCASE: {
		detailsProductHeadingTitle: 'Unit',
		detailsProductInsertHeadingTitle: 'Insert',
		frameFirstBuilder: true,
		hideInsertMessageDesktop: true,
		insertMessage: 'Drag and drop inserts onto your frame(s).',
		maxWorkspaceProducts: 3,
		replaceWorkspaceProducts: false,
		showDimensionsTabTypes: ['SIZE', 'WIDTH', 'INSERT', 'DOWNLOAD'],
		showHelpControl: true,
		showInsertMessage: true,
		showInsertOptionsEdit: false,
		showMaxWorkspaceProductsWarning: true,
		showSlotText: false,
		showSlotPlus: true,
		showThumbnailsControl: true,
	},
	STACK: {
		acceptDraggableInsertProductOnEmptyWorkspace: true,
		detailsProductHeadingTitle: 'Unit',
		detailsProductInsertHeadingTitle: 'Piece',
		frameFirstBuilder: false,
		insertMessage: 'Each unit comes with a .75" finished top and 4" base.',
		maxWorkspaceProducts: 3,
		renderWorkspaceProductsAsStacks: true,
		showHelpControl: true,
		showDimensionsTabTypes: ['WIDTH', 'DOWNLOAD'],
		showInsertMessage: true,
		showMaxWorkspaceProductsWarning: true,
	}
};

export const productGroupOverrideSettingsWorkspaceCasegoods = (key) => {
	switch (key) {
		case 'BEAM_STORAGE_BUILDER':
			return {
				showAllDimensionsTabTypes: ['SIZE', 'DOWNLOAD'],
				showDimensionsTabTypes: ['SIZE', 'DOWNLOAD', 'INSERT'],
				showFramePrice: false,
				dragPreviewLineDrawingImage: true,
			};
		default:
			return {};
	}
};

export {
	dimensionsWorkspaceCasegoods,
	imageScaleWorkspaceCasegoods,
	downloadImageWorkspaceCasegoods,
	settingsWorkspaceCasegoods,
};
