import { observer } from 'mobx-react';
import React from 'react';

import { addToUrl } from '~/util/addToUrl';

export const WorkspaceProductFrameImageCasegoods = observer((props: {
	imageScale?: number,
	overrideImageUrl?: string,
	workspaceProduct: {
		frameProductModel: {
			imageUrl: string,
			salesText: string,
		},
		workspaceProductImageUrl: string,
	}
}) => {
	const {
		imageScale = 0,
		overrideImageUrl = '',
		workspaceProduct: {
			frameProductModel: {
				imageUrl = '',
				salesText = '',
			} = {},
			workspaceProductImageUrl = '',
		} = {},
	} = props;

	const imageUrlToUse = overrideImageUrl || workspaceProductImageUrl || imageUrl;

	const srcParams = `$proddd$&scl=${imageScale}`;

	const srcSetParams = `$proddd$&scl=${imageScale / 2}`;

	const srcAttr = addToUrl(imageUrlToUse, srcParams);

	const srcSetAttr = addToUrl(imageUrlToUse, srcSetParams);

	return (
		<img
			alt={salesText}
			className="tw-user-drag-none tw-user-select-none"
			data-qa="workspace-frame-image"
			draggable={false}
			src={srcAttr}
			srcSet={`${srcSetAttr} 2x`}
		/>
	);
});
