import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { WorkspaceProductInsertImageCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-insert/WorkspaceProductInsertImageCasegoods';
import { WorkspaceModalInsertOptionsControlsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-insert-options/WorkspaceModalInsertOptionsControlsCasegoods';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-insert-options/modal-insert-options.module.scss';

export const WorkspaceModalInsertOptionsCasegoods = observer((props = {}) => {
	const { imageScale = 0 } = props;

	const { magicModal = {} } = useGlobalContext();

	const {
		selectorConfigStore = {},
		workspaceModalInsertOptionsModel = {}
	} = useProductContext();

	const {
		selectedSelectorValues = [],
		slotModel = {},
		slotModel: {
			insertOptionsSelectors = [],
			insertProductModel = {},
			insertProductModel: {
				subtitle = '',
				title = '',
			} = {},
		} = {},
	} = workspaceModalInsertOptionsModel;

	function handleClick() {
		runInAction(() => {
			selectedSelectorValues
				.filter(({ hasOverrideQuestionAnswers = false }) => hasOverrideQuestionAnswers)
				.forEach(({ overrideQuestionAnswers = {} }) => {
					selectorConfigStore.overrideOptionsSelectorValues(overrideQuestionAnswers);
				});

			slotModel.insertOptionsSelectorValues = selectedSelectorValues;
		});

		magicModal.closeModal();
	}

	return (
		<div className={styles['workspace-modal-insert-options']}>
			<div className={styles['workspace-modal-insert-options-image']}>
				<WorkspaceProductInsertImageCasegoods
					imageScale={imageScale}
					insertProductModel={insertProductModel}
				/>
			</div>
			<div className={styles['workspace-modal-insert-options-title-container']}>
				<div
					className={styles['workspace-modal-insert-options-title']}
					data-qa="workspace-modal-insert-options-title"
				>
					{title}
				</div>
				{
					subtitle && (
						<div
							className={styles['workspace-modal-insert-options-subtitle']}
							data-qa="workspace-modal-insert-options-subtitle'"
						>
							{subtitle}
						</div>
					)
				}
			</div>
			<WorkspaceModalInsertOptionsControlsCasegoods
				insertOptionsSelectors={insertOptionsSelectors}
				insertOptionsSelectorValues={selectedSelectorValues}
			/>
			<div className="ButtonGroup">
				<button
					className="Button"
					data-qa="workspace-modal-insert-options-save"
					onClick={handleClick}
				>
					Save Options
				</button>
				<button
					className="ButtonAnchor"
					data-qa="workspace-modal-insert-options-cancel"
					onClick={magicModal.closeModal}
				>
					Cancel
				</button>
			</div>
		</div>
	);
});
