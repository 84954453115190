import React from 'react';
import { observer } from 'mobx-react';

import { deliveryMessages as messageMap } from '~/components/magic-delivery/Components/deliveryMessages';

export const DeliveryMessaging = observer((props) => {
	const {
		store = {},
		store: {
			magicDeliveryModel: {
				shippingMethod = null,
				contentAreaKey = null,
				zipCode = null,
				deliveryMessages = [],
			} = {},
		} = {},
	} = props;

	if (!deliveryMessages.length) {
		return null;
	}

	const dataQa = ['delivery', shippingMethod];

	if (zipCode) {
		dataQa.push('with-zip');
	} else {
		dataQa.push('without-zip');
	}
	dataQa.push(contentAreaKey);

	return (
		<p className="tw-mb-0" data-qa={dataQa.filter(Boolean).join(' ')}>
			{
				deliveryMessages.map((deliveryMessage, i) => {
					const { messageKey, messageValues = {} } = deliveryMessage;
					const DeliveryMessageComponent = messageMap[messageKey];

					if (!DeliveryMessageComponent) {
						console.warn(`Missing Delivery Message component for message key: ${messageKey}`);
						return null;
					}

					return (
						<span key={`delivery-message-${i}`} data-qa="product-summary-leadTime">
							<DeliveryMessageComponent store={store} values={messageValues} />
						</span>
					);
				})
			}
		</p>
	);
});
