import { IStyliticsClickJSON } from '~/tracking/vendor-events/stylitics/style-click/Interfaces/IStyliticsClickEvent.tracking';
import { sanitize } from '~/util/sanitizeString';
import { VendorEventTrackingModel } from '~/tracking/vendor-events/common/Models/VendorEvent.tracking.model';

export class StyliticsClickEventTrackingModel {
	articleNumber: string;

	id: string;

	items: object;

	material: string | null;

	constructor(articleNumber: string, id: string, items: object, material: string | null) {
		this.articleNumber = articleNumber;
		this.id = id;
		this.items = items;
		this.material = material;
	}

	get styleKey() {
		if (this.material) {
			return `${this.articleNumber}|${this.material}`;
		}
		return this.articleNumber;
	}

	get trackingJson(): IStyliticsClickJSON {
		const vendorEventTrackingModel = new VendorEventTrackingModel();

		return {
			event: 'styleclick',
			stylitics: `${this.id}:${this.articleNumber}`,
			stylitics_arr: [{ value: `${this.id}:${this.articleNumber}` }],
			content: vendorEventTrackingModel.pageID,
			page: {
				id: vendorEventTrackingModel.pageID,
			},
			vendor: {
				styleKey: sanitize(this.styleKey),
			},
		};
	}
}
