import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import styles from '#/product/standard/details/details-attributes.module.scss';
import stylesDetail from '#/product/standard/details/details.module.scss';

export const DetailOptionsQuestionAnswerCasegoods = observer((props = {}) => {
	const {
		detail: {
			group = '',
			label = '',
		} = {},
	} = props;

	const {
		selectorConfigModel: {
			optionsSelectorModel: {
				childSelectors = [],
			} = {},
		} = {},
		workspaceModel: {
			workspaceProducts = [],
		} = {},
	} = useProductContext();

	const [firstWorkspaceProduct = {}] = workspaceProducts;

	const {
		frameProductModel: {
			questionModels = [],
		} = {},
	} = firstWorkspaceProduct;

	const matchingQuestionModel = questionModels.find(({ group: questionGroup = '' }) => group === questionGroup) || {};

	const { key = '' } = matchingQuestionModel;

	const matchingSelector = childSelectors.find(({
		selectedSelectorValue: {
			question = '',
		} = {},
	}) => key === question);

	if (!matchingSelector) {
		return null;
	}

	const {
		selectedSelectorValue: {
			title = '',
			upcharge = '',
		} = {},
	} = matchingSelector;

	const titleToRender = `${upcharge && '+ '}${title}${upcharge && ' '}${upcharge}`;

	return (
		<div
			className={`${styles['detail']} ${stylesDetail['detail']} detail-options-question-answer`}
			data-qa="detail-options-question-answer"
		>
			<strong data-qa="detail-name-value-name">{label}:</strong>
			<span data-qa="detail-name-value-value"> {titleToRender}</span>
		</div>
	);
});
