import { observer } from 'mobx-react';
import React from 'react';

import { SwatchViewerModalWrapper } from '~/components/magic-modal/Components/SwatchViewerModal';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { swatchViewerModalSettings } from '~/product/common/swatchViewer/swatchViewer.constants';
import { SwatchViewerProvider } from '~/product/common/swatchViewer/Contexts/SwatchViewer.context';
import { SwatchViewerModal } from '~/product/common/swatchViewer/Components/SwatchViewer.modal';

export const DetailLinkSwatchDetail = observer((props) => {
	const {
		linkModel: {
			displayText = '',
			url = '',
		} = {},
	} = props;

	const {
		magicModal,
	} = useGlobalContext();

	const {
		productCommons: {
			productGroupModel: {
				key: productGroupKey = '',
			} = {},
		} = {},
	} = useProductContext();

	const modalSettings = swatchViewerModalSettings.DETAIL;

	function handleClick(event) {
		magicModal.openModal({
			WrapperComponent: SwatchViewerModalWrapper,
			...modalSettings,
			content: {
				children: (
					<SwatchViewerProvider
						key="detail-link-swatch-detail-provider"
						productGroupKey={productGroupKey}
						apiUrl={`${url.startsWith('/') ? '' : '/'}${url}`}
						renderMode="DETAIL"
					>
						<SwatchViewerModal />
					</SwatchViewerProvider>
				),
			},
		}, event);
	}

	return (
		<button
			className="detail-link detail-link-swatch-detail ButtonAnchor tw-hidden-for-print" data-qa="detail-link-swatch-detail"
			onClick={handleClick}
		>
			{displayText}
		</button>
	);
});
