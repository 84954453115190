import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import classNames from 'classnames';

import { S7Image } from '~/components/Images/S7Image';
import { scaleLayeredImage } from '~/util/scaleLayeredImage';
import {
	mediaSetDetailFullscreenProps,
	mediaSetModalSettings,
} from '~/product/common/media-set/media-set.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { MediaControlFullscreen } from '~/media-set/Components/MediaControlFullscreen';
import { s7ContentPath } from '~/global/global.constants';
import { MediaSetMinimal } from '~/media-set/Components/MediaSetMinimal';
import { addToUrl } from '~/util/addToUrl';

import shopRoomLinkStyles from '~/components/Buttons/Components/ShopRoomLink.module.scss';
import styles from '#/media-set/media-image.module.scss';
import { useDrift } from '~/hooks/useDrift';

export const MediaImageWithCaption = observer((props = {}) => {
	const { magicModal = {} } = useGlobalContext();

	const {
		imageHeight = 0,
		imagePreset = '',
		imageWidth = 0,
		isMediaThumbnail = false,
		mediaModel: {
			altText = '',
			caption = '',
			hasShopThisRooms = false,
			imageUrl = '',
			shopThisRoomUrl = '',
			selected = false,
		} = {},
		mediaSetModel = {},
		mediaSetStore = {},
		allowShopThisRoom = true,
		shouldLoad = false,
		showDriftZoom = false,
		showFullScreenButton = false,
	} = props;

	const mediaImageRef = useRef();
	useDrift({ driftRef: mediaImageRef, showDriftZoom });
	const src = addToUrl(imageUrl, imagePreset);
	const zoomSrc = scaleLayeredImage(src, imageWidth * 2, imageHeight * 2);
	const image = (
		<S7Image
			alt={altText}
			data-zoom={showDriftZoom ? zoomSrc : null}
			height={imageHeight}
			priority={shouldLoad}
			ref={mediaImageRef}
			sizes={!isMediaThumbnail ? `(max-width: 900px) 100vw, ${imageWidth}px` : null}
			src={src}
			width={imageWidth}
		/>
	);
	const imageButtons = [];

	if (showFullScreenButton) {
		const openFullScreenModal = () => {
			// unbox selectedMediaIndex at initialization time - we don't want initialSlide prop to be observable
			const { selectedMediaIndex: selectedMediaIndexFullscreen = 0 } = mediaSetModel;

			magicModal.openModal({
				...mediaSetModalSettings,
				content: {
					children: (
						<div
							className={styles['media-set-detail-fullscreen']}
							data-qa="media-set-detail-fullscreen"
						>
							<MediaSetMinimal
								{...mediaSetDetailFullscreenProps}
								initialSlide={selectedMediaIndexFullscreen}
								// mediaControls={mediaControlsFullscreen}
								mediaSetModel={mediaSetModel}
								// mediaSetOverride={mediaSetOverrideFullscreen}
								// mediaSetOverrideBeforeChange={mediaSetOverrideBeforeChange}
								mediaSetStore={mediaSetStore}
								showArrows={true}
								showCounterForMedium={false}
							/>
						</div>
					),
				},
			});
		};

		imageButtons.push(
			<MediaControlFullscreen
				key="media-control-fullscreen"
				dataQa="media-control-fullscreen-close"
				onClick={openFullScreenModal}
				screenReaderText="Close fullscreen media"
				src={`${s7ContentPath}/enlarge`}
			/>
		);
	}

	if (hasShopThisRooms && allowShopThisRoom) {
		const conditionalButtonAttrs = {};

		if (!selected) {
			conditionalButtonAttrs.tabIndex = '-1';
		}
		imageButtons.push(
			<a
				{...conditionalButtonAttrs}
				data-qa="shop-room-button"
				className={classNames({
					[styles['shop-this-room-link']]: true,
					'slide-dependent-button': true,
					[shopRoomLinkStyles.root]: true
				})}
				href={shopThisRoomUrl}
			>
				<svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" viewBox="0 0 24.77 24.44">
					<path d="M13.71 22.42L2.95 11.66l-1-4.79 5.13-5.13 4.75 1.04 10.75 10.76-8.87 8.88z" />
					<circle cx="6.62" cy="6.46" r="1.25" />
				</svg>
				<span className={styles['shop-this-room-link-text']}>Shop</span>
			</a>
		);
	}

	return (
		<div className={styles['media-image-container']}>
			{image}
			{
				!isMediaThumbnail && (
					<div className="tw-sr-only">
						{caption}
					</div>
				)
			}
			{
				imageButtons.length > 0 && (
					<div className={styles['media-buttons']}>
						{imageButtons}
					</div>
				)
			}
		</div>
	);
});
