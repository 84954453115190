import { computed, makeObservable, observable } from 'mobx';

import type { IProductSectionals } from '~/product/sectionals/Interfaces/ProductSectionals.interface';
import type { ISelectorValueProduct } from '~/product/sectionals/selector/Interfaces/SelectorValueProduct.interface';
import type { IWorkspaceProductJoinableSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductJoinableSectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

import {
	isBufferingWorkspaceProductsInches, opposingOrientationMap, opposingSidesMap, orientationByJoinableSidesMapByOrientation, perpendicularOrientationsMap,
} from '~/product/sectionals/workspace/workspace-sectionals.constants';

export class WorkspaceProductSectionalsModel implements IWorkspaceProductSectionals {
	orientation: string;

	productSelectorValue: ISelectorValueProduct;

	workspaceProductIndex: number;

	workspaceProductJoinableModels: Array<IWorkspaceProductJoinableSectionals>;

	constructor({
		orientation = '',
		productSelectorValue = {},
		workspaceProductIndex = 0,
		workspaceProductJoinableModels = [],
	}: any = {}) {
		makeObservable(this, {
			orientation: observable,
			additionalBackSides: computed,
			alignToSidesOrientations: computed,
			armSidesOrientations: computed,
			backSidesOrientations: computed,
			beforeWorkspaceProductModel: computed,
			isDepthWorkspaceProductModel: computed,
			isReversedDirection: computed,
			isWidthWorkspaceProductModel: computed,
			orientationByJoinableSidesMap: computed,
			prevWorkspaceProductModel: computed,
			productModel: computed,
			showDimensionsOrientations: computed,
			workspaceProductDepth: computed,
			workspaceProductFrameDepthRatio: computed,
			workspaceProductFrameWidthRatio: computed,
			workspaceProductJoinableModelE: computed,
			workspaceProductJoinableModelN: computed,
			workspaceProductJoinableModelS: computed,
			workspaceProductJoinableModelsByOrientation: computed,
			workspaceProductJoinableModelsWithNextWorkspaceProductModel: computed,
			workspaceProductJoinableModelsWithPrevWorkspaceProductModel: computed,
			workspaceProductJoinableModelW: computed,
			workspaceProductWidth: computed,
			workspaceProductXBufferEnd: computed,
			workspaceProductXBufferStart: computed,
			workspaceProductXEnd: computed,
			workspaceProductXStart: computed,
			workspaceProductYBufferEnd: computed,
			workspaceProductYBufferStart: computed,
			workspaceProductYEnd: computed,
			workspaceProductYStart: computed,
		});

		this.orientation = orientation;
		this.productSelectorValue = productSelectorValue;
		this.workspaceProductIndex = workspaceProductIndex;
		this.workspaceProductJoinableModels = workspaceProductJoinableModels;
	}

	get additionalBackSides(): Array<string> {
		const { isEveryJoinableSides = false } = this.productModel;

		return isEveryJoinableSides
			? [...this.workspaceProductJoinableModelsWithPrevWorkspaceProductModel, ...this.workspaceProductJoinableModelsWithNextWorkspaceProductModel].map(({ joinableSide = '' }) => {
				const oppositeSide = opposingSidesMap[joinableSide as keyof typeof opposingSidesMap];

				const oppositeWorkspaceProductJoinableModel = this.workspaceProductJoinableModels.find(({ joinableSide: oppositeJoinableSide = '' }) => {
					return oppositeJoinableSide === oppositeSide;
				});

				const {
					joinableSide: oppositeJoinableSide = '',
					nextWorkspaceProductModel = null,
					prevWorkspaceProductModel = null,
				} = oppositeWorkspaceProductJoinableModel || {};

				if (!nextWorkspaceProductModel && !prevWorkspaceProductModel) {
					return oppositeJoinableSide;
				}

				return '';
			}).filter(Boolean)
			: [];
	}

	get alignToSidesOrientations(): Array<string> {
		const oppositeArmSidesOrientations = this.armSidesOrientations.map((side = '') => opposingOrientationMap[side as keyof typeof opposingOrientationMap]);

		return [...oppositeArmSidesOrientations, ...this.backSidesOrientations].map((side = '') => side);
	}

	get armSidesOrientations(): Array<string> {
		const {
			connectables: {
				armSides = [],
			} = {},
		} = this.productModel;

		const { orientationByJoinableSidesMap = {} } = this;

		return armSides.map((armSide = '') => orientationByJoinableSidesMap[armSide as keyof typeof orientationByJoinableSidesMap]);
	}

	get backSidesOrientations(): Array<string> {
		const { filteredBackSides = [] } = this.productModel;

		const { orientationByJoinableSidesMap = {} } = this;

		return filteredBackSides.map((backSide = '') => orientationByJoinableSidesMap[backSide as keyof typeof orientationByJoinableSidesMap]);
	}

	get beforeWorkspaceProductModel(): IWorkspaceProductSectionals | null {
		const perpendicularOrientations = perpendicularOrientationsMap[this.orientation as keyof typeof perpendicularOrientationsMap];

		const firstWorkspaceProductJoinableModel = perpendicularOrientations
			.map((perpendicularOrientation = '') => this.workspaceProductJoinableModelsByOrientation[perpendicularOrientation])
			.filter(Boolean)
			.find(({ prevWorkspaceProductModel }) => prevWorkspaceProductModel);

		const { prevWorkspaceProductModel = null } = firstWorkspaceProductJoinableModel || {};

		return prevWorkspaceProductModel;
	}

	get isDepthWorkspaceProductModel(): boolean {
		return ['E', 'W'].includes(this.orientation);
	}

	get isReversedDirection(): boolean {
		const { backSidesOrientations: prevBackSidesOrientations = [] } = this.prevWorkspaceProductModel || {};

		const [firstWorkspaceProductJoinableModelWithPrevWorkspaceProductModel] = this.workspaceProductJoinableModelsWithPrevWorkspaceProductModel;

		const { joinableSideOrientation = '' } = firstWorkspaceProductJoinableModelWithPrevWorkspaceProductModel || {};

		if (this.isDepthWorkspaceProductModel) {
			return joinableSideOrientation === 'S' || prevBackSidesOrientations.includes('S');
		}

		return joinableSideOrientation === 'E' || prevBackSidesOrientations.includes('E');
	}

	get isWidthWorkspaceProductModel(): boolean {
		return ['N', 'S'].includes(this.orientation);
	}

	get orientationByJoinableSidesMap(): any {
		return orientationByJoinableSidesMapByOrientation[this.orientation as keyof typeof orientationByJoinableSidesMapByOrientation];
	}

	get prevWorkspaceProductModel(): IWorkspaceProductSectionals | null {
		const [firstWorkspaceProductJoinableModelWithPrevWorkspaceProductModel] = this.workspaceProductJoinableModelsWithPrevWorkspaceProductModel;

		if (firstWorkspaceProductJoinableModelWithPrevWorkspaceProductModel) {
			const { prevWorkspaceProductModel = null } = firstWorkspaceProductJoinableModelWithPrevWorkspaceProductModel;

			return prevWorkspaceProductModel;
		}

		return null;
	}

	get productModel(): IProductSectionals {
		const { preselectedProductModel } = this.productSelectorValue;

		return preselectedProductModel;
	}

	get showDimensionsOrientations(): Array<string> {
		const {
			connectables: {
				armSides = [],
			} = {},
			filteredBackSides = [],
			filteredJoinableSides = [],
			isDeep = false,
		} = this.productModel;

		const deepSide = isDeep ? 'C' : '';

		const filteredJoinableSidesToUse = isDeep
			? filteredJoinableSides
			: filteredJoinableSides.filter((joinableSide = '') => !filteredBackSides.includes(opposingSidesMap[joinableSide as keyof typeof opposingSidesMap]));

		const showDimensionsSides = [...armSides, ...filteredBackSides, ...filteredJoinableSidesToUse, deepSide].filter(Boolean);

		const showDimensionsOrientations = showDimensionsSides.map((showDimensionsOrientation = '') => this.orientationByJoinableSidesMap[showDimensionsOrientation]);

		const filteredShowDimensionsOrientations = showDimensionsOrientations.filter((orientation = '') => orientation !== this.orientation);

		const joinableModelsByOrientation = this.workspaceProductJoinableModelsByOrientation;

		return filteredShowDimensionsOrientations.filter((orientation = '') => {
			const workspaceProductJoinableModel = joinableModelsByOrientation[orientation as keyof typeof joinableModelsByOrientation];

			const {
				nextWorkspaceProductModel = null,
				prevWorkspaceProductModel = null,
			} = workspaceProductJoinableModel || {};

			return !nextWorkspaceProductModel && !prevWorkspaceProductModel;
		});
	}

	get workspaceProductDepth(): number {
		const {
			depth = 0,
			width = 0,
		} = this.productModel;

		if (['N', 'S'].includes(this.orientation)) {
			return depth;
		}

		return width;
	}

	get workspaceProductFrameDepthRatio(): number {
		const {
			depth = 0,
			frameDepth = 0,
		} = this.productModel;

		return frameDepth / depth;
	}

	get workspaceProductFrameWidthRatio(): number {
		const {
			frameDepth = 0,
			width = 0,
		} = this.productModel;

		return frameDepth / width;
	}

	get workspaceProductJoinableModelE(): IWorkspaceProductJoinableSectionals {
		return this.workspaceProductJoinableModelsByOrientation['E'];
	}

	get workspaceProductJoinableModelN(): IWorkspaceProductJoinableSectionals {
		return this.workspaceProductJoinableModelsByOrientation['N'];
	}

	get workspaceProductJoinableModelS(): IWorkspaceProductJoinableSectionals {
		return this.workspaceProductJoinableModelsByOrientation['S'];
	}

	get workspaceProductJoinableModelsByOrientation(): any {
		return this.workspaceProductJoinableModels.reduce((accumulatedWorkspaceProductJoinableModelsByOrientation: any, workspaceProductJoinableModel: IWorkspaceProductJoinableSectionals) => {
			const { joinableSide = '' } = workspaceProductJoinableModel;

			const orientation = this.orientationByJoinableSidesMap[joinableSide];

			if (orientation) {
				accumulatedWorkspaceProductJoinableModelsByOrientation[orientation] = workspaceProductJoinableModel;
			}

			return accumulatedWorkspaceProductJoinableModelsByOrientation;
		}, {});
	}

	get workspaceProductJoinableModelsWithNextWorkspaceProductModel(): Array<IWorkspaceProductJoinableSectionals> {
		return this.workspaceProductJoinableModels.filter(({ nextWorkspaceProductModel }) => nextWorkspaceProductModel);
	}

	get workspaceProductJoinableModelsWithPrevWorkspaceProductModel(): Array<IWorkspaceProductJoinableSectionals> {
		return this.workspaceProductJoinableModels.filter(({ prevWorkspaceProductModel }) => prevWorkspaceProductModel);
	}

	get workspaceProductJoinableModelW(): IWorkspaceProductJoinableSectionals {
		return this.workspaceProductJoinableModelsByOrientation['W'];
	}

	get workspaceProductWidth(): number {
		const {
			depth = 0,
			width = 0,
		} = this.productModel;

		if (['N', 'S'].includes(this.orientation)) {
			return width;
		}

		return depth;
	}

	get workspaceProductXBufferEnd(): number {
		if (this.orientation === 'W') {
			return this.workspaceProductXEnd + isBufferingWorkspaceProductsInches;
		}

		return this.workspaceProductXEnd;
	}

	get workspaceProductXBufferStart(): number {
		if (this.orientation === 'E') {
			return this.workspaceProductXStart - isBufferingWorkspaceProductsInches;
		}

		return this.workspaceProductXStart;
	}

	get workspaceProductXEnd(): number {
		return this.workspaceProductXStart + this.workspaceProductWidth;
	}

	get workspaceProductXStart(): number {
		const {
			workspaceProductXEnd: prevWorkspaceProductXEnd = 0,
			workspaceProductXStart: prevWorkspaceProductXStart = 0,
		} = this.prevWorkspaceProductModel || {};

		if (!this.prevWorkspaceProductModel) {
			return 0;
		}

		if (this.isDepthWorkspaceProductModel) {
			if (this.orientation === 'W') {
				if (this.beforeWorkspaceProductModel) {
					return prevWorkspaceProductXStart;
				}

				return prevWorkspaceProductXStart - this.workspaceProductWidth;
			}

			if (this.beforeWorkspaceProductModel) {
				return prevWorkspaceProductXEnd - this.workspaceProductWidth;
			}

			return prevWorkspaceProductXEnd;
		}

		if (this.isReversedDirection) {
			if (this.beforeWorkspaceProductModel) {
				return prevWorkspaceProductXStart - this.workspaceProductWidth;
			}

			return prevWorkspaceProductXEnd - this.workspaceProductWidth;
		}

		if (this.beforeWorkspaceProductModel) {
			return prevWorkspaceProductXEnd;
		}

		return prevWorkspaceProductXStart;
	}

	get workspaceProductYBufferEnd(): number {
		if (this.orientation === 'N') {
			return this.workspaceProductYEnd + isBufferingWorkspaceProductsInches;
		}

		return this.workspaceProductYEnd;
	}

	get workspaceProductYBufferStart(): number {
		if (this.orientation === 'S') {
			return this.workspaceProductYStart - isBufferingWorkspaceProductsInches;
		}

		return this.workspaceProductYStart;
	}

	get workspaceProductYEnd(): number {
		return this.workspaceProductYStart + this.workspaceProductDepth;
	}

	get workspaceProductYStart(): number {
		const {
			workspaceProductYEnd: prevWorkspaceProductYEnd = 0,
			workspaceProductYStart: prevWorkspaceProductYStart = 0,
		} = this.prevWorkspaceProductModel || {};

		if (!this.prevWorkspaceProductModel) {
			return 0;
		}

		if (this.isWidthWorkspaceProductModel) {
			if (this.orientation === 'S') {
				if (this.beforeWorkspaceProductModel) {
					return prevWorkspaceProductYEnd - this.workspaceProductDepth;
				}

				return prevWorkspaceProductYEnd;
			}

			if (this.beforeWorkspaceProductModel) {
				return prevWorkspaceProductYStart;
			}

			return prevWorkspaceProductYStart - this.workspaceProductDepth;
		}

		if (this.isReversedDirection) {
			if (this.beforeWorkspaceProductModel) {
				return prevWorkspaceProductYStart - this.workspaceProductDepth;
			}

			return prevWorkspaceProductYEnd - this.workspaceProductDepth;
		}

		if (this.beforeWorkspaceProductModel) {
			return prevWorkspaceProductYEnd;
		}

		return prevWorkspaceProductYStart;
	}
}

export const WorkspaceProductSectionalsModelFactory = ({
	create: (data: any = {}) => {
		return new WorkspaceProductSectionalsModel(data);
	},
});
