import { DetailLinkModal } from '~/product/common/details/Components/links/DetailLinkModal';
import { DetailLinkNew } from '~/product/common/details/Components/links/DetailLinkNew';
import { DetailLinkStatic } from '~/product/common/details/Components/links/DetailLinkStatic';
import { DetailLinkSwatchDetail } from '~/product/common/details/Components/links/DetailLinkSwatchDetail';
import { DetailLinkSwatchOrder } from '~/product/common/details/Components/links/DetailLinkSwatchOrder';
import { DetailLinkTab } from '~/product/common/details/Components/links/DetailLinkTab';
import { DetailLinkCushionCore } from '~/product/common/details/Components/links/DetailLinkCushionCore';

export const detailLinkComponents = {
	MODAL: DetailLinkModal,
	NEW: DetailLinkNew,
	STATIC: DetailLinkStatic,
	SWATCH_DETAIL: DetailLinkSwatchDetail,
	SWATCH_ORDER: DetailLinkSwatchOrder,
	TAB: DetailLinkTab,
	CUSHION_CORE: DetailLinkCushionCore,
};
