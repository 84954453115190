import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Field } from '~/util/formz/Components/fields/Field';
import { Validation } from '~/util/formz/Components/Validation';

export const DeliveryForm = observer(({ store, autoFocusZipCode }) => {
	const {
		formStore: {
			form,
		},
		magicDeliveryModel: {
			hasTransZoneError = false,
			controlReactPropsOverride = {},
		},
	} = store;

	useEffect(() => {
		const zipCodeRef = form?.fields?.zipCode?.control.controlRef;

		if (!zipCodeRef || !autoFocusZipCode) {
			return;
		}
		zipCodeRef.focus();
	}, []);

	return (
		<form {...form.reactProps}>
			<Field
				field={form.fields.zipCode}
				controlReactPropsOverride={controlReactPropsOverride}
				showValidationMessage={false}
				afterControl={
					<button
						data-tr-link-event-track={false}
						data-qa="delivery-widget-submit-button"
						className="ButtonSupport"
					>
						Submit
					</button>
				}
			/>
			{
				!hasTransZoneError && (
					<div className="tw-text-red">
						<Validation field={form.fields.zipCode} />
					</div>
				)
			}
			{
				hasTransZoneError && (
					<div data-qa="invalid-trans-zone-message" className="validation">
						<span className="tw-text-red">
							{'Please check your ZIP Code for accuracy. For deliveries to AK or HI, please call '}
							<a href="tel:+18003019720">800.301.9720</a>
						</span>
					</div>
				)
			}
		</form>
	);
});
