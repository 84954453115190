import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { s7ContentPath } from '~/global/global.constants';
import { MediaControl } from '~/media-set/Components/MediaControl';
import { MediaCylindoDimensions } from '~/product/common/media-set/Components/MediaCylindoDimensions';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

import styles from '#/product/common/media-set/media-control-cylindo-dimensions.module.scss';

export const MediaControlCylindoDimensions = observer((props = {}) => {
	const {
		mediaCylindoModel = {},
		mediaCylindoModel: {
			hasCylindoDimensionImage = '',
			isCylindoDimensionsActive = false,
		} = {},
		mediaCylindoStore = {},
		mediaSetStore = {},
	} = props;

	const {
		magicModal = {},
	} = useGlobalContext();

	if (!hasCylindoDimensionImage) {
		return null;
	}

	function handleClick(event) {
		event.stopPropagation();

		if (isCylindoDimensionsActive) {
			mediaCylindoStore.setIsCylindoActive(false);

			mediaCylindoStore.setIsCylindoARActive(false);

			mediaCylindoStore.setIsCylindoDimensionsActive(false);

			mediaSetStore.setIsMediaSetOverrideActive(false);
		} else {
			mediaCylindoStore.getCylindoData()
				.then(() => {
					if (window.matchMedia('(max-width: 40em)').matches) {
						mediaCylindoStore.setIsCylindoARActive(false);

						magicModal.openModal({
							title: '',
							content: {
								children: (
									<MediaCylindoDimensions mediaCylindoModel={mediaCylindoModel} />
								),
							},
							width: '100vw',
							maxWidth: '725px',
						});
					} else {
						runInAction(() => {
							mediaCylindoStore.setIsCylindoActive(false);

							mediaCylindoStore.setIsCylindoARActive(false);

							mediaCylindoStore.setIsCylindoDimensionsActive(true);

							mediaSetStore.setIsMediaSetOverrideActive(true);
						});
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}

	return (
		<MediaControl>
			<button
				aria-current={isCylindoDimensionsActive}
				className={
					classNames(styles['media-control-cylindo-dimensions'], {
						[styles['media-control-cylindo-dimensions-active']]: isCylindoDimensionsActive,
					})
				}
				data-event122
				data-tr-link-event-name="cylindo dimensions view"
				data-tr-link-event-track={isCylindoDimensionsActive ? false : null}
				data-tr-link-event-type={LinkEventTypes.SITE_ACTION}
				data-qa="media-control-cylindo-dimensions"
				onClick={handleClick}
			>
				<div className={styles['media-control-cylindo-dimensions-image-container']}>
					<img
						alt=""
						className={styles['media-control-cylindo-dimensions-image']}
						src={`${s7ContentPath}/image-set-ruler`}
					/>
				</div>
				<span className="tw-sr-only">
					View AR Media
				</span>
			</button>
		</MediaControl>
	);
});
