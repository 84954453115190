import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { SelectorValueInsertProductDraggable } from '~/product/casegoods/selector/Components/insert/SelectorValueInsertProductDraggable';

import styles from '#/product/casegoods/selector/frame/selector-value-frame-tabccordion-tab.module.scss';

export const SelectorValueInsertProduct = observer((props) => {
	const {
		insertOptionsSelectors = [],
		lineDrawingImage = '',
		productModels = [],
	} = props;

	const productModel = productModels.length ? productModels[0] : {};

	const {
		salesTextToRender = '',
		titleToRender,
		title,
	} = productModel;

	const { selectorConfigModel = {} } = useProductContext();

	const {
		imageScale: {
			lineDrawing: imageScaleLineDrawing = 0,
			lineDrawingDraggable: imageScaleLineDrawingDraggable = 0,
			lineDrawingDraggableSmall: imageScaleLineDrawingDraggableSmall = 0,
		} = {},
		settings: {
			showInsertTitle = true,
			showInsertSalesText = false,
		} = {},
	} = selectorConfigModel;

	return (
		<div className={styles['selector-value-frame']}>
			<div className={styles['selector-value-frame-image-container']}>
				<SelectorValueInsertProductDraggable
					imageScale={imageScaleLineDrawing}
					imageScaleDraggable={imageScaleLineDrawingDraggable}
					imageScaleDraggableSmall={imageScaleLineDrawingDraggableSmall}
					insertOptionsSelectors={insertOptionsSelectors}
					lineDrawingImage={lineDrawingImage}
					productModel={productModel}
					productModels={productModels}
				/>
			</div>
			<div
				className={styles['selector-value-frame-title']}
				data-qa="insert-selector-value-title"
			>
				{showInsertTitle && <div className="tw-font-semibold">{ titleToRender || title }</div>}
				{showInsertSalesText && salesTextToRender}
			</div>
		</div>
	);
});
