import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import styles from './FourSquareRecos.module.scss';

import { headerLevelType, MagicHeaderTag } from '~/components/magic-header';

interface Props {
	recs: any[]
	title: string
	className?: string
	headerClassName?: string
	headerLevel?: headerLevelType
}
export const FourSquareRecos = observer(({
	recs = [], title, className = '', headerClassName = '', headerLevel,
}: Props) => {
	if (!recs) return null;

	const items = recs.slice(0, 4);

	return (
		<div className={cn(styles['four-square-recs'], { [className]: Boolean(className) })}>
			{title && <MagicHeaderTag
				headerLevel={headerLevel || 3}
				className={headerClassName || 'Heading Heading--5 tw-text-gray'}
				props={{ 'data-tracking-title': 'title' }}
			>
				{title}
			</MagicHeaderTag>}
			<div className={styles['rec-items']} data-qa="recommendations-items">
				{items}
			</div>
		</div>
	);
});
