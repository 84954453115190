import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import type { SelectorFrameModal as SelectorFrameModalType } from '~/product/casegoods/selector/Models/SelectorFrameModal.model';

import { Button } from '~/components/Buttons/Components/Button';
import { TextButton } from '~/components/Buttons/Components/TextButton';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { SelectorRadioFrameModal } from '~/product/casegoods/selector/Components/frame-modal/SelectorRadioFrameModal';
import { SelectorSelectFrameModal } from '~/product/casegoods/selector/Components/frame-modal/SelectorSelectFrameModal';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceModalFrameHeightCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameHeightCasegoods';
import { WorkspaceModalFrameWidthCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameWidthCasegoods';
import { WorkspaceProductFrameImageCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-frame/WorkspaceProductFrameImageCasegoods';

import styles from '~/product/casegoods/selector/Components/frame-modal/SelectorFrameModal.module.scss';

interface Props {
	selectorFrameModalModel: SelectorFrameModalType
}

export const SelectorFrameModal = observer(({
	selectorFrameModalModel: {
		selectorConfigFrameModalModel,
		selectorConfigFrameModalModel: {
			frameSelectorModel = {},
			productModels = [],
			productSelectorValues = [],
			selectedQuestionSelectors = [],
			settings: {
				showModalFrameDimensions = false,
			} = {},
		},
		selectorConfigFrameModalStore,
		workspaceProductFrameModal,
		workspaceProductFrameModal: {
			frameProductModel: {
				articleNumber = '',
				depth = 0,
				height = 0,
				links: {
					FRAME_DOCUMENT: {
						href: frameDocumentHref = '',
					} = {},
				} = {},
				width = 0,
			} = {},
			workspaceProductImageData,
		},
		workspaceProductStoreFrameModal,
		workspaceProductIndex = 0,
	},
}: Props) => {
	const { magicModal = {} } = useGlobalContext();

	const {
		selectorConfigStore = {},
		workspaceModel: {
			imageScale: {
				modal: imageScaleModal = 0,
			} = {},
			workspaceProducts = [],
		} = {},
		workspaceStore = {},
	} = useProductContext();

	const showProductSelectorValues = productSelectorValues.length > 1;

	const widthQuestionSelector = selectedQuestionSelectors.find(({ type = '' }) => type === 'WIDTH');

	const nonWidthQuestionSelectors = selectedQuestionSelectors.filter(questionSelector => questionSelector !== widthQuestionSelector);

	function handleContinueClick() {
		selectorConfigStore.getSelectorData({
			currentProductModels: productModels,
			currentWorkspaceProducts: workspaceProducts,
			currentFrameProductArticleNumber: articleNumber,
			hrefToUse: frameDocumentHref,
		}).then(() => {
			workspaceStore.addOrUpdateWorkspaceProduct({
				selectorConfigFrameModalModel,
				workspaceProductImageData,
				workspaceProductIndex,
			});
		})
			.catch((error: any) => {
				console.error(error);
			});

		magicModal.closeModal();
	}

	function handleCancelClick() {
		magicModal.closeModal();
	}

	return (
		<div className={styles['selector-frame-modal']}>
			<div
				className={
					classNames(styles['selector-frame-modal-product'], {
						[styles['selector-frame-modal-product-dimensions']]: showModalFrameDimensions,
					})
				}
			>
				{
					showModalFrameDimensions && (
						<WorkspaceModalFrameHeightCasegoods height={height} />
					)
				}
				<div className={styles['selector-frame-modal-product-image']}>
					{
						showModalFrameDimensions && (
							<WorkspaceModalFrameWidthCasegoods width={width} />
						)
					}
					<WorkspaceProductFrameImageCasegoods
						imageScale={imageScaleModal}
						workspaceProduct={workspaceProductFrameModal}
					/>
				</div>
			</div>
			<div className={styles['selector-frame-modal-attributes']}>
				<div className={styles['selector-frame-modal-attribute']}>
					<span className={styles['selector-frame-modal-attribute-label']}>Height:</span>
					<span data-qa="frame-modal-height">{height} inches</span>
				</div>
				{
					widthQuestionSelector && (
						<SelectorSelectFrameModal
							selectedSelector={widthQuestionSelector}
							selectorConfigFrameModalStore={selectorConfigFrameModalStore}
							workspaceProductStoreFrameModal={workspaceProductStoreFrameModal}
						/>
					)
				}
				{
					!widthQuestionSelector && (
						<div className={styles['selector-frame-modal-attribute']}>
							<span className={styles['selector-frame-modal-attribute-label']}>Width:</span>
							<span data-qa="frame-modal-width">{width} inches</span>
						</div>
					)
				}
				{
					showProductSelectorValues && (
						<SelectorRadioFrameModal
							selectedSelector={frameSelectorModel}
							selectorConfigFrameModalStore={selectorConfigFrameModalStore}
							workspaceProductStoreFrameModal={workspaceProductStoreFrameModal}
						/>
					)
				}
				{
					!showProductSelectorValues && (
						<div className={styles['selector-frame-modal-attribute']}>
							<span className={styles['selector-frame-modal-attribute-label']}>Depth:</span>
							<span data-qa="frame-modal-depth">{depth} inches</span>
						</div>
					)
				}
				{
					nonWidthQuestionSelectors.map((questionSelector: any = {}, index = 0) => {
						return (
							<SelectorRadioFrameModal
								key={`question-selector-${index}`}
								selectedSelector={questionSelector}
								selectorConfigFrameModalStore={selectorConfigFrameModalStore}
								workspaceProductStoreFrameModal={workspaceProductStoreFrameModal}
							/>
						);
					})
				}
			</div>
			<div className={styles['selector-frame-modal-continue']}>
				<Button
					data-qa="frame-modal-continue"
					onClick={handleContinueClick}
				>
					Continue
				</Button>
			</div>
			<TextButton
				data-qa="frame-modal-cancel"
				onClick={handleCancelClick}
			>
				Cancel
			</TextButton>
		</div>
	);
});
