import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceModalFrameControlCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameControlCasegoods';

export const WorkspaceModalFrameControlsCasegoods = observer(() => {
	const {
		workspaceModalFrameModel: {
			workspaceProductModalFrameModel: {
				frameProductModel: selectedFrameProductModel = {},
				frameProductModel: {
					depth = 0,
				} = {},
				frameProductModels = [],
			} = {},
		} = {},
	} = useProductContext();

	const sortedFrameProductModels = frameProductModels.slice().sort((prevFrameProductModel, frameProductModel) => (prevFrameProductModel.depth <= frameProductModel.depth ? -1 : 0));

	return (
		<fieldset>
			<legend className="tw-sr-only">Choose a depth</legend>
			{
				sortedFrameProductModels.length === 1
					? <span>{depth} inches</span>
					: sortedFrameProductModels.map((frameProductModel = {}, index) => {
						const selected = frameProductModel === selectedFrameProductModel;

						return (
							<WorkspaceModalFrameControlCasegoods
								frameProductModel={frameProductModel}
								key={`frame-product-${index}`}
								selected={selected}
							/>
						);
					})
			}
		</fieldset>
	);
});
