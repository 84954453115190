import { DetailModelFactory } from '~/product/common/details/Models/Detail.model';
import { DetailLinkModelFactory } from '~/product/common/details/Models/DetailLink.model';
import { getProcessedDetailsData } from '~/product/common/details/Utils/Details.utils';

const createDetailModel = (detailData = {}) => {
	const {
		behavior = '',
		link: detailLinkData = {},
	} = detailData;

	// some details are actually links, rather than having a nested link object
	// so let's normalize that into our linkModel system
	const linkData = behavior ? detailData : detailLinkData;

	const linkModel = DetailLinkModelFactory.create(linkData || {});

	return DetailModelFactory.create({
		...detailData,
		linkModel,
	});
};

export const createDetailsModel = (detailsData = []) => {
	const processedDetailsData = getProcessedDetailsData(detailsData);

	const detailsModel = processedDetailsData.map((detailData) => {
		const { detailsGroupData = [] } = detailData;

		const detailsGroup = detailsGroupData.map(groupedDetailData => createDetailModel(groupedDetailData));

		return createDetailModel({
			...detailData,
			detailsGroup,
		});
	});

	return detailsModel;
};
