import React from 'react';

import { SelectorValueMaterial } from '~/product/common/selector/Components/material/SelectorValueMaterial';
import { getProductModelsByQuestionAnswers } from '~/product/common/selector/Utils/SelectorConfig.utils';
import { formatCurrency } from '~/util/formatCurrency';

import styles from '#/product/standard/selector/selector-material.module.scss';

export const SelectorValuesGroupMaterial = (props) => {
	const {
		isActive = false,
		label = '',
		parentProductSelectorValues = [],
		priceGroupPadding = 0,
		priceGroupPrice = '',
		selectorValues = [],
		selectorValuesType = '',
		selectorValueWidth = 0,
	} = props;

	const formattedPriceGroupPrice = formatCurrency(priceGroupPrice, true);

	const selectorValuesContainerWidth = Math.ceil(selectorValues.length / 4) * selectorValueWidth + priceGroupPadding;

	return (
		<div
			className={styles['selector-values-group-material']}
			style={selectorValuesContainerWidth ? { flexBasis: selectorValuesContainerWidth } : {}}
		>
			<fieldset className={styles['selector-values-group-material-fieldset']}>
				<legend
					className={styles['selector-values-group-material-price']}
					data-qa="selector-values-group-material-price"
				>
					{formattedPriceGroupPrice}
				</legend>
				<div className={styles['selector-values-material']}>
					{
						selectorValues.map((selectorValue, selectorValueIndex) => {
							const { questionAnswers = [] } = selectorValue;

							const parentProductSelectorValue = parentProductSelectorValues.find(({ preselectedProductModel = {} }) => {
								const productModelsByQuestionAnswers = getProductModelsByQuestionAnswers({
									productModels: [preselectedProductModel],
									questionAnswers,
								});

								return Boolean(productModelsByQuestionAnswers.length);
							});

							return (
								<SelectorValueMaterial
									formattedPriceGroupPrice={formattedPriceGroupPrice}
									isActive={isActive}
									key={`selector-value-${selectorValueIndex}`}
									label={label}
									parentProductSelectorValue={parentProductSelectorValue}
									selectorValue={selectorValue}
									selectorValueIndex={selectorValueIndex}
									selectorValuesType={selectorValuesType}
								/>
							);
						})
					}
				</div>
			</fieldset>
		</div>
	);
};
