import { reaction, extendObservable, when } from 'mobx';
import React from 'react';

import { MagicTabccordionStore } from '~/components/magic-tabccordion/Stores/magicTabccordion.store';
import { isOnServer } from '~/global/global.constants';
import { SelectorHeading } from '~/product/common/selector/Components/SelectorHeading';
import { selectorHeadingCasegoodsComponents } from '~/product/casegoods/selector/Components/selector-heading-casegoods-components';
import { SelectorTabccordionTabCasegoods } from '~/product/casegoods/selector/Components/SelectorTabccordionTabCasegoods';

const isMobile = isOnServer || !window.matchMedia('(min-width: 40em)').matches;

const getTabGroups = (selectedSelectorsToMap, activeIndex = 0) => {
	return [
		{
			tabs: selectedSelectorsToMap.map((selectedSelector, index) => {
				const tab = extendObservable({
					selectedSelector,
					tabTitleComponent: ({
						tab: {
							isActive,
						},
					}) => {
						return (
							<SelectorHeading
								isActive={isActive}
								selectedSelector={selectedSelector}
								selectorHeadingOverrideComponents={selectorHeadingCasegoodsComponents}
							/>
						);
					},
				}, {
					isActive: index === activeIndex,
					get isValid() {
						return selectedSelector.isValid;
					},
					get type() {
						return selectedSelector.type;
					},
				});

				when(() => tab.isActive, () => {
					selectedSelector.wasActive = true;
				});

				return tab;
			}),
		},
	];
};

export const createSelectorMagicTabccordionCasegoodsStore = ({
	linkEventStore = {},
	selectorConfigModel = {},
}) => {
	const { sortedSelectedSelectorsToRender = [] } = selectorConfigModel;

	const selectorTabccordionData = {
		disableAriaRoles: true,
		scrollToActiveTab: isMobile,
		scrollToActiveTabOffset: 80,
		id: 'selector-tabccordion',
		keepActiveTabOpen: isMobile,
		renderMode: 'ACCORDION',
		tabGroups: getTabGroups(sortedSelectedSelectorsToRender),
		wrapperRenderer: SelectorTabccordionTabCasegoods,
	};

	const selectorMagicTabccordionStore = new MagicTabccordionStore(selectorTabccordionData, linkEventStore);

	if (!isOnServer) {
		const updateTabGroupsDisposer = reaction(() => {
			return selectorConfigModel.sortedSelectedSelectorsToRender;
		}, (newSortedSelectedSelectorsToRender) => {
			const activeTab = selectorMagicTabccordionStore.tabGroups[0].tabs.find(({ isActive }) => isActive) || selectorMagicTabccordionStore.tabs[0];

			const newTabGroups = getTabGroups(newSortedSelectedSelectorsToRender, activeTab.index);

			selectorMagicTabccordionStore.updateTabs(newTabGroups, selectorTabccordionData.id);
		});
	}

	return selectorMagicTabccordionStore;
};
