import { runInAction } from 'mobx';
import React from 'react';

import styles from '#/product/common/media-set/media-cylindo-ar-close.module.scss';

export const MediaCylindoARClose = (props = {}) => {
	const {
		mediaControlCylindoARRef = {},
		mediaCylindoStore = {},
		mediaSetStore = {},
	} = props;

	function handleClick() {
		runInAction(() => {
			mediaCylindoStore.setIsCylindoARActive(false);

			mediaSetStore.setIsMediaSetOverrideActive(false);
		});

		if (mediaControlCylindoARRef.current) {
			mediaControlCylindoARRef.current.focus();
		}
	}

	return (
		<button
			className={styles['media-cylindo-ar-close']}
			data-qa="media-cylindo-ar-close"
			data-tr-link-event-track={false}
			onClick={handleClick}
		>
			<span className="tw-sr-only">
				Back to product images
			</span>
		</button>
	);
};
