import { MagicDeliveryModelFactory } from '~/components/magic-delivery/Models/MagicDelivery.model';
import { MagicDeliveryStoreFactory } from '~/components/magic-delivery/Stores/MagicDelivery.store';

export const createMagicDeliveryModel = ({
	initialErrorView = 'deliveryForm',
	initialView = 'deliveryMessaging',
	summaryModel = {},
	trLinkEventCompName = null,
	trLinkEventCompType = null,
} = {}) => {
	const {
		isUpsDeliveryGroup = false,
		shippingMethod = '',
	} = summaryModel;

	const magicDeliveryModel = MagicDeliveryModelFactory.create({
		initialErrorView,
		initialView,
		isUps: isUpsDeliveryGroup,
		shippingMethod,
		summaryModel,
		trLinkEventCompName,
		trLinkEventCompType,
	});

	return magicDeliveryModel;
};

export const createMagicDeliveryStore = ({
	featureTogglesModel = {},
	globalDynamicStore = {},
	magicDeliveryModel = {},
	summaryStore,
	timeout = undefined,
	timeoutDuration = 5000,
} = {}) => {
	const magicDeliveryStore = MagicDeliveryStoreFactory.create({
		featureTogglesModel,
		globalDynamicStore,
		magicDeliveryModel,
		summaryStore,
		timeout,
		timeoutDuration,
	});

	return magicDeliveryStore;
};
