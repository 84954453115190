import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { kebabCase } from '~/util/kebabCase';
import styles from '#/product/standard/selector/selector-radio-vertical.module.scss';

export const SelectorValueRadioVertical = observer((props) => {
	const {
		label = '',
		selectorValue,
		selectorValue: {
			selected,
			title = '',
		},
	} = props;

	const { selectorConfigStore } = useProductContext();

	function handleChange() {
		selectorConfigStore.selectSelectorValue(selectorValue);
	}

	return (
		<label
			className={
				classNames(styles['selector-value-radio-vertical'], {
					'selector-value-radio-vertical-selected': selected,
				})
			}
			data-qa={`${kebabCase(label)}-selector-value`}
			title={title}
		>
			<input
				checked={selected}
				className={styles['selector-value-radio-vertical-input']}
				data-tr-link-event-track={false}
				onChange={handleChange}
				type="radio"
			/>
			<div className="selector-value-radio-vertical-text">
				<span
					className="selector-value-radio-vertical-title"
					data-qa={`${kebabCase(label)}-selector-value-title`}
				>
					{title}
				</span>
			</div>
		</label>
	);
});
