import { computed, makeObservable } from 'mobx';

class SelectorInsertOptions {
	renderer

	selectorValues

	type

	constructor() {
		makeObservable(this, {
			defaultSelectorValue: computed,
			negativeSelectorValue: computed,
			selectedSelectorValue: computed,
			selectorValueToRender: computed,
		});
	}

	get defaultSelectorValue() {
		return this.selectorValues[0];
	}

	get negativeSelectorValue() {
		return this.selectorValues.find(({ title = '' }) => ['no', 'none'].includes(title.toLowerCase()));
	}

	get selectedSelectorValue() {
		return this.selectorValues.find(({ selected = false }) => selected) || this.defaultSelectorValue;
	}

	get selectorValueToRender() {
		return this.selectorValues.find(({ title = '' }) => !['no', 'none'].includes(title.toLowerCase()));
	}
}

export const SelectorInsertOptionsModelFactory = ({
	create: ({
		renderer = '',
		selectorValues = [],
		type = '',
	}) => {
		const selectorInsertOptions = new SelectorInsertOptions();

		Object.assign(selectorInsertOptions, {
			renderer,
			selectorValues,
			type,
		});

		return selectorInsertOptions;
	},
});
