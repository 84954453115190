import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import Slider from 'react-slick';

import magicRecosRfkStyles from './MagicRecosRfk.module.scss';

import { kebabCase } from '~/util/kebabCase';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { ReflektionEventsTrackingStore } from '~/tracking/vendor-events/reflektion/Stores/ReflektionEvents.tracking.store';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { MagicHeaderTag } from '~/components/magic-header';
import { updateSlidesAttributes } from '~/media-set/Utils/Media.utils';

import styles from '#/composite/bem-components/common/magic-recos-nav.module.scss';
import sharedSlickStyles from '~/components/content-slick-slider/content-slick-slider-shared.module.scss';

const BaseButton = (props) => {
	const {
		className, hidden, mouseDownHandler, isNext, onClick, parentKey = '',
	} = props;
	const classVariant = isNext ? 'next' : 'prev';
	const accessibleText = isNext ? 'Next' : 'Previous';
	const dataQaVariant = isNext ? 'next' : 'previous';

	return (
		<div
			className={
				cn(styles['MagicRecos-nav-container'], {
					'tw-hidden': hidden,
				})
			}
		>
			<button
				key={`${parentKey}-${dataQaVariant}-nav-button`}
				className={
					cn(`${className} ${styles[`MagicRecos-${classVariant}Button`]} ${styles['MagicRecos-navButton']} tw-hidden md:tw-flex tw-hidden-for-print`, {
						'tw-sr-only': hidden,
						'focus:tw-not-sr-only': hidden,
					})
				}
				data-qa={`media-viewer-${dataQaVariant}`}
				data-tr-link-event-type={LinkEventTypes.SITE_ACTION}
				data-tr-link-event-name={isNext ? 'right navigation' : 'left navigation'}
				disabled={className.includes('slick-disabled')}
				onClick={onClick}
				onMouseDown={() => {
					if (mouseDownHandler) {
						mouseDownHandler();
					}
				}}
				onTouchStart={() => {
					if (mouseDownHandler) {
						mouseDownHandler();
					}
				}}
			>
				<span className="nav-button-display"></span>
				<span className="tw-sr-only">{accessibleText}</span>
			</button>
		</div>
	);
};

export const MagicRecosRfk = observer(({
	recs = [],
	hasTopBorder = false,
	headerClassName,
	headerLevel,
	title,
	className = '',
	defaultSlidesToShow = 3,
	sliderSettings = {},
	widgetId,
	width1600 = false,
}) => {
	const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
	const [nextBtnDisabled, setNextBtnDisabled] = useState(false);
	const [shouldTrackReflektionAppear, setShouldTrackReflektionAppear] = useState(false);
	const recosRef = useRef();
	const theRecs = recs?.items ? recs.items : recs;

	const defaultSettings = {
		focusOnSelect: false,
		infinite: false,
		speed: 300,
		slidesToShow: defaultSlidesToShow,
		slidesToScroll: defaultSlidesToShow,
		prevArrow: <BaseButton key={'prev-parent-nav-button'} isNext={false} prevBtnDisabled={prevBtnDisabled} />,
		nextArrow: <BaseButton key={'next-parent-nav-button'} isNext={true} nextBtnDisabled={nextBtnDisabled} />,
		afterChange: (index) => {
			const remainingHiddenItems = theRecs.length - index;
			setPrevBtnDisabled(!index);
			setNextBtnDisabled(remainingHiddenItems <= defaultSlidesToShow);
			updateSlidesAttributes(recosRef);
		},
		onInit: () => {
			setTimeout(() => {
				updateSlidesAttributes(recosRef);
			}, 150);
		},
	};

	const settings = {
		...defaultSettings,
		...sliderSettings,
	};

	const {
		featureTogglesModel
	} = useGlobalContext();

	function handleIntersect(entries, obs) {
		// Element is visible
		if (entries[0].intersectionRatio > 0) {
			setShouldTrackReflektionAppear(entries[0].isIntersecting);
			obs.disconnect();

			if (!shouldTrackReflektionAppear) {
				return;
			}

			const reflektionEventsTrackingStore = new ReflektionEventsTrackingStore(featureTogglesModel);
			reflektionEventsTrackingStore.trackReflektionAppear(widgetId);
		}
	}

	useEffect(() => {
		const options = {
			root: null,
			rootMargin: '0px',
		};
		const obs = new IntersectionObserver(handleIntersect, options);

		obs.observe(recosRef.current);
	}, [recosRef.current]);

	useEffect(() => {
		updateSlidesAttributes(recosRef);
	}, []);

	if (!theRecs) {
		return null;
	}
	return (
		<div
			aria-label={`${title} carousel`}
			className={cn('MagicRecos', {
				[magicRecosRfkStyles.MagicRecos]: true,
				'tw-border-t tw-border-gray-400': hasTopBorder,
				[className]: Boolean(className),
			})}
			data-tr-link-event-comp-type="reflektion"
			data-tr-link-event-comp-name={title}
			role="region"
			ref={recosRef}
			data-qa={`recos-widget-${kebabCase(title)}`}
		>
			<p className="tw-sr-only">
				The following is a carousel of product links. Selecting the links will to navigate to each product's page.
			</p>
			{
				Boolean(title) &&
				<div className={cn('MagicRecos-title', {
					'md:tw-text-center': width1600,
				})} data-qa="magicrecos-title">
					<MagicHeaderTag headerLevel={headerLevel || 3} className={headerClassName || 'Heading Heading--3'}>{title}</MagicHeaderTag>
				</div>
			}
			<Slider {...settings} className={`${settings.className} ${sharedSlickStyles['content-slick-slider-shared']}`}>
				{theRecs}
			</Slider>
		</div>
	);
});
