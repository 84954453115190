import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { SelectorValueLayoutImage } from '~/product/sectionals/selector/Components/layout/SelectorValueLayoutImage';

import styles from '~/product/sectionals/selector/Components/layout/SelectorValueLayoutDraggable.module.scss';

export const SelectorValueLayoutDraggable = observer((props = {}) => {
	const {
		imageScale = 0,
		selectorValue = {},
	} = props;

	const { workspaceStore = {} } = useProductContext();

	const dragItem = {
		imageScale,
		productSelectorValue: selectorValue,
	};

	const dragItemRef = useRef(dragItem);

	const [, drag, preview] = useDrag(() => ({
		end: (() => {
			runInAction(() => {
				workspaceStore.setDragOrientation('N');
				workspaceStore.setIsDragging(false);
			});
		}),
		item: () => {
			workspaceStore.setIsDragging(true);

			return dragItemRef.current;
		},
		type: 'PRODUCT',
	}));

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	useEffect(() => {
		dragItemRef.current = dragItem;
	});

	return (
		<div
			className={styles['selector-value-layout-draggable']}
			data-qa="layout-selector-value-draggable"
			ref={drag}
		>
			<SelectorValueLayoutImage
				imageScale={imageScale}
				selectorValue={selectorValue}
			/>
		</div>
	);
});
