import React from 'react';
import {
	action, reaction, observe, makeObservable,
} from 'mobx';
import axios from 'axios';
import dynamic from 'next/dynamic';

import { apiUrl, isOnServer, recommendationsEnabled } from '~/global/global.constants';
import { addToCartActionKeys, addProductsToCartErrorKeys } from '~/product/common/summary/summary.constants';
import { InterstitialStoreFactory } from '~/product/common/interstitial/Stores/Interstitial.store';
import { loggedInAction } from '~/account/sign-in/Actions/loggedIn.action';
import { loggedOutAction } from '~/account/sign-in/Actions/loggedOut.action';
import { AddedToWishlistStoreFactory } from '~/product/common/interstitial/wishlist/Stores/AddedToWishlist.store';
import { getFavoritesInfoAndAddToFavoritesStore } from '~/product/common/summary/Helpers/AddToFavorites.helpers';
import { ReflektionEventsTrackingStore } from '~/tracking/vendor-events/reflektion/Stores/ReflektionEvents.tracking.store';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import { ViewState } from '~/util/viewState/Components/ViewState';
import { debounce } from '~/util/debounce';
import { noop } from '~/util/noop';

const CartInterstitial = dynamic(
	() => import('~/product/common/interstitial/Components/CartInterstitial').then(module => module.CartInterstitial),
	{ loading: () => <LoadingSpinner isLoading /> }
);

const paramsSerializer = (paramsToSerialize) => {
	return Object.entries(paramsToSerialize).map(([key, value]) => {
		if (Array.isArray(value)) {
			return value.map((subValue) => {
				return `${key}=${encodeURIComponent(subValue)}`;
			}).join('&');
		}

		return `${key}=${encodeURIComponent(value)}`;
	}).join('&');
};

class SummaryStore {
	HREF;

	disableSummaryReactions;

	observers = [];

	summaryModel;

	addedToWishlistStore;

	globalStaticModel;

	productTrackingStore;

	rfkTrackingStore;

	trackingCallback;

	constructor() {
		makeObservable(this, {
			setQuantity: action.bound,
			addToCartInit: action.bound,
			addProductToCart: action.bound,
			addProductToWishlist: action.bound,
			getSummaryData: action.bound,
			addToCartErrorHandler: action.bound,
			addToCartSuccessHandler: action.bound,
			openCartInterstitialModal: action.bound,
			openWishlistInterstitialModal: action.bound,
			stopAnimating: action.bound,
			handleConfigurationChange: action.bound
		});
	}

	setQuantity(quantity) {
		this.summaryModel.quantity = quantity;
	}

	addToCartInit(addToCartAction) {
		this.summaryModel.isMaxQuantity = false;
		this.summaryModel.addToCartAction = addToCartAction;
		this.summaryModel.isAddToCartInProcess = true;
		this.summaryModel.isAddToCartDone = false;
		this.summaryModel.shouldButtonAnimate = true;
	}

	addProductToCart(event) {
		const {
			selectedProduct: { addToCartHref },
		} = this.summaryModel.selectorConfigModel;

		const onCloseFocusElement = event ? event.target : null;

		if (addToCartHref) {
			this.addToCartInit(addToCartActionKeys.CART);

			return axios
				.post(`${apiUrl}${addToCartHref}`, this.summaryModel.addProductsToCartRequestDto)
				.then(({ data }) => {
					this.summaryModel.addToCartResponse = data;
					this.openCartInterstitialModal(onCloseFocusElement, data);
					this.addToCartSuccessHandler();
					this.productTrackingStore.trackAddToCart(data);
				}, ({
					response: {
						data: { errors = [] },
					}
				}) => {
					this.addToCartErrorHandler(onCloseFocusElement, errors);
				});
		}

		return Promise.reject();
	}

	addToFavorites(event) {
		const onCloseFocusElement = event ? event.target : null;

		const {
			selectedProduct: { addToFavoritesHref },
		} = this.summaryModel.selectorConfigModel;

		const {
			isCustomerGuest,
		} = this.globalDynamicStore.model;

		const promise = getFavoritesInfoAndAddToFavoritesStore(
			addToFavoritesHref,
			this.summaryModel.addProductsToFavoritesRequestDto,
			this.summaryModel.favoritesModalProductDto,
			null,
			this.addToCartErrorHandler,
			this.featureTogglesModel,
			this.globalDynamicStore,
			this.magicModal,
			onCloseFocusElement,
		);

		promise.then(() => {
			if (!isCustomerGuest) {
				this.magicModal.closeModal();
			}
		});

		return promise;
	}

	addProductToWishlist(event) {
		const onCloseFocusElement = event ? event.target : null;
		return this.addToFavorites(event)
			.then(() => {
				this.addToCartSuccessHandler();
			}, ({
				response: {
					data: { errors = [] },
				} = {}
			}) => {
				this.addToCartErrorHandler(onCloseFocusElement, errors);
			});
	}

	getSummaryData() {
		const {
			isValidQuantity = false,
			selectedProductIsMadeForYou = false,
			summaryParams: params,
			selectorConfigModel: {
				selectedMaterialSelectorValue: {
					title = null,
				} = {},
				selectedProduct,
				selectedProduct: {
					isLoaded = false,
					leadTime,
					summaryHref,
				} = {},
			} = {},
		} = this.summaryModel;

		if (!summaryHref) {
			console.warn(`No _links.summary found for selectedProduct ${selectedProduct}`);

			Object.assign(this.summaryModel, {
				analytics: [{}],
				hasError: true,
				isLoading: false,
				reflektionSkuKey: null,
				styliticsSku: '',
				variantIds: [],
			});
		}

		if (!isOnServer && summaryHref && isLoaded && isValidQuantity) {
			const endpoint = `${apiUrl}${summaryHref}`;
			const restConfig = {
				params,
				paramsSerializer,
			};

			this.summaryModel.isLoading = true;

			const promise = axios.get(endpoint, restConfig);

			return promise
				.then(({ data = {} }) => {
					// deals with summaryWithSizes vs traditional summary
					const {
						questionSelectors: _questionSelectorsData = [],
						sizes = {},
						summary,
					} = data;

					const {
						availability = {
							stockedItem: !selectedProductIsMadeForYou,
							leadTime,
						},
						analytics,
						analytics: [{
							articleNumber = '',
						}] = [{}],
						comfortSpectrumKeys = [],
						customBaseArticleNumber,
						customTopAndBaseArticleNumber,
						customTopArticleNumber,
						detailArticleNumber: stockedArticleNumber,
						deliveryMessages = [],
						deliveryRateMessages = [],
						details = [],
						pricing,
						widthQuantity = 0,
						vendor: {
							reflektion = [],
							stylitics: {
								skuKey: styliticsSku = '',
							} = {},
							variantIds = [],
						} = {},
					} = summary || data;

					this.selectorConfigStore.updateQuestionSelectorModelsFromSummary({ _questionSelectorsData });

					Object.assign(this.summaryModel, {
						analytics,
						availability,
						comfortSpectrumKeys,
						customBaseArticleNumber,
						customTopAndBaseArticleNumber,
						customTopArticleNumber,
						deliveryMessages,
						deliveryRateMessages,
						details,
						hasError: false,
						isLoading: false,
						pricing,
						sizes,
						reflektionSkuKey: reflektion[0]?.skuKey,
						stockedArticleNumber,
						styliticsSku,
						variantIds,
						widthQuantity,
					});

					// if there's no styliticsSku we want want to track that no outfits will be shown
					if (!styliticsSku) {
						this.styliticsStore.trackStyliticsPresent({ articleNumber, material: title });
					}

					this.cylindoPresentEventTrackingStore.trackCylindoPresent(articleNumber);

					this.productTrackingStore.trackProductView('pass');
				})
				.catch((error) => {
					console.error(error);
					Object.assign(this.summaryModel, {
						analytics: [{}],
						hasError: true,
						isLoading: false,
						reflektionSkuKey: null,
						styliticsSku: '',
						variantIds: [],
					});

					this.productTrackingStore.trackProductView('fail');
				});
		}

		return Promise
			.reject()
			.catch(noop);
	}

	addToCartErrorHandler(onCloseFocusElement, errors) {
		this.summaryModel.isAddToCartDone = true;
		this.summaryModel.isAddToCartInProcess = false;
		this.stopAnimating();

		errors.forEach((err) => { console.error(err); });

		if (errors.find(({ errorKey }) => errorKey === addProductsToCartErrorKeys.maxQuantity)) {
			this.summaryModel.isMaxQuantity = true;
		}
	}

	addToCartSuccessHandler() {
		this.summaryModel.isAddToCartDone = true;
		this.summaryModel.isAddToCartInProcess = false;
		setTimeout(this.stopAnimating, 3000);
		this.globalDynamicStore.fetchData();
	}

	openCartInterstitialModal(onCloseFocusElement, data) {
		const loadRfkRecos = recommendationsEnabled;
		const store = InterstitialStoreFactory.create(
			data,
			{
				magicModal: this.magicModal,
				featureTogglesModel: this.featureTogglesModel,
				globalDynamicModel: this.globalDynamicStore.model,
				globalStaticModel: this.globalStaticModel,
				pageStore: this.pageStore,
			},
			{
				loadRfkRecos,
			},
		);

		this.magicModal.openModal({
			title: 'Nice choice! Your cart has been updated.',
			content: {
				children: (
					<CartInterstitial store={store} />
				),
			},
			maxWidth: '725px',
		});
	}

	openWishlistInterstitialModal(onCloseFocusElement, data) {
		const store = AddedToWishlistStoreFactory.create(data, this.magicModal);

		this.magicModal.openModal({
			id: 'added-to-wishlist-modal',
			title: 'Added to Wish List!',
			maxWidth: '784px',
			onCloseFocusElement,
			content: {
				children: <ViewState viewState={store.viewState} />,
			},
		});
	}

	stopAnimating() {
		this.summaryModel.shouldButtonAnimate = false;
		this.summaryModel.isAddToCartDone = false;
	}

	handleConfigurationChange() {
		this.summaryModel.availability = undefined;
		this.summaryModel.detailArticleNumber = undefined;
		this.summaryModel.isMaxQuantity = false;
		this.getSummaryData();
	}
}

export const SummaryStoreFactory = {
	create({
		cylindoPresentEventTrackingStore,
		disableSummaryReactions = false,
		featureTogglesModel,
		globalDynamicStore,
		globalStaticModel,
		HREF,
		magicModal,
		selectorConfigStore,
		summaryModel,
		trackingCallback,
		pageStore,
		productTrackingStore,
		styliticsStore,
		trackingDebugMode = {},
	}) {
		const summaryStore = new SummaryStore();

		const rfkTrackingStore = new ReflektionEventsTrackingStore(featureTogglesModel);

		Object.assign(summaryStore, {
			cylindoPresentEventTrackingStore,
			disableSummaryReactions,
			featureTogglesModel,
			globalDynamicStore,
			globalStaticModel,
			HREF,
			magicModal,
			selectorConfigStore,
			summaryModel,
			trackingCallback,
			pageStore,
			productTrackingStore,
			rfkTrackingStore,
			styliticsStore,
			trackingDebugMode,
		});

		if (!isOnServer && !disableSummaryReactions) {
			summaryStore.observers.push(
				reaction(() => summaryModel.quantity, () => summaryStore.handleConfigurationChange(), { fireImmediately: summaryModel.questionsAnswers.length }),

				// need to debounce the callback function for when fabric + material get answered in succession
				reaction(() => summaryModel.questionsAnswersHash, debounce(() => summaryStore.handleConfigurationChange(), 100), { fireImmediately: !summaryModel.questionsAnswers.length }),

				// Refresh summary if an Engage user creates an online account
				// (effectively changing who they're working with).
				observe(globalDynamicStore.model, (change) => {
					if (change.name !== 'workingWith') {
						return;
					}
					if (!change.oldValue.name && change.newValue.name) {
						return;
					}
					if (!change.oldValue.name && !change.newValue.name) {
						return;
					}
					summaryStore.getSummaryData();
				}),
			);
			// Refresh summary upon logging in or out.
			loggedInAction.add(() => {
				summaryStore.getSummaryData();
			});
			loggedOutAction.add(() => {
				summaryStore.getSummaryData();
			});
		}

		return summaryStore;
	},
	destroy(store) {
		store.observers?.forEach?.(dispose => dispose());
	},
};
