import { observer } from 'mobx-react';
import React from 'react';

import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

import { addToUrl } from '~/util/addToUrl';

import styles from '~/product/sectionals/workspace/Components/product/workspace-product-image.module.scss';

interface Props {
	imageScale: number,
	workspaceProductModel: IWorkspaceProductSectionals
}

export const imageRotationMap = {
	'N': 0,
	'E': 90,
	'S': 180,
	'W': 270,
};

export const WorkspaceProductImageSectionals = observer(({
	imageScale = 0,
	workspaceProductModel: {
		orientation = '',
		productModel: {
			articleNumber = '',
			imageUrl = '',
		},
	},
}: Props) => {
	const imageRotation = imageRotationMap[orientation as keyof typeof imageRotationMap];

	const srcParamsRoot = `$proddd$&rotate=${imageRotation}&fmt=png-alpha`;

	const srcParams = `${srcParamsRoot}&scl=${imageScale}`;

	const srcSetParams = `${srcParamsRoot}&scl=${imageScale / 2}`;

	const srcAttr = addToUrl(imageUrl, srcParams);

	const srcSetAttr = addToUrl(imageUrl, srcSetParams);

	return (
		<img
			alt={articleNumber}
			className={styles['workspace-product-image']}
			data-qa="workspace-product-image"
			draggable={false}
			src={srcAttr}
			srcSet={`${srcSetAttr} 2x`}
		/>
	);
});
