import { observer } from 'mobx-react';
import React from 'react';

import { addToUrl } from '~/util/addToUrl';

import styles from '#/product/casegoods/workspace/product-insert/workspace-product-insert-image.module.scss';

export const WorkspaceProductInsertImageCasegoods = observer((props) => {
	const {
		imageScale = 0,
		insertProductModel: {
			imageUrl = '',
		} = {},
	} = props;

	const srcParams = `$proddd$&fmt=png-alpha&scl=${imageScale}`;

	const srcSetParams = `$proddd$&fmt=png-alpha&scl=${imageScale / 2}`;

	const srcAttr = addToUrl(imageUrl, srcParams);

	const srcSetAttr = addToUrl(imageUrl, srcSetParams);

	return (
		<img
			alt=""
			className={`${styles['workspace-product-insert-image']} tw-user-drag-none tw-user-select-none`}
			draggable={false}
			src={srcAttr}
			srcSet={`${srcSetAttr} 2x`}
		/>
	);
});
