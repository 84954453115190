import { SelectorOptionsModelFactory } from '~/product/casegoods/selector/Models/SelectorOptions.model';
import { SelectorValueOptionsModelFactory } from '~/product/casegoods/selector/Models/SelectorValueOptions.model';

export const createSelectorOptionsModel = ({
	isValid = false,
	selectorData = {},
	wasActive = false,
}) => {
	const {
		label = '',
		type = '',
		values: selectorValuesData = [],
	} = selectorData;

	const selectorValues = selectorValuesData.map((selectorValueData = {}) => {
		return createSelectorValueOptionsModel({
			parentLabel: label,
			parentType: type,
			selectorValueData,
			wasActive,
		});
	});

	const selectorOptionsModel = SelectorOptionsModelFactory.create({
		...selectorData,
		isValid,
		selectorValues,
		wasActive,
	});

	return selectorOptionsModel;
};

const createSelectorValueOptionsModel = ({
	parentLabel = '',
	parentType = '',
	selectorValueData = {},
	selectorValueData: {
		selectors: selectorsData = [],
	} = {},
	wasActive = false,
}) => {
	const selectors = selectorsData.map((selectorData = {}) => {
		return createSelectorOptionsModel({
			selectorData,
			wasActive,
		});
	});

	const selectorValueOptionsModel = SelectorValueOptionsModelFactory.create({
		...selectorValueData,
		parentLabel,
		parentType,
		selectors,
	});

	return selectorValueOptionsModel;
};