/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import { runInAction } from 'mobx';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { WorkspaceModalFrameCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameCasegoods';

import joinableStyles from '~/product/sectionals/workspace/Components/product/workspace-product-joinable-droppable.module.scss';

interface IconProps {
	location: 'top' | 'bottom' | 'left' | 'right'
	imageScale: number
	imageInchesToPixelsFactor: number
	// optional
	slot?: any
	show?: boolean
	productIndex?: number
}

export const WorkspaceStackDropZone = observer(({
	slot: baseSlot, location, imageScale, show = true, productIndex, imageInchesToPixelsFactor,
}: IconProps) => {
	const { magicModal = {} } = useGlobalContext();
	const {
		selectorConfigModel: {
			frameSelectorModel: {
				selectorValues: frameWidthSelectorValues = [],
			} = {},
			settings: {
				frameModalHeadingTitle = '',
			} = {},
		} = {},
		workspaceModalFrameModel = {},
		workspaceModalFrameStore = {},
		workspaceModel = {},
		workspaceStore = {},
		productGroupModel: {
			imageData = {},
		} = {},
	} = useProductContext();
	const isHorizontal = location === 'left' || location === 'right';
	const isVertical = location === 'top' || location === 'bottom';
	const isTop = location === 'top';
	const [dropResult, drop] = useDrop(
		() => ({
			accept: ['INSERT_PRODUCT', 'WORKSPACE_INSERT_PRODUCT'],
			collect: (monitor: any) => ({
				item: monitor.getItem(),
				isOver: monitor.isOver(),
			}),
			drop: (args: any, monitor: any) => {
				// store the item type for the continueOnClick frame modal handler, otherwise it will become undefined by then
				const isDraggableWorkspaceInsertProduct = monitor.getItemType() === 'WORKSPACE_INSERT_PRODUCT';
				// grabbing current workspace's product at correct index
				const matchingProduct = workspaceModel.workspaceProducts.find((testProduct: any) => testProduct.workspaceProductIndex === productIndex);
				runInAction(() => {
					if (baseSlot && isVertical) {
						// if moving an existing insert, clean up its slot and stack
						if (isDraggableWorkspaceInsertProduct) {
							workspaceStore.clearSlot(args?.slot);
							workspaceStore.updateWorkspaceStackProductsSlotModels();
							workspaceStore.removeWorkspaceProductsWithoutInserts();
						}
						// need to traverse reversed non-observable copy, as setInsertProduct updates model on each iteration
						const reversedSlotModels = [...matchingProduct.workspaceProductSlotModels].reverse();
						// finding workspaceProductSlotModel that matches our baseSlot
						const matchingBaseSlotModelIndex = matchingProduct.workspaceProductSlotModels.findIndex((testSlot: any) => testSlot.id === baseSlot.id);
						// use reversedSlotModels so we can cleanly move existing inserts upward
						const matchingReversedBaseSlotModelIndex = reversedSlotModels.findIndex((testSlot: any) => testSlot.id === baseSlot.id);
						// moving all inserts up to make room for new insert when dropping on a 'bottom' target
						const isDroppingOnSameStack = productIndex === args?.productIndex;
						const isRearrangingUpward = args?.slot?.rowIndex < baseSlot.rowIndex;
						const reversedSlotModelIndexAdjust = isDroppingOnSameStack && isRearrangingUpward ? 1 : 0;
						const reversedSlotModelsToUpdate = reversedSlotModels.slice(0, matchingReversedBaseSlotModelIndex + reversedSlotModelIndexAdjust);
						reversedSlotModelsToUpdate.forEach((slotModel: any, index: number) => {
							const belowSlotModel = reversedSlotModels[index + 1];
							if (belowSlotModel?.insertProductModel) {
								workspaceStore.setInsertProduct({
									insertProductModel: belowSlotModel.insertProductModel,
									insertProps: args,
									slotModel,
									workspaceProduct: matchingProduct,
								});
							}
						});
						let slotModelIndexAdjust = 0;
						// adjust slot index for dropping upward within the same stack (-1 since everything above the source slot collapsed down when it was cleared)
						if (isDroppingOnSameStack && isRearrangingUpward) {
							slotModelIndexAdjust -= 1;
						}
						// adjust slot index for dropping on the top slot (+1 since you're adding on the top of the stack)
						if (isTop) {
							slotModelIndexAdjust += 1;
						}
						const slotModelToUpdate = matchingProduct.workspaceProductSlotModels[matchingBaseSlotModelIndex + slotModelIndexAdjust];
						workspaceStore.setInsertProduct({
							insertProductModel: args.productModel,
							insertProps: args,
							slotModel: slotModelToUpdate,
							workspaceProduct: matchingProduct,
						});
						// set lastInteractionWasRearrange property so we don't track existing inserts as productviews
						workspaceStore.setLastInteractionWasRearrange(isDraggableWorkspaceInsertProduct);
						// make call to populate validation for new insert configuration
						workspaceStore.getAvailableInsertValidationData(matchingProduct);
					} else if (isHorizontal) {
						const newProductIndex = matchingProduct.workspaceProductIndex + (location === 'right' ? 1 : 0);
						const matchingFrameWidthSelectorValue = frameWidthSelectorValues.find(
							({ productModels: selectorValueProductModels = [] }) => selectorValueProductModels.some(
								({ width = 0 }) => width === args.productModel?.compatibleFrameWidth
							)
						);
						const { selectors = [] } = matchingFrameWidthSelectorValue || {};
						const [frameDepthSelector = {}] = selectors;
						const { selectorValues: [{ productModels: [firstFrameDepthSelectorValueProductModel] = [] } = {}] = [] } = frameDepthSelector;
						const { productModels: frameWidthSelectorValueProductModels = [] } = matchingFrameWidthSelectorValue || {};
						const {
							firstDoorSelectorValue = {},
							firstWidthSelectorValue = {},
						} = workspaceModalFrameModel;
						workspaceModalFrameStore.setDepthSelector(frameDepthSelector);
						// telling workspaceProductModal that the frame will be added to the back of list of products
						workspaceModalFrameStore.setWorkspaceProductModalFrame({
							frameProductModel: firstFrameDepthSelectorValueProductModel,
							frameProductModels: frameWidthSelectorValueProductModels,
							insertProductModelToAdd: args.productModel,
							workspaceProductIndex: workspaceModel.workspaceProductsCount,
							workspaceProductImageData: imageData,
						});
						workspaceModalFrameStore.setDoorSelectorValue(firstDoorSelectorValue);
						workspaceModalFrameStore.setWidthSelectorValue(firstWidthSelectorValue);
						magicModal.openModal({
							content: {
								children: (
									<WorkspaceModalFrameCasegoods
										continueOnClick={() => {
											// if moving an existing insert, clean up its slot and stack
											if (isDraggableWorkspaceInsertProduct) {
												workspaceStore.clearSlot(args?.slot);
												workspaceStore.updateWorkspaceStackProductsSlotModels();
												workspaceStore.removeWorkspaceProductsWithoutInserts();
											}
											// the new product has been added to the back of the workspaceProducts array, now we move it to it's correct spot
											const productToMove = workspaceModel.workspaceProducts.pop();
											productToMove.workspaceProductIndex = newProductIndex;
											workspaceStore.insertProduct({
												insertIndex: newProductIndex,
												product: productToMove
											});
											// set lastInteractionWasRearrange property so we don't track existing inserts as productviews
											workspaceStore.setLastInteractionWasRearrange(isDraggableWorkspaceInsertProduct);
										}}
									/>
								),
							},
							id: 'workspace-frame-modal',
							maxWidth: '600px',
							title: frameModalHeadingTitle,
						});
					}
				});
			},
		}),
	);
	const workspaceDropAreaHeight = ((imageInchesToPixelsFactor * dropResult?.item?.productModel?.height || 0) / imageScale);
	return (
		<div
			className={classNames('tw-self-end',
				joinableStyles['workspace-product-joinable-droppable'],
				joinableStyles['workspace-product-joinable-droppable-active'],
				{
					[joinableStyles['workspace-product-joinable-droppable-hover']]: dropResult?.isOver,
					'tw-sr-only': !show
				})}
			data-qa={`workspace-product-joinable-droppable-${location}`}
			ref={drop}
			style={{ ...(isHorizontal && { height: workspaceDropAreaHeight, marginBottom: 300 / imageScale }), padding: 100 / imageScale }}
		>
			<div className={joinableStyles['workspace-product-joinable-droppable-icon']}
				style={{ height: Math.max(150 / imageScale, 20), width: Math.max(150 / imageScale, 20) }}
			/>
		</div>
	);
});
