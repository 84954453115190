import { s7ImagePath } from '~/global/global.constants';

export const bookcaseConstructionData = {
	CST_BKCS_FEATURES_WOODWIND: {
		title: 'Features',
		srcSet: `${s7ImagePath}/ConstructionWoodwind?wid=423, ${s7ImagePath}/ConstructionWoodwind?wid=846 2x`,
		srcSetSm: `${s7ImagePath}/ConstructionWoodwind_sm?wid=380, ${s7ImagePath}/ConstructionWoodwind_sm?wid=760 2x`,
		list: [
			{
				title: 'Center Divider',
				description: 'Depending on overall width, some bookcases have a 1.75-inch wide center divider.',
			},
			{
				title: 'Adjustable Shelves',
				description: 'Adjustable shelves are .75 inches thick. Each shelf has a 30-pound weight capacity.',
			},
			{
				title: 'Fixed Shelf',
				description: 'All shelves are adjustable except for a fixed shelf above doors.',
			},
			{
				title: 'Shelves Behind Doors',
				description: 'There is an adjustable shelf behind each set of doors.',
			},
			{
				title: 'Cord Openings',
				description: 'Bookcases with doors have cord openings with 2-inch diameters located behind doors at the back of the unit and above each door or set of doors. Bookcases without doors do not have cord openings.',
			},
		],
	},
	CST_BKCS_FEATURES_WOODWIND_OPEN: {
		title: 'Features',
		srcSet: `${s7ImagePath}/ConstructionWoodwindOB?wid=423, ${s7ImagePath}/ConstructionWoodwindOB?wid=846 2x`,
		srcSetSm: `${s7ImagePath}/ConstructionWoodwindOB_sm?wid=380, ${s7ImagePath}/ConstructionWoodwindOB_sm?wid=760 2x`,
		list: [
			{
				title: 'Center Divider',
				description: 'Depending on overall width, some bookcases have a 1.75-inch wide center divider.',
			},
			{
				title: 'Adjustable Shelves',
				description: 'Adjustable shelves are .75 inches thick. Each shelf has a 30-pound weight capacity.',
			},
			{
				title: 'Finished On All Sides',
				description: 'Every part of the bookcase is finished so you can position it against a wall or stand it freely in a room.',
			},
		],
	},
	CST_BKCS_FEATURES_KEATON: {
		title: 'Features',
		srcSet: `${s7ImagePath}/ConstructionKeaton?wid=423, ${s7ImagePath}/ConstructionKeaton?wid=846 2x`,
		srcSetSm: `${s7ImagePath}/ConstructionKeaton_sm?wid=380, ${s7ImagePath}/ConstructionKeaton_sm?wid=760 2x`,
		list: [
			{
				title: 'Center Divider',
				description: 'Depending on overall width, some bookcases have one or two .75-inch wide center dividers.',
			},
			{
				title: 'Adjustable Shelves',
				description: 'All shelves are adjustable within the open cabinet, which is 12 inches deep. Each shelf is .75 inches thick and has a 30-pound weight capacity.',
			},
			{
				title: 'Fixed Shelf',
				description: 'The base cabinet features a fixed shelf and is 18 inches deep, extending 6 inches deeper than the open cabinet.',
			},
			{
				title: 'Shelves Behind Doors',
				description: 'There is an adjustable shelf behind each single door or set of doors.',
			},
			{
				title: 'Cord Openings',
				description: 'Bookcases between 24 and 54 inches wide have four 9.5"h x 2.5"w cord openings, located behind doors at the back of the unit. Bookcases 55 inches or wider have six 9.5"h x 2.5"w cord openings.',
			},
		],
	},
	FEATURES_TAYLOR_BUILDER: {
		title: 'Features',
		srcSet: `${s7ImagePath}/ConstructionTaylor?wid=520&cb, ${s7ImagePath}/ConstructionTaylor?wid=1040&cb 2x`,
		srcSetSm: `${s7ImagePath}/ConstructionTaylor_sm?wid=380, ${s7ImagePath}/ConstructionTaylor_sm?wid=760 2x`,
		list: [
			{
				title: 'Pieces lock together and stack up to 84.75" high. ',
			},
			{
				title: 'Doors are push-to-open.',
			},
			{
				title: 'Pieces with doors or a drop-down surface come with cord openings.',
			},
			{
				title: 'Drawers have integrated handles and 3/4 extension glides.',
			},
			{
				title: 'Both steel and wood bases are 4" tall.',
			},
			{
				title: 'Wall installation with included hardware is required.',
			},
		],
	},
};
