import React from 'react';
import { observer } from 'mobx-react';

import { curalateImageSizes } from '~/util/curalate/curalate.constants';

import styles from '~/util/curalate/Components/curalate-detail.module.scss';
import { S7Image } from '~/components/Images/S7Image';

const CuralateItemProduct = observer(class CuralateItemProduct extends React.Component {
	constructor(props) {
		super(props);

		this.clickHandler = this.clickHandler.bind(this);
	}

	render() {
		const {
			product: {
				images,
				link,
				name,
			},
		} = this.props;

		const {
			medium,
			small,
		} = images[0] || {};

		const { width = '', height = '' } = curalateImageSizes.SMALL_SQUARE.dimensions;

		return (
			<a className={styles['curalate-item-product']} href={link} onClick={this.clickHandler}>
				{
					images[0] && (
						<div className={styles['curalate-item-product-image-container']}>
							<S7Image alt="" className={styles['curalate-item-product-image']} src={small.link} srcSet={`${medium.link} 2x`} width={width} height={height} />
						</div>
					)
				}
				<p className={styles['curalate-item-product-description']}>{name}</p>
			</a>
		);
	}

	clickHandler() {
		const {
			clickHandler,
			product,
		} = this.props;

		clickHandler(product);
	}
});

export { CuralateItemProduct };
