import { observer } from 'mobx-react';
import React from 'react';

export const DetailLinkCushionCore = observer((props) => {
	const {
		linkModel: {
			label = '',
			displayText = '',
			src = '',
		} = {},
	} = props;

	return (
		<div className="detail-link detail-link-cushion-core">
			<p>
				<strong>{label}:</strong>
				<span> {displayText} </span>
			</p>
			{
				src &&
				<div className="tw-text-center">
					<img
						width="400"
						alt=""
						src={src}
						srcSet={`${src} 2x`}
					/>
				</div>
			}
		</div>
	);
});
