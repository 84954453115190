import { IBazaarvoiceJSON } from '~/tracking/vendor-events/bazzarvoice/Interfaces/IBazaarvoicePresentEvent.tracking';
import { VendorEventTrackingModel } from '~/tracking/vendor-events/common/Models/VendorEvent.tracking.model';

export class BazaarvoicePresentEventTrackingModel {
	get trackingJson(): IBazaarvoiceJSON {
		const vendorEventTrackingModel = new VendorEventTrackingModel();
		return {
			event: 'bvpresent',
			vendor: {
				bvPresent: 1,
			},
			content: vendorEventTrackingModel.pageID,
			page: {
				id: vendorEventTrackingModel.pageID,
			},
		};
	}
}
