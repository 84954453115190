import { observer } from 'mobx-react';
import React from 'react';

import { WorkspaceProductsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductsCasegoods';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/controls/workspace-control-thumbnails.module.scss';

export const WorkspaceControlThumbnailsCasegoods = observer(() => {
	const {
		workspaceModel: {
			hasWorkspaceProducts = false,
			imageSettings = {},
		} = {}
	} = useProductContext();

	const { thumbnail: imageSettingsThumbnail = {} } = imageSettings;

	if (!hasWorkspaceProducts) {
		return null;
	}

	return (
		<div className={`${styles['workspace-control-thumbnails']} tw-hidden md:tw-block`}>
			<div className={styles['workspace-control-thumbnail']}>
				<WorkspaceProductsCasegoods
					imageSettings={imageSettingsThumbnail}
					isDraggable={false}
					isMediaThumbnail={true}
				/>
			</div>
		</div>
	);
});
