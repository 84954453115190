import classNames from 'classnames';
import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import React from 'react';
import { useDrop } from 'react-dnd';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { createSelectorFrameModalModel } from '~/product/casegoods/selector/Helpers/SelectorFrameModal.init';
import { WorkspaceControlsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/controls/WorkspaceControlsCasegoods';
import { WorkspaceOptionalFeaturesSavedCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/optional-features-saved/WorkspaceOptionalFeaturesSavedCasegoods';
import { WorkspaceProductsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductsCasegoods';
import { WorkspaceProductsControlsCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-controls/WorkspaceProductsControlsCasegoods';
import { WorkspaceProductControlsGroupCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-controls/WorkspaceProductControlsGroupCasegoods';
import { WorkspaceEmptyCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/WorkspaceEmptyCasegoods';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/workspace.module.scss';

const SelectorFrameModal = dynamic(() => import('~/product/casegoods/selector/Components/frame-modal/SelectorFrameModal').then(module => module.SelectorFrameModal), { ssr: false });
const WorkspaceModalFrameCasegoods = dynamic(() => import('~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameCasegoods').then(module => module.WorkspaceModalFrameCasegoods), { ssr: false });
const WorkspaceModalFrameMaximumCasegoods = dynamic(() => import('~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameMaximumCasegoods').then(module => module.WorkspaceModalFrameMaximumCasegoods), { ssr: false });
const WorkspaceModalFrameStartOverCasegoods = dynamic(() => import('~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameStartOverCasegoods').then(module => module.WorkspaceModalFrameStartOverCasegoods), { ssr: false });

export const WorkspaceCasegoods = observer(({ imageSettings = {} }) => {
	const { magicModal = {} } = useGlobalContext();

	const {
		productGroupModel = {},
		productGroupModel: {
			imageData = {},
		} = {},
		selectorConfigModel = {},
		selectorConfigModel: {
			frameSelectorModel: {
				selectorValues: frameWidthSelectorValues = [],
			} = {},
			settings: {
				frameModalHeadingTitle = '',
			} = {},
		} = {},
		selectorConfigStore = {},
		workspaceModalFrameModel = {},
		workspaceModalFrameStore = {},
		workspaceModel = {},
		workspaceStore = {},
	} = useProductContext();

	const {
		hasWorkspaceProducts = false,
		isDraggable = false,
		settings: {
			acceptDraggableInsertProductOnEmptyWorkspace = false,
			maxWorkspaceProducts = 0,
			replaceWorkspaceProducts = false,
			showHelpControl = false,
			showMaxWorkspaceProductsWarning = false,
			showThumbnailsControl = false,
			frameFirstBuilder,
		} = {},
		showControls = false,
		showSecondaryControls = false,
		showDimensions = false,
		showOptionalFeaturesSaved = false,
		showOverallWidthDimension = false,
		sortedWorkspaceProducts = [],
		workspaceProducts = [],
		workspaceProductsCount = 0,
	} = workspaceModel;

	const [, drop] = useDrop({
		accept: acceptDraggableInsertProductOnEmptyWorkspace ? ['INSERT_PRODUCT'] : ['FRAME_PRODUCT'],
		drop: (args) => {
			const {
				productModel = {},
				productModel: {
					compatibleFrameWidth = 0,
				} = {},
				selectedDepthSelector = {},
				selectedDepthSelector: {
					renderer = '',
					selectedSelectorValue = {},
				} = {},
			} = args;

			function openSelectorFrameModal(selectorFrameModalModel = {}) {
				magicModal.openModal({
					content: {
						children: (
							<SelectorFrameModal selectorFrameModalModel={selectorFrameModalModel} />
						),
					},
					id: 'workspace-frame-modal',
					maxWidth: '600px',
					title: frameModalHeadingTitle,
				});
			}

			function openWorkspaceModalFrame({ removeAllWorkspaceProducts = false } = {}) {
				magicModal.openModal({
					content: {
						children: (
							<WorkspaceModalFrameCasegoods removeAllWorkspaceProducts={removeAllWorkspaceProducts} />
						),
					},
					id: 'workspace-frame-modal',
					maxWidth: '600px',
					title: frameModalHeadingTitle,
				});
			}

			if (frameFirstBuilder) {
				selectorConfigStore.selectSelectorValue(selectedSelectorValue);

				const {
					insertConfiguration = {},
					selectedProduct = {},
					selectedProductConfiguration = {},
				} = selectorConfigModel;

				const selectorFrameModalModel = createSelectorFrameModalModel({
					defaultConfig: selectedProductConfiguration,
					defaultnsertConfig: insertConfiguration,
					defaultProduct: selectedProduct,
					productGroupModel,
					selectedSelector: selectedDepthSelector,
					selectorConfigModel,
					selectorConfigStore,
					workspaceProductIndex: workspaceProductsCount,
				});

				if (workspaceProductsCount < maxWorkspaceProducts) {
					if (renderer === 'NONE') {
						const {
							selectorConfigFrameModalModel = {},
							selectorConfigFrameModalModel: {
								productModels = [],
							},
							workspaceProductFrameModal: {
								frameProductModel: {
									articleNumber = '',
									links: {
										FRAME_DOCUMENT: {
											href: frameDocumentHref = '',
										} = {},
									} = {},
								} = {},
								workspaceProductImageData,
							},
							workspaceProductIndex = 0,
						} = selectorFrameModalModel;

						selectorConfigStore.getSelectorData({
							currentProductModels: productModels,
							currentWorkspaceProducts: workspaceProducts,
							currentFrameProductArticleNumber: articleNumber,
							hrefToUse: frameDocumentHref,
						}).then(() => {
							workspaceStore.addOrUpdateWorkspaceProduct({
								selectorConfigFrameModalModel,
								workspaceProductImageData,
								workspaceProductIndex,
							});
						});
					} else {
						openSelectorFrameModal(selectorFrameModalModel);
					}
				} else if (replaceWorkspaceProducts) {
					magicModal.openModal({
						content: {
							children: (
								<WorkspaceModalFrameStartOverCasegoods
									openWorkspaceModalFrame={
										() => {
											openSelectorFrameModal(selectorFrameModalModel);
										}
									}
								/>
							),
						},
						id: 'workspace-help-maximum-modal',
						maxWidth: '575px',
						title: 'Do you want to start over?',
					});
				} else if (showMaxWorkspaceProductsWarning) {
					magicModal.openModal({
						content: {
							children: (
								<WorkspaceModalFrameMaximumCasegoods />
							),
						},
						id: 'workspace-help-maximum-modal',
						maxWidth: '500px',
					});
				}
			} else if (!hasWorkspaceProducts) {
				const matchingFrameWidthSelectorValue = frameWidthSelectorValues.find(({ productModels: selectorValueProductModels = [] }) => selectorValueProductModels.some(({ width = 0 }) => width === compatibleFrameWidth));
				const { selectors = [] } = matchingFrameWidthSelectorValue || {};
				const [frameDepthSelector = {}] = selectors;
				const { selectorValues: [{ productModels: [firstFrameDepthSelectorValueProductModel] = [] } = {}] = [] } = frameDepthSelector;
				const { productModels: frameWidthSelectorValueProductModels = [] } = matchingFrameWidthSelectorValue || {};
				const {
					firstDoorSelectorValue = {},
					firstWidthSelectorValue = {},
				} = workspaceModalFrameModel;
				workspaceModalFrameStore.setDepthSelector(frameDepthSelector);
				workspaceModalFrameStore.setWorkspaceProductModalFrame({
					frameProductModel: firstFrameDepthSelectorValueProductModel,
					frameProductModels: frameWidthSelectorValueProductModels,
					insertProductModelToAdd: productModel,
					insertProps: args,
					workspaceProductIndex: workspaceProductsCount,
					workspaceProductImageData: imageData,
				});
				workspaceModalFrameStore.setDoorSelectorValue(firstDoorSelectorValue);
				workspaceModalFrameStore.setWidthSelectorValue(firstWidthSelectorValue);
				openWorkspaceModalFrame();
			}
		},
	});

	return (
		<div className={styles['workspace']}>
			<div
				className={
					classNames(styles['workspace-products-container'], {
						[styles['workspace-products-container-show-controls']]: showControls,
						[styles['workspace-products-container-show-dimensions']]: showDimensions,
						[styles['workspace-product-container-show-frame-and-overall-dimensions']]: showOverallWidthDimension,
					})
				}
				data-qa="workspace-products-droppable"
				ref={drop}
			>
				{
					hasWorkspaceProducts
						? <>
							<WorkspaceProductsCasegoods
								imageSettings={imageSettings}
								isDraggable={isDraggable}
							/>
							<div className={`${styles['workspace-products-controls-groups']} tw-hidden md:tw-flex print:tw-hidden`}>
								{
									sortedWorkspaceProducts.map((workspaceProduct, index) => {
										return (
											<WorkspaceProductControlsGroupCasegoods
												key={`workspace-product-control-${index}`}
												workspaceProduct={workspaceProduct}
											/>
										);
									})
								}
							</div>
							<div className="md:tw-hidden">
								<WorkspaceProductsControlsCasegoods />
							</div>
						</>
						: <WorkspaceEmptyCasegoods />
				}
			</div>
			{
				showOptionalFeaturesSaved && (
					<WorkspaceOptionalFeaturesSavedCasegoods />
				)
			}
			<WorkspaceControlsCasegoods
				showHelpControl={showHelpControl}
				showEnlargeControl={showSecondaryControls}
				showThumbnailsControl={showSecondaryControls && showThumbnailsControl}
			/>
		</div>
	);
});
