import { createSelectorFiltersCasegoodsModel } from '~/product/casegoods/selector/Helpers/SelectorFiltersCasegoods.init';
import { CustomQuestionAnswerSelectorValueFrameModelFactory } from '~/product/casegoods/selector/Models/CustomQuestionAnswerSelectorValueFrame.model';
import { SelectorFrameModelFactory } from '~/product/casegoods/selector/Models/SelectorFrame.model';
import { SelectorValueFrameModelFactory } from '~/product/casegoods/selector/Models/SelectorValueFrame.model';
import { createSelectorFiltersMagicTabccordionStore } from '~/product/common/selector/Helpers/SelectorFiltersTabccordion.init';
import { SelectorFiltersStoreFactory } from '~/product/common/selector/Stores/SelectorFilters.store';

export const createSelectorFrameModel = ({
	customQuestionAnswersData = {},
	linkEventStore = {},
	productModels = [],
	selectorData = {},
	settings: {
		frameSelectorHeadingTitle = '',
	} = {},
	wasActive = false,
}) => {
	const {
		displayType = '',
		label = '',
		filters: filtersData = {},
		renderer = '',
		selectorValues: selectorValuesData,
		title: selectorDataTitle = '',
		type = '',
		values: valuesData,
	} = selectorData;

	const selectorValuesDataToUse = valuesData || [...selectorValuesData] || [];

	const [firstSelectorValueData = {}] = selectorValuesDataToUse;

	const {
		answerKeys: answerKeysData = '',
		question = '',
	} = firstSelectorValueData;

	const answerKeys = answerKeysData.split(',').filter(Boolean);

	const isCustomQuestionAnswer = (displayType === 'QUESTION_ANSWER');

	const lookedUpCustomQuestionAnswersData = customQuestionAnswersData[question] || {};

	const {
		selectorValues: lookedUpSelectorValuesData = [],
	} = lookedUpCustomQuestionAnswersData;

	const validLookedUpSelectorValuesData = answerKeys.length
		? lookedUpSelectorValuesData.filter(({ questionAnswers = {} }) => {
			return answerKeys.includes(questionAnswers[question]);
		})
		: lookedUpSelectorValuesData;

	const selectorValuesDataToMap = isCustomQuestionAnswer ? validLookedUpSelectorValuesData : selectorValuesDataToUse;

	const selectorValues = selectorValuesDataToMap.map((selectorValueData = {}) => {
		if (isCustomQuestionAnswer) {
			// eslint-disable-next-line @typescript-eslint/no-use-before-define
			return createCustomQuestionAnswerSelectorValueFrameModel({
				customQuestionAnswersData,
				firstSelectorValueData,
				parentDisplayType: displayType,
				parentRenderer: renderer,
				parentLabel: label,
				parentType: type,
				productModels,
				selectorValueData,
			});
		}

		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		return createSelectorValueFrameModel({
			customQuestionAnswersData,
			linkEventStore,
			parentLabel: label,
			parentType: type,
			productModels,
			selectorValueData,
			wasActive,
		});
	});

	const filtersModel = createSelectorFiltersCasegoodsModel({
		filtersData,
		selectorValues,
	});

	const filtersStore = SelectorFiltersStoreFactory.create({ filtersModel });

	const filtersMagicTabccordionStore = createSelectorFiltersMagicTabccordionStore(filtersStore, linkEventStore);

	const selectorFrameModel = SelectorFrameModelFactory.create({
		...selectorData,
		filtersMagicTabccordionStore,
		filtersModel,
		filtersStore,
		productModels,
		selectorData,
		selectorValues,
		title: selectorDataTitle || frameSelectorHeadingTitle,
		wasActive,
	});

	return selectorFrameModel;
};

const createSelectorValueFrameModel = ({
	customQuestionAnswersData = {},
	linkEventStore = {},
	parentLabel = '',
	parentType = '',
	productModels = [],
	selectorValueData = {},
	wasActive = false,
}) => {
	const {
		articleNumbers: articleNumbersData = [],
		articles: articlesData = [],
		selectors: selectorsData = [],
	} = selectorValueData;

	// TODO: remove these shims to normalize articleNumbers into articles and vice versa
	const articles = articlesData.length ? articlesData : articleNumbersData.map(articleNumber => ({ article: articleNumber }));
	const articleNumbers = articleNumbersData.length ? articleNumbersData : articlesData.map(({ article = '' }) => article);

	const matchingProductModels = articles.map(({ article = '' }) => {
		return productModels.find(({ articleNumber = '' }) => articleNumber === article);
	}).filter(Boolean);

	// TODO: have the API omit question selectors that have no selectorValues
	const filteredSelectorsData = selectorsData.filter(({
		selectorValues: selectorValuesData = [],
		values: valuesData = [],
	}) => {
		const selectorValuesDataToUse = valuesData || [...selectorValuesData] || [];

		return Boolean(selectorValuesDataToUse.length);
	});

	const selectors = filteredSelectorsData.map((selectorData = {}) => {
		return createSelectorFrameModel({
			customQuestionAnswersData,
			linkEventStore,
			productModels: matchingProductModels,
			selectorData,
			wasActive,
		});
	});

	const selectorValueFrameModel = SelectorValueFrameModelFactory.create({
		...selectorValueData,
		articleNumbers,
		parentLabel,
		parentType,
		productModels: matchingProductModels,
		selectors,
	});

	return selectorValueFrameModel;
};

const createCustomQuestionAnswerSelectorValueFrameModel = ({
	customQuestionAnswersData = {},
	firstSelectorValueData = {},
	firstSelectorValueData: {
		articles: articlesData = [],
	} = {},
	parentDisplayType = '',
	parentLabel = '',
	parentRenderer = '',
	parentType = '',
	productModels = [],
	selectorValueData = {},
	selectorValueData: {
		selectors: selectorsData = [],
	} = {},
}) => {
	const articleNumbers = articlesData.map(({ article = '' }) => article);

	const products = productModels.filter(({ articleNumber: articleNumberToLookup }) => articleNumbers.includes(articleNumberToLookup));

	const selectors = selectorsData.map((selectorData) => {
		return createSelectorFrameModel({
			customQuestionAnswersData,
			productModels,
			selectorData,
		});
	});

	const customQuestionAnswerSelectorValueModel = CustomQuestionAnswerSelectorValueFrameModelFactory.create({
		...firstSelectorValueData,
		...selectorValueData,
		parentDisplayType,
		parentLabel,
		parentRenderer,
		parentType,
		products,
		selectors,
	});

	return customQuestionAnswerSelectorValueModel;
};
