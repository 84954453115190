import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector-filters.module.scss';

export const selectorFacetTextClass = {
	'black': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-black']}`,
	'blue': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-blue']}`,
	'brown': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-brown']}`,
	'DEFAULT': styles['selector-filters-facet-text'],
	'beige': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-beige']}`,
	'dnu_beige': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-beige']}`,
	'dnu_camel': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-camel']}`,
	'dnu_charcoal': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-charcoal']}`,
	'dnu_gold': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-gold']}`,
	'dnu_ivory': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-ivory']}`,
	'green': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-green']}`,
	'grey': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-grey']}`,
	'orange': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-orange']}`,
	'pink': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-pink']}`,
	'purple': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-purple']}`,
	'red': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-red']}`,
	'white': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-white']}`,
	'yellow': `${styles['selector-filters-facet-text']} ${styles['selector-filters-facet-text-color']} ${styles['selector-filters-facet-text-color-yellow']}`,
};

export const SelectorFiltersFacet = observer((props) => {
	const {
		facetModel = {},
		facetModel: {
			intersectingFilteredSelectorValuesCount = 0,
			parentType = '',
			selected = false,
			title = '',
			showMatchCount = true,
		} = {},
		filtersStore = {},
	} = props;

	const { selectorConfigStore = {} } = useProductContext() || {};

	function handleChange() {
		if (selectorConfigStore.toggleFacet) {
			selectorConfigStore.toggleFacet(facetModel);
		} else {
			filtersStore.toggleFacet(facetModel);
		}
	}

	return (
		<label
			className={styles['selector-filters-facet']}
			data-qa={`${kebabCase(parentType)}-filter-${kebabCase(title)}-facet`}
		>
			<input
				checked={selected}
				className="selector-filters-facet-input"
				onChange={handleChange}
				type="checkbox"
				value={title}
				data-tr-link-event-track={false}
			/>
			<span className={selectorFacetTextClass[kebabCase(title)] || selectorFacetTextClass.DEFAULT}>
				{title}
				{' '}
				{
					showMatchCount && Boolean(intersectingFilteredSelectorValuesCount) &&
					`(${intersectingFilteredSelectorValuesCount})`
				}
			</span>
		</label>
	);
});
