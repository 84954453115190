import type { IWorkspaceProductsChunkSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductsChunkSectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

import { opposingOrientationMap, orientationByJoinableSidesMapByOrientation } from '~/product/sectionals/workspace/workspace-sectionals.constants';

export const getFlattenedArticleNumbers = ({
	articleNumber = '',
	joinables = [],
}: any,
articleNumbersToReturn: Array<string> = []) => {
	articleNumbersToReturn.push(articleNumber);

	joinables.forEach(({ workspaceProduct = {} }) => {
		getFlattenedArticleNumbers(workspaceProduct, articleNumbersToReturn);
	});

	return articleNumbersToReturn;
};

export const getFlattenedWorkspaceProductModels = (workspaceProductModelToAdd: IWorkspaceProductSectionals, workspaceProductModelsToReturn: Array<IWorkspaceProductSectionals> = []) => {
	const { workspaceProductJoinableModels = [] } = workspaceProductModelToAdd;

	workspaceProductModelsToReturn.push(workspaceProductModelToAdd);

	workspaceProductJoinableModels.forEach(({ nextWorkspaceProductModel }) => {
		if (nextWorkspaceProductModel) {
			getFlattenedWorkspaceProductModels(nextWorkspaceProductModel, workspaceProductModelsToReturn);
		}
	});

	return workspaceProductModelsToReturn;
};

export const getHasBufferingWorkspaceProducts = (workspaceProductModels: Array<IWorkspaceProductSectionals>, workspaceProductsChunkModels: Array<IWorkspaceProductsChunkSectionals>) => {
	return workspaceProductModels.some((workspaceProductModel) => {
		const {
			workspaceProductXBufferEnd = 0,
			workspaceProductXBufferStart = 0,
			workspaceProductYBufferEnd = 0,
			workspaceProductYBufferStart = 0,
		} = workspaceProductModel;

		const workspaceProductsChunkModelWithWorkspaceProduct = workspaceProductsChunkModels.find(({ workspaceProductModelsWithAdjacentWorkspaceProductModels = [] }: IWorkspaceProductsChunkSectionals) => {
			return workspaceProductModelsWithAdjacentWorkspaceProductModels.includes(workspaceProductModel);
		});

		const { workspaceProductModelsWithAdjacentWorkspaceProductModels = [] } = workspaceProductsChunkModelWithWorkspaceProduct || {};

		const filteredWorkspaceProductModels = workspaceProductModels.filter((nextWorkspaceProductModel) => {
			const { prevWorkspaceProductModel } = nextWorkspaceProductModel;

			const chunkIncludesNextWorkspaceProductModel = workspaceProductModelsWithAdjacentWorkspaceProductModels.includes(nextWorkspaceProductModel);

			const chunkIncludesPrevWorkspaceProductModel = prevWorkspaceProductModel ? workspaceProductModelsWithAdjacentWorkspaceProductModels.includes(prevWorkspaceProductModel) : false;

			return !chunkIncludesNextWorkspaceProductModel && !chunkIncludesPrevWorkspaceProductModel;
		});

		return filteredWorkspaceProductModels.some((nextWorkspaceProductModel) => {
			const {
				workspaceProductXEnd: nextWorkspaceProductXEnd = 0,
				workspaceProductXStart: nextWorkspaceProductXStart = 0,
				workspaceProductYEnd: nextWorkspaceProductYEnd = 0,
				workspaceProductYStart: nextWorkspaceProductYStart = 0,
			} = nextWorkspaceProductModel;

			const hasBufferingWorkspaceProductsX = ((nextWorkspaceProductXStart <= workspaceProductXBufferStart) && (nextWorkspaceProductXEnd > workspaceProductXBufferStart))
				|| ((nextWorkspaceProductXStart >= workspaceProductXBufferStart) && (nextWorkspaceProductXStart < workspaceProductXBufferEnd));

			const hasBufferingWorkspaceProductsY = ((nextWorkspaceProductYStart <= workspaceProductYBufferStart) && (nextWorkspaceProductYEnd > workspaceProductYBufferStart))
				|| ((nextWorkspaceProductYStart >= workspaceProductYBufferStart) && (nextWorkspaceProductYStart < workspaceProductYBufferEnd));

			return hasBufferingWorkspaceProductsX && hasBufferingWorkspaceProductsY;
		});
	});
};

export const getHasOverlappingWorkspaceProducts = (workspaceProductModels: Array<IWorkspaceProductSectionals>, workspaceProductsChunkModels: Array<IWorkspaceProductsChunkSectionals>) => {
	return workspaceProductModels.some((workspaceProductModel) => {
		const {
			workspaceProductXEnd = 0,
			workspaceProductXStart = 0,
			workspaceProductYEnd = 0,
			workspaceProductYStart = 0,
		} = workspaceProductModel;

		const workspaceProductsChunkModelWithWorkspaceProduct = workspaceProductsChunkModels.find(({ workspaceProductModelsWithAdjacentWorkspaceProductModels = [] }: IWorkspaceProductsChunkSectionals) => {
			return workspaceProductModelsWithAdjacentWorkspaceProductModels.includes(workspaceProductModel);
		});

		const { workspaceProductModelsWithAdjacentWorkspaceProductModels = [] } = workspaceProductsChunkModelWithWorkspaceProduct || {};

		const filteredWorkspaceProductModels = workspaceProductModels.filter(nextWorkspaceProductModel => !workspaceProductModelsWithAdjacentWorkspaceProductModels.includes(nextWorkspaceProductModel));

		return filteredWorkspaceProductModels.some((nextWorkspaceProductModel) => {
			const {
				workspaceProductXEnd: nextWorkspaceProductXEnd = 0,
				workspaceProductXStart: nextWorkspaceProductXStart = 0,
				workspaceProductYEnd: nextWorkspaceProductYEnd = 0,
				workspaceProductYStart: nextWorkspaceProductYStart = 0,
			} = nextWorkspaceProductModel;

			const hasOverlappingWorkspaceProductsX = ((nextWorkspaceProductXStart <= workspaceProductXStart) && (nextWorkspaceProductXEnd > workspaceProductXStart))
				|| ((nextWorkspaceProductXStart >= workspaceProductXStart) && (nextWorkspaceProductXStart < workspaceProductXEnd));

			const hasOverlappingWorkspaceProductsY = ((nextWorkspaceProductYStart <= workspaceProductYStart) && (nextWorkspaceProductYEnd > workspaceProductYStart))
				|| ((nextWorkspaceProductYStart >= workspaceProductYStart) && (nextWorkspaceProductYStart < workspaceProductYEnd));

			return hasOverlappingWorkspaceProductsX && hasOverlappingWorkspaceProductsY;
		});
	});
};

const getJoinableSidesOrientations = ({
	joinableSideOrientation = '',
	joinableSides = [],
	orientationByJoinableSidesMap = {},
}: any): Array<string> => {
	return joinableSides.reduce((accumulatedJoinableSidesOrientations: Array<string>, joinableSide = '') => {
		const productSelectorValueJoinableSideOrientation = orientationByJoinableSidesMap[joinableSide as keyof typeof orientationByJoinableSidesMap];

		const productSelectorValueOpposingJoinableSideOrientation = opposingOrientationMap[productSelectorValueJoinableSideOrientation as keyof typeof opposingOrientationMap];

		if (productSelectorValueOpposingJoinableSideOrientation !== joinableSideOrientation) {
			return [...accumulatedJoinableSidesOrientations, productSelectorValueJoinableSideOrientation];
		}

		return accumulatedJoinableSidesOrientations;
	}, []);
};

export const getValidWorkspaceProductJoinableModelByOrientation = ({
	joinableSideOrientation = '',
	orientationByJoinableSidesMap = {},
	productSelectorValue: {
		preselectedProductModel: {
			filteredBackSides = [],
			filteredJoinableSides = [],
		} = {},
	} = {},
	workspaceProductJoinableModel = {},
	workspaceProductModel,
}: any): any => {
	const {
		additionalBackSides = [],
		orientationByJoinableSidesMap: workspaceProductOrientationByJoinableSidesMap = {},
		productModel: {
			filteredBackSides: workspaceProductFilteredBackSides = [],
		} = {},
	} = workspaceProductModel || {};

	const opposingJoinableSide = filteredJoinableSides.find((joinableSide = '') => {
		const newJoinableSideOrientation = orientationByJoinableSidesMap[joinableSide as keyof typeof orientationByJoinableSidesMap];

		const opposingJoinableSideOrientation = opposingOrientationMap[newJoinableSideOrientation as keyof typeof opposingOrientationMap];

		return joinableSideOrientation === opposingJoinableSideOrientation;
	});

	if (!opposingJoinableSide) {
		return null;
	}

	const workspaceProductFilteredBackSidesToUse = [...workspaceProductFilteredBackSides, ...additionalBackSides];

	const opposingJoinableSideOrientation = opposingOrientationMap[joinableSideOrientation as keyof typeof opposingOrientationMap];

	const backSidesOrientations = filteredBackSides.map((backSide = '') => {
		const backSideOrientation = orientationByJoinableSidesMap[backSide as keyof typeof orientationByJoinableSidesMap];

		return ![joinableSideOrientation, opposingJoinableSideOrientation].includes(backSideOrientation) && backSideOrientation;
	}).filter(Boolean);

	const workspaceProductBackSidesOrientations = workspaceProductFilteredBackSidesToUse.map((backSide = '') => {
		const workspaceProductBackSideOrientation = workspaceProductOrientationByJoinableSidesMap[backSide as keyof typeof workspaceProductOrientationByJoinableSidesMap];

		return ![joinableSideOrientation, opposingJoinableSideOrientation].includes(workspaceProductBackSideOrientation) && workspaceProductBackSideOrientation;
	}).filter(Boolean);

	const validBacksidesOrientation = workspaceProductBackSidesOrientations.length
		? backSidesOrientations.every((backSideOrientation = '') => {
			return backSideOrientation !== opposingJoinableSideOrientation && workspaceProductBackSidesOrientations.includes(backSideOrientation);
		})
		: true;

	return validBacksidesOrientation && workspaceProductJoinableModel;
};

export const getValidWorkspaceProductOrientations = ({
	nextWorkspaceProductModel = null,
	productSelectorValue = {},
	productSelectorValue: {
		preselectedProductModel = {},
		preselectedProductModel: {
			connectables: {
				joinableSides = [],
			} = {},
		} = {},
	} = {},
	workspaceProductJoinableModel = {},
	workspaceProductJoinableModel: {
		joinableSideOrientation = '',
		opposingJoinableSideOrientation = '',
	} = {},
	workspaceProductModel = {},
}: any): any => {
	const {
		productSelectorValue: nextProductSelectorValue = {},
		workspaceProductJoinableModelsByOrientation: nextWorkspaceProductJoinableModelsByOrientation = {},
	} = nextWorkspaceProductModel || {};

	const nextWorkspaceProductJoinableModel = nextWorkspaceProductJoinableModelsByOrientation[opposingJoinableSideOrientation as keyof typeof nextWorkspaceProductJoinableModelsByOrientation];

	const validWorkspaceProductOrientations: Array<any> = Object.entries(orientationByJoinableSidesMapByOrientation).map(([orientation = '', orientationByJoinableSidesMap = {}]) => {
		const validWorkspaceProductJoinableModel = getValidWorkspaceProductJoinableModelByOrientation({
			joinableSideOrientation,
			orientationByJoinableSidesMap,
			productSelectorValue,
			workspaceProductJoinableModel,
			workspaceProductModel,
		});

		if (!validWorkspaceProductJoinableModel) {
			return null;
		}

		const joinableSidesOrientations = getJoinableSidesOrientations({
			joinableSideOrientation,
			joinableSides,
			orientationByJoinableSidesMap,
		});

		const tempWorkspaceProductModel = {
			orientationByJoinableSidesMap: orientationByJoinableSidesMapByOrientation[orientation as keyof typeof orientationByJoinableSidesMapByOrientation],
			productModel: preselectedProductModel,
		};

		const nextWorkspaceProductOrientations: Array<any> = nextWorkspaceProductJoinableModel
			? Object.entries(orientationByJoinableSidesMapByOrientation)
				.map(([nextOrientation = '', nextOrientationByJoinableSidesMap = {}]) => {
					return joinableSidesOrientations.reduce((accumulatedNextOrientations: any, nextJoinableSideOrientation = '') => {
						const nextValidWorkspaceProductJoinableModel = getValidWorkspaceProductJoinableModelByOrientation({
							joinableSideOrientation: nextJoinableSideOrientation,
							orientationByJoinableSidesMap: nextOrientationByJoinableSidesMap,
							productSelectorValue: nextProductSelectorValue,
							workspaceProductJoinableModel: nextWorkspaceProductJoinableModel,
							workspaceProductModel: tempWorkspaceProductModel,
						});

						if (!nextValidWorkspaceProductJoinableModel) {
							return accumulatedNextOrientations;
						}

						return [
							...accumulatedNextOrientations,
							{
								orientation: nextOrientation,
								workspaceProductJoinableModel: nextValidWorkspaceProductJoinableModel,
							},
						];
					}, []);
				}).filter(nextOrientations => Boolean(nextOrientations.length))
			: [];

		return {
			nextWorkspaceProductOrientations,
			orientation,
			workspaceProductJoinableModel: validWorkspaceProductJoinableModel,
		};
	}).filter(Boolean);

	return validWorkspaceProductOrientations;
};

export const getWorkspaceProductData = ({
	workspaceProductJoinableModelsWithNextWorkspaceProductModel = [],
	orientation = '',
	productModel: {
		articleNumber = '',
	},
}: IWorkspaceProductSectionals): any => {
	const joinables = workspaceProductJoinableModelsWithNextWorkspaceProductModel.map(({
		joinableSide = '',
		nextWorkspaceProductModel = null,
	}) => {
		if (nextWorkspaceProductModel) {
			return {
				joinableSide,
				workspaceProduct: getWorkspaceProductData(nextWorkspaceProductModel),
			};
		}

		return {};
	});

	const workspaceProduct = {
		articleNumber,
		joinables,
		orientation,
	};

	return workspaceProduct;
};

export const updateWorkspaceProductJoinableModels = (workspaceProductModel: IWorkspaceProductSectionals | any, workspaceProductsChunkOrientation = '') => {
	let workspaceProductJoinableXOrientation = 'W';

	if (workspaceProductsChunkOrientation === 'E') {
		workspaceProductJoinableXOrientation = 'N';
	}

	if (workspaceProductsChunkOrientation === 'S') {
		workspaceProductJoinableXOrientation = 'E';
	}

	if (workspaceProductsChunkOrientation === 'W') {
		workspaceProductJoinableXOrientation = 'S';
	}

	const {
		[`workspaceProductJoinableModel${workspaceProductJoinableXOrientation}`]: workspaceProductJoinableModelX,
		[`workspaceProductJoinableModel${workspaceProductsChunkOrientation}`]: workspaceProductJoinableModelY,
	} = workspaceProductModel;

	const {
		nextWorkspaceProductJoinableModel: nextWorkspaceProductJoinableModelX = null,
		nextWorkspaceProductModel: nextWorkspaceProductModelX = null,
	} = workspaceProductJoinableModelX || {};

	const {
		nextWorkspaceProductJoinableModel: nextWorkspaceProductJoinableModelY = null,
		nextWorkspaceProductModel: nextWorkspaceProductModelY = null,
	} = workspaceProductJoinableModelY || {};

	if (nextWorkspaceProductJoinableModelY) {
		workspaceProductJoinableModelY.nextWorkspaceProductJoinableModel = null;
		workspaceProductJoinableModelY.nextWorkspaceProductModel = null;
		workspaceProductJoinableModelY.prevWorkspaceProductJoinableModel = nextWorkspaceProductJoinableModelY;
		workspaceProductJoinableModelY.prevWorkspaceProductModel = nextWorkspaceProductModelY;

		nextWorkspaceProductJoinableModelY.nextWorkspaceProductJoinableModel = workspaceProductJoinableModelY;
		nextWorkspaceProductJoinableModelY.nextWorkspaceProductModel = workspaceProductModel;
		nextWorkspaceProductJoinableModelY.prevWorkspaceProductJoinableModel = null;
		nextWorkspaceProductJoinableModelY.prevWorkspaceProductModel = null;
	}

	if (nextWorkspaceProductJoinableModelY && nextWorkspaceProductModelY) {
		updateWorkspaceProductJoinableModels(nextWorkspaceProductModelY, workspaceProductsChunkOrientation);
	}

	if (nextWorkspaceProductJoinableModelX) {
		workspaceProductJoinableModelX.nextWorkspaceProductJoinableModel = null;
		workspaceProductJoinableModelX.nextWorkspaceProductModel = null;
		workspaceProductJoinableModelX.prevWorkspaceProductJoinableModel = nextWorkspaceProductJoinableModelX;
		workspaceProductJoinableModelX.prevWorkspaceProductModel = nextWorkspaceProductModelX;

		nextWorkspaceProductJoinableModelX.nextWorkspaceProductJoinableModel = workspaceProductJoinableModelX;
		nextWorkspaceProductJoinableModelX.nextWorkspaceProductModel = workspaceProductModel;
		nextWorkspaceProductJoinableModelX.prevWorkspaceProductJoinableModel = null;
		nextWorkspaceProductJoinableModelX.prevWorkspaceProductModel = null;
	}

	if (nextWorkspaceProductJoinableModelX && nextWorkspaceProductModelX) {
		updateWorkspaceProductJoinableModels(nextWorkspaceProductModelX, workspaceProductsChunkOrientation);
	}
};

export const updateWorkspacePreflightModel = ({
	workspacePreflightStore = {},
	workspaceProductModel = {},
	workspaceProductPreflightModel = {},
}: any): void => {
	const { workspaceProductJoinableModels = [] }: IWorkspaceProductSectionals = workspaceProductModel;

	const { workspaceProductJoinableModels: workspaceProductJoinablePreflightModels = [] }: IWorkspaceProductSectionals = workspaceProductPreflightModel;

	workspaceProductJoinableModels.forEach((workspaceProductJoinableModel, index) => {
		const { nextWorkspaceProductModel } = workspaceProductJoinableModel;

		const workspaceProductJoinablePreflightModel = workspaceProductJoinablePreflightModels[index];

		if (nextWorkspaceProductModel) {
			const {
				orientation = '',
				productSelectorValue,
			} = nextWorkspaceProductModel;

			workspacePreflightStore.addWorkspaceProductModel({
				orientation,
				productSelectorValue,
				workspaceProductJoinableModel: workspaceProductJoinablePreflightModel,
				workspaceProductModel: workspaceProductPreflightModel,
			});

			const { nextWorkspaceProductModel: nextWorkspaceProductPreflightModel } = workspaceProductJoinablePreflightModel;

			updateWorkspacePreflightModel({
				workspacePreflightStore,
				workspaceProductModel: nextWorkspaceProductModel,
				workspaceProductPreflightModel: nextWorkspaceProductPreflightModel,
			});
		}
	});
};
