import { SelectorProductModelFactory } from '~/product/sectionals/selector/Models/SelectorProduct.model';
import { SelectorValueProductModelFactory } from '~/product/sectionals/selector/Models/SelectorValueProduct.model';
import { noop } from '~/util/noop';

let createSelectorValueProductModel = noop;

export const createSelectorProductModel = ({
	productModels = [],
	questionSelectorModels = [],
	selectorData = {},
	selectorData: {
		questionGroupToAggregate = '',
		values: selectorValuesData = [],
	} = {},
}) => {
	const selectorValues = selectorValuesData.map((selectorValueData = {}) => {
		return createSelectorValueProductModel({
			productModels,
			questionGroupToAggregate,
			questionSelectorModels,
			selectorValueData,
		});
	});

	const selectorProductModel = SelectorProductModelFactory.create({
		...selectorData,
		productModels,
		selectorValues,
	});

	return selectorProductModel;
};

createSelectorValueProductModel = ({
	productModels = [],
	questionGroupToAggregate = '',
	questionSelectorModels = [],
	selectorValueData = {},
	selectorValueData: {
		articleNumbers: articleNumbersData = [],
		selectors: selectorsData = [],
	} = {},
}) => {
	const filteredProductModels = productModels.filter(({ articleNumber = '' }) => {
		return articleNumbersData.includes(articleNumber);
	}).filter(Boolean);

	const selectors = selectorsData.map((selectorData = {}) => {
		return createSelectorProductModel({
			productModels: filteredProductModels,
			questionSelectorModels,
			selectorData,
		});
	});

	const selectorValueProductModel = SelectorValueProductModelFactory.create({
		...selectorValueData,
		productModels: filteredProductModels,
		questionGroupToAggregate,
		questionSelectorModels,
		selectors,
	});

	return selectorValueProductModel;
};
