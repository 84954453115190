import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector.module.scss';

export const SelectorHeadingFrame = observer((props) => {
	const {
		isActive = false,
		selectedSelector: {
			label = '',
			title,
		} = {},
	} = props;

	const {
		workspaceModel = {},
		selectorConfigModel = {},
	} = useProductContext();

	const {
		hasWorkspaceProducts = false,
		workspaceProducts = [],
	} = workspaceModel;

	const {
		settings: {
			frameSelectorHeadingTitle,
			frameSelectorHeadingTitlePrefix = '',
			showHeadingSalesText = false,
			frameSelectorHeadingTitleSuffixSalesTextOverride = false,
		} = {},
	} = selectorConfigModel;

	const titleToUse = title || frameSelectorHeadingTitle;

	function workspaceProductSelectorHeadingText({
		index = 1, salesText = '', salesTextOverride = '', workspaceProductDimensions = ''
	}) {
		// show the salesText from the API or build up the text based on the dimensions of the product
		let headingText = showHeadingSalesText ? salesText : workspaceProductDimensions;

		// optionally add a prefix to the heading text e.g. Bookcase or Unit
		headingText = frameSelectorHeadingTitlePrefix ? `${frameSelectorHeadingTitlePrefix} ${index}: ${headingText}` : headingText;

		// optionally add a suffix of salesTextOverride from the API to the heading text
		headingText = frameSelectorHeadingTitleSuffixSalesTextOverride ? `${headingText} ${salesTextOverride}` : headingText;

		return headingText;
	}

	return (
		<div
			className="selector-heading-frame selector-heading"
			data-qa={`${kebabCase(label)}-selector-heading`}
		>
			<div className="selector-heading-label-container">
				<SelectorHeadingLabel label={label} />
			</div>
			{(!hasWorkspaceProducts || isActive) && titleToUse && (
				<span
					className={
						classNames(styles['selector-heading-title'], {
							'tw-hidden md:tw-block': isActive,
						})
					}
				>
					{titleToUse}
				</span>
			)}
			{hasWorkspaceProducts && !isActive && (
				<span
					className={`${styles['selector-heading-title']} tw-flex tw-flex-col`}
					data-qa={`${kebabCase(label)}-selector-heading-title`}
				>
					{workspaceProducts.map(({
						frameProductModel: {
							salesText = '',
							salesTextOverride = '',
						} = {},
						workspaceProductDimensions = '',
					}, index) => {
						return (
							<span
								className="selector-heading-title-item"
								key={`frame-product-${index}`}
							>
								{workspaceProductSelectorHeadingText({
									index: index + 1,
									salesText,
									salesTextOverride,
									workspaceProductDimensions,
								})}
							</span>
						);
					})
					}
				</span>
			)}
		</div>
	);
});
