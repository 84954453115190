import { computed, observable, makeObservable } from 'mobx';

import { s7ImagePath } from '~/global/global.constants';
import type { IProductSectionals } from '~/product/sectionals/Interfaces/ProductSectionals.interface';
import type { ISelectorProduct } from '~/product/sectionals/selector/Interfaces/SelectorProduct.interface';
import type { ISelectorQuestion } from '~/product/sectionals/selector/Interfaces/SelectorQuestion.interface';
import type { ISelectorValueProduct } from '~/product/sectionals/selector/Interfaces/SelectorValueProduct.interface';

class SelectorValueProductModel implements ISelectorValueProduct {
	articleNumbers: Array<string>

	imageSrc: string

	productModels: Array<IProductSectionals>

	questionGroupToAggregate: string

	questionSelectorModels: Array<ISelectorQuestion>

	sectionalDesignerDrapeableArticle: string

	selected: boolean

	selectors: Array<ISelectorProduct>

	stocked: boolean

	subtitle: string

	title: string

	constructor({
		articleNumbers = [],
		imageSrc = '',
		productModels = [],
		questionGroupToAggregate = '',
		questionSelectorModels = [],
		sectionalDesignerDrapeableArticle = '',
		selected = false,
		selectors = [],
		stocked = false,
		subtitle = '',
		title = '',
	}: any = {}) {
		makeObservable(this, {
			selected: observable,
			image: computed,
			imageUrl: computed,
			preselectedProductModel: computed,
			selectedSelector: computed,
		});

		this.articleNumbers = articleNumbers;
		this.imageSrc = imageSrc;
		this.productModels = productModels;
		this.questionGroupToAggregate = questionGroupToAggregate;
		this.questionSelectorModels = questionSelectorModels;
		this.sectionalDesignerDrapeableArticle = sectionalDesignerDrapeableArticle;
		this.selected = selected;
		this.selectors = selectors;
		this.stocked = stocked;
		this.subtitle = subtitle;
		this.title = title;
	}

	// this is a shim until all selectorValues look like custom
	get image(): any {
		return { imageSrc: this.imageSrc };
	}

	get imageUrl(): string {
		const {
			imageData: {
				imageUrl = '',
			} = {},
		} = this.preselectedProductModel;

		return imageUrl || `${s7ImagePath}/${this.imageSrc}`;
	}

	get isProductSelectorValue(): boolean {
		return !this.selectors.length;
	}

	get preselectedProductModel(): IProductSectionals {
		const [firstProductModel] = this.productModels;

		if (!this.questionGroupToAggregate) {
			return firstProductModel;
		}

		const questionSelectorModelToFilterBy = this.questionSelectorModels.find(({ questionGroups = [] }: ISelectorQuestion) => {
			return questionGroups.includes(this.questionGroupToAggregate);
		});

		const {
			selectedSelectorValue: {
				questionAnswers = [],
			} = {},
		}: any = questionSelectorModelToFilterBy;

		const validProductModel = this.productModels.find(({
			questionToAggregateModel: {
				answersModel = [],
			},
		}: IProductSectionals) => {
			return answersModel.find(({ key = '' }) => {
				return questionAnswers.some(({ answer = '' }) => answer === key);
			});
		});

		return validProductModel || firstProductModel;
	}

	get selectedSelector(): ISelectorProduct {
		return this.selectors.find(({ selected }) => selected) || this.selectors[0];
	}
}

export const SelectorValueProductModelFactory = ({
	create: (data: any = {}) => {
		return new SelectorValueProductModel(data);
	},
});
