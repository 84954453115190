import { observer } from 'mobx-react';
import React from 'react';

import { MagicTabccordionContainer } from '~/components/magic-tabccordion/Components/Containers/MagicTabccordionContainer';
import { SelectorSwatchCombinedSwatch } from '~/product/casegoods/selector/Components/swatch-combined/SelectorSwatchCombinedSwatch';
import { SelectorSwatchCombinedSwatchHeading } from '~/product/casegoods/selector/Components/swatch-combined/SelectorSwatchCombinedSwatchHeading';
import { selectorHeadingCasegoodsComponents } from '~/product/casegoods/selector/Components/selector-heading-casegoods-components';

import styles from '#/product/casegoods/selector/swatch-combined/selector-swatch-combined.module.scss';

export const SelectorSwatchCombined = observer((props) => {
	const {
		isActive = false,
		selectedSelector: {
			materialSelectorModels = [],
			selectorMaterialCombinedMagicTabccordionStore = {},
		} = {},
	} = props;

	return (
		<>
			<div className={`${styles['selector-swatch-combined-tabccordion']} md:tw-hidden`}>
				<MagicTabccordionContainer
					id="selector-swatch-combined-tabccordion"
					store={selectorMaterialCombinedMagicTabccordionStore}
				/>
			</div>
			<div className="tw-hidden md:tw-block">
				{
					materialSelectorModels.map((selectedSelector = {}, index) => {
						return (
							<React.Fragment key={`material-selector-${index}`}>
								<SelectorSwatchCombinedSwatchHeading
									hideOptionsCountWhenActive={true}
									isActive={isActive}
									selectedSelector={selectedSelector}
									selectorHeadingOverrideComponents={selectorHeadingCasegoodsComponents}
								/>
								<SelectorSwatchCombinedSwatch
									isActive={isActive}
									key={`selector-swatch-${index}`}
									selectedSelector={selectedSelector}
								/>
							</React.Fragment>
						);
					})
				}
			</div>
		</>
	);
});
