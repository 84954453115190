import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductControlsGroupCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-controls/WorkspaceProductControlsGroupCasegoods';

import styles from '#/product/casegoods/workspace/product-controls/workspace-products-controls.module.scss';

export const WorkspaceProductsControlsCasegoods = observer(() => {
	const { workspaceModel = {} } = useProductContext();

	const {
		sortedWorkspaceProducts = [],
	} = workspaceModel;

	return (
		<div className={`${styles['workspace-products-controls']} print:tw-hidden`}>
			{
				sortedWorkspaceProducts.map((workspaceProduct, index) => {
					return (
						<WorkspaceProductControlsGroupCasegoods
							key={`workspace-product-controls-${index}`}
							workspaceProduct={workspaceProduct}
						/>
					);
				})
			}
		</div>
	);
});
