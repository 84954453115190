import { observer } from 'mobx-react';
import React from 'react';

import { CylindoImage } from '~/components/Images/CylindoImage';

import styles from '#/product/common/media-set/media-cylindo-dimensions.module.scss';

export const MediaCylindoDimensions = observer(({
	mediaCylindoModel: {
		cylindoDimensionsImage = '',
	} = {},
}) => {
	return (
		<div
			className={styles['media-cylindo-dimensions']}
			data-qa="media-cylindo-dimensions"
		>
			<CylindoImage
				alt=""
				className={`media-cylindo-dimensions-image ${styles['media-cylindo-dimensions-image']}`}
				height="650"
				src={cylindoDimensionsImage}
				width="650"
			/>
		</div>
	);
});
