import {
	action, computed, reaction, when, makeObservable,
} from 'mobx';

import { isOnServer } from '~/global/global.constants';
import { DeliveryForm } from '~/components/magic-delivery/Components/DeliveryForm';
import { DeliveryMessaging } from '~/components/magic-delivery/Components/DeliveryMessaging';
import { EditZipCode } from '~/components/magic-delivery/Components/EditZipCode';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { MagicDeliveryFormStoreFactory } from '~/components/magic-delivery/Stores/MagicDeliveryForm.store';
import { MagicOverlayStoreFactory } from '~/components/magic-overlay/Stores/MagicOverlay.store';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { noop } from '~/util/noop';

class MagicDeliveryStore {
	formStore

	globalDynamicStore

	magicDeliveryModel

	magicOverlayStore

	summaryStore

	timeout

	timeoutDuration

	viewState

	zipCodeChangedDisposer

	constructor() {
		makeObservable(this, {
			getSummaryData: action.bound,
			fetchTransZoneData: action.bound,
			onFetchTransZoneSuccess: action.bound,
			trackZipCodeWidgetInteration: action.bound,
			viewStateSettings: computed,
		});
	}

	getSummaryData() {
		const summaryPromise = this.summaryStore.getSummaryData()
			.catch(() => {
				this.magicDeliveryModel.isInvalidTransZone = true;
			});

		return summaryPromise;
	}

	fetchTransZoneData() {
		const {
			transZoneStore: {
				model: transZoneModel = {},
			} = {},
		} = this.globalDynamicStore;

		// clean up any previous timeouts first
		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.timeout = setTimeout(() => {
			this.magicDeliveryModel.initialTransZoneTimeout = true;
		}, this.timeoutDuration);

		return new Promise((resolve, reject) => {
			when(() => typeof transZoneModel.hasTransZone !== 'undefined', () => {
				if (transZoneModel.hasTransZone) {
					when(() => (
						transZoneModel.hasTransZone
						&& transZoneModel.contentAreaKey
						&& transZoneModel.zipCode
					), () => {
						clearTimeout(this.timeout);

						resolve({
							hasTransZone: transZoneModel.hasTransZone,
							contentAreaKey: transZoneModel.contentAreaKey,
							zipCode: transZoneModel.zipCode,
						});
					});
				} else {
					clearTimeout(this.timeout);

					resolve({ hasTransZone: transZoneModel.hasTransZone });
				}
			});

			when(() => this.magicDeliveryModel.initialTransZoneTimeout, () => {
				console.warn('initialTransZoneTimeout reached.');
				clearTimeout(this.timeout);

				reject();
			});
		});
	}

	onFetchTransZoneSuccess(data) {
		this.magicDeliveryModel.updateTransZoneData(data);
		const {
			hasTransZone = false,
			hasTransZoneError = false,
			initialErrorView = '',
			initialView = '',
		} = this.magicDeliveryModel;

		if ((hasTransZoneError || this.initialTransZoneTimeout) && initialErrorView) {
			this.viewState.goTo(initialErrorView);
		} else if (initialView) {
			this.viewState.goTo(initialView);
		} else if (hasTransZone) {
			this.viewState.goTo('editZipCode');
		} else {
			this.viewState.goTo('deliveryForm');
		}
	}

	// eslint-disable-next-line consistent-return
	trackZipCodeWidgetInteration(trLinkEventCompName = null, trLinkEventCompType = null, linkEventStore) {
		if (!linkEventStore) {
			return null;
		}

		if (window.eventDataLayer && linkEventStore) {
			const linkEventTrackingData = {
				trLinkEventName: 'open zip code delivery widget',
				trLinkEventCompName,
				trLinkEventCompType,
				trLinkEventType: LinkEventTypes.SITE_ACTION,
			};
			linkEventStore.trackLinkEvent(linkEventTrackingData);
		}
	}

	get viewStateSettings() {
		return {
			loading: {
				component: () => null,
			},
			deliveryMessaging: {
				component: DeliveryMessaging,
				onEnter: (viewState, store, parameters = {}) => {
					const deliveryMessages = parameters.deliveryMessages || this.magicDeliveryModel.deliveryMessages || null;

					return { store, deliveryMessages };
				},
			},
			deliveryForm: {
				component: DeliveryForm,
				onEnter: (viewState, store, parameters = {}) => {
					Object.assign(this.magicDeliveryModel, {
						isInvalidTransZone: false,
						...parameters,
					});

					this.formStore.form.model.zipCode = '';

					return {
						store,
						autoFocusZipCode: parameters.autoFocusZipCode || false,
					};
				},
			},
			editZipCode: {
				component: EditZipCode,
				onEnter: (viewState, store) => ({ store }),
			},
		};
	}
}

export const MagicDeliveryStoreFactory = {
	create({
		featureTogglesModel = {},
		globalDynamicStore = {},
		magicDeliveryModel = {},
		summaryStore,
		timeout = undefined,
		timeoutDuration = 5000,
	} = {}) {
		if (isOnServer) {
			return false;
		}

		const magicDeliveryStore = new MagicDeliveryStore();

		const formStore = MagicDeliveryFormStoreFactory.create(magicDeliveryStore, globalDynamicStore);

		const magicOverlayStore = MagicOverlayStoreFactory.create({
			message: false,
			useSpinner: false,
		});

		const viewState = new ViewStateStore(magicDeliveryStore, magicDeliveryStore.viewStateSettings);

		let zipCodeChangedDisposer = noop;

		if (summaryStore) {
			when(() => magicDeliveryModel.hasInitialized, () => {
				zipCodeChangedDisposer = reaction(() => magicDeliveryModel.zipCode, magicDeliveryStore.getSummaryData)
			});
		}

		Object.assign(magicDeliveryStore, {
			formStore,
			globalDynamicStore,
			magicDeliveryModel,
			magicOverlayStore,
			summaryStore,
			timeout,
			timeoutDuration: featureTogglesModel.isOn('SPOOF_SLOW_TZONE') ? 0 : timeoutDuration,
			viewState,
			zipCodeChangedDisposer,
		});

		magicDeliveryStore.viewState.goTo('loading');

		magicDeliveryStore.fetchTransZoneData()
			.then(magicDeliveryStore.onFetchTransZoneSuccess)
			.catch(noop);

		return magicDeliveryStore;
	},
};
