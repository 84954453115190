import React from 'react';

import { selectorLinkComponents } from '~/product/common/selector/Components/selector-link-components';
import styles from '#/product/standard/selector/selector-links.module.scss';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

export const SelectorLinks = (props) => {
	const {
		links = [],
		product: {
			_links = {},
		} = {},
	} = props;

	const {
		productGroupModel: {
			name = '',
		} = {},
	} = useProductContext();

	if (!links.length) {
		return null;
	}

	return (
		<div className={styles['selector-links-container']}>
			<ul className={`${styles['selector-links']} u-noListStyle`} data-tr-link-event-comp-name={name} data-tr-link-event-comp-type="product selector promo">
				{
					links.map((link, index) => {
						const productLink = _links[link];

						const LinkRenderer = selectorLinkComponents[link];

						if (!LinkRenderer) {
							console.warn(`No selector link renderer found for "${link}"`);

							return null;
						}

						return (
							<li
								className="selector-link-container"
								key={`selector-link-${index}`}
							>
								<LinkRenderer productLink={productLink} />
							</li>
						);
					})
				}
			</ul>
		</div>
	);
};
