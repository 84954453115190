import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { kebabCase } from '~/util/kebabCase';

export const SelectorInsertTabccordionTabHeading = observer((props) => {
	const {
		selectorValueModel: {
			parentLabel = '',
			title = '',
		},
		isActive = false,
	} = props;

	return (
		<div
			className={
				classNames('selector-insert-tabccordion-tab-heading', {
					'selector-insert-tabccordion-tab-heading-selected tw-font-bold': isActive,
				})
			}
		>
			<span data-qa={`${kebabCase(parentLabel)}-type-heading`}>{title}</span>
		</div>
	);
});
