import { observer } from 'mobx-react';
import React from 'react';

import { DetailLinkIcon } from '~/product/common/details/Components/details/DetailLinkIcon';

export const DetailLinkStatic = observer((props) => {
	const {
		linkModel: {
			displayText = '',
			url = '',
			mediaIcon = '',
		} = {},
	} = props;

	return (
		<a
			className="detail-link detail-link-static tw-hidden-for-print"
			href={`/${url}`}
			rel="noopener noreferrer"
			target="_self"
		>
			{displayText}
			<DetailLinkIcon mediaIcon={mediaIcon} />
		</a>
	);
});
