import { createComfortSpectrumModel } from '~/product/common/comfort-spectrum/Helpers/ComfortSpectrum.init';
import { createDetailsModel } from '~/product/common/details/Helpers/Details.init';
import { createMediaSetModel } from '~/media-set/Helpers/MediaSet.init';
import { ProductModelFactory } from '~/product/common/Models/Product.model';
import { ProductAnswerModelFactory } from '~/product/common/product/Models/ProductAnswer.model';
import { ProductQuestionModelFactory } from '~/product/common/product/Models/ProductQuestion.model';

const createAnswersModel = (answersData = []) => {
	const answersModel = answersData.map((answerData) => {
		const answerModel = ProductAnswerModelFactory.create(answerData);

		return answerModel;
	});

	return answersModel;
};

const createQuestionsModel = (questionsData = []) => {
	const questionsModel = questionsData.map((questionData) => {
		const { answers: answersData = [] } = questionData;

		const answersModel = createAnswersModel(answersData);

		const questionModel = ProductQuestionModelFactory.create({
			...questionData,
			answersModel,
		});

		return questionModel;
	});

	return questionsModel;
};

export const createProductModel = ({
	configurationOverrideModel = {},
	configurationOverrideModel: {
		defaultProduct: configurationOverrideDefaultProductData = {},
		defaultProduct: {
			articleNumber: configurationOverrideDefaultProductArticleNumber = '',
		} = {},
	} = {},
	defaultProductData = {},
	defaultProductData: {
		articleNumber: defaultProductArticleNumber = '',
	} = {},
	productData = {},
	productData: {
		articleNumber = '',
	} = {},
	relativeProductGroupPath = '',
}) => {
	const isDefaultProduct = articleNumber === defaultProductArticleNumber;

	const isConfigurationOverrideDefaultProduct = articleNumber === configurationOverrideDefaultProductArticleNumber;

	const defaultProductDataToUse = isConfigurationOverrideDefaultProduct ? configurationOverrideDefaultProductData : defaultProductData;

	const productDataToUse = (isDefaultProduct || isConfigurationOverrideDefaultProduct) ? defaultProductDataToUse : productData;

	const { imageData: configurationOverrideImageData = {} } = configurationOverrideModel;

	const {
		comfortSpectrum: comfortSpectrumData = {},
		comfortSpectrumKeys: comfortSpectrumKeysData = {},
		detail: {
			additionalInformation: detailsAdditionalData = [],
			construction: detailsConstructionData = [],
			details: detailsAttributesData = [],
			dimensions: detailsDimensionsData = [],
			materials: detailsMaterialsData = [],
			sustainability: detailsSustainabilityData = [],
		} = {},
		dimensionMediaSet: mediaSetDimensionData = [],
		imageData: productImageData = {},
		imageData: {
			cylindo: cylindoData,
		} = {},
		mattressComfortSpectrum: mattressComfortSpectrumData = {},
		questions: questionsData = [],
	} = productDataToUse;

	const matchingConfigurationOverrideImageData = configurationOverrideImageData[articleNumber];

	const imageData = matchingConfigurationOverrideImageData || productImageData;

	// TODO: clean this up once the sever starts sending cylindo data in the image endpoint response
	const imageDataWithCylindo = {
		...imageData,
		cylindo: cylindoData,
	};

	const comfortSpectrumModel = createComfortSpectrumModel(comfortSpectrumData, comfortSpectrumKeysData);

	const mattressComfortSpectrumModel = createComfortSpectrumModel(mattressComfortSpectrumData);

	const detailsAdditionalModel = createDetailsModel(detailsAdditionalData);

	const detailsAttributesModel = createDetailsModel(detailsAttributesData);

	const detailsConstructionModel = createDetailsModel(detailsConstructionData);

	const detailsDimensionsModel = createDetailsModel(detailsDimensionsData);

	const detailsMaterialsModel = createDetailsModel(detailsMaterialsData);

	const detailsSustainabilityModel = createDetailsModel(detailsSustainabilityData);

	const mediaSetDimensionsModel = createMediaSetModel({ mediaSetData: mediaSetDimensionData });

	const questionsModel = createQuestionsModel(questionsData);

	const productModel = ProductModelFactory.create({
		...productDataToUse,
		comfortSpectrumModel,
		mattressComfortSpectrumModel,
		detailsAdditionalModel,
		detailsAttributesModel,
		detailsConstructionModel,
		detailsDimensionsModel,
		detailsMaterialsModel,
		detailsSustainabilityModel,
		imageData: imageDataWithCylindo,
		isLoaded: isDefaultProduct || isConfigurationOverrideDefaultProduct,
		mediaSetDimensionsModel,
		questionsModel,
		relativeProductGroupPath,
	});

	return productModel;
};

export const createProductModels = ({
	configurationOverrideModel = {},
	defaultProductData = {},
	productsData = [],
	relativeProductGroupPath = '',
}) => {
	const productModels = productsData.map(productData => createProductModel({
		configurationOverrideModel,
		defaultProductData,
		productData,
		relativeProductGroupPath,
	}));

	return productModels;
};

export const updateProductModel = ({
	productData: {
		_links = {},
		caption = '',
		comfortSpectrum: comfortSpectrumData = {},
		comfortSpectrumKeys: comfortSpectrumKeysData = [],
		components = [],
		delivery = {},
		detail: {
			additionalInformation: detailsAdditionalData = [],
			construction: detailsConstructionData = [],
			details: detailsAttributesData = [],
			dimensions: detailsDimensionsData = [],
			materials: detailsMaterialsData = [],
			sustainability: detailsSustainabilityData = [],
		} = {},
		dimensionMediaSet: mediaSetDimensionData = [],
		hasCylindo = false,
		hasCylindoDimensionImage = false,
		longSalesText = '',
		mattressComfortSpectrum: mattressComfortSpectrumData = {},
		maxQty = 0,
		nonCustomArticles = [],
		originImage,
		originImageText,
		questions: questionsData = [],
		status = {},
		summaryTitle = '',
	} = {},
	productModel = {},
}) => {
	const comfortSpectrumModel = createComfortSpectrumModel(comfortSpectrumData, comfortSpectrumKeysData);

	const mattressComfortSpectrumModel = createComfortSpectrumModel(mattressComfortSpectrumData);

	const detailsAdditionalModel = createDetailsModel(detailsAdditionalData);

	const detailsAttributesModel = createDetailsModel(detailsAttributesData);

	const detailsConstructionModel = createDetailsModel(detailsConstructionData);

	const detailsDimensionsModel = createDetailsModel(detailsDimensionsData);

	const detailsMaterialsModel = createDetailsModel(detailsMaterialsData);

	const detailsSustainabilityModel = createDetailsModel(detailsSustainabilityData);

	const mediaSetDimensionsModel = createMediaSetModel({ mediaSetData: mediaSetDimensionData });

	const questionsModel = createQuestionsModel(questionsData);

	Object.assign(productModel, {
		_links,
		caption,
		comfortSpectrumModel,
		components,
		delivery,
		detailsAdditionalModel,
		detailsAttributesModel,
		detailsConstructionModel,
		detailsDimensionsModel,
		detailsMaterialsModel,
		detailsSustainabilityModel,
		hasCylindo,
		hasCylindoDimensionImage,
		isLoaded: true,
		isLoading: false,
		longSalesText,
		mattressComfortSpectrumModel,
		maxQty,
		mediaSetDimensionsModel,
		nonCustomArticles,
		originImage,
		originImageText,
		questionsModel,
		status,
		summaryTitle,
	});

	return productModel;
};
