import { ShopThisRoomSubcategoryResponse, SUBCAT_DOCUMENT_TYPE } from '~/subcategory/common/subcategory.type';
import { breadcrumbsType } from '~/layout/Models/Breadcrumb.model';
import { ShopThisRoomProductModel } from '~/subcategory/shopThisRoom/Models/ShopThisRoomProduct.model';
import { SUBFEATURE_ID } from '~/features/Types/FeatureLibrary.type';

export class ShopThisRoomModel {
	data;

	altText;

	breadcrumbs: breadcrumbsType;

	canonicalUrl;

	hideRoomNavLinks;

	imageCaption;

	imageUrl;

	longName;

	metaDescription;

	nextRoomUrl;

	pageTitle;

	previousRoomUrl;

	shortName;

	subFeatureKey: SUBFEATURE_ID;

	type: SUBCAT_DOCUMENT_TYPE;

	constructor(data: ShopThisRoomSubcategoryResponse) {
		this.data = data;
		this.altText = data.document.altText;
		this.breadcrumbs = data.breadcrumbs;
		this.canonicalUrl = data.document.canonicalUrl;
		this.hideRoomNavLinks = data.hideRoomNavLinks;
		this.imageCaption = data.document.imageCaption;
		this.imageUrl = data.document.imageUrl;
		this.longName = data.document.longName;
		this.metaDescription = data.document.metaDescription;
		this.nextRoomUrl = data.nextRoomUrl;
		this.pageTitle = data.document.pageTitle;
		this.previousRoomUrl = data.previousRoomUrl;
		this.shortName = data.document.shortName;
		this.subFeatureKey = data.document.subFeatureKey;
		this.type = SUBCAT_DOCUMENT_TYPE.SHOP_THIS_ROOM;
	}

	get products() {
		const prods = this.data.document.products.map((product) => {
			return new ShopThisRoomProductModel(product);
		});
		return prods;
	}
}
