import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { s7ImagePath } from '~/global/global.constants';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { addToUrl } from '~/util/addToUrl';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector-drop-down.module.scss';

export const SelectorValueDropDown = observer((props) => {
	const {
		isActive = false,
		label = '',
		selectorIndex = 0,
		selectorValue,
		selectorValue: {
			imageSrc = '',
			preselectedProduct = { imageData: {} },
			selected,
			statusToRender = '',
			subtitle = '',
			title = '',
		},
		showImages,
	} = props;

	const { imageData } = preselectedProduct;

	const { imageUrl } = imageData;

	const imageUrlToUse = imageSrc ? `${s7ImagePath}/${imageSrc}` : imageUrl;

	const {
		selectorConfigStore,
		selectorConfigStores,
	} = useProductContext();

	function handleChange() {
		const selectorConfigStoreToUse = selectorConfigStores ? selectorConfigStores[selectorIndex] : selectorConfigStore;

		selectorConfigStoreToUse.selectSelectorValue(selectorValue);
	}

	return (
		<label
			className={
				classNames(styles['selector-value-drop-down'], {
					[styles['selector-value-drop-down-selected']]: selected,
				})
			}
			data-qa={`${kebabCase(label)}-selector-value`}
			title={title}
		>
			{
				showImages && (
					<S7Image
						alt=""
						className={styles['selector-value-drop-down-image']}
						height="85"
						priority={isActive}
						src={addToUrl(imageUrlToUse, '$proddd$')}
						width="155"
					/>
				)
			}
			<div className={classNames(styles['selector-value-drop-down-text'], {
				[styles['has-status']]: statusToRender,
			})}>
				{
					statusToRender && (
						<span className={
							classNames(styles['selector-value-drop-down-status'], {
								[styles['selector-value-drop-down-status-new']]: statusToRender === 'New',
							})
						}>{statusToRender}</span>
					)
				}
				<span
					className={styles['selector-value-drop-down-title']}
					data-qa={`${kebabCase(label)}-selector-value-title`}
				>
					{title}
				</span>
				{
					subtitle && (
						<span
							className={styles['selector-value-drop-down-subtitle']}
							data-qa={`${kebabCase(label)}-selector-value-subtitle`}
						>
							{subtitle}
						</span>
					)
				}
			</div>
			<input
				checked={selected}
				className="tw-sr-only"
				data-tr-link-event-track={false}
				onChange={handleChange}
				type="radio"
			/>
		</label>
	);
});
