import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { addToUrl } from '~/util/addToUrl';

import styles from '#/product/standard/selector/selector-material.module.scss';

export const SelectorValueMaterial = observer((props) => {
	const {
		formattedPriceGroupPrice = '',
		isActive = false,
		parentProductSelectorValue,
		selectorValue = {},
		selectorValue: {
			imageUrl = '',
			selected = false,
			title = '',
		},
		selectorValueIndex = 0,
		selectorValuesType = '',
	} = props;

	const {
		mediaSetDetailStore = {},
		selectorConfigStore = {},
	} = useProductContext();

	function handleChange() {
		runInAction(() => {
			mediaSetDetailStore.selectMediaModelHead();

			if (parentProductSelectorValue) {
				selectorConfigStore.selectProductSelectorValue(parentProductSelectorValue);
			}

			selectorConfigStore.selectSelectorValue(selectorValue);
		});
	}

	return (
		<div className={styles['selector-value-material-container']}>
			<label
				className={
					classNames(styles['selector-value-material'], {
						[styles['selector-value-material-selected']]: selected,
					})
				}
				data-qa="material-selector-value"
			>
				<S7Image
					alt=""
					className={styles['selector-value-material-image']}
					data-qa="material-selector-value-title"
					height="70"
					priority={isActive && selectorValueIndex <= 12}
					src={addToUrl(imageUrl, '$swsmall$')}
					width="70"
				/>
				<div className={styles['selector-value-material-text']}>
					<span
						className={styles['selector-value-material-title']}
						data-qa="material-selector-value-title"
					>
						{title}
					</span>
				</div>
				<input
					checked={selected}
					className="tw-sr-only"
					data-tr-link-event-track={false}
					name={`${selectorValuesType} - ${formattedPriceGroupPrice}`}
					onChange={handleChange}
					type="radio"
				/>
			</label>
		</div>
	);
});
