import React from 'react';

import { openSignInModalHandler } from '~/account/sign-in/Handlers/openSignInModalHandler';
import { Button } from '~/components/Buttons/Components/Button';
import { CloseButton } from '~/components/Buttons/Components/CloseButton';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { s7ImagePath } from '~/global/global.constants';

import styles from '~/global/header/Components/HeaderAccountNudge.module.scss';

export const HeaderAccountNudge = ({ closeButtonClickHandler }: { closeButtonClickHandler: () => void }) => {
	const {
		globalDynamicStore,
		HREF,
		magicModal,
	} = useGlobalContext();

	const openSignInOrAccountModal = (viewKey = '') => {
		openSignInModalHandler(viewKey, magicModal, globalDynamicStore, HREF);
	};

	const signInButtonClickHandler = () => {
		openSignInOrAccountModal('signIn');
	};

	const createAccountButtonClickHandler = () => {
		openSignInOrAccountModal('createAccount');
	};

	return (
		<div
			className={styles.accountNudge}
			data-qa="header-nudge"
		>
			<h3 className={styles.accountNudgeHeading}>
				Sign in for the best experience
			</h3>
			<div className={styles.accountNudgeClose}>
				<CloseButton
					data-qa="header-nudge-close-button"
					onClick={closeButtonClickHandler}
				/>
			</div>
			<ul className={styles.accountNudgeList} style={{ listStyleImage: `url(${s7ImagePath}/biPage21_checkmark?wid=20&fmt=png-alpha)` }}>
				<li>Check out faster</li>
				<li>Save and create Favorites lists</li>
				<li>Manage your orders</li>
			</ul>
			<div className={styles.accountNudgeButtons}>
				<Button
					data-qa="header-nudge-sign-in-button"
					onClick={signInButtonClickHandler}
				>
					Sign In
				</Button>
				<Button
					color={ButtonColor.Gray}
					data-qa="header-nudge-create-account-button"
					onClick={createAccountButtonClickHandler}
					variant={ButtonVariant.Hollow}
				>
					Create an Account
				</Button>
			</div>
		</div>
	);
};
