export const interstitialViewstates = {
	LOADING: 'loading',
	WISHLIST_INTERSTITIAL: 'wishlist',
	MULTI_SELECT: 'MULTI_SELECT',
	CUSTOM_BOOKCASE_DESIGNER: 'CUSTOM_BOOKCASE_DESIGNER',
	CUSTOM_SECTIONAL_BUILDER: 'CUSTOM_SECTIONAL_BUILDER',
	WISHLIST_ASSIGNED: 'submitted',
};

export const assignRoomApiUrl = '/api/web/shop/wishlists/assign-to-room';

export const assignRoomNoteApiUrl = '/api/web/shop/wishlists/set-room-note';
