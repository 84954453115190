import { observer } from 'mobx-react';
import React from 'react';

import { WorkspaceProductStackSlotCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductStackSlotCasegoods';
import { WorkspaceProductWidthCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductWidthCasegoods';
import { addToUrl } from '~/util/addToUrl';

import styles from '#/product/casegoods/workspace/product/workspace-product-stack.module.scss';

export const WorkspaceProductStackCasegoods = observer((props: any = {}) => {
	const {
		imageInchesToPixelsFactor,
		isMediaThumbnail = false,
		translateBottomInches,
		imageScale = 0,
		showDimensions = false,
		workspaceProduct = {},
		workspaceStore = {},
	} = props;
	const widthInches = workspaceProduct?.workspaceProductWidth || 0;
	const widthPixels = (imageInchesToPixelsFactor * widthInches) / imageScale;
	const translateBottomPixels = (imageInchesToPixelsFactor * translateBottomInches) / imageScale;
	const srcParams = `$proddd$&fmt=png-alpha&scl=${imageScale}`;
	const srcSetParams = `$proddd$&fmt=png-alpha&scl=${imageScale / 2}`;
	return (
		<div className={styles['workspace-product-stack']}>
			{showDimensions && !isMediaThumbnail
				&& <WorkspaceProductWidthCasegoods
					canShowWorkspaceErrorMessage={true}
					workspaceProduct={workspaceProduct}
					workspaceStore={workspaceStore}
				/>
			}
			<div className="tw-flex tw-flex-col">
				<img
					alt=""
					className="tw-z-20"
					src={addToUrl(workspaceProduct.frameProductModel.topUrl, srcParams)}
					srcSet={`${addToUrl(workspaceProduct.frameProductModel.topUrl, srcSetParams)} 2x`}
					style={{
						marginBottom: -translateBottomPixels,
						marginTop: translateBottomPixels,
						width: widthPixels,
					}}
				/>
				<div className="tw-flex tw-flex-col-reverse tw-z-10">
					{workspaceProduct.workspaceProductSlotModels.map((slot: any, index: number) => {
						return (!slot.hasInsertProductModel) ? null : (
							<WorkspaceProductStackSlotCasegoods
								key={index}
								slot={slot}
								index={index}
								translateBottomPixels={translateBottomPixels}
								imageInchesToPixelsFactor={imageInchesToPixelsFactor}
								imageScale={imageScale}
							/>
						);
					})}
				</div>
				<img
					alt=""
					src={addToUrl(workspaceProduct.frameProductModel.baseUrl, srcParams)}
					srcSet={`${addToUrl(workspaceProduct.frameProductModel.baseUrl, srcSetParams)} 2x`}
					style={{ width: widthPixels }}
				/>
			</div>
		</div>
	);
});
