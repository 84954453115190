import { computed, observable, makeObservable } from 'mobx';

import { apiUrl } from '~/global/global.constants';
import { getProductConfiguration } from '~/product/casegoods/selector/Utils/SelectorConfigCasegoods.utils';
import { questionParams } from '~/product/casegoods/selector/selector-casegoods.constants';
import { getFlattenedSelectors, getFlattenedSelectorValues } from '~/product/common/selector/Utils/SelectorConfig.utils';
import { addToUrl } from '~/util/addToUrl';

export class SelectorConfigCasegoods {
	_articleHref;

	customQuestionAnswersData;

	defaultConfigModel;

	defaultInsertConfigModel;

	defaultProduct;

	downloadSelectorModel;

	frameSelectorModel;

	imageScale;

	_insertQuestionAnswer;

	insertMaterialSelectorModels;

	insertsSelectorModel;

	lastSelectedSelectorValue;

	materialCombinedSelectorModel;

	materialSelectorModels;

	optionsSelectorModel;

	productModels;

	questionAnswerSelectorModels;

	settings;

	shouldCombineMaterialSelectors;

	workspaceProductStores;

	constructor() {
		makeObservable(this, {
			insertsSelectorModel: observable.ref,
			lastSelectedSelectorValue: observable,
			optionsSelectorModel: observable.ref,
			productModels: observable,
			workspaceProductStores: observable.ref,
			allProductModels: computed,
			allProductQuestionModels: computed,
			flattenedInsertArticles: computed,
			flattenedInsertMaterialSelectorValues: computed,
			flattenedMaterialSelectorValues: computed,
			flattenedOptionsSelectors: computed,
			flattenedOptionsSelectorsAndSelectorValues: computed,
			flattenedOptionsSelectorValues: computed,
			flattenedSelectors: computed,
			flattenedSelectorsAndSelectorValues: computed,
			flattenedSelectorValues: computed,
			frameAPIUrl: computed,
			hasInsertsSelector: computed,
			hasOptionsSelector: computed,
			hasSelectorsToRender: computed,
			insertConfiguration: computed,
			insertQuestionAnswer: computed,
			materialSelectorModelsToRender: computed,
			materialSelectorWithNonMatchingProducts: computed,
			productSelectorValues: computed,
			questionAnswerSelectors: computed,
			questionAnswerSelectorValues: computed,
			selectedAnswerSelectorValues: computed,
			selectedInsertMaterialSelectorValues: computed,
			selectedMaterialSelectorValues: computed,
			selectedOptionsSelectorValues: computed,
			selectedProduct: computed,
			selectedProductConfiguration: computed,
			selectedProductSelectorValue: computed,
			selectedQuestionSelectors: computed,
			selectedSelectorValues: computed,
			sortedSelectedSelectorsToRender: computed,
			widthSelectorValues: computed,
		});
	}

	get allProductModels() {
		const { productModels: insertProductModels = [] } = this.insertsSelectorModel;

		return [...this.productModels, ...insertProductModels];
	}

	get allProductQuestionModels() {
		return this.allProductModels.reduce((accumulatedQuestionModels, { questionModels = [] }) => {
			return [...accumulatedQuestionModels, ...questionModels];
		}, []);
	}

	get articleHref() {
		return this._articleHref;
	}

	set articleHref(href) {
		this._articleHref = href;
	}

	get flattenedInsertArticles() {
		const { selectorValues = [] } = this.insertsSelectorModel;

		return selectorValues.reduce((accumulatedArticles, { articles = [] }) => {
			return [...accumulatedArticles, ...articles];
		}, []);
	}

	get flattenedInsertMaterialSelectorValues() {
		const insertMaterialSelectorValues = this.insertMaterialSelectorModels.reduce((accumulatedSelectorValues, { selectorValues = [] }) => {
			return [...accumulatedSelectorValues, ...selectorValues];
		}, []);

		return getFlattenedSelectorValues(insertMaterialSelectorValues);
	}

	get flattenedMaterialSelectorValues() {
		const materialSelectorValues = this.materialSelectorModels.reduce((accumulatedSelectorValues, { selectorValues = [] }) => {
			return [...accumulatedSelectorValues, ...selectorValues];
		}, []);

		const insertMaterialSelectorValues = this.insertMaterialSelectorModels.reduce((accumulatedSelectorValues, { selectorValues = [] }) => {
			return [...accumulatedSelectorValues, ...selectorValues];
		}, []);

		const productAndInsertMaterialSelectorValues = materialSelectorValues.concat(insertMaterialSelectorValues);

		return getFlattenedSelectorValues(productAndInsertMaterialSelectorValues);
	}

	get flattenedOptionsSelectors() {
		return getFlattenedSelectors([this.optionsSelectorModel]);
	}

	get flattenedOptionsSelectorsAndSelectorValues() {
		return [...this.flattenedOptionsSelectors, ...this.flattenedOptionsSelectorValues];
	}

	get flattenedOptionsSelectorValues() {
		const { selectorValues = [] } = this.optionsSelectorModel;

		return getFlattenedSelectorValues(selectorValues);
	}

	get flattenedSelectors() {
		return getFlattenedSelectors([this.frameSelectorModel]);
	}

	get flattenedSelectorsAndSelectorValues() {
		return [...this.flattenedSelectors, ...this.flattenedSelectorValues];
	}

	get flattenedSelectorValues() {
		const { selectorValues = [] } = this.frameSelectorModel;

		return getFlattenedSelectorValues(selectorValues);
	}

	get frameAPIUrl() {
		if (this.insertQuestionAnswer) return `${apiUrl}${this.articleHref}${this.insertQuestionAnswer}`;

		let url = `${apiUrl}${this.articleHref}`;

		Object.keys(questionParams).map((question) => {
			const param = this.selectedSelectorValues.find(selectorMaterial => selectorMaterial.question === question)?.answer;
			if (param) {
				url = addToUrl(url, `question=${questionParams[question]?.param}%3A${param}`);
			}
			return param;
		});

		return url;
	}

	get hasInsertsSelector() {
		const { renderer = '' } = this.insertsSelectorModel;

		return Boolean(renderer);
	}

	get hasOptionsSelector() {
		const { renderer = '' } = this.optionsSelectorModel;

		return Boolean(renderer);
	}

	get hasSelectorsToRender() {
		return Boolean(this.sortedSelectedSelectorsToRender.length);
	}

	get insertConfiguration() {
		return this.selectedInsertMaterialSelectorValues.reduce((accumulatedConfiguration = {}, { questionAnswers = {} } = {}) => {
			return {
				...accumulatedConfiguration,
				...questionAnswers,
			};
		}, {});
	}

	get insertQuestionAnswer() {
		return this._insertQuestionAnswer;
	}

	set insertQuestionAnswer(key) {
		this._insertQuestionAnswer = key;
	}

	get materialSelectorModelsToRender() {
		return this.settings.showAllMaterialSelectors
			? this.materialSelectorModels
			: this.materialSelectorModels.filter(({ isVisible = true }) => isVisible);
	}

	// Find the material selector where not every product has some corresponding selector value
	get materialSelectorWithNonMatchingProducts() {
		return this.materialSelectorModels
			.find(({ selectorValues = [] }) => {
				const [firstSelectorValue] = selectorValues;
				const { question = '' } = firstSelectorValue;

				// WIT-16645: the !questionModels.length is a shim to deal with bad frameProduct data where it was missing all its questions
				return !this.productModels
					.every(({ questionModels = [] }) => !questionModels.length || questionModels
						.some(({ key = '' }) => {
							return question === key;
						}));
			});
	}

	get productSelectorValues() {
		return this.flattenedSelectorValues.filter(({ productSelectorValue }) => productSelectorValue);
	}

	get questionAnswerSelectors() {
		return this.flattenedSelectors.filter(({ displayType = '' }) => displayType === 'QUESTION_ANSWER');
	}

	get questionAnswerSelectorValues() {
		return this.flattenedSelectorValues.filter(({ parentDisplayType = '' }) => parentDisplayType === 'QUESTION_ANSWER');
	}

	get selectedAnswerSelectorValues() {
		return this.questionAnswerSelectorValues.filter(({ selected }) => selected);
	}

	get selectedInsertMaterialSelectorValues() {
		return this.flattenedInsertMaterialSelectorValues.filter(({ selected }) => selected);
	}

	get selectedMaterialSelectorValues() {
		return this.flattenedMaterialSelectorValues.filter(({ selected }) => selected);
	}

	get selectedOptionsSelectorValues() {
		return this.flattenedOptionsSelectorValues.filter(({ selected }) => selected);
	}

	get selectedProduct() {
		const { productModels = [] } = this.selectedProductSelectorValue || {};

		const [selectorValueProductModel = {}] = productModels;

		const [firstProductModel] = this.productModels;

		return selectorValueProductModel || firstProductModel;
	}

	get selectedProductConfiguration() {
		return getProductConfiguration({
			productModel: this.selectedProduct,
			selectedQuestionSelectorValues: [...this.selectedAnswerSelectorValues, ...this.selectedMaterialSelectorValues],
		});
	}

	get selectedProductSelectorValue() {
		const [firstProductSelectorValue] = this.productSelectorValues;

		return this.productSelectorValues.find(({ selected = false }) => selected) || firstProductSelectorValue;
	}

	get selectedQuestionSelectors() {
		return this.questionAnswerSelectors.filter(({ selected }) => selected);
	}

	// normalize selectedMaterialSelectorValues into selectedSelectorValues so details get picked up correctly
	get selectedSelectorValues() {
		return this.selectedMaterialSelectorValues;
	}

	get sortedSelectedSelectorsToRender() {
		const materialSelectorsToUse = this.shouldCombineMaterialSelectors ? [this.materialCombinedSelectorModel] : this.materialSelectorModelsToRender;
		return this.settings.hideFrameSelector
			? [
				this.insertsSelectorModel,
				...materialSelectorsToUse,
				this.downloadSelectorModel,
			]
			: [
				this.frameSelectorModel,
				...materialSelectorsToUse,
				...(this.hasInsertsSelector ? [this.insertsSelectorModel] : []),
				...(this.insertMaterialSelectorModels.length ? this.insertMaterialSelectorModels : []),
				...(this.hasInsertsSelector && !this.hasOptionsSelector ? [this.downloadSelectorModel] : []),
				...(this.hasOptionsSelector ? [this.optionsSelectorModel] : []),
			];
	}

	get widthSelectorValues() {
		return this.flattenedSelectorValues.filter(({
			parentDisplayType = '',
			parentType = '',
		}) => {
			return parentDisplayType !== 'QUESTION_ANSWER' && parentType === 'WIDTH';
		});
	}
}

export const SelectorConfigCasegoodsModelFactory = ({
	create: ({
		customQuestionAnswersData = {},
		defaultConfigModel = {},
		defaultInsertConfigModel = {},
		defaultProduct = {},
		downloadSelectorModel = {},
		frameSelectorModel = {},
		imageScale = 0,
		insertMaterialSelectorModels = [],
		insertsSelectorModel = {},
		materialCombinedSelectorModel = {},
		materialSelectorModels = [],
		optionsSelectorModel = {},
		productModels = [],
		settings = {},
		shouldCombineMaterialSelectors = false,
		workspaceProductStores = [],
	}) => {
		const selectorConfigCasegoods = new SelectorConfigCasegoods();

		Object.assign(selectorConfigCasegoods, {
			customQuestionAnswersData,
			defaultConfigModel,
			defaultInsertConfigModel,
			defaultProduct,
			downloadSelectorModel,
			frameSelectorModel,
			imageScale,
			insertMaterialSelectorModels,
			insertsSelectorModel,
			materialCombinedSelectorModel,
			materialSelectorModels,
			optionsSelectorModel,
			productModels,
			settings,
			shouldCombineMaterialSelectors,
			workspaceProductStores,
		});

		return selectorConfigCasegoods;
	},
});
