import React, { useState } from 'react';
import classNames from 'classnames';

import styles from './ShopThisRoomLayout.module.scss';

import type { breadcrumbsType } from '~/layout/Models/Breadcrumb.model';
import type { ShopThisRoomProductModel } from '~/subcategory/shopThisRoom/Models/ShopThisRoomProduct.model';
import { ReviewStars } from '~/components/review-stars/ReviewStars';
import { S7Image } from '~/components/Images/S7Image';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { AddToFavoritesButton } from '~/components/Buttons/ButtonAddToFavorites/AddToFavoritesBtn';
import { AddToFavoritesButtonModel } from '~/components/Buttons/ButtonAddToFavorites/Models/AddToFavoritesButton.model';
import { SUBCAT_DOCUMENT_TYPE } from '~/subcategory/common/subcategory.type';
import { AddToFavoritesButtonStore } from '~/components/Buttons/ButtonAddToFavorites/Stores/AddToFavoritesButton.store';
import { ClickOrTouchEventHandler } from '~/components/product-group/Types/ProductGroup.type';
import { AddToFavoritesStore } from '~/favorites/add/Stores/AddToFavorites.store';
import { FavoritesTrackingEventsStore } from '~/favorites/Stores/FavoritesEvents.tracking.store';

export const ShopThisRoomProduct = ({
	product,
	canonicalUrl,
	breadcrumbs = [],
	isTray = false,
}: { product: ShopThisRoomProductModel, canonicalUrl: string, breadcrumbs: breadcrumbsType, isTray?: Boolean }) => {
	const {
		articleNumber,
		addToFavoritesAPI,
		addToFavoritesType,
		addToFavoritesPropertiesAPI,
		imageUrl,
		moreOptions,
		name,
		priceRange,
		reviews,
		reviews: {
			count: reviewsCount = 0,
			reviewsAverageOverallRating: starCount = 0,
		},
		status,
		url,
	} = product;

	const {
		globalDynamicStore,
	} = useGlobalContext();

	const [isAnimating, setIsAnimating] = useState(false);

	const addToFavModel = new AddToFavoritesButtonModel({
		addToFavoritesAPI,
		addToFavoritesType,
		addToFavoritesPropertiesAPI,
		breadcrumbs,
		productGroupName: name,
		pageType: isTray ? 'PRODUCT_PAGE' : SUBCAT_DOCUMENT_TYPE.SHOP_THIS_ROOM,
		productGroupURL: url,
	});

	const addToFavStore = new AddToFavoritesButtonStore({ model: addToFavModel });

	const addToFavoritesButtonClasses = classNames(styles['product-group-fav-btn'], {
		'tw-opacity-100': isAnimating,
	});

	const addToFavoritesClickHandler: ClickOrTouchEventHandler = () => {
		setIsAnimating(true);
		setTimeout(() => {
			setIsAnimating(false);
		}, 1400);
		addToFavStore.getFavoritesProperties().then(() => {
			const addToFavoritesStore = new AddToFavoritesStore({
				addToFavoritesAPI: addToFavModel.addToFavoritesAPI,
				productDTO: JSON.parse(JSON.stringify(addToFavModel.productDTO)),
				trackingEventsStore: new FavoritesTrackingEventsStore({}),
			});

			addToFavoritesStore.successCallback = (() => {
				globalDynamicStore.fetchData();
			});
			addToFavoritesStore.setTargetFavoriteListID(addToFavModel.targetFavoriteListID);
			addToFavoritesStore.submitHandler();
		});
	};

	return (
		<li key={`product-${articleNumber}`} data-qa="product-group">
			<div
				aria-label="product"
				role="group"
				className={styles.ShopThisRoomProduct}
			>
				<a
					className={styles.ShopThisRoomLink}
					data-tr-link-event-name={name}
					href={`${canonicalUrl}/${url}`}
				>
					<div className={styles.ProductImageWrapper}>
						<S7Image
							alt=""
							height={isTray ? 182 : 260}
							src={imageUrl}
							sizes={`(max-width: 900px) 50vw, ${isTray ? '270px' : '385px'}`}
							width={isTray ? 270 : 385}
							priority={true}
						/>
					</div>
					<div className={styles.ProductDetails} data-qa="pg-details">
						<span className={styles.ProductStatus}>{status?.status} &nbsp;</span>
						<h2 data-qa="pg-name" className={styles.ProductName}>{name}</h2>
						<div className={styles.ProductPrice} data-qa="pg-price">{priceRange}</div>
						{moreOptions && <div className={styles.ProductOptions} data-qa="pg-options">{moreOptions}</div>}
						{reviews && Number(starCount) > 0 && <div className="tw-flex tw-mt-1">
							<ReviewStars starCount={starCount} reviewsCount={reviewsCount} />
						</div>}
					</div>
				</a>
				{ addToFavoritesAPI &&
					<AddToFavoritesButton
						btnClassNames={addToFavoritesButtonClasses}
						clickHandler={addToFavoritesClickHandler}
						trLinkEventCompName={addToFavModel.favTrLinkEventCompName}
						trLinkEventCompType={addToFavModel.favTrLinkEventCompType}
						trLinkEventName="add to favorites list icon"
						isAnimating={isAnimating}
					/>
				}
			</div>
		</li>
	);
};
