import React from 'react';

import { DetailImage } from '~/product/common/details/Components/details/DetailImage';
import { detailComponents } from '~/product/common/details/Components/detail-components';
import styles from '#/product/standard/selector/selector-details.module.scss';

export const SelectorDetails = (props) => {
	const {
		isActive = false,
		selectorDetailImage,
		selectorDetails = [],
	} = props;

	return (
		<div className={`${styles['selector-details-container']} tw-hidden md:tw-flex`}>
			{
				selectorDetailImage && (
					<DetailImage
						detail={selectorDetailImage}
						priority={isActive}
					/>
				)
			}
			<ul className={`${styles['selector-details']} u-noListStyle`}>
				{
					selectorDetails.map((detail, index) => {
						const { type } = detail;

						let DetailRenderer = detailComponents[type];

						if (!DetailRenderer) {
							DetailRenderer = detailComponents.nameValue;

							console.warn(`No selector detail renderer found for "${type}"`);
						}

						return (
							<li
								className="selector-detail-container"
								key={`selector-detail-${index}`}
							>
								<DetailRenderer detail={detail} />
							</li>
						);
					})
				}
			</ul>
		</div>
	);
};
