import { FunctionComponent } from 'react';

import type { RENDERER_KEY } from '~/product/casegoods/selector/Types/SelectorCasegoods.types';

import { SelectorDraggable } from '~/product/casegoods/selector/Components/draggable/SelectorDraggable';
import { SelectorFrameModal } from '~/product/casegoods/selector/Components/frame-modal/SelectorFrameModal';
import { SelectorNone } from '~/product/casegoods/selector/Components/none/SelectorNone';
import { SelectorStaticHeading } from '~/product/casegoods/selector/Components/static-heading/SelectorStaticHeading';

export type RecursiveCasegoodsComponentType = {
	[key in RENDERER_KEY]: FunctionComponent<any>
};

export const selectorRecursiveCasegoodsComponents: RecursiveCasegoodsComponentType = {
	DRAGGABLE: SelectorDraggable,
	FRAME_MODAL: SelectorFrameModal,
	NONE: SelectorNone,
	STATIC_HEADING: SelectorStaticHeading,
};
