import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductSlotCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-slot/WorkspaceProductSlotCasegoods';

import styles from '#/product/casegoods/workspace/product-slot/workspace-product-slots.module.scss';

export const WorkspaceProductSlotsCasegoods = observer((props = {}) => {
	const {
		imageScale = 0,
		workspaceProduct: {
			frameProductModel: {
				depth = 0,
				slotsColumnCount = 0,
				slotsRowCount = 0,
				width = 0,
			} = {},
			workspaceProductSlotModels = [],
		} = {},
	} = props;

	const {
		workspaceModel: {
			dimensions: {
				imageInchesToPixelsFactor = 0,
				offsetBottomInches = 0,
				offsetTopInches = 0,
				offsetXInches = 0,
			} = {},
		} = {},
	} = useProductContext();

	const workspaceProductSlotsPixelsBottom = (imageInchesToPixelsFactor * offsetBottomInches) / imageScale;

	const workspaceProductSlotsPixelsTop = (imageInchesToPixelsFactor * offsetTopInches) / imageScale;

	const workspaceProductSlotsPixelsX = (imageInchesToPixelsFactor * offsetXInches) / imageScale;

	const slotHeightPercentage = 100 / slotsRowCount;

	const slotWidthPercentage = 100 / slotsColumnCount;

	return (
		<div
			className={styles['workspace-product-slots']}
			style={{ padding: `${workspaceProductSlotsPixelsTop}px ${workspaceProductSlotsPixelsX}px ${workspaceProductSlotsPixelsBottom}px` }}
		>
			{
				workspaceProductSlotModels.map((slotModel, index) => {
					return (
						<WorkspaceProductSlotCasegoods
							depth={depth}
							imageScale={imageScale}
							key={`product-slot-${index}`}
							slotHeightPercentage={slotHeightPercentage}
							slotModel={slotModel}
							slotWidthPercentage={slotWidthPercentage}
							width={width}
						/>
					);
				})
			}
		</div>
	);
});
