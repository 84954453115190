import React, { ComponentProps } from 'react';
// import classNames from 'classnames';

import { ButtonBase } from '~/components/Buttons/Components/ButtonBase';
import { ButtonFlasher } from '~/util/Components/ButtonFlasher';
import styles from '~/components/Buttons/ButtonAddToFavorites/AddToFavoritesBtn.module.scss';
import { noop } from '~/util/noop';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { s7ContentPath } from '~/global/global.constants';
import { ClickOrTouchEventHandler } from '~/components/product-group/Types/ProductGroup.type';

export interface IProps extends ComponentProps<typeof ButtonBase> {
	clickHandler: ClickOrTouchEventHandler | undefined
	btnClassNames?: string
	isAnimating?: boolean
	isDisabled?: boolean
	isPending?: boolean
	isResolved?: boolean
	trLinkEventCompName: string | null
	trLinkEventCompType: string | null
	trLinkEventName?: string
}

export const AddToFavoritesButton = (props: IProps) => {
	const {
		btnClassNames = '',
		clickHandler = noop,
		isAnimating = false,
		isDisabled = false,
		isPending = false,
		isResolved = false,
		trLinkEventCompName = null,
		trLinkEventCompType = null,
		trLinkEventName = null,
	} = props;

	if (isDisabled) {
		return null;
	}

	return (
		<ButtonFlasher
			className={`${styles.AddToFavoritesHeart} ${btnClassNames}`}
			data-qa="add-to-fav-button"
			data-tr-link-event-comp-name={trLinkEventCompName}
			data-tr-link-event-comp-type={trLinkEventCompType}
			data-tr-link-event-type={(LinkEventTypes as any).SITE_ACTION}
			data-tr-link-event-name={trLinkEventName}
			isAnimating={isAnimating}
			isResolved={isResolved}
			isPending={isPending}
			onClick={clickHandler}
			onTouchStart={clickHandler}
		>
			<img className={styles.heart} src={`${s7ContentPath}/favorite`} width="24" height="22" alt="" />
			<img className={styles.heartChecked} src={`${s7ContentPath}/favorite-check`} width="24" height="22" alt="" />
			<span className="tw-sr-only">Save to Favorites</span>
		</ButtonFlasher>
	);
};
