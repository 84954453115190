import { computed, makeObservable } from 'mobx';

class CartProductGroup {
	data

	products

	constructor() {
		makeObservable(this, {
			id: computed,
			isCustom: computed,
			totalPrice: computed,
			type: computed,
		});
	}

	get id() {
		return this.data.cartProductGroupId;
	}

	get isCustom() {
		return Boolean(this.data.isCustom);
	}

	get totalPrice() {
		return this.data.total;
	}

	get type() {
		return this.data.type;
	}
}

export const CartProductGroupFactory = ({
	create: (data = {}, products = []) => {
		const instance = new CartProductGroup();

		instance.data = data;
		instance.products = products;

		return instance;
	},
});
