import { observer } from 'mobx-react';
import React from 'react';

import { detailLinkComponents } from '~/product/common/details/Components/detail-link-components';

export const DetailLink = observer((props) => {
	const {
		detail: {
			linkModel = {},
			linkModel: {
				behavior = '',
			} = {},
		}
	} = props;

	const DetailLinkRenderer = detailLinkComponents[behavior];

	if (!DetailLinkRenderer) {
		// console.warn(`No detail link renderer found for "${behavior}"`);

		return null;
	}

	return (
		<DetailLinkRenderer linkModel={linkModel} />
	);
});
