import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { SelectorOptionsGroupInserts } from '~/product/casegoods/selector/Components/options/SelectorOptionsGroupInserts';
import { SelectorOptionsToggle } from '~/product/casegoods/selector/Components/options/SelectorOptionsToggle';

import styles from '~/product/casegoods/selector/Components/options/selector-options-inserts.module.scss';

export const SelectorOptionsInserts = observer((props = {}) => {
	const { slotModels = [] } = props;

	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div
			className={
				classNames(styles['selector-options-inserts'], {
					[styles['selector-options-inserts-expanded']]: isExpanded,
				})
			}
			id="options-inserts"
		>
			<SelectorOptionsToggle
				ariaControls="options-inserts"
				buttonText="Summary of inserts"
				dataQa="selector-options-toggle-inserts"
				isExpanded={isExpanded}
				setIsExpanded={setIsExpanded}
			/>
			<div className={styles['selector-options-inserts-selectors']}>
				{
					slotModels.map((slotModel, index) => {
						return (
							<SelectorOptionsGroupInserts
								key={`selector-options-group-${index}`}
								slotModel={slotModel}
							/>
						);
					})
				}
			</div>
		</div>
	);
});
