import { computed, observable, makeObservable } from 'mobx';

import { sortBy } from '~/util/sortBy';

class SelectorValueInsert {
	articles

	filters

	parentLabel

	productModels

	selected

	selectors

	stocked

	title

	constructor() {
		makeObservable(this, {
			selected: observable,
			chunkedProductModels: computed,
			productModelsByLineDrawingImage: computed,
			selectedSelector: computed,
		});
	}

	get chunkedProductModels() {
		return Object.values(this.productModelsByLineDrawingImage)
			.map((productModels = []) => sortBy(productModels, 'depth'));
	}

	get productModelsByLineDrawingImage() {
		return this.articles.reduce((accumulatedLineDrawingImagesMap, {
			articleNumber = '',
			lineDrawingImage = '',
		}) => {
			const matchingProduct = this.productModels.find(({ articleNumber: productModelArticleNumber = '' }) => articleNumber === productModelArticleNumber);

			if (matchingProduct) {
				const { imageUrl = '' } = matchingProduct;

				const imageToUse = lineDrawingImage || imageUrl;

				(accumulatedLineDrawingImagesMap[imageToUse] = accumulatedLineDrawingImagesMap[imageToUse] || []).push(matchingProduct);
			}
			return accumulatedLineDrawingImagesMap;
		}, {});
	}

	get selectedSelector() {
		return this.selectors.find(({ selected }) => selected) || this.selectors[0];
	}
}

export const SelectorValueInsertModelFactory = ({
	create: ({
		articles = [],
		filters = {},
		parentLabel = '',
		productModels = [],
		selected = false,
		selectors = [],
		stocked = false,
		title = '',
	}) => {
		const selectorValueInsert = new SelectorValueInsert();

		Object.assign(selectorValueInsert, {
			articles,
			filters,
			parentLabel,
			productModels,
			selected,
			selectors,
			stocked,
			title,
		});

		return selectorValueInsert;
	},
});
