import axios from 'axios';
import {
	action, computed, observable, makeObservable
} from 'mobx';
import React from 'react';

import {
	isOnServer,
	curalateApiUrl,
	isEngage,
} from '~/global/global.constants';
import { CuralateDetail } from '~/util/curalate/Components/CuralateDetail';
import { CuralateItemReport } from '~/util/curalate/Components/CuralateItemReport';
import { CuralateItemReportStore } from '~/util/curalate/Stores/CuralateItemReport.store';
import { CuralateUpload } from '~/util/curalate/Components/CuralateUpload';
import { CuralateUploadStore } from '~/util/curalate/Stores/CuralateUpload.store';
import { CuralateModelFactory } from '~/util/curalate/Models/Curalate.model';
import { BazaarvoicePresentEventTrackingStore } from '~/tracking/vendor-events/bazzarvoice/Stores/BazaarvoicePresentEvent.tracking.store';

export class CuralateStore {
	curalateDetailModalIsOpen = false;

	curalateItemProductViewed = null;

	featureTogglesModel;

	model;

	curalateItemReportStore = null;

	curalateUploadStore = null;

	disposer = null;

	nextPageLink = null;

	onCloseFocusElement = null;

	constructor() {
		makeObservable(this, {
			curalateDetailModalIsOpen: observable,
			curalateItemProductViewed: observable,
			model: observable,
			filterAndPersistentFilter: computed,
			itemPlaceholders: computed,
			getCuralateData: action.bound,
			getCuralateMoreItems: action.bound,
			setSlickPageIndex: action.bound,
			selectNextItem: action.bound,
			selectPrevItem: action.bound,
			handleKeyDown: action.bound,
			openCuralateDetailModal: action.bound,
			openCuralateItemReportModal: action.bound,
			openCuralateUploadModal: action.bound,
			setCuralateDetailModalIsOpen: action.bound,
			setCuralateFilter: action.bound,
			setCuralateItemProductViewed: action.bound,
			trackCuralateItemProductClick: action.bound,
			trackVendorPresent: action.bound,
		});
	}

	get filterAndPersistentFilter() {
		return this.model.filterAndPersistentFilter;
	}

	get itemPlaceholders() {
		return this.model.itemPlaceholders;
	}

	getCuralateData() {
		const {
			dataSourceId,
			filterAndPersistentFilter,
			limit,
		} = this.model;

		const params = {
			filter: filterAndPersistentFilter,
		};

		if (limit) {
			params.limit = limit;
		}

		axios.request({
			url: `${curalateApiUrl}/v1/media/${dataSourceId}`,
			method: 'get',
			params,
			paramsSerializer: (parameters) => {
				// yes, we need a custom paramsSerializer here - axios default paramsSerializer converts spaces to plus signs (+), rather than the standard encodeURIComponent value (%20)
				return Object.keys(parameters).map(key => `${key}=${parameters[key]}`).join('&');
			},
			transformRequest: [
				(data, headers) => {
					headers.clear();
					return data;
				},
			],
		}).then((resp) => {
			const { items } = resp.data.data;
			const { next = null } = resp.data.paging;
			this.nextPageLink = next;
			this.model.setItems(items);

			this.trackVendorPresent();
		}).catch((error) => {
			console.error(error);
		});
	}

	getCuralateMoreItems(nextUri) {
		if (!nextUri) { return false; }

		return axios.get(nextUri, {
			transformRequest: [
				(data, headers) => {
					headers.clear();
					return data;
				},
			],
		})
			.then((resp) => {
				const { items } = resp.data.data;
				const { next = null } = resp.data.paging;

				this.nextPageLink = next;
				this.model.addItems(items);
			});
	}

	setSlickPageIndex(index) {
		this.model.setSlickPageIndex(index);
	}

	selectNextItem() {
		if (this.model.hasNextItem) {
			this.model.setSlickPageIndex(this.model.slickPageIndex + 1);
		}
	}

	selectPrevItem() {
		if (this.model.hasPrevItem) {
			this.model.setSlickPageIndex(this.model.slickPageIndex - 1);
		}
	}

	handleKeyDown(
		{
			event = {},
			nextFunc = this.selectNextItem,
			prevFunc = this.selectPrevItem,
		}
	) {
		const { key } = event;

		switch (key) {
			case 'ArrowLeft':
				event.preventDefault();
				prevFunc();
				break;
			case 'ArrowRight':
				event.preventDefault();
				nextFunc();
				break;
			default:
				break;
		}
	}

	openCuralateDetailModal({
		onCloseFocusElement,
		item,
		heading = null,
		trLinkEventUseCompNameH1 = null,
	}) {
		this.model.trLinkEventCompName = heading;
		this.onCloseFocusElement = onCloseFocusElement || null;
		this.model.setSlickPageIndex(this.model.items.indexOf(item));
		this.magicModal.openModal({
			absoluteScrollTop: '10vh',
			content: {
				children: <CuralateDetail store={this} />,
			},
			onCloseModal: () => {
				this.setCuralateDetailModalIsOpen(false);
			},
			showHeader: false,
			title: 'Customer Photos and Products',
			maxWidth: 770,
			onCloseFocusElement: onCloseFocusElement || null,
			trLinkEventCompName: this.model.trLinkEventCompName,
			trLinkEventUseCompNameH1,
			trLinkEventCompType: 'bazaarvoice social commerce',
		});

		this.setCuralateDetailModalIsOpen(true);
	}

	openCuralateItemReportModal() {
		if (!this.curalateItemReportStore) {
			this.curalateItemReportStore = new CuralateItemReportStore({
				fanreelId: this.model.fanreelId,
				curalateModel: this.model,
			});
		}
		const { resetReportCuralateItem } = this.curalateItemReportStore;
		const { selectedItem } = this.model;

		this.magicModal.alterModal({
			content: {
				children: (
					<CuralateItemReport
						item={selectedItem}
						store={this.curalateItemReportStore}
						magicModal={this.magicModal}
					/>
				),
			},
			flushSides: false,
			onCloseModal: () => {
				resetReportCuralateItem();

				if (this.onCloseFocusElement) {
					setTimeout(() => {
						this.onCloseFocusElement.focus();
					});
				}
			},
			showHeader: true,
			title: 'Report this media?',
			width: 500,
			maxWidth: '95vw',
			trLinkEventCompType: 'bazaarvoice social commerce',
		});
	}

	openCuralateUploadModal() {
		if (!this.curalateUploadStore) {
			this.curalateUploadStore = new CuralateUploadStore({
				dataSourceId: this.model.dataSourceId,
				fanreelId: this.model.fanreelId,
				magicModal: this.magicModal,
			});
		}
		const { resetUploadCuralate } = this.curalateUploadStore;

		this.magicModal.openModal({
			content: {
				children: <CuralateUpload store={this.curalateUploadStore} magicModal={this.magicModal} />,
			},
			initialScrollTop: 0,
			onCloseModal: resetUploadCuralate,
			title: 'Select Your Content',
			maxWidth: 500,
			trLinkEventUseCompNameH1: true,
			trLinkEventCompType: 'bazaarvoice social commerce',
		});
	}

	setCuralateDetailModalIsOpen(isOpen) {
		this.curalateDetailModalIsOpen = isOpen;
	}

	setCuralateFilter(filterValue) {
		this.model.filter = filterValue;
		this.getCuralateData();
	}

	setCuralateItemProductViewed(product) {
		this.curalateItemProductViewed = product;
	}

	trackCuralateItemProductClick(item, product) {
		this.setCuralateItemProductViewed(item, product);
	}

	trackVendorPresent() {
		if (!isOnServer && !isEngage) {
			const bazaarvoicePresentTrackingStore = new BazaarvoicePresentEventTrackingStore();
			bazaarvoicePresentTrackingStore.trackBazaarvoicePresent();
		}
	}
}

const CuralateStoreFactory = {
	create: (cookies, magicModal, featureTogglesModel, settings) => {
		const instance = new CuralateStore();
		instance.model = CuralateModelFactory.create(settings);
		instance.cookies = cookies;
		instance.featureTogglesModel = featureTogglesModel;
		instance.magicModal = magicModal;

		if (!isOnServer) {
			instance.getCuralateData();
		}

		return instance;
	}
};

export { CuralateStoreFactory };
