/* eslint-disable no-mixed-operators */
import axios from 'axios';
import {
	action, autorun, runInAction, makeObservable,
} from 'mobx';

import { apiUrl, isOnServer } from '~/global/global.constants';
import { createWorkspaceProductCasegoodsModel } from '~/product/casegoods/workspaces/defaultWorkspace/Helpers/WorkspaceProductCasegoods.init';
import { WorkspaceProductCasegoodsStoreFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Stores/WorkspaceProductCasegoods.store';
import { sortBy } from '~/util/sortBy';

class WorkspaceCasegoodsStore {
	productGroupModel;

	selectorConfigModel;

	selectorConfigStore;

	workspaceModel;

	constructor() {
		makeObservable(this, {
			addOrUpdateWorkspaceProduct: action,
			clearSlot: action,
			getAvailableInsertValidationData: action,
			getPreflightAvailableInsertValidationData: action,
			overrideInsertOptionsSelectorValues: action,
			removeAllWorkspaceProducts: action,
			removeWorkspaceProduct: action,
			removeWorkspaceProductsWithoutInserts: action,
			setDidShowInsertMessage: action,
			setInsertProduct: action,
			setInsertProductIsDragging: action,
			setIsHoveringOverFrames: action,
			setIsDragging: action,
			setIsWorkspaceEnlarged: action,
			setShowErrorMessage: action,
			setWorkspaceProductIsHidden: action,
			updateWorkspaceProductIndex: action,
			updateWorkspaceProductIndicies: action,
			updateWorkspaceProductsSort: action,
			updateWorkspaceStackProductsSlotModels: action,
		});
	}

	addOrUpdateWorkspaceProduct({
		depthSelector = {},
		doorSelectorValue = {},
		frameProductModel = {},
		frameProductModels = [],
		frameSelectorModel = {},
		selectorConfigFrameModalModel = {},
		widthSelectorValue = {},
		workspaceProductImageData,
		workspaceProductIndex = 0,
	}) {
		const {
			insertsSelectorModel: {
				combinedChunkedProductModels = [],
			} = {},
			settings: {
				useInsertImageEndpoint = false,
			} = {},
		} = this.selectorConfigModel;

		const {
			hasWorkspaceProducts = false,
			workspaceProductsCount = 0,
		} = this.workspaceModel;

		const workspaceProductModelToReplace = workspaceProductIndex < workspaceProductsCount ? this.workspaceModel.workspaceProducts[workspaceProductIndex] : {};

		const { slotModelsWithInserts = [] } = workspaceProductModelToReplace;

		const workspaceProductModel = createWorkspaceProductCasegoodsModel({
			depthSelector,
			doorSelectorValue,
			frameProductModel,
			frameProductModels,
			frameSelectorModel,
			selectorConfigFrameModalModel,
			widthSelectorValue,
			workspaceProductImageData,
			workspaceProductIndex,
		});

		const workspaceProductStore = WorkspaceProductCasegoodsStoreFactory.create({
			productGroupModel: this.productGroupModel,
			workspaceProductModel,
		});

		const {
			workspaceProductDepth = 0,
			workspaceProductSlotModels = [],
		} = workspaceProductModel;

		slotModelsWithInserts.forEach(({
			id: slotModelWithInsertId = '',
			insertProductModel = {},
			insertProps = {},
		}) => {
			const matchingSlotModel = workspaceProductSlotModels.find(({ id = '' }) => id === slotModelWithInsertId);

			const matchingInsertProductModelChunk = combinedChunkedProductModels.find(productModels => productModels.includes(insertProductModel)) || [];

			const matchingInsertProductModel = matchingInsertProductModelChunk.find(({ compatibleFrameDepth = 0 }) => workspaceProductDepth === compatibleFrameDepth);

			this.setInsertProduct({
				insertProductModel: matchingInsertProductModel || insertProductModel,
				insertProps,
				slotModel: matchingSlotModel,
				workspaceProduct: workspaceProductModel,
			});
		});

		this.workspaceModel.workspaceProductStores.push(workspaceProductStore);

		if (workspaceProductIndex >= workspaceProductsCount) {
			this.workspaceModel.workspaceProducts.push(workspaceProductModel);

			this.workspaceModel.lastSelectedWorkspaceProductModel = workspaceProductModel;
		} else {
			this.workspaceModel.workspaceProducts[workspaceProductIndex] = workspaceProductModel;
		}

		this.selectorConfigStore.setWorkspaceProductStores(this.workspaceModel.workspaceProductStores);

		if (!hasWorkspaceProducts && useInsertImageEndpoint) {
			this.selectorConfigStore.getInsertProductImagesData();
		}
	}

	clearSlot(slotModelToClear) {
		Object.assign(slotModelToClear, {
			answeredBySlot: null,
			answerForSlots: [],
			insertOptionsSelectorValues: [],
			insertProductModel: null,
			insertProps: {},
		});
	}

	getAvailableInsertValidationData(WorkspaceProduct = {}) {
		const {
			depthSelector: {
				selectedSelectorValue: {
					articles,
				} = {},
			} = {},
			frameProductModel: {
				links: {
					STACK_DOCUMENT: {
						href,
					} = {},
				} = {},
			} = {},
			slotModelsWithInserts,
			workspaceProductSlotModels,
		} = WorkspaceProduct;
		const url = href || articles[0]._links?.STACK_DOCUMENT?.href;
		if (!url) console.error('no link provided for insert validation');
		const inserts = [...slotModelsWithInserts.map(model => model.insertProductModel.answerKey)];
		const questionParam = inserts.map(insert => `INSERT:${insert}`);
		return url && questionParam.length && axios.get(`${apiUrl}${url}`, {
			params: { question: questionParam },
			paramsSerializer: (paramsToSerialize) => {
				return Object.entries(paramsToSerialize).map(([key, value]) => {
					if (Array.isArray(value)) {
						return value.map((subValue) => {
							return `${key}=${encodeURIComponent(subValue)}`;
						}).join('&');
					}

					return `${key}=${encodeURIComponent(value)}`;
				}).join('&');
			},
		})
			.then(({ data }) => {
				workspaceProductSlotModels.forEach((slotModel) => {
					const validtion = data.insertOptions[slotModel.questions.INSERT];
					slotModel.allow = validtion;
				});
			});
	}

	getPreflightAvailableInsertValidationData(WorkspaceProduct = {}, draggingSlot = {}) { // USED ONLY ON DRAG START
		const {
			depthSelector: {
				selectedSelectorValue: {
					articles,
				} = {},
			} = {},
			frameProductModel: {
				links: {
					STACK_DOCUMENT: {
						href,
					},
				} = {},
			} = {},
			slotModelsWithInserts,
			workspaceProductSlotModels,
		} = WorkspaceProduct;
		const url = href || articles[0]._links?.STACK_DOCUMENT?.href;
		if (!url) console.error('no link provided for insert validation');
		const nonObservableSlotsWithInserts = [...slotModelsWithInserts.map(insert => ({ ...insert }))].filter(slot => slot.id !== draggingSlot.id);
		const inserts = [...nonObservableSlotsWithInserts.map(model => model.insertProductModel.answerKey)];
		const questionParam = inserts.map(insert => `INSERT:${insert}`);
		return url && questionParam.length && axios.get(`${apiUrl}${url}`, {
			params: { question: questionParam },
			paramsSerializer: (paramsToSerialize) => {
				return Object.entries(paramsToSerialize).map(([key, value]) => {
					if (Array.isArray(value)) {
						return value.map((subValue) => {
							return `${key}=${encodeURIComponent(subValue)}`;
						}).join('&');
					}

					return `${key}=${encodeURIComponent(value)}`;
				}).join('&');
			},
		})
			.then(({ data }) => {
				let hitMatch = false;
				Object.entries(data.insertOptions).reverse().forEach(([slotKey, validation]) => { // reversing to start at A1
					if (slotKey === draggingSlot.questions.INSERT) hitMatch = true;
					const matchSlotIndex = workspaceProductSlotModels.findIndex(slot => slot.questions.INSERT === slotKey);
					const index = hitMatch ? matchSlotIndex + 1 : matchSlotIndex;
					const target = workspaceProductSlotModels[index];
					if (target) target.allow = validation;
				});
			});
	}

	insertProduct({ insertIndex, product }) {
		this.workspaceModel.workspaceProducts.splice(insertIndex, 0, product);
		this.updateWorkspaceProductIndicies();
	}

	overrideInsertOptionsSelectorValues(overrideQuestionAnswers = {}) {
		const { workspaceProducts = [] } = this.workspaceModel;

		workspaceProducts.forEach(({ workspaceProductSlotModels = [] }) => {
			workspaceProductSlotModels.forEach(({
				insertOptionsSelectors = [],
				insertOptionsSelectorValues = [],
			}) => {
				insertOptionsSelectors.forEach(({
					selectorValues = [],
					type = '',
				}) => {
					const insertOptionsSelectorValueToReplace = insertOptionsSelectorValues.find(({ parentType = '' }) => parentType === type);

					selectorValues.forEach((selectorValue = {}) => {
						const {
							answer = {},
							question = {},
						} = selectorValue;

						Object.entries(overrideQuestionAnswers).forEach(([overrideQuestion, overrideAnswer]) => {
							if (question === overrideQuestion && answer === overrideAnswer) {
								if (insertOptionsSelectorValueToReplace) {
									const insertOptionsSelectorValueIndex = insertOptionsSelectorValues.indexOf(insertOptionsSelectorValueToReplace);

									insertOptionsSelectorValues[insertOptionsSelectorValueIndex] = selectorValue;
								}
							}
						});
					});
				});
			});
		});
	}

	removeAllWorkspaceProducts() {
		const { workspaceProducts = [] } = this.workspaceModel;

		workspaceProducts.forEach((workspaceProduct = {}) => {
			this.removeWorkspaceProduct(workspaceProduct);
		});
	}

	removeWorkspaceProduct(workspaceProductToRemove = {}) {
		const { workspaceProducts = [] } = this.workspaceModel;

		this.workspaceModel.workspaceProducts = workspaceProducts.filter(workspaceProduct => workspaceProduct !== workspaceProductToRemove);

		this.updateWorkspaceProductIndicies();
	}

	removeWorkspaceProductsWithoutInserts() {
		const { workspaceProducts = [] } = this.workspaceModel;

		workspaceProducts.forEach((workspaceProduct = {}) => {
			const { hasSlotModelsWithInserts = false } = workspaceProduct;

			if (!hasSlotModelsWithInserts) {
				this.removeWorkspaceProduct(workspaceProduct);
			}
		});
	}

	setDidShowInsertMessage(didShowInsertMessage = false) {
		this.workspaceModel.didShowInsertMessage = didShowInsertMessage;
	}

	setInsertProduct({
		insertProductModel = {},
		insertProps = {},
		slotModel = {},
		workspaceProduct = {},
	}) {
		// unpacking
		const { insertSpace = '' } = insertProductModel;
		const {
			insertOptionsSelectors = [],
			insertOptionsSelectorValues = [],
		} = insertProps;
		const {
			columnIndex = 0,
			rowIndex = 0,
		} = slotModel;
		const { workspaceProductSlotModels = [] } = workspaceProduct;

		// determined
		const columnToAnswerAddend = ['DOUBLE WIDE', 'QUAD'].includes(insertSpace) ? 1 : 0;
		const rowToAnswerAddend = ['DOUBLE TALL', 'QUAD'].includes(insertSpace) ? 1 : 0;
		const defaultInsertOptionsSelectorValues = insertOptionsSelectors.map(({ defaultSelectorValue = {} }) => defaultSelectorValue);
		const answerForSlots = workspaceProductSlotModels.filter(({
			columnIndex: columnIndexToAnswer = 0,
			rowIndex: rowIndexToAnswer = 0,
		}) => {
			// don't include the answeredBySlot slot itself
			if ((columnIndex === columnIndexToAnswer) && (rowIndex === rowIndexToAnswer)) {
				return false;
			}
			return (columnIndex + columnToAnswerAddend === columnIndexToAnswer) && (rowIndex + rowToAnswerAddend === rowIndexToAnswer)
				|| (columnIndex === columnIndexToAnswer) && (rowIndex + rowToAnswerAddend === rowIndexToAnswer)
				|| (columnIndex + columnToAnswerAddend === columnIndexToAnswer) && (rowIndex === rowIndexToAnswer);
		});

		// clear the adjacent slots
		[slotModel, ...answerForSlots].forEach((slotModelToAnswer = {}) => {
			const {
				answeredBySlot = null,
				answerForSlots: answerForSlotsToClear = [],
			} = slotModelToAnswer;

			// clear any slots answered by the adjacent slot
			if (answeredBySlot) {
				const { answerForSlots: answeredBySlotsToClear = [] } = answeredBySlot;

				[answeredBySlot, ...answeredBySlotsToClear].forEach((answeredBySlotToClear = {}) => {
					this.clearSlot(answeredBySlotToClear);
				});
			}

			// clear any slots answered by the slot itself
			answerForSlotsToClear.forEach((answerForSlotToClear = {}) => {
				this.clearSlot(answerForSlotToClear);
			});

			this.clearSlot(slotModelToAnswer);

			// don't set answeredBySlot on the slot itself
			if (slotModelToAnswer !== slotModel) {
				slotModelToAnswer.answeredBySlot = slotModel;
			}
		});

		Object.assign(slotModel, {
			answerForSlots,
			insertOptionsSelectorValues: insertOptionsSelectorValues.length ? insertOptionsSelectorValues : defaultInsertOptionsSelectorValues,
			insertProps,
			insertProductModel,
		});

		this.workspaceModel.insertWorkspaceProducts.push(insertProductModel);

		this.workspaceModel.lastSelectedInsertProductModel = insertProductModel;
	}

	setInsertProductIsDragging(insertProductIsDragging = false) {
		this.workspaceModel.insertProductIsDragging = insertProductIsDragging;
	}

	setIsHoveringOverFrames(isHovering = false) {
		this.workspaceModel.isHoveringOverFrames = isHovering;
	}

	setIsWorkspaceEnlarged(isWorkspaceEnlarged = false) {
		this.workspaceModel.isWorkspaceEnlarged = isWorkspaceEnlarged;
	}

	setLastInteractionWasRearrange(lastInteractionWasRearrange = false) {
		this.workspaceModel.lastInteractionWasRearrange = lastInteractionWasRearrange;
	}

	setIsDragging(isDragging = false) {
		this.workspaceModel.isDragging = isDragging;
	}

	setShowErrorMessage(showErrorMessage = false) {
		this.workspaceModel.showErrorMessage = showErrorMessage;
	}

	setWorkspaceProductIsHidden({
		isHidden = false,
		workspaceProduct = {},
	}) {
		workspaceProduct.isHidden = isHidden;
	}

	updateWorkspaceProductIndex({
		newIndex = 0,
		oldIndex = 0,
	}) {
		const { workspaceProducts = [] } = this.workspaceModel;

		workspaceProducts.forEach((workspaceProduct) => {
			const { workspaceProductIndex = 0 } = workspaceProduct;

			if (workspaceProductIndex === newIndex) {
				workspaceProduct.workspaceProductIndex = oldIndex;
			}

			if (workspaceProductIndex === oldIndex) {
				workspaceProduct.workspaceProductIndex = newIndex;
			}
		});

		this.updateWorkspaceProductsSort();
	}

	updateWorkspaceProductIndicies() {
		this.workspaceModel.sortedWorkspaceProducts.forEach((workspaceProduct, index) => {
			workspaceProduct.workspaceProductIndex = index;
		});
	}

	updateWorkspaceProductsSort() {
		this.workspaceModel.workspaceProducts = sortBy(this.workspaceModel.workspaceProducts, 'workspaceProductIndex');
	}

	updateWorkspaceStackProductsSlotModels() {
		this.workspaceModel.workspaceProducts.forEach((workspaceProduct = {}) => {
			const { workspaceProductSlotModels = [] } = workspaceProduct;

			workspaceProductSlotModels.forEach((slotModel = {}, index = 0) => {
				const {
					hasInsertProductModel = false,
					insertProps = {},
				} = slotModel;

				const nextSlotWithInsertProductModel = workspaceProductSlotModels.slice(index).find(({ hasInsertProductModel: nextHasInsertProductModel = false }) => nextHasInsertProductModel);

				const { insertProductModel: nextInsertProductModel } = nextSlotWithInsertProductModel || {};

				if (!hasInsertProductModel && nextInsertProductModel) {
					this.setInsertProduct({
						insertProductModel: nextInsertProductModel,
						insertProps,
						slotModel,
						workspaceProduct,
					});

					this.clearSlot(nextSlotWithInsertProductModel);
				}
			});
		});
	}
}

export const WorkspaceCasegoodsStoreFactory = ({
	create: ({
		productGroupModel = {},
		selectorConfigModel = {},
		selectorConfigModel: {
			defaultConfigModel: {
				defaultAnswers = [],
			} = {},
			imageScale: {
				lineDrawingDraggable: imageScale = 0,
				lineDrawingDraggableSmall: imageScaleSmall = 0,
			} = {},
			insertsSelectorModel: {
				combinedArticles = [],
			} = {},
			settings: {
				frameFirstBuilder = false,
				isValidInsertsSelectorAlways = false,
			} = {},
		} = {},
		selectorConfigStore = {},
		workspaceModel = {},
		workspaceModel: {
			workspaceOverrideModel: {
				workspaceProducts = [],
			} = {},
		} = {},
	}) => {
		const workspaceCasegoodsStore = new WorkspaceCasegoodsStore();

		Object.assign(workspaceCasegoodsStore, {
			productGroupModel,
			selectorConfigModel,
			selectorConfigStore,
			workspaceModel,
		});

		runInAction(() => {
			workspaceProducts.forEach(({
				depthSelector = {},
				doorSelectorValue = {},
				frameProductModel = {},
				frameProductModels = [],
				frameSelectorModel = {},
				selectorConfigFrameModalModel = {},
				slots = [],
				widthSelectorValue = {},
				workspaceProductImageData,
			}, index) => {
				if (frameFirstBuilder) {
					workspaceCasegoodsStore.addOrUpdateWorkspaceProduct({
						selectorConfigFrameModalModel,
						workspaceProductImageData,
						workspaceProductIndex: index,
					});
				} else {
					workspaceCasegoodsStore.addOrUpdateWorkspaceProduct({
						depthSelector,
						doorSelectorValue,
						frameProductModel,
						frameProductModels,
						frameSelectorModel,
						widthSelectorValue,
						workspaceProductIndex: index,
					});
				}

				const { workspaceProducts: workspaceProductModels = [] } = workspaceModel;

				const workspaceProductModel = workspaceProductModels[index] || {};

				const { workspaceProductSlotModels = [] } = workspaceProductModel;

				slots.forEach(({
					insertProps = {},
					insertProductModel = {},
					insertProductModel: {
						articleNumber: insertProductArticleNumber = '',
						imageUrl = '',
					} = {},
					slotId = '',
				}) => {
					const matchingSlotModel = workspaceProductSlotModels.find(({ id = '' }) => id === slotId);

					if (matchingSlotModel) {
						const { questions = {} } = matchingSlotModel;

						const matchingInsertArticle = combinedArticles.find(({ articleNumber = '' }) => articleNumber === insertProductArticleNumber) || {};

						const { selectors: insertOptionsSelectors = [] } = matchingInsertArticle;

						workspaceCasegoodsStore.setInsertProduct({
							insertProductModel,
							insertProps: {
								...insertProps,
								imageScale,
								imageScaleSmall,
								insertOptionsSelectors,
								lineDrawingImage: imageUrl,
							},
							slotModel: matchingSlotModel,
							workspaceProduct: workspaceProductModel,
						});

						const insertOptionsSelectorValues = insertOptionsSelectors.map(({ selectorValues = [] }) => {
							return selectorValues.find(({
								answer = '',
								question = '',
							}) => {
								const productQuestion = questions[question];

								const productQuestionAnswer = defaultAnswers.find(({ question: defaultConfigQuestion = '' }) => productQuestion === defaultConfigQuestion) || {};

								const { answer: productAnswer = '' } = productQuestionAnswer;

								return answer === productAnswer;
							});
						});

						matchingSlotModel.insertOptionsSelectorValues = insertOptionsSelectorValues;
					}
				});
			});
		});

		if (!isOnServer) {
			if (!frameFirstBuilder) {
				workspaceModel.workspaceProducts.forEach(product => workspaceCasegoodsStore.getAvailableInsertValidationData(product));
			}

			workspaceModel.workspaceProductStores.forEach(workspaceProductStore => workspaceProductStore.getWorkspaceProductImagesData());

			autorun(() => {
				if (selectorConfigModel.materialSelectorWithNonMatchingProducts) {
					Object.assign(selectorConfigModel.materialSelectorWithNonMatchingProducts, { isVisible: workspaceModel.hasWorkspaceProductsWithHiddenSelector });
				}
				Object.assign(selectorConfigModel.downloadSelectorModel, { isValid: workspaceModel.hasWorkspaceProducts });
			});

			autorun(() => {
				Object.assign(selectorConfigModel.insertsSelectorModel, { isValid: isValidInsertsSelectorAlways || workspaceModel.hasWorkspaceProducts });
			});
		}

		return workspaceCasegoodsStore;
	},
});
