import React from 'react';

import { s7ContentPath } from '~/global/global.constants';

export const DeliveryTruckIcon = () => {
	return (
		<img
			src={`${s7ContentPath}/delivery-truck`}
			alt=""
			width="60"
			className="delivery-icon delivery-truck"
		/>
	);
};
