import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { noop } from '~/util/noop';
import { MediaSetVariants } from '~/media-set/Helpers/MediaSetVariants';
import styles from '#/media-set/media-nav.module.scss';

interface Props {
	additionalClassNames?: object,
	additionalContainerClassNames?: object,
	children?: JSX.Element,
	className?: string,
	dataQa?: string,
	trLinkEventName?: string,
	hideArrows: boolean,
	onClick?: Function,
	scrollerHeight?: number,
	showArrowsForMedium: boolean,
	showArrowsForSmall: boolean,
	variant: typeof MediaSetVariants,
}
const MediaNav = observer((props: Props) => {
	const {
		additionalClassNames = {},
		additionalContainerClassNames = {},
		children,
		className = '',
		dataQa = '',
		trLinkEventName = '',
		hideArrows = false,
		onClick = noop,
		scrollerHeight,
		showArrowsForMedium = false,
		showArrowsForSmall = false,
		variant = (MediaSetVariants as any).DEFAULT,
	} = props;

	const customStyles: any = {};
	const isDisabled = className.includes('disabled');
	const optionalAttributes: any = {};

	if (scrollerHeight) {
		// 35px == height of nav arrow
		customStyles.top = `${scrollerHeight / 2 - 17}px`;
	}

	if (isDisabled) {
		optionalAttributes.disabled = true;
	}

	return (
		<div
			className={
				classNames(styles['media-nav-container'], 'tw-hidden-for-print', {
					...additionalContainerClassNames,
					[styles[variant]]: variant !== MediaSetVariants.DEFAULT,
					'tw-hidden': hideArrows,
				})
			}
		>
			<button
				{...optionalAttributes}
				className={
					classNames(`${styles['media-nav']} focus:tw-not-sr-only`, {
						...additionalClassNames,
						'md:tw-not-sr-only': showArrowsForMedium,
						'tw-sr-only': !showArrowsForSmall,
					})
				}
				data-qa={dataQa}
				data-tr-link-event-type={(LinkEventTypes as any).SITE_ACTION}
				data-tr-link-event-name={trLinkEventName}
				onClick={onClick}
				style={customStyles}
			>
				{children}
			</button>
		</div>
	);
});

export const MediaNavNext = observer((props: Props) => {
	const {
		additionalContainerClassNames = {},
		className = '',
		dataQa = 'media-nav-next',
		hideArrows = false,
		onClick = noop,
		scrollerHeight,
		showArrowsForMedium = false,
		showArrowsForSmall = false,
		variant,
	} = props;

	return (
		<MediaNav
			additionalContainerClassNames={additionalContainerClassNames}
			additionalClassNames={{
				[styles['media-nav-next']]: true,
			}}
			className={className}
			dataQa={dataQa}
			hideArrows={hideArrows}
			onClick={onClick}
			scrollerHeight={scrollerHeight}
			showArrowsForMedium={showArrowsForMedium}
			showArrowsForSmall={showArrowsForSmall}
			variant={variant}
			trLinkEventName={'right navigation'}
		>
			<span className="tw-sr-only">
				Next item
			</span>
		</MediaNav>
	);
});

export const MediaNavPrev = observer((props: Props) => {
	const {
		additionalContainerClassNames = {},
		className = '',
		dataQa = 'media-nav-prev',
		hideArrows = false,
		onClick = noop,
		showArrowsForMedium = false,
		showArrowsForSmall = false,
		scrollerHeight,
		variant,
	} = props;

	return (
		<MediaNav
			additionalContainerClassNames={additionalContainerClassNames}
			additionalClassNames={{
				[styles['media-nav-prev']]: true,
			}}
			className={className}
			dataQa={dataQa}
			trLinkEventName={'left navigation'}
			hideArrows={hideArrows}
			onClick={onClick}
			scrollerHeight={scrollerHeight}
			showArrowsForMedium={showArrowsForMedium}
			showArrowsForSmall={showArrowsForSmall}
			variant={variant}
		>
			<span className="tw-sr-only">
				Previous item
			</span>
		</MediaNav>
	);
});
