import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { SelectorFiltersFacetsChunk } from '~/product/common/selector/Components/filters/SelectorFiltersFacetsChunk';
import { kebabCase } from '~/util/kebabCase';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import styles from '#/product/standard/selector/selector-filters.module.scss';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const SelectorFiltersFacets = observer((props = {}) => {
	const {
		magicTabccordionStore = {},
		filterModel: {
			chunkedFacets = [],
			facetsToRender,
			title = '',
			type = '',
		} = {},
		filtersStore = {},
		tab = {},
	} = props;

	// for tracking facet positions we want to know the initial number of facets.
	// sometimes applying a facet changes the number of facets to render, but for tracking we always want to know initial number when the filterStore changes
	// e.g. the page loads with 14 type & feature facets, selecting  the 3rd facet "dog friendly" changes the number of facets to render form 14 to 8. now "dog friendly" is the second of 8 facets. for tracking we always to report the position of the facet as 3:14, not 2:8
	useEffect(() => {
		facetsToRender.forEach((facet, index) => {
			facet.facetPosition = `${index + 1}:${facetsToRender.length}`;
		});
	}, [filtersStore]);

	const {
		linkEventStore,
	} = useGlobalContext();

	function handleClick() {
		magicTabccordionStore.deactivateTab(tab);
		const {
			filtersModel: {
				filterModels,
			} = {},
		} = filtersStore;

		const selectedFacetTitles = [];
		const selectedFacetPositions = [];

		filterModels.forEach((filterModel) => {
			const {
				hasSelectedFacets,
				selectedFacets = [],
			} = filterModel;

			let linkEventTrackingData = {};

			if (hasSelectedFacets) {
				// selectedFacets returns all of the selected facets but we're only interested in the ones in the open "dropdown"
				const matchingTypeFacets = selectedFacets.filter(facet => facet.parentType === type);

				matchingTypeFacets.forEach((selected) => {
					selectedFacetTitles.push(selected.title);
					selectedFacetPositions.push(selected.facetPosition);
				});

				linkEventTrackingData = {
					trLinkEventName: `apply filter: ${selectedFacetTitles.join('|')}`,
					trLinkEventCompPosition: selectedFacetPositions.join('|'),
					trLinkEventCompType: 'tabccordion',
					trLinkEventCompName: title,
					trLinkEventType: LinkEventTypes['SITE_ACTION'],
				};

				// Only send the traking event for the selections made in the open "dropdown"
				if (matchingTypeFacets.length > 0) {
					linkEventStore.trackLinkEvent(linkEventTrackingData);
				}
			}
		});
	}

	const selectorFiltersFacetTitle = {
		'color': styles['selector-filters-facets-color'],
	};

	return (
		<fieldset>
			<legend className="tw-sr-only">{title} options</legend>
			<div className={`${styles['selector-filters-facets']} ${selectorFiltersFacetTitle[kebabCase(title)]}`}>
				{
					chunkedFacets.map((facetsChunk = [], index) => {
						return (
							<SelectorFiltersFacetsChunk
								facetsChunk={facetsChunk}
								filtersStore={filtersStore}
								key={`filters-facet-${index}`}
								facetChunkIndex={index}
							/>
						);
					})
				}
			</div>
			<button
				className="selector-filters-facets-button ButtonTertiary"
				data-qa={`${kebabCase(type)}-filter-apply-button`}
				onClick={e => handleClick(e)}
				data-tr-link-event-track={false}
			>
				See Results
			</button>
		</fieldset>
	);
});
