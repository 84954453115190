import { observer } from 'mobx-react';
import React from 'react';

import { SelectorHeadingDefault } from '~/product/common/selector/Components/SelectorHeading';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { getSelectorValueByArticleNumber } from '~/product/common/selector/Utils/SelectorConfig.utils';

export const SelectorHeadingUnattachedOttoman = observer((props) => {
	const {
		isActive = false,
		selectedSelector = {},
		showPrice = false,
	} = props;

	const {
		selectorConfigModel: {
			unattachedOttomanProductModel = {},
			flattenedOttomanSelectorValues = [],
		} = {},
	} = useProductContext();

	if (!unattachedOttomanProductModel) {
		return null;
	}

	const {
		articleNumber = '',
	} = unattachedOttomanProductModel;

	const unattachedOttomanSelectorValue = getSelectorValueByArticleNumber(flattenedOttomanSelectorValues, articleNumber);

	const {
		title = '',
		selected = false,
	} = unattachedOttomanSelectorValue;

	const overrideTitle = !isActive && selected ? `+ ${title}` : 'Add a matching, unattached ottoman';

	return (
		<SelectorHeadingDefault
			isActive={isActive}
			selectedSelector={selectedSelector}
			showOptional={true}
			showPrice={showPrice}
			titleOverride={overrideTitle}
			alwaysHideOptionsCount={true}
		/>
	);
});
