import { SelectorFacetModelFactory } from '~/product/common/selector/Models/SelectorFacet.model';
import { SelectorFilterModelFactory } from '~/product/common/selector/Models/SelectorFilter.model';
import { SelectorFiltersModelFactory } from '~/product/common/selector/Models/SelectorFilters.model';

export const createSelectorFiltersModel = ({
	aggregatorSelectorValueCount = 0,
	filtersData = {},
	parentSelectorModel = {},
	selectorValues = [],
}) => {
	const {
		colors: colorsData = [],
		shapes: shapesData = [],
		styles: stylesData = [],
		widths: widthsData = [],
	} = filtersData;

	// colors filter needs to aggregate all descendant selectorValues
	const shouldAggregateSelectorValues = Boolean(colorsData.length);

	const facetsColorModel = colorsData.map(colorData => SelectorFacetModelFactory.create({
		...colorData,
		aggregatorSelectorValueCount,
		isInclusive: true,
		parentSelectorModel,
		parentType: 'colors',
		selectorValues,
		shouldAggregateSelectorValues,
	}));

	const facetsStyleModel = stylesData.map(styleData => SelectorFacetModelFactory.create({
		...styleData,
		aggregatorSelectorValueCount,
		parentSelectorModel,
		parentType: 'styles',
		selectorValues,
		shouldAggregateSelectorValues,
	}));

	const facetsWidthModel = widthsData.map(widthData => SelectorFacetModelFactory.create({
		...widthData,
		aggregatorSelectorValueCount,
		isInclusive: true,
		parentSelectorModel,
		parentType: 'widths',
		selectorValues,
		shouldAggregateSelectorValues,
		title: widthData.title || widthData,
	}));

	const facetsShapeModel = shapesData.map(shapeData => SelectorFacetModelFactory.create({
		...shapeData,
		aggregatorSelectorValueCount,
		parentSelectorModel,
		parentType: 'shapes',
		selectorValues,
		shouldAggregateSelectorValues,
	}));

	facetsColorModel.forEach((facetColorModel) => {
		const filteredFacetsColorModel = facetsColorModel.filter(facetModel => facetModel !== facetColorModel);

		facetColorModel.matchingFacetsModels = [...filteredFacetsColorModel];
		facetColorModel.nonMatchingFacetsModels = [...facetsStyleModel];
	});

	facetsStyleModel.forEach((facetStyleModel) => {
		const filteredFacetsStyleModel = facetsStyleModel.filter(facetModel => facetModel !== facetStyleModel);

		facetStyleModel.matchingFacetsModels = [...filteredFacetsStyleModel];
		facetStyleModel.nonMatchingFacetsModels = [...facetsColorModel];
	});

	facetsWidthModel.forEach((facetWidthModel) => {
		const filteredFacetsWidthModel = facetsWidthModel.filter(facetModel => facetModel !== facetWidthModel);

		facetWidthModel.matchingFacetsModels = [...filteredFacetsWidthModel];
		facetWidthModel.nonMatchingFacetsModels = [...facetsShapeModel];
	});

	facetsShapeModel.forEach((facetShapeModel) => {
		const filteredFacetsShapeModel = facetsShapeModel.filter(facetModel => facetModel !== facetShapeModel);

		facetShapeModel.matchingFacetsModels = [...filteredFacetsShapeModel];
		facetShapeModel.nonMatchingFacetsModels = [...facetsWidthModel];
	});

	const filterColorModel = SelectorFilterModelFactory.create({
		facetsModel: facetsColorModel,
		sort: 0,
		title: 'Color',
		type: 'colors',
	});

	const filterStyleModel = SelectorFilterModelFactory.create({
		facetsModel: facetsStyleModel,
		sort: 1,
		title: 'Type & Features',
		type: 'styles',
	});

	const filterWidthModel = SelectorFilterModelFactory.create({
		facetsModel: facetsWidthModel,
		sort: 2,
		title: 'Widths',
		type: 'widths',
	});

	const filterShapeModel = SelectorFilterModelFactory.create({
		facetsModel: facetsShapeModel,
		sort: 3,
		title: 'Shapes',
		type: 'shapes',
	});

	// only send along filterModels with facets
	const filterModels = [
		filterColorModel,
		filterStyleModel,
		filterShapeModel,
		filterWidthModel,
	].filter(({ facetsModel = [] }) => Boolean(facetsModel.length));

	const selectorFiltersModel = SelectorFiltersModelFactory.create({
		filterModels,
		parentSelectorModel,
		selectorValues,
		shouldAggregateSelectorValues,
	});

	return selectorFiltersModel;
};
