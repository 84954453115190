import classNames from 'classnames';
import React from 'react';

import { noop } from '~/util/noop';

import styles from '~/product/casegoods/selector/Components/options/selector-options-toggle.module.scss';

export const SelectorOptionsToggle = (props) => {
	const {
		ariaControls = '',
		buttonText,
		dataQa = '',
		isExpanded = false,
		setIsExpanded = noop,
	} = props;

	function handleClick() {
		setIsExpanded(!isExpanded);
	}

	return (
		<button
			aria-controls={ariaControls}
			aria-expanded={isExpanded}
			data-qa={dataQa}
			className={
				classNames(`${styles['selector-options-toggle']} ButtonAnchor tw-hidden-for-print`, {
					[styles['selector-options-toggle-expanded']]: isExpanded,
				})
			}
			data-tracking-text={`${buttonText} ${isExpanded ? 'open' : 'closed'}`}
			onClick={handleClick}
		>
			{buttonText}
		</button>
	);
};
