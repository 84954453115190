import { observer } from 'mobx-react';
import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { DetailLink } from '~/product/common/details/Components/details/DetailLink';
import { addToUrl } from '~/util/addToUrl';

import styles from '#/product/standard/details/details-attributes.module.scss';
import stylesDetail from '#/product/standard/details/details.module.scss';
import stylesSelector from '#/product/standard/selector/selector-details.module.scss';
import stylesDetailMaterials from '#/product/standard/details/details-materials.module.scss';

export const DetailImage = observer((props) => {
	const {
		detail,
		detail: {
			src = '',
			hasLink = false,
		} = {},
		priority = false,
	} = props;

	return (
		<div className={`${styles['detail']} ${stylesDetail['detail']} ${stylesDetailMaterials['detail-image']} ${stylesSelector['detail-image']}`}>
			{
				src && (
					<S7Image
						alt=""
						className={styles['selector-value-drop-down-image']}
						disableScaleS7Image
						height="100"
						priority={priority}
						src={addToUrl(src, '$proddd$')}
						width="185"
					/>
				)
			}
			{
				hasLink && (
					<DetailLink detail={detail} />
				)
			}
		</div>
	);
});
