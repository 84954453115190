'use client';

import { observer } from 'mobx-react';
import cn from 'classnames';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { SummaryCount } from '~/account/top-links/Components/cart/SummaryCount';
import { NextJsTextLink } from '~/components/Buttons/Components/NextJsTextLink';
import { CartSvg } from '~/components/Cart.svg';

export const CartAnchor = observer(({ className = '', hideCount = false }) => {
	const {
		HREF: {
			cart = '/cart',
		} = {},
	} = useGlobalContext();

	return (
		<NextJsTextLink
			href={`${cart}`}
			className={cn('tw-block tw-relative tw-leading-[0] tw-h-5 md:tw-w-[34px] md:tw-h-[27px]', {
				[className]: Boolean(className),
			})}
			suppressHydrationWarning
			data-tr-link-event-comp-name="top links"
			data-tr-link-event-name="cart icon"
			data-qa="cart-items-status-link"
			data-track
			title="Cart"
		>
			<CartSvg />
			<SummaryCount hideCount={hideCount} />
		</NextJsTextLink>
	);
});

// eslint-disable-next-line import/no-default-export
export default CartAnchor;
