/* eslint jsx-a11y/no-static-element-interactions: "off" */

import React from 'react';
import { observer } from 'mobx-react';

import { CuralateUploadSelect } from '~/util/curalate/Components/CuralateUploadSelect';
import { Field } from '~/util/formz/Components/fields/Field';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

export const CuralateUploadFiles = observer((props) => {
	const {
		store,
		store: {
			controlReactPropsOverride = {},
			form: {
				fields: {
					files: filesField,
				} = {},
			} = {},
			uploadFiles,
			addFiles,
		} = {},
	} = props;

	const dragOverHandler = (event) => {
		event.preventDefault();
	};

	const dropHandler = (event) => {
		const { files } = event.dataTransfer;

		// IE11 won't let us use _.values on the native FileList created by <input type="file">, because it is read-only
		// ...so we manually build up an array like animals
		const filesToAdd = [];

		for (let i = 0; i < files.length; i++) {
			filesToAdd.push(files[i]);
		}

		event.preventDefault();

		addFiles(filesToAdd);
	};

	const beforeLabel = [
		<span className={styles['files-field-group-heading']} key={'files-field-group-heading'}>Drag & Drop</span>,
		<span className={styles['files-field-group-label-before']} key={'files-field-group-label-before'}>your content or </span>,
	];

	return (
		<div className={styles['curalate-upload-files']}>
			<fieldset>
				<div className={styles['upload-files-fieldset']}>
					<legend className="tw-sr-only">Upload your files</legend>
					<div className={styles['upload-files-droppable']} onDragOver={dragOverHandler} onDrop={dropHandler}>
						<Field beforeLabel={beforeLabel} field={filesField} reverseOrder={true} showValidationMessage={false} controlReactPropsOverride={controlReactPropsOverride} />
					</div>
				</div>
			</fieldset>
			<CuralateUploadSelect files={uploadFiles} store={store} />
		</div>
	);
});
