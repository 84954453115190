import React from 'react';

import { SelectorSwatchCombinedSwatch } from '~/product/casegoods/selector/Components/swatch-combined/SelectorSwatchCombinedSwatch';
import { SelectorSwatchCombinedSwatchHeading } from '~/product/casegoods/selector/Components/swatch-combined/SelectorSwatchCombinedSwatchHeading';
import { selectorHeadingCasegoodsComponents } from '~/product/casegoods/selector/Components/selector-heading-casegoods-components';

import styles from '#/product/casegoods/selector/swatch-combined/selector-swatch-combined-tabccordion-tab.module.scss';

export const SelectorSwatchCombinedTabccordionTab = (props = {}) => {
	const {
		tab: {
			isActive = false,
			selectorModel = {},
		} = {},
	} = props;

	return (
		<div className={styles['selector-swatch-combined-tabccordion-tab']}>
			<SelectorSwatchCombinedSwatchHeading
				hideOptionsCountWhenActive={true}
				isActive={isActive}
				selectedSelector={selectorModel}
				selectorHeadingOverrideComponents={selectorHeadingCasegoodsComponents}
			/>
			<SelectorSwatchCombinedSwatch
				isActive={isActive}
				selectedSelector={selectorModel}
			/>
		</div>
	);
};
