import { observer } from 'mobx-react';
import React from 'react';

import { SelectorValueSwatch } from '~/product/casegoods/selector/Components/swatch/SelectorValueSwatch';

import selectorStyles from '#/product/standard/selector/selector-swatch.module.scss';
import styles from '#/product/casegoods/selector/swatch-combined/selector-swatch-combined-swatch.module.scss';

export const SelectorSwatchCombinedSwatch = observer((props) => {
	const {
		selectedSelector: {
			label = '',
			spoSelectorValues = [],
			stockedSelectorValues = [],
		} = {},
	} = props;

	// these values MUST match what you have in CSS, and I hate it a whole bunch
	// nested flexbox columns don't grow their parent width, so we need to figure it out on our own...
	const selectorValueMargin = 4;

	const selectorValueWidth = 78;

	const spoSelectorValuesContainerWidth = spoSelectorValues.length * selectorValueWidth + (spoSelectorValues.length - 1) * selectorValueMargin;

	const stockedSelectorValuesContainerWidth = stockedSelectorValues.length * selectorValueWidth + (stockedSelectorValues.length - 1) * selectorValueMargin;

	return (
		<div className={`${styles['selector-swatch-container']}`}>
			<div className="selector-swatch">
				<fieldset className="selector-values-types-swatch-container">
					<div className={selectorStyles['selector-values-types-swatch']}>
						<legend className="tw-sr-only">Choose a {label}</legend>
						{
							stockedSelectorValues.length > 0 && (
								<div
									className={`${selectorStyles['selector-values-type-swatch']} selector-values-type-swatch-stocked`}
									style={{ flexBasis: stockedSelectorValuesContainerWidth }}
								>
									<div className={selectorStyles['selector-values-swatch']}>
										{
											stockedSelectorValues.map((selectorValue, index) => {
												return (
													<SelectorValueSwatch
														key={`selector-value-${index}`}
														label={label}
														selectorValue={selectorValue}
													/>
												);
											})
										}
									</div>
								</div>
							)
						}
						{
							spoSelectorValues.length > 0 && (
								<div
									className={`${selectorStyles['selector-values-type-swatch']} selector-values-type-swatch-spo`}
									style={{ flexBasis: spoSelectorValuesContainerWidth }}
								>
									<div className={selectorStyles['selector-values-swatch']}>
										{
											spoSelectorValues.map((selectorValue, index) => {
												return (
													<SelectorValueSwatch
														key={`selector-value-${index}`}
														label={label}
														selectorValue={selectorValue}
													/>
												);
											})
										}
									</div>
								</div>
							)
						}
					</div>
				</fieldset>
			</div>
		</div>
	);
});
