import React from 'react';
import { observer } from 'mobx-react';

import { DeliveryTruckIcon } from '~/delivery/Components/DeliveryTruckIcon';

export const DeliveryStandardWithoutZip = observer((props) => {
	const {
		store: {
			editZipCode,
			invalidTzone,
			shippingMethod,
		},
	} = props;

	return (
		<div data-qa={`delivery ${shippingMethod} without-zip`} className="delivery-container delivery-container-standard delivery-container-inhome without-zip-code">
			<div className="delivery-icon">
				<DeliveryTruckIcon />
			</div>

			<div className="delivery-content">
				{
					invalidTzone &&
					<p className="delivery-instructions is-invalid-tzone" id={'invalid-tzone-message'}>
						{'We cannot determine your specific delivery zone. Please contact a Design Associate at '}
						<a href="tel:+18003019720">800.301.9720</a>
						{'within the United States, or '}
						<a href="tel:+011-612-234-3049">+011 612.234.3049</a>
						{' internationally.'}
					</p>
				}
				{
					!invalidTzone &&
					<label htmlFor="zipCode-control-deliveryForm" className="delivery-instructions tw-font-semibold">
						<strong>$79 full-service delivery of unlimited items.</strong>
						<br />
						<button
							className="ButtonAnchor"
							onClick={editZipCode}
							data-qa="delivery-widget-enter-zip"
						>
							See delivery timing
						</button>
					</label>
				}
			</div>
		</div>
	);
});
