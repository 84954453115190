export const rugConstructionData = {
	RUGS_HAND_KNOTTED: {
		title: 'Construction: Hand-knotted',
		steps: [
			{
				stepTitle: 'How it’s made',
				img: 'Construction_Rug_Hand_Knotted_01',
				copy: 'Expert weavers graph and then create each hand-knotted wool rug one tiny knot at a time, completing about two inches or less per day. These skills are handed down—some weavers are part of a sixth generation of weavers.',
			},
			{
				stepTitle: 'Construction',
				img: 'Construction_Rug_Hand_Knotted_02',
				alt: 'Diagram showing weft yarns knotted individually on warp yarns.',
				copy: 'Weavers set columns of warps stretching across the loom. They complete the first weft, tamp it down with a mallet and continue through the entire rug, creating a one-of-a-kind work of art that is exceptionally durable.',
			},
			{
				stepTitle: 'Finish details',
				img: 'Construction_Rug_Hand_Knotted_03',
				copy: 'Hand-knotted rugs do not require backing layers because their construction makes them naturally stable and incredibly durable. Each rug is thoroughly inspected for quality and consistency, then washed.',
			},
		],
	},
	RUGS_HAND_TUFTED: {
		title: 'Construction: Hand-tufted',
		steps: [
			{
				stepTitle: 'How it’s made',
				img: 'HAND_TUFTED_RUG_CONSTRUCTION_01',
				copy: 'Skilled artisans use a handheld tufting tool to carefully create about 10 square inches per day. Their expertise is showcased in the consistent quality of the pattern produced.',
			},
			{
				stepTitle: 'Construction',
				img: 'HAND_TUFTED_RUG_CONSTRUCTION_02',
				alt: 'Diagram showing tufts pushed through primary backing fabric and adhered to secondary backing fabric with bonding agent.',
				copy: 'Using a handheld tufting tool, artisans insert loops of yarn through a fabric backing to create a looped or cut pile. The resulting pile is neat and uniform.',
			},
			{
				stepTitle: 'Finish details',
				img: 'HAND_TUFTED_RUG_CONSTRUCTION_03',
				copy: 'After the tufting process, a flexible synthetic latex backing is added by hand to hold the yarns in place, giving the rug a clean, finished back and increasing its strength and stability.',
			},
		],
	},
	RUGS_HAND_WOVEN: {
		title: 'Construction: Hand-woven',
		steps: [
			{
				stepTitle: 'How it’s made',
				img: 'Construction_Rug_Hand_Woven_01',
				copy: 'Experienced weavers use an upright or hand loom and traditional techniques to make hand-woven rugs. Due to the nature of this skill, weavers take one day to complete a small 4-7 inch square.',
			},
			{
				stepTitle: 'Construction',
				img: 'Construction_Rug_Hand_Woven_02',
				alt: 'Diagram showing horizontal weft yarns weaving over and under vertical warp yarns.',
				copy: 'Artisans weave warp yarns (vertical) with weft yarns (horizontal). Artisans use techniques that have been around for hundreds or even thousands of years to achieve the desired pattern and texture.',
			},
			{
				stepTitle: 'Finish details',
				img: 'Construction_Rug_Hand_Woven_03',
				alt: 'Diagram showing tufts pushed through primary backing fabric and adhered to secondary backing fabric with bonding agent.',
				copy: 'Because yarns are secured through the weaving process, hand-woven rugs do not require a backing. After thoroughly washing, each rug is hand-finished and inspected one at a time for quality and consistency.',
			},
		],
	},
	RUGS_MACHINE_LOOMED_HAND_FINISH: {
		title: 'Construction: Machine-loomed, Hand-finished Rugs',
		steps: [
			{
				stepTitle: 'How it’s made',
				img: 'Construction_Rug_Machine_Loomed_Hand_Finished_01',
				copy: 'State-of-the-art machines weave the entire rug, producing a uniform look and dense weave at a great value. Finishing details are completed primarily by hand.',
			},
			{
				stepTitle: 'Construction',
				img: 'Construction_Rug_Machine_Loomed_Hand_Finished_02',
				alt: 'Diagram showing tufts pushed through primary backing fabric and adhered to secondary backing fabric with bonding agent.',
				copy: 'Computer-controlled tufting needles push loops of yarn through a backing, then craftspeople fill in gaps with hand tools. Using scissors, they trim excess yarns and inspect each rug for consistency and quality.',
			},
			{
				stepTitle: 'Finish details',
				img: 'Construction_Rug_Machine_Loomed_Hand_Finished_03',
				copy: 'A non-allergenic synthetic latex backing is rolled on by hand to hold the yarns in place. Craftspeople add a second and third backing to increase the rug’s strength and stability and create a smooth back.',
			},
		],
	},
};
