import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import Slider, { LazyLoadTypes } from 'react-slick';

import { Media } from '~/media-set/Models/Media.model';
import type { MediaSetStore } from '~/media-set/Stores/MediaSet.store';
import type { MediaSet } from '~/media-set/Models/MediaSet.model';
import { MediaMain } from '~/media-set/Components/MediaMain';
import { MediaNavNext, MediaNavPrev } from '~/media-set/Components/MediaNav';
import { noop } from '~/util/noop';
import styles from '#/media-set/media-set.module.scss';
import { MediaSetVariants } from '~/media-set/Helpers/MediaSetVariants';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { updateSlidesAttributes } from '~/media-set/Utils/Media.utils';
import sharedSlickStyles from '~/components/content-slick-slider/content-slick-slider-shared.module.scss';

interface Props {
	hideCounter?: boolean,
	hideMediaMainLinks?: boolean,
	imagePreset?: string,
	infinite?: boolean,
	initialSlide?: number,
	lazyLoad?: LazyLoadTypes,
	mainHeight?: number,
	mainWidth?: number,
	mediaComponentsOverride?: object,
	mediaMainOnClick?: Function,
	mediaSetModel: MediaSet,
	mediaSetOverride?: any,
	mediaSetOverrideBeforeChange?: Function,
	mediaSetStore: MediaSetStore,
	resetMediaSetMouseLeave?: boolean,
	showArrows?: boolean,
	showCounterForMedium?: boolean,
	showDimensions?: boolean,
	speed?: number,
	trLinkEventCompName?: string,
	trLinkEventCompType?: string,
	trLinkEventUseCompNameH1?: string,
	variant?: typeof MediaSetVariants,
}

export const MediaSetMinimal = observer((props: Props) => {
	const {
		hideCounter = false,
		hideMediaMainLinks = false,
		imagePreset = undefined,
		infinite = true,
		initialSlide = 0,
		lazyLoad = 'ondemand',
		mainHeight = 400,
		mainWidth = 600,
		mediaComponentsOverride = {},
		mediaMainOnClick,
		mediaSetModel,
		mediaSetModel: {
			isMediaSetOverrideActive = false,
			isSingleMediaSet = false,
			mediaModels = [],
			mediaModelsCount = 0,
			selectedMediaIndex = 0,
		},
		mediaSetOverride,
		mediaSetOverrideBeforeChange = noop,
		mediaSetStore,
		resetMediaSetMouseLeave = false,
		showArrows = false,
		showCounterForMedium = true,
		showDimensions = false,
		speed = 300,
		trLinkEventCompType,
		trLinkEventCompName,
		trLinkEventUseCompNameH1,
		variant = (MediaSetVariants as any).SLIDING_THUMBNAILS,
	} = props;

	const {
		linkEventStore,
	} = useGlobalContext();

	const [mediaCurrentIndex, setMediaCurrentIndex] = useState(selectedMediaIndex);

	const didInitializeRef = useRef(false);

	const mediaSetRef = useRef<Slider | null>(null);

	const mediaSetMarkupRef = useRef<HTMLDivElement | null>(null);

	const settings = {
		adaptiveHeight: false,
		arrows: showArrows,
		lazyLoad,
		afterChange: (currentIndex: number) => {
			setMediaCurrentIndex(currentIndex);
			updateSlidesAttributes(mediaSetMarkupRef);
		},
		beforeChange: (prevIndex: number, nextIndex: number) => {
			// let react-slick finish animating before triggering a re-render, or it will jank noticeably
			setTimeout(() => {
				runInAction(() => {
					if (mediaModelsCount > nextIndex) {
						mediaSetStore.selectMediaModel(mediaModels[nextIndex]);
						setMediaCurrentIndex(nextIndex);
					}
					mediaSetOverrideBeforeChange(nextIndex);
				});
			}, 150);
		},
		dots: false,
		infinite: isSingleMediaSet ? false : infinite,
		initialSlide,
		nextArrow: (
			<MediaNavNext
				hideArrows={isMediaSetOverrideActive}
				showArrowsForMedium={showArrows}
				showArrowsForSmall={showArrows}
				variant={variant}
			/>
		),
		prevArrow: (
			<MediaNavPrev
				hideArrows={isMediaSetOverrideActive}
				showArrowsForMedium={showArrows}
				showArrowsForSmall={showArrows}
				variant={variant}
			/>
		),
		onInit: () => {
			setTimeout(() => {
				updateSlidesAttributes(mediaSetMarkupRef);
			}, 150);
		},
		onSwipe: (direction: string) => {
			// if the user swipes left it is the equivelent of a right arrow navigation on desktop
			// e.g. user is on index 0, swipe left index = 1 which is the same as hitting the right arrow on desktop
			const navigationDirection = direction === 'left' ? 'right' : 'left';

			if (linkEventStore) {
				const linkEventTrackingData = {
					trLinkEventName: `${navigationDirection} navigation`,
					trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
					trLinkEventCompName,
					trLinkEventCompType,
					trLinkEventUseCompNameH1,
					trLinkEventCompPosition: `${mediaCurrentIndex + 1}:${mediaModels.length}`
				};

				linkEventStore.trackLinkEvent(linkEventTrackingData);
			}
		},
		// setting slidesToShow to 0 will force the thumbnails to render for the single mediaSet scenario
		slidesToShow: 1,
		speed,
		touchMove: true,
	};

	function handleMouseLeave() {
		if (mediaSetRef.current && mediaCurrentIndex !== initialSlide) {
			mediaSetRef.current.slickGoTo(initialSlide);
		}
	}

	// keep the slick instance in sync with programmatic selectedMediaIndex changes after intial mount
	useEffect(() => {
		if (didInitializeRef.current) {
			// allow mobx-react observer to finish its re-render before calling slickGoTo
			setTimeout(() => {
				if (mediaSetRef.current) {
					mediaSetRef.current.slickGoTo(selectedMediaIndex);
					setMediaCurrentIndex(selectedMediaIndex);
				}
			}, 200);
		} else {
			didInitializeRef.current = true;
		}
	}, [selectedMediaIndex]);

	return (
		/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<div
			className={
				classNames(styles['media-set'], {
					[styles['media-set-override']]: isMediaSetOverrideActive,
				})
			}
			data-qa="media-set"
			data-tr-link-event-comp-name={trLinkEventCompName}
			data-tr-link-event-comp-type={trLinkEventCompType}
			data-tr-link-event-comp-position={`${mediaCurrentIndex + 1}:${mediaModels.length}`}
			onMouseLeave={resetMediaSetMouseLeave ? handleMouseLeave : undefined}
			role="region" aria-label="carousel"
			ref={mediaSetMarkupRef}
		>
			<p className="tw-sr-only">
				This section of the page contains a carousel that visually displays various linked images one at a time. For screen reader users, these images appear in a list below. Selecting the links changes the main slide visually.
			</p>
			{
				mediaSetOverride && (
					<div
						className={
							classNames({
								'tw-block': isMediaSetOverrideActive,
								'tw-hidden': !isMediaSetOverrideActive,
							})
						}
					>
						{mediaSetOverride}
					</div>
				)
			}
			<Slider
				{...settings}
				className={sharedSlickStyles['content-slick-slider-shared']}
				ref={mediaSetRef}
			>
				{
					mediaModels.map((mediaModel: Media, index: number) => {
						return (
							<MediaMain
								hideCounter={hideCounter}
								hideMediaMainLinks={hideMediaMainLinks}
								imagePreset={imagePreset}
								key={`media-main-${index}`}
								mainHeight={mainHeight}
								mainWidth={mainWidth}
								mediaComponentsOverride={mediaComponentsOverride}
								mediaModel={mediaModel}
								mediaSetModel={mediaSetModel}
								onClick={mediaMainOnClick}
								showCounterForMedium={showCounterForMedium}
								showDimensions={showDimensions}
								showDriftZoom={false}
							/>
						);
					})
				}
			</Slider>
		</div>
	);
});
