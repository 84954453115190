import { computed, observable, makeObservable } from 'mobx';

import {
	getQuestionSelectorValuesByPriceGroup,
	getQuestionSelectorValuesByProductModel,
	getStockedQuestionSelectorValues,
} from '~/product/common/selector/Utils/SelectorConfig.utils';
import { sortBy } from '~/util/sortBy';

class SelectorQuestion {
	blurbs;

	dimensionQuestionSelectorModels;

	displayOrder;

	displayType;

	filter;

	filtersMagicTabccordionStore;

	filtersModel;

	filtersStore;

	firstSelectorValueData;

	groupValuesByStocked;

	hasMatchingProductSelector;

	id;

	isExposed;

	label;

	promos;

	questionGroups;

	renderer;

	selected;

	selectedMaterialSelectorModel;

	selectedProduct;

	selectedProducts;

	selectorValues;

	showAllValues;

	showImages;

	showLinks;

	showSelectorDetails;

	showWidthQuantityMessage;

	summaryRenderer;

	type;

	unavailable;

	constructor() {
		makeObservable(this, {
			hasMatchingProductSelector: observable,
			selected: observable,
			selectedMaterialSelectorModel: observable.ref,
			selectedProduct: observable.ref,
			selectedProducts: observable.ref,
			selectorValues: observable.ref,
			filteredSelectorValues: computed,
			hasFilters: computed,
			hasFilterData: computed,
			hasValidSelectorValues: computed,
			selectedMaterialSelectorValue: computed,
			selectedSelectorValue: computed,
			selectorValuesToRender: computed,
			selectorValuesToRenderCount: computed,
			spoSelectorValues: computed,
			spoSelectorValuesByPriceGroup: computed,
			spoSelectorValuesCount: computed,
			stockedSelectorValues: computed,
			stockedSelectorValuesByPriceGroup: computed,
			validSelectorValues: computed,
		});
	}

	get filteredSelectorValues() {
		const { filteredSelectorValues = [] } = this.filtersModel;

		return filteredSelectorValues.filter(({ unavailable = false }) => !unavailable);
	}

	get hasFilters() {
		return this.filtersModel.hasFilters;
	}

	get hasFilterData() {
		return Boolean(Object.keys(this.filter).length);
	}

	get hasValidSelectorValues() {
		return Boolean(this.validSelectorValues.length);
	}

	get selectedMaterialSelectorValue() {
		const { selectedSelectorValue = {} } = this.selectedMaterialSelectorModel;

		return selectedSelectorValue;
	}

	get selectedSelectorValue() {
		return this.selectorValues.find(({ selected = false }) => selected);
	}

	get selectorValuesToRender() {
		if (this.showAllValues) {
			return sortBy(this.filteredSelectorValues, 'title');
		}

		return getQuestionSelectorValuesByProductModel({
			productModel: this.selectedProduct,
			selectorValues: this.filteredSelectorValues,
		});
	}

	get selectorValuesToRenderCount() {
		return this.selectorValuesToRender.length;
	}

	get spoSelectorValues() {
		return this.selectorValuesToRender.filter((selectorValue = {}) => !this.stockedSelectorValues.includes(selectorValue));
	}

	get spoSelectorValuesByPriceGroup() {
		return getQuestionSelectorValuesByPriceGroup({
			productModel: this.selectedProduct,
			selectorValues: this.spoSelectorValues,
		});
	}

	get spoSelectorValuesCount() {
		return this.spoSelectorValues.length;
	}

	get stockedSelectorValues() {
		return getStockedQuestionSelectorValues({
			materialSelectorModel: this.selectedMaterialSelectorModel,
			questionSelectorValues: this.selectorValuesToRender,
			selectedProduct: this.selectedProduct,
			selectedProducts: this.selectedProducts,
		});
	}

	get stockedSelectorValuesByPriceGroup() {
		return getQuestionSelectorValuesByPriceGroup({
			productModel: this.selectedProduct,
			selectorValues: this.stockedSelectorValues,
		});
	}

	get validSelectorValues() {
		const dimensionQuestionSelectorValues = this.dimensionQuestionSelectorModels.reduce((accumulatedDimensionQuestionSelectorModels = [], { validSelectorValues = [] } = {}) => {
			return [...accumulatedDimensionQuestionSelectorModels, ...validSelectorValues];
		}, []);

		if (dimensionQuestionSelectorValues.length) {
			return dimensionQuestionSelectorValues;
		}

		return getQuestionSelectorValuesByProductModel({
			productModel: this.selectedProduct,
			selectorValues: this.selectorValues,
		});
	}
}

export const SelectorQuestionModelFactory = ({
	create: ({
		blurbs = [],
		dimensionQuestionSelectorModels = [],
		displayOrder = 0,
		displayType = '',
		filter = {},
		filtersMagicTabccordionStore = {},
		filtersModel = {},
		filtersStore = {},
		firstSelectorValueData = {},
		groupValuesByStocked = false,
		hasMatchingProductSelector = false,
		id = '',
		isExposed = false,
		label = '',
		promos = [],
		questionGroups = [],
		renderer = '',
		selected = false,
		selectedMaterialSelectorModel = {},
		selectedProduct = {},
		selectedProducts = [],
		selectorValues = [],
		showAllValues = false,
		showImages = false,
		showLinks = [],
		showSelectorDetails = false,
		showWidthQuantityMessage = false,
		subheader = '',
		summaryRenderer = '',
		type = '',
	}) => {
		const selectorQuestion = new SelectorQuestion();

		Object.assign(selectorQuestion, {
			blurbs,
			dimensionQuestionSelectorModels,
			displayOrder,
			displayType,
			filter,
			filtersMagicTabccordionStore,
			filtersModel,
			filtersStore,
			firstSelectorValueData,
			groupValuesByStocked,
			hasMatchingProductSelector,
			id,
			isExposed,
			label,
			promos,
			questionGroups,
			renderer,
			selected,
			selectedMaterialSelectorModel,
			selectedProduct,
			selectedProducts,
			selectorValues,
			showAllValues,
			showImages,
			showLinks,
			showSelectorDetails,
			showWidthQuantityMessage,
			subheader,
			summaryRenderer,
			type,
		});

		return selectorQuestion;
	},
});
