import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import styles from '#/product/standard/details/details-attributes.module.scss';
import stylesDetail from '#/product/standard/details/details.module.scss';

export const DetailArticleDimensionsCasegoods = observer((props) => {
	const {
		workspaceModel: {
			sortedWorkspaceProducts = [],
		} = {},
	} = useProductContext();

	const {
		detail: {
			label = '',
		} = {},
		detailsListIndex = 0,
	} = props;

	const workspaceProduct = sortedWorkspaceProducts[detailsListIndex];

	const { workspaceProductDimensions = '' } = workspaceProduct;

	return (
		<div
			className={`${styles['detail']} ${stylesDetail['detail']} detail-name-value`}
			data-qa="detail-name-value"
		>
			<strong data-qa="detail-name-value-name">{label}:</strong>
			<span data-qa="detail-name-value-value"> {workspaceProductDimensions}</span>
		</div>
	);
});
