const getAllSlideChildren = (htmlElement) => {
	if (htmlElement.children.length === 0) return [htmlElement];

	const allChildElements = [];

	for (let i = 0; i < htmlElement.children.length; i++) {
		const children = getAllSlideChildren(htmlElement.children[i]);
		if (children) allChildElements.push(...children);
	}
	allChildElements.push(htmlElement);

	return allChildElements.filter(child => child.tagName === 'BUTTON' || child.tagName === 'A');
};

const updateSlideAttributes = (node = null, hidden = true) => {
	if (hidden) {
		node.setAttribute('tabIndex', -1);
		node.setAttribute('aria-hidden', true);
	} else {
		node.removeAttribute('tabIndex');
		node.removeAttribute('aria-hidden');
	}
};

export const updateSlidesAttributes = (ref) => {
	if (ref.current) {
		const inactiveNodes = ref.current.querySelectorAll('.slick-slide:not(.slick-active), .slick-slide:not(.slick-active) .slide-dependent-button');

		const activeNodes = ref.current.querySelectorAll('.slick-slide.slick-active, .slick-slide.slick-active .slide-dependent-button');

		const shouldHideInactiveNodes = Boolean(activeNodes.length);

		inactiveNodes.forEach((node) => {
			updateSlideAttributes(node, shouldHideInactiveNodes);
			getAllSlideChildren(node).forEach((child) => {
				updateSlideAttributes(child, shouldHideInactiveNodes);
			});
		});

		activeNodes.forEach((node) => {
			updateSlideAttributes(node, false);
			getAllSlideChildren(node).forEach((child) => {
				updateSlideAttributes(child, false);
			});
		});
	}
};
