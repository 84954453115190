import { SelectorHeadingFabric } from '~/product/sectionals/selector/Components/fabric/SelectorHeadingFabric';
import { SelectorHeadingLayout } from '~/product/sectionals/selector/Components/layout/SelectorHeadingLayout';
import { SelectorHeadingMediumImage } from '~/product/sectionals/selector/Components/medium-image/SelectorHeadingMediumImage';
import { SelectorHeadingSwatch } from '~/product/sectionals/selector/Components/swatch/SelectorHeadingSwatch';
import { SelectorHeadingDownload } from '~/product/sectionals/selector/Components/download/SelectorHeadingDownload';
import { SelectorHeadingUnattachedOttoman } from '~/product/sectionals/selector/Components/unattached-ottoman/SelectorHeadingUnattachedOttoman';

export const selectorHeadingSectionalsComponents = {
	CUSTOM_SWATCH: SelectorHeadingSwatch,
	DOWNLOAD: SelectorHeadingDownload,
	FABRIC: SelectorHeadingFabric,
	LAYOUT: SelectorHeadingLayout,
	MEDIUM_IMAGE: SelectorHeadingMediumImage,
	// NONE: SelectorNone,
	SWATCH: SelectorHeadingSwatch,
	UNATTACHED_OTTOMAN: SelectorHeadingUnattachedOttoman,
};
