import React from 'react';
import { observer } from 'mobx-react';

import styles from '~/util/curalate/Components/curalate-detail.module.scss';
import { S7Image } from '~/components/Images/S7Image';

export const CuralateItemImage = observer(({
	deferImageLoad,
	media,
	srcSetSizeKey,
	srcDimensions = {},
	srcSizeKey,
}) => {
	if (deferImageLoad) {
		return null;
	}

	const { width = '', height = '' } = srcDimensions;

	// media type "video" has a "poster" node we need to bind to instead of "media"
	const mediaToUse = media.poster || media;
	const {
		[srcSetSizeKey]: {
			link: srcSetSizeLink,
		},
		[srcSizeKey]: {
			link: srcSizeLink,
		},
	} = mediaToUse;

	return (
		<S7Image
			alt=""
			className={styles['curalate-item-image']}
			src={srcSizeLink}
			srcSet={`${srcSetSizeLink} 2x`}
			width={width}
			height={height}
			data-qa="curalate-item-image"
		/>
	);
});
