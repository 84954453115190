const highestComfortRatings = {
	mattressComfort: 'Softest',
	comfort: 'Firm',
	depth: 'Deep',
	height: 'High',
};

const lowestComfortRatings = {
	mattressComfort: 'Firmest',
	comfort: 'Soft',
	depth: 'Shallow',
	height: 'Low',
};

const meterDataMap = {
	mattressComfort: [
		{
			comfortRating: 'Firm',
			meterValue: 1,
			type: 'BASIC_5',
		},
		{
			comfortRating: 'Firm',
			meterValue: 1,
			type: 'ESSENTIAL_5',
		},
		{
			comfortRating: 'Medium-firm',
			meterValue: 2,
			type: 'NATURAL_FOAM_4',
		},
		{
			comfortRating: 'Medium-firm',
			meterValue: 2,
			type: 'NATURAL_HYBRID_4',
		},
		{
			comfortRating: 'Medium',
			meterValue: 3,
			type: 'LUXURY_3',
		},
		{
			comfortRating: 'Medium-soft',
			meterValue: 4,
			type: 'LUXURY_2',
		},
		{
			comfortRating: 'Medium-soft',
			meterValue: 4,
			type: 'ESSENTIAL_2',
		},
	],
	comfort: [
		{
			comfortRating: 'Very soft',
			meterValue: 1,
			type: 'BLEND-DOWN',
		},
		{
			comfortRating: 'Medium soft',
			meterValue: 2,
			type: 'SPRING AND DOWN',
		},
		{
			comfortRating: 'Medium soft',
			meterValue: 2,
			type: 'SPRING&DOWN ALTERNATIVE',
		},
		{
			comfortRating: 'Medium softness',
			meterValue: 3,
			type: 'SPRING AND FOAM',
		},
		{
			comfortRating: 'Medium firm',
			meterValue: 4,
			type: 'WRAPPED FOAM',
		},
		{
			comfortRating: 'Very firm',
			meterValue: 5,
			type: 'FOAM AND FIBER',
		},
	],
	depth: [
		{
			comfortRating: 'Very shallow',
			meterValue: 1,
			min: 0,
			max: 18,
		},
		{
			comfortRating: 'Shallow',
			meterValue: 2,
			min: 19,
			max: 20,
		},
		{
			comfortRating: 'Medium depth',
			meterValue: 3,
			min: 21,
			max: 23,
		},
		{
			comfortRating: 'Between medium deep and deepest',
			meterValue: 4,
			min: 24,
			max: 25,
		},
		{
			comfortRating: 'Very deep',
			meterValue: 5,
			min: 26,
			max: 99999,
		},
	],
	height: [
		{
			comfortRating: 'Very short',
			meterValue: 1,
			min: 0,
			max: 15,
		},
		{
			comfortRating: 'Medium low',
			meterValue: 2,
			min: 16,
			max: 16,
		},
		{
			comfortRating: 'Medium height',
			meterValue: 3,
			min: 17,
			max: 18,
		},
		{
			comfortRating: 'Medium high',
			meterValue: 4,
			min: 19,
			max: 20,
		},
		{
			comfortRating: 'Very high',
			meterValue: 5,
			min: 21,
			max: 99999,
		},
	],
};

const meterTitles = {
	comfort: 'Seat Comfort',
	depth: 'Seat Depth',
	height: 'Seat Height',
};

export {
	highestComfortRatings,
	lowestComfortRatings,
	meterDataMap,
	meterTitles,
};
