import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';

import { DetailLink } from '~/product/common/details/Components/details/DetailLink';
import { selectorSectionalsComponents } from '~/product/sectionals/selector/Components/selector-sectionals-components';
import { WorkspaceSectionals } from '~/product/sectionals/workspace/Components/WorkspaceSectionals';
import { imageSettingsWorkspaceSectionals } from '~/product/sectionals/workspace/workspace-sectionals.constants';

import styles from '~/product/sectionals/selector/Components/selector.module.scss';

const DetailLinkDynamic = observer(({
	selectedSelector: {
		selectedMaterialSelector: {
			selectedSelectorValue: {
				selectorDetailLink: materialSelectorDetailLink,
			} = {},
			showSelectorDetails: materialSelectorShowSelectorDetails = false,
		} = {},
		showSelectorDetails: selectedSelectorShowSelectorDetails = false,
		selectedSelectorValue: {
			selectorDetailLink: selectedSelectorDetailLink,
		} = {},
	} = {},
}) => {
	const selectorDetailLink = materialSelectorDetailLink || selectedSelectorDetailLink;

	const showSelectorDetails = materialSelectorShowSelectorDetails || selectedSelectorShowSelectorDetails;

	if (!showSelectorDetails || !selectorDetailLink) {
		return null;
	}

	return (
		<div className="md:tw-hidden">
			<DetailLink detail={selectorDetailLink} />
		</div>
	);
});

export const SelectorTabccordionTabSectionals = (props) => {
	const {
		tab: {
			isActive = false,
			selectedSelector = {},
			selectedSelector: {
				renderer = '',
			} = {},
		},
	} = props;

	const { small: imageSettings = 0 } = imageSettingsWorkspaceSectionals;

	const tabccordionTabRef = useRef();

	// magicSlider was triggering webkitAnimationEnd events as elements are modified on-the-fly
	// this was ultimately tiggering unwanted calls to magicTabccordionStore.smoothScroll()
	// so we stop these events from propagating from beyond this component
	useEffect(() => {
		function handleAnimationEnd(event) {
			event.stopPropagation();
		}

		if (tabccordionTabRef.current) {
			tabccordionTabRef.current.addEventListener('webkitAnimationEnd', handleAnimationEnd);
		}

		return function cleanupAnimationEnd() {
			if (tabccordionTabRef.current) {
				tabccordionTabRef.current.removeEventListener('webkitAnimationEnd', handleAnimationEnd);
			}
		};
	}, []);

	let SelectorRenderer = selectorSectionalsComponents[renderer];

	if (!SelectorRenderer) {
		SelectorRenderer = selectorSectionalsComponents.LAYOUT;

		console.warn(`No selector renderer found for "${renderer}"`);
	}

	return (
		<div
			className={styles['selector-tabccordion-tab']}
			ref={tabccordionTabRef}
		>
			{
				isActive && (
					<DetailLinkDynamic selectedSelector={selectedSelector} />
				)
			}
			{
				isActive && (
					<div className="md:tw-hidden">
						<WorkspaceSectionals imageSettings={imageSettings} />
					</div>
				)
			}
			<SelectorRenderer
				isActive={isActive}
				selectedSelector={selectedSelector}
			/>
		</div>
	);
};
