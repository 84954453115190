import { IStyliticsPresentJSON } from '~/tracking/vendor-events/stylitics/style-present/Interfaces/IStyliticsPresentEvent.tracking';
import { sanitize } from '~/util/sanitizeString';
import { VendorEventTrackingModel } from '~/tracking/vendor-events/common/Models/VendorEvent.tracking.model';

export class StyliticsPresentEventTrackingModel {
	articleNumber: string;

	count: number;

	material: string | null;

	constructor(articleNumber: string, material: string | null, count: number) {
		this.articleNumber = articleNumber;
		this.count = count;
		this.material = material;
	}

	get styleKey() {
		if (this.material) {
			return `${this.articleNumber}|${this.material}`;
		}
		return this.articleNumber;
	}

	get trackingJson(): IStyliticsPresentJSON {
		const vendorEventTrackingModel = new VendorEventTrackingModel();
		return {
			event: 'stylepresent',
			vendor: {
				stylePresent: this.count,
				styleKey: sanitize(this.styleKey),
			},
			content: vendorEventTrackingModel.pageID,
			page: {
				id: vendorEventTrackingModel.pageID,
			},
		};
	}
}
