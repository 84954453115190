import { observer } from 'mobx-react';
import React from 'react';

import styles from '#/product/standard/details/details-attributes.module.scss';
import stylesDetail from '#/product/standard/details/details.module.scss';

export const DetailMultiValue = observer((props) => {
	const {
		detail: {
			values = [],
			label = '',
		} = {},
	} = props;

	if (!values.length) {
		return null;
	}

	return (
		<div className={`${styles['detail']} ${stylesDetail['detail']} detail-name-value tw-flex-col tw-space-y-2`} data-qa="detail-name-value">
			<span>
				<strong data-qa="detail-name-value-name">
					{`${label}:`}
				</strong>
			</span>

			{
				values.map((value, i) => {
					const {
						articleNumber,
						description,
					} = value;

					return (
						<div key={`multiValue-detail_${i}`}>
							<span data-qa="details-article-number">
								{articleNumber} {description}
							</span>
						</div>
					);
				})
			}
		</div>
	);
});
