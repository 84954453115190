import {
	action, autorun, computed, reaction, observable, makeObservable,
} from 'mobx';

import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { loggedOutAction } from '~/account/sign-in/Actions/loggedOut.action';
import { DeliveryTrackingStoreFactory } from '~/delivery/Stores/DeliveryTracking.store';
import { DeliveryFormStoreFactory } from '~/delivery/Stores/DeliveryForm.store';
import { DeliveryStandardWithZip } from '~/delivery/Components/DeliveryStandardWithZip';
import { DeliveryStandardWithoutZip } from '~/delivery/Components/DeliveryStandardWithoutZip';
import { DeliveryForm } from '~/delivery/Components/DeliveryForm';
import { isOnServer } from '~/global/global.constants';

class DeliveryInHomeStore {
	flavor

	hasLoaded

	shippingMethod

	hasZipCode

	invalidTzone

	results

	observers

	formStore

	trackingStore

	deliveryMainStore

	globalDynamicStore

	constructor() {
		makeObservable(this, {
			flavor: observable,
			hasLoaded: observable,
			shippingMethod: observable,
			hasZipCode: observable,
			invalidTzone: observable,
			results: observable,
			viewState: computed,
			editZipCode: action.bound,
			loadResults: action.bound,
		});
	}

	get viewState() {
		if (this.hasLoaded) {
			return this.viewStates[this.flavor];
		}
		return this.viewStates.common;
	}

	editZipCode() {
		this.invalidTzone = false;
		this.formStore.form.updateModelValue('zipCode', '');
		this.viewState.goTo('editZipCode');
	}

	loadResults() {
		const tzoneData = this.globalDynamicStore.transZoneStore.model;

		if (!tzoneData.contentAreaKey) {
			this.invalidTzone = false;
			this.results = tzoneData;
			this.hasLoaded = true;
		}

		const promise = this.globalDynamicStore.transZoneStore.getTransZone()
			.then((response) => {
				this.invalidTzone = false;
				this.results = response?.data;
				this.hasLoaded = true;
			}, () => {
				this.hasLoaded = true;
			});

		return promise;
	}
}

export const DeliveryInHomeStoreFactory = {
	create(productCommons, globalDynamicStore, options) {
		const deliveryInHomeStore = new DeliveryInHomeStore();
		const { productModel } = productCommons;

		deliveryInHomeStore.globalDynamicStore = globalDynamicStore;
		deliveryInHomeStore.observers = [];
		deliveryInHomeStore.flavor = options.flavor || 'standard';
		deliveryInHomeStore.hasLoaded = false;
		deliveryInHomeStore.shippingMethod = productModel.delivery.shippingMethod;
		deliveryInHomeStore.hasZipCode = false;
		deliveryInHomeStore.invalidTzone = false;
		deliveryInHomeStore.results = null;
		deliveryInHomeStore.showZip = false;
		deliveryInHomeStore.formStore = DeliveryFormStoreFactory.create(deliveryInHomeStore, globalDynamicStore);
		deliveryInHomeStore.trackingStore = DeliveryTrackingStoreFactory.create(deliveryInHomeStore);
		deliveryInHomeStore.viewStates = {
			common: new ViewStateStore(null, {
				pending: {
					component: () => {
						return null;
					},
				},
			}),
			standard: new ViewStateStore(deliveryInHomeStore, {
				withZipCode: {
					component: DeliveryStandardWithZip,
					onEnter: (viewState, store) => {
						return { store };
					},
				},
				withoutZipCode: {
					component: DeliveryStandardWithoutZip,
					onEnter: (viewState, store) => {
						return { store };
					},
				},
				editZipCode: {
					component: DeliveryForm,
					onEnter: (viewState, store) => {
						return {
							store,
							controlReactPropsOverride: store.invalidTzone && {
								'aria-describedby': 'invalid-tzone-message',
								'aria-invalid': true,
							},
						};
					},
				},
			}),
		};

		deliveryInHomeStore.observers.push(
			autorun(() => {
				if (deliveryInHomeStore.hasLoaded && deliveryInHomeStore.results) {
					if (!deliveryInHomeStore.results.zipCode) {
						deliveryInHomeStore.hasZipCode = false;
						deliveryInHomeStore.viewState.goTo('withoutZipCode');
					} else {
						deliveryInHomeStore.hasZipCode = true;
						deliveryInHomeStore.viewState.goTo('withZipCode');
					}
				} else {
					deliveryInHomeStore.hasZipCode = false;
					deliveryInHomeStore.viewStates.common.goTo('pending');
				}
			}),
			reaction(() => globalDynamicStore.transZoneStore.model.zipCode, () => {
				deliveryInHomeStore.loadResults();
			}),
			reaction(() => deliveryInHomeStore.formStore.form.model.zipCode, () => {
				deliveryInHomeStore.invalidTzone = false;
			}),
		);

		deliveryInHomeStore.loadResults().then(() => {
			if (isOnServer) {
				deliveryInHomeStore.observers.forEach((disposer) => {
					disposer();
				});
			}
		});

		if (loggedOutAction) {
			loggedOutAction.add(function () {
				deliveryInHomeStore.invalidTzone = false;
				deliveryInHomeStore.results = null;
				deliveryInHomeStore.hasLoaded = true;
				deliveryInHomeStore.viewState.goTo('withoutZipCode');
			});
		}

		return deliveryInHomeStore;
	},
	destroy(store) {
		store.observers?.forEach?.(dispose => dispose());
	},
};
