import { observer } from 'mobx-react';
import React from 'react';
import classNames from 'classnames';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { MediaSetDetail } from '~/product/common/media-set/Components/MediaSetDetail';
import { mediaSectionalsComponents } from '~/product/sectionals/media-set/Components/media-components-sectionals';
import { WorkspaceControlsSectionals } from '~/product/sectionals/workspace/Components/controls/WorkspaceControlsSectionals';
import { WorkspaceProductsSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductsSectionals';
import { WorkspaceEmptyDroppableSectionals } from '~/product/sectionals/workspace/Components/WorkspaceEmptyDroppableSectionals';
import { WorkspaceInvalidConfigurationMessage } from '~/product/sectionals/workspace/Components/WorkspaceInvalidConfigurationMessage';
import { WorkspaceMinumumSpacingMessage } from '~/product/sectionals/workspace/Components/WorkspaceMinumumSpacingMessage';

import styles from '~/product/sectionals/workspace/Components/workspace.module.scss';

interface Props {
	imageSettings: any
}

export const WorkspaceSectionals = observer(({ imageSettings = {} }: Props) => {
	const {
		workspaceModel: {
			activeTabType = '',
			hasWorkspaceProductModels = false,
			showInvalidConfigurationMessage = false,
			showMinimumSpacingMessage = false,
		} = {},
	} = useProductContext();

	// this is a shim to make typescript happy about MediaSetDetail not being in TS yet.
	const mediaSetDetailProps = { mediaComponentsOverride: mediaSectionalsComponents };

	const showMediaSet = activeTabType === 'QUESTION' || activeTabType === 'CUSTOM_SECTIONAL_DESIGNER_OTTOMAN';

	const isDraggable = !['DEPTH', 'DOWNLOAD'].includes(activeTabType);

	const workspaceComponent = showMediaSet
		? <MediaSetDetail {...mediaSetDetailProps} />
		: <WorkspaceProductsSectionals
			imageSettings={imageSettings}
			isDraggable={isDraggable}
			isMainWorkspace={true}
		/>;

	return (
		<div className={classNames(styles['workspace'], 'print:tw-items-start')}>
			{
				hasWorkspaceProductModels
					? workspaceComponent
					: <WorkspaceEmptyDroppableSectionals />
			}
			{
				showInvalidConfigurationMessage && (
					<WorkspaceInvalidConfigurationMessage />
				)
			}
			{
				showMinimumSpacingMessage && (
					<WorkspaceMinumumSpacingMessage />
				)
			}
			<WorkspaceControlsSectionals isMainWorkspace={isDraggable && !showMediaSet} />
		</div>
	);
});
