import { computed, observable, makeObservable } from 'mobx';

import { s7ImagePath } from '~/global/global.constants';
import { getProductModelsByQuestionAnswers } from '~/product/common/selector/Utils/SelectorConfig.utils';

export class SelectorValueProduct {
	articleNumbers;

	detailsModel;

	filters;

	id;

	imageSrc;

	parentDisplayType;

	parentLabel;

	parentRenderer;

	parentType;

	peerId;

	preselected;

	products;

	questionSelectorsQuestionAnswers;

	selected;

	selectorDetailsModel;

	selectors;

	status;

	stocked;

	subtitle;

	title;

	_links;

	constructor() {
		makeObservable(this, {
			preselected: observable,
			questionSelectorsQuestionAnswers: observable.ref,
			selected: observable,
			detailImage: computed,
			detailsToRender: computed,
			hasSelectors: computed,
			hasSelectorDetails: computed,
			image: computed,
			preselectedProduct: computed,
			preselectedProductModel: computed,
			preselectedSelector: computed,
			selectorDetailImage: computed,
			selectorDetailLink: computed,
			selectorDetailsToRender: computed,
			selectedSelector: computed,
			statusToRender: computed,
			materialAPIHref: computed,
		});
	}

	get detailImage() {
		return this.detailsModel.find(({ type = '' }) => type === 'image');
	}

	get detailsToRender() {
		return this.detailsModel.filter(({ type = '' }) => type !== 'image');
	}

	get hasSelectorDetails() {
		return Boolean(this.selectorDetailsModel.length);
	}

	get hasSelectors() {
		return Boolean(this.selectors.length);
	}

	// this is a shim until all selectorValues look like custom
	get image() {
		return { imageSrc: this.imageSrc };
	}

	get imageUrl() {
		const { imageSrc = '' } = this.image;

		return `${s7ImagePath}/${imageSrc}`;
	}

	get preselectedProduct() {
		return this.preselectedProductModel;
	}

	get preselectedProductModel() {
		const [firstProductModel = {}] = this.products;

		const {
			preselectedSelectorValue: {
				preselectedProductModel: childPreslectedProductModel,
			} = {},
		} = this.preselectedSelector || {};

		const productModelsByQuestionAnswers = getProductModelsByQuestionAnswers({
			productModels: this.products,
			questionAnswers: this.questionSelectorsQuestionAnswers,
		});

		const [firstProductModelByQuestionAnswers] = productModelsByQuestionAnswers;

		return childPreslectedProductModel || firstProductModelByQuestionAnswers || firstProductModel;
	}

	get preselectedSelector() {
		return this.selectors.find(({ preselected = false }) => preselected);
	}

	get selectedSelector() {
		return this.selectors.find(({ selected = false }) => selected);
	}

	get selectorDetailImage() {
		return this.selectorDetailsModel.find(({ type = '' }) => type === 'image');
	}

	get selectorDetailLink() {
		return this.selectorDetailsModel.find(({ type = '' }) => type === 'link');
	}

	get selectorDetailsToRender() {
		return this.selectorDetailsModel.filter(({ type = '' }) => type !== 'image');
	}

	get statusToRender() {
		return this.status !== 'COMPONENT' ? this.status : '';
	}

	get materialAPIHref() {
		return this._links?.SWATCH_API_LINK?.href;
	}
}

export const SelectorValueProductModelFactory = ({
	create: ({
		articleNumbers = [],
		detailsModel = [],
		filters = {},
		id = '',
		imageSrc = '',
		parentDisplayType = '',
		parentLabel = '',
		parentRenderer = '',
		parentType = '',
		peerId = '',
		preselected = false,
		products = [],
		questionSelectorsQuestionAnswers = [],
		selected = false,
		selectorDetailsModel = [],
		selectors = [],
		status = '',
		stocked = false,
		subtitle = '',
		title = '',
		_links = {},
	}) => {
		const selectorValueProduct = new SelectorValueProduct();

		Object.assign(selectorValueProduct, {
			articleNumbers,
			detailsModel,
			filters,
			id,
			imageSrc,
			parentDisplayType,
			parentLabel,
			parentRenderer,
			parentType,
			peerId,
			preselected,
			products,
			questionSelectorsQuestionAnswers,
			selected,
			selectorDetailsModel,
			selectors,
			status,
			stocked,
			subtitle,
			title,
			_links,
		});

		return selectorValueProduct;
	},
});
