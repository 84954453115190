export const getProductConfiguration = ({
	productModel: {
		articleNumber = '',
		questionModels = [],
	} = {},
	selectedQuestionSelectorValues = [],
}) => {
	return selectedQuestionSelectorValues.reduce((accumulatedConfiguration = {}, { questionAnswers = {} } = {}) => {
		Object.entries(questionAnswers).forEach(([question = '', answer = '']) => {
			const productQuestion = questionModels.find(({ key = '' }) => key === question);

			if (productQuestion) {
				const { answersModel = [] } = productQuestion;

				const matchingAnswerModel = answersModel.find(({ key = '' }) => key === answer);

				if (matchingAnswerModel) {
					accumulatedConfiguration[question] = answer;
				} else {
					console.warn(`Could not find product answer ${answer} for product question ${question} on article ${articleNumber}`);
				}
			} else {
				console.warn(`Could not find product question ${question} on article ${articleNumber}`);
			}
		});

		return accumulatedConfiguration;
	}, {});
};

export const selectFrameProductQuestionAnswers = (selectedAnswerSelectorValues = []) => {
	selectedAnswerSelectorValues.forEach(({
		products = [],
		questionAnswers = {},
	}) => {
		products.forEach(({
			articleNumber = '',
			questionModels = [],
		}) => {
			Object.entries(questionAnswers).forEach(([selectorValueQuestion, selectorValueAnswer]) => {
				const productQuestion = questionModels.find(({ key: productQuestionKey }) => productQuestionKey === selectorValueQuestion);

				if (productQuestion) {
					let foundMatchingProductAnswerKey = false;

					productQuestion.answersModel.forEach((productAnswer) => {
						const { key: productAnswerKey } = productAnswer;

						if (productAnswerKey === selectorValueAnswer) {
							productAnswer.selected = true;
							foundMatchingProductAnswerKey = true;
						} else {
							productAnswer.selected = false;
						}
					});

					if (!foundMatchingProductAnswerKey) {
						console.warn(`Could not find product answer ${selectorValueAnswer} for product question ${selectorValueQuestion} on article ${articleNumber}`);
					}
				} else {
					console.warn(`Could not find product question ${selectorValueQuestion} on article ${articleNumber}`);
				}
			});
		});
	});
};

export const selectProductMaterialQuestionAnswers = (selectedMaterialSelectorValues = [], allProductModels = [], insertProductModels = []) => {
	selectedMaterialSelectorValues.forEach(({
		answer: selectorValueAnswer = '',
		question: selectorValueQuestion = '',
	}) => {
		let currentGroup;
		let currentAnswer;

		allProductModels.forEach((pModel) => {
			pModel.questionModels.forEach((qModel) => {
				if (qModel.key === selectorValueQuestion) {
					currentGroup = qModel.group;
					qModel.answersModel.forEach((aModel) => {
						aModel.selected = aModel.key === selectorValueAnswer; // SIDE EFFECT: setting answers' selected state
						if (aModel.selected) currentAnswer = aModel;
					});
				}
			});
		});
		if (!currentAnswer) console.warn(`Could not find product answer ${selectorValueAnswer} for frame product question ${selectorValueQuestion} on article ${currentGroup?.articleNumber}`);
		insertProductModels.forEach(({
			articleNumber = '',
			questionModels = [],
		}) => {
			const insertQuestion = questionModels.find(({ mappedFromQuestionGroup = '', key = '' }) => mappedFromQuestionGroup === currentGroup || key === selectorValueQuestion);
			if (insertQuestion) {
				let foundMatchingProductAnswerKey = false;
				insertQuestion.answersModel.forEach((productAnswer) => {
					const { key: productAnswerKey } = productAnswer;
					if (productAnswerKey === selectorValueAnswer) {
						productAnswer.selected = true;
						foundMatchingProductAnswerKey = true;
					} else {
						productAnswer.selected = false;
					}
				});
				if (!foundMatchingProductAnswerKey) {
					console.warn(`Could not find product answer ${selectorValueAnswer} for insert product question ${selectorValueQuestion} on article ${articleNumber}`);
				}
			}
		});
	});
};

export const selectProductOptionsQuestionAnswers = (selectedOptionsSelectorValues = [], productModels = []) => {
	selectedOptionsSelectorValues.forEach(({
		answer: selectorValueAnswer = '',
		question: selectorValueQuestion = '',
	}) => {
		productModels.forEach(({
			articleNumber = '',
			questionModels = [],
		}) => {
			const productQuestion = questionModels.find(({ key: productQuestionKey }) => productQuestionKey === selectorValueQuestion);

			if (productQuestion) {
				let foundMatchingProductAnswerKey = false;

				productQuestion.answersModel.forEach((productAnswer) => {
					const { key: productAnswerKey } = productAnswer;

					if (productAnswerKey === selectorValueAnswer) {
						productAnswer.selected = true;
						foundMatchingProductAnswerKey = true;
					} else {
						productAnswer.selected = false;
					}
				});

				if (!foundMatchingProductAnswerKey) {
					console.warn(`Could not find product answer ${selectorValueAnswer} for frame product question ${selectorValueQuestion} on article ${articleNumber}`);
				}
			}
		});
	});
};
