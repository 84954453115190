import React from 'react';
import { observer } from 'mobx-react';

import { CuralateUploadControls } from '~/util/curalate/Components/CuralateUploadControls';
import { CuralateUploadEditImage } from '~/util/curalate/Components/CuralateUploadEditImage';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

export const CuralateUploadEdit = observer((props) => {
	const {
		store,
		store: {
			selectedFiles,
			toggleFileSelected,
			updateFileCaption,
		},
		magicModal,
	} = props;

	const uploadImages = selectedFiles.map((file, index) => {
		return (
			<CuralateUploadEditImage changeHandler={updateFileCaption} clickHandler={toggleFileSelected} file={file} index={index} key={`curalate-upload-edit-image-${index}`} />
		);
	});

	const noImages = (
		<div className={styles['upload-edit-noImages']}>
			<p className={styles['upload-edit-noImages-text']}>No content selected.<br />Add content to continue.</p>
		</div>
	);

	return (
		<div className={styles['curalate-upload-edit']}>
			<div className={styles['curalate-upload-edit-fieldset-container']}>
				<fieldset className={styles['curalate-upload-edit-fieldset']}>
					<legend className={styles['upload-edit-legend']}>Edit images and captions</legend>
					<div className={styles['upload-edit-fieldset']}>
						{uploadImages.length ? uploadImages : noImages}
					</div>
				</fieldset>
			</div>
			<CuralateUploadControls store={store} magicModal={magicModal} />
		</div>
	);
});
