'use client';

import React, { useEffect } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import classNames from 'classnames';

import { ArrowButtonProps, ButtonLinkProps, ButtonVariant } from '~/components/Buttons/Types/constants';
import { buildButtonClassNames } from '~/components/Buttons/Utils/Button.utils';
import { isCypress } from '~/global/global.constants';

export const NextJsButtonLink = <V extends ButtonVariant>(
	props: ButtonLinkProps<V> & { children?: React.ReactNode } & NextLinkProps,
) => {
	const {
		variant = ButtonVariant.Primary, className: propsClassName = '', color, isTransparent, ...restProps
	} = props;

	let direction;
	let size;
	let className = buildButtonClassNames(variant, color, isTransparent);

	const [hasBeenHydrated, setHasBeenHydrated] = React.useState(false);
	const [useClientSideNavigation, setUseClientSideNavigation] = React.useState(true);

	useEffect(() => {
		setHasBeenHydrated(true);
		if (isCypress) {
			setUseClientSideNavigation(false);
		}
	}, []);

	if (variant === ButtonVariant.Arrow) {
		({ direction, size } = restProps as unknown as ArrowButtonProps);
		className = buildButtonClassNames(variant, color, isTransparent, direction, size);
		if (!direction || !size) {
			throw new Error('Arrow buttons must have direction and size props.');
		}
	}

	if (!useClientSideNavigation) {
		const {
			prefetch,
			...anchorProps
		} = restProps;
		return (
			// @ts-ignore
			<a
				{...anchorProps}
				className={
					classNames(className, {
						[propsClassName]: propsClassName,
					})
				}
				data-is-hydrated={hasBeenHydrated}
			>
				{props.children}
			</a>
		);
	}

	return (
		<NextLink
			prefetch={false}
			{...restProps}
			data-is-hydrated={hasBeenHydrated}
			className={
				classNames(className, {
					[propsClassName]: propsClassName,
				})
			}
		>
			{props.children}
		</NextLink>
	);
};
