import { observer } from 'mobx-react';
import React from 'react';

import { WorkspaceModalInsertOptionsControlCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-insert-options/WorkspaceModalInsertOptionsControlCasegoods';

import styles from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-insert-options/modal-insert-options-controls.module.scss';

export const WorkspaceModalInsertOptionsControlsCasegoods = observer((props = {}) => {
	const {
		insertOptionsSelectors = [],
		insertOptionsSelectorValues = [],
	} = props;

	return (
		<div className={styles['workspace-modal-insert-options-controls']}>
			{
				insertOptionsSelectors.map((selector = {}, index) => {
					const { selectorValues = [] } = selector;

					const selectedSelectorValue = selectorValues.find(selectorValue => insertOptionsSelectorValues.includes(selectorValue));

					return (
						<WorkspaceModalInsertOptionsControlCasegoods
							selectedSelector={selector}
							selectedSelectorValue={selectedSelectorValue}
							key={`selector-group-${index}`}
						/>
					);
				})
			}
		</div>
	);
});
