import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductDraggableSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductDraggableSectionals';
import { WorkspaceProductSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductSectionals';
import { imageInchesToPixelsFactor } from '~/product/sectionals/workspace/workspace-sectionals.constants';

import styles from '~/product/sectionals/workspace/Components/product/workspace-products.module.scss';

interface Props {
	imageSettings: any
	isDraggable: boolean
	isMainWorkspace: boolean
}

export const WorkspaceProductsSectionals = observer(({
	imageSettings: {
		defaultImageScale = 0,
		workspaceHeight: workspaceHeightSetting = 0,
		workspaceWidth: workspaceWidthSetting = 0,
	} = {},
	isDraggable = false,
	isMainWorkspace = false,
}: Props) => {
	const {
		workspaceModel: {
			firstWorkspaceProductModel = {},
			firstWorkspaceProductModel: {
				workspaceProductDepth = 0,
				workspaceProductWidth = 0,
			} = {},
			isDragging = false,
			workspaceProductModels = [],
			workspaceProductsDepth = 0,
			workspaceProductsWidth = 0,
			workspaceProductsXEnd = 0,
			workspaceProductsXStart = 0,
			workspaceProductsYEnd = 0,
			workspaceProductsYStart = 0,
			workspaceProductsChunkModelWithFirstWorkspaceProduct: {
				isDepthWorkspaceProductsChunkModel = false,
				isReversedDirection = false,
				isWidthWorkspaceProductsChunkModel = false,
			} = {},
		} = {},
	} = useProductContext();

	const workspaceRef = useRef<HTMLDivElement>(null);

	const [workspaceDepthState, setWorkspaceDepthState] = useState(0);

	const [workspaceWidthState, setWorkspaceWidthState] = useState(0);

	const workspaceDepthToUse = workspaceDepthState || workspaceHeightSetting;

	const workspaceWidthToUse = workspaceWidthState || workspaceWidthSetting;

	const adaptiveImageScaleByDepth = (imageInchesToPixelsFactor * workspaceProductsDepth) / workspaceDepthToUse;

	const adaptiveImageScaleByWidth = (imageInchesToPixelsFactor * workspaceProductsWidth) / workspaceWidthToUse;

	const adaptiveImageScaleToUse = adaptiveImageScaleByWidth > adaptiveImageScaleByDepth ? adaptiveImageScaleByWidth : adaptiveImageScaleByDepth;

	const imageScaleToUse = adaptiveImageScaleToUse > defaultImageScale ? adaptiveImageScaleToUse : defaultImageScale;

	const workspaceProductsPixelsHeight = (imageInchesToPixelsFactor * workspaceProductsDepth) / imageScaleToUse;

	const workspaceProductsPixelsWidth = (imageInchesToPixelsFactor * workspaceProductsWidth) / imageScaleToUse;

	const workspaceProductDeltaX = workspaceProductWidth / 2;

	const workspaceProductDeltaY = workspaceProductDepth / 2;

	const workspaceProductsDeltaX = isWidthWorkspaceProductsChunkModel && isReversedDirection ? Math.abs((workspaceProductsXStart - workspaceProductsXEnd) / 2) : ((workspaceProductsXEnd + workspaceProductsXStart) / 2);

	const workspaceProductsDeltaY = isDepthWorkspaceProductsChunkModel && isReversedDirection ? Math.abs((workspaceProductsYStart - workspaceProductsYEnd) / 2) : (workspaceProductsYEnd + workspaceProductsYStart) / 2;

	const workspaceDeltaX = isWidthWorkspaceProductsChunkModel && isReversedDirection ? workspaceProductsDeltaX - workspaceProductDeltaX : workspaceProductDeltaX - workspaceProductsDeltaX;

	const workspaceDeltaY = isDepthWorkspaceProductsChunkModel && isReversedDirection ? workspaceProductsDeltaY - workspaceProductDeltaY : workspaceProductDeltaY - workspaceProductsDeltaY;

	const workspacePixelsDeltaX = (imageInchesToPixelsFactor * workspaceDeltaX) / imageScaleToUse;

	const workspacePixelsDeltaY = (imageInchesToPixelsFactor * workspaceDeltaY) / imageScaleToUse;

	const translateXValue = workspacePixelsDeltaX;

	const translateYValue = workspacePixelsDeltaY;

	const additionalTranslateXValue = (isDragging && workspaceProductModels.length > 1) ? -(workspaceProductsPixelsWidth * 0.025) : 0;

	useEffect(() => {
		const {
			clientHeight = 0,
			clientWidth = 0,
		} = workspaceRef.current || {};

		setWorkspaceDepthState(clientHeight);

		setWorkspaceWidthState(clientWidth);
	}, [workspaceRef.current]);

	return (
		<>
			<div
				className={
					classNames(styles['workspace-products-container'], {
						[styles['workspace-products-container-main']]: isMainWorkspace
					})
				}
				data-workspace-products-container={true}
				ref={workspaceRef}
				style={{ height: workspaceProductsPixelsHeight }}
			>
				<div
					className={styles['workspace-products']}
					style={{
						transform: `translateX(${translateXValue + additionalTranslateXValue}px) translateY(${translateYValue}px)`,
						width: workspaceProductsPixelsWidth,
					}}
				>
					{
						isDraggable && isMainWorkspace
							? <WorkspaceProductDraggableSectionals
								imageScale={imageScaleToUse}
								isDraggable={true}
								showDimensions={true}
								workspaceProductModel={firstWorkspaceProductModel}
							/>
							: <WorkspaceProductSectionals
								imageScale={imageScaleToUse}
								isDraggable={false}
								showDimensions={false}
								workspaceProductModel={firstWorkspaceProductModel}
							/>
					}
				</div>
			</div>
			{
				isMainWorkspace && (
					<span className={classNames(styles['workspace-products-message'], 'tw-hidden-for-print')}>
						Image shows your layout but not your material.
					</span>
				)
			}
		</>
	);
});
