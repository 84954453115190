import styles from '#/components/magic-delivery.module.scss';

export const addToCartActionKeys = {
	WISHLIST: 'WISHLIST',
	CART: 'CART',
	FAVORITES: 'FAVORITES',
};

export const addProductsToCartErrorKeys = {
	maxQuantity: 'maxQuantityError',
	unauthorizedWishlist: 'unauthorizedWishlistModificationError',
};

export const summaryDataQaValues = {
	SUMMARY: 'product-summary',
	SUMMARY_ADD_TO_CART: 'product-page-add-to-cart-button',
	SUMMARY_ADD_TO_FAV: 'product-page-add-to-fav-button',
	SUMMARY_PRICE: 'product-summary-price',
	SUMMARY_ORIGINAL_PRICE: 'product-summary-original-price',
	SUMMARY_QUANTITY: 'product-summary-quantity',
	SUMMARY_STORE_QUANTITY: 'product-summary-store-quantity',
	SUMMARY_AVAILABILITY: 'product-summary-availability',
	SUMMARY_LEAD_TIME: 'product-summary-leadTime',
	SUMMARY_MAX_QUANTITY: 'product-summary-max-quantity',
};

export const availabilityMessages = {
	inStock: 'In stock',
	madeForYou: 'Made for you',
	stocked: 'Stocked',
	stockedItem: 'Stocked Item',
	clearance: 'Discontinued',
};

export const availabilityMessageClasses = {
	inStock: styles['delivery-message-large-font'],
	madeForYou: styles['delivery-message-large-font'],
	stocked: '',
	stockedItem: '',
	clearance: '',
};

export const availabilitySchemas = {
	inStock: 'http://schema.org/InStock',
	outOfStock: 'http://schema.org/OutOfStock',
};

export const shippingMethods = {
	IN_HOME: 'INH',
	UPS: 'UPS',
	UPS_OVERSIZE: 'UP2',
	UPS_GROUND: 'UP3',
};

export const summaryTimeout = 350;
