import { computed, observable, makeObservable } from 'mobx';

class SelectorMaterialCombined {
	label

	materialSelectorModels

	renderer

	selectorMaterialCombinedMagicTabccordionStore

	type

	wasActive

	constructor() {
		makeObservable(this, {
			wasActive: observable,
			combinedMaterialSelectorsConfigurationParams: computed,
			combinedMaterialSelectorsLabel: computed,
			combinedMaterialSelectedSelectorValues: computed,
			combinedMaterialSelectedSelectorValuesTitle: computed,
		});
	}

	get combinedMaterialSelectedSelectorConfig() {
		return this.materialSelectorModels.reduce((
			accumulator,
			{ selectedSelectorValue: { answer, question } = {} },
		) => (
			{ ...accumulator, [question]: answer }
		), {});
	}

	get combinedMaterialSelectorsConfigurationParams() {
		return this.combinedMaterialSelectedSelectorValues.map(({ question = '' }) => `question=${question}`).join('&');
	}

	get combinedMaterialSelectorsLabel() {
		return this.materialSelectorModels.map(({ label = '' }) => label).join(', ');
	}

	get combinedMaterialSelectedSelectorValues() {
		return this.materialSelectorModels.map(({ selectedSelectorValue = {} }) => selectedSelectorValue);
	}

	get combinedMaterialSelectedSelectorValuesTitle() {
		return this.combinedMaterialSelectedSelectorValues.map(({ title = '' }) => title).join(', ');
	}
}

export const SelectorMaterialCombinedModelFactory = ({
	create: ({
		label = '',
		materialSelectorModels = [],
		renderer = '',
		selectorMaterialCombinedMagicTabccordionStore = {},
		type = '',
		wasActive = false,
	}) => {
		const selectorMaterialCombined = new SelectorMaterialCombined();

		Object.assign(selectorMaterialCombined, {
			label,
			materialSelectorModels,
			renderer,
			selectorMaterialCombinedMagicTabccordionStore,
			type,
			wasActive,
		});

		return selectorMaterialCombined;
	},
});
