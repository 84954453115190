import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { kebabCase } from '~/util/kebabCase';

import styles from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-insert-options/modal-insert-options-control-vertical-radio.module.scss';

const WorkspaceModalInsertOptionsSelectorValueVerticalRadioCasegoods = observer((props = {}) => {
	const {
		inputName = '',
		selectedSelectorValue = {},
		selectorValue = {},
		selectorValue: {
			title = '',
			upcharge = '',
		},
	} = props;

	const { workspaceModalInsertOptionsStore = {} } = useProductContext();

	function handleChange() {
		workspaceModalInsertOptionsStore.selectSelectorValue(selectorValue);
	}

	return (
		<label
			className={styles['workspace-modal-insert-options-control-vertical-radio-label']}
			data-qa={`${kebabCase(title)}-insert-option-selector-value`}
		>
			<input
				checked={selectedSelectorValue === selectorValue}
				className={styles['workspace-modal-insert-options-control-vertical-radio-input']}
				data-tr-link-event-track={false}
				name={inputName}
				onChange={handleChange}
				type="radio"
			/>
			<span
				className={styles['workspace-modal-insert-options-control-vertical-radio-text']}
				data-qa={`${kebabCase(title)}-insert-option-title`}
			>
				{title} {upcharge}
			</span>
		</label>
	);
});

export const WorkspaceModalInsertOptionsControlVerticalRadioCasegoods = observer((props = {}) => {
	const {
		selectedSelector: {
			selectorValues = [],
			type = '',
		} = {},
		selectedSelectorValue = {},
	} = props;

	return (
		<div className={styles['workspace-modal-insert-options-control-vertical-radio']}>
			<fieldset>
				<legend className="tw-sr-only">
					Option type: {type}
				</legend>
				{
					selectorValues.map((selectorValue = {}, index) => {
						return (
							<WorkspaceModalInsertOptionsSelectorValueVerticalRadioCasegoods
								inputName={type}
								key={`selector-value-${index}`}
								selectedSelectorValue={selectedSelectorValue}
								selectorValue={selectorValue}
							/>
						);
					})
				}
			</fieldset>
		</div>
	);
});
