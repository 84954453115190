import { computed, observable, makeObservable } from 'mobx';

import { formatCurrency } from '~/util/formatCurrency';

class SummaryMultiOptions {
	analytics;

	addToCartAction;

	addToCartResponse;

	addToWishListResponse;

	breadcrumbModels;

	deliveryMessages;

	deliveryRateMessages;

	hasError;

	hasInitialized;

	isAddToCartDone;

	isAddToCartInProcess;

	isLoading;

	pricing;

	stockedArticleNumber;

	reflektionSkuKeys;

	productGroupModel;

	summaryModels;

	variantIds;

	constructor() {
		makeObservable(this, {
			addToCartAction: observable,
			addToCartResponse: observable,
			addToWishListResponse: observable,
			deliveryMessages: observable,
			deliveryRateMessages: observable,
			hasError: observable,
			isAddToCartDone: observable,
			isAddToCartInProcess: observable,
			isLoading: observable,
			pricing: observable,
			stockedArticleNumber: observable,
			reflektionSkuKeys: observable,
			variantIds: observable,
			analytics: observable,
			addProductsToCartRequestDto: computed,
			finalPrice: computed,
			firstSelectedProduct: computed,
			hasSelectedSelectors: computed,
			isUpsDeliveryGroup: computed,
			isValidQuantity: computed,
			isValidSummary: computed,
			priceText: computed,
			productRequestDtos: computed,
			selectedProducts: computed,
			shippingMethod: computed,
			summaryParams: computed,
			validSummaryModels: computed,
		});
	}

	get addProductsToFavoritesRequestDto() {
		const {
			canonicalUrl: friendlyPath,
			key: productGroupKey,
		} = this.productGroupModel;

		return {
			addToFavoritesPayload: {
				friendlyPath,
				productGroupKey,
				products: this.validSummaryModels.map((model) => {
					const {
						quantity = 0,
						selectedProductRequestDto: {
							articleNumber,
						} = {},
						selectorConfigModel: {
							selectedProduct: {
								summaryTitle,
							} = {},
						} = {},
					} = model;

					return {
						articleNumber,
						quantity,
						salesText: summaryTitle,
					};
				}),
				sourceBreadcrumbs: this.sourceBreadcrumbs,
			},
		};
	}

	get addProductsToCartRequestDto() {
		const {
			canonicalUrl: friendlyPath,
			key: productGroupKey,
		} = this.productGroupModel;

		return {
			friendlyPath,
			productGroupKey,
			productRequestDtos: this.productRequestDtos,
			quantity: this.quantity,
		};
	}

	get finalPrice() {
		const { priceRange = '' } = this.productGroupModel;

		if (this.isValidQuantity) {
			return this.pricing;
		}

		return priceRange;
	}

	get firstSelectedProduct() {
		const [firstValidSummaryModel = {}] = this.summaryModels;

		const {
			selectorConfigModel: {
				selectedProduct = {},
			},
		} = firstValidSummaryModel;

		return selectedProduct;
	}

	get hasSelectedSelectors() {
		return this.summaryModels.some(({
			selectorConfigModel: {
				hasUnselectedSelectors = false,
			} = {},
		}) => !hasUnselectedSelectors);
	}

	get isUpsDeliveryGroup() {
		return /^UP(S|\d)$/.test(this.shippingMethod);
	}

	get isValidQuantity() {
		return this.summaryModels.some(({
			quantity = 0,
			selectorConfigModel: {
				hasUnselectedSelectors = false,
			} = {},
		}) => quantity > 0 && !hasUnselectedSelectors);
	}

	get isValidSummary() {
		return !this.isLoading && this.hasSelectedSelectors && this.isValidQuantity;
	}

	get priceText() {
		const { priceRange = '' } = this.productGroupModel;

		if (this.hasError) {
			return 'Call for pricing';
		}

		return this.isValidQuantity ? formatCurrency(this.finalPrice, true) : priceRange;
	}

	get productRequestDtos() {
		return this.validSummaryModels.map(({
			quantity = 0,
			selectedProductRequestDto = {},
		}) => {
			return {
				...selectedProductRequestDto,
				quantity,
			};
		});
	}

	get shippingMethod() {
		const {
			delivery: {
				shippingMethod,
			},
		} = this.firstSelectedProduct;

		return shippingMethod;
	}

	get selectedProducts() {
		return [];
	}

	get sourceBreadcrumbs() {
		if (!this.breadcrumbModels) {
			return {};
		}

		const sourceCrumbs = {};

		this.breadcrumbModels.forEach((crumb) => {
			const { url = '', type = '' } = crumb.data[0];
			if (!url || !type) {
				return;
			}
			const lastIndex = url.lastIndexOf('/');
			const trimmedUrl = url.slice(lastIndex + 1);
			const sourceCrumb = { [type]: trimmedUrl };
			Object.assign(sourceCrumbs, sourceCrumb);
		});
		return sourceCrumbs;
	}

	get summaryParams() {
		return this.validSummaryModels.map(({
			quantity,
			selectedProductRequestDto = {},
		}) => {
			return {
				...selectedProductRequestDto,
				quantity,
				productGroupKey: this.productGroupModel.key,
			};
		});
	}

	get validSummaryModels() {
		return this.summaryModels.filter(({
			quantity,
			selectorConfigModel: {
				hasUnselectedSelectors = false,
			} = {},
		}) => !hasUnselectedSelectors && quantity);
	}
}

export const SummaryMultiOptionsModelFactory = ({
	create: ({
		analytics = [],
		breadcrumbModels = [],
		hasError = false,
		productGroupModel = {},
		reflektionSkuKeys = [],
		summaryModels = [],
		variantIds = [],
	}) => {
		const summary = new SummaryMultiOptions();

		Object.assign(summary, {
			analytics,
			breadcrumbModels,
			hasError,
			productGroupModel,
			reflektionSkuKeys,
			summaryModels,
			variantIds,
		});

		return summary;
	},
});
