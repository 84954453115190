import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/modal-frame/workspace-modal-frame-attribute-control.module.scss';

const WorkspaceModalFrameSelectorValueDoorCasegoods = observer((props) => {
	const {
		selected = false,
		selectorValue = {},
		selectorValue: {
			title = '',
		} = {},
	} = props;

	const { workspaceModalFrameStore = {} } = useProductContext();

	function handleChange() {
		workspaceModalFrameStore.setDoorSelectorValue(selectorValue, true);
	}

	return (
		<label
			className={styles['workspace-modal-frame-attribute-control']}
			data-qa="workspace-modal-frame-door-selector-value"
		>
			<input
				checked={selected}
				className={styles['workspace-modal-frame-attribute-control-input']}
				data-tr-link-event-track={false}
				onChange={handleChange}
				type="radio"
			/>
			<span>{title}</span>
		</label>
	);
});

export const WorkspaceModalFrameControlDoorCasegoods = observer(() => {
	const {
		workspaceModalFrameModel: {
			doorSelectorValue = {},
			doorSelectorValues = [],
		} = {},
	} = useProductContext();

	return (
		<>
			<span className={styles['workspace-modal-frame-attribute-label']}>Door: </span>
			<fieldset>
				<legend className="tw-sr-only">Choose a door configuration</legend>
				{
					doorSelectorValues.map((selectorValue = {}, index) => {
						const selected = doorSelectorValue === selectorValue;

						return (
							<WorkspaceModalFrameSelectorValueDoorCasegoods
								key={`door-selector-value-${index}`}
								selected={selected}
								selectorValue={selectorValue}
							/>
						);
					})
				}
			</fieldset>
		</>
	);
});
