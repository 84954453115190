class ProductsStore {
	productGroupModel

	productImagesData

	productsModel

	selectorConfigModel
}

export const ProductsStoreFactory = ({
	create: ({
		productGroupModel = {},
		selectorConfigModel = {},
		selectorConfigModel: {
			productsModel = [],
		} = {},
	}) => {
		const productsStore = new ProductsStore();

		Object.assign(productsStore, {
			productGroupModel,
			productsModel,
			selectorConfigModel,
		});

		return productsStore;
	},
});
