import axios from 'axios';
import { action, makeObservable } from 'mobx';

import { apiUrl } from '~/global/global.constants';

export class WorkspaceProductCasegoodsStore {
	productGroupModel;

	workspaceProductModel;

	constructor() {
		makeObservable(this, { getWorkspaceProductImagesData: action });
	}

	getWorkspaceProductImagesData() {
		const {
			id = '',
			_links: {
				images: {
					href = `/api/web/product-group/${id}/image`,
				} = {},
			} = {},
		} = this.productGroupModel;

		const { workspaceProductQuestionModels = [] } = this.workspaceProductModel;

		const url = `${apiUrl}${href}`;

		const questionParam = workspaceProductQuestionModels.map(({
			group = '',
			selectedAnswer: {
				key = '',
			} = {},
		}) => `${group}:${key}`).filter(Boolean);

		const params = { question: questionParam };

		return axios.request({
			url,
			method: 'get',
			params,
			paramsSerializer: (paramsToSerialize) => {
				return Object.entries(paramsToSerialize).map(([key, value]) => {
					if (Array.isArray(value)) {
						return value.map((subValue) => {
							return `${key}=${encodeURIComponent(subValue)}`;
						}).join('&');
					}

					return `${key}=${encodeURIComponent(value)}`;
				}).join('&');
			},
		})
			.then(({ data = {} }) => {
				this.workspaceProductModel.workspaceProductImageData = data;
			})
			.catch((error) => {
				console.info(error);
			});
	}
}

export const WorkspaceProductCasegoodsStoreFactory = ({
	create: ({
		productGroupModel = {},
		workspaceProductModel = {},
	}) => {
		const workspaceProductCasegoodsStore = new WorkspaceProductCasegoodsStore();

		Object.assign(workspaceProductCasegoodsStore, {
			productGroupModel,
			workspaceProductModel,
		});

		return workspaceProductCasegoodsStore;
	},
});
