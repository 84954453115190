import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { s7ContentPath } from '~/global/global.constants';
import { MediaControl } from '~/media-set/Components/MediaControl';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';

import styles from '#/product/common/media-set/media-control-cylindo-ar.module.scss';

export const MediaControlCylindoAR = observer((props = {}) => {
	const {
		buttonRef = {},
		mediaCylindoModel: {
			hasCylindo = '',
			isCylindoARActive = false,
		} = {},
		mediaCylindoStore = {},
		mediaSetStore = {},
	} = props;

	const [isLoadingState, setIsLoadingState] = useState(false);

	if (!hasCylindo) {
		return null;
	}

	function handleClick(event) {
		event.stopPropagation();

		if (isCylindoARActive) {
			mediaCylindoStore.setIsCylindoActive(false);

			mediaCylindoStore.setIsCylindoARActive(false);

			mediaCylindoStore.setIsCylindoDimensionsActive(false);

			mediaSetStore.setIsMediaSetOverrideActive(false);
		} else {
			mediaCylindoStore.getCylindoData()
				.then(() => {
					if (window.matchMedia('(max-width: 40em)').matches) {
						setIsLoadingState(true);

						mediaCylindoStore.setIsCylindoActive(false);

						mediaCylindoStore.setIsCylindoARActive(true);

						mediaCylindoStore.setIsCylindoDimensionsActive(false);

						setTimeout(() => {
							setIsLoadingState(false);
						}, 5000);
					} else {
						runInAction(() => {
							mediaCylindoStore.setIsCylindoActive(false);

							mediaCylindoStore.setIsCylindoARActive(true);

							mediaCylindoStore.setIsCylindoDimensionsActive(false);

							mediaSetStore.setIsMediaSetOverrideActive(true);
						});
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}

	return (
		<MediaControl>
			<button
				aria-current={isCylindoARActive}
				className={
					classNames(styles['media-control-cylindo-ar'], {
						[styles['media-control-cylindo-ar-active']]: isCylindoARActive,
					})
				}
				data-event122
				data-tr-link-event-name="cylindo ar view"
				data-tr-link-event-track={isCylindoARActive ? false : null}
				data-tr-link-event-type={LinkEventTypes.SITE_ACTION}
				data-qa="media-control-cylindo-ar"
				onClick={handleClick}
				ref={buttonRef}
			>
				<div className={styles['media-control-cylindo-ar-image-container']}>
					<img
						alt=""
						className={styles['media-control-cylindo-ar-image']}
						src={`${s7ContentPath}/AR_button`}
					/>
				</div>
				<span className="tw-sr-only">
					View AR Media
				</span>
			</button>
			<div className="md:tw-hidden">
				<LoadingSpinner isLoading={isLoadingState} />
			</div>
		</MediaControl>
	);
});
