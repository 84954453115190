class BlurbModel {
	data;

	constructor(data) {
		this.data = data || {};
	}

	get _links() {
		return this.data._links;
	}

	get id() {
		return this.data.id;
	}

	get key() {
		return this.data.key;
	}

	get type() {
		return this.data.type;
	}

	get name() {
		return this.data.name;
	}

	get verbiageHTML() {
		return this.data.text;
	}

	get position() {
		return this.data.position;
	}

	get imageFilename() {
		return this.data.imageFilename;
	}

	get imageOrientation() {
		return this.data.imageOrientation;
	}

	get linkAltText() {
		return this.data.linkAltText;
	}

	get linkHref() {
		return this.data.link;
	}

	get linkTitle() {
		return this.data.linkTitle;
	}

	get linkifiedVerbiageHTML() {
		const blurbTextPdf = this.verbiageHTML.replace(/(<a.*[^>]href[ ]*=[ ]*[\\"'])((?!.*\/\/.*).*\.pdf.*)([\\"'])/gi, '$1${Application.pdfPath}/$2$3');

		const blurbTextModal = blurbTextPdf.replace(/(<a.*[^>]href[ ]*=[ ]*[\\"'])(.*?[^>]automodal=true.*?)([\\"'])(.*?>)/gi, '$1$2$3 class=\"js-open-modal\"$4');

		const blurbTextPdfSite = blurbTextModal.replace(/(<a.*[^>]href[ ]*=[ ]*[\\"'])((?!.*\/\/.*|.*\.pdf.*).*)([\\"'])/gi, '$1${Application.sitePath}/$2$3');

		return blurbTextPdfSite;
	}

	get text() {
		return this.data.text;
	}

	get vendor() {
		return this.data.vendor;
	}
}

export { BlurbModel };
