import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { kebabCase } from '~/util/kebabCase';

import styles from '~/product/casegoods/selector/Components/radio/selector-value-radio.module.scss';

export const SelectorValueRadio = observer((props = {}) => {
	const {
		label = '',
		selectorValue = {},
		selectorValue: {
			overrideQuestionAnswers = {},
			selected = false,
			showOverrideWarning = false,
			title = '',
			upcharge = '',
		} = {},
	} = props;

	const {
		selectorConfigStore = {},
		workspaceStore = {},
	} = useProductContext();

	function handleChange() {
		runInAction(() => {
			workspaceStore.overrideInsertOptionsSelectorValues(overrideQuestionAnswers);

			selectorConfigStore.selectOptionsSelectorValue(selectorValue);
		});
	}

	return (
		<>
			<label
				className={styles['selector-value-radio']}
				data-qa={`${kebabCase(label)}-selector-value`}
			>
				<input
					checked={selected}
					className={styles['selector-value-radio-input']}
					data-tr-link-event-track={false}
					name={label}
					onChange={handleChange}
					type="radio"
				/>
				<span
					className={styles['selector-values-radio-text']}
					data-qa={`${kebabCase(label)}-selector-value-title`}
				>
					{title} {upcharge}
					{
						showOverrideWarning && (
							<span className={styles['selector-value-radio-warning']}>
								If selected, any cord openings that were added will be removed.
							</span>
						)
					}
				</span>
			</label>
		</>
	);
});
