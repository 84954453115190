import classNames from 'classnames';
import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { s7ImagePath } from '~/global/global.constants';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { addToUrl } from '~/util/addToUrl';
import { kebabCase } from '~/util/kebabCase';

import styles from '~/product/sectionals/selector/Components/medium-image/selector-medium-image.module.scss';

const WorkspaceModalStartOverSectionals = dynamic(
	() => import('~/product/sectionals/workspace/Components/modal-start-over/WorkspaceModalStartOverSectionals').then(module => module.WorkspaceModalStartOverSectionals),
);

export const SelectorValueMediumImage = observer((props) => {
	const {
		isActive = false,
		isAggregatedQuestionSelector = false,
		label = '',
		selectorValue,
		selectorValue: {
			image: {
				imageSrc = '',
			},
			questionAnswers,
			selected,
			title = '',
		},
	} = props;

	const { magicModal = {} } = useGlobalContext();

	const {
		mediaSetDetailStore = {},
		selectorConfigStore = {},
		workspaceModel: {
			hasWorkspaceProductModels = false,
			workspaceProductModels = [],
		} = {},
	} = useProductContext();

	function handleChange() {
		if (questionAnswers || !hasWorkspaceProductModels) {
			mediaSetDetailStore.selectMediaModelHead();

			selectorConfigStore.selectSelectorValue(selectorValue, isAggregatedQuestionSelector, workspaceProductModels);
		} else {
			magicModal.openModal({
				content: {
					children: (
						<WorkspaceModalStartOverSectionals selectorValue={selectorValue} />
					),
				},
				id: 'workspace-help-maximum-modal',
				maxWidth: '325px',
				title: 'Start Over',
			});
		}
	}

	return (
		<label
			className={
				classNames([styles['selector-value-medium-image']], {
					[styles['selector-value-medium-image-selected']]: selected,
				})
			}
			data-qa={`${kebabCase(label)}-selector-value`}
		>
			<S7Image
				alt=""
				className={styles['selector-value-medium-image-image']}
				height="70"
				priority={isActive}
				src={addToUrl(`${s7ImagePath}/${imageSrc}`, '$proddd$')}
				width="105"
			/>
			<div className={styles['selector-value-medium-image-text']}>
				<span
					className={styles['selector-value-medium-image-title']}
					data-qa={`${kebabCase(label)}-selector-value-title`}
				>
					{title}
				</span>
			</div>
			<input
				checked={selected}
				className="tw-sr-only"
				data-tr-link-event-track={false}
				onChange={handleChange}
				type="radio"
			/>
		</label>
	);
});
