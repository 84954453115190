import { action, autorun, makeObservable } from 'mobx';

import type { ISelectorConfigSectionals } from '~/product/sectionals/selector/Interfaces/SelectorConfigSectionals.interface';
import type { IWorkspaceProductJoinableSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductJoinableSectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';
import type { IWorkspaceSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceSectionals.interface';

import { isOnServer } from '~/global/global.constants';
import { createWorkspaceProductSectionalsModel } from '~/product/sectionals/workspace/Helpers/WorkspaceProductSectionals.init';
import { getFlattenedArticleNumbers, getValidWorkspaceProductOrientations, updateWorkspaceProductJoinableModels } from '~/product/sectionals/workspace/Utils/WorkspaceSectionals.utils';
import { orientationByOrientationChangeMapByOrientation, orientationChangeByOrientationMapByOrientation } from '~/product/sectionals/workspace/workspace-sectionals.constants';

class WorkspaceSectionalsStore {
	selectorConfigModel: ISelectorConfigSectionals;

	selectorConfigStore: any;

	workspaceModel: IWorkspaceSectionals;

	constructor({
		selectorConfigModel = {},
		selectorConfigStore = {},
		workspaceModel = {},
	}: any) {
		makeObservable(this, {
			addFirstWorkspaceProductModel: action,
			addFirstWorkspaceProductModelFromWorkspaceData: action,
			addWorkspaceProductModel: action,
			addWorkspaceProductModelFromWorkspaceData: action,
			removeAllWorkspaceProductModels: action,
			removeWorkspaceProductModel: action,
			setDragOrientation: action,
			setDragPreviewHeight: action,
			setDragPreviewWidth: action,
			setIsDragging: action,
			setIsDraggingWorkspaceProductModel: action,
			setShowInvalidConfigurationMessage: action,
			setShowMinimumSpacingMessage: action,
			updateFirstWorkspaceProductModel: action,
			updateWorkspaceProductModelsOrientations: action,
		});

		this.selectorConfigModel = selectorConfigModel;
		this.selectorConfigStore = selectorConfigStore;
		this.workspaceModel = workspaceModel;
	}

	addFirstWorkspaceProductModel({
		orientation = '',
		productSelectorValue = {},
	}: any): IWorkspaceProductSectionals {
		const { lastWorkspaceProductIndex = 0 } = this.workspaceModel;

		const workspaceProductModel = createWorkspaceProductSectionalsModel({
			orientation,
			productSelectorValue,
			workspaceProductIndex: lastWorkspaceProductIndex + 1,
		});

		this.workspaceModel.firstWorkspaceProductModel = workspaceProductModel;

		return workspaceProductModel;
	}

	addFirstWorkspaceProductModelFromWorkspaceData({
		workspaceProduct = {},
		workspaceProduct: {
			joinables = [],
			orientation = '',
		} = {},
	}: any): void {
		const { productSelectorValues = [] } = this.selectorConfigModel;

		const workspaceArticleNumbers = getFlattenedArticleNumbers(workspaceProduct);

		const workspaceProductSelectorValues = workspaceArticleNumbers.map((articleNumber = '') => {
			return productSelectorValues.find(({ articleNumbers = [] }: any) => {
				return articleNumbers.includes(articleNumber);
			});
		});

		const [firstProductSelectorValue = {}] = workspaceProductSelectorValues;

		Promise.all(workspaceProductSelectorValues.map(productSelectorValue => this.selectorConfigStore.getProductDataBySelectorValue(productSelectorValue)))
			.then(() => {
				this.addFirstWorkspaceProductModel({
					orientation,
					productSelectorValue: firstProductSelectorValue,
				});

				this.selectorConfigStore.setWorkspaceProductModels(this.workspaceModel.workspaceProductModels);

				const { firstWorkspaceProductModel } = this.workspaceModel;

				const { workspaceProductJoinableModels = [] } = firstWorkspaceProductModel || {};

				joinables.forEach(({
					joinableSide: joinableSideData = '',
					workspaceProduct: nextWorkspaceProduct = {},
				}) => {
					const workspaceProductJoinableModel = workspaceProductJoinableModels.find(({ joinableSide = '' }) => joinableSide === joinableSideData);

					this.addWorkspaceProductModelFromWorkspaceData({
						workspaceProduct: nextWorkspaceProduct,
						workspaceProductJoinableModel,
						workspaceProductModel: firstWorkspaceProductModel,
					});
				});
			})
			.catch((error: any) => {
				console.error(error);
			});
	}

	addWorkspaceProductModel({
		nextOrientation = '',
		nextWorkspaceProductJoinableModel,
		orientation = '',
		productSelectorValue = {},
		workspaceProductJoinableModel = {},
		workspaceProductJoinableModel: {
			nextWorkspaceProductModel = null,
			opposingJoinableSideOrientation = '',
		} = {},
		workspaceProductModel: prevWorkspaceProductModel = {},
	}: any): IWorkspaceProductSectionals {
		const { lastWorkspaceProductIndex = 0 } = this.workspaceModel;

		const { orientation: originalNextOrientation = '' } = nextWorkspaceProductModel || {};

		const orientationChangeByOrientationMap = orientationChangeByOrientationMapByOrientation[originalNextOrientation as keyof typeof orientationChangeByOrientationMapByOrientation] || {};

		const orientationChange = orientationChangeByOrientationMap[nextOrientation as keyof typeof orientationChangeByOrientationMap] || 0;

		const newWorkspaceProductModel = createWorkspaceProductSectionalsModel({
			orientation,
			productSelectorValue,
			workspaceProductIndex: lastWorkspaceProductIndex + 1,
		});

		const { workspaceProductJoinableModelsByOrientation = {} } = newWorkspaceProductModel;

		// update the nextWorkspaceProductModel orientations before unboxing nextOpposingJoinableSideOrientation
		if (nextWorkspaceProductModel) {
			this.updateWorkspaceProductModelsOrientations({
				orientationChange,
				workspaceProductModel: nextWorkspaceProductModel,
			});
		}

		const { opposingJoinableSideOrientation: nextOpposingJoinableSideOrientation = '' } = nextWorkspaceProductJoinableModel || {};

		const newWorkspaceProductJoinableModel = workspaceProductJoinableModelsByOrientation[opposingJoinableSideOrientation as keyof typeof workspaceProductJoinableModelsByOrientation];

		const newNextWorkspaceProductJoinableModel = workspaceProductJoinableModelsByOrientation[nextOpposingJoinableSideOrientation as keyof typeof workspaceProductJoinableModelsByOrientation];

		workspaceProductJoinableModel.nextWorkspaceProductJoinableModel = newWorkspaceProductJoinableModel;
		workspaceProductJoinableModel.nextWorkspaceProductModel = newWorkspaceProductModel;

		if (newWorkspaceProductJoinableModel) {
			newWorkspaceProductJoinableModel.prevWorkspaceProductJoinableModel = workspaceProductJoinableModel;
			newWorkspaceProductJoinableModel.prevWorkspaceProductModel = prevWorkspaceProductModel;
		}

		if (newNextWorkspaceProductJoinableModel) {
			newNextWorkspaceProductJoinableModel.nextWorkspaceProductJoinableModel = nextWorkspaceProductJoinableModel;
			newNextWorkspaceProductJoinableModel.nextWorkspaceProductModel = nextWorkspaceProductModel;

			nextWorkspaceProductJoinableModel.prevWorkspaceProductJoinableModel = newNextWorkspaceProductJoinableModel;
			nextWorkspaceProductJoinableModel.prevWorkspaceProductModel = newWorkspaceProductModel;
		}

		this.updateFirstWorkspaceProductModel();

		this.workspaceModel.lastWorkspaceProductModel = newWorkspaceProductModel;

		return newWorkspaceProductModel;
	}

	addWorkspaceProductModelFromWorkspaceData({
		workspaceProduct: {
			articleNumber = '',
			joinables = [],
			orientation = '',
		} = {},
		workspaceProductJoinableModel,
		workspaceProductModel,
	}: any): void {
		const { productSelectorValues = [] } = this.selectorConfigModel;

		const productSelectorValue = productSelectorValues.find(({ articleNumbers = [] }: any) => {
			return articleNumbers.includes(articleNumber);
		});

		this.selectorConfigStore.getProductDataBySelectorValue(productSelectorValue)
			.then(() => {
				const newWorkspaceProductModel = this.addWorkspaceProductModel({
					orientation,
					productSelectorValue,
					workspaceProductJoinableModel,
					workspaceProductModel,
				});

				const { workspaceProductJoinableModels = [] } = newWorkspaceProductModel;

				joinables.forEach(({
					joinableSide: joinableSideData = '',
					workspaceProduct = {},
				}) => {
					const newWorkspaceProductJoinableModel = workspaceProductJoinableModels.find(({ joinableSide = '' }) => joinableSide === joinableSideData);

					this.addWorkspaceProductModelFromWorkspaceData({
						workspaceProduct,
						workspaceProductJoinableModel: newWorkspaceProductJoinableModel,
						workspaceProductModel: newWorkspaceProductModel,
					});
				});

				this.selectorConfigStore.setWorkspaceProductModels(this.workspaceModel.workspaceProductModels);
			})
			.catch((error: any) => {
				console.error(error);
			});
	}

	removeAllWorkspaceProductModels(): void {
		this.workspaceModel.firstWorkspaceProductModel = null;
	}

	removeWorkspaceProductModel(workspaceProductModelToRemove: IWorkspaceProductSectionals): void {
		const {
			workspaceProductJoinableModelsWithNextWorkspaceProductModel = [],
			workspaceProductJoinableModelsWithPrevWorkspaceProductModel = [],
		}: IWorkspaceProductSectionals = workspaceProductModelToRemove;

		const [firstWorkspaceProductJoinableModelWithWorkspaceProductModel] = workspaceProductJoinableModelsWithNextWorkspaceProductModel;

		const {
			nextWorkspaceProductJoinableModel = null,
			nextWorkspaceProductModel = null,
		} = firstWorkspaceProductJoinableModelWithWorkspaceProductModel || {};

		workspaceProductJoinableModelsWithPrevWorkspaceProductModel.forEach(({
			prevWorkspaceProductJoinableModel = null,
			prevWorkspaceProductModel = null,
		}: IWorkspaceProductJoinableSectionals) => {
			const { opposingJoinableSideOrientation = '' } = prevWorkspaceProductJoinableModel || {};

			const { productSelectorValue } = nextWorkspaceProductModel || {};

			const workspaceProductOrientations = productSelectorValue
				? getValidWorkspaceProductOrientations({
					productSelectorValue,
					workspaceProductJoinableModel: prevWorkspaceProductJoinableModel,
					workspaceProductModel: prevWorkspaceProductModel,
				})
				: [];

			const [firstWorkspaceProductOrientation = {}] = workspaceProductOrientations;

			const { orientation: nextOrientation = 'N' } = firstWorkspaceProductOrientation;

			const { orientation: originalNextOrientation = '' } = nextWorkspaceProductModel || {};

			const orientationChangeByOrientationMap = orientationChangeByOrientationMapByOrientation[originalNextOrientation as keyof typeof orientationChangeByOrientationMapByOrientation] || {};

			const orientationChange = orientationChangeByOrientationMap[nextOrientation as keyof typeof orientationChangeByOrientationMap] || 0;

			if (prevWorkspaceProductJoinableModel) {
				if (nextWorkspaceProductModel) {
					// update the nextWorkspaceProductModel orientations before unboxing nextOpposingJoinableSideOrientation
					this.updateWorkspaceProductModelsOrientations({
						orientationChange,
						workspaceProductModel: nextWorkspaceProductModel,
					});

					const { workspaceProductJoinableModelsByOrientation = {} } = nextWorkspaceProductModel;

					const newNextWorkspaceProductJoinableModel = workspaceProductJoinableModelsByOrientation[opposingJoinableSideOrientation as keyof typeof workspaceProductJoinableModelsByOrientation];

					if (nextWorkspaceProductJoinableModel) {
						prevWorkspaceProductJoinableModel.nextWorkspaceProductJoinableModel = nextWorkspaceProductJoinableModel;
						prevWorkspaceProductJoinableModel.nextWorkspaceProductModel = nextWorkspaceProductModel;
					}

					if (newNextWorkspaceProductJoinableModel) {
						newNextWorkspaceProductJoinableModel.prevWorkspaceProductJoinableModel = prevWorkspaceProductJoinableModel;
						newNextWorkspaceProductJoinableModel.prevWorkspaceProductModel = prevWorkspaceProductModel;
					}
				} else {
					prevWorkspaceProductJoinableModel.nextWorkspaceProductJoinableModel = null;
					prevWorkspaceProductJoinableModel.nextWorkspaceProductModel = null;
				}
			}
		});

		if (!workspaceProductJoinableModelsWithPrevWorkspaceProductModel.length) {
			if (!workspaceProductJoinableModelsWithNextWorkspaceProductModel.length) {
				this.removeAllWorkspaceProductModels();
			} else {
				workspaceProductJoinableModelsWithNextWorkspaceProductModel.forEach(({
					nextWorkspaceProductJoinableModel: newNextWorkspaceProductJoinableModel = null,
					nextWorkspaceProductModel: newNextWorkspaceProductModel = null,
				}: IWorkspaceProductJoinableSectionals) => {
					if (newNextWorkspaceProductModel === nextWorkspaceProductModel) {
						if (newNextWorkspaceProductJoinableModel) {
							newNextWorkspaceProductJoinableModel.prevWorkspaceProductJoinableModel = null;
							newNextWorkspaceProductJoinableModel.prevWorkspaceProductModel = null;
						}
					} else {
						const { productSelectorValue } = newNextWorkspaceProductModel || {};

						const workspaceProductOrientations = productSelectorValue
							? getValidWorkspaceProductOrientations({
								productSelectorValue,
								workspaceProductJoinableModel: nextWorkspaceProductJoinableModel,
								workspaceProductModel: nextWorkspaceProductModel,
							})
							: [];

						const [firstWorkspaceProductOrientation = {}] = workspaceProductOrientations;

						const { orientation: nextOrientation = 'N' } = firstWorkspaceProductOrientation;

						const { orientation: originalNextOrientation = '' } = newNextWorkspaceProductModel || {};

						const orientationChangeByOrientationMap = orientationChangeByOrientationMapByOrientation[originalNextOrientation as keyof typeof orientationChangeByOrientationMapByOrientation] || {};

						const orientationChange = orientationChangeByOrientationMap[nextOrientation as keyof typeof orientationChangeByOrientationMap] || 0;

						this.updateWorkspaceProductModelsOrientations({
							orientationChange,
							workspaceProductModel: newNextWorkspaceProductModel,
						});

						if (nextWorkspaceProductJoinableModel) {
							nextWorkspaceProductJoinableModel.prevWorkspaceProductJoinableModel = newNextWorkspaceProductJoinableModel;
							nextWorkspaceProductJoinableModel.prevWorkspaceProductModel = newNextWorkspaceProductModel;
						}

						if (newNextWorkspaceProductJoinableModel) {
							newNextWorkspaceProductJoinableModel.nextWorkspaceProductJoinableModel = nextWorkspaceProductJoinableModel;
							newNextWorkspaceProductJoinableModel.nextWorkspaceProductModel = nextWorkspaceProductModel;
							newNextWorkspaceProductJoinableModel.prevWorkspaceProductJoinableModel = null;
							newNextWorkspaceProductJoinableModel.prevWorkspaceProductModel = null;
						}
					}

					this.workspaceModel.firstWorkspaceProductModel = newNextWorkspaceProductModel;
				});
			}
		}

		this.updateFirstWorkspaceProductModel();
	}

	setDragOrientation(dragOrientation: string): void {
		this.workspaceModel.dragOrientation = dragOrientation;
	}

	setDragPreviewHeight(height: number): void {
		this.workspaceModel.dragPreviewHeight = height;
	}

	setDragPreviewWidth(width: number): void {
		this.workspaceModel.dragPreviewWidth = width;
	}

	setIsDragging(isDragging: boolean): void {
		this.workspaceModel.isDragging = isDragging;
	}

	setIsDraggingWorkspaceProductModel(workspaceProductModel: IWorkspaceProductSectionals | null): void {
		this.workspaceModel.isDraggingWorkspaceProductModel = workspaceProductModel;
	}

	setShowInvalidConfigurationMessage(showInvalidConfigurationMessage: boolean): void {
		this.workspaceModel.showInvalidConfigurationMessage = showInvalidConfigurationMessage;
	}

	setShowMinimumSpacingMessage(showMinimumSpacingMessage: boolean): void {
		this.workspaceModel.showMinimumSpacingMessage = showMinimumSpacingMessage;
	}

	updateFirstWorkspaceProductModel(): void {
		const {
			firstWorkspaceProductModel = null,
			workspaceProductsChunkModelWithFirstWorkspaceProduct,
		} = this.workspaceModel;

		const { workspaceProductsChunkOrientation = 'N' } = workspaceProductsChunkModelWithFirstWorkspaceProduct || {};

		if (firstWorkspaceProductModel) {
			updateWorkspaceProductJoinableModels(firstWorkspaceProductModel, workspaceProductsChunkOrientation);
		}

		const { firstWorkspaceProductModelInChunk } = workspaceProductsChunkModelWithFirstWorkspaceProduct || {};

		this.workspaceModel.firstWorkspaceProductModel = firstWorkspaceProductModelInChunk;
	}

	updateWorkspaceProductModelsOrientations({
		orientationChange = 0,
		workspaceProductModel = {},
		workspaceProductModel: {
			orientation = '',
			workspaceProductJoinableModels = [],
			workspaceProductJoinableModelsWithNextWorkspaceProductModel = [],
		} = {},
	}: any): void {
		workspaceProductJoinableModelsWithNextWorkspaceProductModel.forEach(({ nextWorkspaceProductModel }: IWorkspaceProductJoinableSectionals) => {
			this.updateWorkspaceProductModelsOrientations({
				orientationChange,
				workspaceProductModel: nextWorkspaceProductModel,
			});
		});

		const orientationByOrientationChangeMap = orientationByOrientationChangeMapByOrientation[orientation as keyof typeof orientationByOrientationChangeMapByOrientation] || {};

		const newOrientation = orientationByOrientationChangeMap[orientationChange as keyof typeof orientationByOrientationChangeMap] || 'N';

		workspaceProductJoinableModels.forEach((workspaceProductJoinableModel: IWorkspaceProductJoinableSectionals) => {
			workspaceProductJoinableModel.parentOrientation = newOrientation;
		});

		workspaceProductModel.orientation = newOrientation;
	}
}

export const WorkspaceSectionalsStoreFactory = ({
	create: ({
		isPreconfiguredWorkspace = false,
		selectorConfigModel = {},
		selectorConfigModel: {
			downloadSelectorModel = {},
			ottomanSelectorModel = {},
			questionSelectorModels = [],
		} = {},
		selectorConfigStore = {},
		workspaceData = {},
		workspaceModel = {},
	}: any = {}) => {
		const workspaceStore = new WorkspaceSectionalsStore({
			selectorConfigModel,
			selectorConfigStore,
			workspaceModel,
		});

		autorun(() => {
			questionSelectorModels.forEach((questionSelectorModel: any) => {
				Object.assign(questionSelectorModel, { isValid: workspaceModel.hasWorkspaceProductModels });
			});

			Object.assign(downloadSelectorModel, { isValid: workspaceModel.hasWorkspaceProductModels });
			Object.assign(ottomanSelectorModel, { isValid: workspaceModel.hasWorkspaceProductModels });
		});

		if (!isOnServer && isPreconfiguredWorkspace) {
			workspaceStore.addFirstWorkspaceProductModelFromWorkspaceData(workspaceData);
		}

		return workspaceStore;
	},
});
