import { computed, observable, makeObservable } from 'mobx';

export class WorkspaceSlotModel {
	allow

	answeredBySlot

	answerForSlots

	columnIndex

	id

	insertOptionsSelectorValues

	detailsAttributesValues

	insertProductModel

	insertProps

	isMaxColumn

	isMaxRow

	questions

	rowIndex

	constructor() {
		makeObservable(this, {
			answeredBySlot: observable,
			insertOptionsSelectorValues: observable.ref,
			detailsAttributesValues: observable,
			insertProductModel: observable,
			insertProps: observable,
			configuration: computed,
			detailsAttributesToRender: computed,
			detailsDimensionsToRender: computed,
			hasInsertOptionsSelectors: computed,
			hasInsertOptionsSelectorsToRender: computed,
			hasInsertProductModel: computed,
			hasNonDefaultInsertOptionsSelectorValues: computed,
			insertOptionsConfiguration: computed,
			insertOptionsSelectors: computed,
			insertOptionsSelectorsToRender: computed,
			insertOptionsSelectorsWithSelectedSelectorValue: computed,
			isValid: computed,
			nonDefaultInsertOptionsSelectorValues: computed,
			rowId: computed,
		});
	}

	get configuration() {
		const { id: answeredBySlotId = '' } = this.answeredBySlot || {};

		if (answeredBySlotId) {
			const { INSERT = '' } = this.questions;

			if (INSERT) {
				return { [INSERT]: `UB${answeredBySlotId}` };
			}
		}

		const { questionModels = [] } = this.insertProductModel || {};

		const configuration = Object.entries(this.questions).reduce((accumulatedConfiguration, [key, value]) => {
			const matchingQuestionModel = questionModels.find(({ group = '' }) => group === key) || {};

			const { selectedAnswerKey = '' } = matchingQuestionModel;

			if (selectedAnswerKey) {
				accumulatedConfiguration[value] = selectedAnswerKey;
			}

			return accumulatedConfiguration;
		}, {});

		return {
			...configuration,
			...this.insertOptionsConfiguration,
		};
	}

	get detailsAttributesToRender() {
		return this.detailsAttributesValues || this.insertProductModel?.detailsAttributesModel;
	}

	get detailsDimensionsToRender() {
		const {
			detailsAttributesModel = [],
			insertDynamicDetails = {},
			title = '',
		} = this.insertProductModel;

		const detailInsertTitle = {
			displayText: title,
			label: 'Insert',
			type: 'nameValue',
		};

		const matchingInsertDynamicDetails = this.insertOptionsSelectorValues.map(({ answer = '' }) => {
			const matchingInsertDynamicDetail = insertDynamicDetails[answer];

			if (matchingInsertDynamicDetail) {
				return {
					...matchingInsertDynamicDetail,
					type: 'nameValue',
				};
			}

			return null;
		}).filter(Boolean);

		const isPluralInsertOptions = Boolean(this.insertOptionsSelectorsToRender.length > 1);

		const detailInsertOptions = this.hasInsertOptionsSelectorsToRender && {
			insertOptionsSelectors: this.insertOptionsSelectorsToRender,
			label: `Feature${isPluralInsertOptions ? 's' : ''}`,
			type: 'casegoodsInsertOptionsQuestionAnswer',
		};

		return [
			detailInsertTitle,
			...(matchingInsertDynamicDetails.length ? matchingInsertDynamicDetails : detailsAttributesModel),
			...(detailInsertOptions ? [detailInsertOptions] : []),
		];
	}

	get hasInsertOptionsSelectors() {
		return Boolean(this.insertOptionsSelectors.length);
	}

	get hasInsertOptionsSelectorsToRender() {
		return Boolean(this.insertOptionsSelectorsToRender.length);
	}

	get hasInsertProductModel() {
		return Boolean(this.insertProductModel);
	}

	get hasNonDefaultInsertOptionsSelectorValues() {
		return Boolean(this.nonDefaultInsertOptionsSelectorValues.length);
	}

	get insertOptionsConfiguration() {
		return this.insertOptionsSelectorValues.reduce((accumulatedConfiguration, {
			answer = '',
			question = '',
		}) => {
			const matchingSlotQuestion = this.questions[question];

			if (matchingSlotQuestion) {
				accumulatedConfiguration[matchingSlotQuestion] = answer;
			}

			return accumulatedConfiguration;
		}, {});
	}

	get insertOptionsSelectors() {
		const { insertOptionsSelectors = [] } = this.insertProps;

		return insertOptionsSelectors;
	}

	get insertOptionsSelectorsToRender() {
		return this.insertOptionsSelectorsWithSelectedSelectorValue.filter(({
			selectedSelectorValue: {
				title = '',
			} = {},
		}) => {
			return !['no', 'none'].includes(title.toLowerCase());
		});
	}

	get insertOptionsSelectorsWithSelectedSelectorValue() {
		return this.insertOptionsSelectors.map((selector = {}) => {
			const { type = '' } = selector;

			const selectedSelectorValue = this.insertOptionsSelectorValues.find(({ parentType = '' }) => parentType === type) || {};

			return {
				selectedSelectorValue,
				selector,
			};
		});
	}

	get isValid() {
		return this.allow !== 'NONE';
	}

	get nonDefaultInsertOptionsSelectorValues() {
		return this.insertOptionsSelectorsWithSelectedSelectorValue.filter(({
			selectedSelectorValue = {},
			selector: {
				defaultSelectorValue = {},
			} = {},
		}) => selectedSelectorValue !== defaultSelectorValue);
	}

	get rowId() {
		return this.id.substring(0, 1);
	}
}

export const WorkspaceSlotModelCasegoodsFactory = ({
	create: ({
		allow = 'ALL',
		answeredBySlot = null,
		answerForSlots = [],
		columnIndex = 0,
		id = '',
		insertOptionsSelectorValues = [],
		insertProductModel = null,
		insertProps = {},
		isMaxColumn = false,
		isMaxRow = false,
		questions = {},
		rowIndex = 0,
	}) => {
		const workspaceSlotModel = new WorkspaceSlotModel();

		Object.assign(workspaceSlotModel, {
			allow,
			answeredBySlot,
			answerForSlots,
			columnIndex,
			id,
			insertOptionsSelectorValues,
			insertProductModel,
			insertProps,
			isMaxColumn,
			isMaxRow,
			questions,
			rowIndex,
		});

		return workspaceSlotModel;
	},
});
