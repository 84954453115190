import { observer } from 'mobx-react';
import React from 'react';

import { SelectorValueInsertProduct } from '~/product/casegoods/selector/Components/insert/SelectorValueInsertProduct';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import styles from '#/product/casegoods/selector/insert/selector-values-type-insert.module.scss';

export const SelectorValuesTypeInsert = observer((props) => {
	const {
		selectorValue: {
			articles = [],
			productModelsByLineDrawingImage = {},
			productModels = [],
		},
	} = props;

	const {
		selectorConfigModel: {
			settings: {
				showFlattenedInsertProducts = false,
			} = {},
		} = {},
	} = useProductContext();

	if (showFlattenedInsertProducts) {
		return (
			<div className={`${styles['selector-values-type-insert']} tw-flex md:tw-flex-wrap`}>
				{
					productModels.map((productModel = {}, index) => {
						const {
							articleNumber: productArticleNumber = '',
							imageUrl = '',
						} = productModel;

						const matchingArticle = articles.find(({ articleNumber = '' }) => articleNumber === productArticleNumber) || {};

						const { selectors: insertOptionsSelectors = [] } = matchingArticle;

						return (
							<SelectorValueInsertProduct
								key={`selector-value-insert-${index}`}
								insertOptionsSelectors={insertOptionsSelectors}
								lineDrawingImage={imageUrl}
								productModels={[productModel]}
							/>
						);
					})
				}
			</div>
		);
	}

	return (
		<div className={`${styles['selector-values-type-insert']} tw-flex md:tw-flex-wrap`}>
			{
				Object.entries(productModelsByLineDrawingImage).map(([lineDrawingImage = '', lineDrawingProductModels = []], index) => {
					return (
						<SelectorValueInsertProduct
							key={`selector-value-insert-${index}`}
							lineDrawingImage={lineDrawingImage}
							productModels={lineDrawingProductModels}
						/>
					);
				})
			}
		</div>
	);
});
