/* eslint-disable jsx-a11y/no-static-element-interactions */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useDragLayer } from 'react-dnd';

import styles from '#/product/casegoods/workspace/product/workspace-product-width.module.scss';

export const WorkspaceProductWidthDraggableCasegoods = observer((props) => {
	const {
		workspaceProduct: {
			showErrorMessage = false,
			workspaceProductWidth = 0,
		} = {},
		workspaceStore = {},
		canShowWorkspaceErrorMessage = false,
	} = props;

	const [isDraggingOver, setIsDraggingOver] = useState(false);

	const handleDragOver = () => {
		!isDraggingOver && setIsDraggingOver(true);
	};

	const handleDragLeave = () => {
		setIsDraggingOver(false);
	};

	const {
		isDragging,
		type,
	} = useDragLayer(monitor => ({
		isDragging: monitor.isDragging(),
		type: monitor.getItemType(),
	}));

	useEffect(() => {
		!isDragging && setIsDraggingOver(false);
	}, [isDragging]);

	useEffect(() => {
		if (!isDraggingOver) {
			workspaceStore.setShowErrorMessage(false);
		}
		if (isDragging && isDraggingOver && type === 'INSERT_PRODUCT' && !showErrorMessage && canShowWorkspaceErrorMessage) {
			workspaceStore.setShowErrorMessage(true);
		}
	}, [isDragging, isDraggingOver, canShowWorkspaceErrorMessage]);

	return <>
		<div className={`${styles['workspace-product-width']}`} data-qa="workspace-product-width">
			<span className={styles['workspace-product-width-value']}>
				{workspaceProductWidth}"
			</span>
		</div>
		<div onDragOver={handleDragOver} onDragLeave={handleDragLeave} className={styles['workspace-product-width-drag-area']} />
	</>;
});
