import { observer } from 'mobx-react';
import React from 'react';

import styles from '#/product/casegoods/workspace/workspace.module.scss';
import styles2 from '#/product/casegoods/workspace/workspace-empty.module.scss';

export const StackWorkspace = observer(() => {
	return (
		<div className={styles['workspace-products-container']}>
			<div
				className={styles2['workspace-empty-text']}
				data-qa="workspace-products-droppable"
			>
				Drag and drop pieces <br/> into your design
			</div>
		</div>
	);
});
