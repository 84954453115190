import { computed, makeObservable } from 'mobx';

class CartProduct {
	data

	constructor() {
		makeObservable(this, {
			articleNumber: computed,
			imageUrl: computed,
			name: computed,
			quantity: computed,
			price: computed,
			returnMessageType: computed,
		});
	}

	get articleNumber() {
		return this.data.articleNumber;
	}

	get imageUrl() {
		return this.data.productImage;
	}

	get name() {
		return this.data.productName;
	}

	get quantity() {
		return this.data.quantity;
	}

	get price() {
		return this.data.sellingPrice;
	}

	get returnMessageType() {
		return this.data.returnMessageType || 'MISSING';
	}
}

export const CartProductFactory = ({
	create: (data = {}) => {
		const instance = new CartProduct();
		instance.data = data;
		return instance;
	},
});
