import { createDetailsModel } from '~/product/common/details/Helpers/Details.init';
import { createSelectorFiltersModel } from '~/product/common/selector/Helpers/SelectorFilters.init';
import { createSelectorFiltersMagicTabccordionStore } from '~/product/common/selector/Helpers/SelectorFiltersTabccordion.init';
import { SelectorFiltersStoreFactory } from '~/product/common/selector/Stores/SelectorFilters.store';
import { SelectorQuestionModelFactory } from '~/product/sectionals/selector/Models/SelectorQuestion.model';
import { SelectorValueQuestionModelFactory } from '~/product/sectionals/selector/Models/SelectorValueQuestion.model';

const createSelectorValueQuestionModel = ({
	insertProductModels = [],
	parentLabel = '',
	parentType = '',
	selectorValueData = {},
}) => {
	const {
		details: detailsData,
		selectorDetails: selectorDetailsData,
	} = selectorValueData;

	const detailsModel = createDetailsModel(detailsData);

	const selectorDetailsModel = createDetailsModel(selectorDetailsData);

	const selectorValueQuestionModel = SelectorValueQuestionModelFactory.create({
		...selectorValueData,
		detailsModel,
		insertProductModels,
		parentLabel,
		parentType,
		selectorDetailsModel,
	});

	return selectorValueQuestionModel;
};

export const createSelectorQuestionModel = ({
	selectorData = {},
	selectorData: {
		filter: filtersData = {},
		label = '',
		questionGroups = [],
		renderer = '',
		type = 'QUESTION',
		values = [],
	} = {},
}) => {
	const isFabricSelector = questionGroups.some((questionGroup = '') => {
		return ['FABRIC', 'LEATHER'].includes(questionGroup);
	});

	const rendererToUse = isFabricSelector ? 'FABRIC' : renderer;

	const selectorValues = values.map((selectorValueData = {}) => {
		return createSelectorValueQuestionModel({
			parentLabel: label,
			parentType: type,
			selectorValueData,
		});
	});

	const filtersModel = createSelectorFiltersModel({
		filtersData,
		selectorValues,
	});

	const filtersStore = SelectorFiltersStoreFactory.create({ filtersModel });

	const filtersMagicTabccordionStore = createSelectorFiltersMagicTabccordionStore(filtersStore);

	const selectorQuestionModel = SelectorQuestionModelFactory.create({
		...selectorData,
		filtersMagicTabccordionStore,
		filtersModel,
		filtersStore,
		selectorValues,
		renderer: rendererToUse,
		type,
	});

	return selectorQuestionModel;
};
