import { BazaarvoicePresentEventTrackingModel } from '~/tracking/vendor-events/bazzarvoice/Models/BazaarvoicePresentEvent.tracking.model';
import { IBazaarvoiceJSON } from '~/tracking/vendor-events/bazzarvoice/Interfaces/IBazaarvoicePresentEvent.tracking';

export class BazaarvoicePresentEventTrackingStore {
	tsWindow = window as any;

	trackBazaarvoicePresent() {
		if (!this.tsWindow.eventDataLayer) {
			return;
		}

		const bazaarVoicePresentTrackingModel = new BazaarvoicePresentEventTrackingModel();

		this.pushToEventDataLayer(bazaarVoicePresentTrackingModel.trackingJson);
	}

	pushToEventDataLayer(json: IBazaarvoiceJSON) {
		this.tsWindow.eventDataLayer.push(json);
	}
}
