import React from 'react';
import { observer } from 'mobx-react';

import classNames from 'classnames';

import styles from './added-to-favorites-modal.module.scss';

import { TextButton } from '~/components/Buttons/Components/TextButton';
import { ButtonVariant } from '~/components/Buttons/Types/constants';
import { AddToFavoritesStore } from '~/favorites/add/Stores/AddToFavorites.store';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { isEngage, s7ContentPath } from '~/global/global.constants';
import { Link } from '~/components/Buttons/Components/Link';
import { openSignInModalHandler } from '~/account/sign-in/Handlers/openSignInModalHandler';
import { LazyPicture } from '~/util/Components/LazyPicture';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';

interface Props {
	store: AddToFavoritesStore
	productInfo: {
		imageUrl: string | undefined
		salesText: string
		quantity: number
		price: number
	}
}

export const AddedToFavoritesModal = observer((props: Props) => {
	const {
		store: {
			addedProductGroups = [],
			isProcessing = true,
		} = {},
	} = props;

	const {
		globalDynamicStore,
		magicModal,
		HREF,
		HREF: {
			favorites: favoritesUrl
		},
	} = useGlobalContext();

	const openSignInModal = (viewKey: string = 'signIn') => {
		openSignInModalHandler(viewKey, magicModal, globalDynamicStore, HREF);
	};

	const hasMultipleProducts = addedProductGroups?.length > 1;
	const displayedProduct = addedProductGroups?.length > 0 ? addedProductGroups[0] : null;

	const {
		image: {
			imageSrcSet = '',
			mediumImageSrc = '',
			mediumImageSrcSet = '',
		} = {},
		hasImage,
	} = displayedProduct || {};

	if (isProcessing) {
		return (
			<div className="tw-relative tw-h-72">
				<LoadingSpinner isLoading />
			</div>
		);
	}

	return (
		<div data-qa="guest-add-to-favorites-modal">
			<div className={
				classNames({
					[styles.engageGrid]: isEngage,
					[styles.multipleItems]: hasMultipleProducts,
				})
			}>
				<div>
					<div className={styles.imageHolder}>
						{
							hasImage &&
							<LazyPicture
								mediumSrcSet={mediumImageSrcSet}
								smallSrcSet={imageSrcSet}
								fallbackSrc={mediumImageSrc}
							/>
						}
						{
							!hasImage &&
							<img alt="" src={`${s7ContentPath}/black_heart_icon`} />
						}
					</div>
					{ !isEngage &&
						<div className={styles.verbiageContainerSimple}>
							<h2 className="tw-heading-5 md:tw-heading-4">Save Your Favorites</h2>
							<div className={styles.verbiage}>
								<TextButton
									type="button"
									data-qa="added-to-favorites-sign-in-button"
									onClick={() => {
										openSignInModal('signIn');
									}}
								>
									Sign in
								</TextButton>
								{' or '}
								<TextButton
									type="button"
									data-qa="added-to-favorites-create-account-button"
									onClick={() => {
										openSignInModal('createAccount');
									}}
								>
									create an account
								</TextButton>
								{' to save your favorites and make sure they\'re here next time you visit.'}
							</div>
						</div>
					}
				</div>
			</div>
			<div className={`${styles.buttons}`}>
				<Link
					data-qa="go-to-favorites-link"
					variant={ButtonVariant.Primary}
					href={favoritesUrl}
				>
					Go to Favorites
				</Link>
				<TextButton
					type="button"
					data-qa="added-to-favorites-close-button"
					onClick={() => {
						magicModal.closeModal();
					}}
					data-tr-link-event-track="false"
				>
					Continue shopping
				</TextButton>
			</div>
		</div>
	);
});
