import { SelectorFrame } from '~/product/casegoods/selector/Components/frame/SelectorFrame';
import { SelectorInsert } from '~/product/casegoods/selector/Components/insert/SelectorInsert';
import { SelectorOptions } from '~/product/casegoods/selector/Components/options/SelectorOptions';
import { SelectorSwatch } from '~/product/casegoods/selector/Components/swatch/SelectorSwatch';
import { SelectorSwatchCombined } from '~/product/casegoods/selector/Components/swatch-combined/SelectorSwatchCombined';
import { SelectorDownload } from '~/product/casegoods/selector/Components/download/SelectorDownload';
import { SelectorMediumImage } from './medium/SelectormediumImage';

export const selectorCasegoodsComponents = {
	FRAME: SelectorFrame,
	FRAMESTACK: SelectorFrame,
	STACK: SelectorInsert,
	INSERT: SelectorInsert,
	OPTIONS: SelectorOptions,
	SWATCH: SelectorSwatch,
	MEDIUM_IMAGE: SelectorMediumImage,
	SWATCH_COMBINED: SelectorSwatchCombined,
	DOWNLOAD: SelectorDownload,
};
