import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { kebabCase } from '~/util/kebabCase';

export const SelectorSwatchCombinedTabccordionTabHeading = observer((props) => {
	const {
		selectorModel: {
			label = '',
		},
		isActive = false,
	} = props;

	return (
		<div
			className={
				classNames('selector-swatch-combined-tabccordion-tab-heading', {
					'selector-swatch-combined-tabccordion-tab-heading-selected': isActive,
				})
			}
		>
			<span data-qa={`${kebabCase(label)}-swatch-combined-heading`}>{label}</span>
		</div>
	);
});
