import React from 'react';

import { ProductPromos } from '~/product/common/promos/Components/ProductPromos';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import styles from '#/product/standard/selector/selector-promos.module.scss';

export const SelectorPromos = (props) => {
	const {
		promos = [],
	} = props;

	const {
		productGroupModel: {
			name = '',
		} = {},
	} = useProductContext();

	if (!promos.length) {
		return null;
	}

	return (
		<div className={styles['selector-promos-container']}>
			<ProductPromos
				listClassName={`${styles['selector-promos']} selector-promos u-noListStyle`}
				promos={promos}
				trLinkEventCompName={name}
				trLinkEventCompType="product selector promo"
			/>
		</div>
	);
};
