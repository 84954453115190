import type { SelectorConfigCasegoods } from '~/product/casegoods/selector/Models/SelectorConfigCasegoods.model';
import type { SelectorConfigCasegoodsStore } from '~/product/casegoods/selector/Stores/SelectorConfigCasegoods.store';
import type { WorkspaceProductCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Models/WorkspaceProductCasegoods.model';
import type { WorkspaceProductCasegoodsStore } from '~/product/casegoods/workspaces/defaultWorkspace/Stores/WorkspaceProductCasegoods.store';

export class SelectorFrameModal {
	productGroupModel: any;

	selectorConfigFrameModalModel: SelectorConfigCasegoods;

	selectorConfigFrameModalStore: SelectorConfigCasegoodsStore;

	workspaceProductImageData?: any;

	workspaceProductIndex: number;

	workspaceProductFrameModal: WorkspaceProductCasegoods;

	workspaceProductStoreFrameModal: WorkspaceProductCasegoodsStore;

	constructor({
		productGroupModel,
		selectorConfigFrameModalModel,
		selectorConfigFrameModalStore,
		workspaceProductImageData,
		workspaceProductIndex,
		workspaceProductFrameModal,
		workspaceProductStoreFrameModal,
	}: any) {
		this.productGroupModel = productGroupModel;
		this.selectorConfigFrameModalModel = selectorConfigFrameModalModel;
		this.selectorConfigFrameModalStore = selectorConfigFrameModalStore;
		this.workspaceProductImageData = workspaceProductImageData;
		this.workspaceProductIndex = workspaceProductIndex;
		this.workspaceProductFrameModal = workspaceProductFrameModal;
		this.workspaceProductStoreFrameModal = workspaceProductStoreFrameModal;
	}
}

export const SelectorFrameModalModelFactory = ({
	create: (data: any): SelectorFrameModal => {
		return new SelectorFrameModal(data);
	},
});
