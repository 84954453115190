import React from 'react';
import { observer } from 'mobx-react';

import styles from '~/util/curalate/Components/curalate-detail.module.scss';

const CuralateItemSource = observer(class CuralateItemSource extends React.Component {
	constructor(props) { // eslint-disable-line no-useless-constructor
		super(props);
	}

	render() {
		const { source } = this.props;

		const {
			caption,
			type,
			user,
		} = source;

		const {
			displayName,
			image = {},
			username,
		} = user;

		const { smallSquare } = image;

		const displayNameUserDetail = displayName && (
			<span>{displayName}</span>
		);

		const usernameUserDetail = username && (
			<span>{`@${username}`}</span>
		);

		const userDetails = (
			<div className={styles['curalate-item-source-user-details']}>
				{displayNameUserDetail}
				{usernameUserDetail}
			</div>
		);

		const userImage = smallSquare && (
			<img
				alt={`${username}’s ${type} profile`}
				className={styles['curalate-item-source-user-image']}
				src={smallSquare.link}
				srcSet={`${smallSquare.link} 2x`}
			/>
		);

		return (
			<div className={styles['curalate-item-source']}>
				<div className={styles['curalate-item-source-user']} data-qa="curalate-item-source-user">
					{userImage}
					{userDetails}
				</div>
				<p className={styles['curalate-item-source-caption']}>
					{caption}
				</p>
			</div>
		);
	}
});

export { CuralateItemSource };
