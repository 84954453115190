class DetailLink {
	behavior

	displayText

	key

	linkType

	url

	src

	label

	mediaIcon
}

export const DetailLinkModelFactory = ({
	create: ({
		behavior = '',
		linkBehavior = '',
		displayText = '',
		key = '',
		linkType = '',
		url = '',
		src = '',
		label = '',
		mediaIcon = '',
	}) => {
		const detailLink = new DetailLink();

		Object.assign(detailLink, {
			behavior: linkBehavior || behavior,
			displayText,
			key,
			linkType,
			url,
			src,
			label,
			mediaIcon,
		});

		return detailLink;
	},
});
