import { computed, makeObservable } from 'mobx';

import { s7ImagePath } from '~/global/global.constants';

class ConfigurationOverrideCasegoods {
	additionalFrameProductList

	frameProduct

	imageData

	insertProducts

	insertSelector

	optionsSelector

	constructor() {
		makeObservable(this, {
			defaultAnswers: computed,
			hasConfigurationOverride: computed,
			hasDefaultAnswers: computed,
			imageUrl: computed,
		});
	}

	get defaultAnswers() {
		return Object.entries(this.configuration).map(([question, answer]) => {
			return {
				answer,
				question,
			};
		});
	}

	get hasConfigurationOverride() {
		return Boolean(this.articleNumber) || this.hasDefaultAnswers;
	}

	get hasDefaultAnswers() {
		return Boolean(this.defaultAnswers.length);
	}

	get imageUrl() {
		const imageData = this.imageData[this.articleNumber] || {};

		const { imageUrl = `${s7ImagePath}/noimage` } = imageData;

		return imageUrl;
	}
}

export const ConfigurationOverrideCasegoodsModelFactory = ({
	create: ({
		additionalFrameProductList = [],
		frameProduct = {},
		imageData = {},
		insertProducts = [],
		insertSelector = {},
		optionsSelector = {},
	}) => {
		const configurationOverride = new ConfigurationOverrideCasegoods();

		Object.assign(configurationOverride, {
			additionalFrameProductList,
			frameProduct,
			imageData,
			insertProducts,
			insertSelector,
			optionsSelector,
		});

		return configurationOverride;
	},
});
