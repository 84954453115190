class WorkspaceOverrideCasegoods {
	workspaceProducts
}

export const WorkspaceOverrideCasegoodsModelFactory = ({
	create: ({
		workspaceProducts = [],
	}) => {
		const workspaceOverride = new WorkspaceOverrideCasegoods();

		Object.assign(workspaceOverride, {
			workspaceProducts,
		});

		return workspaceOverride;
	},
});
