import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { WorkspaceProductCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductCasegoods';
import { WorkspaceProductsErrorCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductsErrorCasegoods';
import { WorkspaceProductsHeightCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductsHeightCasegoods';
import { WorkspaceProductStackCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductStackCasegoods';
import { WorkspaceProductStackDraggableCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductStackDraggableCasegoods';
import { WorkspaceProductsWidthCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductsWidthCasegoods';
import { calculateImageScale } from '~/product/casegoods/workspaces/defaultWorkspace/Utils/WorkspaceCasegoods.utils';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductsMessageCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product/WorkspaceProductsMessageCasegoods';

import styles from '#/product/casegoods/workspace/product/workspace-products.module.scss';

export const WorkspaceProductsCasegoods = observer((props) => {
	const {
		imageSettings: {
			defaultImageScale = 0,
			workspaceHeight: workspaceHeightSetting = 0,
			workspaceWidth: workspaceWidthSetting = 0,
		} = {},
		isDraggable = false,
		isMediaThumbnail = false,
	} = props;

	const {
		workspaceModel: {
			dimensions: {
				imageInchesToPixelsFactor = 0,
				translateBottomInches,
			} = {},
			settings: {
				renderWorkspaceProductsAsStacks = false,
			},
			showDimensions = false,
			showOverallWidthDimension = true,
			sortedWorkspaceProducts = [],
			workspaceProductsHeight = 0,
			workspaceProductsWidth = 0,
			showInsertMessage = false,
			showInsertAdditionalInfo = false,
		} = {},
		workspaceStore = {},
	} = useProductContext();

	const workspaceRef = useRef(null);
	const [workspaceHeightState, setWorkspaceHeightState] = useState(0);
	const [workspaceWidthState, setWorkspaceWidthState] = useState(0);

	const { workspaceProductsPixelsHeight, workspaceProductsPixelsWidth, imageScaleToUse } = calculateImageScale({
		workspaceHeightState,
		workspaceHeightSetting,
		workspaceWidthState,
		workspaceWidthSetting,
		imageInchesToPixelsFactor,
		workspaceProductsHeight,
		workspaceProductsWidth,
		defaultImageScale,
	});

	useEffect(() => {
		const { parentElement } = workspaceRef.current || {};
		const {
			clientHeight = 0,
			clientWidth = 0,
		} = parentElement || {};
		if (!isMediaThumbnail) {
			setWorkspaceHeightState(clientHeight);
			setWorkspaceWidthState(clientWidth);
		}
	}, [workspaceRef.current]);

	return (
		<div
			className={styles['workspace-products']}
			ref={workspaceRef}
		>
			{
				showDimensions && !isMediaThumbnail && (
					<WorkspaceProductsHeightCasegoods />
				)
			}
			<div className={styles['workspace-products-width-products']}>
				{(showInsertMessage || showInsertAdditionalInfo) && (
					<WorkspaceProductsMessageCasegoods />
				)}
				{
					showDimensions && !isMediaThumbnail && showOverallWidthDimension && (
						<WorkspaceProductsWidthCasegoods />
					)
				}
				<div
					className={styles['workspace-products-products']}
					style={
						isMediaThumbnail ? {
							height: workspaceProductsPixelsHeight,
							width: workspaceProductsPixelsWidth,
						} : {}
					}
				>
					{sortedWorkspaceProducts.map((workspaceProduct, index) => {
						if (renderWorkspaceProductsAsStacks) {
							if (isDraggable) {
								return (
									<WorkspaceProductStackDraggableCasegoods
										imageInchesToPixelsFactor={imageInchesToPixelsFactor}
										imageScale={imageScaleToUse}
										key={`workspace-product-${index}`}
										translateBottomInches={translateBottomInches}
										showDimensions={showDimensions}
										workspaceProduct={workspaceProduct}
										workspaceStore={workspaceStore}
										productIndex={index}
									/>
								);
							}
							return <WorkspaceProductStackCasegoods
								imageInchesToPixelsFactor={imageInchesToPixelsFactor}
								imageScale={imageScaleToUse}
								isMediaThumbnail={isMediaThumbnail}
								key={`workspace-product-${index}`}
								translateBottomInches={translateBottomInches}
								showDimensions={showDimensions}
								workspaceProduct={workspaceProduct}
								workspaceStore={workspaceStore}
							/>;
						}

						return (
							<WorkspaceProductCasegoods
								imageScale={imageScaleToUse}
								isDraggable={isDraggable}
								isMediaThumbnail={isMediaThumbnail}
								key={`workspace-product-${index}`}
								workspaceProduct={workspaceProduct}
								workspaceStore={workspaceStore}
							/>
						);
					})}
				</div>
			</div>
			{
				isDraggable && (
					<WorkspaceProductsErrorCasegoods />
				)
			}
		</div>
	);
});
