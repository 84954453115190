import classNames from 'classnames';
import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { WorkspaceModalHelpSectionals } from '~/product/sectionals/workspace/Components/modal-help/WorkspaceModalHelpSectionals';

import styles from '~/product/sectionals/workspace/Components/controls/workspace-control-help.module.scss';

interface Props {
	isMainWorkspace: boolean
}

export const WorkspaceControlHelpSectionals = ({ isMainWorkspace = false }: Props) => {
	const { magicModal = {} } = useGlobalContext();

	function handleClick(event: React.MouseEvent): void {
		magicModal.openModal({
			content: {
				children: <WorkspaceModalHelpSectionals />,
			},
			id: 'workspace-help-modal',
			maxWidth: '600px',
			title: 'Help',
		}, event);
	}

	return (
		<button
			className={
				classNames(
					styles['workspace-control-help'],
					'ButtonAnchor', {
						'tw-hidden md:tw-flex': !isMainWorkspace,
					}
				)
			}
			data-qa="workspace-control-help"
			onClick={handleClick}
		>
			<span className={`${styles['workspace-control-help-text']} tw-hidden md:tw-block`}>
				Help
			</span>
		</button>
	);
};
