import { observable, reaction } from 'mobx';

import { autoFocusHelper } from '~/util/autoFocusHelper';
import { isOnServer } from '~/global/global.constants';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

const curalateUploadFormModel = (defaultData, store) => {
	const { addFiles } = store;

	const model = observable({
		displayName: '',
		emailAddress: '',
		files: [],
		termsAndConditions: true,
	});

	Object.assign(model, defaultData);

	if (!isOnServer) {
		// this handles the "browse files" dialog. need to unpack the behind-the-scenes "files" attribute of the input element to get at the native FileList
		reaction(() => model.files, (modelFiles) => {
			if (modelFiles.length) {
				const { files } = store.form.fields.files.control.controlRef;

				const filesToAdd = [];

				for (let i = 0; i < files.length; i++) {
					filesToAdd.push(files[i]);
				}

				addFiles(filesToAdd);
			}
		});
	}

	return model;
};

const curalateUploadFormSettings = (store) => {
	return {
		id: 'curalate-upload',
		reactProps: {
			encType: 'multipart/form-data',
			method: 'POST',
			className: styles['curalate-upload'],
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler: form => store.submitCuralateMedia(form.model),
						promiseHandler: (promise) => {
							promise.then(() => {
								store.resetUploadCuralate();
								store.isSubmitted = true;
							});
							promise.catch(() => {
								store.hasError = true;
							});
						},
					},
				}),
			],
		},
		fields: {
			displayName: {
				label: {
					reactProps: {
						children: 'Display Name',
					},
				},
				control: {
					reactProps: {
						ref: autoFocusHelper,
						type: 'text',
						maxLength: 100,
					},
				},
				settings: {
					validationConstraints: {
						length: {
							minimum: 0,
							maximum: 100,
						},
						presence: {
							message: '^Enter your display name',
						},
					},
				},
			},
			emailAddress: {
				control: {
					reactProps: {
						type: 'email',
					},
				},
				label: {
					reactProps: {
						children: 'Email Address',
					},
				},
				settings: {
					validationConstraints: {
						presence: {
							message: '^Enter your email address',
						},
						email: {
							message: '^Please enter a valid email address.',
						},
					},
				},
			},
			files: {
				label: {
					reactProps: {
						className: `${styles['files-label-curalate-upload']} Button--smallOnly`,
						children: 'browse files',
					},
				},
				control: {
					reactProps: {
						accept: 'image/*',
						multiple: true,
						type: 'file',
					},
				},
			},
			termsAndConditions: {
				control: {
					reactProps: {
						type: 'hidden',
					},
				},
			},
		},
	};
};

export { curalateUploadFormModel, curalateUploadFormSettings };
