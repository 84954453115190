export const calculateImageScale = ({
	workspaceHeightState,
	workspaceHeightSetting,
	workspaceWidthState,
	workspaceWidthSetting,
	imageInchesToPixelsFactor,
	workspaceProductsHeight,
	workspaceProductsWidth,
	defaultImageScale,
}) => {
	const workspaceHeightToUse = workspaceHeightState || workspaceHeightSetting;
	const workspaceWidthToUse = workspaceWidthState || workspaceWidthSetting;
	const adaptiveImageScaleByHeight = ((imageInchesToPixelsFactor * workspaceProductsHeight) / workspaceHeightToUse).toFixed(3);
	const adaptiveImageScaleByWidth = ((imageInchesToPixelsFactor * workspaceProductsWidth) / workspaceWidthToUse).toFixed(3);
	const adaptiveImageScaleToUse = adaptiveImageScaleByWidth > adaptiveImageScaleByHeight ? adaptiveImageScaleByWidth : adaptiveImageScaleByHeight;
	const imageScaleToUse = adaptiveImageScaleToUse > defaultImageScale ? adaptiveImageScaleToUse : defaultImageScale;
	const workspaceProductsPixelsHeight = ((imageInchesToPixelsFactor * workspaceProductsHeight) / imageScaleToUse).toFixed(3);
	const workspaceProductsPixelsWidth = ((imageInchesToPixelsFactor * workspaceProductsWidth) / imageScaleToUse).toFixed(3);

	return { workspaceProductsPixelsHeight, workspaceProductsPixelsWidth, imageScaleToUse };
};

const getIsValidWorkspaceProductHover = ({
	draggableIndex = 0,
	monitor = {},
	ref = {},
	workspaceProductIndex = 0,
}) => {
	if (!ref.current) {
		return false;
	}

	if (workspaceProductIndex === draggableIndex) {
		return false;
	}

	const hoverBoundingRect = ref.current.getBoundingClientRect();

	const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

	const clientOffset = monitor.getClientOffset();

	const hoverClientX = clientOffset.x - hoverBoundingRect.left;

	// dragging right
	if (draggableIndex < workspaceProductIndex && hoverClientX < hoverMiddleX) {
		return false;
	}

	// dragging left
	if (draggableIndex > workspaceProductIndex && hoverClientX > hoverMiddleX) {
		return false;
	}

	return true;
};

const getMatchingInsertProductModel = ({
	allow = '',
	depth = 0,
	insertProductModels = [],
	insertSizeKey = '',
	rowId = '',
	width = 0,
}) => {
	return insertProductModels.find(({
		compatibleFrameDepth = 0,
		compatibleFrameSize = '',
		compatibleFrameWidth = 0,
		exceptionRows = [],
		type = '',
	}) => {
		const isCompatibleDimensions = (depth === compatibleFrameDepth) && ((width === compatibleFrameWidth) || (insertSizeKey === compatibleFrameSize));

		let isAllowed = false;
		// deal with riser inserts, which are the only insert type allowed without a shelf above them
		if (typeof allow === 'string') {
			isAllowed = (allow === 'ALL') || (allow === 'RISER_ONLY' && type === 'RISER');
		} else {
			isAllowed = allow.some(value => (value === 'ALL' || (value === 'RISER_ONLY' && type === 'RISER')));
		}

		// deal with exception rows - no drawers up high, no desk surface up high, etc.
		const isExceptionRow = exceptionRows.includes(rowId);

		return isCompatibleDimensions && isAllowed && !isExceptionRow;
	});
};

export {
	getIsValidWorkspaceProductHover,
	getMatchingInsertProductModel,
};
