import { observer } from 'mobx-react';
import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { WorkspaceModalHelpCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/modal-help/WorkspaceModalHelpCasegoods';

import styles from '#/product/casegoods/workspace/controls/workspace-control-help.module.scss';

export const WorkspaceControlHelpCasegoods = observer(() => {
	const { magicModal } = useGlobalContext();

	function handleClick(event) {
		magicModal.openModal({
			content: {
				children: <WorkspaceModalHelpCasegoods />,
			},
			id: 'workspace-help-modal',
			maxWidth: '600px',
			title: 'Help',
		}, event);
	}

	return (
		<button
			className={`${styles['workspace-control-help']} ButtonAnchor`}
			data-qa="workspace-control-help"
			onClick={handleClick}
		>
			<span className={`${styles['workspace-control-help-text']} tw-hidden md:tw-block`}>
				Help
			</span>
		</button>
	);
});
