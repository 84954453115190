import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { isEngage } from '~/global/global.constants';

import styles from '#/mobile/global/header.module.scss';
import { Button } from '~/components/Buttons/Components/Button';
import { ButtonVariant } from '~/components/Buttons/Types/constants';

interface HeaderMobileAccountButtonProps {
	headerMobile: {
		isAccountOpen: boolean;
		isLoggedIn: boolean;
	};
	clickHandler: () => void;
}

const HeaderMobileAccountButton: React.FC<HeaderMobileAccountButtonProps> = observer((props) => {
	const {
		headerMobile: {
			isAccountOpen,
			isLoggedIn,
		},
		clickHandler,
	} = props;

	const handleClick = () => {
		clickHandler();
	};

	const [isOnClient, setIsOnClient] = React.useState(false);

	useEffect(() => {
		setIsOnClient(true);
	}, []);

	return (
		<Button
			aria-haspopup={isOnClient && isLoggedIn ? true : undefined}
			className={
				classNames({
					[styles['header-mobile-account-button']]: true,
					'has-menu': isAccountOpen,
					'has-focus': isAccountOpen,
				})
			}
			data-tr-link-event-name="sign in icon"
			data-qa="account-button"
			onClick={handleClick}
			variant={ButtonVariant.Icon}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="22.642" height="21.654" viewBox="0 0 22.642 21.654" focusable="false">
				<g transform="translate(0.5 0.5)">
					<ellipse className="hover-fill" cx="5.761" cy="5.761" rx="5.761" ry="5.761" transform="translate(5.06)" fill="#333" stroke="#333" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1" />
					<g transform="translate(0 12.502)">
						<path className="hover-fill" d="M23.642,22.666c0-2.314-4.206-4.907-5.656-4.907a10.29,10.29,0,0,1-5.165,1.3,10.29,10.29,0,0,1-5.165-1.3C5.721,17.759,2,20.136,2,22.666v3.245H23.642Z" transform="translate(-2 -17.759)" fill="#333" stroke="#333" strokeMiterlimit="10" strokeWidth="1" />
					</g>
				</g>
			</svg>
			<span className="tw-sr-only">Open account menu</span>
			{
				isOnClient && !isEngage && isLoggedIn &&
				<span className="tw-border tw-border-solid tw-border-white tw-bg-gray tw-w-5 tw-h-5 tw-absolute tw-left-[-12px] tw-bottom-[-6px] tw-flex tw-justify-center tw-items-center tw-rounded-full" data-qa="account-signed-in-icon">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="13"
						height="9"
						viewBox="0 0 13 9"
						focusable="false"
						role="img"
						aria-labelledby="signedInTitle"
					>
						<title id="signedInTitle">Signed In</title>
						<g>
							<polygon
								fill="white"
								points="4.46,9.17 0.86,5.57 2.28,4.16 4.46,6.34 9.75,1.04 11.17,2.46  "
							/>
						</g>
					</svg>
				</span>
			}
		</Button>
	);
});

export { HeaderMobileAccountButton };
