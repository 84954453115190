import { action, observable, makeObservable } from 'mobx';

import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { magicDeliveryFormModel, magicDeliveryFormSettings } from '~/components/magic-delivery/Models/MagicDeliveryForm.model';

class MagicDeliveryFormStore {
	form;

	parentStore

	constructor() {
		makeObservable(this, {
			form: observable.ref,
			trackZipCodeWidgetInteration: action.bound,
		});
	}

	// eslint-disable-next-line consistent-return
	trackZipCodeWidgetInteration(trLinkEventCompName = null, trLinkEventCompType = null, linkEventStore) {
		if (!linkEventStore) {
			return null;
		}

		if (window.eventDataLayer && linkEventStore) {
			const linkEventTrackingData = {
				trLinkEventName: 'submit zip code delivery widget',
				trLinkEventCompName,
				trLinkEventCompType,
				trLinkEventType: LinkEventTypes.SITE_ACTION,
			};
			linkEventStore.trackLinkEvent(linkEventTrackingData);
		}
	}
}

export const MagicDeliveryFormStoreFactory = {
	create(parentStore, globalDynamicStore) {
		if (!parentStore) {
			throw new Error('Missing context: parentStore');
		}
		if (!globalDynamicStore) {
			throw new Error('Missing context: globalDynamicStore');
		}

		const deliveryFormStore = new MagicDeliveryFormStore();

		deliveryFormStore.globalDynamicStore = globalDynamicStore;
		deliveryFormStore.parentStore = parentStore;
		deliveryFormStore.form = new FormBuilder(
			magicDeliveryFormModel(),
			magicDeliveryFormSettings(deliveryFormStore),
		);

		return deliveryFormStore;
	},
};
