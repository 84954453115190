import { createSelectorFrameModel } from '~/product/casegoods/selector/Helpers/SelectorFrame.init';
import { createSelectorInsertModel } from '~/product/casegoods/selector/Helpers/SelectorInsert.init';
import { createSelectorMaterialModel, createSelectorMaterialCombinedModel } from '~/product/casegoods/selector/Helpers/SelectorMaterial.init';
import { createSelectorOptionsModel } from '~/product/casegoods/selector/Helpers/SelectorOptions.init';
import { SelectorConfigCasegoodsModelFactory } from '~/product/casegoods/selector/Models/SelectorConfigCasegoods.model';
import { SelectorDownloadModelFactory } from '~/product/casegoods/selector/Models/SelectorDownload.model';
import { imageScaleSelectorCasegoods, settingsSelectorCasegoods, productGroupOverrideSettingsSelectorCasegoods } from '~/product/casegoods/selector/selector-casegoods.constants';

export const createSelectorConfigCasegoodsModel = ({
	configurationOverrideModel: {
		insertSelector: configurationOverrideInsertSelectorData = {},
		optionsSelector: configurationOverrideOptionsSelectorData = {},
	} = {},
	customQuestionAnswers: customQuestionAnswersData = {},
	defaultAvailableConfigurations,
	defaultConfigModel = {},
	defaultInsertConfigModel = {},
	frameProductModels = [],
	frameSelectorData = {},
	insertGroupsData = {},
	insertMaterialSelectors: insertMaterialSelectorsData = [],
	insertProductModels = [],
	insertSelectorData = {},
	isPreconfiguredWorkspace = false,
	linkEventStore = {},
	materialSelectors: materialSelectorsData = [],
	optionsSelectorData = {},
	productGroupModel: {
		productPageType = '',
	} = {},
}) => {
	const insertSelectorDataToUse = (configurationOverrideInsertSelectorData && Object.keys(configurationOverrideInsertSelectorData).length) ? configurationOverrideInsertSelectorData : insertSelectorData;

	const optionsSelectorDataToUse = (configurationOverrideOptionsSelectorData && Object.keys(configurationOverrideOptionsSelectorData).length) ? configurationOverrideOptionsSelectorData : optionsSelectorData;

	const shouldCombineMaterialSelectors = productPageType === 'CUSTOM_CABINET';

	const firstFrameProductModel = frameProductModels.length ? frameProductModels[0] : {};

	const defaultProduct = frameProductModels.find(({ detailsAdditionalModel = [] }) => Boolean(detailsAdditionalModel.length)) || firstFrameProductModel;

	const { imageCollectionName = '' } = firstFrameProductModel;

	let imageScale = imageScaleSelectorCasegoods[imageCollectionName];

	if (!imageScale) {
		imageScale = imageScaleSelectorCasegoods.DEFAULT;

		console.warn(`selector imageScale not found for imageCollectionName ${imageCollectionName}`);
	}

	let settings = settingsSelectorCasegoods(productPageType);

	if (!settings) {
		settings = settingsSelectorCasegoods('DEFAULT');

		console.warn(`selector settings not found for productPageType ${productPageType}`);
	}

	const productGroupSettingOverrides = productGroupOverrideSettingsSelectorCasegoods[imageCollectionName] || {};

	const settingsToUse = { ...settings, ...productGroupSettingOverrides };

	const frameSelectorModel = createSelectorFrameModel({
		customQuestionAnswersData,
		linkEventStore,
		productModels: frameProductModels,
		selectorData: frameSelectorData,
		wasActive: isPreconfiguredWorkspace,
		settings: settingsToUse,
	});

	const insertMaterialSelectorModels = insertMaterialSelectorsData.map((selectorData = {}) => {
		const insertMaterialSelectorModel = createSelectorMaterialModel({
			defaultAvailableConfigurations,
			isPreconfiguredWorkspace,
			selectorData,
			wasActive: isPreconfiguredWorkspace,
		});

		return insertMaterialSelectorModel;
	});

	const materialSelectorModels = materialSelectorsData.map((selectorData = {}) => {
		const materialSelectorModel = createSelectorMaterialModel({
			defaultAvailableConfigurations,
			isPreconfiguredWorkspace,
			selectorData,
			wasActive: isPreconfiguredWorkspace,
		});

		return materialSelectorModel;
	});

	const materialCombinedSelectorModel = createSelectorMaterialCombinedModel({
		linkEventStore,
		materialSelectorModels,
		selectorData: {
			label: 'Materials',
			renderer: 'SWATCH_COMBINED',
		},
	});

	const insertsSelectorModel = createSelectorInsertModel({
		linkEventStore,
		productModels: insertProductModels,
		insertGroupsData,
		selectorData: insertSelectorDataToUse,
		settings: settingsToUse,
		wasActive: isPreconfiguredWorkspace,
	});

	const optionsSelectorModel = createSelectorOptionsModel({
		isValid: isPreconfiguredWorkspace,
		selectorData: optionsSelectorDataToUse,
		wasActive: isPreconfiguredWorkspace,
	});

	const downloadSelectorModel = SelectorDownloadModelFactory.create({
		label: 'Download Your Design',
		renderer: 'DOWNLOAD',
		type: 'DOWNLOAD',
		isValid: isPreconfiguredWorkspace,
	});

	const selectorConfigCasegoodsModel = SelectorConfigCasegoodsModelFactory.create({
		customQuestionAnswersData,
		defaultConfigModel,
		defaultInsertConfigModel,
		defaultProduct,
		downloadSelectorModel,
		frameSelectorModel,
		productModels: frameProductModels,
		settings: settingsToUse,
		imageScale,
		insertsSelectorModel,
		insertMaterialSelectorModels,
		materialCombinedSelectorModel,
		materialSelectorModels,
		optionsSelectorModel,
		shouldCombineMaterialSelectors,
	});

	return selectorConfigCasegoodsModel;
};
