import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import type { IWorkspaceProductsChunkSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductsChunkSectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductImageSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductImageSectionals';
import { WorkspaceProductJoinableSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductJoinableSectionals';
import { WorkspaceProductsChunkDepthSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductsChunkDepthSectionals';
import { WorkspaceProductsChunkWidthSectionals } from '~/product/sectionals/workspace/Components/product/WorkspaceProductsChunkWidthSectionals';

import styles from '~/product/sectionals/workspace/Components/product/workspace-product-children.module.scss';

interface Props {
	imageScale: number
	isDraggable: boolean
	showDimensions: boolean
	workspaceProductModel: IWorkspaceProductSectionals
	workspaceProductPixelsHeight: number
	workspaceProductPixelsWidth: number
}

export const WorkspaceProductChildrenSectionals = observer(({
	imageScale = 0,
	isDraggable = false,
	showDimensions = false,
	workspaceProductModel,
	workspaceProductModel: {
		workspaceProductJoinableModelE,
		workspaceProductJoinableModelN,
		workspaceProductJoinableModelS,
		workspaceProductJoinableModelW,
	},
	workspaceProductPixelsHeight = 0,
	workspaceProductPixelsWidth = 0,
}: Props) => {
	const {
		workspaceModel: {
			combinedDepthWorkspaceProductsChunkModels = [],
			combinedWidthWorkspaceProductsChunkModels = [],
			firstWorkspaceProductModel = {},
			isDragging = false,
			isDraggingWorkspaceProductModel = null,
			workspaceProductModels = [],
		} = {},
	} = useProductContext();

	const isDraggingCurrentWorkspaceProductModel = isDraggingWorkspaceProductModel === workspaceProductModel;

	const isDraggingFirstWorkspaceProductModel = isDraggingWorkspaceProductModel === firstWorkspaceProductModel;

	const depthWorkspaceProductsChunkModels: Array<IWorkspaceProductsChunkSectionals> = combinedDepthWorkspaceProductsChunkModels.filter(({ firstWorkspaceProductModelInChunk }: IWorkspaceProductsChunkSectionals) => {
		return firstWorkspaceProductModelInChunk === workspaceProductModel;
	});

	const widthWorkspaceProductsChunkModels: Array<IWorkspaceProductsChunkSectionals> = combinedWidthWorkspaceProductsChunkModels.filter(({ firstWorkspaceProductModelInChunk }: IWorkspaceProductsChunkSectionals) => {
		return firstWorkspaceProductModelInChunk === workspaceProductModel;
	});

	const shouldRenderDimensions = showDimensions && workspaceProductModels.length > 0;

	const showWorkspaceProductJoinableModelE = workspaceProductJoinableModelE && !workspaceProductJoinableModelE.prevWorkspaceProductJoinableModel;

	const showWorkspaceProductJoinableModelN = workspaceProductJoinableModelN && !workspaceProductJoinableModelN.prevWorkspaceProductJoinableModel;

	const showWorkspaceProductJoinableModelS = workspaceProductJoinableModelS && !workspaceProductJoinableModelS.prevWorkspaceProductJoinableModel;

	const showWorkspaceProductJoinableModelW = workspaceProductJoinableModelW &&
		(!workspaceProductJoinableModelW.prevWorkspaceProductJoinableModel || ((workspaceProductJoinableModelW.prevWorkspaceProductModel === firstWorkspaceProductModel) && isDraggingFirstWorkspaceProductModel));

	return (
		<>
			{
				shouldRenderDimensions && (
					widthWorkspaceProductsChunkModels.map((widthWorkspaceProductsChunkModel, index) => {
						return workspaceProductModels.length === 1 && widthWorkspaceProductsChunkModels.length > 1 && widthWorkspaceProductsChunkModel.workspaceProductsChunkOrientation === 'S' ?
							null :
							<WorkspaceProductsChunkWidthSectionals
								imageScale={imageScale}
								key={`workapce-products-chunk-${index}`}
								workspaceProductsChunkModel={widthWorkspaceProductsChunkModel}
							/>;
					})
				)
			}
			{
				shouldRenderDimensions && (
					depthWorkspaceProductsChunkModels.map((depthWorkspaceProductsChunkModel, index) => {
						return workspaceProductModels.length === 1 && depthWorkspaceProductsChunkModels.length > 1 && depthWorkspaceProductsChunkModel.workspaceProductsChunkOrientation === 'E' ?
							null :
							<WorkspaceProductsChunkDepthSectionals
								imageScale={imageScale}
								key={`workapce-products-chunk-${index}`}
								workspaceProductsChunkModel={depthWorkspaceProductsChunkModel}
							/>;
					})
				)
			}
			{
				showWorkspaceProductJoinableModelW && (
					<WorkspaceProductJoinableSectionals
						imageScale={imageScale}
						isDraggable={isDraggable}
						showDimensions={showDimensions}
						workspaceProductJoinableModel={workspaceProductJoinableModelW}
						workspaceProductModel={workspaceProductModel}
						workspaceProductPixelsHeight={workspaceProductPixelsHeight}
						workspaceProductPixelsWidth={workspaceProductPixelsWidth}
					/>
				)
			}
			<div className={styles['workspace-product-column']}>
				{
					showWorkspaceProductJoinableModelN && (
						<WorkspaceProductJoinableSectionals
							imageScale={imageScale}
							isDraggable={isDraggable}
							showDimensions={showDimensions}
							workspaceProductJoinableModel={workspaceProductJoinableModelN}
							workspaceProductModel={workspaceProductModel}
							workspaceProductPixelsHeight={workspaceProductPixelsHeight}
							workspaceProductPixelsWidth={workspaceProductPixelsWidth}
						/>
					)
				}
				<div
					className={
						classNames(styles['workspace-product-image'], {
							'tw-opacity-0': isDraggingCurrentWorkspaceProductModel,
							'tw-opacity-60': isDragging && !isDraggingCurrentWorkspaceProductModel,
						})
					}
				>
					<WorkspaceProductImageSectionals
						imageScale={imageScale}
						workspaceProductModel={workspaceProductModel}
					/>
				</div>
				{
					showWorkspaceProductJoinableModelS && (
						<WorkspaceProductJoinableSectionals
							imageScale={imageScale}
							isDraggable={isDraggable}
							showDimensions={showDimensions}
							workspaceProductJoinableModel={workspaceProductJoinableModelS}
							workspaceProductModel={workspaceProductModel}
							workspaceProductPixelsHeight={workspaceProductPixelsHeight}
							workspaceProductPixelsWidth={workspaceProductPixelsWidth}
						/>
					)
				}
			</div>
			{
				showWorkspaceProductJoinableModelE && (
					<WorkspaceProductJoinableSectionals
						imageScale={imageScale}
						isDraggable={isDraggable}
						showDimensions={showDimensions}
						workspaceProductJoinableModel={workspaceProductJoinableModelE}
						workspaceProductModel={workspaceProductModel}
						workspaceProductPixelsHeight={workspaceProductPixelsHeight}
						workspaceProductPixelsWidth={workspaceProductPixelsWidth}
					/>
				)
			}
		</>
	);
});
