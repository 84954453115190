import {
	action, computed, observable, makeObservable,
} from 'mobx';

import { apiUrl } from '~/global/global.constants';
import { getProductAvailabilityMessage } from '~/product/common/summary/summary.utils';
import { formatCurrency } from '~/util/formatCurrency';

class Summary {
	addToCartAction;

	addToCartResponse;

	availability;

	deliveryMessages;

	deliveryRateMessages;

	hasError;

	isAddToCartDone;

	isAddToCartInProcess;

	isLoading;

	isMaxQuantity;

	productModel;

	quantity;

	reflektionSkuKey;

	friendlyUrl;

	productUrl;

	constructor() {
		makeObservable(this, {
			addToCartAction: observable,
			addToCartResponse: observable,
			availability: observable,
			deliveryMessages: observable,
			deliveryRateMessages: observable,
			hasError: observable,
			isAddToCartDone: observable,
			isAddToCartInProcess: observable,
			isLoading: observable,
			isMaxQuantity: observable,
			productModel: observable,
			quantity: observable,
			reflektionSkuKey: observable,
			paramsSerializer: action.bound,
			apiConfiguration: computed,
			availabilityMessage: computed,
			isUpsDeliveryGroup: computed,
			isValidQuantity: computed,
			price: computed,
			finalPrice: computed,
			originalPrice: computed,
			isValidSummary: computed,
			isLargeOrder: computed,
			priceText: computed,
			shippingMethod: computed,
			showQuantityNotAvailableError: computed,
			summaryParams: computed,
			addProductsToCartRequestDto: computed,
		});
	}

	get apiConfiguration() {
		return {
			method: 'get',
			payload: {
				params: this.summaryParams,
				paramsSerializer: this.paramsSerializer,
			},
			uri: apiUrl + this.productModel.summaryHref,
		};
	}

	get availabilityMessage() {
		return this.availability ? getProductAvailabilityMessage(this.availability) : '';
	}

	paramsSerializer(paramsToSerialize) {
		return Object.entries(paramsToSerialize).map(([key, value]) => {
			if (Array.isArray(value)) {
				return value.map((subValue) => {
					return `${key}=${encodeURIComponent(subValue)}`;
				}).join('&');
			}
			if (value?.split?.(':')?.length > 1) {
				const [articleNumber, quantity] = value.split(':');

				return `${key}=${articleNumber}&quantity=${quantity}`;
			}
			return `${key}=${encodeURIComponent(value)}`;
		}).join('&');
	}

	get isUpsDeliveryGroup() {
		return /^UP(S|\d)$/.test(this.shippingMethod);
	}

	get isValidQuantity() {
		return this.quantity > 0 && this.quantity <= this.productModel.maxQty && !this.showQuantityNotAvailableError;
	}

	get price() {
		return this.productModel.price;
	}

	get finalPrice() {
		return this.price;
	}

	get originalPrice() {
		return this.productModel.originalPrice;
	}

	get isValidSummary() {
		return !this.isLoading && this.isValidQuantity && !this.showQuantityNotAvailableError;
	}

	get isLargeOrder() {
		return this.quantity > this.productModel.maxQty;
	}

	get priceText() {
		if (this.hasError) {
			return 'Call for pricing';
		}

		return this.finalPrice ? formatCurrency(this.finalPrice, true) : '';
	}

	get shippingMethod() {
		return this.productModel?.delivery?.shippingMethod;
	}

	get showQuantityNotAvailableError() {
		const hasAvailability = Boolean(this.availability);
		const hasAvailabilityDate = hasAvailability && Boolean((this.availability.inStockDate || this.availability.availableDate));

		return this.isLargeOrder || (hasAvailability ? (!this.availability.inStock && !hasAvailabilityDate) : false);
	}

	get summaryParams() {
		const {
			quantity,
			productModel: {
				articleNumber,
				delivery: { shippingMethod },
			},
			friendlyUrl,
		} = this;

		return {
			articleNumber: `${articleNumber}:${quantity}`,
			shippingMethod,
			friendlyPath: friendlyUrl,
		};
	}

	// // TODO: figure out the proper request data
	// get addProductsToFavoritesRequestDto() {
	// 	const {
	// 		articleNumber,
	// 	} = this.productModel;

	// 	return {
	// 		addFavoritesToFavoritesPayload: {
	// 			friendlyPath: this.friendlyUrl,
	// 			quantity: this.quantity,
	// 			productGroupKey: this.productGroupKey,
	// 			product: {
	// 				articleNumber,
	// 				salesText: this.salesText,
	// 				configuration: [

	// 				],
	// 			},
	// 		},
	// 		productUrl: this.productUrl,
	// 		productRequestDtos: [{
	// 			availabilityMessage: this.availability,
	// 		}],
	// 	};
	// }

	// TODO: figure out the proper request data
	get addProductsToCartRequestDto() {
		const {
			articleNumber,
		} = this.productModel;

		return {
			friendlyPath: this.friendlyUrl,
			productUrl: this.productUrl,
			productRequestDtos: [{
				articleNumber,
				availabilityMessage: this.availability,
			}],
			quantity: this.quantity,
		};
	}
}

export const SummaryModelFactory = ({
	create: (productModel, friendlyUrl = '', productUrl = '', featureTogglesModel = {}) => {
		const summary = new Summary();

		summary.featureTogglesModel = featureTogglesModel;
		summary.productModel = productModel;
		summary.quantity = 1;
		summary.hasError = false;
		summary.isMaxQuantity = false;
		summary.showPromoPricing = true;
		summary.displaySummaryLink = false;
		summary.reflektionSkuKey = null;
		summary.availability = {
			discontinued: true,
			inStock: true,
		};

		summary.friendlyUrl = friendlyUrl;
		summary.productUrl = productUrl;

		return summary;
	},
});
