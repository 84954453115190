import { SelectorFacetModelFactory } from '~/product/common/selector/Models/SelectorFacet.model';
import { SelectorFilterModelFactory } from '~/product/common/selector/Models/SelectorFilter.model';
import { SelectorFiltersModelFactory } from '~/product/common/selector/Models/SelectorFilters.model';

export const createSelectorFiltersCasegoodsModel = ({
	filtersData = {},
	parentSelectorModel = {},
	selectorValues = [],
}) => {
	const {
		heights: heightsData = [],
		widths: widthsData = [],
	} = filtersData;

	const facetsHeightModel = heightsData.map(heightData => SelectorFacetModelFactory.create({
		...heightData,
		isInclusive: true,
		parentSelectorModel,
		parentType: 'heights',
		selectorValues,
		showMatchCount: false,
		title: heightData,
	}));

	const facetsWidthModel = widthsData.map(widthData => SelectorFacetModelFactory.create({
		...widthData,
		isInclusive: true,
		parentSelectorModel,
		parentType: 'widths',
		selectorValues,
		showMatchCount: false,
		title: widthData,
	}));

	facetsHeightModel.forEach((facetHeightModel) => {
		const filteredFacetsHeightModel = facetsHeightModel.filter(facetModel => facetModel !== facetHeightModel);

		facetHeightModel.matchingFacetsModels = [...filteredFacetsHeightModel];
		facetHeightModel.nonMatchingFacetsModels = [...facetsWidthModel];
	});

	facetsWidthModel.forEach((facetWidthModel) => {
		const filteredFacetsWidthModel = facetsWidthModel.filter(facetModel => facetModel !== facetWidthModel);

		facetWidthModel.matchingFacetsModels = [...filteredFacetsWidthModel];
		facetWidthModel.nonMatchingFacetsModels = [...facetsHeightModel];
	});

	const filterHeightModel = SelectorFilterModelFactory.create({
		facetsColumnCount: facetsHeightModel.length > 5 ? 2 : 1,
		facetsModel: facetsHeightModel,
		sort: 0,
		title: 'Height',
		type: 'heights',
	});

	const filterWidthModel = SelectorFilterModelFactory.create({
		facetsModel: facetsWidthModel,
		sort: 1,
		title: 'Width',
		type: 'widths',
	});

	// only send along filterModels with facets
	const filterModels = [
		filterHeightModel,
		filterWidthModel,
	].filter(({ facetsModel = [] }) => Boolean(facetsModel.length));

	const selectorFiltersModel = SelectorFiltersModelFactory.create({
		filterModels,
		parentSelectorModel,
		selectorValues,
	});

	return selectorFiltersModel;
};
