import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { SelectorFiltersTabccordion } from '~/product/common/selector/Components/filters/SelectorFiltersTabccordion';

import styles from '#/product/standard/selector/selector-filters.module.scss';

export const SelectorFilters = observer((props) => {
	const {
		filtersMagicTabccordionStore = {},
		filtersMagicTabccordionStore: {
			activeTab,
		} = {},
		filtersModel: {
			hasActiveFilters = false,
			hasFilters = false,
			isSingleFilter = false,
		} = {},
		filtersStore = {},
		legendClass = undefined,
		noBorder = false,
		hideClearFiltersButton = false,
		useSingleColumn = false,
	} = props;

	const { selectorConfigStore = {} } = useProductContext() || {};

	if (!hasFilters) {
		return null;
	}

	function handleClick() {
		if (selectorConfigStore.clearAllFilters) {
			selectorConfigStore.clearAllFilters();
		} else {
			filtersStore.clearAllFilters();
		}
	}

	return (
		<div
			className={
				classNames(styles['selector-filters'], {
					[styles['selector-filters-single']]: isSingleFilter,
					[styles['selector-filters-single-column']]: useSingleColumn,
				})
			}
		>
			<fieldset className={styles['selector-filters-fieldset']}>
				<div className={styles['selector-filters-heading']}>
					<legend className={classNames(styles['selector-filters-heading-text'], {
						[legendClass]: legendClass,
					})}>Filter by:</legend>
					{
						!hideClearFiltersButton && hasActiveFilters && !isSingleFilter && !activeTab && (
							<button
								className="selector-filters-heading-button ButtonAnchor"
								data-tr-link-event-track={false}
								data-qa="filters-clear-button"
								onClick={handleClick}
							>
								Clear all selections
							</button>
						)
					}
				</div>
				<div className={styles['selector-filters-tabccordion']}>
					<SelectorFiltersTabccordion filtersMagicTabccordionStore={filtersMagicTabccordionStore} noBorder={noBorder} />
				</div>
			</fieldset>
		</div>
	);
});
