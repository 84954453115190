import classNames from 'classnames';
import { observer } from 'mobx-react';
import NextHead from 'next/head';
import Script from 'next/script';
import React, { useEffect, useRef, useState } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { isOnServer } from '~/global/global.constants';
import { MediaCylindoARClose } from '~/product/common/media-set/Components/MediaCylindoARClose';
import { MediaCylindoDimensions } from '~/product/common/media-set/Components/MediaCylindoDimensions';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';

import styles from '#/product/common/media-set/media-cylindo.module.scss';

export const MediaCylindo = observer((props = {}) => {
	const {
		forceLoad = false,
		hideMediaCylindoARClose = false,
		mediaControlCylindoARRef = {},
		mediaCylindoId = 'media-cylindo',
		mediaCylindoModel = {},
		mediaCylindoModel: {
			cylindoFeatures = [],
			cylindoProductCode = '',
			hasCylindo = false,
			isCylindoActive = false,
			isCylindoARActive = false,
			isCylindoDimensionsActive = false,
		} = {},
		mediaCylindoStore = {},
		mediaSetStore = {},
		selectorConfigModel: {
			hasCylindoData = false,
		} = {},
		tooltipDragText = 'Drag to rotate',
		tooltipZoomText = '',
		zoom = false,
	} = props;

	const {
		pageStore: {
			isMediaQueryMd = false,
		} = {},
	} = useGlobalContext();

	const viewerInstanceRef = useRef();

	const cylindoAREnabledRef = useRef();

	const [cylindoLoaded, setCylindoLoaded] = useState(!isOnServer && window.cylindo);

	const shouldLoad = !cylindoLoaded && (forceLoad || isCylindoActive || isCylindoARActive);

	function handleLoad() {
		setCylindoLoaded(true);
	}

	function loadCylindoAR() {
		let attempts = 0;

		const cylindoARInterval = setInterval(() => {
			const cylindoARButtonElement = document.querySelector('.cylindo-ar-button a');

			// programmatically trigger a touchend event to make android happy
			const touchEndEvent = new Event('touchend');
			if (cylindoARButtonElement) {
				clearInterval(cylindoARInterval);

				// this Cyldindo element has a mind of its own, and tries to set a previous value for eVar200
				if (window.s) {
					window.s.eVar200 = undefined;
				}

				if (!isMediaQueryMd) {
					mediaCylindoStore.setIsCylindoARActive(false);
				}

				cylindoARButtonElement.dispatchEvent(touchEndEvent);
			}

			attempts++;

			if (attempts >= 5) {
				clearInterval(cylindoARInterval);
			}
		}, 250);
	}

	function loadViewer() {
		const options = {
			accountID: 4951,
			ARDesktop: true,
			containerID: mediaCylindoId,
			features: cylindoFeatures,
			fullscreen: false,
			missingCombinationErrorText: '',
			productCode: cylindoProductCode,
			thumbs: false,
			tooltipDragText,
			tooltipZoomText,
			zoom,
		};

		function loadCylindoViewer() {
			window.cylindo.on('ready', (() => {
				viewerInstanceRef.current = window.cylindo.viewer.create(options);

				// the AR button can take a moment to become present in the DOM
				if (isCylindoARActive) {
					loadCylindoAR();
				}
			}));
		}

		// retry system for fullscreen
		if (window.cylindo) {
			loadCylindoViewer();
		} else {
			setTimeout(() => {
				if (window.cylindo) {
					loadCylindoViewer();
				}
			}, 500);
		}
	}

	function destroyViewer() {
		if (viewerInstanceRef.current) {
			viewerInstanceRef.current.destroy();

			viewerInstanceRef.current = null;
		}
	}

	function updateViewer() {
		if (hasCylindo && (forceLoad || isCylindoActive || isCylindoARActive)) {
			loadViewer();
		} else {
			destroyViewer();
		}
	}

	useEffect(updateViewer, [cylindoLoaded, isCylindoActive, isCylindoARActive]);

	useEffect(() => destroyViewer, []);

	if (isCylindoDimensionsActive) {
		return (
			<MediaCylindoDimensions mediaCylindoModel={mediaCylindoModel} />
		);
	}

	return (
		<>
			<div className={styles['media-cylindo-container']}>
				<div
					className={
						classNames(styles['media-cylindo'], {
							[styles['media-cylindo-ar']]: isCylindoARActive,
						})
					}
					data-qa="media-cylindo"
					id={mediaCylindoId}
				/>
				{
					!hideMediaCylindoARClose && isCylindoARActive && (
						<MediaCylindoARClose
							mediaControlCylindoARRef={mediaControlCylindoARRef}
							mediaCylindoStore={mediaCylindoStore}
							mediaSetStore={mediaSetStore}
						/>
					)
				}
				{
					cylindoLoaded && (
						<span
							className="tw-hidden"
							data-qa="cylindo-loaded"
						/>
					)
				}
				{
					hasCylindoData && (
						<button
							className="tw-hidden"
							data-event121
							data-qa="cylindo-ar-enabled"
							data-tr-link-event-name="cylindo ar view"
							data-tr-link-event-track={isCylindoARActive ? false : null}
							data-tr-link-event-type={LinkEventTypes.SITE_ACTION}
							ref={cylindoAREnabledRef}
						>
							Load AR Media
						</button>
					)
				}
			</div>
			{
				shouldLoad && (
					<Script
						src="https://viewer.cylindo.com/v4/viewer.min.js"
						strategy="lazyOnload"
						onLoad={handleLoad}
					/>
				)
			}
			{
				cylindoLoaded && (
					<NextHead>
						<link id="cylindo-styles" rel="stylesheet" href="https://viewer.cylindo.com/v4/viewer.min.css" />
					</NextHead>
				)
			}
		</>
	);
});
