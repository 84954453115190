import { observer } from 'mobx-react';
import React from 'react';

import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector.module.scss';

export const SelectorHeadingOptions = observer((props) => {
	const {
		selectedSelector: {
			label = '',
		} = {},
	} = props;

	return (
		<div
			className="selector-heading-options selector-heading"
			data-qa={`${kebabCase(label)}-selector-heading`}
			data-selector-heading-options={true}
		>
			<div className="selector-heading-label-container">
				<SelectorHeadingLabel label={label} />
			</div>
			<span
				className={styles['selector-heading-title']}
				data-qa={`${kebabCase(label)}-selector-heading-title`}
			>
				Extra shelves, cord openings and more.
			</span>
		</div>
	);
});
