import { computed, makeObservable, observable } from 'mobx';

import { s7ImagePath } from '~/global/global.constants';

class MediaProductSectionals {
	captionOverride

	fileName

	index

	selected

	selectorConfigModel

	type

	constructor() {
		makeObservable(this, {
			caption: computed,
			hasCaption: computed,
			imageUrl: computed,
			mediaImageUrl: computed,
			productAltText: computed,
			productImageUrl: computed,
			selected: observable,
		});
	}

	get caption() {
		const {
			selectedProduct: {
				caption = '',
			} = {},
		} = this.selectorConfigModel;

		return this.captionOverride || caption;
	}

	get hasCaption() {
		const { productsModel = [] } = this.selectorConfigModel;

		return Boolean(this.captionOverride) || productsModel.some(({ caption = '' }) => caption);
	}

	get imageUrl() {
		return `${s7ImagePath}/${this.fileName}`;
	}

	get mediaImageUrl() {
		return this.productImageUrl;
	}

	get productAltText() {
		const {
			selectedProduct: {
				longSalesText = '',
			} = {},
		} = this.selectorConfigModel;

		return longSalesText || this.caption;
	}

	get productImageUrl() {
		const {
			selectedProduct: {
				drapeableImageUrl = '',
			} = {},
		} = this.selectorConfigModel;

		return drapeableImageUrl || this.imageUrl;
	}

	get productImageUrlWithDimensions() {
		return this.productImageUrl || this.imageUrl;
	}
}

export const MediaProductSectionalsModelFactory = ({
	create: ({
		caption: captionOverride = '',
		fileName = '',
		index = 0,
		selected = false,
		selectorConfigModel = {},
		type = 'PRODUCT',
	}) => {
		const mediaProductSectionals = new MediaProductSectionals();

		Object.assign(mediaProductSectionals, {
			captionOverride,
			fileName,
			index,
			selected,
			selectorConfigModel,
			type,
		});

		return mediaProductSectionals;
	},
});
