import React from 'react';
import { useRouter } from 'next/router';
import { reaction } from 'mobx';

import { createMagicDeliveryModel, createMagicDeliveryStore } from '~/components/magic-delivery/Helpers/MagicDelivery.init';
import { MagicPagerStore } from '~/components/magic-pager/Stores/MagicPager.store';
import { MagicTabccordionStore } from '~/components/magic-tabccordion/Stores/magicTabccordion.store';
import { DeliveryMainStoreFactory } from '~/delivery/Stores/DeliveryMain.store';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import {
	curalateProductDataSourceId,
	curalateProductFanreelId,
	isEngage,
	isOnServer,
} from '~/global/global.constants';
import { createProductGroupModel } from '~/product/common/Helpers/ProductGroup.init';
import { createProductModels } from '~/product/common/product/Helpers/Product.init';
import { createMediaSetModel } from '~/media-set/Helpers/MediaSet.init';
import { createMediaSetDetailModel } from '~/product/common/media-set/Helpers/MediaSetDetail.init';
import { MediaCylindoModelFactory } from '~/product/common/media-set/Models/MediaCylindo.model';
import { MediaCylindoStoreFactory } from '~/product/common/media-set/Stores/MediaCylindo.store';
import { MediaSetStoreFactory } from '~/media-set/Stores/MediaSet.store';
import { ProductCommonsModelFactory } from '~/product/common/Models/ProductCommons.model';
import { createSelectorConfigMultiModels, createSelectorConfigMultiStores } from '~/product/multi/selector/Helpers/SelectorConfigMulti.init';
import { createSummaryMultiModels, createSummaryMultiStores } from '~/product/multi/summary/Helpers/SummaryMulti.init';
import { SummaryMultiModelFactory } from '~/product/multi/summary/Models/SummaryMulti.model';
import { SummaryMultiStoreFactory } from '~/product/multi/summary/Stores/SummaryMulti.store';
import { generateDetailsTabData } from '~/product/common/Data/tabs.data';
import { ErrorModals } from '~/product/common/errors/Components/ErrorModals';
import { ErrorsModel } from '~/product/common/errors/Models/Errors.model';
import { createSelectorConfigModel } from '~/product/common/selector/Helpers/SelectorConfig.init';
import { ConfigurationOverrideModelFactory } from '~/product/common/selector/Models/ConfigurationOverride.model';
import { createSelectorMagicTabccordionStore } from '~/product/common/selector/Helpers/SelectorTabccordion.init';
import { SelectorConfigStoreFactory } from '~/product/common/selector/Stores/SelectorConfig.store';
import { BreadcrumbModel } from '~/layout/Models/Breadcrumb.model';
import { PowerReviewsStoreFactory } from '~/util/power-reviews/Stores/PowerReviews.store';
import { CuralateStoreFactory } from '~/util/curalate/Stores/Curalate.store';
import { createProductRecoStore } from '~/product/common/Helpers/ProductRecos.init';
import { ProductEventsTrackingStoreFactory } from '~/product/common/Stores/ProductEvents.tracking.store';
import { TrackingDebugModeFactory } from '~/tracking/debug/Tracking.debugMode';
import { ReviewEventTrackingStore } from '~/tracking/review-event/Stores/ReviewEvent.tracking.store';

export const productMultiContext = (pageProps) => {
	const router = useRouter();

	const {
		pageType,
		printModule,
		productPageModel,
	} = pageProps;

	const {
		breadcrumbs,
		collectionSearchSegment,
		configurationOverride: configurationOverrideData,
		defaultProduct: defaultProductData = {},
		productGroup,
		productGroup: {
			dimensionMediaSet = [],
			environmentMediaSet = [],
			videoMediaSet = [],
		} = {},
		// productPlaceholders: productPlaceholdersData = [],
		products: productsData = [],
		productSelector = {},
		query: {
			article = '',
			category = '',
			subcategory = '',
			limit,
			product = '',
			error = '',
		},
	} = productPageModel;

	const relativeProductGroupPath = `/catalog/${category}/${subcategory}/${product}`;

	const {
		globalDynamicModel,
		globalDynamicStore,
		globalStaticModel,
		magicModal,
		cookies,
		featureTogglesModel,
		linkEventStore,
		pageStore,
		HREF,
	} = useGlobalContext();

	// TODO: include configurationOverride.defaultProduct in the API response
	const configurationOverrideDefaultProductData = productsData.find(({ articleNumber = '' }) => articleNumber === article);

	const configurationOverrideModel = ConfigurationOverrideModelFactory.create({
		...configurationOverrideData,
		defaultProduct: configurationOverrideDefaultProductData,
	});

	const productGroupModel = createProductGroupModel(productGroup);

	const errorsModel = new ErrorsModel(error);

	const productModels = createProductModels({
		configurationOverrideModel,
		defaultProductData,
		productsData,
		relativeProductGroupPath,
	});

	const selectorConfigModel = createSelectorConfigModel({
		configurationOverrideModel,
		defaultProductData,
		productModels,
		productSelector,
		relativeProductGroupPath,
	});

	const selectorConfigStore = SelectorConfigStoreFactory.create({
		productGroupModel,
		selectorConfigModel,
	});

	const selectorConfigModels = createSelectorConfigMultiModels({
		configurationOverrideModel,
		productModels,
		productSelector,
		relativeProductGroupPath,
		selectorConfigModel,
	});

	const selectorConfigStores = createSelectorConfigMultiStores({
		productGroupModel,
		selectorConfigModels,
	});

	const selectorMagicTabccordionStore = createSelectorMagicTabccordionStore(selectorConfigModel);

	const {
		selectedProduct: {
			urlSuffix = 'empty',
		},
	} = selectorConfigModel;

	const breadcrumbModels = breadcrumbs.map((breadcrumb) => {
		return new BreadcrumbModel(breadcrumb);
	});

	const categoryBreadcrumb = breadcrumbModels.find(breadcrumbModel => breadcrumbModel.isCategory);
	const categoryName = categoryBreadcrumb ? categoryBreadcrumb.pageTitle || categoryBreadcrumb.title : '';
	const subcategoryBreadcrumb = breadcrumbModels.find(breadcrumbModel => breadcrumbModel.isSubcategory);
	const subcategoryName = subcategoryBreadcrumb ? subcategoryBreadcrumb.pageTitle || subcategoryBreadcrumb.title : '';
	const lastBreadcrumbUrl = breadcrumbModels[breadcrumbModels.length - 1]?.url;
	const defaultProductUrl = `${lastBreadcrumbUrl}/${urlSuffix}`;

	const mediaCylindoModel = MediaCylindoModelFactory.create({
		productGroupModel,
		selectorConfigModel,
	});

	const mediaCylindoStore = MediaCylindoStoreFactory.create({
		mediaCylindoModel,
		selectorConfigModel,
	});

	const mediaSetDetailModel = createMediaSetDetailModel({
		productGroup,
		selectorConfigModel,
	});

	const mediaSetDetailStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetDetailModel });

	const mediaSetEnvironmentModel = createMediaSetModel({ mediaSetData: environmentMediaSet });

	const mediaSetEnvironmentStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetEnvironmentModel });

	const mediaSetVideoModel = createMediaSetModel({ mediaSetData: videoMediaSet, featureTogglesModel });

	const mediaSetVideoStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetVideoModel });

	const mediaSetDimensionsModel = createMediaSetModel({
		disableClick: true,
		mediaSetData: dimensionMediaSet,
	});

	const mediaSetDimensionsStore = MediaSetStoreFactory.create({ mediaSetModel: mediaSetDimensionsModel });

	const summaryModels = createSummaryMultiModels({
		globalDynamicStore,
		productGroupModel,
		selectorConfigModels,
	});

	const trackingDebugMode = TrackingDebugModeFactory.create(featureTogglesModel);

	const summaryStores = createSummaryMultiStores({
		featureTogglesModel,
		globalDynamicStore,
		magicModal,
		pageStore,
		summaryModels,
		HREF,
		trackingDebugMode,
	});

	const magicDeliveryModels = summaryModels.map((summaryModel) => {
		return createMagicDeliveryModel({
			summaryModel,
			trLinkEventCompType: 'product summary',
			trLinkEventCompName: productGroupModel.name,
		});
	});

	const magicDeliveryStores = summaryStores.map((summaryStore, index) => {
		return createMagicDeliveryStore({
			featureTogglesModel,
			globalDynamicStore,
			magicDeliveryModel: magicDeliveryModels[index],
			summaryStore,
		});
	});

	const summaryModel = SummaryMultiModelFactory.create({
		breadcrumbModels,
		productGroupModel,
		summaryModels,
	});

	const productTrackingStore = ProductEventsTrackingStoreFactory.create(summaryModel, featureTogglesModel);

	const summaryStore = SummaryMultiStoreFactory.create({
		featureTogglesModel,
		globalDynamicStore,
		globalStaticModel,
		magicModal,
		pageStore,
		productTrackingStore,
		summaryModel,
		summaryStores,
		HREF,
		trackingDebugMode,
	});

	const magicDeliveryModel = createMagicDeliveryModel({ summaryModel });

	const magicDeliveryStore = createMagicDeliveryStore({
		featureTogglesModel,
		globalDynamicStore,
		magicDeliveryModel,
		summaryStore,
	});

	const tabData = generateDetailsTabData();
	const reviewsMagicTabccordionStore = new MagicTabccordionStore(tabData, linkEventStore);
	const powerReviewsStore = PowerReviewsStoreFactory.create(selectorConfigModel, productGroupModel, featureTogglesModel);

	const recoItemWidth = 212;
	const recoItemHeight = 224;
	const recoPageWidth = recoItemWidth;
	const recoPageHeight = recoItemHeight;
	let productRecosStore;
	let productRecosPagerModel;

	if (!isEngage) {
		productRecosStore = createProductRecoStore(
			'rfkid_32',
			router.asPath,
			{
				globalDynamicModel,
				globalStaticModel,
				featureTogglesModel,
			},
			{
				sku: null,
				title: 'You May Also Like',
				analytics: {
					pageId: 'product',
				},
				maxItems: 18,
				forceNonSkuImage: true,
			},
		);

		productRecosPagerModel = new MagicPagerStore(recoPageWidth, recoPageHeight, recoItemWidth, recoItemHeight);
	}

	const productRecentsStore = createProductRecoStore(
		'rfkid_33',
		'',
		{
			globalDynamicModel,
			globalStaticModel,
			featureTogglesModel,
		},
		{
			sku: [],
			title: 'Recently Viewed',
			analytics: {
				pageId: 'product2_recently_viewed',
			},
			maxItems: 18,
		},
	);

	const productRecentsPagerModel = new MagicPagerStore(recoPageWidth, recoPageHeight, recoItemWidth, recoItemHeight);

	if (!isOnServer && productRecosStore) {
		const rfkSkuReaction = reaction(
			() => summaryModel.reflektionSkuKeys.slice(),
			(reflektionSkuKeys) => {
				const value = Array.isArray(reflektionSkuKeys) ? reflektionSkuKeys : [reflektionSkuKeys];
				productRecosStore.model.setSku(value);
				rfkSkuReaction();
			},
		);
	}

	const curalateStore = CuralateStoreFactory.create(cookies, magicModal, featureTogglesModel, {
		dataSourceId: curalateProductDataSourceId,
		experienceId: 'product-page-experience',
		fanreelId: curalateProductFanreelId,
		filter: `productId:${productGroupModel.ugcProductGroupId}`,
		limit,
		itemsPerPage: 5,
	});

	const productCommons = ProductCommonsModelFactory.create({
		breadcrumbModels,
		printModule,
		productGroupModel,
		summaryModel,
		selectorConfigModel,
		collectionSearchSegment,
		type: pageType,
	});

	const deliveryMainStore = DeliveryMainStoreFactory.create(productCommons, globalDynamicStore);

	if (!isOnServer && errorsModel.componentKey) {
		const Component = ErrorModals[errorsModel.componentKey];

		if (Component) {
			magicModal.openModal({
				id: 'product-error-modal',
				title: errorsModel.modalTitle,
				maxWidth: '600px',
				content: {
					children: (
						<Component
							productGroupModel={productGroupModel}
							selectorConfigModel={selectorConfigModel}
							goBackFunction={magicModal.closeModal}
						/>
					),
				},
			});
		}
	}

	if (!isOnServer && !isEngage) {
		const reviewEventTracking = new ReviewEventTrackingStore();
		const {
			reviewProductGroupKey = '',
			reviewsAverageOverallRating = 0,
			reviewsCount = 0,
		} = productGroupModel;
		reviewEventTracking.trackReviewStats(reviewProductGroupKey, reviewsAverageOverallRating, reviewsCount);
	}

	const context = {
		breadcrumbModels,
		categoryName,
		curalateStore,
		defaultProductUrl,
		deliveryMainStore,
		errorsModel,
		magicDeliveryModel,
		magicDeliveryModels,
		magicDeliveryStore,
		magicDeliveryStores,
		mediaCylindoModel,
		mediaCylindoStore,
		mediaSetDetailModel,
		mediaSetDetailStore,
		mediaSetEnvironmentModel,
		mediaSetEnvironmentStore,
		mediaSetDimensionsModel,
		mediaSetDimensionsStore,
		mediaSetVideoModel,
		mediaSetVideoStore,
		mediaViewerEnvironmentData: environmentMediaSet,
		productPageModel,
		productGroupModel,
		powerReviewsStore,
		productCommons,
		productRecentsStore,
		productRecentsPagerModel,
		productRecosPagerModel,
		productRecosStore,
		productTrackingStore,
		reviewsMagicTabccordionStore,
		selectorConfigModel,
		selectorConfigModels,
		selectorConfigStore,
		selectorConfigStores,
		selectorMagicTabccordionStore,
		subcategoryName,
		summaryModel,
		summaryModels,
		summaryStore,
		summaryStores,
	};

	return context;
};

export const ProductMultiContextProvider = ({
	pageProps = {},
	pageProps: {
		children,
	} = {},
	productContext = {},
}) => {
	return <productContext.Provider value={productMultiContext(pageProps)}>{children}</productContext.Provider>;
};
