import { observer } from 'mobx-react';
import React from 'react';
import { toWords } from 'number-to-words';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { kebabCase } from '~/util/kebabCase';
import { upperFirst } from '~/util/upperFirst';

import styles from '~/product/sectionals/selector/Components/selector.module.scss';

export const SelectorHeadingLayout = observer((props) => {
	const {
		isActive = true,
		selectedSelector: {
			label = '',
			selectorValuesCount = 0,
		} = {},
	} = props;

	const {
		workspaceModel: {
			firstWorkspaceProductModel,
			hasWorkspaceProductModels = false,
			workspaceProductModels = [],
			workspaceProductsDepth = 0,
			workspaceProductsWidth = 0,
		} = {},
	} = useProductContext();

	const {
		productModel: {
			shortSalesText = '',
		} = {},
	} = firstWorkspaceProductModel || {};

	const titleText = !isActive && hasWorkspaceProductModels
		? `${workspaceProductsWidth}"w ${workspaceProductsDepth}"d ${
			workspaceProductModels.length > 1
				? `${upperFirst(toWords(workspaceProductModels.length))}-Piece Sectional`
				: shortSalesText
		}`
		: 'Drag and drop pieces';
	const mobileTitle = isActive ? '&nbsp;' : titleText;

	return (
		<div
			className="selector-heading"
			data-qa={`${kebabCase(label)}-selector-heading`}
		>
			<div className="selector-heading-label-container">
				<SelectorHeadingLabel label={label} />
				{!isActive &&
					<span
						className={styles['selector-heading-count']}
						data-qa={`${kebabCase(label)}-selector-heading-count`}
					>
						{selectorValuesCount} {selectorValuesCount === 1 ? 'option' : 'options'}
					</span>
				}
			</div>
			<span
				className={styles['selector-heading-title']}
				data-qa={`${kebabCase(label)}-selector-heading-title`}
			>
				<span className="tw-hidden md:tw-inline">
					{titleText}
				</span>
				<span
					className="tw-hidden sm:tw-inline"
					dangerouslySetInnerHTML={{ __html: mobileTitle }}
				>
				</span>
			</span>
		</div>
	);
});
