import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { kebabCase } from '~/util/kebabCase';
import styles from '#/product/standard/selector/selector-filters.module.scss';

export const SelectorFiltersHeading = observer((props) => {
	const {
		filterModel: {
			hasSelectedFacets = false,
			selectedFacetsCount = 0,
			title = '',
			type = '',
		},
		isActive = false,
	} = props;

	return (
		<div
			className={
				classNames(styles['selector-filters-tabccordion-tab-heading'], {
					'selector-filters-tabccordion-tab-heading-selected': hasSelectedFacets,
				})
			}
		>
			<span data-qa={`${kebabCase(type)}-filter-heading`}>{title}</span>
			{
				!isActive && hasSelectedFacets && (
					<span data-qa={`${kebabCase(type)}-filter-heading-count`}> ({selectedFacetsCount})</span>
				)
			}
		</div>
	);
});
