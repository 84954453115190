import { ReviewStatsEvent } from '~/tracking/review-event/Models/ReviewEvent.tracking';

export class ReviewEventTrackingStore {
	trackReviewStats(groupKey, average, count) {
		const reviewStatsTrackingModel = new ReviewStatsEvent(
			groupKey,
			average,
			count,
		);

		this.pushToEventDataLayer(reviewStatsTrackingModel.trackingJson);
	}

	pushToEventDataLayer(json) {
		window.eventDataLayer = window.eventDataLayer || [];
		window.eventDataLayer.push(json);
	}
}
