import React from 'react';
import axios from 'axios';

import { FavoritesTrackingEventsStore } from '~/favorites/Stores/FavoritesEvents.tracking.store';

import { AddToFavoritesStore } from '~/favorites/add/Stores/AddToFavorites.store';
import { createFavoriteSummaries } from '~/favorites/list/Factories/FavoritesSummary.factory';
import { apiUrl, isEngage } from '~/global/global.constants';
import { AddedToFavoritesModal } from '~/product/common/interstitial/favorites/Components/AddedToFavorites.modal';
import { SelectAFavoriteList } from '~/product/common/interstitial/favorites/Components/SelectAFavoritesList';

export const getFavoritesInfoAndAddToFavoritesStore = (
	addToFavoritesHref: string,
	productDTO: any,
	productInfo: any,
	successCallback: Function | undefined,
	errorHandler: Function | undefined,
	featureTogglesModel: any,
	globalDynamicStore: any,
	magicModal: any,
	onCloseFocusElement: any,
) => {
	const {
		isCustomerGuest,
	} = globalDynamicStore.model;

	const promise = new Promise((resolve, reject) => {
		axios
			.get(`${apiUrl}/api/favorites`)
			.then((response) => {
				const {
					data = {},
				} = response;

				const lists = createFavoriteSummaries(data);
				const store = new AddToFavoritesStore({
					addToFavoritesAPI: addToFavoritesHref,
					lists,
					productDTO,
					trackingEventsStore: new FavoritesTrackingEventsStore({}),
				});

				store.featureTogglesModel = featureTogglesModel;
				store.magicModal = magicModal;
				store.successCallback = resolve;
				store.failureCallback = reject;

				if (isCustomerGuest) {
					const defaultList = store.lists.find((summary) => {
						return summary.defaultList;
					});

					if (defaultList) {
						store.updateSelectedState(defaultList.id, true);
						store.submitHandler();

						magicModal.openModal({
							id: 'added-to-favorites-modal',
							title: 'Added to Favorites',
							maxWidth: '500px',
							onCloseFocusElement,
							closeModalOnOverlayClick: isEngage,
							showCloseButton: true,
							trLinkEventCompName: 'Added to Favorites',
							content: {
								children: (
									<AddedToFavoritesModal
										store={store}
										productInfo={productInfo}
									/>
								),
							},
						});
					}
				} else {
					magicModal.openModal({
						id: 'added-to-favorites-modal',
						title: 'Choose Your List(s)',
						maxWidth: '500px',
						onCloseFocusElement,
						closeModalOnOverlayClick: false,
						showCloseButton: false,
						content: {
							children: (
								<SelectAFavoriteList
									store={store}
								/>
							),
						},
					});
				}

				if (successCallback) {
					successCallback(store, onCloseFocusElement);
				}
			}, ({
				response: {
					data: { errors = [] },
				},
			}) => {
				console.error(errors);

				if (errorHandler) {
					errorHandler(onCloseFocusElement, errors);
				}
			});
	});

	return promise;
};
