import { RefObject } from 'react';

import { addToUrl } from '~/util/addToUrl';
import { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';
import {
	defaultDownloadImageWorkspaceSectionals, defaultImageScaleWorkspaceSectionals, imageInchesToPixelsFactor, imageSettingsWorkspaceSectionals,
} from '~/product/sectionals/workspace/workspace-sectionals.constants';
import { ICanvasOffset, IGroupedWorkspaceImages, IWorkspaceCanvasImage } from '~/product/sectionals/selector/Interfaces/SelectorDownload.interface';
import { downloadImage } from '~/product/sectionals/selector/Utils/download.utils';
import { imageRotationMap } from '~/product/sectionals/workspace/Components/product/WorkspaceProductImageSectionals';

export const horizontalDimensionLineYOffset = 20;
export const verticalDimensionLineTextOffset = 30;
export const dimensionLineTickOffset = 10;
export const tickWidth = 1;
export const tickHeight = 4;

export const createWorkspaceImage = (src: string): HTMLImageElement => {
	const workspaceImage = new Image();

	workspaceImage.crossOrigin = 'Anonymous';
	workspaceImage.src = src;

	return workspaceImage;
};

export const getWorkspaceCanvasImages = async (sortedWorkspaceProducts: IWorkspaceProductSectionals[], imageScale: number): Promise<IWorkspaceCanvasImage[]> => {
	const workspaceImagePromises: Promise<IWorkspaceCanvasImage>[] = sortedWorkspaceProducts.map((workspaceProduct) => {
		return new Promise((resolve) => {
			const { productModel: { imageUrl = '' } = {}, orientation } = workspaceProduct;
			const imageRotation = imageRotationMap[orientation as keyof typeof imageRotationMap];
			const workspaceImageSource = addToUrl(imageUrl, `$proddd$&scl=${imageScale}&rotate=${imageRotation}`);
			const workspaceImage = createWorkspaceImage(workspaceImageSource);

			const handleWorkspaceImageLoad = () => {
				workspaceImage.removeEventListener('load', handleWorkspaceImageLoad);
				resolve({
					workspaceImage,
					workspaceProduct,
				});
			};

			workspaceImage.addEventListener('load', handleWorkspaceImageLoad);
		});
	});

	const workspaceImages = await Promise.all(workspaceImagePromises);

	return workspaceImages;
};

export const resetWorkspaceCanvasDimensions = (workspaceCanvas: RefObject<HTMLCanvasElement>): void => {
	if (workspaceCanvas.current) {
		workspaceCanvas.current.width = 0;
		workspaceCanvas.current.height = 0;
	}
};

export const getCanvasOffset = (
	workspaceProducts: IWorkspaceProductSectionals[],
	workspaceProductStartKey: 'workspaceProductYStart' | 'workspaceProductXStart',
): number => {
	return workspaceProducts.reduce((canvasOffset: number, currentWorkspaceProduct: IWorkspaceProductSectionals): number => {
		if (currentWorkspaceProduct[workspaceProductStartKey] < canvasOffset) {
			return currentWorkspaceProduct[workspaceProductStartKey];
		}

		return canvasOffset;
	}, 0);
};

export const getCanvasOffsetValues = (workspaceProducts: IWorkspaceProductSectionals[]): ICanvasOffset => {
	return {
		canvasOffsetX: getCanvasOffset(workspaceProducts, 'workspaceProductXStart'),
		canvasOffsetY: getCanvasOffset(workspaceProducts, 'workspaceProductYStart'),
	};
};

export const translateCanvasOrigin = (
	context: CanvasRenderingContext2D,
	workspaceCanvas: RefObject<HTMLCanvasElement>,
	canvasOffset: ICanvasOffset,
	imageScale: number,
): void => {
	if (!workspaceCanvas.current) {
		return;
	}

	let canvasX = 0;
	let canvasY = 0;
	const hasNegativeXOffset = canvasOffset.canvasOffsetX < 0;
	const hasNegativeYOffset = canvasOffset.canvasOffsetY < 0;

	if (hasNegativeXOffset) {
		canvasX = (workspaceCanvas.current.width / imageScale) - Math.abs(canvasOffset.canvasOffsetX);
	}

	if (hasNegativeYOffset) {
		canvasY = (workspaceCanvas.current.height / imageScale) - Math.abs(canvasOffset.canvasOffsetY);
	}

	context.translate(canvasX / 2, canvasY / 2);
};

export const stretchCanvas = (
	workspaceCanvas: RefObject<HTMLCanvasElement>,
	xMargin: number,
	yMargin: number,
	workspaceProductsDepth: number,
	workspaceProductsWidth: number,
	imageScale: number,
): void => {
	resetWorkspaceCanvasDimensions(workspaceCanvas);

	if (workspaceCanvas.current) {
		workspaceCanvas.current.width = (workspaceProductsWidth * imageInchesToPixelsFactor) / imageScale;
		workspaceCanvas.current.height = (workspaceProductsDepth * imageInchesToPixelsFactor) / imageScale;
		workspaceCanvas.current.width += xMargin * 2;
		workspaceCanvas.current.height += yMargin * 2;
	}
};

export const drawBackground = (workspaceCanvas: RefObject<HTMLCanvasElement>, context: CanvasRenderingContext2D): void => {
	context.fillStyle = '#FFFFFF';

	if (workspaceCanvas.current) {
		context.fillRect(0, 0, workspaceCanvas.current.width, workspaceCanvas.current.height);
	}
};

export const calculateImageMargin = (currentMargin: number, imageStart: number, imageScale: number): number => {
	return currentMargin + (imageStart * imageInchesToPixelsFactor) / imageScale;
};

export const drawHorizontalDimensionLineTickMarks = (context: CanvasRenderingContext2D, lineToX: number, lineToY: number, imageScale: number): void => {
	const lineYOffsetTo = lineToY - (dimensionLineTickOffset / imageScale);
	const lineYOffsetFrom = lineToY + (dimensionLineTickOffset / imageScale);

	context.beginPath();
	context.moveTo(lineToX, lineYOffsetTo - tickWidth + tickHeight);
	context.lineTo(lineToX, lineYOffsetTo + tickWidth - tickHeight);
	context.stroke();
	context.moveTo(lineToX, lineYOffsetFrom + tickWidth + tickHeight);
	context.lineTo(lineToX, lineYOffsetFrom - tickWidth - tickHeight);
	context.stroke();
};

export const drawVerticalDimensionLineTickMarks = (context: CanvasRenderingContext2D, lineToY: number, lineToX: number, imageScale: number): void => {
	const lineXOffsetTo = lineToX - (dimensionLineTickOffset / imageScale);
	const lineXOffsetFrom = lineToX + (dimensionLineTickOffset / imageScale);

	context.beginPath();
	context.moveTo(lineXOffsetTo - tickWidth - tickHeight, lineToY);
	context.lineTo(lineXOffsetTo + tickWidth + tickHeight, lineToY);
	context.stroke();
	context.moveTo(lineXOffsetFrom + tickWidth + tickHeight, lineToY);
	context.lineTo(lineXOffsetFrom - tickWidth - tickHeight, lineToY);
	context.stroke();
};

export const drawDimensionLine = (context: CanvasRenderingContext2D, moveToX: number, moveToY: number, lineToX: number, lineToY: number): void => {
	context.fillStyle = '#000000';
	context.lineWidth = 1;
	context.beginPath();
	context.moveTo(moveToX, moveToY);
	context.lineTo(lineToX, lineToY);
	context.stroke();
};

export const groupImagesByStartorEndValue = (
	workspaceImages: IWorkspaceCanvasImage[],
	startOrEndKey: 'workspaceProductYStart' | 'workspaceProductYEnd' | 'workspaceProductXEnd' | 'workspaceProductXStart',
): IGroupedWorkspaceImages => {
	return workspaceImages.reduce((groupedImages: IGroupedWorkspaceImages, currentImage): IGroupedWorkspaceImages => {
		const startOrEndValue = currentImage.workspaceProduct[startOrEndKey];
		const currentImagesGroupedByStartOrEndValue = groupedImages[startOrEndValue]?.value || [];

		return {
			...groupedImages,
			[startOrEndValue]: {
				value: [...currentImagesGroupedByStartOrEndValue, currentImage],
			},
		};
	}, {});
};

export const calculateDimensionLineStart = (
	workspaceImages: IWorkspaceCanvasImage[],
	startOrEndKey: 'workspaceProductXStart' | 'workspaceProductYStart',
): number => {
	const imageStartValues = workspaceImages.map(workspaceImage => workspaceImage.workspaceProduct[startOrEndKey]);

	return Math.min(...imageStartValues);
};

export const calculateDimensionLineEnd = (
	workspaceImages: IWorkspaceCanvasImage[],
	startOrEndKey: 'workspaceProductXEnd' | 'workspaceProductYEnd',
): number => {
	const imageEndValues = workspaceImages.map(workspaceImage => workspaceImage.workspaceProduct[startOrEndKey]);

	return Math.max(...imageEndValues);
};

export const shouldDrawHorizonalDimensionLine = (sortedWorkspaceImageYStartValues: string[], imageYStart: number): boolean => {
	const firstImageYStart = +(sortedWorkspaceImageYStartValues.at(0) || 0);
	const lastImageYStart = +(sortedWorkspaceImageYStartValues.at(-1) || 0);
	const isFirstImageYStart = imageYStart === firstImageYStart;
	const isLastImageYStart = imageYStart === lastImageYStart;

	return isFirstImageYStart || isLastImageYStart;
};

export const shouldDrawVerticalDimensionLine = (sortedWorkspaceImageXStartValues: string[], imageXStart: number): boolean => {
	const firstImageXStart = +(sortedWorkspaceImageXStartValues.at(0) || 0);
	const lastImageXStart = +(sortedWorkspaceImageXStartValues.at(-1) || 0);
	const isFirstImageXStart = imageXStart === firstImageXStart;
	const isLastImageXStart = imageXStart === lastImageXStart;

	return isFirstImageXStart || isLastImageXStart;
};

export const sortImageStartValuesAscending = (imagesGroupedByStartValue: IGroupedWorkspaceImages): string[] => {
	return Object.keys(imagesGroupedByStartValue).sort((start1: string, start2: string) => +start1 - +start2);
};

export const drawDimensionLineText = (context: CanvasRenderingContext2D): void => {
	context.textBaseline = 'bottom';
	context.textAlign = 'center';
	context.font = '300 15px proxima-nova';
};

export const drawHorizontalDimensionLineText = (
	workspaceImages: IWorkspaceCanvasImage[],
	imageScale: number,
	context: CanvasRenderingContext2D,
	xMargin: number,
	yMargin: number,
	dimensionLineXStart: number,
): void => {
	const workspaceImageXEndValues = workspaceImages.map(workspaceImage => workspaceImage.workspaceProduct.workspaceProductXEnd);
	const dimensionLineXEnd = Math.max(...workspaceImageXEndValues);
	const dimensionLineWidth = dimensionLineXEnd - dimensionLineXStart;
	const dimensionLineWidthInPixels = ((dimensionLineWidth) * imageInchesToPixelsFactor) / imageScale;

	const workspaceProductsWidth = workspaceImages.reduce((width, workspaceImage) => {
		return width + workspaceImage.workspaceProduct.workspaceProductWidth;
	}, 0);

	if (workspaceProductsWidth < dimensionLineWidth) {
		return;
	}

	const dimensionLineTextXOffset = Math.max(...workspaceImageXEndValues) - workspaceProductsWidth;
	const dimensionLineTextXOffsetInPixels = xMargin + ((dimensionLineTextXOffset * imageInchesToPixelsFactor) / imageScale);

	drawDimensionLineText(context);
	context.fillText(`${dimensionLineWidth}"`, dimensionLineTextXOffsetInPixels + (dimensionLineWidthInPixels / 2), yMargin);
};

export const drawVerticalDimensionLineText = (
	workspaceImages: IWorkspaceCanvasImage[],
	imageScale: number,
	context: CanvasRenderingContext2D,
	xMargin: number,
	yMargin: number,
): void => {
	const workspaceImageYEndValues = workspaceImages.map(workspaceImage => workspaceImage.workspaceProduct.workspaceProductYEnd);
	const dimensionLineWidth = Math.max(...workspaceImageYEndValues);
	const dimensionLineWidthInPixels = (dimensionLineWidth * imageInchesToPixelsFactor) / imageScale;

	const workspaceProductsDepth = workspaceImages.reduce((width, workspaceImage) => {
		return width + workspaceImage.workspaceProduct.workspaceProductDepth;
	}, 0);

	if (workspaceProductsDepth < dimensionLineWidth) {
		return;
	}

	const hasWidth = dimensionLineWidth > 0;
	const dimensionLineText = hasWidth ? workspaceProductsDepth : Math.abs(Math.max(...workspaceImageYEndValues) - workspaceProductsDepth);
	const dimensionLineTextYOffset = (Math.max(...workspaceImageYEndValues) - workspaceProductsDepth) / 2;
	const dimensionLineTextXYOffsetInPixels = yMargin + ((dimensionLineTextYOffset * imageInchesToPixelsFactor) / imageScale);

	drawDimensionLineText(context);
	context.fillText(`${dimensionLineText}"`, xMargin - 15, dimensionLineTextXYOffsetInPixels + (dimensionLineWidthInPixels / 2));
};

export const getLastImageStartOrEndValue = (groupedImagesByStartOrEndValue: IGroupedWorkspaceImages): number => {
	const sortedImageStartOrEndValues = sortImageStartValuesAscending(groupedImagesByStartOrEndValue);
	const lastImageStartOrEndValue = +(sortedImageStartOrEndValues.at(-1) || 0);

	return lastImageStartOrEndValue;
};

export const getFirstImageStartOrEndValue = (groupedImagesByStartOrEndValue: IGroupedWorkspaceImages): number => {
	const sortedImageStartOrEndValues = sortImageStartValuesAscending(groupedImagesByStartOrEndValue);
	const firstImageStartOrEndValue = +(sortedImageStartOrEndValues.at(0) || 0);

	return firstImageStartOrEndValue;
};

export const drawHorizontalLineTickMarks = (
	image: IWorkspaceCanvasImage,
	dimensionLineXEnd: number,
	dimensionLineXStart: number,
	xMarginEnd: number,
	xMarginStart: number,
	yMargin: number,
	imageScale: number,
	context: CanvasRenderingContext2D,
) => {
	if (image.workspaceProduct.workspaceProductXEnd === dimensionLineXEnd) {
		drawHorizontalDimensionLineTickMarks(context, xMarginEnd, yMargin, imageScale);
	}

	if (image.workspaceProduct.workspaceProductXStart === dimensionLineXStart) {
		drawHorizontalDimensionLineTickMarks(context, xMarginStart, yMargin, imageScale);
	}
};

export const drawVerticalLineTickMarks = (
	image: IWorkspaceCanvasImage,
	dimensionLineYEnd: number,
	dimensionLineYStart: number,
	yMarginEnd: number,
	yMarginStart: number,
	xMargin: number,
	imageScale: number,
	context: CanvasRenderingContext2D,
) => {
	if (image.workspaceProduct.workspaceProductYEnd === dimensionLineYEnd) {
		drawVerticalDimensionLineTickMarks(context, yMarginEnd, xMargin, imageScale);
	}

	if (image.workspaceProduct.workspaceProductYStart === dimensionLineYStart) {
		drawVerticalDimensionLineTickMarks(context, yMarginStart, xMargin, imageScale);
	}
};

export const isSingleImageInWorkspace = (workspaceImages: IWorkspaceCanvasImage[]): boolean => {
	return workspaceImages.length === 1;
};

export const hasMultipleImageStartOrEndValues = (groupedImagesByStartOrEndValue: IGroupedWorkspaceImages): boolean => {
	return Object.keys(groupedImagesByStartOrEndValue).length > 1;
};

export const drawHorizontalDimensionLines = (
	context: CanvasRenderingContext2D,
	imageScale: number,
	xMargin: number,
	workspaceImages: IWorkspaceCanvasImage[],
	yMargin: number,
	workspaceOrientation: keyof typeof imageRotationMap,
): void => {
	const workspaceImagesGroupedByYStart = groupImagesByStartorEndValue(workspaceImages, 'workspaceProductYStart');
	const sortedWorkspaceImageYStartValues = sortImageStartValuesAscending(workspaceImagesGroupedByYStart);
	const workspaceImagesGroupedByYEnd = groupImagesByStartorEndValue(workspaceImages, 'workspaceProductYEnd');
	const lastImageYEnd = getLastImageStartOrEndValue(workspaceImagesGroupedByYEnd);
	const isSingleWorkspaceImage = isSingleImageInWorkspace(workspaceImages);
	const hasSingleYStartAndSingleYEnd = Object.keys(workspaceImagesGroupedByYEnd).length === 1 && Object.keys(workspaceImagesGroupedByYStart).length === 1;

	Object.entries(workspaceImagesGroupedByYEnd).forEach(([workspaceImageYEnd, groupedImages]) => {
		const groupedImageValues = groupedImages.value;
		const imageYEnd = +workspaceImageYEnd;
		const yMarginEnd = calculateImageMargin(yMargin, imageYEnd, imageScale) + horizontalDimensionLineYOffset;
		const isLastImageYEnd = imageYEnd === lastImageYEnd;
		const dimensionLineXStart = calculateDimensionLineStart(groupedImageValues, 'workspaceProductXStart');
		const dimensionLineXEnd = calculateDimensionLineEnd(groupedImageValues, 'workspaceProductXEnd');
		let lineWidth = 0;

		groupedImageValues.every((image, index): boolean => {
			const xMarginStart = calculateImageMargin(xMargin, image.workspaceProduct.workspaceProductXStart, imageScale);
			const xMarginEnd = calculateImageMargin(xMargin, image.workspaceProduct.workspaceProductXEnd, imageScale);
			const isWidthOrDepthWorkspaceProductModel = image.workspaceProduct.isWidthWorkspaceProductModel || image.workspaceProduct.isDepthWorkspaceProductModel;
			const shouldDrawDimensionLine = image.workspaceProduct.showDimensionsOrientations.includes('S') && isWidthOrDepthWorkspaceProductModel;

			const nextImage: IWorkspaceCanvasImage | undefined = groupedImageValues[index + 1];
			const isJoinedWithNextImage = image.workspaceProduct.workspaceProductXStart === nextImage?.workspaceProduct?.workspaceProductXEnd;
			const { orientation } = image.workspaceProduct;

			if (isSingleWorkspaceImage) {
				if (orientation === 'S') {
					drawDimensionLine(context, xMarginStart, yMarginEnd, xMarginEnd, yMarginEnd);

					const lineWidthInPixels = ((image.workspaceProduct.workspaceProductWidth) * imageInchesToPixelsFactor) / imageScale;

					drawDimensionLineText(context);
					context.fillText(`${image.workspaceProduct.workspaceProductWidth}"`, xMarginStart + (lineWidthInPixels / 2), yMarginEnd);
					drawHorizontalLineTickMarks(image, dimensionLineXEnd, dimensionLineXStart, xMarginEnd, xMarginStart, yMarginEnd, imageScale, context);
				}

				return true;
			}

			if (hasSingleYStartAndSingleYEnd) {
				if (workspaceOrientation !== 'N') {
					drawDimensionLine(context, xMarginStart, yMarginEnd, xMarginEnd, yMarginEnd);

					lineWidth += image.workspaceProduct.workspaceProductWidth;

					if (!isJoinedWithNextImage) {
						const lineWidthInPixels = ((lineWidth) * imageInchesToPixelsFactor) / imageScale;

						drawDimensionLineText(context);
						context.fillText(`${lineWidth}"`, xMarginStart + (lineWidthInPixels / 2), yMarginEnd);
						drawHorizontalLineTickMarks(image, dimensionLineXEnd, dimensionLineXStart, xMarginEnd, xMarginStart, yMarginEnd, imageScale, context);

						lineWidth = 0;
					}
				}

				return true;
			}

			if (isLastImageYEnd) {
				if (groupedImageValues.length >= 1) {
					drawDimensionLine(context, xMarginStart, yMarginEnd, xMarginEnd, yMarginEnd);

					lineWidth += image.workspaceProduct.workspaceProductWidth;

					if (!isJoinedWithNextImage) {
						const lineWidthInPixels = ((lineWidth) * imageInchesToPixelsFactor) / imageScale;

						drawDimensionLineText(context);
						context.fillText(`${lineWidth}"`, xMarginStart + (lineWidthInPixels / 2), yMarginEnd);
						drawHorizontalLineTickMarks(image, dimensionLineXEnd, dimensionLineXStart, xMarginEnd, xMarginStart, yMarginEnd, imageScale, context);

						lineWidth = 0;
					}
				}

				return true;
			}

			if (shouldDrawDimensionLine) {
				drawDimensionLine(context, xMarginStart, yMarginEnd, xMarginEnd, yMarginEnd);
				drawHorizontalDimensionLineTickMarks(context, xMarginEnd, yMarginEnd, imageScale);
				drawHorizontalDimensionLineTickMarks(context, xMarginStart, yMarginEnd, imageScale);

				const dimensionLineWidth = image.workspaceProduct.workspaceProductWidth;
				const dimensionLineWidthInPixels = ((dimensionLineWidth) * imageInchesToPixelsFactor) / imageScale;

				drawDimensionLineText(context);
				context.fillText(`${dimensionLineWidth}"`, xMarginStart + (dimensionLineWidthInPixels / 2), yMarginEnd);
			}

			return true;
		});
	});

	Object.entries(workspaceImagesGroupedByYStart).forEach(([workspaceImageYStart, groupedImages]) => {
		const groupedImageValues = groupedImages.value;
		const imageYStart = +workspaceImageYStart;
		const yMarginStart = calculateImageMargin(yMargin, imageYStart, imageScale) - horizontalDimensionLineYOffset;
		const lastImageYStart = +(sortedWorkspaceImageYStartValues.at(-1) || 0);
		const isLastImageYStart = imageYStart === lastImageYStart;
		const shouldDrawDimensionLine = shouldDrawHorizonalDimensionLine(sortedWorkspaceImageYStartValues, imageYStart);
		const dimensionLineXStart = calculateDimensionLineStart(groupedImageValues, 'workspaceProductXStart');
		const dimensionLineXEnd = calculateDimensionLineEnd(groupedImageValues, 'workspaceProductXEnd');

		groupedImageValues.every((image): boolean => {
			const xMarginStart = calculateImageMargin(xMargin, image.workspaceProduct.workspaceProductXStart, imageScale);
			const xMarginEnd = calculateImageMargin(xMargin, image.workspaceProduct.workspaceProductXEnd, imageScale);
			const yMarginEnd = calculateImageMargin(yMargin, image.workspaceProduct.workspaceProductYEnd, imageScale) + horizontalDimensionLineYOffset;
			const toYValue = isLastImageYStart && sortedWorkspaceImageYStartValues.length > 1 ? yMarginEnd : yMarginStart;
			const { orientation } = image.workspaceProduct;

			if (toYValue === yMarginEnd) {
				return true;
			}

			if (isSingleWorkspaceImage) {
				if (orientation !== 'S') {
					drawDimensionLine(context, xMarginStart, toYValue, xMarginEnd, toYValue);
					drawHorizontalLineTickMarks(image, dimensionLineXEnd, dimensionLineXStart, xMarginEnd, xMarginStart, toYValue, imageScale, context);
					drawHorizontalDimensionLineText(groupedImageValues, imageScale, context, xMargin, toYValue, dimensionLineXStart);
				}

				return true;
			}

			if (hasSingleYStartAndSingleYEnd) {
				if (workspaceOrientation !== 'S') {
					drawDimensionLine(context, xMarginStart, toYValue, xMarginEnd, toYValue);
					drawHorizontalLineTickMarks(image, dimensionLineXEnd, dimensionLineXStart, xMarginEnd, xMarginStart, toYValue, imageScale, context);
					drawHorizontalDimensionLineText(groupedImageValues, imageScale, context, xMargin, toYValue, dimensionLineXStart);
				}

				return true;
			}

			if (shouldDrawDimensionLine) {
				drawDimensionLine(context, xMarginStart, toYValue, xMarginEnd, toYValue);
				drawHorizontalLineTickMarks(image, dimensionLineXEnd, dimensionLineXStart, xMarginEnd, xMarginStart, toYValue, imageScale, context);
				drawHorizontalDimensionLineText(groupedImageValues, imageScale, context, xMargin, toYValue, dimensionLineXStart);
			}

			return true;
		});
	});
};

export const drawVerticalDimensionLines = (
	context: CanvasRenderingContext2D,
	imageScale: number,
	xMargin: number,
	workspaceImages: IWorkspaceCanvasImage[],
	yMargin: number,
	workspaceOrientation: keyof typeof imageRotationMap,
): void => {
	const imagesGroupedByXStart = groupImagesByStartorEndValue(workspaceImages, 'workspaceProductXStart');
	const sortedImagesXStart = sortImageStartValuesAscending(imagesGroupedByXStart);
	const imagesGroupedByXEnd = groupImagesByStartorEndValue(workspaceImages, 'workspaceProductXEnd');
	const isSingleWorkspaceImage = isSingleImageInWorkspace(workspaceImages);
	const hasSingleXStartAndXEnd = Object.keys(imagesGroupedByXStart).length === 1 && Object.keys(imagesGroupedByXEnd).length === 1;

	Object.entries(imagesGroupedByXStart).forEach(([workspaceImageXStart, groupedImages]) => {
		const groupedImageValues = groupedImages.value;
		const imageXStart = +workspaceImageXStart;
		const dimensionLineYStart = calculateDimensionLineStart(groupedImageValues, 'workspaceProductYStart');
		const dimensionLineYEnd = calculateDimensionLineEnd(groupedImageValues, 'workspaceProductYEnd');
		let dimensionLineWidth = 0;
		let yStartValue = dimensionLineYStart;

		groupedImageValues.every((image, index): boolean => {
			const yMarginStart = calculateImageMargin(yMargin, image.workspaceProduct.workspaceProductYStart, imageScale);
			const yMarginEnd = calculateImageMargin(yMargin, image.workspaceProduct.workspaceProductYEnd, imageScale);
			const xMarginStart = calculateImageMargin(xMargin, +imageXStart, imageScale) - verticalDimensionLineTextOffset;
			const xMarginEnd = calculateImageMargin(xMargin, image.workspaceProduct.workspaceProductXEnd, imageScale) + verticalDimensionLineTextOffset;

			const lastImageXStart = +(sortedImagesXStart.at(-1) || 0);
			const isLastImageXStart = imageXStart === lastImageXStart;
			const toXValue = isLastImageXStart && sortedImagesXStart.length > 1 ? xMarginEnd : xMarginStart;
			const shouldDrawDimensionLine = shouldDrawVerticalDimensionLine(sortedImagesXStart, imageXStart);
			const firstImageXStart = +(sortedImagesXStart.at(0) || 0);
			const isFirstImageXStart = imageXStart === firstImageXStart;
			const nextImage: IWorkspaceCanvasImage | undefined = groupedImageValues[index + 1];
			const isJoinedWithNextImage = image.workspaceProduct.workspaceProductYEnd >= nextImage?.workspaceProduct?.workspaceProductYStart;
			const { orientation } = image.workspaceProduct;

			if (image.workspaceProduct.showDimensionsOrientations.includes('W') && groupedImageValues.length === 1) {
				drawDimensionLine(context, xMarginStart, yMarginStart, xMarginStart, yMarginEnd);
				dimensionLineWidth += image.workspaceProduct.workspaceProductDepth;

				if (!isJoinedWithNextImage) {
					const dimensionLineWidthInPixels = ((dimensionLineWidth) * imageInchesToPixelsFactor) / imageScale;
					const dimensionLineYStartInPixels = (yStartValue * imageInchesToPixelsFactor) / imageScale;

					context.fillText(`${dimensionLineWidth}"`, xMarginStart - 15, yMargin + dimensionLineYStartInPixels + (dimensionLineWidthInPixels / 2));
					drawVerticalLineTickMarks(image, dimensionLineYEnd, dimensionLineYStart, yMarginEnd, yMarginStart, xMarginStart, imageScale, context);

					dimensionLineWidth = 0;
					yStartValue = image.workspaceProduct.workspaceProductYStart;
				}
			}

			if (toXValue === xMarginEnd) {
				return true;
			}

			if (isSingleWorkspaceImage) {
				if (orientation !== 'E') {
					drawDimensionLine(context, toXValue, yMarginStart, toXValue, yMarginEnd);
					drawVerticalDimensionLineText(groupedImageValues, imageScale, context, toXValue, yMargin);
					drawVerticalLineTickMarks(image, dimensionLineYEnd, dimensionLineYStart, yMarginEnd, yMarginStart, toXValue, imageScale, context);
				}

				return true;
			}

			if (hasSingleXStartAndXEnd) {
				if (workspaceOrientation !== 'E') {
					drawDimensionLine(context, toXValue, yMarginStart, toXValue, yMarginEnd);
					drawVerticalDimensionLineText(groupedImageValues, imageScale, context, toXValue, yMargin);
					drawVerticalLineTickMarks(image, dimensionLineYEnd, dimensionLineYStart, yMarginEnd, yMarginStart, toXValue, imageScale, context);
				}

				return true;
			}

			if ((shouldDrawDimensionLine || isFirstImageXStart)) {
				drawDimensionLine(context, toXValue, yMarginStart, toXValue, yMarginEnd);
				drawVerticalDimensionLineText(groupedImageValues, imageScale, context, toXValue, yMargin);
				drawVerticalLineTickMarks(image, dimensionLineYEnd, dimensionLineYStart, yMarginEnd, yMarginStart, toXValue, imageScale, context);
			}

			return true;
		});
	});

	Object.entries(imagesGroupedByXEnd).forEach(([workspaceImageXEnd, groupedImagesByXEnd]) => {
		const groupedImageValuesByXEnd = groupedImagesByXEnd.value;
		const imageXEnd = +workspaceImageXEnd;
		const yStartDimensionLine = calculateDimensionLineStart(groupedImageValuesByXEnd, 'workspaceProductYStart');
		const yEndDimensionLine = calculateDimensionLineEnd(groupedImageValuesByXEnd, 'workspaceProductYEnd');
		const lastImageXEnd = getLastImageStartOrEndValue(imagesGroupedByXEnd);
		const isLastImageXEnd = lastImageXEnd === imageXEnd;
		let dimensionLineWidth = 0;
		let yStartValue = yStartDimensionLine;

		groupedImageValuesByXEnd.every((image, index): boolean => {
			const yMarginStart = calculateImageMargin(yMargin, image.workspaceProduct.workspaceProductYStart, imageScale);
			const yMarginEnd = calculateImageMargin(yMargin, image.workspaceProduct.workspaceProductYEnd, imageScale);
			const xMarginEnd = calculateImageMargin(xMargin, imageXEnd, imageScale) + verticalDimensionLineTextOffset;
			const shouldDrawDimensionLine = image.workspaceProduct.showDimensionsOrientations.includes('E') && !image.workspaceProduct.isWidthWorkspaceProductModel;
			const dimensionLineDepth = image.workspaceProduct.workspaceProductDepth;
			const dimensionLineDepthInPixels = ((dimensionLineDepth) * imageInchesToPixelsFactor) / imageScale;
			const nextImage: IWorkspaceCanvasImage | undefined = groupedImageValuesByXEnd[index + 1];
			const isJoinedWithNextImage = image.workspaceProduct.workspaceProductYStart <= nextImage?.workspaceProduct?.workspaceProductYEnd;

			if (isLastImageXEnd && groupedImageValuesByXEnd.length > 1 && !hasSingleXStartAndXEnd) {
				drawDimensionLine(context, xMarginEnd, yMarginStart, xMarginEnd, yMarginEnd);
				dimensionLineWidth += image.workspaceProduct.workspaceProductDepth;

				if (!isJoinedWithNextImage) {
					const dimensionLineWidthInPixels = ((dimensionLineWidth) * imageInchesToPixelsFactor) / imageScale;

					drawDimensionLineText(context);
					context.fillText(`${dimensionLineWidth}"`, xMarginEnd + horizontalDimensionLineYOffset, yMargin + (dimensionLineWidthInPixels / 2));
					drawVerticalLineTickMarks(image, yEndDimensionLine, yStartDimensionLine, yMarginEnd, yMarginStart, xMarginEnd, imageScale, context);

					dimensionLineWidth = 0;
				}

				return true;
			}

			if ((image.workspaceProduct.showDimensionsOrientations.includes('E') && Object.keys(imagesGroupedByXEnd).length > 1)) {
				drawDimensionLine(context, xMarginEnd, yMarginStart, xMarginEnd, yMarginEnd);

				if (nextImage && isJoinedWithNextImage) {
					dimensionLineWidth = nextImage.workspaceProduct.workspaceProductYEnd;
				} else {
					dimensionLineWidth = image.workspaceProduct.workspaceProductYEnd - yStartValue;
					const dimensionLineWidthInPixels = ((dimensionLineWidth) * imageInchesToPixelsFactor) / imageScale;
					const dimensionLineYStartInPixels = (yStartValue * imageInchesToPixelsFactor) / imageScale;

					context.fillText(`${dimensionLineWidth}"`, xMarginEnd - 15, yMargin + dimensionLineYStartInPixels + (dimensionLineWidthInPixels / 2));
					drawVerticalDimensionLineTickMarks(context, yMarginEnd, xMarginEnd, imageScale);

					if (image.workspaceProduct.workspaceProductYStart === yStartValue) {
						drawVerticalDimensionLineTickMarks(context, yMarginStart, xMarginEnd, imageScale);
					}

					dimensionLineWidth = 0;
					yStartValue = image.workspaceProduct.workspaceProductYStart;
				}

				return true;
			}

			if (!isLastImageXEnd) {
				return true;
			}

			if (isSingleWorkspaceImage) {
				if (workspaceOrientation === 'E') {
					drawDimensionLine(context, xMarginEnd, yMarginStart, xMarginEnd, yMarginEnd);
					context.fillText(`${dimensionLineDepth}"`, xMarginEnd + horizontalDimensionLineYOffset, yMarginStart + (dimensionLineDepthInPixels / 2));
					drawVerticalLineTickMarks(image, yEndDimensionLine, yStartDimensionLine, yMarginEnd, yMarginStart, xMarginEnd, imageScale, context);
				}

				return true;
			}

			if (hasSingleXStartAndXEnd) {
				if (workspaceOrientation !== 'W') {
					drawDimensionLine(context, xMarginEnd, yMarginStart, xMarginEnd, yMarginEnd);
					dimensionLineWidth += image.workspaceProduct.workspaceProductDepth;

					if (!isJoinedWithNextImage) {
						const dimensionLineWidthInPixels = ((dimensionLineWidth) * imageInchesToPixelsFactor) / imageScale;

						drawDimensionLineText(context);
						context.fillText(`${dimensionLineWidth}"`, xMarginEnd + horizontalDimensionLineYOffset, yMargin + (dimensionLineWidthInPixels / 2));
						drawVerticalLineTickMarks(image, yEndDimensionLine, yStartDimensionLine, yMarginEnd, yMarginStart, xMarginEnd, imageScale, context);

						dimensionLineWidth = 0;
					}
				}

				return true;
			}

			if ((shouldDrawDimensionLine || (isLastImageXEnd && groupedImageValuesByXEnd.length === 1))) {
				drawDimensionLine(context, xMarginEnd, yMarginStart, xMarginEnd, yMarginEnd);
				context.fillText(`${dimensionLineDepth}"`, xMarginEnd + horizontalDimensionLineYOffset, yMarginStart + (dimensionLineDepthInPixels / 2));
				drawVerticalLineTickMarks(image, yEndDimensionLine, yStartDimensionLine, yMarginEnd, yMarginStart, xMarginEnd, imageScale, context);
			}

			return true;
		});
	});
};

export const drawImages = (canvasImages: IWorkspaceCanvasImage[], context: CanvasRenderingContext2D, xMargin: number, yMargin: number, imageScale: number): void => {
	canvasImages.forEach((canvasImage) => {
		const { workspaceImage, workspaceProduct } = canvasImage;
		const workspaceImageXMargin = calculateImageMargin(xMargin, workspaceProduct.workspaceProductXStart, imageScale);
		const workspaceImageYMargin = calculateImageMargin(yMargin, workspaceProduct.workspaceProductYStart, imageScale);

		context.drawImage(workspaceImage, workspaceImageXMargin, workspaceImageYMargin, workspaceImage.width, workspaceImage.height);
	});
};

export const saveWorkspaceImage = async (
	workspaceCanvas: RefObject<HTMLCanvasElement>,
	sortedWorkspaceProducts: IWorkspaceProductSectionals[],
	workspaceProductsDepth: number,
	workspaceProductsWidth: number,
	workspaceOrientation: keyof typeof imageRotationMap,
): Promise<void> => {
	const imageSettings = defaultDownloadImageWorkspaceSectionals;
	const { defaultImageScale } = imageSettingsWorkspaceSectionals.medium;
	const workspaceCanvasImages = await getWorkspaceCanvasImages(sortedWorkspaceProducts, defaultImageScale);
	const canvasOffset = getCanvasOffsetValues(sortedWorkspaceProducts);

	if (workspaceCanvas.current) {
		const context = workspaceCanvas.current.getContext('2d');
		const { canvas: imageScale = 0 } = defaultImageScaleWorkspaceSectionals;
		const isHorizontal = workspaceProductsWidth > workspaceProductsDepth;
		const xMarginValue = isHorizontal ? 700 : 350;
		const xMargin = Math.ceil(xMarginValue / imageScale);
		const yMargin = Math.ceil(500 / imageScale);

		if (context) {
			context.clearRect(0, 0, workspaceCanvas.current.width, workspaceCanvas.current.height);
			stretchCanvas(workspaceCanvas, xMargin, yMargin, workspaceProductsDepth, workspaceProductsWidth, defaultImageScale);
			drawBackground(workspaceCanvas, context);
			translateCanvasOrigin(context, workspaceCanvas, canvasOffset, imageScale);
			drawImages(workspaceCanvasImages, context, xMargin, yMargin, defaultImageScale);
			drawHorizontalDimensionLines(context, defaultImageScale, xMargin, workspaceCanvasImages, yMargin, workspaceOrientation);
			drawVerticalDimensionLines(context, defaultImageScale, xMargin, workspaceCanvasImages, yMargin, workspaceOrientation);
		}

		const imageBlob = workspaceCanvas.current.toDataURL(imageSettings.mimeType, imageSettings.compression);

		downloadImage(imageBlob, imageSettings.fileName, imageSettings.mimeType);
	}
};
