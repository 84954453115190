import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { s7ImagePath } from '~/global/global.constants';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { addToUrl } from '~/util/addToUrl';
import { kebabCase } from '~/util/kebabCase';
import styles from '#/product/standard/selector/selector-small-image.module.scss';

export const SelectorValueSmallImage = observer((props) => {
	const {
		isActive = false,
		label = '',
		selectorValue,
		selectorValue: {
			image: {
				imageSrc = '',
			},
			selected,
			title = '',
		},
	} = props;

	const { selectorConfigStore } = useProductContext();

	function handleChange() {
		selectorConfigStore.selectSelectorValue(selectorValue);
	}

	return (
		<label
			className={
				classNames(styles['selector-value-small-image'], {
					[styles['selector-value-small-image-selected']]: selected,
				})
			}
			data-qa={`${kebabCase(label)}-selector-value`}
		>
			<S7Image
				alt=""
				className={styles['selector-value-small-image-image']}
				height="80"
				priority={isActive}
				src={addToUrl(`${s7ImagePath}/${imageSrc}`, '$proddd$')}
				width="80"
			/>
			<div className={styles['selector-value-small-image-text']}>
				<span
					className={styles['selector-value-small-image-title']}
					data-qa={`${kebabCase(label)}-selector-value-title`}
				>
					{title}
				</span>
			</div>
			<input
				checked={selected}
				className="tw-sr-only"
				data-tr-link-event-track={false}
				onChange={handleChange}
				type="radio"
			/>
		</label>
	);
});
