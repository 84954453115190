import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { s7ContentPath } from '~/global/global.constants';
import { SelectorValuesGroupMaterial } from '~/product/common/selector/Components/material/SelectorValuesGroupMaterial';
import { getShouldShowShadowX, getShouldShowShadowY } from '~/product/common/selector/Utils/Selector.utils';

import styles from '#/product/standard/selector/selector-material.module.scss';
import stylesSelector from '#/product/standard/selector/selector.module.scss';

export const SelectorMaterial = observer((props) => {
	const {
		isActive = false,
		label = '',
		selectedSelector: {
			selectorValues: parentProductSelectorValues = [],
			spoSelectorValuesCount = 0,
			spoSelectorValuesByPriceGroup = {},
			stockedSelectorValuesByPriceGroup = {},
		} = {},
	} = props;

	// these values MUST match what you have in CSS, and I hate it a whole bunch
	// flexbox columns don't grow their parent width, so we need to figure it out on our own...
	const selectorValueWidth = 82;

	const stockedSelectorValuesByPriceGroupCounts = Object.entries(stockedSelectorValuesByPriceGroup).map(([, selectorValues]) => selectorValues.length);

	const stockedSelectorValuesMaxCount = Math.max(...stockedSelectorValuesByPriceGroupCounts);

	const stockedPriceGroupsBorderTotal = 1;

	const stockedPriceGroupsMarginTotal = 10;

	const stockedPriceGroupsPaddingTotal = 15;

	const stockedSelectorValuesContainerWidth = stockedSelectorValuesMaxCount * selectorValueWidth + stockedPriceGroupsBorderTotal + stockedPriceGroupsMarginTotal + stockedPriceGroupsPaddingTotal;

	const spoPriceGroupPadding = 40;

	const spoSelectorValuesContainerWidth = Math.ceil(spoSelectorValuesCount / 4) * selectorValueWidth;

	const scrollingParentRef = useRef();

	const scrollingChildRef = useRef();

	const messageRef = useRef();

	const [showMessage, setShowMessasge] = useState(true);

	const [showShadowX, setShowShadowX] = useState(false);

	const [showShadowY, setShowShadowY] = useState(false);

	let ticking = false;

	function setShowShadowState() {
		const shouldShowShadowX = getShouldShowShadowX({
			isActive,
			scrollingChildRef,
			scrollingParentRef,
		});

		const shouldShowShadowY = getShouldShowShadowY({
			isActive,
			scrollingChildRef,
			scrollingParentRef,
		});

		setShowShadowX(shouldShowShadowX);

		setShowShadowY(shouldShowShadowY);
	}

	function handleScroll() {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				ticking = false;

				setShowMessasge(false);

				setShowShadowState();
			});

			ticking = true;
		}
	}

	function handleTouchStart() {
		setShowMessasge(false);
	}

	useEffect(setShowShadowState, [isActive]);

	const stockMaterialsCount = Object.keys(stockedSelectorValuesByPriceGroup).reduce((sum, key) => (sum + stockedSelectorValuesByPriceGroup[key].length || 0), 0);
	const spotMaterialsCount = Object.keys(spoSelectorValuesByPriceGroup).reduce((sum, key) => (sum + spoSelectorValuesByPriceGroup[key].length || 0), 0);

	return (
		<div
			className={
				classNames('tw-relative', {
					[styles['selector-material-wrapper-shadow']]: showShadowX,
				})
			}
		>
			<div
				className={`${styles['selector-material-container']}`}
				onScroll={handleScroll}
				onTouchStart={handleTouchStart}
				ref={scrollingParentRef}
			>
				<div
					className={
						classNames(`${styles['selector-material-message']} tw-absolute tw-duration-200 tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-full tw-transition md:tw-hidden`, {
							'tw-opacity-0': !showMessage,
						})
					}
					ref={messageRef}
				>
					<S7Image
						alt=""
						className={styles['selector-material-message-image']}
						height="14"
						src={`${s7ContentPath}/swipe-arrows`}
						width="40"
					/>
					<span className={`${styles['selector-material-message-text']} tw-text-center tw-text-white`}>Swipe for <br />options</span>
				</div>
				<div
					className={
						classNames('selector-material', {
							[styles['selector-material-shadow']]: showShadowY,
						})
					}
					ref={scrollingChildRef}
				>
					<div className={styles['selector-values-types-material']}>
						<span className="tw-sr-only">Choose a {label}</span>
						{
							Object.entries(stockedSelectorValuesByPriceGroup).length > 0 && (
								<div
									className={`${styles['selector-values-type-material']} ${styles['selector-values-type-material-stocked']}`}
									style={{ flexBasis: stockedSelectorValuesContainerWidth }}
								>
									{stockMaterialsCount && <span className={styles['selector-values-type-material-title']}>
										<span className="tw-pr-3">Stocked</span>
										<span className={stylesSelector['selector-heading-count']} data-qa="selector-values-type-material-title-stocked-option-count">{stockMaterialsCount} {stockMaterialsCount === 1 ? 'option' : 'options'}</span>
									</span>
									}
									<div
										className={
											classNames(styles['selector-values-groups-material'], {
												'selector-values-groups-material-single': Object.entries(stockedSelectorValuesByPriceGroup).length === 1,
											})
										}
									>
										{
											Object.entries(stockedSelectorValuesByPriceGroup).map(([priceGroupPrice, selectorValues], index) => {
												return (
													<SelectorValuesGroupMaterial
														isActive={isActive}
														key={`selector-values-group-${index}`}
														label={label}
														parentProductSelectorValues={parentProductSelectorValues}
														priceGroupPrice={priceGroupPrice}
														selectorValues={selectorValues}
														selectorValuesType="Stocked"
													/>
												);
											})
										}
									</div>
								</div>
							)
						}
						{
							Object.entries(spoSelectorValuesByPriceGroup).length > 0 && (
								<div
									className={`${styles['selector-values-type-material']} ${styles['selector-values-type-material-spo']}`}
									style={{ flexBasis: spoSelectorValuesContainerWidth }}
								>
									<span className={styles['selector-values-type-material-title']}>
										<span className="tw-pr-3">Made for you</span>
										<span className={stylesSelector['selector-heading-count']} data-qa="selector-values-type-material-title-spot-option-count">{spotMaterialsCount} {spotMaterialsCount === 1 ? 'option' : 'options'}</span>
									</span>
									<div className={styles['selector-values-groups-material']}>
										{
											Object.entries(spoSelectorValuesByPriceGroup).map(([priceGroupPrice, selectorValues], index) => {
												return (
													<SelectorValuesGroupMaterial
														isActive={isActive}
														key={`selector-values-group-${index}`}
														label={label}
														parentProductSelectorValues={parentProductSelectorValues}
														priceGroupPadding={spoPriceGroupPadding}
														priceGroupPrice={priceGroupPrice}
														selectorValues={selectorValues}
														selectorValuesType="Made for you"
														selectorValueWidth={selectorValueWidth}
													/>
												);
											})
										}
									</div>
								</div>
							)
						}
					</div>
				</div>
			</div>
		</div>
	);
});
