import classNames from 'classnames';
import React from 'react';
import { observer } from 'mobx-react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { recommendationsEnabled } from '~/global/global.constants';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { InterstitialRecos } from '~/product/common/interstitial/Components/InterstitialRecos';
import { formatCurrency } from '~/util/formatCurrency';
import { addToUrl } from '~/util/addToUrl';
import { LineItemMessaging } from '~/util/messaging/line-item/LineItemMessaging';

import styles from '~/product/common/interstitial/Components/cart-interstitial.module.scss';
import messagingCss from '~/util/messaging/line-item/LineItemMessaging.module.scss';

interface Props {
	store: any
}

export const CartInterstitialSectionals = observer(({ store = {} }: Props) => {
	const {
		HREF: {
			cart: cartPageLink,
		},
		magicModal: { closeModal },
	} = useGlobalContext();

	const {
		selectorConfigModel = {},
		workspaceModel = {},
	} = useProductContext();

	const {
		settings: {
			interstitialTitlePrefix = '',
			showStandardInterstitial = false,
		} = {},
	} = selectorConfigModel;

	const { sortedWorkspaceProducts = [] } = workspaceModel;

	const {
		cart,
		cartProductGroups = [],
		cartProductGroupReturnMessages,
	} = store.model;

	const {
		cartProducts = [],
		total: sellingPrice = 0,
	} = cartProductGroups[0];

	const [firstCartProduct = {}] = cartProducts;

	const {
		productName = '',
		productImage
	} = firstCartProduct;

	const showRfkRecos = store.loadRfkRecos;

	const productImageWidth = 200;

	const productImageSrcAttr = addToUrl(productImage, `wid=${productImageWidth}`);

	const productImageSrcSetAttr = addToUrl(productImage, `wid=${productImageWidth * 2}`);

	return (
		<div className={`${styles['CartInterstitial']} tw-space-y-6`}>
			<div className="tw-space-y-6 md:tw-flex md:tw-space-y-0">
				<div className="md:tw-w-8/12">
					<div className={`${styles['CartInterstitial-productImgWrapper']} tw-mb-6"`}>
						<img
							alt={productName}
							className={styles['CartInterstitial-productImg']}
							src={productImageSrcAttr}
							srcSet={`${productImageSrcSetAttr} 2x`}
						/>
					</div>
					<div className="tw-mb-6 tw-space-y-0 md:tw-space-x-0 md:tw-pr-6">
						<div data-qa="interstitial-productName" className={styles['CartInterstitial-productName']}>
							{productName}
						</div>
						<div className="tw-text-base md:tw-text-sm">
							{formatCurrency(sellingPrice, true)}
						</div>
					</div>
					<div
						className={
							classNames({
								'tw-mb-6': !showStandardInterstitial,
							})
						}
					>
						{
							sortedWorkspaceProducts.map(({ workspaceProductDimensions = '' }, index: number) => {
								const cartProduct = cartProducts[index] || {};

								const { productDetails = [] } = cartProduct;

								const filteredProductDetails = productDetails.filter((productDetail = {}) => {
									return !['Hardware', 'Frame', 'Wood', 'Shelf', 'Width'].includes(Object.keys(productDetail)[0]);
								});

								return (
									<div key={`cart-product-${index}`}>
										<div>
											{interstitialTitlePrefix ? `${interstitialTitlePrefix} ${index + 1}: ` : ''}{workspaceProductDimensions}
										</div>
										{
											// eslint-disable-next-line default-param-last
											filteredProductDetails.map((productDetail = {}, productDetailsIndex: number) => {
												return (
													<div key={`product-details-${productDetailsIndex}`}>
														{
															Object.entries(productDetail).map(([key, value], productDetailIndex) => {
																return (
																	<div key={`door-product-detail-${productDetailIndex}`}>{`${key}: ${value}`}</div>
																);
															})
														}
													</div>
												);
											})
										}
									</div>
								);
							})
						}
					</div>
				</div>
				<div className={`${styles['CartInterstitial-summary']} tw-space-y-4`}>
					<div className="tw-flex tw-items-center tw-space-x-4 md:tw-block md:tw-space-x-0">
						<h4 className="tw-text-lg tw-font-normal">Cart Subtotal</h4>
						<h4 className="tw-text-xl tw-font-normal">{formatCurrency(cart.total, true)}</h4>
					</div>
					<div className="ButtonGroup md:tw-block md:tw-space-y-6">
						<a
							className="Button tw-px-6"
							href={cartPageLink}
							data-qa="interstitial-checkout-now-button"
						>
							Checkout Now
						</a>

						<button
							className="ButtonAnchor tw-ml-3 md:tw-block md:tw-mx-0"
							onClick={closeModal}
							data-qa="interstitial-continue-shopping-button"
						>
							Continue Shopping
						</button>
					</div>
					<LineItemMessaging
						className={`${messagingCss.lineItemMessaging} tw-space-y-1 md:tw-w-11/12`}
						parentKey="line-item"
						messages={cartProductGroupReturnMessages}
						readOnly
					/>
				</div>
			</div>
			{
				recommendationsEnabled &&
				<div>
					{
						showRfkRecos &&
						<InterstitialRecos store={store.productRecosStore} />
					}
				</div>
			}
		</div>
	);
});
