import { WorkspaceProductCasegoodsModelFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Models/WorkspaceProductCasegoods.model';
import { WorkspaceSlotModelCasegoodsFactory } from '~/product/casegoods/workspaces/defaultWorkspace/Models/WorkspaceSlotCasegoods.model';

const createWorkspaceProductSlotCasegoodsModels = ({
	slotsColumnCount = 0,
	slotsData = [],
	slotsRowCount = 0,
	applicableInsertValidationData,
}) => {
	const workspaceSlotModels = slotsData.map((slotData) => {
		const {
			columnIndex = 0,
			rowIndex = 0,
		} = slotData;

		const isMaxColumn = columnIndex + 1 === slotsColumnCount;

		const isMaxRow = rowIndex + 1 === slotsRowCount;

		const slotModel = WorkspaceSlotModelCasegoodsFactory.create({
			...slotData,
			isMaxColumn,
			isMaxRow,
		});

		if (applicableInsertValidationData) {
			const applicableInsertValidation = (
				applicableInsertValidationData
				&& slotModel?.questions?.INSERT
				&& applicableInsertValidationData[slotModel.questions.INSERT])
				? Object.values(applicableInsertValidationData[slotModel.questions.INSERT])
				: ['ALL'];

			Object.assign(slotModel, {
				allow: applicableInsertValidation,
			});
		}

		return slotModel;
	});

	return workspaceSlotModels;
};

export const createWorkspaceProductCasegoodsModel = ({
	depthSelector = {},
	doorSelectorValue = {},
	frameProductModel = {},
	frameProductModels = [],
	frameSelectorModel = {},
	selectorConfigFrameModalModel = undefined,
	selectorConfigFrameModalModel: {
		selectedProduct,
	} = {},
	widthSelectorValue = {},
	workspaceProductImageData = undefined,
	workspaceProductIndex = 0,
}) => {
	const frameProductModelToUse = selectedProduct || frameProductModel;

	const {
		slotsColumnCount = 0,
		slots: slotsData = [],
		slotsRowCount = 0,
	} = frameProductModelToUse;

	const workspaceProductSlotModels = createWorkspaceProductSlotCasegoodsModels({
		slotsData,
		slotsColumnCount,
		slotsRowCount,
		applicableInsertValidationData: frameProductModelToUse.applicableInsertValidationData,
	});

	const workspaceProductModel = WorkspaceProductCasegoodsModelFactory.create({
		depthSelector,
		doorSelectorValue,
		frameProductModel: frameProductModelToUse,
		frameProductModels,
		frameSelectorModel,
		selectorConfigFrameModalModel,
		widthSelectorValue,
		workspaceProductIndex,
		workspaceProductImageData,
		workspaceProductSlotModels,
	});

	return workspaceProductModel;
};
