import React from 'react';
import { observer } from 'mobx-react';

import { CuralateUploadEdit } from '~/util/curalate/Components/CuralateUploadEdit';
import { CuralateUploadInfo } from '~/util/curalate/Components/CuralateUploadInfo';
import { CuralateUploadTabset } from '~/util/curalate/Components/CuralateUploadTabset';
import { AsyncFormSubmit } from '~/util/formz/Components/plugins/async-form-submit/AsyncFormSubmit';

import styles from '~/util/curalate/Components/curalate-upload.module.scss';

export const CuralateUploadForm = observer((props) => {
	const {
		store,
		magicModal,
	} = props;

	const {
		form,
		isImagesComplete,
	} = store;

	const { reactProps } = form;

	const curalateUploadInfo = (
		<CuralateUploadInfo store={store} />
	);

	const curalateUploadTabset = (
		<CuralateUploadTabset store={store} />
	);

	return (
		<div className={`${styles['curalate-upload-form']} formz`}>
			<AsyncFormSubmit form={form} showFormLevelErrors={false}>
				<form {...reactProps}>
					{isImagesComplete ? curalateUploadInfo : curalateUploadTabset}
					<CuralateUploadEdit store={store} magicModal={magicModal} />
				</form>
			</AsyncFormSubmit>
		</div>
	);
});
