import { observer } from 'mobx-react';
import React from 'react';

import { ProductPromos } from '~/product/common/promos/Components/ProductPromos';
import styles from '#/product/standard/details/details-attributes.module.scss';
import stylesDetail from '#/product/standard/details/details.module.scss';

export const DetailPromo = observer((props) => {
	const {
		detail = {},
	} = props;

	return (
		<div className={`${styles['detail']} ${stylesDetail['detail']} detail-promo`} data-qa="detail-promo">
			<ProductPromos promos={[detail]} listClassName="tw-ml-0" />
		</div>
	);
});
