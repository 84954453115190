import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/workspace/modal-frame/workspace-modal-frame-attribute-control.module.scss';

export const WorkspaceModalFrameControlCasegoods = observer((props = {}) => {
	const {
		frameProductModel = {},
		frameProductModel: {
			depth = 0,
		} = {},
		selected = false,
	} = props;

	const { workspaceModalFrameStore = {} } = useProductContext();

	function handleChange() {
		workspaceModalFrameStore.setFrameProductModel(frameProductModel);
	}

	return (
		<label
			className={styles['workspace-modal-frame-attribute-control']}
			data-qa="depth-selector-value"
		>
			<input
				checked={selected}
				className={styles['workspace-modal-frame-attribute-control-input']}
				data-tr-link-event-track={false}
				onChange={handleChange}
				type="radio"
			/>
			<span>{depth} inches</span>
		</label>
	);
});
