import React from 'react';
import { observer } from 'mobx-react';

import { isNumber } from '~/util/isNumber';
import { formatCurrency } from '~/util/formatCurrency';
import { S7Image } from '~/components/Images/S7Image';
import { s7ContentPath } from '~/global/global.constants';

export const DeliveryStandardWithZip = observer((props) => {
	const {
		store: {
			editZipCode,
			shippingMethod,
			results: {
				zipCode,
				deliveryPrice,
				contentAreaKey,
			},
		},
	} = props;
	let formattedDeliveryPrice = isNumber(deliveryPrice) ? formatCurrency(deliveryPrice, true) : '';

	if (!formattedDeliveryPrice) {
		formattedDeliveryPrice = '$79';
	}

	return (
		<div
			data-qa={`delivery ${shippingMethod} with-zip ${contentAreaKey}`}
			className="delivery-container delivery-container-standard delivery-container-inhome"
		>
			<div className="delivery-icon">
				<S7Image
					alt=""
					height="71"
					src={`${s7ContentPath}/rb-delivery-truck?size=68,71&scl=1`}
					width="75"
				/>
			</div>

			<div className="delivery-content">
				<span className="delivery-instructions tw-font-semibold">
					{formattedDeliveryPrice} full-service delivery of unlimited items to
					<button
						className="ButtonAnchor"
						onClick={editZipCode}
						data-qa="delivery-widget-enter-zip"
					>
						{zipCode.substring(0, 5)}
					</button>
				</span>
			</div>
		</div>
	);
});
