import React from 'react';
import { observer } from 'mobx-react';

import { CuralateItemImage } from '~/util/curalate/Components/CuralateItemImage';
import { CuralateItemProducts } from '~/util/curalate/Components/CuralateItemProducts';
import { CuralateItemSource } from '~/util/curalate/Components/CuralateItemSource';
import { curalateImageSizes } from '~/util/curalate/curalate.constants';

import styles from '~/util/curalate/Components/curalate-detail.module.scss';

export const CuralateDetailItem = observer(({
	item,
	item: {
		media,
		source,
	},
	handleReportItemClick,
	handleItemProductClick,
	trLinkEventUseCompNameH1 = null,
	trLinkEventCompName = null,
}) => {
	return (
		<div className={styles['curalate-detail-item']}
			data-tr-link-event-comp-name={trLinkEventCompName}
			data-tr-link-event-comp-type="bazaarvoice social commerce"
			data-tr-link-event-use-comp-name-h1={trLinkEventUseCompNameH1} >
			<div className={styles['curalate-detail-item-image-report-container']}>
				<div className={styles['curalate-detail-item-image']} data-qa="curalate-detail-item-image">
					<CuralateItemImage media={media} srcSizeKey={curalateImageSizes.LARGE_SQUARE.key} srcSetSizeKey={curalateImageSizes.XLARGE_SQUARE.key} srcDimensions={curalateImageSizes.LARGE_SQUARE.dimensions} />
				</div>
				<button className={`${styles['curalate-detail-item-report']} ButtonAnchor`} onClick={handleReportItemClick}>Report this image</button>
			</div>
			<div className={styles['curalate-item-source-products-container']}>
				<CuralateItemSource source={source} />
				<CuralateItemProducts handleItemProductClick={handleItemProductClick} item={item} />
			</div>
		</div>
	);
});
