import React from 'react';
import {
	action, reaction, observe, makeObservable
} from 'mobx';
import axios from 'axios';
import dynamic from 'next/dynamic';

import {
	apiUrl,
	isOnServer,
	recommendationsEnabled,
} from '~/global/global.constants';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';
import {
	addToCartActionKeys,
	addProductsToCartErrorKeys,
} from '~/product/common/summary/summary.constants';
import { SignInModalStoreFactory } from '~/account/sign-in/Stores/SignInModal.store';
import { ViewState } from '~/util/viewState/Components/ViewState';
import { noop } from '~/util/noop';
import { InterstitialStoreFactory } from '~/product/common/interstitial/Stores/Interstitial.store';
import { AddedToWishlistStoreFactory } from '~/product/common/interstitial/wishlist/Stores/AddedToWishlist.store';
import { loggedInAction } from '~/account/sign-in/Actions/loggedIn.action';
import { loggedOutAction } from '~/account/sign-in/Actions/loggedOut.action';
import { ReflektionEventsTrackingStore } from '~/tracking/vendor-events/reflektion/Stores/ReflektionEvents.tracking.store';
import { getFavoritesInfoAndAddToFavoritesStore } from '~/product/common/summary/Helpers/AddToFavorites.helpers';

const CartInterstitialMulti = dynamic(
	() => import('~/product/multi/interstitial/Components/CartInterstitialMulti').then(module => module.CartInterstitialMulti),
	{ loading: () => <LoadingSpinner isLoading /> }
);

class SummaryMultiStore {
	HREF;

	observers = [];

	productTrackingStore;

	rfkTrackingStore;

	summaryModel;

	summaryStores;

	constructor() {
		makeObservable(this, {
			addToCartInit: action.bound,
			addProductToCart: action.bound,
			addProductToWishlist: action.bound,
			assignGlobalDeliveryMessages: action.bound,
			assignInnerDeliveryMessages: action.bound,
			getSummaryData: action.bound,
			handleConfigurationChange: action.bound,
			addToCartErrorHandler: action.bound,
			addToCartSuccessHandler: action.bound,
			openCartInterstitialModal: action.bound,
			openWishlistInterstitialModal: action.bound,
			stopAnimating: action.bound
		});
	}

	addToCartInit(addToCartAction) {
		Object.assign(this.summaryModel, {
			addToCartAction,
			isMaxQuantity: false,
			isAddToCartInProcess: true,
			isAddToCartDone: false,
			shouldButtonAnimate: true,
		});
	}

	addProductToCart(event) {
		const {
			firstSelectedProduct: { addToCartHref },
		} = this.summaryModel;

		const onCloseFocusElement = event ? event.target : null;

		this.addToCartInit(addToCartActionKeys.CART);

		return axios
			.post(`${apiUrl}${addToCartHref}`, this.summaryModel.addProductsToCartRequestDto)
			.then(({ data }) => {
				Object.assign(this.summaryModel, { addToCartResponse: data });
				this.openCartInterstitialModal(onCloseFocusElement, data);
				this.addToCartSuccessHandler();
				this.productTrackingStore.trackAddToCart(data);
			}, ({
				response: {
					data: { errors = [] },
				}
			}) => {
				this.addToCartErrorHandler(onCloseFocusElement, errors);
			});
	}

	addToFavorites(event) {
		const onCloseFocusElement = event ? event.target : null;
		const {
			isCustomerGuest,
		} = this.globalDynamicStore.model;

		const {
			addProductsToFavoritesRequestDto,
			favoritesModalProductDto,
			firstSelectedProduct: {
				addToFavoritesHref,
			} = {},
		} = this.summaryModel;

		const promise = getFavoritesInfoAndAddToFavoritesStore(
			addToFavoritesHref,
			addProductsToFavoritesRequestDto,
			favoritesModalProductDto,
			null,
			this.addToCartErrorHandler,
			this.featureTogglesModel,
			this.globalDynamicStore,
			this.magicModal,
			onCloseFocusElement,
		);

		promise.then(() => {
			if (!isCustomerGuest) {
				this.magicModal.closeModal();
			}
		});

		return promise;
	}

	addProductToWishlist(event) {
		const onCloseFocusElement = event ? event.target : null;
		return this.addToFavorites(event)
			.then(() => {
				this.addToCartSuccessHandler();
			}, ({
				response: {
					data: { errors = [] },
				} = {}
			}) => {
				this.addToCartErrorHandler(onCloseFocusElement, errors);
			});
	}

	assignGlobalDeliveryMessages(data) {
		const {
			productSummaries = [],
		} = data;

		const {
			deliveryMessages = [],
			deliveryRateMessages = [],
		} = productSummaries[0];

		Object.assign(this.summaryModel, {
			deliveryMessages,
			deliveryRateMessages,
			isLoading: false,
		});
	}

	assignInnerDeliveryMessages(data) {
		const {
			priceTotal = 0,
			productSummaries = [],
		} = data;

		Object.assign(this.summaryModel, { pricing: priceTotal });

		this.assignGlobalDeliveryMessages(data);

		if (this.summaryModel.analytics) {
			this.summaryModel.analytics.clear();
		}

		productSummaries.forEach(({
			availability = {},
			analytics,
			analytics: [{
				articleNumber = '',
			}] = [{}],
			deliveryMessages = [],
			deliveryRateMessages = [],
			vendor: {
				variantIds = [],
			}
		}) => {
			const matchingSummaryStore = this.summaryStores.find(({
				summaryModel: {
					selectorConfigModel: {
						selectedProduct: {
							articleNumber: selectedProductArticleNumber = '',
						}
					}
				} = {},
			}) => {
				return articleNumber === selectedProductArticleNumber;
			});

			if (matchingSummaryStore) {
				const { summaryModel = {} } = matchingSummaryStore;

				Object.assign(summaryModel, {
					analytics,
					availability,
					deliveryMessages,
					deliveryRateMessages,
					variantIds,
				});

				if (this.summaryModel.analytics) {
					this.summaryModel.analytics.push(...analytics);
				}
			}
		});
	}

	getSummaryData() {
		const {
			firstSelectedProduct: { summaryHref },
			shippingMethod = '',
			summaryParams,
			productGroupModel = {},
			productGroupModel: {
				canonicalUrl,
			},
		} = this.summaryModel;

		const { hasInitialized } = this.summaryModel;

		if (!isOnServer) {
			const endpoint = `${apiUrl}${summaryHref}`;

			const restConfig = {
				products: summaryParams,
				shippingMethod,
				productGroupKey: productGroupModel.key,
				friendlyPath: canonicalUrl,
			};

			if (hasInitialized) {
				Object.assign(this.summaryModel, { isLoading: true });
			}

			const promise = axios.post(endpoint, restConfig);

			return promise
				.then(({ data = {} }) => {
					const {
						productSummaries = [],
					} = data;

					this.assignInnerDeliveryMessages(data);

					const reflektionSkuKeys = [];

					const variantIds = [];

					productSummaries.forEach((summary) => {
						const {
							vendor: {
								reflektion = [],
								variantIds: variantIdsData = [],
							} = {},
						} = summary;

						reflektion.forEach(({ skuKey }) => {
							reflektionSkuKeys.push(skuKey);
						});

						variantIdsData.forEach((variantId) => {
							variantIds.push(variantId);
						});
					});

					Object.assign(this.summaryModel, {
						hasError: false,
						reflektionSkuKeys,
						variantIds,
					});

					// Only fire tracking once on multi product
					if (!hasInitialized) {
						this.productTrackingStore.trackProductView('pass');
					}

					Object.assign(this.summaryModel, { hasInitialized: true });
				}, () => {
					Object.assign(this.summaryModel, {
						hasError: true,
						isLoading: false,
					});

					// Only fire tracking once on multi product
					if (!hasInitialized) {
						this.productTrackingStore.trackProductView('fail');
					}
				});
		}

		return Promise.reject().catch(noop);
	}

	handleConfigurationChange() {
		Object.assign(this.summaryModel, {
			availability: undefined,
			detailArticleNumber: undefined,
			isMaxQuantity: false,
		});

		this.getSummaryData();
	}

	addToCartErrorHandler(onCloseFocusElement, errors) {
		Object.assign(this.summaryModel, {
			isAddToCartDone: true,
			isAddToCartInProcess: false,
		});

		this.stopAnimating();

		errors.forEach((err) => { console.error(err); });

		if (errors.find(({ errorKey }) => errorKey === addProductsToCartErrorKeys.maxQuantity)) {
			Object.assign(this.summaryModel, { isMaxQuantity: true });
		}
	}

	addToCartSuccessHandler() {
		Object.assign(this.summaryModel, {
			isAddToCartDone: true,
			isAddToCartInProcess: false,
		});
		setTimeout(this.stopAnimating, 3000);
		this.globalDynamicStore.fetchData();
	}

	openCartInterstitialModal(onCloseFocusElement, data) {
		const {
			productGroupModel = {},
			validSummaryModels = [],
		} = this.summaryModel;

		const loadRfkRecos = recommendationsEnabled;

		const store = InterstitialStoreFactory.create(
			data,
			{
				magicModal: this.magicModal,
				featureTogglesModel: this.featureTogglesModel,
				globalDynamicModel: this.globalDynamicStore.model,
				globalStaticModel: this.globalStaticModel,
				pageStore: this.pageStore,
			},
			{
				loadRfkRecos,
			},
		);

		this.magicModal.openModal({
			title: 'Nice choice! Your cart has been updated.',
			content: {
				children: (
					<CartInterstitialMulti
						productGroupModel={productGroupModel}
						store={store}
						summaryModel={this.summaryModel}
						summaryModels={validSummaryModels}
					/>
				),
			},
			maxWidth: '725px',
		});
	}

	openWishlistInterstitialModal(onCloseFocusElement, data) {
		const { validSummaryModels = [] } = this.summaryModel;

		const store = AddedToWishlistStoreFactory.create(data, this.magicModal, {
			showQuantity: false,
			summaryModels: validSummaryModels,
		});

		this.magicModal.openModal({
			id: 'added-to-wishlist-modal',
			title: 'Added to Wish List!',
			maxWidth: '784px',
			onCloseFocusElement,
			content: {
				children: <ViewState viewState={store.viewState} />,
			},
		});
	}

	stopAnimating() {
		Object.assign(this.summaryModel, {
			isAddToCartDone: false,
			shouldButtonAnimate: false,
		});
	}
}

export const SummaryMultiStoreFactory = {
	create({
		featureTogglesModel,
		globalDynamicStore,
		globalStaticModel,
		magicModal,
		pageStore,
		productTrackingStore,
		summaryModel = {},
		summaryStores = [],
		HREF,
		trackingDebugMode = {},
	}) {
		const summaryMultiStore = new SummaryMultiStore();

		const rfkTrackingStore = new ReflektionEventsTrackingStore(featureTogglesModel);

		Object.assign(summaryMultiStore, {
			featureTogglesModel,
			globalDynamicStore,
			globalStaticModel,
			magicModal,
			pageStore,
			productTrackingStore,
			summaryModel,
			summaryStores,
			HREF,
			rfkTrackingStore,
			trackingDebugMode,
		});

		if (!isOnServer) {
			summaryMultiStore.observers.push(
				reaction(() => summaryModel.summaryParams, () => summaryMultiStore.getSummaryData()),

				// Refresh summary if an Engage user creates an online account
				// (effectively changing who they're working with).
				observe(globalDynamicStore.model, (change) => {
					if (change.name !== 'workingWith') {
						return;
					}
					if (!change.oldValue.name && change.newValue.name) {
						return;
					}
					if (!change.oldValue.name && !change.newValue.name) {
						return;
					}
					summaryMultiStore.getSummaryData();
				}),
			);
			summaryMultiStore.getSummaryData();
			// Refresh summary upon logging in or out.
			loggedInAction.add(() => {
				summaryMultiStore.getSummaryData();
			});
			loggedOutAction.add(() => {
				summaryMultiStore.getSummaryData();
			});
		}

		return summaryMultiStore;
	},
	destroy(store) {
		store.observers?.forEach?.(dispose => dispose());
	},
};
