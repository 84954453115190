import type { ISelectorConfigSectionals } from '~/product/sectionals/selector/Interfaces/SelectorConfigSectionals.interface';
import type {
	ISiblingDefaultConfig,
	ISiblingParams,
	ISiblingStoreModelData,
	ISiblingWorkspaceData,
	ISiblingWorkspaceProductData,
} from '~/product/common/selector/Components/links/siblingSectionalDesignerLink/Types/SiblingSectionalDesignerLink.types';
import type { ISummarySectionals } from '~/product/sectionals/summary/Interfaces/SummarySectionals.interface';
import type { IWorkspaceProductSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceProductSectionals.interface';
import type { IWorkspaceSectionals } from '~/product/sectionals/workspace/Interfaces/WorkspaceSectionals.interface';
import type { ProductGroupModel } from '~/product/common/Models/ProductGroup.model';
import type { SelectorValueQuestionModel } from '~/product/sectionals/selector/Models/SelectorValueQuestion.model';

export class SibilingSectionalDesignerModel {
	groupMaterialFilter: string[];

	href: string;

	productGroupModel: ProductGroupModel;

	defaultAndSelectedConfig: any[];

	selectorConfigModel: ISelectorConfigSectionals;

	selectedQuestionSelectorValues: any;

	siblingDefaultConfigModels: Array<ISiblingDefaultConfig>;

	summaryModel: ISummarySectionals;

	workspaceModel: IWorkspaceSectionals;

	constructor(data: ISiblingStoreModelData) {
		this.href = data.href;
		this.productGroupModel = data.productGroupModel;
		this.selectorConfigModel = data.selectorConfigModel;
		this.summaryModel = data.summaryModel;
		this.workspaceModel = data.workspaceModel;
		this.groupMaterialFilter = ['LEATHER', 'LTH_COLOR', 'FABRIC', 'FAB_COLOR'];
		this.selectedQuestionSelectorValues = [];
		this.siblingDefaultConfigModels = [];
		this.defaultAndSelectedConfig = [];
	}

	get customConfiguration() {
		return this.siblingDefaulAndSelectedConfig.map(({ answer = '', question = '' }) => {
			return `${question}|${answer}`;
		})
			.join('|');
	}

	get siblingDefaulAndSelectedConfig() {
		return this.siblingDefaultConfig.concat(this.selectedConfig);
	}

	get selectedConfig() {
		return this.selectedQuestionSelectorValues.reduce((accumulator: any[], values: SelectorValueQuestionModel) => {
			values.questionAnswers.map((questionAnswer) => {
				if (!this.groupMaterialFilter.includes(questionAnswer.group)) {
					accumulator.push(questionAnswer);
				}
				return accumulator;
			});
			return accumulator;
		}, []);
	}

	get siblingDefaultConfig() {
		return this.siblingDefaultConfigModels.reduce((accumulator: any[], value: any) => {
			if (this.groupMaterialFilter.includes(value.group)) {
				accumulator.push(value);
			}
			return accumulator;
		}, []);
	}

	get siblingParams(): ISiblingParams {
		const {
			canonicalUrl: friendlyPath = '',
		} = this.productGroupModel;

		const { selectedQuestionSelectorValues = [], siblingDefaultConfigModels } = this.selectorConfigModel;

		this.selectedQuestionSelectorValues = selectedQuestionSelectorValues;

		this.siblingDefaultConfigModels = siblingDefaultConfigModels;

		const { sortedWorkspaceProductModels = [] } = this.workspaceModel;

		const articles = sortedWorkspaceProductModels.map(({
			productModel: {
				siblingArticleNumber = null,
			} = {},
		}) => siblingArticleNumber);

		return {
			articles,
			customConfiguration: this.customConfiguration,
			deliveryMethod: 'INH',
			friendlyPath,
		};
	}

	get siblingWorkspaceData(): ISiblingWorkspaceData {
		const {
			selectedUnattachedOttomanSelectorValue = null,
			selectedProduct: {
				siblingArticleNumber = null,
			} = {},
		} = this.selectorConfigModel;

		return {
			selectedProductArticleNumber: siblingArticleNumber,
			configuration: this.siblingDefaulAndSelectedConfig,
			...(selectedUnattachedOttomanSelectorValue && { selectedOttomanArticle: selectedUnattachedOttomanSelectorValue.siblingArticleNumber }),
			workspaceOrientation: 'N',
			workspaceProduct: this.siblingWorkspaceProductsData,
		};
	}

	get siblingWorkspaceProductsData(): ISiblingWorkspaceProductData | {} {
		if (this.workspaceModel.firstWorkspaceProductModel) {
			return this.getSiblingWorkspaceProductData(this.workspaceModel.firstWorkspaceProductModel);
		}

		return {};
	}

	getSiblingWorkspaceProductData = ({
		workspaceProductJoinableModelsWithNextWorkspaceProductModel = [],
		orientation = '',
		productModel: {
			siblingArticleNumber = '',
		},
	}: IWorkspaceProductSectionals): ISiblingWorkspaceProductData => {
		const joinables = workspaceProductJoinableModelsWithNextWorkspaceProductModel.map(({
			joinableSide = '',
			nextWorkspaceProductModel = null,
		}) => {
			if (nextWorkspaceProductModel) {
				return {
					joinableSide,
					workspaceProduct: this.getSiblingWorkspaceProductData(nextWorkspaceProductModel),
				};
			}
			return {};
		});

		const workspaceProduct = {
			articleNumber: siblingArticleNumber,
			joinables,
			orientation,
		};

		return workspaceProduct;
	};
}

export const SiblingSectionalDesignerModelFactory = ({
	create: (data: ISiblingStoreModelData) => {
		return new SibilingSectionalDesignerModel(data);
	},
});
