import classNames from 'classnames';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { isEmpty } from '~/util/isEmpty';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { WorkspaceProductFrameImageCasegoods } from '~/product/casegoods/workspaces/defaultWorkspace/Components/product-frame/WorkspaceProductFrameImageCasegoods';
import { getIsValidWorkspaceProductHover } from '~/product/casegoods/workspaces/defaultWorkspace/Utils/WorkspaceCasegoods.utils';

import styles from '#/product/casegoods/workspace/product-frame/workspace-product-frame-draggable.module.scss';

export const WorkspaceProductFrameDraggableCasegoods = observer((props = {}) => {
	const {
		workspaceProduct = {},
		workspaceProduct: {
			isHidden = false,
			workspaceProductIndex = 0,
		} = {},
		imageScale = 0,
	} = props;

	const {
		workspaceModel,
		workspaceModel: {
			showErrorMessage = false,
			isHoveringOverFrames = false,
		} = {},
		workspaceStore = {},
	} = useProductContext();

	const dragItem = { workspaceProduct };

	const dragItemRef = useRef(dragItem);
	const ref = useRef();

	const [{ itemType, isOverCurrent }, drop] = useDrop({
		accept: ['INSERT_PRODUCT', 'WORKSPACE_PRODUCT'],
		collect: (monitor) => {
			return {
				itemType: monitor.getItemType(),
				isOverCurrent: monitor.isOver({ shallow: true }),
			};
		},
		hover: ({
			workspaceProduct: draggableWorkspaceProduct = {},
			workspaceProduct: {
				workspaceProductIndex: draggableIndex = 0,
			} = {},
		}, monitor) => {
			itemType === 'INSERT_PRODUCT' && isOverCurrent && !showErrorMessage && workspaceStore.setShowErrorMessage(true);
			const isValidHover = getIsValidWorkspaceProductHover({
				draggableIndex,
				monitor,
				ref,
				workspaceProductIndex,
			});
			if (isValidHover && !isEmpty(draggableWorkspaceProduct)) {
				runInAction(() => {
					workspaceStore.setWorkspaceProductIsHidden({
						isHidden: false,
						workspaceProduct: draggableWorkspaceProduct,
					});

					workspaceStore.updateWorkspaceProductIndex({
						newIndex: workspaceProductIndex,
						oldIndex: draggableIndex,
					});
				});
			} else if (isOverCurrent) {
				!isHoveringOverFrames && workspaceStore.setIsHoveringOverFrames(true);
				workspaceStore.setWorkspaceProductIsHidden({
					isHidden: true,
					workspaceProduct: draggableWorkspaceProduct,
				});
			}
		},
	});

	const [, drag, preview] = useDrag(() => ({
		end: (({
			workspaceProduct: draggableWorkspaceProduct = {},
		}, monitor) => {
			// frame was dropped outside the frame or workspace
			if (!monitor.didDrop()) {
				workspaceStore.removeWorkspaceProduct(draggableWorkspaceProduct);
			}

			runInAction(() => {
				workspaceStore.setIsDragging(false);
				workspaceStore.setIsHoveringOverFrames(false);

				workspaceStore.setWorkspaceProductIsHidden({
					isHidden: false,
					workspaceProduct: draggableWorkspaceProduct,
				});
			});
		}),
		canDrag: (() => {
			return !workspaceModel.isDraggableInserts; // frame isn't draggable when inserts are draggable
		}),
		item: (() => {
			const { clientWidth: width = 0 } = ref.current;

			workspaceStore.setIsDragging(true);
			return {
				workspaceProduct: dragItemRef.current.workspaceProduct,
				style: {
					...(width && { width }),
				},
			};
		}),
		type: 'WORKSPACE_PRODUCT',
	}));

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, []);

	useEffect(() => {
		dragItemRef.current = dragItem;
	});

	drag(drop(ref));

	function handleMouseDown(event) {
		event.stopPropagation();
	}

	useEffect(() => {
		if (itemType === 'WORKSPACE_PRODUCT' || !isOverCurrent) {
			showErrorMessage && workspaceStore.setShowErrorMessage(false);
		}
	}, [isOverCurrent]);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			className={
				classNames({
					[styles['workspace-product-frame-draggable']]: !workspaceModel.isDraggableInserts, // frame isn't draggable when inserts are draggable
					'tw-opacity-0': isHidden,
				})
			}
			data-qa="workspace-product-draggable"
			ref={ref}
			onMouseDown={handleMouseDown}
		>
			<WorkspaceProductFrameImageCasegoods
				imageScale={imageScale}
				workspaceProduct={workspaceProduct}
			/>
		</div>
	);
});
