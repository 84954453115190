import { computed, observable, makeObservable } from 'mobx';

export class WorkspaceModalFrameCasegoods {
	depthSelector

	doorSelectorValue

	insertProductModel

	insertProps

	widthSelectorValue

	workspaceProductModalFrameModel

	workspaceProductModalFrameStore

	constructor() {
		makeObservable(this, {
			depthSelector: observable.ref,
			doorSelectorValue: observable.ref,
			widthSelectorValue: observable.ref,
			workspaceProductModalFrameModel: observable.ref,
			workspaceProductModalFrameStore: observable.ref,
			depthSelectorValue: computed,
			doorSelector: computed,
			doorSelectorValues: computed,
			firstDoorSelectorValue: computed,
			firstWidthSelectorValue: computed,
			questionSelectors: computed,
			widthSelector: computed,
			widthSelectorValues: computed,
		});
	}

	get depthSelectorValue() {
		const { frameProductModel = {} } = this.workspaceProductModalFrameModel;

		const { articleNumber = '' } = frameProductModel;

		const { selectorValues = [] } = this.depthSelector;

		const depthSelectorValue = selectorValues.find(({ articles = [] }) => {
			return articles.find(({ article = '' }) => article === articleNumber);
		});

		return depthSelectorValue || {};
	}

	get doorSelector() {
		return this.questionSelectors.find(({ renderer = '' }) => renderer === 'DOOR') || {};
	}

	get doorSelectorValues() {
		const { selectorValues = [] } = this.doorSelector;

		return selectorValues;
	}

	get firstDoorSelectorValue() {
		const {
			selectedSelectorValue = {},
			selectorValues = [],
		} = this.doorSelector;

		return selectedSelectorValue || (selectorValues.length ? selectorValues[0] : {});
	}

	get firstWidthSelectorValue() {
		const { selectorValues = [] } = this.widthSelector;

		return selectorValues.length ? selectorValues[0] : {};
	}

	get questionSelectors() {
		const { selectors = [] } = this.depthSelectorValue;

		return selectors;
	}

	get widthSelector() {
		return this.questionSelectors.find(({ renderer = '' }) => renderer === 'WIDTH') || {};
	}

	get widthSelectorValues() {
		const { selectorValues = [] } = this.widthSelector;

		return selectorValues;
	}
}

export const WorkspaceModalFrameCasegoodsModelFactory = ({
	create: ({
		depthSelector = {},
		insertProductModel,
		insertProps = {},
		widthSelectorValue = {},
		workspaceProductModalFrameModel = {},
		workspaceProductModalFrameStore = {},
	} = {}) => {
		const workspaceModalFrame = new WorkspaceModalFrameCasegoods();

		Object.assign(workspaceModalFrame, {
			depthSelector,
			insertProductModel,
			insertProps,
			widthSelectorValue,
			workspaceProductModalFrameModel,
			workspaceProductModalFrameStore,
		});

		return workspaceModalFrame;
	},
});
