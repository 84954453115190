import axios from 'axios';
import Link from 'next/link';
import { observer } from 'mobx-react';
import React, { MouseEventHandler } from 'react';

import { cartChangedAction } from '~/cart/Actions/cartChanged.action';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { noop } from '~/util/noop';

import styles from '#/mobile/global/header.module.scss';

type Props = {
	signOutClickHandler: MouseEventHandler<HTMLButtonElement>
};

export const HeaderMobileAccountMenu = observer(({ signOutClickHandler = noop }: Props) => {
	const {
		globalDynamicStore,
		HREF,
		headerMobileStore: {
			toggleIsAccountOpen,
		},
		signInStore,
		siteMessagingStore,
	} = useGlobalContext();

	return (
		<ul className={`${styles['header-mobile-account-menu']} u-noListStyle`}>
			{
				!siteMessagingStore.hasOutageMessage &&
				<>
					<li className={styles['header-mobile-account-menu-item-container']}>
						<Link
							className={styles['header-mobile-account-menu-item']}
							data-qa="account-profile-link"
							href={HREF.account.overview}
							onClick={toggleIsAccountOpen}
						>
							Overview
						</Link>
					</li>
					<li className={styles['header-mobile-account-menu-item-container']}>
						<Link
							className={styles['header-mobile-account-menu-item']}
							data-qa="order-history-link"
							href={HREF.account.orderHistory}
							onClick={toggleIsAccountOpen}
						>
							Orders
						</Link>
					</li>
					<li className={styles['header-mobile-account-menu-item-container']}>
						<Link
							className={styles['header-mobile-account-menu-item']}
							href={HREF.account.swatchOrders}
							data-qa="swatch-orders-link"
							onClick={toggleIsAccountOpen}
						>
							Swatch Orders
						</Link>
					</li>
					<li className={styles['header-mobile-account-menu-item-container']}>
						<Link
							className={styles['header-mobile-account-menu-item']}
							data-qa="delivery-address-book-link"
							href={HREF.account.deliveryAddressBook}
							onClick={toggleIsAccountOpen}
						>
							Delivery Addresses
						</Link>
					</li>
					<li className={styles['header-mobile-account-menu-item-container']}>
						<Link
							className={styles['header-mobile-account-menu-item']}
							href={HREF.account.emailPreferences}
							data-qa="email-preferences-link"
							onClick={toggleIsAccountOpen}
						>
							Email Preferences
						</Link>
					</li>
				</>
			}
			<li className={styles['header-mobile-account-menu-item-container']}>
				<button
					className={styles['header-mobile-account-menu-item']}
					data-qa="sign-out-link"
					onClick={(event) => {
						const {
							model: {
								gpcSignal = false,
								logoutLink,
							},
						} = globalDynamicStore;

						// set the current gpcSignal value
						// if the logout post succeeds then we set it to false and will need to do some settings work if the value was previously true
						signInStore.oneTrustStore.setGpcSignal(gpcSignal);

						if (!logoutLink) {
							return;
						}
						signOutClickHandler(event);
						axios
							.post(logoutLink)
							.then(noop, noop)
							.then(() => {
								if (!cartChangedAction) {
									return;
								}
								if (cartChangedAction.getNumListeners()) {
									cartChangedAction.dispatch();
								} else {
									globalDynamicStore.fetchData()
										.then(() => {
											// when a user logs out set the gpcSignal to false and handle settings for OneTrust and Adobe
											signInStore.oneTrustStore.handleGPCSignal(false);
										});
								}
							});
					}}
				>
					Sign Out
				</button>
			</li>
		</ul>
	);
});
