import { computed, observable, makeObservable } from 'mobx';

export class SelectorFilters {
	didHaveActiveFilters;

	hasActiveFiltersChangedDisposer;

	filterModels;

	parentSelectorModel;

	selectorValues;

	shouldAggregateSelectorValues;

	constructor() {
		makeObservable(this, {
			didHaveActiveFilters: observable,
			selectorValues: observable.ref,
			activeFilters: computed,
			activeFiltersSelectorValues: computed,
			filteredSelectorValues: computed,
			flattenedActiveFiltersSelectorValues: computed,
			hasActiveFilters: computed,
			hasFilters: computed,
			isSingleFilter: computed,
			selectedFacets: computed,
			selectedFacetsByFilter: computed,
			selectedParentSelectorValue: computed,
			selectorValuesToUse: computed,
			sortedFilters: computed,
			validSelectorValues: computed,
		});
	}

	get activeFilters() {
		return this.filterModels.filter(({ selectedFacets = [] }) => selectedFacets.length);
	}

	get activeFiltersSelectorValues() {
		return this.activeFilters.map(({ filteredSelectorValues = [] }) => filteredSelectorValues);
	}

	get filteredSelectorValues() {
		if (!this.activeFilters.length) {
			return this.selectorValuesToUse;
		}

		return this.selectorValuesToUse.filter((selectorValue) => {
			return this.flattenedActiveFiltersSelectorValues.includes(selectorValue);
		});
	}

	get flattenedActiveFiltersSelectorValues() {
		return this.activeFiltersSelectorValues.reduce((accumulatedSelectorValues, activeFilterSelectorValues) => {
			return [...accumulatedSelectorValues, ...activeFilterSelectorValues];
		}, []);
	}

	get hasActiveFilters() {
		return Boolean(this.activeFilters.length);
	}

	get hasFilters() {
		return Boolean(this.filterModels.length);
	}

	get isSingleFilter() {
		return this.filterModels.length === 1;
	}

	get selectedFacets() {
		return this.filterModels.reduce((accumulatedSelectedFacets, { selectedFacets = [] }) => {
			return [...accumulatedSelectedFacets, ...selectedFacets];
		}, []);
	}

	get selectedFacetsByFilter() {
		return this.filterModels.reduce((accumulatedSelectedFacets, {
			selectedFacets = [],
			title = '',
		}) => {
			if (selectedFacets.length) {
				accumulatedSelectedFacets[title] = selectedFacets;
			}

			return accumulatedSelectedFacets;
		}, {});
	}

	get selectedParentSelectorValue() {
		const { selectedSelectorValue = {} } = this.parentSelectorModel;

		return selectedSelectorValue;
	}

	get selectorValuesToUse() {
		if (!this.hasFilters || this.shouldAggregateSelectorValues) {
			return this.selectorValues;
		}

		return this.validSelectorValues;
	}

	get sortedFilters() {
		return this.filterModels.sort((a, b) => a - b);
	}

	get validSelectorValues() {
		const {
			selectedSelector: {
				selectorValues = [],
			} = {},
		} = this.selectedParentSelectorValue;

		return selectorValues.length ? selectorValues : this.selectorValues;
	}
}

export const SelectorFiltersModelFactory = ({
	create: ({
		didHaveActiveFilters = false,
		filterModels = [],
		parentSelectorModel = {},
		selectorValues = [],
		shouldAggregateSelectorValues = false,
	}) => {
		const selectorFilters = new SelectorFilters();

		Object.assign(selectorFilters, {
			didHaveActiveFilters,
			filterModels,
			parentSelectorModel,
			selectorValues,
			shouldAggregateSelectorValues,
		});

		return selectorFilters;
	},
});
