import { observable, makeObservable } from 'mobx';

class ProductAnswer {
	key

	selected;

	constructor() {
		makeObservable(this, {
			selected: observable,
		});
	}
}

export const ProductAnswerModelFactory = ({
	create: ({
		key = '',
		componentIds = [],
		name = '',
		selected = false,
		upcharge = 0,
	}) => {
		const productAnswer = new ProductAnswer();

		Object.assign(productAnswer, {
			key,
			componentIds,
			name,
			selected,
			upcharge,
		});

		return productAnswer;
	},
});
