import axios from 'axios';
import { action, reaction, makeObservable } from 'mobx';
import React from 'react';

import type { AxiosResponse } from 'axios';

import type { ISummarySectionals } from '~/product/sectionals/summary/Interfaces/SummarySectionals.interface';
import type { ISummarySectionalsStore } from '~/product/sectionals/summary/Interfaces/SummarySectionalsStore.interface';

import { apiUrl, isOnServer, recommendationsEnabled } from '~/global/global.constants';
import { InterstitialStoreFactory } from '~/product/common/interstitial/Stores/Interstitial.store';
import { AddedToWishlistStoreFactory } from '~/product/common/interstitial/wishlist/Stores/AddedToWishlist.store';
import { addToCartActionKeys, addProductsToCartErrorKeys } from '~/product/common/summary/summary.constants';
import { CartInterstitialSectionals } from '~/product/sectionals/interstitial/Components/CartInterstitialSectionals';
import { ViewState } from '~/util/viewState/Components/ViewState';
import { getFavoritesInfoAndAddToFavoritesStore } from '~/product/common/summary/Helpers/AddToFavorites.helpers';

class SummarySectionalsStore implements ISummarySectionalsStore {
	didTrackFirstProductView: boolean;

	featureTogglesModel: any;

	globalDynamicModel: any;

	globalDynamicStore: any;

	globalStaticModel: any;

	HREF: string;

	magicModal: any;

	productTrackingStore: any;

	summaryModel: ISummarySectionals;

	constructor({
		didTrackFirstProductView = false,
		featureTogglesModel = {},
		globalDynamicModel = {},
		globalDynamicStore = {},
		globalStaticModel = {},
		HREF = '',
		magicModal = {},
		productTrackingStore = {},
		summaryModel = {},
	}: any = {}) {
		makeObservable(this, {
			addToCartInit: action,
			addToCartSuccessHandler: action,
			addProductToCart: action,
			addProductToWishlist: action.bound,
			getSummaryData: action,
		});

		this.didTrackFirstProductView = didTrackFirstProductView;
		this.featureTogglesModel = featureTogglesModel;
		this.globalDynamicModel = globalDynamicModel;
		this.globalDynamicStore = globalDynamicStore;
		this.globalStaticModel = globalStaticModel;
		this.HREF = HREF;
		this.magicModal = magicModal;
		this.productTrackingStore = productTrackingStore;
		this.summaryModel = summaryModel;
	}

	addToCartInit(addToCartAction: string): void {
		this.summaryModel.addToCartAction = addToCartAction;
		this.summaryModel.isAddToCartDone = false;
		this.summaryModel.isAddToCartInProcess = true;
		this.summaryModel.isMaxQuantity = false;
		this.summaryModel.shouldButtonAnimate = true;
	}

	addToCartSuccessHandler(): void {
		this.summaryModel.isAddToCartDone = true;
		this.summaryModel.isAddToCartInProcess = false;
		this.globalDynamicStore.fetchData();
		setTimeout(() => {
			this.stopAnimating();
		}, 3000);
	}

	addProductToCart(event: MouseEvent): Promise<AxiosResponse | void> {
		const {
			addToCartHref = '',
			summaryParams = {},
			workspaceModel: {
				workspaceData = {},
			} = {},
		} = this.summaryModel;

		const onCloseFocusElement = event ? event.target : null;

		const url = `${apiUrl}${addToCartHref}`;

		if (addToCartHref) {
			this.addToCartInit(addToCartActionKeys.CART);

			return axios.request({
				data: {
					...summaryParams,
					workspace: workspaceData,
				},
				method: 'post',
				url,
			})
				.then(({ data }) => {
					this.openCartInterstitialModal(onCloseFocusElement, data);
					this.addToCartSuccessHandler();
					this.productTrackingStore.trackAddToCart(data);
				})
				.catch(({
					response: {
						data: { errors = [] },
					}
				}) => {
					this.addToCartErrorHandler(onCloseFocusElement, errors);
				});
		}

		return Promise.reject();
	}

	addToFavorites(event: MouseEvent) {
		const onCloseFocusElement = event ? event.target : null;

		const {
			addToFavoritesHref,
			addToFavoritesPayload,
			favoritesModalProductDto,
		} = this.summaryModel;

		const {
			isCustomerGuest,
		} = this.globalDynamicStore.model;

		const promise = getFavoritesInfoAndAddToFavoritesStore(
			addToFavoritesHref,
			addToFavoritesPayload,
			favoritesModalProductDto,
			undefined,
			this.addToCartErrorHandler,
			this.featureTogglesModel,
			this.globalDynamicStore,
			this.magicModal,
			onCloseFocusElement,
		);

		promise.then(() => {
			if (!isCustomerGuest) {
				this.magicModal.closeModal();
			}
		});

		return promise;
	}

	addProductToWishlist(event: MouseEvent): Promise<AxiosResponse | void> {
		const onCloseFocusElement = event ? event.target : null;

		return this.addToFavorites(event)
			.then(() => {
				this.addToCartSuccessHandler();
			}, ({
				response: {
					data: { errors = [] } = {},
				} = {}
			}) => {
				this.addToCartErrorHandler(onCloseFocusElement, errors);
			});
	}

	addToCartErrorHandler(onCloseFocusElement: any, errors: Array<any>): void {
		this.summaryModel.isAddToCartDone = true;
		this.summaryModel.isAddToCartInProcess = false;
		this.stopAnimating();

		errors.forEach((err) => { console.error(err); });

		if (errors.find(({ errorKey }) => errorKey === addProductsToCartErrorKeys.maxQuantity)) {
			this.summaryModel.isMaxQuantity = true;
		}
	}

	getSummaryData(): Promise<AxiosResponse | void> {
		const {
			analytics: prevAnalytics = [],
			isValidSummary = false,
			summaryHref = '',
			summaryParams = {},
		} = this.summaryModel;

		if (!isValidSummary) {
			return Promise.resolve();
		}

		if (!isOnServer) {
			const url = `${apiUrl}${summaryHref}`;

			this.summaryModel.isLoading = true;

			return axios.request({
				data: summaryParams,
				method: 'post',
				url,
			})
				.then(({ data = {} }) => {
					const {
						analytics = [],
						availability = {},
						deliveryMessages = [],
						deliveryRateMessages = [],
						detailArticleNumbers = [],
						ottomanDetailArticleNumber = {},
						pricing = 0,
						vendor: {
							reflektion = [],
							variantIds = [],
						} = {},
					} = data;

					const reflektionSkuKeys = reflektion.map(({ skuKey = '' }) => skuKey);

					this.summaryModel.analytics = analytics;
					this.summaryModel.availability = availability;
					this.summaryModel.deliveryMessages = deliveryMessages;
					this.summaryModel.deliveryRateMessages = deliveryRateMessages;
					this.summaryModel.detailArticleNumbers = detailArticleNumbers;
					this.summaryModel.hasError = false;
					this.summaryModel.isLoading = false;
					this.summaryModel.reflektionSkuKeys = reflektionSkuKeys;
					this.summaryModel.ottomanDetailArticleNumber = ottomanDetailArticleNumber;
					this.summaryModel.pricing = pricing;
					this.summaryModel.variantIds = variantIds;

					// only track workspaceProduct additions - not removals or re-arrangements
					if (analytics.length > prevAnalytics.length) {
						this.productTrackingStore.trackProductView('pass', { trackAllWorkspaceProducts: !this.didTrackFirstProductView });

						this.didTrackFirstProductView = true;
					}
				})
				.catch(() => {
					this.summaryModel.hasError = true;
					this.summaryModel.isLoading = false;

					this.productTrackingStore.trackProductView('fail');
				});
		}

		this.summaryModel.isLoading = false;

		return Promise.resolve();
	}

	openCartInterstitialModal(onCloseFocusElement: any, data = {}): void {
		const store = InterstitialStoreFactory.create(
			data,
			{
				featureTogglesModel: this.featureTogglesModel,
				globalDynamicModel: this.globalDynamicModel,
				globalStaticModel: this.globalStaticModel,
				magicModal: this.magicModal,
			},
			{
				loadRfkRecos: recommendationsEnabled,
			},
		);

		this.magicModal.openModal({
			title: 'Nice choice! Your cart has been updated.',
			content: {
				children: (
					<CartInterstitialSectionals store={store} />
				),
			},
			maxWidth: '725px',
			onCloseFocusElement,
		});
	}

	openWishlistInterstitialModal(onCloseFocusElement: any, data = {}) {
		const store = AddedToWishlistStoreFactory.create(data, this.magicModal);

		this.magicModal.openModal({
			id: 'added-to-wishlist-modal',
			title: 'Added to Wish List!',
			maxWidth: '784px',
			onCloseFocusElement,
			content: {
				children: <ViewState viewState={store.viewState} />,
			},
		});
	}

	stopAnimating(): void {
		this.summaryModel.shouldButtonAnimate = false;
		this.summaryModel.isAddToCartDone = false;
	}
}

export const SummarySectionalsStoreFactory = {
	create(data: any = {}) {
		const { summaryModel = {} } = data;

		const summaryStore = new SummarySectionalsStore(data);

		if (!isOnServer) {
			reaction(() => summaryModel.summaryParams, () => summaryStore.getSummaryData(), { delay: 250 });
		}

		return summaryStore;
	},
};
