import React from 'react';
import { observer } from 'mobx-react';

import { CuralateItemImage } from '~/util/curalate/Components/CuralateItemImage';
import { curalateImageSizes } from '~/util/curalate/curalate.constants';
import { Field } from '~/util/formz/Components/fields/Field';
import { TextareaField } from '~/util/formz/Components/fields/TextareaField';
import { AsyncFormSubmit } from '~/util/formz/Components/plugins/async-form-submit/AsyncFormSubmit';

export const CuralateItemReportForm = observer((props) => {
	const {
		item: {
			media,
		},
		store: {
			form,
			form: {
				fields: {
					reportReason,
					userEmail,
				},
				reactProps,
			}
		},
		magicModal: {
			closeModal,
		}
	} = props;

	return (
		<div>
			<AsyncFormSubmit form={form} showFormLevelErrors={false}>
				<form {...reactProps}>
					<fieldset>
						<div>
							<legend className={'tw-sr-only'}>Report this media</legend>
							<p>This means it is inappropriate, it has violated a law, or it infringes upon someone’s rights. Reporting this media will flag it for further review.</p>
							<p>All fields are required unless noted.</p>
							<CuralateItemImage media={media} srcSizeKey={curalateImageSizes.SMALL_SQUARE.key} srcSetSizeKey={curalateImageSizes.MEDIUM_SQUARE.key} srcDimensions={curalateImageSizes.SMALL_SQUARE.dimensions} />
							<div>
								<Field field={userEmail} />
								<TextareaField field={reportReason} />
							</div>
						</div>
					</fieldset>
					<div className="ButtonGroup ButtonGroup--stackedSmallOnly">
						<input className={'Button'} type={'submit'} value={'Report'} />
						<button className={'ButtonAnchor'} onClick={closeModal} type={'button'}>Cancel</button>
					</div>
				</form>
			</AsyncFormSubmit>
		</div>
	);
});
