import { computed, makeObservable, observable } from 'mobx';

import { addToUrl } from '~/util/addToUrl';

class Product {
	_links;

	articleNumber;

	caption;

	comfortSpectrumModel;

	mattressComfortSpectrumModel;

	components;

	delivery;

	detailsAdditionalModel;

	detailsAttributesModel;

	detailsConstructionModel;

	detailsDimensionsModel;

	detailsMaterialsModel;

	detailsSustainabilityModel;

	hasCylindo;

	hasCylindoDimensionImage;

	imageData;

	isLoaded;

	isLoading;

	dimensionMedia;

	longSalesText;

	leadTime;

	maxQty;

	mediaSetDimensionsModel;

	nonCustomArticles;

	originImage;

	originImageText;

	pricingInfo;

	questionsModel;

	relativeProductGroupPath;

	selected;

	status;

	summaryTitle;

	constructor() {
		makeObservable(this, {
			caption: observable,
			components: observable.ref,
			comfortSpectrumModel: observable.ref,
			mattressComfortSpectrumModel: observable.ref,
			delivery: observable.ref,
			detailsAdditionalModel: observable.ref,
			detailsAttributesModel: observable.ref,
			detailsConstructionModel: observable.ref,
			detailsDimensionsModel: observable.ref,
			detailsMaterialsModel: observable.ref,
			detailsSustainabilityModel: observable.ref,
			hasCylindo: observable,
			hasCylindoDimensionImage: observable,
			imageData: observable,
			isLoaded: observable,
			dimensionMedia: observable,
			longSalesText: observable,
			maxQty: observable,
			mediaSetDimensionsModel: observable.ref,
			nonCustomArticles: observable.ref,
			originImage: observable,
			originImageText: observable,
			pricingInfo: observable,
			questionsModel: observable.ref,
			selected: observable,
			status: observable.ref,
			summaryTitle: observable,
			addToCartHref: computed,
			addToFavoritesHref: computed,
			addToWishlistHref: computed,
			materialDetailsHref: computed,
			configurationParams: computed,
			links: computed,
			originalPrice: computed,
			productHref: computed,
			price: computed,
			questionAnswerParams: computed,
			questionGroupParams: computed,
			selectedQuestionAnswers: computed,
			summaryHref: computed,
			url: computed,
			urlSuffix: computed,
			upcharge: computed,
		});
	}

	get addToCartHref() {
		return this.links.addToCart?.href;
	}

	get addToFavoritesHref() {
		return this.links.addToFavorites?.href;
	}

	get addToWishlistHref() {
		return this.links.addToWishlist?.href;
	}

	get materialDetailsHref() {
		return this.links.materialDetails?.href;
	}

	get configurationParams() {
		return `articleNumber=${this.articleNumber}&${this.questionAnswerParams}`;
	}

	get links() {
		return this._links || {};
	}

	get originalPrice() {
		if (!this.questionsModel && this.pricingInfo?.base) {
			return this.pricingInfo.base;
		}

		return this.questionsModel
			.map(({ selectedAnswer }) => selectedAnswer)
			.reduce((acc, answer) => acc + (answer ? answer.upcharge : 0), this.pricingInfo.base);
	}

	get price() {
		const {
			clearance: clearanceOffset = 0,
			promotional: promotionalOffset = 0,
		} = this.pricingInfo;

		return this.originalPrice + (clearanceOffset || promotionalOffset || 0);
	}

	get productHref() {
		return this.links.product?.href;
	}

	get questionAnswerParams() {
		return this.selectedQuestionAnswers.map(({
			answer = '',
			question = '',
		}) => {
			return `CHAR_${this.articleNumber}_${question}=${answer}`;
		}).join('&');
	}

	get questionGroupParams() {
		return this.selectedQuestionAnswers.map(({
			answer = '',
			group = '',
		}) => {
			return `question=${group}:${answer}`;
		}).join('&');
	}

	get selectedQuestionAnswers() {
		return this.questionsModel.map(({
			group,
			key,
			selectedAnswer,
		}) => {
			return {
				answer: selectedAnswer?.key,
				group,
				question: key,
			};
		});
	}

	get summaryHref() {
		return this.links.summary?.href;
	}

	get url() {
		return addToUrl(`${this.relativeProductGroupPath}/${this.articleNumber}`, this.configurationParams);
	}

	get urlSuffix() {
		return addToUrl(this.articleNumber, this.questionAnswerParams);
	}

	get upcharge() {
		return this.questionsModel
			.map(({ selectedAnswer }) => selectedAnswer)
			.reduce((acc, answer) => acc + (answer ? answer.upcharge : 0), 0);
	}
}

export const ProductModelFactory = ({
	create: ({
		_links = {},
		articleNumber = '',
		caption = '',
		comfortSpectrumModel,
		components = [],
		delivery = {},
		detailsAdditionalModel = [],
		detailsAttributesModel = [],
		detailsConstructionModel = [],
		detailsDimensionsModel = [],
		detailsMaterialsModel = [],
		detailsSustainabilityModel = [],
		dimensionMedia,
		hasCylindo = false,
		hasCylindoDimensionImage = false,
		imageData = {},
		isLoaded = false,
		isLoading = false,
		longSalesText = '',
		leadTime,
		mattressComfortSpectrumModel,
		maxQty,
		mediaSetDimensionsModel = {},
		nonCustomArticles = [],
		originImage = '',
		originImageText = '',
		pricing = {},
		questionsModel = [],
		relativeProductGroupPath = '',
		selected = false,
		status = {},
		statusOverride = {},
		summaryTitle = '',
		oneAvailableQty,
		maxQtyOverride,
		oneAvailableQtyOverride,
		promos,
	}) => {
		const product = new Product();

		Object.assign(product, {
			_links,
			articleNumber,
			caption,
			comfortSpectrumModel,
			components,
			delivery,
			detailsAdditionalModel,
			detailsAttributesModel,
			detailsConstructionModel,
			detailsDimensionsModel,
			detailsMaterialsModel,
			detailsSustainabilityModel,
			dimensionMedia,
			hasCylindo,
			hasCylindoDimensionImage,
			imageData,
			isLoaded,
			isLoading,
			longSalesText,
			leadTime,
			mattressComfortSpectrumModel,
			maxQty: maxQtyOverride || maxQty,
			mediaSetDimensionsModel,
			nonCustomArticles,
			originImage,
			originImageText,
			pricingInfo: pricing,
			questionsModel,
			relativeProductGroupPath,
			selected,
			status: {
				...status,
				...statusOverride,
			},
			summaryTitle,
			oneAvailableQty: oneAvailableQtyOverride || oneAvailableQty,
			promos,
		});

		return product;
	},
});
