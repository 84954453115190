import { observer } from 'mobx-react';
import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/standard/details/details-attributes.module.scss';
import stylesDetail from '#/product/standard/details/details.module.scss';

export const DetailOverallDimensions = observer((props) => {
	const {
		detail: {
			label = '',
		} = {},
	} = props;

	const {
		workspaceModel: {
			firstWorkspaceProductModel: {
				productModel: {
					height = 0,
					topCushionHeight = 0,
				}
			},
			hasWorkspaceProductModels = false,
			workspaceProductsDepth = 0,
			workspaceProductsWidth = 0,
		} = {},
	} = useProductContext();

	return (
		hasWorkspaceProductModels ?
			(<div
				className={`${styles['detail']} ${stylesDetail['detail']} detail-name-value`}
				data-qa="detail-name-value"
			>
				<strong data-qa="detail-name-value-name">{label}:</strong>
				<span data-qa="detail-name-value-value"> {`${workspaceProductsWidth}"w ${workspaceProductsDepth}"d ${height}"h${topCushionHeight ? ` (${topCushionHeight}"h with cushion)` : ''}`} </span>

			</div>
			) : null
	);
});
