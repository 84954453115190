import React from 'react';

import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';
import { vendorStoryId } from '~/product/common/vendor-story/vendorStory.constants';

import styles from '#/product/standard/selector/selector-blurbs.module.scss';

export const SelectorBlurbs = (props) => {
	const {
		blurbs = [],
		className = '',
	} = props;

	const {
		stickySummaryModel: {
			disableSummaries = true,
		} = {},
		productCommons: {
			productGroupModel: {
				introBlurb: {
					key: introBlurbKey = '',
					name: introBlurbName = '',
					vendor: introBlurbVendor = '',
					position: introBlurbPosition = '',
				} = {},
			} = {},
		} = {},
	} = useProductContext();

	const scrollToVendorStory = () => {
		const vendorStoryEl = document.querySelector(`#${vendorStoryId}`);

		// WIT-18521 Scroll margin is set to 110px to account for the mobile mini sticky summary
		if (!disableSummaries) {
			vendorStoryEl.style.scrollMarginTop = '110px';
		}

		if (vendorStoryEl) {
			vendorStoryEl.scrollIntoView({
				behavior: 'smooth',
			});

			vendorStoryEl.focus({ preventScroll: true });
		}
	};

	const introBlurbComponents = {
		'VENDOR_STORY': <>
			<span>
				{`${introBlurbName} `}
			</span>

			<button
				onClick={scrollToVendorStory}
				className="ButtonAnchor md:tw-text-sm tw-text-base"
			>
				{introBlurbVendor}
			</button>
		</>,
	};

	const introBlurb = introBlurbComponents[introBlurbPosition];

	if (!introBlurb && !blurbs.length) {
		return null;
	}

	return (
		<div className={`${styles['selector-blurbs-container']} selector-blurbs-container ${className}`}>
			{
				introBlurb &&
				<div
					data-qa={`selector-blurb_${introBlurbKey}`}
					className={`${styles['selector-blurb']} tw-font-bold`}
				>
					{ introBlurb }
				</div>
			}

			{
				blurbs.map((blurb) => {
					const {
						key = '',
						name = '',
					} = blurb;

					return (
						<div
							key={`selector-blurb_${key}`}
							data-qa={`selector-blurb_${key}`}
							className="selector-blurb"
						>
							<span className="tw-font-bold">{name}</span>
						</div>
					);
				})
			}
		</div>
	);
};
