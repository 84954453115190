class CareGuideModel {
	data;

	constructor(data) {
		this.data = data || {};
	}

	get id() {
		return this.data.id;
	}

	get key() {
		return this.data.key;
	}

	get linkTitle() {
		return this.data.linkTitle;
	}

	get url() {
		return this.data.url;
	}
}

export { CareGuideModel };
