import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { S7Image } from '~/components/Images/S7Image';
import { SelectorHeadingLabel } from '~/product/common/selector/Components/SelectorHeadingLabel';
import { addToUrl } from '~/util/addToUrl';
import { kebabCase } from '~/util/kebabCase';

import styles from '#/product/standard/selector/selector-static.module.scss';
import stylesSelector from '#/product/standard/selector/selector.module.scss';

export const SelectorHeadingStatic = observer((props) => {
	const {
		isActive = true,
		selectedSelector: {
			label = '',
			selectedSelectorValue: {
				preselectedProduct = { imageData: {} },
				statusToRender = '',
				title = '',
			} = {},
			showImages,
		} = {},
	} = props;

	const { imageData } = preselectedProduct;

	const { imageUrl } = imageData;

	return (
		<div
			className={`selector-heading ${stylesSelector['selector-heading']} ${styles['selector-heading-static']}`}
			data-qa={`${kebabCase(label)}-selector-heading`}
		>
			<div className="selector-heading-label-container tw-sr-only">
				<SelectorHeadingLabel label={label} />
			</div>
			<span
				className={`${stylesSelector['selector-heading-title']} ${styles['selector-heading-static-title']}`}
				data-qa={`${kebabCase(label)}-selector-heading-title`}
			>
				{title}
				{
					isActive && statusToRender && (
						<span className={
							classNames(`${stylesSelector['selector-heading-status']} ${styles['selector-heading-static-status']}`, {
								[stylesSelector['selector-heading-status-new']]: statusToRender === 'New',
							})
						}>{statusToRender}</span>
					)
				}
			</span>
			{
				showImages && (
					<S7Image
						alt=""
						className={styles['selector-heading-static-image']}
						data-qa={`${kebabCase(label)}-selector-heading-image`}
						height="85"
						src={addToUrl(imageUrl, '$proddd$')}
						width="150"
					/>
				)
			}
		</div>
	);
});
