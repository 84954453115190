import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { SelectorRadio } from '~/product/casegoods/selector/Components/radio/SelectorRadio';
import { SelectorOptionsToggle } from '~/product/casegoods/selector/Components/options/SelectorOptionsToggle';

import styles from '~/product/casegoods/selector/Components/options/selector-options-frame.module.scss';

export const SelectorOptionsFrame = observer((props = {}) => {
	const { selectors = [] } = props;

	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div
			className={
				classNames(styles['selector-options-frame'], {
					[styles['selector-options-frame-expanded']]: isExpanded,
				})
			}
			id="options-frame"
		>
			<SelectorOptionsToggle
				ariaControls="options-frame"
				buttonText="Cabinet options"
				dataQa="selector-options-toggle-frame"
				isExpanded={isExpanded}
				setIsExpanded={setIsExpanded}
			/>
			<div className={styles['selector-options-frame-selectors']}>
				{
					selectors.map((selector = {}, index) => {
						return (
							<SelectorRadio
								key={`selector-options-${index}`}
								selectedSelector={selector}
							/>
						);
					})
				}
			</div>
		</div>
	);
});
