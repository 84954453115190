import { observable, makeObservable } from 'mobx';

import { FormBuilder } from '~/util/formz/builders/FormBuilder';
import { deliveryFormModel, deliveryFormSettings } from '~/delivery/Models/DeliveryForm.model';

class DeliveryFormStore {
	form;

	parentStore

	constructor() {
		makeObservable(this, {
			form: observable.ref,
		});
	}
}

export const DeliveryFormStoreFactory = {
	create(parentStore, globalDynamicStore) {
		const deliveryFormStore = new DeliveryFormStore();

		deliveryFormStore.globalDynamicStore = globalDynamicStore;
		deliveryFormStore.parentStore = parentStore;
		deliveryFormStore.form = new FormBuilder(
			deliveryFormModel(),
			deliveryFormSettings(deliveryFormStore),
		);

		return deliveryFormStore;
	},
};
