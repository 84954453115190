import { observer } from 'mobx-react';
import dynamic from 'next/dynamic';
import React from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { SelectorValueFrameImage } from '~/product/casegoods/selector/Components/frame/SelectorValueFrameImage';
import { SelectorValueFrameTitle } from '~/product/casegoods/selector/Components/frame/SelectorValueFrameTitle';
import { createSelectorFrameModalModel } from '~/product/casegoods/selector/Helpers/SelectorFrameModal.init';
import { useProductContext } from '~/product/common/Contexts/SharedProduct.context';

import styles from '#/product/casegoods/selector/frame/selector-value-frame-button.module.scss';

const SelectorFrameModal = dynamic(() => import('~/product/casegoods/selector/Components/frame-modal/SelectorFrameModal').then(module => module.SelectorFrameModal), { ssr: false });
const WorkspaceModalFrameMaximumCasegoods = dynamic(() => import('~/product/casegoods/workspaces/defaultWorkspace/Components/modal-frame/WorkspaceModalFrameMaximumCasegoods').then(module => module.WorkspaceModalFrameMaximumCasegoods), { ssr: false });

export const SelectorValueFrameButton = observer((props) => {
	const {
		productModel = {},
		productModel: {
			articleNumber,
			hasAllowAllSlot = false,
			salesTextToRender = '',
		} = {},
		selectedDepthSelector = {},
		selectedDepthSelector: {
			selectedSelectorValue = {},
		} = {},
		title = '',
	} = props;

	const { magicModal = {} } = useGlobalContext();

	const {
		selectorConfigModel = {},
		selectorConfigModel: {
			imageScale: {
				small: imageScaleSmall = 0,
			} = {},
			settings: {
				frameModalHeadingTitle = '',
			} = {},
		} = {},
		selectorConfigStore = {},
		workspaceModel: {
			settings: {
				maxWorkspaceProducts = 0,
				replaceWorkspaceProducts = false,
				showMaxWorkspaceProductsWarning = false,
			},
			workspaceProductsCount = 0,
		} = {},
		workspaceStore = {},
		productGroupModel = {},
		productGroupModel: {
			imageData = {},
		} = {},
	} = useProductContext();

	const dataQaSuffix = hasAllowAllSlot ? 'ALL' : 'NONE';

	const imageOverride = Array.isArray(imageData[articleNumber]) ? imageData[articleNumber][0].imageUrl : imageData[articleNumber]?.imageUrl;

	function openSelectorFrameModal(selectorFrameModalModel = {}) {
		magicModal.openModal({
			content: {
				children: (
					<SelectorFrameModal selectorFrameModalModel={selectorFrameModalModel} />
				),
			},
			id: 'workspace-frame-modal',
			maxWidth: '600px',
			title: frameModalHeadingTitle,
		});
	}

	function handleClick() {
		selectorConfigStore.selectSelectorValue(selectedSelectorValue);

		const {
			insertConfiguration = {},
			selectedProduct = {},
			selectedProductConfiguration = {},
		} = selectorConfigModel;

		const selectorFrameModalModel = createSelectorFrameModalModel({
			defaultConfig: selectedProductConfiguration,
			defaultnsertConfig: insertConfiguration,
			defaultProduct: selectedProduct,
			productGroupModel,
			selectedSelector: selectedDepthSelector,
			selectorConfigModel,
			selectorConfigStore,
			workspaceProductIndex: workspaceProductsCount,
		});

		if (workspaceProductsCount < maxWorkspaceProducts) {
			magicModal.openModal({
				content: {
					children: (
						<SelectorFrameModal selectorFrameModalModel={selectorFrameModalModel} />
					),
				},
				id: 'workspace-frame-modal',
				maxWidth: '600px',
				title: frameModalHeadingTitle,
			});
		} else if (replaceWorkspaceProducts) {
			workspaceStore.removeAllWorkspaceProducts();

			openSelectorFrameModal(selectorFrameModalModel);
		} else if (showMaxWorkspaceProductsWarning) {
			magicModal.openModal({
				content: {
					children: (
						<WorkspaceModalFrameMaximumCasegoods />
					),
				},
				id: 'workspace-help-maximum-modal',
				maxWidth: '500px',
			});
		}
	}

	return (
		<button
			className={styles['selector-value-frame-button']}
			onClick={handleClick}
			data-qa={`frame-selector-value-${dataQaSuffix}`}
		>
			<div className={styles['selector-value-frame-image-container']}>
				{
					<SelectorValueFrameImage
						imageScale={imageScaleSmall}
						imageOverride={imageOverride}
						productModel={productModel}
						title={title}
					/>
				}
			</div>
			<SelectorValueFrameTitle title={title || salesTextToRender} />
		</button>
	);
});
