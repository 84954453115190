import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { responsiveSettings } from '../Recos.constants';

import styles from './Recos.module.scss';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { MagicRecosRfk } from '~/components/magic-recos-rfk/Components/MagicRecosRfk';
import { RecoItemRfk } from '~/components/magic-recos-rfk/Components/RecoItemRfk';
import { MagicRecosRfkStore } from '~/components/magic-recos-rfk/Stores/MagicRecosRfk.store';
import { headerLevelType } from '~/components/magic-header';
import { RecoItemModel } from '~/components/magic-recos-rfk/Models/RecoItem.model';
import { FourSquareRecos } from '~/components/magic-recos-rfk/Components/FourSquareRecos';

interface IProps {
	className?: string;
	defaultSlidesToShow?: number;
	hasTopBorder?: boolean;
	headerClassName?: string;
	headerLevel?: headerLevelType;
	store?: MagicRecosRfkStore;
	shouldShowRecos?: boolean;
	shouldShowFourSquaresOnMobile?: boolean;
	shouldTrackReflektionProductClicked?: boolean;
	width1600?: boolean,
}

export const Recos = observer(({
	className = '',
	defaultSlidesToShow = 5,
	hasTopBorder = false,
	headerClassName = '',
	headerLevel = 3,
	store,
	shouldShowRecos = true,
	shouldShowFourSquaresOnMobile = false,
	shouldTrackReflektionProductClicked = true,
}: IProps) => {
	const { featureTogglesModel } = useGlobalContext();

	if (!store) {
		return null;
	}

	const {
		model: {
			recoItems = [],
			variationId = '',
			title = '',
			widgetId = '',
			width1600 = false,
		} = {},
		reflektionEventsTrackingStore,
	} = store || {};

	if (!recoItems.length || !shouldShowRecos) {
		return null;
	}

	const headerNumber = Number(`${headerLevel}`.replace(/\D/g, ''));

	const recs = recoItems.map((item: RecoItemModel, index: number) => (
		<RecoItemRfk
			featureTogglesModel={featureTogglesModel}
			bemVariant={'RecoItem--lg'}
			key={`rec-number-${index + 1}`}
			item={item}
			totalItems={recoItems.length}
			headerLevel={headerNumber < 6 ? headerNumber + 1 : headerNumber}
			onClick={(event: React.MouseEvent, recoItemModel: RecoItemModel) => {
				reflektionEventsTrackingStore.trackReflektionProductClicked(widgetId, recoItemModel, !shouldTrackReflektionProductClicked);
			}}
		/>

	));

	return (
		<>
			{featureTogglesModel.isOn('DEBUG_MODE') && <div>RFK &mdash; {variationId}</div>}
			<MagicRecosRfk
				recs={recs}
				hasTopBorder={hasTopBorder}
				headerClassName={cn({
					'tw-m-0 tw-heading-4 md:tw-heading-3': true,
					[headerClassName]: headerClassName
				})}
				headerLevel={headerLevel}
				className={cn({
					'tw-hidden-for-print': true,
					[styles['recommendation-container']]: true,
					'tw-hidden md:tw-block': shouldShowFourSquaresOnMobile,
					[className]: className,
					[styles['recommendation-container-1600']]: width1600,
				})}
				title={title}
				defaultSlidesToShow={defaultSlidesToShow}
				sliderSettings={{
					centerMode: false,
					responsive: responsiveSettings,
				}}
				widgetId={widgetId}
				width1600={width1600}
			/>
			{shouldShowFourSquaresOnMobile && (
				<FourSquareRecos
					recs={recs}
					className={cn({
						'md:tw-hidden tw-mb-4': true,
						'tw-border-t tw-border-t-gray-400': hasTopBorder,
						'tw-border-0': !hasTopBorder,
						[className]: className
					})}
					title={title}
					headerClassName={cn({
						'tw-heading-4 tw-mb-4': true,
						[headerClassName]: headerClassName
					})}
				/>
			)}
		</>
	);
});
