import { MediaProductModelFactory } from '~/product/common/media-set/Models/MediaProduct.model';

export const createMediaProductModel = ({
	mediaData = {},
	selectorConfigModel = {},
}) => {
	const mediaModel = MediaProductModelFactory.create({
		...mediaData,
		selectorConfigModel,
	});

	return mediaModel;
};
