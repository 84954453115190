import React from 'react';
import { observable, computed, makeObservable } from 'mobx';
import axios from 'axios';

import {
	assignRoomApiUrl,
	assignRoomNoteApiUrl,
	interstitialViewstates,
} from '~/product/common/interstitial/interstitial.constants';
import { ValidateAndAjaxSubmitPlugin } from '~/util/formz/plugins/ValidateAndAjaxSubmitPlugin';

const {
	LOADING,
	WISHLIST_ASSIGNED,
} = interstitialViewstates;

class Interstitial {
	cart

	cartProductGroups

	cartRooms

	featureTogglesModel

	interstitialRenderer

	notesMaxLength

	constructor() {
		makeObservable(this, {
			cart: observable.ref,
			cartProductGroups: observable.ref,
			interstitialRenderer: observable.ref,
			cartRooms: observable.ref,
			notesMaxLength: observable,
			reflektionSkuKeys: computed,
			cartProductGroupIds: computed,
			cartProductArticleNumbers: computed,
			cartProductGroupReturnMessages: computed,
		});
	}

	get reflektionSkuKeys() {
		return this.cartProductGroups.flatMap((group) => {
			const {
				cartProducts = [],
			} = group;

			return cartProducts.map((product) => {
				const {
					analytics: {
						reflektionSkuKey = ''
					} = {},
					vendor: {
						reflektion = [{}],
					} = {},
				} = product;
				
				return reflektion[0]?.skuKey || "";
			});
		});
	}

	get cartProductGroupIds() {
		return this.cartProductGroups
			.map(({ cartProductGroupId }) => cartProductGroupId);
	}

	get cartProductArticleNumbers() {
		const result = [];

		this.cartProductGroups.forEach((cartProductGroup) => {
			cartProductGroup.cartProducts.forEach((cartProduct) => {
				result.push(cartProduct.articleNumber);
			});
		});
		return result;
	}

	get cartProductGroupReturnMessages() {
		if (!this.cartProductGroups?.length) {
			return [];
		}
		const result = this.cartProductGroups.reduce((totalMessages, cartProductGroup) => {
			if (!cartProductGroup.messages?.length) {
				return totalMessages;
			}
			const totalMessageKeys = totalMessages.map(message => message.messageKey);
			const messages =
				cartProductGroup.messages.filter(message => !totalMessageKeys.includes(message.messageKey));

			return totalMessages.concat(messages);
		}, []);

		return [...new Set(result)];
	}
}

export const interstitialFormModel = (store) => {
	const { cartProductGroupIds } = store.model;
	const model = observable({
		note: '',
		wishlistAction: 'EXISTING_ROOM',
		existingRoomName: '',
		newRoomName: '',
		cartProductGroupIds,
	});

	return model;
};

export const interstitialFormSettings = (store) => {
	const formSettings = {
		id: 'wishlistIntertstitialForm',
		reactProps: {
			method: 'POST',
		},
		settings: {
			plugins: [
				new ValidateAndAjaxSubmitPlugin({
					ajaxSubmit: {
						submitHandler: (form) => {
							const {
								model: {
									wishlistAction,
									cartProductGroupIds,
									newRoomName = '',
									existingRoomName = '',
									note = '',
								},
							} = form;
							const cartRoomName = {
								'NEW_ROOM': newRoomName,
								'EXISTING_ROOM': existingRoomName,
							}[wishlistAction];

							store.viewState.goTo(LOADING);

							if (cartRoomName) {
								return axios.post(assignRoomApiUrl, {
									cartProductGroupIds,
									cartRoomName,
									note,
								})
									.then(() => {
										store.viewState.goTo(WISHLIST_ASSIGNED);
									});
							}

							if (note) {
								return axios.post(assignRoomNoteApiUrl, {
									cartProductGroupIds,
									note,
								})
									.then(() => {
										store.viewState.goTo(WISHLIST_ASSIGNED);
									});
							}

							return store.magicModal.closeModal();
						},
					},
				}),

			],
		},
		fields: {
			wishlistAction: {
				radios: [
					{
						control: {
							reactProps: {
								'value': 'EXISTING_ROOM',
								'data-qa': 'wishlist-assign-existing-room-radio',
							},
						},
						label: {
							reactProps: {
								children: 'Existing Room',
							},
						},
					},
					{
						control: {
							reactProps: {
								'value': 'NEW_ROOM',
								'data-qa': 'wishlist-assign-new-room-radio',
							},
						},
						label: {
							reactProps: {
								children: 'New Room',
							},
						},
					},
				],
			},
			existingRoomName: {
				control: {
					reactProps: {
						'data-qa': 'wishlist-assign-room-name',
					},
				},
				label: {
					reactProps: {
						children: 'Select your room below',
					},
				},
			},
			newRoomName: {
				control: {
					reactProps: {
						'type': 'text',
						'data-qa': 'wishlist-assign-room-name',
						'maxLength': 100,
					},
				},
				label: {
					reactProps: {
						children: 'Enter your new room below',
					},
				},
			},
			note: {
				label: {
					reactProps: {
						children: (
							<h5 className="Heading Heading--5 tw-text-brown">
								Add a note about this item
							</h5>
						),
					},
				},
				control: {
					reactProps: {
						'type': 'textarea',
						'rows': 5,
						'maxLength': store.model.notesMaxLength,
						'data-qa': 'wishlist-interstitial-note',
						'className': 'tw-w-full',
					},
				},
				settings: {
					validationConstraints: {
						length: {
							minimum: 0,
							maximum: store.model.notesMaxLength,
						},
					},
				},
				showValidationMessage: false,
			},
		},
	};

	return formSettings;
};

export const InterstitialFactory = ({
	create: ({
		cart,
		cartProductGroups,
		cartRooms,
		interstitialRenderer,
	}) => {
		const interstitial = new Interstitial();

		Object.assign(interstitial, {
			cart,
			cartProductGroups,
			cartRooms,
			interstitialRenderer,
		});

		return interstitial;
	},
});
