'use client';

import { observer } from 'mobx-react';

import { ClientOnly } from '~/util/Components/ClientOnly';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const SummaryCount = observer(({ hideCount }: { hideCount: boolean }) => {
	const { globalDynamicStore } = useGlobalContext();

	return (
		<ClientOnly>
			{
				!hideCount && globalDynamicStore.model.cartQuantity > 0
					?
					<span
						data-qa="shopping-cart-summary-count"
						className="
							tw-font-bold tw-text-white tw-border tw-border-solid tw-border-white tw-text-center
							tw-tracking-normal tw-bg-gray tw-w-5 tw-h-5 tw-text-3xs tw-rounded-full
							tw-absolute tw-left-0 tw-bottom-[-6px] tw-flex tw-justify-center tw-items-center
							md:tw-bottom-[-2px]
						"
					>
						<span>{globalDynamicStore.model.cartQuantity}</span>
						<span className="tw-sr-only"> items in cart</span>
					</span>
					:
					<span className="tw-sr-only">Cart</span>
			}
		</ClientOnly>
	);
});
